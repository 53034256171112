import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ChangeCommissionPercentVariables = Types.Exact<{
  input: Types.TherapistChangeCommissionInput;
}>;


export type ChangeCommissionPercent = (
  { __typename: 'Mutation' }
  & { adminChangeCommissionPercent: (
    { __typename: 'AdminTherapistPayload' }
    & { therapistAdmin?: Types.Maybe<(
      { __typename: 'TherapistAdminModel' }
      & Pick<Types.TherapistAdminModel, 'id' | 'initialSessionCommissionPercent' | 'ongoingSessionCommissionPercent'>
    )>, userErrors: Array<(
      { __typename: 'ValidationError' }
      & Pick<Types.ValidationError, 'field' | 'messages'>
    )> }
  ) }
);


export const ChangeCommissionPercentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ChangeCommissionPercent"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TherapistChangeCommissionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminChangeCommissionPercent"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"therapistAdmin"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"initialSessionCommissionPercent"}},{"kind":"Field","name":{"kind":"Name","value":"ongoingSessionCommissionPercent"}}]}},{"kind":"Field","name":{"kind":"Name","value":"userErrors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"field"}},{"kind":"Field","name":{"kind":"Name","value":"messages"}}]}}]}}]}}]} as unknown as DocumentNode;
export type ChangeCommissionPercentMutationFn = Apollo.MutationFunction<ChangeCommissionPercent, ChangeCommissionPercentVariables>;

/**
 * __useChangeCommissionPercent__
 *
 * To run a mutation, you first call `useChangeCommissionPercent` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCommissionPercent` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCommissionPercent, { data, loading, error }] = useChangeCommissionPercent({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeCommissionPercent(baseOptions?: Apollo.MutationHookOptions<ChangeCommissionPercent, ChangeCommissionPercentVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeCommissionPercent, ChangeCommissionPercentVariables>(ChangeCommissionPercentDocument, options);
      }
export type ChangeCommissionPercentHookResult = ReturnType<typeof useChangeCommissionPercent>;
export type ChangeCommissionPercentMutationResult = Apollo.MutationResult<ChangeCommissionPercent>;
export type ChangeCommissionPercentMutationOptions = Apollo.BaseMutationOptions<ChangeCommissionPercent, ChangeCommissionPercentVariables>;