import React from 'react';

function AttachIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="#13101F"
      viewBox="0 0 16 16"
      {...rest}>
      <path d="M13.735 9.629l-1.036-1.036L8.04 3.934A2.196 2.196 0 004.934 7.04l4.66 4.66a.488.488 0 10.69-.69l-4.66-4.66A1.22 1.22 0 117.35 4.624l4.66 4.66 1.036 1.035a2.197 2.197 0 01-3.108 3.106l-.862-.863L4.244 7.73l-.345-.345A3.173 3.173 0 018.385 2.9l5.177 5.177a.488.488 0 00.69-.69L9.076 2.207a4.149 4.149 0 00-5.868 5.868l5.177 5.176.864.863a3.173 3.173 0 004.486-4.486z" />
    </svg>
  );
}

export default AttachIcon;
