import { StorageService } from 'lib/utils';
import auth0 from 'auth0-js';
import { config } from 'lib/utils/config';
import { client } from 'lib/apollo-client/client';

export interface SignInOptions {
  email: string;
  password: string;
}

const RESPONSE_TYPE = 'token';

export const auth0WebAuth = new auth0.WebAuth({
  audience: config.AUTH0_AUDIENCE,
  domain: config.AUTH0_DOMAIN,
  responseType: RESPONSE_TYPE,
  clientID: config.AUTH0_CLIENT_ID,
  redirectUri: config.AUTH0_REDIRECT_URI,
  scope: config.AUTH0_SCOPE,
});

export function signIn({ email, password }: SignInOptions) {
  return new Promise((resolve, reject) => {
    auth0WebAuth.login(
      { email, password, realm: config.AUTH0_DB_NAME },
      (error, result) => (error ? reject(error) : resolve(result)),
    );
  });
}

export function refreshAccessToken(hasLocalStorage?: boolean) {
  return new Promise((resolve, reject) => {
    auth0WebAuth.checkSession(
      { clientID: config.AUTH0_CLIENT_ID },
      (error, result) => {
        if (error) return reject(error);

        const newAccessToken = result?.accessToken;
        StorageService.setAccessToken(newAccessToken, hasLocalStorage);

        return resolve(newAccessToken);
      },
    );
  });
}

export async function logout() {
  auth0WebAuth.logout({
    clientID: config.AUTH0_CLIENT_ID,
    returnTo: config.AUTH0_LOGOUT_URI,
  });
  StorageService.removeAccessToken();
  StorageService.removeIsRememberUser();
  await client.clearStore();
}
