import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AdminBillStatusHistoryVariables = Types.Exact<{
  first?: Types.Maybe<Types.Scalars['Int']>;
  after?: Types.Maybe<Types.Scalars['String']>;
  filter?: Types.Maybe<Types.BillStatusHistoryFilter>;
}>;


export type AdminBillStatusHistory = (
  { __typename: 'Query' }
  & { adminBillStatusHistory: (
    { __typename: 'BillStatusHistoryConnection' }
    & { nodes: Array<(
      { __typename: 'BillStatusHistoryModel' }
      & BillStatusHistoryFragment
    )>, pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<Types.PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);

export type BillStatusHistoryFragment = (
  { __typename: 'BillStatusHistoryModel' }
  & Pick<Types.BillStatusHistoryModel, 'id' | 'patientId' | 'therapistId' | 'status' | 'providerReference' | 'res' | 'createdAt' | 'updatedAt' | 'viewedAt'>
);

export const BillStatusHistoryFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BillStatusHistoryFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BillStatusHistoryModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"patientId"}},{"kind":"Field","name":{"kind":"Name","value":"therapistId"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"providerReference"}},{"kind":"Field","name":{"kind":"Name","value":"res"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"viewedAt"}}]}}]} as unknown as DocumentNode;
export const AdminBillStatusHistoryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AdminBillStatusHistory"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"first"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"after"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"BillStatusHistoryFilter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminBillStatusHistory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"Variable","name":{"kind":"Name","value":"first"}}},{"kind":"Argument","name":{"kind":"Name","value":"after"},"value":{"kind":"Variable","name":{"kind":"Name","value":"after"}}},{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"nodes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BillStatusHistoryFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"pageInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hasNextPage"}},{"kind":"Field","name":{"kind":"Name","value":"endCursor"}}]}}]}}]}},...BillStatusHistoryFragment.definitions]} as unknown as DocumentNode;

/**
 * __useAdminBillStatusHistory__
 *
 * To run a query within a React component, call `useAdminBillStatusHistory` and pass it any options that fit your needs.
 * When your component renders, `useAdminBillStatusHistory` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminBillStatusHistory({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminBillStatusHistory(baseOptions?: Apollo.QueryHookOptions<AdminBillStatusHistory, AdminBillStatusHistoryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminBillStatusHistory, AdminBillStatusHistoryVariables>(AdminBillStatusHistoryDocument, options);
      }
export function useAdminBillStatusHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminBillStatusHistory, AdminBillStatusHistoryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminBillStatusHistory, AdminBillStatusHistoryVariables>(AdminBillStatusHistoryDocument, options);
        }
export type AdminBillStatusHistoryHookResult = ReturnType<typeof useAdminBillStatusHistory>;
export type AdminBillStatusHistoryLazyQueryHookResult = ReturnType<typeof useAdminBillStatusHistoryLazyQuery>;
export type AdminBillStatusHistoryQueryResult = Apollo.QueryResult<AdminBillStatusHistory, AdminBillStatusHistoryVariables>;