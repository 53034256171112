import { usePayInsuranceSession } from 'common/mutation/__generated__/pay-insurance-session';
import { useGetFilteredInsuranceSessions } from 'common/query/__generated__/get-filtered-insurance-sessions';
import { useGetInsuranceSessions } from 'common/query/__generated__/get-insurance-sessions';
import { getSize, ISO_DATE_FORMAT, TimeUtil } from 'lib/utils';
import { notifyError } from 'lib/utils/notification';
import React, { useMemo, useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import styled from 'styled-components';
import { Checkbox, Loader } from 'ui';
import { DatesRange } from 'ui/drop-down-calendar/utils/add-day-to-range';
import { SwitchComponent } from 'ui/switch-component';
import { RemittanceStatus } from '__generated__/types';
import { SessionsFilters, SessionsList } from './components';
import PayConfirmationModal from './components/pay-confimation-modal/pay-confirmation-modal';

const InsuranceSessionsPage = () => {
  const [tab, setTab] = useState(1);
  const [isPayingSession, setIsPayingSession] = useState(false);
  const [sessionMetaId, setSessionMetaId] = useState('');
  const [selectedDaysRange, setSelectedDaysRange] = useState<
    DatesRange | undefined
  >();
  const [searchByReference, setSearchByReference] = useState<
    string | undefined
  >();
  const [searchByTherapistName, setSearchByTherapistName] = useState<
    string | undefined
  >();
  const [isUnpaidOnly, setIsUnpaidOnly] = useState(false);

  const [onPayInsuranceSession] = usePayInsuranceSession({
    update(cache) {
      cache.evict({
        fieldName: 'getSessionsNeedTransferForInsurance',
        broadcast: true,
      });
      cache.gc();
    },
  });

  const { data, loading } = useGetInsuranceSessions();

  const toStartDateUtc = selectedDaysRange?.to
    ? selectedDaysRange?.to.toISOString()
    : selectedDaysRange?.from
    ? new Date(
        new Date().setTime(
          selectedDaysRange?.from.getTime() + 24 * 60 * 60 * 1000,
        ),
      ).toISOString()
    : undefined;

  const fromStartDateUtc =
    selectedDaysRange?.from && selectedDaysRange?.to
      ? selectedDaysRange?.from.toISOString()
      : selectedDaysRange?.from
      ? selectedDaysRange?.from.toISOString()
      : undefined;

  const { data: historyData, loading: historyLoading } =
    useGetFilteredInsuranceSessions({
      variables: {
        filter: {
          toStartDateUtc: toStartDateUtc,
          fromStartDateUtc: fromStartDateUtc,
        },
      },
    });

  const historySessions = historyData?.getInsuranceSessions;
  const historySessionsFiltered = historySessions?.filter(
    (session) => session.remittanceStatus === RemittanceStatus.Unpaid,
  );

  const sessions = data?.getSessionsNeedTransferForInsurance;

  const filteredByDate = useMemo(() => {
    if (selectedDaysRange) {
      const { from, to } = selectedDaysRange;
      if (!from && !to) return sessions;
      const fromDayjs =
        from &&
        TimeUtil.parse(from.toISOString(), ISO_DATE_FORMAT).startOf('day');
      const toDayjs =
        to && TimeUtil.parse(to.toISOString(), ISO_DATE_FORMAT).endOf('day');

      return sessions?.filter(({ startDateUtc }) => {
        const startDate = TimeUtil.parse(startDateUtc, ISO_DATE_FORMAT);
        if (fromDayjs && toDayjs) {
          return startDate >= fromDayjs && startDate <= toDayjs;
        } else if (fromDayjs) {
          return startDate >= fromDayjs && startDate <= fromDayjs.endOf('day');
        }
      });
    }
    return sessions;
  }, [sessions, selectedDaysRange]);

  const sortByTherapistName = useMemo(() => {
    if (searchByTherapistName?.trim()) {
      return filteredByDate?.filter(({ therapistPublic: { fullName } }) => {
        return fullName.includes(searchByTherapistName);
      });
    }
    return filteredByDate;
  }, [filteredByDate, searchByTherapistName]);

  const sortByReference = useMemo(() => {
    if (searchByReference?.trim()) {
      return sortByTherapistName?.filter(({ invoiceNo }) => {
        return invoiceNo?.includes(searchByReference);
      });
    }
    return sortByTherapistName;
  }, [sortByTherapistName, searchByReference]);

  const handlePaySession = () => {
    setIsPayingSession(true);

    onPayInsuranceSession({
      variables: {
        sessionMetaId,
      },
    })
      .then((response) => {
        const validateErrors =
          response.data?.adminPayForInsuranceSession.userErrors;
        if (validateErrors?.length) {
          validateErrors.forEach(({ field, messages }) =>
            notifyError({
              title: field,
              text: messages[0],
            }),
          );
        }
      })
      .finally(() => {
        setIsPayingSession(false);
        setSessionMetaId('');
      });
  };

  enum SwitchVariants {
    CURRENT = 'Current',
    HISTORY = 'History',
  }
  const switchVariants = ['Current', 'History'];
  const switchSelect = switchVariants[tab - 1];

  if (loading) {
    return <Loader hasFillWholeBlock size={55} />;
  }

  return (
    <Wrapper>
      <SwitchLine>
        <SwitchComponentStyled
          variants={switchVariants}
          tabNumber={tab}
          setTabNumber={setTab}
        />
        {switchSelect === SwitchVariants.HISTORY && (
          <Form
            onSubmit={() => undefined}
            render={({ values }) => (
              <>
                <FormSpy
                  onChange={({ values }) => {
                    setIsUnpaidOnly(!!values?.unpaid);
                  }}
                />

                <Field
                  name="unpaid"
                  type="checkbox"
                  label="Unpaid Only"
                  component={Checkbox}
                />
              </>
            )}
          />
        )}
      </SwitchLine>

      <SessionsFilters
        selectedDaysRange={selectedDaysRange}
        setSelectedDaysRange={setSelectedDaysRange}
        onSearchByReference={setSearchByReference}
        onSearchByTherapistName={setSearchByTherapistName}
      />

      {switchSelect === SwitchVariants.CURRENT && (
        <SessionsList
          sessions={sortByReference}
          onPaySession={setSessionMetaId}
        />
      )}

      {switchSelect === SwitchVariants.HISTORY && (
        <SessionsList
          sessions={isUnpaidOnly ? historySessionsFiltered : historySessions}
          onPaySession={setSessionMetaId}
          isPayButtonHide
        />
      )}
      <PayConfirmationModal
        isVisible={Boolean(sessionMetaId)}
        isLoading={isPayingSession}
        onClose={() => setSessionMetaId('')}
        onPay={handlePaySession}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: ${getSize(50)} ${getSize(56)};
`;

const SwitchComponentStyled = styled(SwitchComponent)`
  margin: 30px 0 30px 0;
`;

const SwitchLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default InsuranceSessionsPage;
