import { useStripe } from '@stripe/react-stripe-js';
import { useEditClinicCredentials } from 'common/mutation/__generated__/edit-clinic-credentials';
import { useAdminGetBankAccount } from 'common/query/__generated__/admin-get-bank-account';
import { useAdminSetUpBankAccount } from 'common/query/__generated__/admin-set-up-bank-account';
import { useGetClinicCredentials } from 'common/query/__generated__/get-clinic-credentials';
import { InsuranceForm } from 'components/insurance-form';
import { InsuranceFormValues } from 'components/insurance-form/insurance-form';
import { getSize } from 'lib/utils';
import { getFormSubmitError } from 'lib/utils/form';
import { notifyError, notifySuccess } from 'lib/utils/notification';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, Loader, ShadowWrapper } from 'ui';

const InsurancePage = () => {
  const [isLoading, setIsLoading] = useState(false);

  const stripe = useStripe();

  const { data: bankAccountData, loading: isLoadingBankAccount } =
    useAdminGetBankAccount();
  const paymentMethod = bankAccountData?.adminGetBankAccount.paymentMethod;

  const { data: checkoutSessionData, loading: isLoadingCheckoutSessionData } =
    useAdminSetUpBankAccount();
  const checkoutSessionId = checkoutSessionData?.adminSetUpBankAccount;

  const handleRedirectToStripe = useCallback(() => {
    if (checkoutSessionId && stripe) {
      stripe
        .redirectToCheckout({
          sessionId: checkoutSessionId,
        })
        .then((response) => {
          const error = response.error;
          if (error) {
            notifySuccess({
              title: error.type,
              text: error.message,
            });
          }
        });
    }
  }, [checkoutSessionId, stripe]);

  const { data: credentialsData, loading: isLoadingCredentials } =
    useGetClinicCredentials();

  const adminClinicCredentials = credentialsData?.adminClinicCredentials;
  const [editClinicCredentials] = useEditClinicCredentials();

  const handleSubmit = (values: InsuranceFormValues) => {
    const providerId = values.providerId;
    if (providerId) {
      setIsLoading(true);
      return editClinicCredentials({
        variables: {
          input: {
            ...values,
            insuranceLogin: values.insuranceLogin || '',
            providerId,
          },
        },
      })
        .then((response) => {
          const validateErrors =
            response.data?.adminClinicCredentialsEdit.userErrors;

          if (validateErrors?.length) {
            validateErrors.forEach(
              ({ field, messages }) =>
                field === 'base' && notifyError({ text: messages[0] }),
            );
            return getFormSubmitError(validateErrors);
          } else {
            notifySuccess({
              title: 'Insurance credentials',
              text: 'Has been edited successfully!',
            });
          }
        })
        .finally(() => setIsLoading(false));
    }
  };

  const initialsValues = useMemo(() => {
    if (adminClinicCredentials) {
      const { password, providerId, siteId, username, insuranceLogin } =
        adminClinicCredentials;

      return {
        password,
        providerId,
        siteId,
        username,
        insuranceLogin,
      };
    }
  }, [adminClinicCredentials]);

  if (
    isLoadingCredentials ||
    isLoadingBankAccount ||
    isLoadingCheckoutSessionData
  ) {
    return <Loader hasFillWholeBlock size={55} />;
  }

  return (
    <Wrapper>
      <InsuranceForm
        onSubmit={handleSubmit}
        initialValues={initialsValues}
        isLoading={isLoading}
      />
      <BankAccountWrapper>
        <Title>Bank account</Title>
        <BankAccountButton onClick={handleRedirectToStripe} theme="primary">
          {paymentMethod ? 'Edit account' : 'Add account'}
        </BankAccountButton>
      </BankAccountWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: ${getSize(50)} ${getSize(56)};
`;

const BankAccountWrapper = styled(ShadowWrapper)`
  padding: ${getSize(34)} ${getSize(32)};
  margin-bottom: ${getSize(40)};
`;

const Title = styled.p`
  ont-weight: 600;
  font-size: ${getSize(24)};
  line-height: ${getSize(36)};
  margin-bottom: ${getSize(25)};
`;

const BankAccountButton = styled(Button)`
  min-width: ${getSize(282)};
`;

export default InsurancePage;
