import React, { UIEvent } from 'react';
import styled from 'styled-components';

import { getSize } from 'lib/utils';
import { ChatFragment } from '../../query/__generated__/chats-list';

import { WriteMessageButton, ChatItem } from './components';
import { DoubleArrowIcon, Loader } from 'ui';
import { getScrollPosition } from '../../utils';

export interface SideBarProps {
  onWriteMessageClick?: () => void;
  chats: ChatFragment[];
  selectedChatId?: string;
  isExpanded: boolean;
  onExpandClick: () => void;
  isChatsLoadaing: boolean;
  onChatClick: (id: string) => void;
  fetchMoreChats: () => void;
  hasMoreChats?: boolean;
  isFetchingNewChats: boolean;
}

function SideBar({
  onWriteMessageClick,
  chats,
  selectedChatId,
  isExpanded,
  onExpandClick,
  isChatsLoadaing,
  onChatClick,
  fetchMoreChats,
  hasMoreChats,
  isFetchingNewChats,
}: SideBarProps) {
  function handleScroll({
    currentTarget: chatsList,
  }: UIEvent<HTMLUListElement>) {
    if (isFetchingNewChats) return;

    const { isOnBottom } = getScrollPosition(chatsList);

    if (hasMoreChats && isOnBottom) {
      fetchMoreChats();
    }
  }

  return (
    <Wrapper $isExpanded={isExpanded}>
      <Header>
        <Title $isExpanded={isExpanded}>Conversations</Title>
        <WriteMessageButton
          isExpanded={isExpanded}
          onClick={onWriteMessageClick}
        />
        <ExpandButton $isExpanded={isExpanded} onClick={onExpandClick}>
          <span className="visually-hidden">Expand interlocutor list</span>
          <Icon className="expand-icon" />
        </ExpandButton>
      </Header>

      {isChatsLoadaing ? (
        <Loader size={50} hasFillWholeBlock />
      ) : (
        <ChatsWrapper>
          <ChatsList onScroll={handleScroll}>
            {chats?.map((chat) => {
              const chatId = chat.id;

              return (
                <ChatItem
                  key={chatId}
                  chat={chat}
                  isExpanded={isExpanded}
                  onClick={() => onChatClick(chatId)}
                  isActive={chatId === selectedChatId}
                />
              );
            })}
          </ChatsList>
        </ChatsWrapper>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ $isExpanded: boolean; $isTherapist?: boolean }>`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: ${getSize(22)} 0 0;
  background: var(--purple11);
  width: ${({ $isExpanded }) => getSize($isExpanded ? 90 : 378)};
  transition: 0.3s ease-out;
  overflow: hidden;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  margin: 0 0 ${getSize(10)};
  padding: 0 ${getSize(24)};
  height: ${getSize(20)};
`;

const Title = styled.h1<{ $isExpanded: boolean }>`
  font-weight: 600;
  font-size: ${getSize(22)};
  line-height: ${getSize(33)};
  ${({ $isExpanded }) => $isExpanded && 'opacity: 0;'}
  transition: 0.3s ease-out;
`;

const ExpandButton = styled.button<{ $isExpanded: boolean }>`
  position: absolute;
  right: 0;
  transition: 0.3s ease-out;
  ${({ $isExpanded }) => $isExpanded && `transform: scale(-1);`}

  &:hover {
    .expand-icon {
      fill: var(--purple);
    }
  }
`;

const Icon = styled(DoubleArrowIcon)`
  transition: 0.3s ease-out;
`;

const ChatsWrapper = styled.div`
  height: 100%;
  overflow: hidden;
`;

const ChatsList = styled.ul`
  padding: ${getSize(10)} 0 0;
  height: 100%;
  overflow-y: overlay;

  @supports not (overflow-y: overlay) {
    overflow-y: auto;
  }
`;

export default SideBar;
