import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useRedirect } from '../../hooks/index';

export type IPrivateRouteProps = RouteProps;

const PrivateRoute: React.FC<IPrivateRouteProps> = (props) => {
  useRedirect();

  return <Route {...props} />;
};

export default PrivateRoute;
