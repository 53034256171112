import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AdminBillSubmitHistoryVariables = Types.Exact<{
  first?: Types.Maybe<Types.Scalars['Int']>;
  after?: Types.Maybe<Types.Scalars['String']>;
  filter?: Types.Maybe<Types.BillValidateHistoryFilter>;
}>;


export type AdminBillSubmitHistory = (
  { __typename: 'Query' }
  & { adminBillSubmitHistory: (
    { __typename: 'BillSubmitHistoryConnection' }
    & { nodes: Array<(
      { __typename: 'BillSubmitHistoryModel' }
      & BillSubmitHistoryFragment
    )>, pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<Types.PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);

export type BillSubmitHistoryFragment = (
  { __typename: 'BillSubmitHistoryModel' }
  & Pick<Types.BillSubmitHistoryModel, 'id' | 'patientId' | 'therapistId' | 'req' | 'res' | 'startDateUtc' | 'endDateUtc' | 'isSuccess' | 'createdAt' | 'updatedAt' | 'viewedAt'>
);

export const BillSubmitHistoryFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BillSubmitHistoryFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BillSubmitHistoryModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"patientId"}},{"kind":"Field","name":{"kind":"Name","value":"therapistId"}},{"kind":"Field","name":{"kind":"Name","value":"req"}},{"kind":"Field","name":{"kind":"Name","value":"res"}},{"kind":"Field","name":{"kind":"Name","value":"startDateUtc"}},{"kind":"Field","name":{"kind":"Name","value":"endDateUtc"}},{"kind":"Field","name":{"kind":"Name","value":"isSuccess"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"viewedAt"}}]}}]} as unknown as DocumentNode;
export const AdminBillSubmitHistoryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AdminBillSubmitHistory"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"first"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"after"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"BillValidateHistoryFilter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminBillSubmitHistory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"Variable","name":{"kind":"Name","value":"first"}}},{"kind":"Argument","name":{"kind":"Name","value":"after"},"value":{"kind":"Variable","name":{"kind":"Name","value":"after"}}},{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"nodes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BillSubmitHistoryFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"pageInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hasNextPage"}},{"kind":"Field","name":{"kind":"Name","value":"endCursor"}}]}}]}}]}},...BillSubmitHistoryFragment.definitions]} as unknown as DocumentNode;

/**
 * __useAdminBillSubmitHistory__
 *
 * To run a query within a React component, call `useAdminBillSubmitHistory` and pass it any options that fit your needs.
 * When your component renders, `useAdminBillSubmitHistory` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminBillSubmitHistory({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminBillSubmitHistory(baseOptions?: Apollo.QueryHookOptions<AdminBillSubmitHistory, AdminBillSubmitHistoryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminBillSubmitHistory, AdminBillSubmitHistoryVariables>(AdminBillSubmitHistoryDocument, options);
      }
export function useAdminBillSubmitHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminBillSubmitHistory, AdminBillSubmitHistoryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminBillSubmitHistory, AdminBillSubmitHistoryVariables>(AdminBillSubmitHistoryDocument, options);
        }
export type AdminBillSubmitHistoryHookResult = ReturnType<typeof useAdminBillSubmitHistory>;
export type AdminBillSubmitHistoryLazyQueryHookResult = ReturnType<typeof useAdminBillSubmitHistoryLazyQuery>;
export type AdminBillSubmitHistoryQueryResult = Apollo.QueryResult<AdminBillSubmitHistory, AdminBillSubmitHistoryVariables>;