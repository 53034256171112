import React from 'react';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import './styles/index.css';
import { ApolloProvider } from '@apollo/client';
import { ToastContainer } from 'react-toastify';
import { client } from './lib/apollo-client/client';
import RootRoutes from './routes';
import 'react-toastify/dist/ReactToastify.css';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { config } from 'lib/utils';
import 'react-day-picker/lib/style.css';
import 'emoji-mart/css/emoji-mart.css';

console.log('Config', config);

function App() {
  const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY);

  return (
    <ApolloProvider client={client}>
      <Elements stripe={stripePromise}>
        <RootRoutes />
        <ToastContainer />
      </Elements>
    </ApolloProvider>
  );
}

export default App;
