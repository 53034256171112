import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetInsuranceSessionsVariables = Types.Exact<{ [key: string]: never; }>;


export type GetInsuranceSessions = (
  { __typename: 'Query' }
  & { getSessionsNeedTransferForInsurance: Array<(
    { __typename: 'AdminInsuranceSessionModel' }
    & SessionNeedTransferModelFragment
  )> }
);

export type SessionNeedTransferModelFragment = (
  { __typename: 'AdminInsuranceSessionModel' }
  & Pick<Types.AdminInsuranceSessionModel, 'id' | 'startDateUtc' | 'sessionType' | 'providerReference' | 'invoiceNo' | 'billStatus' | 'amountToPay' | 'remittanceStatus'>
  & { therapistPublic: (
    { __typename: 'TherapistPublicModel' }
    & Pick<Types.TherapistPublicModel, 'id' | 'fullName' | 'professionalTitle' | 'avatarUrl'>
  ), patientConnected: (
    { __typename: 'PatientConnectedModel' }
    & Pick<Types.PatientConnectedModel, 'id' | 'firstName' | 'lastName'>
    & { avatar?: Types.Maybe<(
      { __typename: 'UserPublicFileModel' }
      & Pick<Types.UserPublicFileModel, 'source'>
    )>, insuranceCompany?: Types.Maybe<(
      { __typename: 'InsuranceCompanyModel' }
      & Pick<Types.InsuranceCompanyModel, 'id' | 'logoRoundUrl'>
    )> }
  ) }
);

export const SessionNeedTransferModelFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SessionNeedTransferModelFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AdminInsuranceSessionModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"startDateUtc"}},{"kind":"Field","name":{"kind":"Name","value":"sessionType"}},{"kind":"Field","name":{"kind":"Name","value":"therapistPublic"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"fullName"}},{"kind":"Field","name":{"kind":"Name","value":"professionalTitle"}},{"kind":"Field","name":{"kind":"Name","value":"avatarUrl"}}]}},{"kind":"Field","name":{"kind":"Name","value":"patientConnected"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"avatar"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"source"}}]}},{"kind":"Field","name":{"kind":"Name","value":"insuranceCompany"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"logoRoundUrl"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"providerReference"}},{"kind":"Field","name":{"kind":"Name","value":"invoiceNo"}},{"kind":"Field","name":{"kind":"Name","value":"billStatus"}},{"kind":"Field","name":{"kind":"Name","value":"amountToPay"}},{"kind":"Field","name":{"kind":"Name","value":"remittanceStatus"}}]}}]} as unknown as DocumentNode;
export const GetInsuranceSessionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetInsuranceSessions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getSessionsNeedTransferForInsurance"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SessionNeedTransferModelFragment"}}]}}]}},...SessionNeedTransferModelFragment.definitions]} as unknown as DocumentNode;

/**
 * __useGetInsuranceSessions__
 *
 * To run a query within a React component, call `useGetInsuranceSessions` and pass it any options that fit your needs.
 * When your component renders, `useGetInsuranceSessions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInsuranceSessions({
 *   variables: {
 *   },
 * });
 */
export function useGetInsuranceSessions(baseOptions?: Apollo.QueryHookOptions<GetInsuranceSessions, GetInsuranceSessionsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInsuranceSessions, GetInsuranceSessionsVariables>(GetInsuranceSessionsDocument, options);
      }
export function useGetInsuranceSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInsuranceSessions, GetInsuranceSessionsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInsuranceSessions, GetInsuranceSessionsVariables>(GetInsuranceSessionsDocument, options);
        }
export type GetInsuranceSessionsHookResult = ReturnType<typeof useGetInsuranceSessions>;
export type GetInsuranceSessionsLazyQueryHookResult = ReturnType<typeof useGetInsuranceSessionsLazyQuery>;
export type GetInsuranceSessionsQueryResult = Apollo.QueryResult<GetInsuranceSessions, GetInsuranceSessionsVariables>;