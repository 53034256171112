export type AddressComponentName = 'country' | 'city' | 'postcode';

const ADDRESS_COMPONENTS = {
  country: 'country',
  city: 'locality',
  postcode: 'postal_code',
};

export function getAddressComponentByName(
  components: google.maps.GeocoderAddressComponent[] = [],
  name: AddressComponentName,
) {
  return (
    components.find((component) =>
      component.types.includes(ADDRESS_COMPONENTS[name]),
    )?.long_name || ''
  );
}
