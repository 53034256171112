import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isoWeek from 'dayjs/plugin/isoWeek';
import isBetween from 'dayjs/plugin/isBetween';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isYesterday from 'dayjs/plugin/isYesterday';
import localeData from 'dayjs/plugin/localeData';
import duration from 'dayjs/plugin/duration';

dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isoWeek);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isYesterday);
dayjs.extend(localeData);
dayjs.extend(duration);

export class TimeUtil {
  static validate(param: string, format: string) {
    const currentDate = dayjs(param, format);
    const isValid = currentDate.format(format) === param;
    if (!isValid) {
      throw new Error(
        `Invalid date string ${param} for format ${format}. Date to compare: ${currentDate.toISOString()}`,
      );
    }
  }

  static now() {
    return dayjs();
  }

  static getStartOFNowDay() {
    return dayjs().startOf('date');
  }

  static getStartOfDay(param: string, format: string) {
    this.validate(param, format);
    return dayjs(param).startOf('day');
  }

  static parse(param: string, format: string) {
    this.validate(param, format);
    return dayjs(param);
  }

  static parseWithZone(param: string, tz: string, format: string) {
    this.validate(param, format);
    return dayjs(param).tz(tz);
  }

  static add(
    date: string | Date,
    { value, unit }: { value: number; unit: dayjs.ManipulateType },
  ) {
    return dayjs(date).add(value, unit);
  }

  static getClientTimezone() {
    return dayjs.tz.guess();
  }

  static getYear(date: string | Date) {
    return dayjs(date).year();
  }

  static getMonth(date: string | Date) {
    return dayjs(date).month();
  }

  static getDate(date: string | Date) {
    return dayjs(date).date();
  }

  static getDay(date: string | Date) {
    return dayjs(date).day();
  }

  static getHour(date: string | Date) {
    return dayjs(date).hour();
  }

  static getFormatted(date: string | Date, format?: string) {
    return dayjs(date).format(format);
  }

  static getNumberOfDaysInMonth(params: string) {
    return dayjs(params).daysInMonth();
  }

  static getDiffOfYears(firstYear: Date | string, secondYear: Date | string) {
    return dayjs(firstYear).diff(secondYear, 'year');
  }

  static getDiffOfMinutes(firstDate: Date | string, secondDate: Date | string) {
    return dayjs(secondDate).diff(firstDate, 'minute');
  }

  static getDiffOfMilliseconds(
    firstDate: Date | string,
    secondDate: Date | string,
  ) {
    return dayjs(secondDate).diff(firstDate, 'millisecond');
  }

  static monthsShort() {
    return dayjs().localeData().monthsShort();
  }

  /**
   * @description Method will return start and end of last month in format if provided.
   */
  static getLastMonthStartAndEnd(format?: string) {
    const today = dayjs();
    const lastMonth = today.subtract(1, 'month');
    const startOfMonth = lastMonth.startOf('month');
    const endOfMonth = lastMonth.endOf('month');

    if (format) {
      const formattedStartOfMonth = startOfMonth.format(format);
      const formattedEndOfMonth = endOfMonth.format(format);
      return {
        start: formattedStartOfMonth,
        end: formattedEndOfMonth,
      };
    } else {
      const start = lastMonth.startOf('month').toISOString();
      const end = lastMonth.endOf('month').toISOString();
      return { start, end };
    }
  }

  /**
   * @description Method will start and end of previus 3 months.
   */
  static getLastQuarterStartAndEnd(format?: string): {
    start: string;
    end: string;
  } {
    const now = dayjs();

    // Calculate the start date of the previous quarter
    const quarterStart = now
      .subtract((now.month() % 3) + 3, 'month')
      .startOf('month')
      .toISOString();

    // Calculate the end date of the previous quarter
    const quarterEnd = now
      .subtract(now.month() % 3, 'month')
      .endOf('month')
      .toISOString();

    // Format the dates if a format string is provided
    if (format) {
      return {
        start: dayjs(quarterStart).format(format),
        end: dayjs(quarterEnd).format(format),
      };
    }

    return {
      start: quarterStart,
      end: quarterEnd,
    };
  }

  static getLastYearStartAndEnd(format?: string) {
    const lastYearStart = dayjs().subtract(1, 'year').startOf('year');
    const lastYearEnd = dayjs().subtract(1, 'year').endOf('year');
    if (format) {
      return {
        start: lastYearStart.format(format),
        end: lastYearEnd.format(format),
      };
    }
    return {
      start: lastYearStart.toISOString(),
      end: lastYearEnd.toISOString(),
    };
  }
}
