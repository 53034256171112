import React from 'react';

function ThreeDotsIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      stroke="#444752"
      viewBox="0 0 22 22"
      {...rest}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11 11.917a.917.917 0 100-1.834.917.917 0 000 1.834zM17.417 11.917a.917.917 0 100-1.833.917.917 0 000 1.833zM4.583 11.917a.917.917 0 100-1.834.917.917 0 000 1.834z"
      />
    </svg>
  );
}

export default ThreeDotsIcon;
