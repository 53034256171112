import React, { useState, useCallback, useMemo } from 'react';
import {
  useAdminTherapistSearch,
  TherapistFragment,
} from 'common/query/__generated__/get-therapists-by-request';
import { Paper } from 'ui/paper';
import {
  OrderByDirection,
  TherapistAdminSort,
  TherapistRequestStatus,
} from '__generated__/types';
import { useDebounceCallback } from '@react-hook/debounce';

import { TherapistsList } from 'components/therapists-list';
import { useGetFutureUpcomingSessionCountExtended } from '../../common/query/__generated__/get-future-session-count-extended';

const MAX_VISIBLE_THERAPISTS = 10;

const TherapistsPage = () => {
  const [filter, setFilter] = useState('');

  const { data: futureSessionCountExtended } =
    useGetFutureUpcomingSessionCountExtended();

  const therapistSessionCountExtended =
    futureSessionCountExtended?.futureUpcomingSessionCountExtended
      ? futureSessionCountExtended?.futureUpcomingSessionCountExtended
      : [0, 0, 0, 0];

  const [sortOption, setSortOption] = useState(TherapistAdminSort.UpdatedAt);
  const [sortDirection, setSortDirection] = useState(OrderByDirection.Desc);

  const therapistsByRequestQuery = useAdminTherapistSearch({
    variables: {
      first: MAX_VISIBLE_THERAPISTS,
      filter: { status: TherapistRequestStatus.Accepted, searchName: filter },
      sort: {
        sortOption: sortOption,
        direction: sortDirection,
      },
    },
  });

  const therapistsList: TherapistFragment[] = useMemo(
    () => therapistsByRequestQuery.data?.adminTherapistsSearch.nodes || [],
    [therapistsByRequestQuery.data?.adminTherapistsSearch.nodes],
  );

  const handleSearchMessageFormSubmit = useCallback((searchText?: string) => {
    const searchPhrase = searchText?.trim() || '';
    setFilter(searchPhrase);
  }, []);

  const debounceHandleSearchFormSubmit = useDebounceCallback(
    handleSearchMessageFormSubmit,
    250,
  );

  return (
    <Paper searchRequest={debounceHandleSearchFormSubmit} title="List">
      <TherapistsList
        dataLength={therapistsList.length}
        onNext={() =>
          therapistsByRequestQuery.fetchMore({
            variables: {
              filter: {
                status: TherapistRequestStatus.Accepted,
                searchName: filter,
              },
              after:
                therapistsByRequestQuery.data?.adminTherapistsSearch.pageInfo
                  .endCursor,
            },
          })
        }
        hasMore={Boolean(
          therapistsByRequestQuery.data?.adminTherapistsSearch.pageInfo
            .hasNextPage,
        )}
        therapists={therapistsList}
        isLoading={therapistsByRequestQuery.loading}
        futureSessionCountExtended={therapistSessionCountExtended}
        setSortOption={setSortOption}
        setSortDirection={setSortDirection}
        sortDirection={sortDirection}
        sortOption={sortOption}
      />
    </Paper>
  );
};

export default TherapistsPage;
