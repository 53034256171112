import React from 'react';
import styled from 'styled-components';
import { getSize } from 'lib/utils';
import { UserSecondaryIcon } from 'ui';

function EmptyBlock() {
  return (
    <Wrapper>
      <Icon />
      <Text>No people with that name found</Text>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Icon = styled(UserSecondaryIcon)`
  margin: 0 0 ${getSize(16)};
`;

const Text = styled.p`
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--gray7);
`;

export default EmptyBlock;
