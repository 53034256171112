import { getSize, validationRules } from 'lib/utils';
import React, { FC, useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import {
  NewPostInput,
  ResourcesCategoryDetailsModel,
  ResourcesPostTypeEnum,
} from '__generated__/types';

import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import { SingleSelect } from 'ui/form-elements/single-select';
import { bookingSelectStyles } from 'ui/form-elements/single-select/utils/styles';
import { Input } from 'ui';
import button from 'ui/button/button';
import { transformEnumToOptions } from 'lib/utils/form';

import { UploadMaterialButton } from '../../../common/upload-material-modal/components';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from 'draft-js';
import { notifyError, notifySuccess } from '../../../lib/utils/notification';
import { useGetCategoriesAndSubcategories } from '../../../common/query/__generated__/resources-get-categories-and-subcategories';

/** Mutations */
import { useCreateNewPost } from '../../business-page/mutation/__generated__/admin-create-new-post';
import { useEditPost } from '../../business-page/mutation/__generated__/admin-edit-post';

/** Editor */
import Editor from './editor';

/** PostPreview */
import PostPreview from './post-preview';

export interface ResourceFormValues {
  resourceType: ResourcesPostTypeEnum;
  resourceCategory: string;
  resourceSubCategories: HTMLOptionElement[];
  resourceSubCategoryOption: HTMLOptionElement;
  resourceSubCategoryId: string;
  resourceSubCategoryName: string;
  title: string;
  subtitle: string;
  author: string;
  imageUrl: string;
  videoUrl: string;
  postText: string;
  imageUrlVal: string;
  videoUrlVal: string;
  editorState: any;
}

const INPUT_CSS = css`
  border: 1px solid var(--gray19);
  border-radius: ${getSize(8)};
  padding: ${getSize(6)} ${getSize(12)};
  min-height: ${getSize(47)};
  font-weight: 500;
  font-size: ${getSize(13)};
  line-height: ${getSize(24)};
  overflow: visible;

  transition: 0.3s ease-out;

  &:focus {
    border-color: var(--purple);
  }

  &:hover:not(:focus) {
    border-color: var(--black10);
  }

  &:-webkit-autofill:focus {
    transition: 0.3s ease-out;
    border: 1px solid var(--purple) !important;
  }
  &:-webkit-autofill:hover:not(:-webkit-autofill:focus) {
    transition: 0.3s ease-out;
    border: 1px solid var(--black10) !important;
  }
  &:-webkit-autofill {
    transition: 0.3s ease-out;
    border: 1px solid var(--gray36) !important;
  }
`;

const ROOT_CSS = css`
  margin-bottom: ${getSize(10)};
`;

const ROOT_UPLOAD_INPUT_CSS = css`
  margin-bottom: ${getSize(10)};
  width: 80%;
`;

const ROOT_UPLOAD_BUTTON_CSS = css`
  margin-top: ${getSize(5)};
  width: 20%;
  margin-left: ${getSize(10)};
`;

interface AddResourcesFormProps {
  resourceToEdit: any;
  refetchResources: () => void;
  onCloseModal: () => void;
}

export const AddResourcesForm: FC<AddResourcesFormProps> = ({
  onCloseModal,
  resourceToEdit,
  refetchResources,
}) => {
  /** STATE DATA */
  const [postText, setPostText] = useState(
    resourceToEdit ? resourceToEdit.postText : '',
  );
  const [previewMode, setPreviewMode] = useState(false);

  const [addNewPost, { loading: addNewPostLoading }] = useCreateNewPost();
  const [editPost, { loading: editPostLoading }] = useEditPost();
  const categoriesAndSubCategories = useGetCategoriesAndSubcategories();

  const categories: HTMLOptionElement[] =
    categoriesAndSubCategories.data?.getCategoriesAndSubcategories.map(
      (category) => new Option(category.name, category.id, false, false),
    ) || [];

  const subcategories = (categoryId: string) => {
    const category: ResourcesCategoryDetailsModel | undefined =
      categoriesAndSubCategories.data?.getCategoriesAndSubcategories?.find(
        (category) => category.id === categoryId,
      );

    return (
      category?.subCategories.map(
        (subcategory) =>
          new Option(subcategory.name, subcategory.id, false, false),
      ) || []
    );
  };

  const handleFormSubmit = async (values: ResourceFormValues) => {
    const newPostData: any =
      values.resourceType === ResourcesPostTypeEnum.Text
        ? {
            subCategoryId: values.resourceSubCategoryOption,
            author: values.author,
            title: values.title,
            subtitle: values.subtitle,
            imageUrl: values.imageUrlVal,
            postText: postText,
            type: ResourcesPostTypeEnum.Text,
          }
        : {
            subCategoryId: values.resourceSubCategoryId,
            author: values.author,
            title: values.title,
            subtitle: values.subtitle,
            imageUrl: values.imageUrlVal,
            videoUrl: values.videoUrlVal,
            type: ResourcesPostTypeEnum.Video,
          };

    try {
      if (resourceToEdit) {
        await editPost({
          variables: {
            id: resourceToEdit.id,
            input: newPostData,
          },
        });
      } else {
        await addNewPost({
          variables: {
            input: newPostData,
          },
        });
      }

      notifySuccess({
        title: 'Resource',
        text: 'Resource has been saved successfully!',
      });
    } catch (e: any) {
      notifyError({
        title: 'Error',
        text: e.message,
      });
    } finally {
      refetchResources();
      onCloseModal();
    }
  };

  const onEditorContentChanged = (content: any) => {
    setPostText(draftToHtml(convertToRaw(content.getCurrentContent())));
  };

  const getInitialValues = () => {
    if (!resourceToEdit) {
      return null;
    }

    /** Find category & subcategory id */
    let categoryId = '';
    let subcategoryId = '';
    categoriesAndSubCategories.data?.getCategoriesAndSubcategories.forEach(
      (category) => {
        category.subCategories.forEach((subCategory) => {
          if (subCategory.id === resourceToEdit.subCategory.id) {
            categoryId = category.id;
            subcategoryId = subCategory.id;
          }
        });
      },
    );

    /** If post is text post make state for editor
     *  TODO: htmlToDraft function is to blame for everything bad
     */
    let editorState = null;
    if (resourceToEdit.type === ResourcesPostTypeEnum.Text) {
      const contentBlock = htmlToDraft(resourceToEdit.postText);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      editorState = EditorState.createWithContent(contentState);
    }

    return {
      title: resourceToEdit.title,
      subtitle: resourceToEdit.subtitle,
      author: resourceToEdit.author,
      imageUrlVal: resourceToEdit.imageUrl,
      imageUrl: resourceToEdit.imageUrl,
      resourceCategory: categoryId,
      resourceSubCategories: subcategories(categoryId),
      resourceSubCategoryOption: subcategoryId,
      resourceSubCategoryId: subcategoryId,
      resourceType: resourceToEdit.type,
      videoUrlVal: resourceToEdit.videoUrl,
      videoUrl: resourceToEdit.videoUrl,
      editorState: editorState,
    };
  };

  const renderActionButton = (handleSubmit: any, values: any) => {
    if (values.resourceType === ResourcesPostTypeEnum.Text && !previewMode) {
      return (
        <CreateCompanyButton
          isLoading={false}
          onClick={() => setPreviewMode(true)}>
          Preview
        </CreateCompanyButton>
      );
    } else if (
      values.resourceType === ResourcesPostTypeEnum.Text &&
      previewMode
    ) {
      return (
        <>
          <CreateCompanyButton
            onClick={() => setPreviewMode(false)}
            isLoading={false}>
            Back
          </CreateCompanyButton>
          <Spacer />
          <CreateCompanyButton
            onClick={handleSubmit}
            isLoading={addNewPostLoading || editPostLoading}>
            {resourceToEdit ? 'Update' : 'Create'}
          </CreateCompanyButton>
        </>
      );
    }

    return (
      <CreateCompanyButton
        onClick={handleSubmit}
        isLoading={addNewPostLoading || editPostLoading}>
        {resourceToEdit ? 'Update' : 'Create'}
      </CreateCompanyButton>
    );
  };

  const renderPostPreview = (values: any) => {
    if (previewMode) {
      const title = values.title;
      return (
        <PostPreviewAbsoluteContainer>
          <PostPreview postText={postText} title={title} />
        </PostPreviewAbsoluteContainer>
      );
    }

    return null;
  };

  return (
    <FinalForm
      keepDirtyOnReinitialize
      onSubmit={handleFormSubmit}
      initialValues={getInitialValues()}
      render={({ handleSubmit, hasValidationErrors, dirty, values, form }) => (
        <Wrapper>
          <div className="container g-0">
            <FormSpy
              onChange={({ values }) => {
                if (values.imageUrl) {
                  form.change(
                    'imageUrlVal',
                    values.imageUrl[0].source || values.imageUrl,
                  );
                }

                if (values.videoUrl) {
                  form.change(
                    'videoUrlVal',
                    values.videoUrl[0].source || values.videoUrl,
                  );
                }
              }}
            />
            <div className="row">
              <div className="col-md-4 mt-4">
                <Field
                  name="resourceCategory"
                  label="Resource category"
                  customSelectStyles={bookingSelectStyles}
                  isSearchable={false}
                  rootCSS={ROOT_CSS}
                  inputCSS={INPUT_CSS}
                  hasLine={false}
                  validate={validationRules.required}
                  render={({ input, meta, ref, ...outerProps }) => {
                    const onChange = (resourceCategoryId: string) => {
                      values.resourceSubCategories =
                        subcategories(resourceCategoryId);
                      form.change('resourceCategory', resourceCategoryId);
                    };
                    return (
                      <SingleSelect
                        selectRef={ref}
                        options={categories}
                        input={{ ...input, onChange }}
                        meta={meta}
                        {...outerProps}
                      />
                    );
                  }}
                />
              </div>
              <div className="col-md-4 mt-4">
                <Field
                  name="resourceSubCategoryOption"
                  label="Resource subcategory"
                  customSelectStyles={bookingSelectStyles}
                  isSearchable={false}
                  rootCSS={ROOT_CSS}
                  inputCSS={INPUT_CSS}
                  hasLine={false}
                  validate={validationRules.required}
                  render={({ input, meta, ref, ...outerProps }) => {
                    const onChange = (e: HTMLOptionElement) => {
                      form.change('resourceSubCategoryOption', e);
                      form.change('resourceSubCategoryId', e.toString());
                    };
                    return (
                      <SingleSelect
                        selectRef={ref}
                        options={values.resourceSubCategories}
                        input={{ ...input, onChange }}
                        meta={meta}
                        {...outerProps}
                      />
                    );
                  }}
                />
              </div>
              <div className="col-md-4 mt-4">
                <Field
                  name="resourceType"
                  label="Resource type"
                  customSelectStyles={bookingSelectStyles}
                  isSearchable={false}
                  rootCSS={ROOT_CSS}
                  inputCSS={INPUT_CSS}
                  hasLine={false}
                  validate={validationRules.required}
                  render={({ input, meta, ref, ...outerProps }) => {
                    const onChange = (e: ResourcesPostTypeEnum) => {
                      form.change('resourceType', e);
                    };
                    return (
                      <SingleSelect
                        selectRef={ref}
                        options={transformEnumToOptions(ResourcesPostTypeEnum)}
                        input={{ ...input, onChange }}
                        meta={meta}
                        {...outerProps}
                      />
                    );
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mt-4">
                <Field
                  name="title"
                  label="Resource title"
                  placeholder="Enter resource title"
                  validate={validationRules.required}
                  inputCSS={INPUT_CSS}
                  rootCSS={ROOT_CSS}
                  hasLine={false}
                  component={Input}
                />
              </div>
              <div className="col-md-4 mt-4">
                <Field
                  name="subtitle"
                  label="Resource subtitle"
                  placeholder="Enter resource subtitle"
                  validate={validationRules.required}
                  inputCSS={INPUT_CSS}
                  rootCSS={ROOT_CSS}
                  hasLine={false}
                  component={Input}
                />
              </div>
              <div className="col-md-4 mt-4">
                <Field
                  name="author"
                  label="Resource author"
                  placeholder="Enter resource author"
                  validate={validationRules.required}
                  inputCSS={INPUT_CSS}
                  rootCSS={ROOT_CSS}
                  hasLine={false}
                  component={Input}
                />
              </div>
            </div>
            <div className="row mt-4">
              <UploadContainer>
                <Field
                  name="imageUrlVal"
                  label="Resource banner image"
                  placeholder="Enter resource image URL"
                  disabled={true}
                  validate={validationRules.required}
                  inputCSS={INPUT_CSS}
                  rootCSS={ROOT_UPLOAD_INPUT_CSS}
                  hasLine={false}
                  component={Input}
                />
                <Field
                  name="imageUrl"
                  validate={validationRules.requiredArr}
                  rootCSS={ROOT_UPLOAD_BUTTON_CSS}
                  disabled={false}
                  component={UploadMaterialButton}
                />
              </UploadContainer>
            </div>
            {values.resourceType === ResourcesPostTypeEnum.Video && (
              <div className="row mt-4">
                <UploadContainer>
                  <Field
                    name="videoUrlVal"
                    label="Resource Video"
                    placeholder="Enter resource video URL"
                    validate={validationRules.required}
                    inputCSS={INPUT_CSS}
                    rootCSS={ROOT_UPLOAD_INPUT_CSS}
                    hasLine={false}
                    disabled={true}
                    component={Input}
                  />
                  <Field
                    name="videoUrl"
                    validate={validationRules.required}
                    rootCSS={ROOT_UPLOAD_BUTTON_CSS}
                    disabled={false}
                    component={UploadMaterialButton}
                  />
                </UploadContainer>
              </div>
            )}

            {values.resourceType === ResourcesPostTypeEnum.Text && (
              <Editor
                defaultEditorState={values.editorState}
                onEditorContentChanged={onEditorContentChanged}
              />
            )}

            {/* Preview Container as overlay */}
            {renderPostPreview(values)}

            {/* Action Button */}
            <ButtonFieldWrapper>
              {renderActionButton(handleSubmit, values)}
            </ButtonFieldWrapper>
          </div>
        </Wrapper>
      )}
    />
  );
};

const Wrapper = styled.div`
  overflow: scroll;
  padding-bottom: ${getSize(80)};
`;

const Spacer = styled.div`
  width: 20px;
`;

const CreateCompanyButton = styled(button)`
  bottom: 0;
  height: 42px;
  background: #6b4ee6;
  border: 1px solid #6b4ee6;
  box-sizing: border-box;
  border-radius: 34px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
  font-weight: 600;
  font-size: 14px;
`;

const ButtonFieldWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: ${getSize(11)} ${getSize(27)};
  background-color: white;
  border-radius: 10px;
  border-top: 1px solid #f1f1f1;
  z-index: 10;

  display: flex;
  flex-direction: row;
`;

const PostPreviewAbsoluteContainer = styled.div`
  border-radius: 10px;
  position: absolute;
  top: 68px;
  bottom: 65px;
  right: 0;
  left: 0;
  background: white;
  z-index: 2;
  overflow: scroll;
`;

const UploadContainer = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;
