import * as Types from '../../../../__generated__/types';

import { ChatMessageFragment } from '../../query/__generated__/chat-messages';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteMessagesVariables = Types.Exact<{
  input: Types.MessageRemoveInput;
}>;


export type DeleteMessages = (
  { __typename: 'Mutation' }
  & { removeMessage: (
    { __typename: 'MessageRemovePayload' }
    & Pick<Types.MessageRemovePayload, 'success'>
    & { node?: Types.Maybe<(
      { __typename: 'MessagesRemoveModel' }
      & MessageRemoveModalFragment
    )>, userErrors: Array<(
      { __typename: 'ValidationError' }
      & Pick<Types.ValidationError, 'field' | 'messages'>
    )> }
  ) }
);

export type MessageRemoveModalFragment = (
  { __typename: 'MessagesRemoveModel' }
  & Pick<Types.MessagesRemoveModel, 'messagesIds' | 'chatId'>
  & { lastMessage?: Types.Maybe<(
    { __typename: 'MessageModel' }
    & ChatMessageFragment
  )> }
);

export const MessageRemoveModalFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"MessageRemoveModalFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"MessagesRemoveModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"messagesIds"}},{"kind":"Field","name":{"kind":"Name","value":"chatId"}},{"kind":"Field","name":{"kind":"Name","value":"lastMessage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChatMessageFragment"}}]}}]}},...ChatMessageFragment.definitions]} as unknown as DocumentNode;
export const DeleteMessagesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteMessages"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"MessageRemoveInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeMessage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MessageRemoveModalFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"userErrors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"field"}},{"kind":"Field","name":{"kind":"Name","value":"messages"}}]}}]}}]}},...MessageRemoveModalFragment.definitions]} as unknown as DocumentNode;
export type DeleteMessagesMutationFn = Apollo.MutationFunction<DeleteMessages, DeleteMessagesVariables>;

/**
 * __useDeleteMessages__
 *
 * To run a mutation, you first call `useDeleteMessages` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessages` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessages, { data, loading, error }] = useDeleteMessages({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMessages(baseOptions?: Apollo.MutationHookOptions<DeleteMessages, DeleteMessagesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMessages, DeleteMessagesVariables>(DeleteMessagesDocument, options);
      }
export type DeleteMessagesHookResult = ReturnType<typeof useDeleteMessages>;
export type DeleteMessagesMutationResult = Apollo.MutationResult<DeleteMessages>;
export type DeleteMessagesMutationOptions = Apollo.BaseMutationOptions<DeleteMessages, DeleteMessagesVariables>;