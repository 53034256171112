import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { getSize, ISO_DATE_FORMAT, TimeUtil } from 'lib/utils';
import { Button } from 'ui/button';
import { ShadowWrapper } from 'ui/shadow-wrapper';
import { dashboardRoutes } from 'routes/root-routes';
import { TherapistFragment } from 'common/query/__generated__/get-therapists-by-request';
import { DEFAULT_AVATAR } from 'lib/constants/therapist';
import { UserShortInfo } from 'components/user-short-info';
import { PopUpMessage } from 'ui';

export interface RequestItemProps {
  therapist: TherapistFragment;
}

function RequestItem({ therapist }: RequestItemProps) {
  const history = useHistory();

  const date = useMemo(
    () =>
      TimeUtil.parse(therapist.updatedAt, ISO_DATE_FORMAT).format('DD.MM.YYYY'),
    [therapist.updatedAt],
  );

  const openTherapistProfile = () => {
    history.push(dashboardRoutes.request.pathBuild(therapist.id));
  };

  const {
    user: { avatar, lastName, firstName, email },
    qualification: { professionalTitle },
  } = therapist;

  return (
    <Wrapper>
      <UserShortInfo
        avatar={avatar?.source || DEFAULT_AVATAR}
        titleCSS={{ width: getSize(355) }}
        name={`${firstName} ${lastName}`}
        profession={professionalTitle.label}
      />
      <Text $width={150}>{date}</Text>
      <MailBlock>
        <MailText $width={200}>{email}</MailText>
        <PopUpMessage
          className="tooltip-message"
          rootCSS={{ width: 'auto', bottom: `calc(100% + ${getSize(-2)})` }}>
          {email}
        </PopUpMessage>
      </MailBlock>
      <ButtonsWrapper>
        <MoreButton onClick={openTherapistProfile} theme="primary">
          More
        </MoreButton>
      </ButtonsWrapper>
    </Wrapper>
  );
}

const Wrapper = styled(ShadowWrapper)`
  display: flex;
  align-items: center;
  padding: ${getSize(12)} ${getSize(24)};
`;

const Text = styled.p<{ $color?: string; $width?: number }>`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: ${({ $color }) => $color || `var(--black3)`};
  width: ${({ $width }) => ($width ? getSize($width) : '100%')};
  min-width: ${({ $width }) => ($width ? getSize($width) : '100%')};
  overflow-wrap: break-word;
`;

const MailBlock = styled.div`
  position: relative;
  margin-right: auto;
`;

const MailText = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:hover ~ {
    .tooltip-message {
      display: block;
      animation: showTooltip 0.3s ease-out forwards;
      white-space: nowrap;
    }
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

const MoreButton = styled(Button)`
  padding: ${getSize(5)} ${getSize(20)};
  font-weight: 400;
  max-width: ${getSize(70)};
`;

export default RequestItem;
