import { ApolloCache } from '@apollo/client';
import { TimeUtil } from 'lib/utils';
import { LogType } from '__generated__/types';
import { AdminReadSpecificLog } from '../mutations/__generated__/admin-read-specific-log';
import { BillStatusHistoryFragment } from '../queries/__generated__/admin-bill-status-history';
import { BillSubmitHistoryFragment } from '../queries/__generated__/admin-bill-submit-history';
import { BillValidateHistoryFragment } from '../queries/__generated__/admin-bill-validate-history';
import { BillFragments, SpecificBillFragmentMeta } from '../types';

const billLogFragmentMap: {
  [P in LogType]: SpecificBillFragmentMeta<P>;
} = {
  [LogType.BillStatus]: {
    __typename: 'BillStatusHistoryModel',
    fragment: BillStatusHistoryFragment,
    fragmentName: 'BillStatusHistoryFragment',
  },
  [LogType.SubmitBill]: {
    __typename: 'BillSubmitHistoryModel',
    fragment: BillSubmitHistoryFragment,
    fragmentName: 'BillSubmitHistoryFragment',
  },
  [LogType.ValidateBill]: {
    __typename: 'BillValidateHistoryModel',
    fragment: BillValidateHistoryFragment,
    fragmentName: 'BillValidateHistoryFragment',
  },
};

export const updateBillLogHistoryCache = <T extends BillFragments>(
  cache: ApolloCache<AdminReadSpecificLog>,
  type: LogType,
  logId: T['id'],
) => {
  const { __typename, fragment, fragmentName } = billLogFragmentMap[type];

  const cacheId = cache.identify({
    __typename,
    id: logId,
  });

  const billData = cache.readFragment<T>({
    id: cacheId,
    fragment,
    fragmentName,
  });

  if (!billData) {
    return;
  }

  const updatedBillData = {
    ...billData,
    viewedAt: TimeUtil.now().toISOString(),
  };

  cache.writeFragment<T>({
    id: cacheId,
    fragment,
    fragmentName,
    data: updatedBillData,
  });
};
