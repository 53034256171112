import { useAdminTotalBusinessInfo } from 'common/query/__generated__/admin-total-bisness-info';
import { useGetAdminFeedbackTotalInfo } from 'common/query/__generated__/get-admin-total-feedback';
import React, { FC } from 'react';
import styled from 'styled-components';

export const HeaderTable: FC = () => {
  const { data: totalBusinessInfoData } = useAdminTotalBusinessInfo();

  const totalBusinessInfo = totalBusinessInfoData?.adminTotalBusinessInfo;
  const columns = [
    {
      name: 'Total registered users',
      value: totalBusinessInfo?.activePatients,
    },
    {
      name: 'Total active users',
      value: totalBusinessInfo?.activePatients,
    },
    {
      name: 'Total sessions',
      value: totalBusinessInfo?.totalSessionsAmount.toFixed(2),
    },
    {
      name: 'Avg sessions / person',
      value: totalBusinessInfo?.avgSessionsPerPatient.toFixed(2),
    },
    {
      name: 'Upcoming sessions',
      value: totalBusinessInfo?.upcomingSessions.toFixed(2),
    },
  ];
  return (
    <Wrapper>
      {columns.map((column, index) => (
        <TableInner key={index}>
          <ColumnHeader>{column.name}</ColumnHeader>
          <ColumnValue>{column.value}</ColumnValue>
        </TableInner>
      ))}
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  display: flex;
`;

export const TableInner = styled.div`
  width: 183px;
`;

export const ColumnHeader = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;

  color: #838383;
`;

export const ColumnValue = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;

  display: flex;
  align-items: center;

  color: #0f0f0f;
`;
