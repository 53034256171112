import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled, { CSSProp } from 'styled-components';
import { floatInputRegExp, getSize } from 'lib/utils';

interface PriceInputProps extends FieldRenderProps<string> {
  placeholder?: string;
  label?: string;
  rootCSS?: CSSProp;
  inputCSS?: CSSProp;
  disabled?: boolean;
  maxLength?: number;
  errorCSS?: CSSProp;
}

function PriceInput({
  meta,
  input,
  label,
  placeholder,
  rootCSS,
  inputCSS,
  disabled,
  maxLength = 10,
  errorCSS,
}: PriceInputProps) {
  const { error, submitError, touched, dirtySinceLastSubmit, data } = meta;

  const hasError =
    ((error || submitError) && touched && !dirtySinceLastSubmit) || data?.error;

  const onEnterInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (floatInputRegExp.test(value) || Number(value) >= 0) {
      input.onChange(value);
    }
  };

  return (
    <Wrapper $CSS={rootCSS}>
      <div>
        <SessionWrapper>
          <Text>{label}</Text>
        </SessionWrapper>
        <InputWrapper>
          <Input
            {...input}
            $CSS={inputCSS}
            disabled={disabled}
            maxLength={maxLength}
            placeholder={placeholder || ''}
            onChange={(e) => onEnterInput(e)}
          />
          <Percent>£</Percent>
        </InputWrapper>

        {hasError && (
          <ErrorText $CSS={errorCSS}>
            {error || submitError || data?.error}
          </ErrorText>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.label<{ $CSS?: CSSProp }>`
  background: var(--white);
  box-shadow: ${getSize(0)} ${getSize(20)} ${getSize(90)} rgba(40, 31, 61, 0.04);
  border-radius: ${getSize(8)};
  padding: ${getSize(10)} ${getSize(44)};
  ${({ $CSS }) => $CSS}
`;

const InputWrapper = styled.div`
  position: relative;
  border-radius: ${getSize(8)};
  background-color: white;
  min-height: ${getSize(44)};
  width: ${getSize(210)};
  border: ${getSize(1)} solid var(--purple3);
`;

const SessionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${getSize(9)};
`;

const Text = styled.p`
  font-weight: 600;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  align-items: center;
  color: var(--black3);
`;

const Input = styled.input<{ $CSS?: CSSProp }>`
  position: relative;
  padding: ${getSize(12)} ${getSize(85)} ${getSize(12)} ${getSize(54)};
  font-size: ${getSize(12)};
  line-height: ${getSize(20)};
  color: var(--black3);
  transition: all 0.3s ease-out;
  width: ${getSize(210)};
  text-align: right;

  &::placeholder {
    color: var(--gray7);
  }
  ${({ $CSS }) => $CSS}
`;

const Percent = styled.span`
  position: absolute;
  top: 50%;
  left: 63%;
  transform: translate(-50%, -50%);
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--gray7);
`;

const ErrorText = styled.span<{ $CSS?: CSSProp }>`
  margin: ${getSize(2)} 0 0;
  font-weight: 400;
  font-size: ${getSize(10)};
  line-height: ${getSize(16)};
  color: var(--red);
  width: ${getSize(100)};
  padding: 0 ${getSize(10)};
  ${({ $CSS }) => $CSS}
`;

export default PriceInput;
