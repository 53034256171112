import React from 'react';
import styled from 'styled-components';

import { getSize } from 'lib/utils';
import { FileExtension, FileType } from '__generated__/types';

import { Modal } from 'ui';
import { DownloadIconMini } from 'ui/icons';
import { ImagesPreview } from 'ui/images-preview';

const { Jpeg, Jpg, Pdf, Png, Doc, Docx, Mp3, Mp4 } = FileExtension;

export interface PreviewedFile {
  fileType: FileType;
  source: string;
  extension: FileExtension;
  name: string;
}

export interface FilesPreviewModalProps {
  file?: PreviewedFile;
  isVisible: boolean;
  onClose: () => void;
}

const PAPER_CSS = { maxWidth: getSize(1234) };
const HEADER_CSS = { margin: `0 0 ${getSize(20)}` };
const TITLE_CSS = {
  fontWeight: 400,
  fontSize: getSize(12),
  lineHeight: getSize(24),
  color: 'var(--black3)',
};

function FilesPreviewModal({
  isVisible,
  onClose,
  file,
}: FilesPreviewModalProps) {
  if (!file) return null;

  const { extension, name, source } = file;

  const isImageType =
    extension === Jpeg || extension === Jpg || extension === Png;
  const isVideoType = extension === Mp4;
  const isPdf = extension === Pdf;
  const isDocument = extension === Doc || extension === Docx || isPdf;
  const isAudio = extension === Mp3;

  const attachmentName = name;
  const docLink = `https://docs.google.com/viewer?url=${source}&embedded=true`;

  return (
    <Modal
      title={`${attachmentName}.${extension.toLocaleLowerCase()}`}
      titleCSS={TITLE_CSS}
      headerCSS={HEADER_CSS}
      paperCSS={PAPER_CSS}
      isVisible={isVisible}
      onClose={onClose}>
      {!isPdf && (
        <Download href={source} download={`${name}.${extension}`}>
          <DownloadIcon />
          Download
        </Download>
      )}
      {isImageType && <Image src={source} alt={attachmentName} />}
      {isVideoType && <Video src={source} controls />}
      {isDocument && <Frame src={isPdf ? source : docLink} />}
      {isAudio && <Audio src={source} controls />}
    </Modal>
  );
}

const Image = styled(ImagesPreview)`
  width: 100%;
  height: 75vh;
  border-radius: ${getSize(8)};
`;

const Video = styled.video`
  width: 100%;
  height: 75vh;
  border-radius: 8px;
`;

const Frame = styled.iframe`
  width: 100%;
  height: 75vh;
`;

const Audio = styled.audio`
  width: 50%;
  margin: auto;
`;

const Download = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${getSize(3)};
  min-width: ${getSize(182)};
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  border-radius: ${getSize(8)};
  text-align: center;
  transition: 0.3s ease-out;
  color: var(--black3);
  margin: 0 0 ${getSize(16)} auto;
  border: ${getSize(1)} solid var(--purple3);
  background: white;

  &:hover {
    border-color: var(--gray3);
  }

  &:active {
    border-color: var(--purple5);
    background: var(--purple5);
  }

  &:focus {
    border-color: var(--purple5);
  }

  &:disabled {
    border-color: var(--gray4);
    color: var(--gray7);
  }
`;

const DownloadIcon = styled(DownloadIconMini)`
  transform: rotate(180deg);
  margin-right: ${getSize(16)};
`;

export default FilesPreviewModal;
