const countDecimals = (value: number) => {
  if (Math.floor(value) !== value) {
    return value.toString().split('.')[1].length || 0;
  }
  return 0;
};

export const getRoundedValue = (value: number) => {
  return countDecimals(value) ? value.toFixed(1) : value;
};
