import { RefObject } from 'react';

export interface ScrollDialogArgs {
  messageListRef: RefObject<HTMLDivElement>;
  unreadCount?: number;
  searchedMessageId?: string;
  top?: number;
}

export function scrollDialog({
  messageListRef,
  unreadCount = 0,
  searchedMessageId,
  top = 0,
}: ScrollDialogArgs) {
  const dialogWrapper = messageListRef.current;
  if (!dialogWrapper) return;

  const { scrollHeight, clientHeight } = dialogWrapper;
  let scrollTopPosition: number | undefined =
    top || scrollHeight - clientHeight;

  if (searchedMessageId) {
    const searchedMessage = dialogWrapper.querySelector(
      `[data-id="${searchedMessageId}"]`,
    );
    scrollTopPosition = (searchedMessage as any)?.offsetTop;
  } else if (unreadCount > 0) {
    const messageWithUnreadMark = dialogWrapper.querySelector(
      '#unread-message-mark',
    );
    scrollTopPosition = (messageWithUnreadMark as any)?.offsetTop;
  }

  dialogWrapper.scrollTo({
    top: scrollTopPosition,
    left: 0,
  });
}
