import React, { useState } from 'react';
import styled from 'styled-components';
import { useDebounceCallback } from '@react-hook/debounce';
import { NetworkStatus } from '@apollo/client';

import { getChatById } from './utils';
import { getSize } from 'lib/utils';
import { MAX_VISIBLE_CHATS } from './hooks/use-chat-list-logic';
import {
  useChatSubscriptions,
  useReadChatMessages,
  useChatListLogic,
  useStartNewChat,
  useChatMessagesLogic,
} from './hooks';

import {
  Dialog,
  DialogHeader,
  MessageSendForm,
  SideBar,
  WriteMessageModal,
  EmptyBlock,
  DeleteMessagesModal,
} from './components';
import { FilesPreviewModal, Loader, ShadowWrapper } from 'ui';

export const ADMIN_ID = 'admin_chat_bot';
export const THERAPISTS_GROUP_ID = 'therapist_chat_bot';

function MessagingPage() {
  const [isExpanded, setIsExpanded] = useState(false);

  const { readChatMessages } = useReadChatMessages();

  const {
    selectedChatId,
    chatsList,
    selectedChat,
    chatsListQuery,
    currentUnreadCount,
    setCurrentUnreadCount,
    setSelectedChat,
    participantsCountOfSelectedChat,
  } = useChatListLogic({ readChatMessages });

  const {
    isWriteMessageModalVisible,
    idOfUserWithNewChat,
    setSearchInterlocutorString,
    isStartNewAdminChatLoading,
    interlocutorForStartNewChat,
    onWriteMessageClick,
    onParticipantsClick,
    startNewChat,
    onWriteMessageModalClose,
    usersForStartNewChatQuery,
    groupChatParticipantsToShow,
  } = useStartNewChat({ selectedChat, onChatClick: handleChatClick });

  const {
    onSearchMessageFormSubmit,
    messagesSearchPhrase,
    chatMessagesQuery,
    chatMessages,
    isMessagesSearchActive,
    fetchMessages,
    isFetchingNewMessages,
    messageListRef,
    onSendFormSubmit,
    attachmentForFullscreen,
    onMessageClick,
    isGroupSelectedChat,
    onAttachmentClick,
    onSearchCancelClick,
    openMessagesSearch,
    clearAttachmentForFullscreen,
    refetchMessagesByChatId,
    formattedAttachmentForPreview,
    onSelectMessageForDeleting,
    onResetSelectedMessagesForDeleting,
    selectedMessagesForDeleting,
    onCloseDeleteMessageModal,
    onDeleteSelectedMessages,
    onOpenDeleteMessageModal,
    isOpenDeleteMessageModal,
  } = useChatMessagesLogic({
    firstChatId: chatsList[0]?.id,
    selectedChatId,
    participantsCountOfSelectedChat,
  });

  useChatSubscriptions({
    messageListRef,
    selectedChatId,
    setCurrentUnreadCount,
    readChatMessages,
    onChatClick: handleChatClick,
  });

  function handleChatClick(chatId: string) {
    const newChat = getChatById(chatsList, chatId);

    if (newChat) {
      if (isMessagesSearchActive) {
        onSearchCancelClick();
      } else if (chatMessagesQuery.data?.chatMessages.pageInfo.hasNextPage) {
        refetchMessagesByChatId(selectedChatId);
      }

      if (selectedChat?.unreadCount) {
        readChatMessages({ chatId: selectedChatId });
      }

      setCurrentUnreadCount(newChat.unreadCount);
      setSelectedChat(chatId);
    }
  }

  const hasTextForSearchMessages = Boolean(messagesSearchPhrase);
  const debounceOnSearchMessageFormSubmit = useDebounceCallback(
    onSearchMessageFormSubmit,
    250,
  );

  function renderDialogHeader() {
    if (!chatMessagesQuery.data) {
      if (chatMessagesQuery.loading || chatsListQuery.loading) {
        return <LoaderStylized size={50} hasFillWholeBlock />;
      } else if (chatMessagesQuery.called) {
        return <NoChatsText>No chats yet</NoChatsText>;
      }
      return;
    }

    if (chatMessages) {
      return (
        <>
          <DialogHeader
            selectedChat={selectedChat}
            isExpanded={isExpanded}
            onSearchCancelClick={onSearchCancelClick}
            onSearchFormSubmit={debounceOnSearchMessageFormSubmit}
            isSearchActive={isMessagesSearchActive}
            onSearchTriggerClick={openMessagesSearch}
            onParticipantsClick={onParticipantsClick}
            participantsCount={
              isGroupSelectedChat ? participantsCountOfSelectedChat : undefined
            }
          />
          {hasTextForSearchMessages && chatMessages.length === 0 && (
            <EmptyBlock text="No posts found for this request." />
          )}
        </>
      );
    } else if (!selectedChatId) {
      return <EmptyBlock text="Start a dialogue, select chat" />;
    }
  }

  return (
    <ChatWrapper>
      <SideBar
        onWriteMessageClick={onWriteMessageClick}
        chats={chatsList}
        fetchMoreChats={() =>
          chatsListQuery?.fetchMore({
            variables: {
              limit: MAX_VISIBLE_CHATS,
              after: chatsListQuery.data?.listChats.pageInfo.endCursor,
            },
          })
        }
        isFetchingNewChats={
          chatsListQuery.networkStatus === NetworkStatus.fetchMore
        }
        hasMoreChats={chatsListQuery.data?.listChats.pageInfo.hasNextPage}
        selectedChatId={selectedChatId}
        isExpanded={isExpanded}
        isChatsLoadaing={chatsListQuery.networkStatus === NetworkStatus.loading}
        onChatClick={handleChatClick}
        onExpandClick={() => setIsExpanded((prevValue) => !prevValue)}
      />

      <MessagesWrapper>
        {renderDialogHeader()}

        <Dialog
          messageListRef={messageListRef}
          messages={chatMessages}
          isSideBarExpanded={isExpanded}
          isFetchingNewMessages={isFetchingNewMessages}
          hasPreviousPage={
            chatMessagesQuery.data?.chatMessages.pageInfo.hasPreviousPage
          }
          hasNextPage={
            chatMessagesQuery.data?.chatMessages.pageInfo.hasNextPage
          }
          fetchPreviousMessages={() => fetchMessages(0, false)}
          fetchNextMessages={(oldPosition?: number) =>
            fetchMessages(oldPosition, true)
          }
          unreadMessagesCount={currentUnreadCount}
          readChat={() => readChatMessages({ chatId: selectedChatId })}
          hasTextForSearchMessages={hasTextForSearchMessages}
          onMessageClick={onMessageClick}
          isGroupSelectedChat={isGroupSelectedChat}
          onAttachmentClick={onAttachmentClick}
          isMessagesLoading={selectedChatId !== chatMessages?.[0]?.node.chatId}
          onSelectMessageForDeleting={onSelectMessageForDeleting}
          selectedMessagesForDeleting={selectedMessagesForDeleting}
          onResetSelectedMessagesForDeleting={
            onResetSelectedMessagesForDeleting
          }
          onOpenDeleteMessageModal={onOpenDeleteMessageModal}
        />

        {!isMessagesSearchActive && Number(chatMessages?.length) >= 0 && (
          <MessageSendForm
            onSubmit={onSendFormSubmit}
            isExpanded={isExpanded}
            selectedChatId={selectedChat?.id}
          />
        )}
      </MessagesWrapper>

      <WriteMessageModal
        isVisible={isWriteMessageModalVisible}
        onClose={onWriteMessageModalClose}
        onFormSubmit={setSearchInterlocutorString}
        interlocutors={interlocutorForStartNewChat}
        onWriteClick={startNewChat}
        isStartNewAdminChatLoading={isStartNewAdminChatLoading}
        isLoadingInterlocutor={usersForStartNewChatQuery.loading}
        idOfUserWithNewChat={idOfUserWithNewChat}
        isGroupParticipants={Boolean(groupChatParticipantsToShow)}
      />

      <FilesPreviewModal
        isVisible={Boolean(attachmentForFullscreen)}
        file={formattedAttachmentForPreview}
        onClose={clearAttachmentForFullscreen}
      />
      <DeleteMessagesModal
        isOpen={isOpenDeleteMessageModal}
        onClose={onCloseDeleteMessageModal}
        onDeleteMessages={onDeleteSelectedMessages}
      />
    </ChatWrapper>
  );
}

const ChatWrapper = styled(ShadowWrapper)`
  flex-grow: 1;
  display: flex;
  padding: 0;
  overflow: hidden;
`;

const LoaderStylized = styled(Loader)`
  margin: auto;
`;

const NoChatsText = styled.p`
  margin: auto;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--gray7);
`;

const MessagesWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export default MessagingPage;
