import React, { useCallback, useMemo } from 'react';
import styled, { CSSProp } from 'styled-components';
import DayPicker, { DateUtils } from 'react-day-picker';
import { Weekday, Navbar } from './components';
import { getSize, TimeUtil } from 'lib/utils';
import { addDayToRange, DatesRange } from '../../utils/add-day-to-range';

export interface CalendarSmallProps {
  className?: string;
  multiple?: boolean;
  isRange?: boolean;
  selectedDays?: Date | Date[];
  selectedRange?: DatesRange;
  onDayClick: (date: Date | Date[]) => void;
  onDayClickRange?: (value: DatesRange) => void;
  disabledPastDay?: boolean;
  disabledFutureDay?: boolean;
  initialMonth?: Date;
  currentMonth?: Date;
  rootCSS?: CSSProp;
  renderDay?: (date: Date) => JSX.Element;
  onMonthChange?: (value: Date) => void;
}

function CalendarSmall({
  multiple,
  isRange,
  selectedDays,
  selectedRange,
  onDayClick,
  className,
  disabledPastDay,
  disabledFutureDay,
  initialMonth,
  currentMonth,
  rootCSS,
  renderDay,
  onMonthChange,
  onDayClickRange,
}: CalendarSmallProps) {
  const handleClick = useCallback(
    (day: Date, { selected }) => {
      if (disabledPastDay) {
        const isPastDate = TimeUtil.getStartOFNowDay().isAfter(day);
        if (isPastDate) return;
      } else if (disabledFutureDay) {
        const isPastDate = TimeUtil.getStartOFNowDay().isBefore(day);
        if (isPastDate) return;
      }

      const selectedDaysArray = Array.isArray(selectedDays) ? selectedDays : [];
      day.setHours(0);
      day.setMinutes(0);
      day.setSeconds(0);
      day.setMilliseconds(0);

      let newSelectedDays: Date | Date[] = day;

      if (multiple) {
        newSelectedDays = [...selectedDaysArray];

        if (selected) {
          const selectedIndex = selectedDaysArray.findIndex((selectedDay) =>
            DateUtils.isSameDay(selectedDay, day),
          );
          newSelectedDays.splice(selectedIndex, 1);
        } else {
          newSelectedDays.push(day);
        }
      } else if (isRange && onDayClickRange) {
        onDayClickRange(addDayToRange({ day, range: selectedRange }));
      }

      onDayClick(newSelectedDays);
    },
    [
      onDayClick,
      selectedDays,
      multiple,
      disabledFutureDay,
      disabledPastDay,
      selectedRange,
      isRange,
      onDayClickRange,
    ],
  );

  const disabledDays = useMemo(() => {
    if (disabledPastDay) {
      return { before: new Date() };
    } else if (disabledFutureDay) {
      return { after: new Date() };
    }
  }, [disabledPastDay, disabledFutureDay]);

  return (
    <Calendar
      firstDayOfWeek={1}
      className={className}
      selectedDays={selectedDays}
      initialMonth={initialMonth}
      month={currentMonth}
      onDayClick={handleClick}
      weekdayElement={Weekday}
      captionElement={<></>}
      navbarElement={(props) => (
        <Navbar
          {...props}
          onMonthChange={onMonthChange}
          selectedDay={selectedDays}
        />
      )}
      disabledDays={disabledDays}
      renderDay={renderDay}
      $CSS={rootCSS}
    />
  );
}

const Calendar = styled(DayPicker)<{ $CSS?: CSSProp }>`
  .DayPicker-wrapper {
    padding: ${getSize(10)} 0;
  }

  .DayPicker-Month {
    font-weight: 400;
    font-size: ${getSize(14)};
    line-height: ${getSize(14)};
    color: var(--black3);
    margin: 1em 0 0;
  }

  .DayPicker-Weekday {
    width: ${getSize(42)};
    height: ${getSize(42)};
    padding: ${getSize(12)};
    font-size: ${getSize(14)};
    line-height: ${getSize(18)};
    color: var(--gray2);
  }

  .DayPicker-WeekdaysRow {
    display: flex;
  }
  .DayPicker-Week {
    display: flex;
  }

  .DayPicker-Day {
    position: relative;
    z-index: 1;
    width: ${getSize(42)};
    height: ${getSize(42)};
    font-size: ${getSize(14)};
    line-height: ${getSize(18)};
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      width: ${getSize(32)};
      height: ${getSize(32)};
      border-radius: ${getSize(8)};
      transform: translate(-50%, -50%);
      transition: background 0.2s ease-out;
    }

    &:hover {
      &:not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled):not(
          .DayPicker-Day--selected
        ) {
        background: 0 !important;

        &::before {
          background: var(--purple3);
        }
      }
    }

    &--today {
      &:not(.DayPicker-Day--outside) {
        background: 0;
        color: var(--purple);

        &::before {
          background: var(--purple5);
        }
      }
    }

    &--selected {
      &:not(.DayPicker-Day--outside) {
        background: 0;
        color: white;

        &::before {
          background: var(--purple);
        }

        &:hover {
          background: 0 !important;

          &::before {
            background: var(--purple);
          }
        }
      }
    }

    &--disabled {
      color: var(--black3);
    }
  }

  ${({ $CSS }) => $CSS}
`;

export default CalendarSmall;
