import React from 'react';
import styled from 'styled-components';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import { getSize } from 'lib/utils';
import { Loader, Search as SearchInput } from 'ui';
export interface SearchFormProps {
  onFormSubmit: (search?: string) => void;
}

function SearchForm({ onFormSubmit }: SearchFormProps) {
  return (
    <Wrapper className="search-input">
      <FinalForm
        onSubmit={() => {}}
        render={() => (
          <>
            <FormSpy
              subscription={{ values: true }}
              onChange={({ values: { search } }) => onFormSubmit(search)}
            />
            <Field name="search" component={Input} />
          </>
        )}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${getSize(250)};
  transition: width 0.3s linear;
  background: white;
  overflow: hidden;
`;

const Input = styled(SearchInput)`
  flex-grow: 1;
  margin: 0 ${getSize(16)} 0 0;
`;

export default SearchForm;
