import { getSize, ISO_DATE_FORMAT, TimeUtil } from 'lib/utils';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { SearchInput } from 'ui';
import { DropDownCalendar } from 'ui/drop-down-calendar';
import { DatesRange } from 'ui/drop-down-calendar/utils/add-day-to-range';

interface SessionFiltersProps {
  selectedDaysRange?: DatesRange;
  setSelectedDaysRange: (value: DatesRange) => void;
  onSearchByReference: (value?: string) => void;
  onSearchByTherapistName: (value?: string) => void;
}

const SessionFilters: FC<SessionFiltersProps> = ({
  selectedDaysRange,
  setSelectedDaysRange,
  onSearchByReference,
  onSearchByTherapistName,
}) => {
  const selectedDays = useMemo(() => {
    if (!selectedDaysRange) return;
    const from = selectedDaysRange.from;
    const to = selectedDaysRange.to;

    if (from && to) {
      return [from, to];
    } else if (from) {
      return from;
    } else if (to) {
      return to;
    }
  }, [selectedDaysRange]);

  const calendarFilterTitle = useMemo(() => {
    if (!selectedDaysRange) return 'Session date';
    const { from, to } = selectedDaysRange;

    const filterStart =
      from &&
      TimeUtil.parse(from.toISOString(), ISO_DATE_FORMAT).format('MMM D');
    const filterEnd =
      to && TimeUtil.parse(to.toISOString(), ISO_DATE_FORMAT).format('MMM D');

    return filterStart || filterEnd
      ? `${filterStart}${filterEnd ? ` - ${filterEnd}` : ''}`
      : 'Session date';
  }, [selectedDaysRange]);

  return (
    <Wrapper>
      <Title>Insurance Sessions</Title>

      <Filters>
        <Calendar
          title={calendarFilterTitle}
          selectedRange={selectedDaysRange}
          selectedDays={selectedDays}
          onDayClickRange={setSelectedDaysRange}
          isRange
        />
        <Search
          placeholder="Search by invoice No"
          onChange={onSearchByReference}
        />
        <SearchInput
          placeholder="Search by therapist name"
          onChange={onSearchByTherapistName}
        />
      </Filters>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${getSize(40)};
`;

const Title = styled.h1`
  font-weight: 600;
  font-size: ${getSize(22)};
  line-height: ${getSize(33)};
  flex-shrink: 0;
  color: var(--black3);
  margin-right: auto;
`;

const Filters = styled.div`
  display: flex;
`;

const Calendar = styled(DropDownCalendar)`
  margin-right: ${getSize(22)};
`;

const Search = styled(SearchInput)`
  margin-right: ${getSize(16)};
`;

export default SessionFilters;
