import { useEffect } from 'react';

function useWindowClick(callback: () => void) {
  useEffect(() => {
    window.addEventListener('click', callback);
    return () => window.removeEventListener('click', callback);
  });
}

export default useWindowClick;
