import { useReadLogs } from 'common/mutation/__generated__/read-logs';
import { useLogsCounter } from 'components/dashboard-nav-links/utils/use-logs-count';
import { getSize, LOGS_LINKS } from 'lib/utils';
import { Route, Switch } from 'react-router';
import { NavLink, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { TextOfCount } from 'ui';
import { LogType } from '__generated__/types';
import { dashboardRoutes } from '../../routes/root-routes';
import BillStatusHistory from './bill-status-history/bill-status-history';
import SubmitBillHistory from './submit-bill-history/submit-bill-history';
import ValidateBillHistory from './validate-bill-history/validate-bill-history';

const LogsPage = () => {
  const { logsCount } = useLogsCounter();
  const [setReadLogs] = useReadLogs();

  const setReadLogsByType = (type: LogType) => {
    setReadLogs({
      variables: { type },
      update(cache) {
        cache.evict({
          fieldName: 'getUnreadFailedLogsCount',
          broadcast: true,
        });
        cache.gc();
      },
    });
  };
  return (
    <>
      <Nav>
        {LOGS_LINKS.map((l) => {
          let count = 0;

          if (logsCount?.all) {
            if (l.type === LogType.ValidateBill) {
              count = logsCount.validateCount;
            }
            if (l.type === LogType.SubmitBill) {
              count = logsCount.submitCount;
            }
            if (l.type === LogType.BillStatus) {
              count = logsCount.statusCount;
            }
          }

          return (
            <NavLink
              key={l.href}
              to={l.href}
              onClick={() => count && setReadLogsByType(l.type)}>
              {l.text}
              {!!count && (
                <TextOfCountStylized className="logs-count">
                  {count}
                </TextOfCountStylized>
              )}
            </NavLink>
          );
        })}
      </Nav>
      <Switch>
        <Route
          exact
          path={dashboardRoutes.logs.validateBillHistory.PATH}
          component={ValidateBillHistory}
        />
        <Route
          exact
          path={dashboardRoutes.logs.submitBillHistory.PATH}
          component={SubmitBillHistory}
        />
        <Route
          exact
          path={dashboardRoutes.logs.billStatusHistory.PATH}
          component={BillStatusHistory}
        />
        <Redirect to={dashboardRoutes.logs.validateBillHistory.PATH} />
      </Switch>
    </>
  );
};

const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 60px;
`;

const TextOfCountStylized = styled(TextOfCount)`
  margin: 0 0 0 ${getSize(7)};
`;

export default LogsPage;
