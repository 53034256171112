import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AdminBillValidateHistoryVariables = Types.Exact<{
  first?: Types.Maybe<Types.Scalars['Int']>;
  after?: Types.Maybe<Types.Scalars['String']>;
  filter?: Types.Maybe<Types.BillValidateHistoryFilter>;
}>;


export type AdminBillValidateHistory = (
  { __typename: 'Query' }
  & { adminBillValidateHistory: (
    { __typename: 'BillValidateHistoryConnection' }
    & { nodes: Array<(
      { __typename: 'BillValidateHistoryModel' }
      & BillValidateHistoryFragment
    )>, pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<Types.PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);

export type BillValidateHistoryFragment = (
  { __typename: 'BillValidateHistoryModel' }
  & Pick<Types.BillValidateHistoryModel, 'id' | 'patientId' | 'therapistId' | 'req' | 'res' | 'startDateUtc' | 'endDateUtc' | 'isSuccess' | 'createdAt' | 'updatedAt' | 'viewedAt'>
);

export const BillValidateHistoryFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BillValidateHistoryFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BillValidateHistoryModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"patientId"}},{"kind":"Field","name":{"kind":"Name","value":"therapistId"}},{"kind":"Field","name":{"kind":"Name","value":"req"}},{"kind":"Field","name":{"kind":"Name","value":"res"}},{"kind":"Field","name":{"kind":"Name","value":"startDateUtc"}},{"kind":"Field","name":{"kind":"Name","value":"endDateUtc"}},{"kind":"Field","name":{"kind":"Name","value":"isSuccess"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"viewedAt"}}]}}]} as unknown as DocumentNode;
export const AdminBillValidateHistoryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AdminBillValidateHistory"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"first"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"after"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"BillValidateHistoryFilter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminBillValidateHistory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"Variable","name":{"kind":"Name","value":"first"}}},{"kind":"Argument","name":{"kind":"Name","value":"after"},"value":{"kind":"Variable","name":{"kind":"Name","value":"after"}}},{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"nodes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BillValidateHistoryFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"pageInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hasNextPage"}},{"kind":"Field","name":{"kind":"Name","value":"endCursor"}}]}}]}}]}},...BillValidateHistoryFragment.definitions]} as unknown as DocumentNode;

/**
 * __useAdminBillValidateHistory__
 *
 * To run a query within a React component, call `useAdminBillValidateHistory` and pass it any options that fit your needs.
 * When your component renders, `useAdminBillValidateHistory` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminBillValidateHistory({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminBillValidateHistory(baseOptions?: Apollo.QueryHookOptions<AdminBillValidateHistory, AdminBillValidateHistoryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminBillValidateHistory, AdminBillValidateHistoryVariables>(AdminBillValidateHistoryDocument, options);
      }
export function useAdminBillValidateHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminBillValidateHistory, AdminBillValidateHistoryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminBillValidateHistory, AdminBillValidateHistoryVariables>(AdminBillValidateHistoryDocument, options);
        }
export type AdminBillValidateHistoryHookResult = ReturnType<typeof useAdminBillValidateHistory>;
export type AdminBillValidateHistoryLazyQueryHookResult = ReturnType<typeof useAdminBillValidateHistoryLazyQuery>;
export type AdminBillValidateHistoryQueryResult = Apollo.QueryResult<AdminBillValidateHistory, AdminBillValidateHistoryVariables>;