import { ChatFragment } from '../query/__generated__/chats-list';
import { ChatUserType } from '__generated__/types';
import { useMemo, useState } from 'react';
import { ChatUserFragment } from 'pages/messaging-page/query/__generated__/users-for-start-new-chat';
import { useUsersForStartNewChatLazyQuery } from '../query/__generated__/users-for-start-new-chat';
import { useAdminStartNewChat } from '../mutation/__generated__/admin-start-new-chat';
import { ADMIN_ID, THERAPISTS_GROUP_ID } from '../messaging-page';
import { updateChatListCacheAfterStartNewChat } from '../utils';

interface UseStartNewChatArgs {
  selectedChat?: ChatFragment;
  onChatClick: (chadId: string) => void;
}

export function useStartNewChat({
  selectedChat,
  onChatClick,
}: UseStartNewChatArgs) {
  const [idOfUserWithNewChat, setIdOfUserWithNewChat] = useState('');
  const [isWriteMessageModalVisible, setIsWriteMessageModalVisible] =
    useState(false);
  const [searchInterlocutorString, setSearchInterlocutorString] =
    useState<string>();
  const [groupChatParticipantsToShow, grouChatParticipantsToShow] =
    useState<ChatUserFragment[]>();

  const [getUsersForStartNewChat, usersForStartNewChatQuery] =
    useUsersForStartNewChatLazyQuery();
  const [startNewAdminChat, { loading: isStartNewAdminChatLoading }] =
    useAdminStartNewChat({
      update: updateChatListCacheAfterStartNewChat,
    });

  const interlocutorForStartNewChat = useMemo(() => {
    const currentInterlocutors =
      groupChatParticipantsToShow ||
      usersForStartNewChatQuery.data?.adminListUsersCanStartChat ||
      [];

    return currentInterlocutors.filter((interlocutor) => {
      if (
        interlocutor.type === ChatUserType.Admin ||
        interlocutor.id === ADMIN_ID ||
        interlocutor.id === THERAPISTS_GROUP_ID
      ) {
        return;
      }

      if (searchInterlocutorString) {
        const isSearchStringFit = new RegExp(
          `${searchInterlocutorString}`,
          'gi',
        ).test(interlocutor.name);

        return isSearchStringFit;
      }

      return true;
    });
  }, [
    searchInterlocutorString,
    usersForStartNewChatQuery.data,
    groupChatParticipantsToShow,
  ]);

  function showWriteMessageModal() {
    setIsWriteMessageModalVisible(true);
  }
  function handleWriteMessageClick() {
    if (!usersForStartNewChatQuery.called) getUsersForStartNewChat();
    showWriteMessageModal();
  }
  function closeWriteMessageModal() {
    setIsWriteMessageModalVisible(false);
  }

  function handleParticipantsClick() {
    grouChatParticipantsToShow(selectedChat?.participants);
    showWriteMessageModal();
  }

  function startNewChat(chatUserId: string) {
    if (!chatUserId) return;

    startNewAdminChat({ variables: { chatUserId } }).then(({ data }) => {
      closeWriteMessageModal();
      setIdOfUserWithNewChat('');

      if (data) {
        const newChatId = data.adminStartChat.id;

        if (newChatId !== selectedChat?.id) {
          onChatClick(newChatId);
        }
      }
    });
  }

  function handleWriteMessageModalClose() {
    closeWriteMessageModal();
    if (groupChatParticipantsToShow) grouChatParticipantsToShow(undefined);
  }

  return {
    usersForStartNewChatQuery,
    isWriteMessageModalVisible,
    idOfUserWithNewChat,
    setSearchInterlocutorString,
    isStartNewAdminChatLoading,
    interlocutorForStartNewChat,
    onWriteMessageClick: handleWriteMessageClick,
    onParticipantsClick: handleParticipantsClick,
    startNewChat: startNewChat,
    onWriteMessageModalClose: handleWriteMessageModalClose,
    groupChatParticipantsToShow,
  };
}
