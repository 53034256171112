import { useMemo } from 'react';
import { useTherapistTitles } from 'common/query/__generated__/get-therapist-titles';
import { transformToOptions } from 'lib/utils/transform-to-options';
import { sortOptionsByAlphaBet } from 'lib/utils/transfrom-options-to-alpha-bet';

export function useTherapistTitlesOptions() {
  const therapistTitlesQuery = useTherapistTitles();

  return useMemo(
    () =>
      sortOptionsByAlphaBet(
        transformToOptions(therapistTitlesQuery.data?.therapistTitles),
      ),
    [therapistTitlesQuery],
  );
}
