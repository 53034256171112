import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AdminChangeSessionPriceVariables = Types.Exact<{
  input: Types.ChangeSessionPriceInput;
}>;


export type AdminChangeSessionPrice = (
  { __typename: 'Mutation' }
  & { adminChangeSessionPrice: (
    { __typename: 'SessionPriceAdminPayload' }
    & { sessionPrices?: Types.Maybe<(
      { __typename: 'SessionPriceModel' }
      & Pick<Types.SessionPriceModel, 'id' | 'thirtyMin' | 'fiftyMin' | 'eightyMin'>
    )>, userErrors: Array<(
      { __typename: 'ValidationError' }
      & Pick<Types.ValidationError, 'field' | 'messages'>
    )> }
  ) }
);


export const AdminChangeSessionPriceDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AdminChangeSessionPrice"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ChangeSessionPriceInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminChangeSessionPrice"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sessionPrices"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"thirtyMin"}},{"kind":"Field","name":{"kind":"Name","value":"fiftyMin"}},{"kind":"Field","name":{"kind":"Name","value":"eightyMin"}}]}},{"kind":"Field","name":{"kind":"Name","value":"userErrors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"field"}},{"kind":"Field","name":{"kind":"Name","value":"messages"}}]}}]}}]}}]} as unknown as DocumentNode;
export type AdminChangeSessionPriceMutationFn = Apollo.MutationFunction<AdminChangeSessionPrice, AdminChangeSessionPriceVariables>;

/**
 * __useAdminChangeSessionPrice__
 *
 * To run a mutation, you first call `useAdminChangeSessionPrice` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminChangeSessionPrice` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminChangeSessionPrice, { data, loading, error }] = useAdminChangeSessionPrice({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminChangeSessionPrice(baseOptions?: Apollo.MutationHookOptions<AdminChangeSessionPrice, AdminChangeSessionPriceVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminChangeSessionPrice, AdminChangeSessionPriceVariables>(AdminChangeSessionPriceDocument, options);
      }
export type AdminChangeSessionPriceHookResult = ReturnType<typeof useAdminChangeSessionPrice>;
export type AdminChangeSessionPriceMutationResult = Apollo.MutationResult<AdminChangeSessionPrice>;
export type AdminChangeSessionPriceMutationOptions = Apollo.BaseMutationOptions<AdminChangeSessionPrice, AdminChangeSessionPriceVariables>;