import React from 'react';
import { components, IndicatorProps } from 'react-select';
import { Option } from '../../date-select';
import { ArrowDownIcon } from 'ui';
import { getSize } from 'lib/utils';
import styled from 'styled-components';

export interface DropdownIndicatorProps extends IndicatorProps<Option, any> {
  stroke: string;
}

function DropdownIndicator({ stroke, ...rest }: DropdownIndicatorProps) {
  return (
    <components.DropdownIndicator {...rest}>
      {Icon && <Icon stroke={stroke} />}
    </components.DropdownIndicator>
  );
}

const Icon =
  ArrowDownIcon &&
  styled(ArrowDownIcon)`
    width: ${getSize(22)};
    height: ${getSize(22)};
  `;

export default DropdownIndicator;
