function MessageIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="#E5E4E8"
      viewBox="0 0 22 22"
      {...rest}>
      <path d="M15.4 17.417l-2.567 3.208-2.566-3.208H5.5a.916.916 0 01-.917-.917V6.511a.917.917 0 01.917-.916h14.667a.917.917 0 01.916.916v9.99a.917.917 0 01-.916.916H15.4zM1.833 1.833h15.584v1.834H2.75V13.75H.917v-11a.917.917 0 01.916-.917z" />
    </svg>
  );
}

export default MessageIcon;
