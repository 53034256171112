import React, { useState, useCallback, useMemo } from 'react';
import { Paper } from 'ui/paper';
import {
  useAdminTherapistSearch,
  TherapistFragment,
} from 'common/query/__generated__/get-therapists-by-request';
import { TherapistRequestStatus } from '__generated__/types';
import { useDebounceCallback } from '@react-hook/debounce';
import { TherapistRequests } from 'components/therapist-requests';

const MAX_VISIBLE_THERAPISTS = 10;

const RequestsPage = () => {
  const [filter, setFilter] = useState('');

  const therapistsByRequestQuery = useAdminTherapistSearch({
    variables: {
      first: MAX_VISIBLE_THERAPISTS,
      filter: { status: TherapistRequestStatus.Created, searchName: filter },
    },
  });

  const therapistsList: TherapistFragment[] = useMemo(
    () => therapistsByRequestQuery.data?.adminTherapistsSearch.nodes || [],
    [therapistsByRequestQuery.data?.adminTherapistsSearch.nodes],
  );

  const handleSearchMessageFormSubmit = useCallback((searchText?: string) => {
    const searchPhrase = searchText?.trim() || '';
    setFilter(searchPhrase);
  }, []);

  const debounceHandleSearchFormSubmit = useDebounceCallback(
    handleSearchMessageFormSubmit,
    250,
  );

  return (
    <Paper
      searchRequest={debounceHandleSearchFormSubmit}
      isRequest
      title="Requests">
      <TherapistRequests
        onNext={() =>
          therapistsByRequestQuery.fetchMore({
            variables: {
              filter: {
                status: TherapistRequestStatus.Created,
                searchName: filter,
              },
              after:
                therapistsByRequestQuery.data?.adminTherapistsSearch.pageInfo
                  .endCursor,
            },
          })
        }
        isLoading={therapistsByRequestQuery.loading}
        hasMore={Boolean(
          therapistsByRequestQuery.data?.adminTherapistsSearch.pageInfo
            .hasNextPage,
        )}
        dataLength={therapistsList.length}
        therapists={therapistsList}
      />
    </Paper>
  );
};

export default RequestsPage;
