import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled, { css, CSSProp } from 'styled-components';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import { API_DATE_FORMAT, ISO_DATE_FORMAT } from 'lib/constants/date';
import { getSize, TimeUtil } from 'lib/utils';
import { formatDate, parseDate } from './utils/parse-utils';
import { useWindowClick } from 'hooks';

import { ArrowDownIcon, CalendarWideIcon } from 'ui/icons';
import { Weekday, Navbar, DateOfBirthNavbar } from 'ui/calendar-components';
import { WeekdayElementProps } from 'react-day-picker';
import THEMES from './utils/themes';

type DateInputThemes = 'primary' | 'secondary';

export interface DatePickerInputProps extends FieldRenderProps<string> {
  label?: string;
  rootCSS?: CSSProp;
  inputCSS?: CSSProp;
  iconCSS?: CSSProp;
  labelCSS?: CSSProp;
  disabled?: boolean;
  isDateOfBirth?: boolean;
  placeholder?: string;
  inputFormat?: string;
  hasLine?: boolean;
  disabledPastDay?: boolean;
  disabledFutureDay?: boolean;
  arrowStroke?: string;
  hasBookingAvailability?: boolean;
  withCalendarIcon?: boolean;
  renderDay?: (date: Date) => React.ReactNode;
  selectedDays?: Date | Date[];
  onDayClick?: () => void;
  onMonthChange?: (month: Date) => void;
  timeOfAvailabilityInMonth?: { label: string; value: string }[][];
  handleOuterWrapperFocus?: (isFocused: boolean) => void;
  weekdayElement?: (props: WeekdayElementProps) => JSX.Element;
  theme?: DateInputThemes;
  hasArrowIcon?: boolean;
}

function DatePickerInput({
  input,
  meta,
  label,
  rootCSS,
  inputCSS,
  iconCSS,
  labelCSS,
  disabled,
  isDateOfBirth,
  placeholder,
  inputFormat,
  hasLine = true,
  disabledPastDay,
  disabledFutureDay,
  arrowStroke,
  hasBookingAvailability = false,
  withCalendarIcon = false,
  renderDay,
  onMonthChange,
  selectedDays,
  timeOfAvailabilityInMonth,
  handleOuterWrapperFocus,
  weekdayElement,
  hasArrowIcon = false,
  theme = 'primary',
  onDayClick,
}: DatePickerInputProps) {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [dayPickerInput, setDayPickerInput] = useState<DayPickerInput | null>(
    null,
  );
  const [selectedDate, setSelectedDate] = useState(TimeUtil.getStartOFNowDay());

  useEffect(() => {
    if (dayPickerInput) {
      if (isInputFocused) {
        dayPickerInput.showDayPicker();
      } else {
        dayPickerInput.hideDayPicker();
      }
    }
  }, [dayPickerInput, handleOuterWrapperFocus, isInputFocused]);

  useWindowClick(() => setIsInputFocused(false));

  useEffect(() => {
    if (isDateOfBirth && input.value && isInputFocused) {
      const newDate = TimeUtil.getStartOfDay(input.value, API_DATE_FORMAT);
      setSelectedDate(newDate);
    }
  }, [isDateOfBirth, input.value, isInputFocused]);

  const { error, submitError, touched, dirtySinceLastSubmit, data } = meta;
  const hasError =
    ((error || submitError) && touched && !dirtySinceLastSubmit) || data?.error;

  const handleDayChange = useCallback(
    (selectedDate: Date) => {
      if (timeOfAvailabilityInMonth) {
        handleOuterWrapperFocus && handleOuterWrapperFocus(false);
        onDayClick && onDayClick();
        input.onChange(selectedDate);
      } else {
        const newDate = TimeUtil.parse(
          selectedDate.toISOString(),
          ISO_DATE_FORMAT,
        ).format(API_DATE_FORMAT);

        input.onChange(newDate);
      }
    },
    [handleOuterWrapperFocus, input, onDayClick, timeOfAvailabilityInMonth],
  );

  const onInputClick = () => {
    setIsInputFocused(!isInputFocused);
    handleOuterWrapperFocus && handleOuterWrapperFocus(!isInputFocused);
  };

  const disabledDays = useMemo(() => {
    if (disabledPastDay) {
      return { before: new Date() };
    } else if (disabledFutureDay) {
      return { after: new Date() };
    }
  }, [disabledPastDay, disabledFutureDay]);

  const selectedMonth = !Array.isArray(selectedDays) ? selectedDays : undefined;

  return (
    <Wrapper as={label ? 'label' : 'div'} $CSS={rootCSS} theme={theme}>
      {label && (
        <Text $isDisabled={disabled} $CSS={labelCSS}>
          {label}
        </Text>
      )}

      <Inner
        onClick={(e) => {
          hasBookingAvailability
            ? setIsInputFocused(!isInputFocused)
            : e.stopPropagation();
        }}
        $hasBookingAvailability={hasBookingAvailability}>
        {withCalendarIcon && <CalendarIcon />}
        <DayPickerInput
          keepFocus={false}
          ref={(el) => {
            if (el) {
              setDayPickerInput(el);
            }
          }}
          formatDate={formatDate}
          parseDate={parseDate}
          format={inputFormat || API_DATE_FORMAT}
          placeholder={placeholder || 'Month, day, year'}
          component={Input}
          inputProps={{
            $CSS: inputCSS,
            disabled: disabled,
            readOnly: true,
            onClick: onInputClick,
          }}
          value={input.value ? new Date(input.value) : undefined}
          onDayChange={handleDayChange}
          onDayPickerHide={() => setIsInputFocused(false)}
          dayPickerProps={{
            firstDayOfWeek: hasBookingAvailability ? 0 : 1,
            month: selectedMonth || selectedDate.toDate(),
            weekdayElement: weekdayElement || Weekday,
            captionElement: <></>,
            disabledDays: hasBookingAvailability ? undefined : disabledDays,
            selectedDays,
            onMonthChange,
            navbarElement: (props) =>
              isDateOfBirth ? (
                <DateOfBirthNavbar
                  {...props}
                  isDateOfBirth={isDateOfBirth}
                  getMonth={(month) =>
                    setSelectedDate(selectedDate.month(month))
                  }
                  getYear={(year) => setSelectedDate(selectedDate.year(year))}
                  date={selectedDate}
                />
              ) : (
                <Navbar
                  {...props}
                  // hasBookingAvailability={hasBookingAvailability}
                />
              ),
            renderDay,
          }}
        />
        {(!isDateOfBirth || hasArrowIcon) && (
          <ArrowIcon
            $CSS={iconCSS}
            $isInputFocused={isInputFocused}
            stroke={
              disabled
                ? 'var(--gray4)'
                : arrowStroke || isInputFocused
                ? 'var(--purple)'
                : 'var(--black3)'
            }
          />
        )}
      </Inner>
      {hasLine && (
        <Line
          $hasError={hasError}
          $isInputFocused={isInputFocused}
          $isDisabled={disabled}
        />
      )}

      {hasError && <ErrorText>{error || submitError || data?.error}</ErrorText>}
    </Wrapper>
  );
}

const Wrapper = styled.label<{ $CSS?: CSSProp; theme: DateInputThemes }>`
  ${({ theme }) => THEMES[theme]?.() || ''}
  ${({ $CSS }) => $CSS}
`;

const Inner = styled.div<{ $hasBookingAvailability?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;

  ${({ $hasBookingAvailability }) =>
    $hasBookingAvailability &&
    css`
      height: 100%;
      width: 100%;
    `}
`;

const Text = styled.span<{ $isDisabled?: boolean; $CSS?: CSSProp }>`
  display: block;
  font-weight: 400;
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--gray7);

  ${({ $CSS }) => $CSS}
`;

const Input = styled.input<{ $CSS?: CSSProp }>`
  width: 100%;
  padding: ${getSize(10)} 0 ${getSize(11)};
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(20)};
  color: var(--black3);
  border: 0;

  &:not(:disabled) {
    cursor: pointer;
  }

  &::placeholder {
    color: var(--gray2);
  }

  &:disabled {
    color: var(--gray7);
  }

  ${({ $CSS }) => $CSS}
`;

const CalendarIcon = styled(CalendarWideIcon)`
  margin: 0 ${getSize(12)};
  pointer-events: none;
`;

const ArrowIcon = styled(ArrowDownIcon)<{
  $isInputFocused: boolean;
  $CSS?: CSSProp;
}>`
  position: absolute;
  right: ${getSize(14)};
  transition: 0.2s ease-out;
  transform: scale(${({ $isInputFocused }) => ($isInputFocused ? -1 : 1)});
  pointer-events: none;

  ${({ $CSS }) => $CSS}
`;

const Line = styled.div<{
  $isInputFocused: boolean;
  $hasError: boolean;
  $isDisabled?: boolean;
}>`
  width: 100%;
  height: ${getSize(1)};
  border-radius: ${getSize(8)};
  background: var(
    ${({ $isInputFocused, $hasError, $isDisabled }) => {
      if ($isDisabled) {
        return '--gray4';
      } else if ($hasError) {
        return '--red';
      } else if ($isInputFocused) {
        return '--purple';
      } else {
        return '--purple3';
      }
    }}
  );
  transition: 0.3s ease-out;
`;

const ErrorText = styled.span`
  margin: ${getSize(2)} 0 0;
  font-weight: 400;
  font-size: ${getSize(10)};
  line-height: ${getSize(16)};
  color: var(--red);
`;

export default DatePickerInput;
