import { useDebounceCallback } from '@react-hook/debounce';
import { useGetCancelledRequests } from 'common/query/__generated__/get-cancelled-requests';
import { useGetCancelledSessions } from 'common/query/__generated__/get-cancelled-sessions';
import { Cancellations } from 'components/cancellations';
import { getSize } from 'lib/utils';
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { CSSProp } from 'styled-components';
import { Paper } from 'ui';
import { Tabs } from './components';
import { CancellationsTabs } from './components/tabs/tabs';
import { useSetAdminLastVisit } from 'common/mutation/__generated__/set-last-visit';

const PAPER_ROOT_CSS: CSSProp = {
  position: 'relative',
};

const PAPER_TITLE_WRAPPER_CSS = {
  justifyContent: 'flex-end',
  marginBottom: getSize(32),
};

const CancellationsPage = () => {
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [activeTab, setActiveTab] = useState<CancellationsTabs>(
    CancellationsTabs.REQUESTS,
  );
  const [setLastVisitToCancellations] = useSetAdminLastVisit();

  useEffect(() => {
    return () => {
      setLastVisitToCancellations();
    };
  }, []);

  const handleSearchMessageFormSubmit = useCallback((searchText?: string) => {
    const searchPhrase = searchText?.trim();
    setSearchValue(searchPhrase);
  }, []);

  const debounceHandleSearchFormSubmit = useDebounceCallback(
    handleSearchMessageFormSubmit,
    250,
  );

  const {
    data: cancelledRequests,
    fetchMore: fetchMoreRequests,
    loading: requestsLoading,
  } = useGetCancelledRequests({
    variables: {
      first: 10,
      therapist: { name: searchValue },
    },
  });
  const {
    data: cancelledSessions,
    fetchMore: fetchMoreSessions,
    loading: sessionsLoading,
  } = useGetCancelledSessions({
    variables: {
      first: 10,
      therapist: { name: searchValue },
    },
  });

  const cancelledSessionsList = useMemo(
    () => cancelledSessions?.adminCancelledSessions.nodes || [],
    [cancelledSessions?.adminCancelledSessions.nodes],
  );
  const cancelledRequestsList = useMemo(
    () => cancelledRequests?.adminCancelledRequests.nodes || [],
    [cancelledRequests?.adminCancelledRequests.nodes],
  );
  const dataLength =
    activeTab === CancellationsTabs.SESSIONS
      ? cancelledSessionsList.length
      : cancelledRequestsList.length;
  const data =
    activeTab === CancellationsTabs.SESSIONS
      ? cancelledSessions?.adminCancelledSessions.nodes
      : cancelledRequests?.adminCancelledRequests.nodes;

  const hasNextPage =
    activeTab === CancellationsTabs.SESSIONS
      ? cancelledSessions?.adminCancelledSessions.pageInfo.hasNextPage
      : cancelledRequests?.adminCancelledRequests.pageInfo.hasNextPage;

  const fetchMore =
    activeTab === CancellationsTabs.SESSIONS
      ? () =>
          fetchMoreSessions({
            variables: {
              filter: {
                therapist: { name: searchValue },
              },
              after:
                cancelledSessions?.adminCancelledSessions.pageInfo.endCursor,
            },
          })
      : () =>
          fetchMoreRequests({
            variables: {
              filter: {
                therapist: { name: searchValue },
              },
              after:
                cancelledRequests?.adminCancelledRequests.pageInfo.endCursor,
            },
          });
  return (
    <Paper
      rootCSS={PAPER_ROOT_CSS}
      titleWrapperCSS={PAPER_TITLE_WRAPPER_CSS}
      searchRequest={debounceHandleSearchFormSubmit}>
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
      <Cancellations
        dataLength={dataLength}
        activeTab={activeTab}
        data={data || []}
        onNext={fetchMore}
        isLoading={sessionsLoading || requestsLoading}
        hasMore={Boolean(hasNextPage)}
      />
    </Paper>
  );
};

export default CancellationsPage;
