import React from 'react';

function RedFlagMarker({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="#ED6C6C"
      {...rest}>
      <path d="M1.333 2h12.759a.333.333 0 01.29.499L12 6.667l2.382 4.168a.332.332 0 01-.29.498H2.667v3.334H1.333V2z" />
    </svg>
  );
}

export default RedFlagMarker;
