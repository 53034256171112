import { getSize, validationRules } from 'lib/utils';
import React, { FC, useMemo } from 'react';
import styled, { css } from 'styled-components';
import {
  AdminEditTherapistPersonalInfoInput,
  Gender,
} from '__generated__/types';

import { Field, Form as FinalForm } from 'react-final-form';
import { SingleSelect } from 'ui/form-elements/single-select';
import { Button, Input, PhoneInput } from 'ui';
import { transformEnumToOptions } from 'lib/utils/form';
import { useEditTherapist } from '../../mutations/__generated__/edit-therapist';
import {
  AdminTherapistSearchDocument,
  TherapistFragment,
} from 'common/query/__generated__/get-therapists-by-request';
import { useTherapistTitlesOptions } from './hooks/use-therapist-titles-options';
import { AddressInput } from 'ui/form-elements/address-input';
import { Location } from 'types';

export const EditTherapistForm: FC<EditTherapistFormProps> = ({
  onCloseModal,
  therapist,
}) => {
  const [editTherapist, { loading: editLoading }] = useEditTherapist({
    onCompleted: () => {
      onCloseModal();
    },
    refetchQueries: [
      {
        query: AdminTherapistSearchDocument,
      },
    ],
  });

  const handleFormSubmit = ({ address, ...values }: TherapistFormValues) => {
    const { fullAddress, country, city, postcode } = address;

    const input: AdminEditTherapistPersonalInfoInput = {
      ...values,
      country: country || '',
      city: city || '',
      postcode: postcode || '',
      correspondenceAddress: fullAddress || '',
    };

    editTherapist({
      variables: {
        input,
      },
    });
  };

  const therapistTitlesOptions = useTherapistTitlesOptions();
  const genderOptions = transformEnumToOptions(Gender);

  const initialValues: Partial<TherapistFormValues> = useMemo(
    () => ({
      therapistId: therapist.id,
      firstName: therapist.user.firstName,
      lastName: therapist.user.lastName,
      title: therapist.title?.id,
      gender: therapist.gender || undefined,
      phoneNumber: therapist.user.phoneNumber || undefined,
      address: {
        fullAddress: therapist?.location?.correspondenceAddress,
        city: therapist?.location?.city,
        country: therapist?.location?.country,
        postcode: therapist?.location?.postcode,
      },
    }),
    [therapist],
  );

  return (
    <FinalForm
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      render={({ handleSubmit }) => {
        return (
          <Wrapper>
            <Field
              name="firstName"
              label="First name"
              placeholder="Enter first name"
              validate={validationRules.required}
              inputCSS={INPUT_CSS}
              rootCSS={ROOT_CSS}
              hasLine={false}
              component={Input}
            />
            <Field
              name="lastName"
              label="Last name"
              placeholder="Enter last name"
              validate={validationRules.required}
              inputCSS={INPUT_CSS}
              rootCSS={ROOT_CSS}
              hasLine={false}
              component={Input}
            />

            <Field
              name="title"
              label="Title"
              placeholder="Select title..."
              validate={validationRules.required}
              isSearchable={false}
              inputCSS={INPUT_CSS}
              rootCSS={ROOT_CSS}
              hasLine={false}
              options={therapistTitlesOptions}
              component={SingleSelect}
            />

            <Field
              name="gender"
              label="Gender"
              validate={validationRules.required}
              placeholder="Select gender..."
              isSearchable={false}
              inputCSS={INPUT_CSS}
              rootCSS={ROOT_CSS}
              hasLine={false}
              options={genderOptions}
              component={SingleSelect}
            />

            <Field
              name="phoneNumber"
              label="Contact number"
              validate={validationRules.required}
              placeholder="Enter phone"
              component={PhoneInput}
              rootCSS={ROOT_CSS}
              labelCSS={LABEL_INPUT_CSS}
              inputCSS={PHONE_INPUT_CSS}
            />

            <Field
              name="address"
              label={'Full address'}
              placeholder="Enter full address"
              validate={validationRules.address}
              component={AddressInput}
              rootCSS={ROOT_CSS}
              labelCSS={LABEL_INPUT_CSS}
            />
            <Field
              name="address.postcode"
              label="Post code"
              component={Input}
              disabled
              rootCSS={ROOT_CSS}
              labelCSS={LABEL_INPUT_CSS}
            />

            <CreateCompanyButton
              onClick={handleSubmit}
              isLoading={editLoading}
              type="submit">
              Save
            </CreateCompanyButton>
          </Wrapper>
        );
      }}
    />
  );
};

export interface TherapistFormValues
  extends AdminEditTherapistPersonalInfoInput {
  address: Partial<Location>;
}

interface EditTherapistFormProps {
  onCloseModal: () => void;
  therapist: TherapistFragment;
}

const Wrapper = styled.div`
  padding: ${getSize(11)} ${getSize(27)} 80px ${getSize(27)};
  overflow: scroll;
`;

interface CreateCompanyButton {
  isWhite?: boolean;
}

const LABEL_INPUT_CSS = css`
  font-size: ${getSize(11)};
`;

const PHONE_INPUT_CSS = {
  fontSize: getSize(13),
  lineHeight: getSize(24),
  fontWeight: 500,
};

const CreateCompanyButton = styled(Button)<CreateCompanyButton>`
  height: 42px;
  background: ${({ isWhite }) => (isWhite ? 'white' : '#6b4ee6')};
  border: 1px solid #6b4ee6;
  box-sizing: border-box;
  border-radius: 34px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${({ isWhite }) => (isWhite ? '#6b4ee6' : 'white')};
  font-weight: 600;
  font-size: ${getSize(14)};
`;

const INPUT_CSS = css`
  border: 1px solid var(--gray19);
  border-radius: ${getSize(8)};
  padding: ${getSize(6)} ${getSize(12)};
  min-height: ${getSize(47)};
  font-weight: 500;
  font-size: ${getSize(13)};
  line-height: ${getSize(24)};
  overflow: visible;

  transition: 0.3s ease-out;

  &:focus {
    border-color: var(--purple);
  }

  &:disabled {
    color: var(--gray36);
  }

  &:hover:not(:focus) {
    border-color: var(--black10);
  }

  &:-webkit-autofill:focus {
    transition: 0.3s ease-out;
    border: 1px solid var(--purple) !important;
  }
  &:-webkit-autofill:hover:not(:-webkit-autofill:focus) {
    transition: 0.3s ease-out;
    border: 1px solid var(--black10) !important;
  }
  &:-webkit-autofill {
    transition: 0.3s ease-out;
    border: 1px solid var(--gray36) !important;
  }
`;

const ROOT_CSS = css`
  margin-bottom: ${getSize(10)};
`;

export default EditTherapistForm;
