import { getSize } from 'lib/utils';
import React from 'react';
import styled from 'styled-components';
import { MessageIcon } from 'ui';

export interface EmptyBlockProps {
  text: string;
}

function EmptyBlock({ text }: EmptyBlockProps) {
  return (
    <Wrapper>
      <Icon />
      <Text>{text}</Text>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Icon = styled(MessageIcon)`
  margin: 0 0 ${getSize(16)};
  width: ${getSize(90)};
  height: ${getSize(90)};
`;

const Text = styled.p`
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--gray7);
`;

export default EmptyBlock;
