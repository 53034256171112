import React from 'react';
import Collapsible from 'react-collapsible';
import {
  TimeUtil,
  EVENT_TIME_FORMAT,
  ISO_DATE_FORMAT,
  getSize,
} from 'lib/utils';
import ReactJson from 'react-json-view';
import styled from 'styled-components';
import { BillStatusHistoryFragment } from '../../queries/__generated__/admin-bill-status-history';
import { BillStatus } from '../../../../__generated__/types';
import { RenderBillItemProps } from '../bill-history-list/bill-history-list';

interface TriggerProps<T> extends Omit<RenderBillItemProps<T>, 'onOpen'> {
  $open: boolean;
}

const Trigger = <T extends BillStatusHistoryFragment>({
  item,
  $open,
}: TriggerProps<T>) => {
  return (
    <TriggerWrapper $open={$open} $viewed={!!item.viewedAt}>
      <div>
        {TimeUtil.parse(item.updatedAt, ISO_DATE_FORMAT).format(
          EVENT_TIME_FORMAT,
        )}
      </div>
      <div
        style={{
          padding: '0 15px',
          color: item.status === BillStatus.Collected ? 'white' : 'var(--red)',
        }}>
        {item.status}
      </div>
    </TriggerWrapper>
  );
};

const BillStatusItem = <T extends BillStatusHistoryFragment>({
  item,
  onOpen,
}: RenderBillItemProps<T>) => {
  return (
    <Collapsible
      onOpen={() => onOpen?.(item)}
      containerElementProps={{
        style: {
          borderBottom: '1px solid var(--gray5)',
        },
      }}
      key={item.id}
      trigger={<Trigger item={item} $open={false} />}
      triggerWhenOpen={<Trigger item={item} $open />}
      transitionTime={400}>
      <LogRow>Patient: {item.patientId}</LogRow>
      <LogRow>Therapist: {item.therapistId}</LogRow>
      <LogRow>ProviderReference: {item.providerReference}</LogRow>
      <LogRow>
        <div>Response data:</div>
        <ReactJson
          name={null}
          indentWidth={2}
          enableClipboard={false}
          displayObjectSize={false}
          displayDataTypes={false}
          quotesOnKeys={false}
          src={item.res}
        />
      </LogRow>
    </Collapsible>
  );
};

const LogRow = styled.div`
  border: 0.5px solid var(--gray);
`;

const TriggerWrapper = styled.div<{ $open: boolean; $viewed: boolean }>`
  ${({ $open }) => $open && 'background-color: var(--gray2);'}
  ${({ $viewed }) => ($viewed ? 'font-weight: 500;' : 'font-weight: 600;')}
  cursor: pointer;
  padding: ${getSize(10)};
  /* display: flex;
  justify-content: space-between; */
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  transition: background-color 0.4s ease;
`;

export default BillStatusItem;
