import React, { Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import { authRoutes, dashboardRoutes } from './root-routes';
import { Loader } from '../ui/loader';
import DashboardRoute from './dashboard-route';
import AuthPage from '../pages/auth-page';
import PrivateRoute from './private-route';
import { Callback } from '../pages/auth-page/callback';

const RootRoutes = () => {
  return (
    <Router>
      <Suspense fallback={<Loader hasFillWholeBlock size={50} />}>
        <Switch>
          <PrivateRoute
            exact
            path={authRoutes.auth.PATH}
            component={AuthPage}
          />
          <PrivateRoute
            exact
            path={authRoutes.callback.PATH}
            component={Callback}
          />
          <PrivateRoute
            path={dashboardRoutes.dashboard.PATH}
            component={DashboardRoute}
          />
          <Redirect from="/" to={authRoutes.auth.PATH} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default RootRoutes;
