import React, { useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled, { CSSProp } from 'styled-components';

import { getSize } from 'lib/utils';

import { HideEyeIcon } from 'ui/icons';
import { StrengthLine } from './components';

export interface PasswordInputProps extends FieldRenderProps<string> {
  label?: string;
  rootCSS?: CSSProp;
  inputCSS?: CSSProp;
  placeholder?: string;
  disabled?: boolean;
  checkStrength?: boolean;
  hasErrorText?: boolean;
}

function PasswordInput({
  input,
  meta,
  label,
  rootCSS,
  inputCSS,
  placeholder,
  disabled,
  checkStrength,
  hasErrorText,
}: PasswordInputProps) {
  const [isHidePassword, setIsHidePassword] = useState(true);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const { value } = input;

  const { error, submitError, touched, dirtySinceLastSubmit, data } = meta;
  const hasError =
    ((error || submitError) && touched && !dirtySinceLastSubmit) || data?.error;

  return (
    <Wrapper as={label ? 'label' : 'div'} $CSS={rootCSS}>
      {label && <Text>{label}</Text>}

      <Inner>
        <InputStylized
          $CSS={inputCSS}
          placeholder={placeholder}
          type={isHidePassword ? 'password' : undefined}
          {...input}
          disabled={disabled}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setIsInputFocused(false)}
        />
        <button
          type="button"
          onClick={() => setIsHidePassword((prevValue) => !prevValue)}>
          <EyeIcon fill={`var(${isHidePassword ? '--gray2' : '--purple'})`} />
        </button>
      </Inner>
      <Line $hasError={hasError} $isInputFocused={isInputFocused} />

      {hasErrorText && hasError && (
        <ErrorText>{error || submitError || data?.error}</ErrorText>
      )}
      {checkStrength && <StrengthLine value={value} />}
    </Wrapper>
  );
}

const Wrapper = styled.label<{ $CSS?: CSSProp }>`
  display: block;

  ${({ $CSS }) => $CSS}
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Text = styled.span`
  display: block;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  color: var(--gray7);
`;

const InputStylized = styled.input<{ $CSS?: CSSProp }>`
  width: 100%;
  padding: ${getSize(10)} 0;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--black3);

  &::placeholder {
    color: var(--gray7);
  }

  ${({ $CSS }) => $CSS}
`;

const EyeIcon = styled(HideEyeIcon)`
  margin: 0 0 0 ${getSize(10)};
  width: ${getSize(24)};
  height: ${getSize(24)};
`;

const Line = styled.div<{ $isInputFocused: boolean; $hasError: boolean }>`
  width: 100%;
  height: ${getSize(1)};
  border-radius: ${getSize(8)};
  background: var(
    ${({ $isInputFocused, $hasError }) => {
      if ($hasError) {
        return '--red';
      } else if ($isInputFocused) {
        return '--purple';
      } else {
        return '--purple3';
      }
    }}
  );
  transition: 0.3s ease-out;
`;

const ErrorText = styled.span`
  margin: ${getSize(2)} 0 0;
  font-weight: 400;
  font-size: ${getSize(10)};
  line-height: ${getSize(16)};
  color: var(--red);
`;

export default PasswordInput;
