import React, { ReactNode, RefObject } from 'react';
import styled from 'styled-components';
import { getSize } from '../../lib/utils';

export type Element = 'div' | 'section' | 'li';
export interface ShadowWrapperProps {
  className?: string;
  children: ReactNode;
  asElement?: Element;
  rootRef?: RefObject<HTMLElement>;
}

function ShadowWrapper({
  className,
  children,
  asElement,
  rootRef,
}: ShadowWrapperProps) {
  return (
    <Wrapper ref={rootRef} as={asElement} className={className}>
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: ${getSize(40)};
  box-shadow: 0 ${getSize(10)} ${getSize(30)} var(--blue-opacity2);
  border-radius: ${getSize(8)};
  background: white;
`;

export default ShadowWrapper;
