import MailChecker from 'mailchecker';
import { Location } from 'types';
import {
  floatInputRegExp,
  expDateRegexp,
  monthAndYearRegexp,
  passRegExp,
} from './regexps';

const REGISTRATION_NUMBER_LENGTH = 16;

export const validationRules = {
  required: (value: any) => {
    const currentValue = typeof value === 'string' ? value.trim() : value;
    return currentValue ? undefined : ['Required field'];
  },
  requiredArr: (value: any[]) =>
    value?.length > 0 ? undefined : ['Required field'],
  email: (value: string) => {
    const currentValue = value?.trim();
    if (!currentValue) return ['Please enter your email.'];
    if (!MailChecker.isValid(currentValue)) return ['Email invalid'];
  },
  expDate: (value = '') =>
    expDateRegexp.test(value) ? undefined : ['Incorrect exp date'],
  monthAndYear: (value = '') =>
    monthAndYearRegexp.test(value) ? undefined : ['Incorrect date format'],
  registrationNumber: (value = '') =>
    value.replace(/( )/g, '').length === REGISTRATION_NUMBER_LENGTH
      ? undefined
      : ['Incorrect registration number'],
  minLength: (value: string, minLength: number) =>
    value.length < minLength
      ? [`Must have at least ${minLength} characters`]
      : undefined,
  passStrength: (value: string) =>
    value.match(passRegExp)
      ? undefined
      : 'Password must be 8 characters long and must contain lowercase and uppercase letters and number',
  editableCheckboxRequired: (value: { isCompleted: boolean; text: string }) =>
    value.text ? undefined : ['Required field'],
  priceValue: (value: string) => {
    return Number(value) ? undefined : ['Value must not be empty'];
  },
  address: (value: Location) =>
    value?.fullAddress ? undefined : { error: 'Required field' },
  positiveValue: (value: string) => {
    if (!value) return;
    return Number(value) > 0 ? undefined : ['Value must be a positive number'];
  },
  commissionValue: (value: string) => {
    return floatInputRegExp.test(value) && Number(value) <= 100
      ? undefined
      : ['Value must not be greater than 100'];
  },
};

export const composeValidators =
  (...validators: any) =>
  (value: any, allValues: any, fieldState: any) =>
    validators.reduce(
      (error: any, validator?: any) =>
        error || validator?.(value, allValues, fieldState),
      undefined,
    );
