const MIN_SCREEN_SIZE = 1024;
const DEFAULT_SCREEN_SIZE = 1440;

export function getSize(size = 0) {
  return `calc(clamp(${MIN_SCREEN_SIZE}px, 100vw, ${DEFAULT_SCREEN_SIZE}px) / ${DEFAULT_SCREEN_SIZE} * ${size})`;
}

export function getSizeNumber(size: number) {
  let width = window.innerWidth;
  if (width < MIN_SCREEN_SIZE) {
    width = MIN_SCREEN_SIZE;
  } else if (width > DEFAULT_SCREEN_SIZE) {
    width = DEFAULT_SCREEN_SIZE;
  }
  return (width / DEFAULT_SCREEN_SIZE) * size;
}
