import * as React from 'react';

function BusinessIcon({ ...rest }) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <path
        d="M20.4359 9.13399C20.4354 9.13351 20.4349 9.13303 20.4345 9.13255L11.8672 0.566528C11.5021 0.201233 11.0166 0 10.5001 0C9.9837 0 9.49819 0.201073 9.13286 0.566368L0.570133 9.12806C0.567249 9.13094 0.564364 9.13399 0.56148 9.13687C-0.188413 9.89101 -0.187131 11.1146 0.565166 11.8668C0.908867 12.2106 1.36281 12.4098 1.84816 12.4306C1.86786 12.4325 1.88773 12.4335 1.90776 12.4335H2.24922V18.7376C2.24922 19.985 3.2643 21 4.5122 21H7.86397C8.20366 21 8.47926 20.7246 8.47926 20.3848V15.4424C8.47926 14.8731 8.94234 14.4101 9.51165 14.4101H11.4886C12.0579 14.4101 12.521 14.8731 12.521 15.4424V20.3848C12.521 20.7246 12.7964 21 13.1363 21H16.4881C17.736 21 18.751 19.985 18.751 18.7376V12.4335H19.0677C19.5839 12.4335 20.0694 12.2324 20.4349 11.8671C21.188 11.1136 21.1884 9.88797 20.4359 9.13399V9.13399Z"
        fill="#E5E4E8"
      />
    </svg>
  );
}

export default BusinessIcon;
