import { useGetAdminFeedbackTotalInfo } from 'common/query/__generated__/get-admin-total-feedback';
import React, { FC } from 'react';
import styled from 'styled-components';
import { ReviewsTab } from '__generated__/types';

interface HeaderTableProps {
  ReviewsTab: ReviewsTab;
}

export const HeaderTable: FC<HeaderTableProps> = ({ ReviewsTab }) => {
  const totalFeedback = useGetAdminFeedbackTotalInfo({
    variables: {
      tab: ReviewsTab,
    },
  });
  const totalInfo = totalFeedback?.data?.adminFeedbackTotalInfo;

  const columns = [
    {
      name: 'Total Reviews',
      value: totalInfo?.reviewsAmount,
    },
    { name: 'Rating', value: totalInfo?.totalRating.toFixed(2) },
    { name: 'Progress', value: totalInfo?.progress.toFixed(2) },
    { name: 'MTA Experience', value: totalInfo?.experience.toFixed(2) },
  ];
  return (
    <Wrapper>
      {columns.map((column, index) => (
        <TableInner key={index}>
          <ColumnHeader>{column.name}</ColumnHeader>
          <ColumnValue>{column.value}</ColumnValue>
        </TableInner>
      ))}
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  display: flex;
`;

export const TableInner = styled.div`
  width: 183px;
`;

export const ColumnHeader = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;

  color: #838383;
`;

export const ColumnValue = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;

  display: flex;
  align-items: center;

  color: #0f0f0f;
`;
