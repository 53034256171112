import { LogType } from '__generated__/types';
import { useAdminReadSpecificLog } from '../mutations/__generated__/admin-read-specific-log';
import { BillFragments } from '../types';
import { updateBillLogHistoryCache } from '../utils/update-bill-log-history-cache';

const useReadSpecificLog = ({ logType }: { logType: LogType }) => {
  const [readSpecificLog] = useAdminReadSpecificLog();

  const handleReadSpecificLog = <T extends BillFragments>(log: T) => {
    if (log.viewedAt) {
      return;
    }

    return readSpecificLog({
      variables: { type: logType, logId: log.id },
      optimisticResponse: {
        __typename: 'Mutation',
        readSpecificLog: log.id,
      },
      update: (cache, { data }) => {
        if (data?.readSpecificLog) {
          updateBillLogHistoryCache<T>(cache, logType, log.id);
        }
      },
    });
  };

  return {
    handleReadSpecificLog,
  };
};

export default useReadSpecificLog;
