import { getCorrectUri, getRedirectOrLogoutUrl } from './uri-helper';

export const config = {
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY || '',
  REACT_APP_GQL_URL: process.env.REACT_APP_GQL_URL || '',
  AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN || '',
  AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  AUTH0_REDIRECT_URI: getCorrectUri(
    getRedirectOrLogoutUrl(true),
    process.env.REACT_APP_AUTH0_REDIRECT_URN,
  ),
  AUTH0_LOGOUT_URI: getCorrectUri(
    getRedirectOrLogoutUrl(),
    process.env.REACT_APP_AUTH0_LOGOUT_URN,
  ),
  AUTH0_SCOPE: process.env.REACT_APP_AUTH0_SCOPE || '',
  AUTH0_DB_NAME: process.env.REACT_APP_AUTH0_DB_NAME || '',
  AUTH0_GOOGLE_NAME: process.env.REACT_APP_AUTH0_GOOGLE_NAME || '',
  AUTH0_FACEBOOK_NAME: process.env.REACT_APP_AUTH0_FACEBOOK_NAME || '',
  AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE || '',
  QUALIFICATION_URL: process.env.REACT_APP_UPLOAD_QUALIFICATION_URL || '',
  API_URL: process.env.REACT_APP_API_URL || '',
  STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PK || '',
  WS_HOST_AND_PORT: process.env.REACT_APP_WS_HOST_AND_PORT || '',
  AWS_S3_REPRESENTATION_BUCKET_NAME:
    process.env.REACT_APP_AWS_S3_REPRESENTATION_BUCKET_NAME || '',
};
