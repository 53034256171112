import React, { FC, useState } from 'react';
import styled from 'styled-components';
import ScrollContainer from 'react-indiana-drag-scroll';
import { getSize } from 'lib/utils';
import { PlusIcon } from 'ui/icons';

interface ImagesPreviewProps {
  src: string;
  alt: string;
  className?: string;
}

const ImagesPreview: FC<ImagesPreviewProps> = ({ src, className, alt }) => {
  const [scale, setScale] = useState(100);

  const scaleIncrement = () => {
    scale < 200 && setScale(scale + 10);
  };
  const scaleDecrement = () => {
    scale > 100 && setScale(scale - 10);
  };

  return (
    <Wrapper>
      <ScrollContainer className={className}>
        <ImageWrapper $scale={scale}>
          <img className="preview-image" src={src} alt={alt} />
        </ImageWrapper>
      </ScrollContainer>
      <Controllers>
        <ControllerBtn onClick={scaleIncrement}>
          <PlusIcon />
        </ControllerBtn>
        <ControllerBtn onClick={scaleDecrement}>
          <MinusIcon />
        </ControllerBtn>
      </Controllers>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const ImageWrapper = styled.div<{ $scale: number }>`
  display: block;
  width: ${({ $scale }) => $scale}%;
  height: ${({ $scale }) => $scale}%;

  & > .preview-image {
    max-width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Controllers = styled.div`
  width: ${getSize(36)};
  height: ${getSize(80)};
  position: absolute;
  right: ${getSize(32)};
  bottom: ${getSize(32)};
  display: flex;
  flex-direction: column;
  box-shadow: 0px ${getSize(8)} ${getSize(30)} var(--purple-opacity2);
  border-radius: ${getSize(8)};
  background-color: var(--white);
`;

const ControllerBtn = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MinusIcon = styled.div`
  width: ${getSize(10.5)};
  height: ${getSize(2)};
  border-radius: ${getSize(8)};
  background-color: var(--black3);
`;

export default ImagesPreview;
