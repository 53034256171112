import * as Types from '../../../__generated__/types';

import { SessionPriceAdminFragment } from '../../fragments/__generated__/session-price-admin.fragment';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetSessionPricesVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSessionPrices = (
  { __typename: 'Query' }
  & { getSessionPrices: Array<(
    { __typename: 'SessionPriceModel' }
    & SessionPriceAdminFragment
  )> }
);


export const GetSessionPricesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetSessionPrices"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getSessionPrices"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SessionPriceAdminFragment"}}]}}]}},...SessionPriceAdminFragment.definitions]} as unknown as DocumentNode;

/**
 * __useGetSessionPrices__
 *
 * To run a query within a React component, call `useGetSessionPrices` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionPrices` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionPrices({
 *   variables: {
 *   },
 * });
 */
export function useGetSessionPrices(baseOptions?: Apollo.QueryHookOptions<GetSessionPrices, GetSessionPricesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSessionPrices, GetSessionPricesVariables>(GetSessionPricesDocument, options);
      }
export function useGetSessionPricesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSessionPrices, GetSessionPricesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSessionPrices, GetSessionPricesVariables>(GetSessionPricesDocument, options);
        }
export type GetSessionPricesHookResult = ReturnType<typeof useGetSessionPrices>;
export type GetSessionPricesLazyQueryHookResult = ReturnType<typeof useGetSessionPricesLazyQuery>;
export type GetSessionPricesQueryResult = Apollo.QueryResult<GetSessionPrices, GetSessionPricesVariables>;