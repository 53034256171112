import * as React from 'react';

function PatientIcon({ ...props }) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M23.31 2H10l2.628 4H24V2.75c0-.414-.309-.75-.69-.75zM14.5 16c-2.482 0-4.5 1.928-4.5 4.297V23h9v-2.703C19 17.923 16.989 16 14.5 16zM14.91 14.814c1.06-.158 1.782-1.075 1.61-2.048-.172-.973-1.172-1.634-2.234-1.476-1.061.158-1.782 1.075-1.61 2.048.172.973 1.172 1.633 2.233 1.476z"
        fill="#E5E4E8"
      />
      <path
        d="M11.425 6.855L6.864 1H.706C.316 1 0 1.29 0 1.647v20.706c0 .357.316.647.706.647h7.95v-2.652c0-2.282 1.52-4.245 3.682-5.08-2.171-1.918-.677-5.295 2.33-5.295 3.016 0 4.495 3.38 2.332 5.292 2.145.826 3.68 2.777 3.68 5.083V23h2.614c.39 0 .706-.29.706-.647V7.125H12a.729.729 0 01-.575-.27zM6.73 10.167h-.8v.733c0 .357-.316.647-.705.647-.39 0-.706-.29-.706-.647v-.733h-.8c-.39 0-.706-.29-.706-.647 0-.358.316-.647.706-.647h.8v-.734c0-.357.316-.647.706-.647.39 0 .705.29.705.647v.734h.8c.39 0 .706.29.706.647 0 .357-.316.647-.706.647z"
        fill="#E5E4E8"
      />
    </svg>
  );
}

export default PatientIcon;
