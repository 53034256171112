import { getSize, TimeUtil, validationRules } from 'lib/utils';
import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  CompanyPlanType,
  EditCompanyInput,
  IntegrationType,
  PlanDurationType,
  SessionType,
} from '__generated__/types';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import { SingleSelect } from 'ui/form-elements/single-select';
import { bookingSelectStyles } from 'ui/form-elements/single-select/utils/styles';
import { Button, Checkbox, DatePickerInput, Input } from 'ui';
import { transformEnumToOptions } from 'lib/utils/form';
import { useGetInsuranceCompanies } from 'common/query/__generated__/get-all-insurance-companies';
import { toast } from 'react-toastify';
import { notifyError, notifySuccess } from 'lib/utils/notification';
import { AdminGetCompany } from 'common/query/__generated__/admin-get-company';
import useCopyToClipboard from 'ui/hooks/use-copy-to-clipboard';
import { ConfirmType, SwitchVariants } from 'pages/business-page/business-page';
import { useEditCompany } from 'pages/business-page/mutation/__generated__/admin-edit-company';
import { FormApi } from 'final-form';
import { Option } from '../../../../../ui/drop-down-filter/drop-down-filter';
import { useResendSubscriptionEmail } from '../../../mutation/__generated__/admin-resend-subscription-email';
import { useProfessionalTitles } from 'common/query/__generated__/professional-titles';
import { MultiSelect, Option as MultiSelectOption } from './MultiSelect';

export interface CompanyFormValues {
  activatedAt: string;
  duration: PlanDurationType;
  emailDomain1: string;
  emailDomain2: string;
  emailDomain3: string;
  emailDomain4: string;
  emailDomain5: string;
  emailDomain6: string;
  emailDomain7: string;
  emailDomain8: string;
  emailDomain9: string;
  emailDomain10: string;
  employeeLimit: number;
  planStartDate?: string;
  inPerson: boolean;
  liveChat: boolean;
  videoCall: boolean;
  insurer: string;
  integrationType: IntegrationType;
  invoiceEmail: string;
  managerEmail: string;
  managerName: string;
  name: string;
  planType: CompanyPlanType;
  totalEmployees: number;
  isInsuranceVisible: boolean;
  isUnlimited: boolean;
  AccessCode: string;
  month: number;
  year: number;
  isSlackVisible: boolean;
  slackName: string;
  slackDomain: string;
  slackEmail: string;
  isSessionsPerCompany: boolean;
  isPricePerSessionVisible: boolean;
  durationOptions: Option[];
  pricePerSession: string;
  isSubscription: boolean;
  sessionProfessionalTitles: MultiSelectOption[];
  sessionTypes: MultiSelectOption[];
  sessionDurations: MultiSelectOption[];
}

const timezoneOptions = [
  {
    value: 'option 1',
    label: 'option 1',
  },
  {
    value: 'option 2',
    label: 'option 2',
  },
  {
    value: 'option 3',
    label: 'option 3',
  },
];

const INPUT_CSS = css`
  border: 1px solid var(--gray19);
  border-radius: ${getSize(8)};
  padding: ${getSize(6)} ${getSize(12)};
  min-height: ${getSize(47)};
  font-weight: 500;
  font-size: ${getSize(13)};
  line-height: ${getSize(24)};
  overflow: visible;

  transition: 0.3s ease-out;

  &:focus {
    border-color: var(--purple);
  }

  &:disabled {
    color: var(--gray36);
  }

  &:hover:not(:focus) {
    border-color: var(--black10);
  }

  &:-webkit-autofill:focus {
    transition: 0.3s ease-out;
    border: 1px solid var(--purple) !important;
  }
  &:-webkit-autofill:hover:not(:-webkit-autofill:focus) {
    transition: 0.3s ease-out;
    border: 1px solid var(--black10) !important;
  }
  &:-webkit-autofill {
    transition: 0.3s ease-out;
    border: 1px solid var(--gray36) !important;
  }
`;

const ROOT_CSS = css`
  margin-bottom: ${getSize(10)};
`;

interface AddCompanyFormProps {
  onCloseModal: () => void;
  openConfirmModal: () => void;
  openReportModal: () => void;
  companyData?: AdminGetCompany;
  setConfirmType: React.Dispatch<React.SetStateAction<ConfirmType>>;
  switchSelect: string;
  onRefetchCompanies: () => void;
  formRef: React.MutableRefObject<FormApi<
    CompanyFormValues,
    Partial<CompanyFormValues>
  > | null>;
}

export const EditCompanyForm: FC<AddCompanyFormProps> = ({
  onCloseModal,
  setConfirmType,
  openReportModal,
  openConfirmModal,
  companyData,
  switchSelect,
  formRef,
  onRefetchCompanies,
}) => {
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const { data } = useProfessionalTitles({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
  });
  const sessionProfessionalTitlesOptions = (data?.professionalTitles || []).map(
    (pt) => ({
      value: pt.id,
      label: pt.label,
    }),
  );
  const sessionDurationsOptions = [
    { value: 30, label: '30 Minutes' },
    { value: 50, label: '50 Minutes' },
    { value: 80, label: '80 Minutes' },
  ];
  const sessionTypesOptions = [
    { value: SessionType.InPerson, label: 'In Person' },
    { value: SessionType.VideoCall, label: 'Video Call' },
    { value: SessionType.LiveChat, label: 'Live Chat' },
  ];

  const onEditClick = () => {
    setIsFormDisabled(false);
  };

  const onCancelClick = () => {
    formRef.current?.reset();
    setIsFormDisabled(true);
  };

  const onRefreshClick = () => {
    setConfirmType(ConfirmType.Refresh);
    openConfirmModal();
  };

  const onArchiveClick = () => {
    setConfirmType(ConfirmType.Archive);
    openConfirmModal();
  };

  const [
    resendSubscriptionEmail,
    { data: companyPlanId, loading: updateCompanyLoading },
  ] = useResendSubscriptionEmail();

  const sendSubscriptionEmail = async () => {
    const companyPlanId = companyPlan?.id;
    if (!companyPlanId) {
      notifyError({
        title: 'Company plan',
        text: 'Company plan is not valid!',
      });
    } else {
      await resendSubscriptionEmail({
        variables: {
          companyPlanId,
        },
      }).catch((reason) =>
        notifyError({
          title: 'Error',
          text: 'error',
        }),
      );

      notifySuccess({
        title: 'Subscription email',
        text: 'Subscription email successfully sent!',
      });
    }
  };

  const [editCompany, { loading: editCompanyLoading }] = useEditCompany();
  const company = companyData?.adminGetCompany;

  const handleFormSubmit = async (values: CompanyFormValues) => {
    let fromDate;

    if (values.planType === CompanyPlanType.SubscriptionPerCompany) {
      if (!values.planStartDate) {
        throw new Error('Plan start date missing');
      }
      const splitDate = values.planStartDate.split('-');

      fromDate =
        new Date(
          parseInt(splitDate[0]),
          parseInt(splitDate[1]),
          parseInt(splitDate[2]),
          0,
          0,
          0,
          0,
        ).getTime() -
        new Date().getTimezoneOffset() * 60000;
    } else {
      fromDate =
        new Date(values.year, values.month, 1, 0, 0, 0, 0).getTime() -
        new Date().getTimezoneOffset() * 60000;
    }

    const companyId = company?.id || '';

    const isSlackIntegration = values.integrationType === IntegrationType.Slack;

    const sessionDurations = values.sessionDurations.map((sd) =>
      parseInt(sd.value, 10),
    );
    const sessionProfessionalTitles = values.sessionProfessionalTitles.map(
      (pt) => pt.value,
    );
    const sessionTypes = values.sessionTypes.map(
      (st) => st.value as unknown as SessionType,
    );

    const sendData: EditCompanyInput = {
      id: companyId,
      name: values.name,
      emailDomains: [
        values.emailDomain1,
        values.emailDomain2,
        values.emailDomain3,
        values.emailDomain4,
        values.emailDomain5,
        values.emailDomain6,
        values.emailDomain7,
        values.emailDomain8,
        values.emailDomain9,
        values.emailDomain10,
      ].filter((domain) => domain !== undefined),
      totalEmployees: +values.totalEmployees,
      invoiceEmail: values.invoiceEmail,
      manager: { name: values.managerName, email: values.managerEmail },
      plan: {
        planType: values.planType,
        employeeLimit: values.employeeLimit ? +values.employeeLimit : undefined,
        activatedAt: new Date(fromDate).toISOString(),
        insuranceCompanyId: values.insurer,
        durationType: values.duration || PlanDurationType.Quarterly,
        sessionPrice: +values.pricePerSession,
      },
      slackManager: isSlackIntegration
        ? {
            name: values.slackName,
            email: values.slackEmail,
          }
        : null,
      slackDomain: isSlackIntegration ? values.slackDomain : null,
      sessionRestrictions: {
        sessionTypes,
        sessionDurations,
        sessionProfessionalTitles,
      },
    };

    try {
      await editCompany({
        variables: {
          input: sendData,
        },
      }).then((response) => {
        const validateErrors = response.data?.editCompany.userErrors;
        if (validateErrors?.length) {
          notifyError({ text: validateErrors[0].messages[0] });
        }
      });
    } catch (error: any) {
      notifyError({ text: error?.message });
    } finally {
      onCloseModal();
      onRefetchCompanies();
    }
  };

  const { data: insuranceCompaniesData } = useGetInsuranceCompanies({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
  });

  const insuranceOptions = insuranceCompaniesData?.insuranceCompanies.map(
    (insuranceCompany) => {
      return {
        value: insuranceCompany.id,
        label: insuranceCompany.label,
      };
    },
  );

  const companyPlan =
    companyData?.adminGetCompany.currentPlan ||
    companyData?.adminGetCompany.nextPlan;

  const initialValues = {
    activatedAt: company?.createdAt,
    duration: companyPlan?.durationType,
    emailDomain1: company?.emailDomains[0],
    emailDomain2: company?.emailDomains[1],
    emailDomain3: company?.emailDomains[2],
    emailDomain4: company?.emailDomains[3],
    emailDomain5: company?.emailDomains[4],
    emailDomain6: company?.emailDomains[5],
    emailDomain7: company?.emailDomains[6],
    emailDomain8: company?.emailDomains[7],
    emailDomain9: company?.emailDomains[8],
    emailDomain10: company?.emailDomains[9],
    planStartDate: companyPlan?.activatedAt,
    planEndDate: companyPlan?.activeTo,
    employeeLimit: company?.currentPlan?.employeeLimit,
    insurer: companyPlan?.insuranceCompanyId,
    integrationType: company?.integrationType,
    invoiceEmail: company?.invoiceEmail,
    managerEmail: company?.manager.email,
    managerName: company?.manager.name,
    name: company?.name,
    planType: companyPlan?.planType,
    totalEmployees: company?.totalEmployees,
    isInsuranceVisible:
      companyPlan?.planType === CompanyPlanType.Insurance ||
      companyPlan?.planType === CompanyPlanType.InsuranceLimited,
    isUnlimited: true,
    AccessCode: company?.companyKey,
    month:
      company?.createdAt && new Date(company?.createdAt).getMonth().toString(),
    year: company?.createdAt && new Date(company?.createdAt).getFullYear(),
    slackName: company?.slackManager?.name,
    slackEmail: company?.slackManager?.email,
    slackDomain: company?.slackDomain,
    pricePerSession: companyPlan?.sessionPrice,
    isSubscription:
      companyPlan?.planType === CompanyPlanType.SubscriptionPerCompany ||
      companyPlan?.planType === CompanyPlanType.SubscriptionPerEmployee,
    sessionDurations:
      company?.sessionRestrictions.sessionDurations.map((sd) =>
        sessionDurationsOptions.find((op) => op.value === sd),
      ) || sessionDurationsOptions,
    sessionTypes:
      company?.sessionRestrictions.sessionTypes.map((st) =>
        sessionTypesOptions.find((op) => op.value === st),
      ) ||
      [
        { value: SessionType.InPerson, label: 'In Person' },
        { value: SessionType.VideoCall, label: 'Video Call' },
        companyPlan?.planType &&
          (companyPlan?.planType === CompanyPlanType.Unlimited ||
            companyPlan?.planType === CompanyPlanType.LimitedPerEmployee ||
            companyPlan?.planType === CompanyPlanType.SubscriptionPerCompany ||
            companyPlan?.planType ===
              CompanyPlanType.SubscriptionPerEmployee) && {
            value: SessionType.LiveChat,
            label: 'Live Chat',
          },
      ].filter((st) => Boolean(st)),
    sessionProfessionalTitles:
      company?.sessionRestrictions.sessionProfessionalTitles.map((pt) =>
        sessionProfessionalTitlesOptions.find((op) => op.value === pt),
      ) || [],
  };

  const [value, copy] = useCopyToClipboard();

  const onCopyClick = (text: string) => {
    copy(text);
    toast('Access code copied');
  };

  const months = Array.from(Array(12).keys()).map((key) => {
    return {
      value: String(key),
      label: new Date(0, key).toLocaleString('en', { month: 'long' }),
    };
  });
  const year = Array.from(Array(5).keys()).map((key) => {
    return {
      value: new Date().getFullYear() + key,
      label: new Date().getFullYear() + key,
    };
  });

  const autoRenewal =
    companyData?.adminGetCompany.currentPlan?.activeTo ??
    companyData?.adminGetCompany.nextPlan?.activeTo;

  return (
    <FinalForm
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      render={({ handleSubmit, values, form }) => {
        formRef.current = form;
        return (
          <Wrapper>
            <FormSpy
              onChange={({ values }) => {
                const isTypePlanInsurance =
                  values.planType === CompanyPlanType.Insurance ||
                  values.planType === CompanyPlanType.InsuranceLimited;

                const isUnlimited =
                  CompanyPlanType.Unlimited || CompanyPlanType.Insurance;

                values.isInsuranceVisible = isTypePlanInsurance;

                const isTypeIntegrationSlack =
                  values.integrationType === IntegrationType.Slack;

                const isPricePerSessionVisible =
                  values.planType === CompanyPlanType.Mixed ||
                  values.planType === CompanyPlanType.SubscriptionPerCompany ||
                  values.planType === CompanyPlanType.SubscriptionPerEmployee;

                const isSessionsPerCompany =
                  values.planType === CompanyPlanType.SubscriptionPerCompany ||
                  values.planType === CompanyPlanType.LimitedPerCompany;

                switch (values.planType) {
                  case CompanyPlanType.SubscriptionPerEmployee:
                    values.durationOptions = transformEnumToOptions(
                      PlanDurationType,
                    ).filter(
                      (option) =>
                        option.value != 'QUARTERLY' && option.value != 'ANNUAL',
                    );
                    break;
                  case CompanyPlanType.SubscriptionPerCompany:
                  case CompanyPlanType.LimitedPerCompany:
                    values.durationOptions =
                      transformEnumToOptions(PlanDurationType);
                    break;
                  default:
                    values.durationOptions = transformEnumToOptions(
                      PlanDurationType,
                    ).filter((option) => option.value != 'MONTHLY');
                    break;
                }

                values.isSessionsPerCompany = isSessionsPerCompany;
                values.isPricePerSessionVisible = isPricePerSessionVisible;
                values.isSlackVisible = isTypeIntegrationSlack;
                values.isUnlimited = isUnlimited;
              }}
            />
            <Field
              name="name"
              label="Company"
              placeholder="Enter company name"
              validate={validationRules.required}
              inputCSS={INPUT_CSS}
              rootCSS={ROOT_CSS}
              hasLine={false}
              component={Input}
              disabled={isFormDisabled}
            />
            <Field
              name="managerName"
              label="Contact person"
              placeholder="Enter contact person name"
              validate={validationRules.required}
              inputCSS={INPUT_CSS}
              rootCSS={ROOT_CSS}
              hasLine={false}
              component={Input}
              disabled={isFormDisabled}
            />
            <Field
              name="managerEmail"
              label="Contact email"
              placeholder="Enter contact email"
              validate={validationRules.required}
              inputCSS={INPUT_CSS}
              rootCSS={ROOT_CSS}
              hasLine={false}
              component={Input}
              disabled={isFormDisabled}
            />

            <Field
              name="emailDomain1"
              label="Email Domain 1"
              placeholder="@your_domain"
              validate={validationRules.required}
              inputCSS={INPUT_CSS}
              rootCSS={ROOT_CSS}
              hasLine={false}
              component={Input}
              disabled={isFormDisabled}
            />
            <Field
              name="emailDomain2"
              label="Email Domain 2"
              placeholder="@your_domain"
              inputCSS={INPUT_CSS}
              rootCSS={ROOT_CSS}
              hasLine={false}
              component={Input}
              disabled={isFormDisabled}
            />
            <Field
              name="emailDomain3"
              label="Email Domain 3"
              placeholder="@your_domain"
              inputCSS={INPUT_CSS}
              rootCSS={ROOT_CSS}
              hasLine={false}
              component={Input}
              disabled={isFormDisabled}
            />
            <Field
              name="emailDomain4"
              label="Email Domain 4"
              placeholder="@your_domain"
              inputCSS={INPUT_CSS}
              rootCSS={ROOT_CSS}
              hasLine={false}
              component={Input}
              disabled={isFormDisabled}
            />
            <Field
              name="emailDomain5"
              label="Email Domain 5"
              placeholder="@your_domain"
              inputCSS={INPUT_CSS}
              rootCSS={ROOT_CSS}
              hasLine={false}
              component={Input}
              disabled={isFormDisabled}
            />
            <Field
              name="emailDomain6"
              label="Email Domain 6"
              placeholder="@your_domain"
              inputCSS={INPUT_CSS}
              rootCSS={ROOT_CSS}
              hasLine={false}
              component={Input}
              disabled={isFormDisabled}
            />
            <Field
              name="emailDomain7"
              label="Email Domain 7"
              placeholder="@your_domain"
              inputCSS={INPUT_CSS}
              rootCSS={ROOT_CSS}
              hasLine={false}
              component={Input}
              disabled={isFormDisabled}
            />
            <Field
              name="emailDomain8"
              label="Email Domain 8"
              placeholder="@your_domain"
              inputCSS={INPUT_CSS}
              rootCSS={ROOT_CSS}
              hasLine={false}
              component={Input}
              disabled={isFormDisabled}
            />
            <Field
              name="emailDomain9"
              label="Email Domain 9"
              placeholder="@your_domain"
              inputCSS={INPUT_CSS}
              rootCSS={ROOT_CSS}
              hasLine={false}
              component={Input}
              disabled={isFormDisabled}
            />
            <Field
              name="emailDomain10"
              label="Email Domain 10"
              placeholder="@your_domain"
              inputCSS={INPUT_CSS}
              rootCSS={ROOT_CSS}
              hasLine={false}
              component={Input}
              disabled={isFormDisabled}
            />
            <Field
              name="invoiceEmail"
              label="Invoice email"
              placeholder="Enter invoice email"
              inputCSS={INPUT_CSS}
              rootCSS={ROOT_CSS}
              hasLine={false}
              component={Input}
              disabled={isFormDisabled}
              validate={validationRules.required}
            />
            <FieldWrapper>
              <Field
                name="AccessCode"
                label="Access Code"
                placeholder="Enter invoice email"
                inputCSS={INPUT_CSS}
                rootCSS={ROOT_CSS}
                hasLine={false}
                component={Input}
                disabled={isFormDisabled}
              />
              <FieldButtonContainer>
                <FieldButton
                  isDisabled={isFormDisabled}
                  onClick={onRefreshClick}
                  isFormDisabled={isFormDisabled}>
                  Refresh
                </FieldButton>
                <FieldButton onClick={() => onCopyClick(values.AccessCode)}>
                  Copy
                </FieldButton>
              </FieldButtonContainer>
            </FieldWrapper>

            <Field
              name="totalEmployees"
              label="Total employees"
              placeholder="Enter total employees"
              inputCSS={INPUT_CSS}
              rootCSS={ROOT_CSS}
              hasLine={false}
              type="number"
              component={Input}
              disabled={isFormDisabled}
            />

            <InfoRowsContainer>
              <InfoContainer>
                <InfoTextContainer>
                  <InfoLabel>Contract status: </InfoLabel>{' '}
                  <InfoValue>
                    {switchSelect === SwitchVariants.Active
                      ? 'Active'
                      : 'Archive'}
                  </InfoValue>
                </InfoTextContainer>
                <FieldButton
                  isDisabled={isFormDisabled}
                  onClick={onArchiveClick}
                  isFormDisabled={isFormDisabled}>
                  {switchSelect === SwitchVariants.Active
                    ? 'Archive'
                    : 'Set Active'}
                </FieldButton>
              </InfoContainer>

              <LineSeparator marginTop={15} />

              {values.isSubscription && (
                <InfoContainer>
                  <InfoTextContainer>
                    <InfoLabel>Subscription status: </InfoLabel>{' '}
                    <InfoValue>
                      {companyPlan?.isActive
                        ? 'Subscription is active!'
                        : 'Subscription is not active!'}
                    </InfoValue>
                  </InfoTextContainer>
                  {!companyPlan?.isActive && (
                    <FieldButton
                      isDisabled={isFormDisabled}
                      onClick={() => sendSubscriptionEmail()}
                      isFormDisabled={isFormDisabled}>
                      Send subscription email
                    </FieldButton>
                  )}
                </InfoContainer>
              )}

              {values.isSubscription && <LineSeparator marginTop={15} />}

              <InfoContainer>
                {autoRenewal && (
                  <InfoTextContainer>
                    <InfoLabel>Auto renewal: </InfoLabel>{' '}
                    <InfoValue>
                      {TimeUtil.getFormatted(autoRenewal, 'DD MMM YYYY')}
                    </InfoValue>
                  </InfoTextContainer>
                )}

                <FieldButton
                  isDisabled={isFormDisabled}
                  isFormDisabled={isFormDisabled}
                  onClick={openReportModal}>
                  Generate Report
                </FieldButton>
              </InfoContainer>

              <LineSeparator marginTop={15} />
            </InfoRowsContainer>

            <Field
              name="integrationType"
              label="Integration Type"
              options={transformEnumToOptions(IntegrationType)}
              customSelectStyles={bookingSelectStyles}
              isSearchable={false}
              inputCSS={INPUT_CSS}
              rootCSS={ROOT_CSS}
              hasLine={false}
              validate={validationRules.required}
              component={SingleSelect}
              disabled={isFormDisabled}
            />
            {values.isSlackVisible && (
              <>
                <Field
                  name="slackName"
                  label="Slack name"
                  placeholder="Enter Slack name"
                  inputCSS={INPUT_CSS}
                  rootCSS={ROOT_CSS}
                  hasLine={false}
                  component={Input}
                  validate={validationRules.required}
                />
                <Field
                  name="slackDomain"
                  label="Slack URL"
                  placeholder="Enter Slack URL"
                  inputCSS={INPUT_CSS}
                  rootCSS={ROOT_CSS}
                  hasLine={false}
                  component={Input}
                  validate={validationRules.required}
                />
                <Field
                  name="slackEmail"
                  label="Slack email"
                  placeholder="Enter Slack email"
                  inputCSS={INPUT_CSS}
                  rootCSS={ROOT_CSS}
                  hasLine={false}
                  component={Input}
                  validate={validationRules.required}
                />
              </>
            )}
            <Field
              name="planType"
              label="Plan"
              customSelectStyles={bookingSelectStyles}
              isSearchable={false}
              rootCSS={ROOT_CSS}
              inputCSS={INPUT_CSS}
              hasLine={false}
              disabled={isFormDisabled}
              render={({ input, meta, ref, ...outerProps }) => {
                const onChange = (e: CompanyPlanType) => {
                  form.change('planType', e);
                  form.change('employeeLimit', undefined);
                };
                return (
                  <SingleSelect
                    selectRef={ref}
                    options={transformEnumToOptions(CompanyPlanType)}
                    input={{ ...input, onChange }}
                    meta={meta}
                    validate={validationRules.required}
                    {...outerProps}
                  />
                );
              }}
            />
            {values.isPricePerSessionVisible && (
              <Field
                name="pricePerSession"
                label="Price per Session"
                placeholder="Price per Session"
                inputCSS={INPUT_CSS}
                rootCSS={ROOT_CSS}
                hasLine={false}
                disabled={isFormDisabled}
                type="number"
                component={Input}
                validate={validationRules.required}
              />
            )}
            {values.isInsuranceVisible && (
              <Field
                name="insurer"
                label="Insurer"
                options={insuranceOptions}
                customSelectStyles={bookingSelectStyles}
                isSearchable={false}
                rootCSS={ROOT_CSS}
                inputCSS={INPUT_CSS}
                hasLine={false}
                component={SingleSelect}
                disabled={isFormDisabled}
                validate={validationRules.required}
              />
            )}
            <ThreeFieldWrapper>
              {values.planType === CompanyPlanType.SubscriptionPerCompany ? (
                <>
                  <Field
                    name="planStartDate"
                    label="Plan start date"
                    options={months}
                    customSelectStyles={bookingSelectStyles}
                    isSearchable={false}
                    rootCSS={ROOT_CSS}
                    inputCSS={INPUT_CSS}
                    hasLine={false}
                    component={DatePickerInput}
                    validate={validationRules.required}
                    disabled={isFormDisabled}
                  />
                  <Field
                    name="planEndDate"
                    label="Plan end date"
                    options={months}
                    customSelectStyles={bookingSelectStyles}
                    isSearchable={false}
                    rootCSS={ROOT_CSS}
                    inputCSS={INPUT_CSS}
                    hasLine={false}
                    component={DatePickerInput}
                    validate={validationRules.required}
                    disabled={true}
                  />
                </>
              ) : (
                <MonthAndYearContainer>
                  <Field
                    name="month"
                    label="Month"
                    options={months}
                    customSelectStyles={bookingSelectStyles}
                    isSearchable={false}
                    rootCSS={ROOT_CSS}
                    inputCSS={INPUT_CSS}
                    hasLine={false}
                    component={SingleSelect}
                    validate={validationRules.required}
                    disabled={isFormDisabled}
                  />
                  <Field
                    name="year"
                    label="Year"
                    options={year}
                    customSelectStyles={bookingSelectStyles}
                    isSearchable={false}
                    rootCSS={ROOT_CSS}
                    inputCSS={INPUT_CSS}
                    hasLine={false}
                    component={SingleSelect}
                    validate={validationRules.required}
                    disabled={isFormDisabled}
                  />
                </MonthAndYearContainer>
              )}
              {values.planType != CompanyPlanType.Resources && (
                <Field
                  name="employeeLimit"
                  label={
                    values.isSessionsPerCompany
                      ? 'Sessions per company'
                      : 'Sessions per user'
                  }
                  options={timezoneOptions}
                  customSelectStyles={bookingSelectStyles}
                  isSearchable={false}
                  rootCSS={ROOT_CSS}
                  inputCSS={INPUT_CSS}
                  hasLine={false}
                  type="number"
                  component={Input}
                  disabled={
                    isFormDisabled ||
                    (values.planType !== CompanyPlanType.Mixed &&
                      values.planType !== CompanyPlanType.LimitedPerEmployee &&
                      values.planType !== CompanyPlanType.LimitedPerCompany &&
                      values.planType !== CompanyPlanType.InsuranceLimited &&
                      values.planType !==
                        CompanyPlanType.SubscriptionPerCompany &&
                      values.planType !==
                        CompanyPlanType.SubscriptionPerEmployee)
                  }
                />
              )}
              <Field
                name="duration"
                label="Duration"
                options={values.durationOptions}
                customSelectStyles={bookingSelectStyles}
                isSearchable={false}
                rootCSS={ROOT_CSS}
                inputCSS={INPUT_CSS}
                hasLine={false}
                validate={validationRules.required}
                component={SingleSelect}
                disabled={isFormDisabled}
              />
            </ThreeFieldWrapper>
            <div>
              <Field
                name="sessionDurations"
                label="Session Durations"
                options={sessionDurationsOptions}
                component={MultiSelect}
                disabled={isFormDisabled}
                rootCSS={ROOT_CSS}
              />
            </div>
            <div>
              <Field
                name="sessionTypes"
                label="Session Types"
                options={sessionTypesOptions}
                component={MultiSelect}
                disabled={isFormDisabled}
                rootCSS={ROOT_CSS}
              />
            </div>
            <div>
              <Field
                name="sessionProfessionalTitles"
                label="Professional Titles"
                options={sessionProfessionalTitlesOptions}
                component={MultiSelect}
                disabled={isFormDisabled}
                rootCSS={ROOT_CSS}
              />
            </div>
            <ButtonFieldWrapper>
              {isFormDisabled && (
                <CreateCompanyButton onClick={onEditClick}>
                  Edit
                </CreateCompanyButton>
              )}
              {!isFormDisabled && (
                <EditButtonContainer>
                  <CreateCompanyButton isWhite onClick={onCancelClick}>
                    Cancel
                  </CreateCompanyButton>
                  <CreateCompanyButton
                    onClick={handleSubmit}
                    isLoading={editCompanyLoading}>
                    Save
                  </CreateCompanyButton>
                </EditButtonContainer>
              )}
            </ButtonFieldWrapper>
          </Wrapper>
        );
      }}
    />
  );
};

const Wrapper = styled.div`
  padding: ${getSize(11)} ${getSize(27)} 80px ${getSize(27)};
  overflow: scroll;
`;

interface LineSeparatorProps {
  marginTop?: number;
}

const LineSeparator = styled.div<LineSeparatorProps>`
  height: 1px;
  background: #f1f1f1;
  margin-top: ${({ marginTop }) =>
    marginTop || marginTop === 0 ? marginTop : 24}px;
  margin-bottom: 20px;
`;

const ThreeFieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-column-gap: 9px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  margin-left: -15px;

  & label {
    margin-left: 15px;
  }
`;

const Title = styled.p`
  font-weight: 500;
  font-size: ${getSize(12)};
  margin-bottom: 10px;

  color: #838383;
`;

interface CreateCompanyButton {
  isWhite?: boolean;
}

const CreateCompanyButton = styled(Button)<CreateCompanyButton>`
  height: 42px;
  background: ${({ isWhite }) => (isWhite ? 'white' : '#6b4ee6')};
  border: 1px solid #6b4ee6;
  box-sizing: border-box;
  border-radius: 34px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${({ isWhite }) => (isWhite ? '#6b4ee6' : 'white')};
  font-weight: 600;
  font-size: ${getSize(14)};
`;

const FieldWrapper = styled.div`
  position: relative;
`;

const FieldButtonContainer = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50% + ${getSize(9)}));
  right: 0;
`;

interface FieldButtonProps {
  isFormDisabled?: boolean;
}

const FieldButton = styled(Button)<FieldButtonProps>`
  font-weight: 500;
  font-size: ${getSize(14)};
  text-decoration-line: underline;
  margin-right: ${getSize(18)};
  color: ${({ isFormDisabled }) => (isFormDisabled ? '#838383' : '#6b4ee6')};
  cursor: ${({ isFormDisabled }) =>
    isFormDisabled ? 'not-allowed' : '#6b4ee6'};
  ${({ isFormDisabled }) => (isFormDisabled ? '#838383' : '#6b4ee6')};
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InfoTextContainer = styled.p`
  font-weight: 500;
  font-size: ${getSize(13)};
  line-height: 24px;
  align-items: center;
  color: #838383;
`;

const InfoLabel = styled.span`
  color: #444752;
  font-size: ${getSize(13)};
`;

const InfoValue = styled.span``;

const InfoRowsContainer = styled.div`
  margin-top: 39px;
`;

const EditButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 7px;
`;

const ButtonFieldWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: ${getSize(11)} ${getSize(27)};
  background-color: white;
  border-radius: 10px;
  border-top: 1px solid #f1f1f1;
`;

const MonthAndYearContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 3fr 2fr;
  grid-column-gap: 9px;
`;
