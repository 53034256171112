import { TimeUtil } from './time-util';

export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const EXPIRY_DATE_FORMAT = 'MM/YY';
export const MONTH_AND_FULL_YEAR = 'MM/YYYY';
export const HOURS_AND_MINUTES = 'HH.mm';
export const DATE_TIME = 'hh:mm a';
export const TIME_WITH_TWENTY_FOUR_HOURS = 'HH:mm';
export const FULL_YEAR_AND_MONTH = 'YYYY-MM';
export const ISO_DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';
export const EVENT_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss Z';
export const DATE_AND_HOUR = 'YYYY-MM-DD[T]HH:mm';
export const DATE_MONTH = 'D MMM';
export const FULL_MONTH = 'MMMM';
export const DATE_OF_BIRTHDAY = 'DD.MM.YYYY';
export const DATE_WITH_WEEK = 'ddd, DD MMM YYYY';
export const FULL_DATE = 'DD MMMM YYYY';
export const FULL_DATE_WITH_HOUR = 'DD.MM.YYYY HH:mm';

export function transformStringsToOptions(strings?: string[] | null) {
  return strings?.map((string) => ({ value: string, label: string })) || [];
}

export function getDateStringFromExpDate(expiryDate: string) {
  return TimeUtil.parse(
    new Date(
      Number(`20${expiryDate.slice(3)}`),
      Number(expiryDate.slice(0, 2)) - 1,
    ).toISOString(),
    ISO_DATE_FORMAT,
  ).format(API_DATE_FORMAT);
}
export function getYears(from: number) {
  const to = TimeUtil.now().year();
  const years = [];

  for (let i = from; i <= to; i += 1) {
    years.push(i.toString());
    ``;
  }

  return years;
}

export default getYears;
