import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetInsuranceCompaniesVariables = Types.Exact<{ [key: string]: never; }>;


export type GetInsuranceCompanies = (
  { __typename: 'Query' }
  & { insuranceCompanies: Array<(
    { __typename: 'InsuranceCompanyModel' }
    & insuranceCompaniesFragment
  )> }
);

export type insuranceCompaniesFragment = (
  { __typename: 'InsuranceCompanyModel' }
  & Pick<Types.InsuranceCompanyModel, 'id' | 'logoUrl' | 'label'>
);

export const insuranceCompaniesFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"insuranceCompaniesFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"InsuranceCompanyModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"logoUrl"}},{"kind":"Field","name":{"kind":"Name","value":"label"}}]}}]} as unknown as DocumentNode;
export const GetInsuranceCompaniesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetInsuranceCompanies"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"insuranceCompanies"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"insuranceCompaniesFragment"}}]}}]}},...insuranceCompaniesFragment.definitions]} as unknown as DocumentNode;

/**
 * __useGetInsuranceCompanies__
 *
 * To run a query within a React component, call `useGetInsuranceCompanies` and pass it any options that fit your needs.
 * When your component renders, `useGetInsuranceCompanies` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInsuranceCompanies({
 *   variables: {
 *   },
 * });
 */
export function useGetInsuranceCompanies(baseOptions?: Apollo.QueryHookOptions<GetInsuranceCompanies, GetInsuranceCompaniesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInsuranceCompanies, GetInsuranceCompaniesVariables>(GetInsuranceCompaniesDocument, options);
      }
export function useGetInsuranceCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInsuranceCompanies, GetInsuranceCompaniesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInsuranceCompanies, GetInsuranceCompaniesVariables>(GetInsuranceCompaniesDocument, options);
        }
export type GetInsuranceCompaniesHookResult = ReturnType<typeof useGetInsuranceCompanies>;
export type GetInsuranceCompaniesLazyQueryHookResult = ReturnType<typeof useGetInsuranceCompaniesLazyQuery>;
export type GetInsuranceCompaniesQueryResult = Apollo.QueryResult<GetInsuranceCompanies, GetInsuranceCompaniesVariables>;