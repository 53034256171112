import * as Types from '../../../../__generated__/types';

import { ChatMessageFragment } from '../../query/__generated__/chat-messages';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type MessageAddedVariables = Types.Exact<{ [key: string]: never; }>;


export type MessageAdded = (
  { __typename: 'Subscription' }
  & { messageAdded: (
    { __typename: 'MessageModel' }
    & ChatMessageFragment
  ) }
);


export const MessageAddedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"MessageAdded"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"messageAdded"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChatMessageFragment"}}]}}]}},...ChatMessageFragment.definitions]} as unknown as DocumentNode;

/**
 * __useMessageAdded__
 *
 * To run a query within a React component, call `useMessageAdded` and pass it any options that fit your needs.
 * When your component renders, `useMessageAdded` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageAdded({
 *   variables: {
 *   },
 * });
 */
export function useMessageAdded(baseOptions?: Apollo.SubscriptionHookOptions<MessageAdded, MessageAddedVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<MessageAdded, MessageAddedVariables>(MessageAddedDocument, options);
      }
export type MessageAddedHookResult = ReturnType<typeof useMessageAdded>;
export type MessageAddedSubscriptionResult = Apollo.SubscriptionResult<MessageAdded>;