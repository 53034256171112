import { InMemoryCacheConfig } from '@apollo/client';
import {
  relayStyleOffsetPagination,
  relayStylePagination,
} from '../utils/pagination-helper';
import { relayStylePagination as relayStyleChatMessagesPagination } from '@apollo/client/utilities';

export const cacheOptions: InMemoryCacheConfig = {
  typePolicies: {
    AvailabilityIntervalModel: {
      keyFields: false,
    },
    SessionModel: {
      keyFields: false,
    },
    MessageEdge: {
      keyFields: false,
    },
    Query: {
      fields: {
        adminGetVouchers: relayStyleOffsetPagination(['input', ['search']]),
        adminTherapistsSearch: relayStylePagination(['filter', 'sort']),
        adminPatientsSearch: relayStylePagination(['filter', 'orderBy']),
        chatMessages: relayStyleChatMessagesPagination(['chatId']),
        listChats: relayStylePagination(['limit']),
        adminBillValidateHistory: relayStylePagination([
          'filter',
          ['from', 'to'],
        ]),
        adminBillSubmitHistory: relayStylePagination([
          'filter',
          ['from', 'to'],
        ]),
        adminBillStatusHistory: relayStylePagination(['filter']),
        adminTherapistFeedback: relayStylePagination(['therapistId']),
        adminCancelledSessions: relayStylePagination(['therapist']),
        adminCancelledRequests: relayStylePagination(['therapist']),
        adminUsersFeedback: relayStylePagination(['filter', 'sort']),
        adminFeedbackDescriptions: relayStylePagination(
          ['patientId'],
          'sessionMetaId',
        ),
        adminCompaniesFeedback: relayStylePagination(['companyId']),
      },
    },
  },
};
