import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Button, Modal } from 'ui';

interface DeleteMessagesModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDeleteMessages: () => void;
}

const DeleteMessagesModal: FC<DeleteMessagesModalProps> = ({
  isOpen,
  onClose,
  onDeleteMessages,
}) => {
  return (
    <Modal isVisible={isOpen} onClose={onClose} title="Delete message">
      <Text>Are you sure you want to delete the message?</Text>
      <Button theme="secondary" onClick={onDeleteMessages}>
        Delete
      </Button>
      <CancelButton theme="secondary" onClick={onClose}>
        Cancel
      </CancelButton>
    </Modal>
  );
};

const Text = styled.p`
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--black3);
  margin-bottom: ${getSize(36)};
`;

const CancelButton = styled(Button)`
  color: var(--back3);
  margin-top: ${getSize(11)};
`;

export default DeleteMessagesModal;
