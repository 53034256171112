import { ChatAttachmentFragment } from '../query/__generated__/chat-messages';
import { scrollDialog } from '../utils';
import { useChatMessagesLazyQuery } from '../query/__generated__/chat-messages';
import { useSendMessage } from '../mutation/__generated__/send-message';
import { updateChatListCacheAfterSendMessage } from '../utils';
import {
  getFileSourceWithCorrectUrl,
  getFileUrlWithoutAuthMeta,
} from 'lib/utils';
import { MessageSendFormValues } from '../components/message-send-form/message-send-form';
import { useState, useEffect, useMemo, useRef } from 'react';
import { FileType, FileExtension } from '__generated__/types';
import { useDeleteMessages } from '../mutation/__generated__/delete-messages';

interface UseChatMessagesLogicArgs {
  firstChatId?: string;
  selectedChatId: string;
  participantsCountOfSelectedChat: number;
}

const MAX_VISIBLE_MESSAGES = 20;

export function useChatMessagesLogic({
  firstChatId,
  selectedChatId,
  participantsCountOfSelectedChat,
}: UseChatMessagesLogicArgs) {
  const messageListRef = useRef<HTMLDivElement>(null);
  const [isFetchingNewMessages, setIsFetchingNewMessages] = useState(false);
  const [isMessagesSearchActive, setIsMessagesSearchActive] = useState(false);
  const [messagesSearchPhrase, setMessagesSearchPhrase] = useState('');
  const [attachmentForFullscreen, setAttachmentForFullscreen] =
    useState<ChatAttachmentFragment>();

  const [selectedMessagesForDeleting, setSelectedMessagesForDeleting] =
    useState<string[]>([]);
  const [isOpenDeleteMessageModal, setIsOpenDeleteMessageModal] =
    useState(false);

  const [getChatMessages, chatMessagesQuery] = useChatMessagesLazyQuery();
  const [sendMessage] = useSendMessage({
    update:
      firstChatId === selectedChatId
        ? undefined
        : updateChatListCacheAfterSendMessage,
  });
  const [deleteMessages] = useDeleteMessages();

  useEffect(() => {
    if (!selectedChatId) return;

    getChatMessages({
      variables: {
        chatId: selectedChatId,
        last: MAX_VISIBLE_MESSAGES,
      },
    });
  }, [selectedChatId, getChatMessages]);

  function handleSearchMessageFormSubmit(searchText?: string) {
    const searchPhrase = searchText?.trim() || '';
    if (!messagesSearchPhrase && !searchPhrase) return;

    setMessagesSearchPhrase(searchPhrase);
    startMessagesFetching();
    chatMessagesQuery
      .refetch?.({
        chatId: selectedChatId,
        searchPhrase,
        last: MAX_VISIBLE_MESSAGES,
        first: null,
        after: null,
        before: null,
      })
      .finally(stopMessagesFetching);
  }

  function openMessagesSearch() {
    setIsMessagesSearchActive(true);
  }
  function closeMessagesSearch() {
    setMessagesSearchPhrase('');
    setIsMessagesSearchActive(false);
  }

  function handleSearchCancelClick() {
    closeMessagesSearch();
    refetchMessagesByChatId(selectedChatId);
  }

  function refetchMessagesByChatId(chatId: string) {
    chatMessagesQuery.refetch?.({
      chatId,
      before: null,
      after: null,
      last: MAX_VISIBLE_MESSAGES,
      first: null,
      searchPhrase: '',
    });
  }

  const chatMessages = useMemo(() => {
    const cloneMessages = [
      ...(chatMessagesQuery.data?.chatMessages.edges || []),
    ];

    cloneMessages.reverse();
    return cloneMessages;
  }, [chatMessagesQuery.data?.chatMessages.edges]);

  const isGroupSelectedChat = participantsCountOfSelectedChat > 1;

  function handleSendFormSubmit({
    text: textValue,
    attachments,
  }: MessageSendFormValues) {
    const text = textValue?.trim();
    if ((!text && !attachments.length) || !selectedChatId) return;

    return sendMessage({
      variables: {
        input: {
          text,
          chatId: selectedChatId,
          attachments: attachments.map((attachment) => ({
            ...attachment,
            source: getFileSourceWithCorrectUrl(
              getFileUrlWithoutAuthMeta(attachment.source),
            ),
          })),
        },
      },
    });
  }

  function handleMessageClick(
    messageCursor: string,
    searchedMessageId: string,
  ) {
    closeMessagesSearch();

    chatMessagesQuery
      .refetch?.({
        chatId: selectedChatId,
        after: messageCursor,
        first: MAX_VISIBLE_MESSAGES,
        before: null,
        last: null,
        searchPhrase: '',
      })
      .then(() => {
        chatMessagesQuery
          .refetch?.({
            chatId: selectedChatId,
            before: messageCursor,
            last: MAX_VISIBLE_MESSAGES,
            after: null,
            first: null,
            searchPhrase: '',
          })
          .then(() =>
            scrollDialog({
              messageListRef,
              searchedMessageId,
            }),
          );
      });
  }

  function startMessagesFetching() {
    setIsFetchingNewMessages(true);
  }
  function stopMessagesFetching() {
    setIsFetchingNewMessages(false);
  }

  function fetchMessages(oldScrollHeight?: number, isNext?: boolean) {
    if (!selectedChatId || !chatMessages?.length) return;

    startMessagesFetching();
    return chatMessagesQuery
      .fetchMore?.({
        variables: {
          chatId: selectedChatId,
          searchPhrase: messagesSearchPhrase,
          after: isNext ? chatMessages[0].cursor : null,
          before: isNext ? null : chatMessages[chatMessages.length - 1].cursor,
          first: isNext ? MAX_VISIBLE_MESSAGES : null,
          last: isNext ? null : MAX_VISIBLE_MESSAGES,
        },
      })
      .then((result) => {
        if (oldScrollHeight) {
          const dialogWrapper = messageListRef.current;
          if (!dialogWrapper) return result;

          scrollDialog({
            messageListRef,
            top: -(dialogWrapper.scrollHeight - oldScrollHeight),
          });
        }

        return result;
      })
      .finally(stopMessagesFetching);
  }

  function clearAttachmentForFullscreen() {
    setAttachmentForFullscreen(undefined);
  }

  function onSelectMessageForDeleting(id: string) {
    const isSelectedMessage = selectedMessagesForDeleting.includes(id);

    if (!isSelectedMessage)
      return setSelectedMessagesForDeleting([
        ...selectedMessagesForDeleting,
        id,
      ]);

    setSelectedMessagesForDeleting(
      selectedMessagesForDeleting.filter((messageId) => messageId !== id),
    );
  }

  function onDeleteSelectedMessages() {
    startMessagesFetching();
    return deleteMessages({
      variables: {
        input: {
          messagesIds: selectedMessagesForDeleting,
        },
      },
    }).finally(() => {
      setIsOpenDeleteMessageModal(false);
      stopMessagesFetching();
      setSelectedMessagesForDeleting([]);
    });
  }

  const formattedAttachmentForPreview = useMemo(() => {
    if (attachmentForFullscreen) {
      const { name, format, type, source } = attachmentForFullscreen;

      return {
        fileType: type as FileType,
        extension: format as FileExtension,
        name,
        source,
      };
    }
  }, [attachmentForFullscreen]);

  return {
    isFetchingNewMessages,
    isMessagesSearchActive,
    attachmentForFullscreen,
    refetchMessagesByChatId,
    fetchMessages,
    messagesSearchPhrase,
    chatMessagesQuery,
    chatMessages,
    messageListRef,
    isGroupSelectedChat,
    openMessagesSearch,
    clearAttachmentForFullscreen,
    onSearchCancelClick: handleSearchCancelClick,
    onSearchMessageFormSubmit: handleSearchMessageFormSubmit,
    onSendFormSubmit: handleSendFormSubmit,
    onMessageClick: handleMessageClick,
    onAttachmentClick: setAttachmentForFullscreen,
    formattedAttachmentForPreview,
    onSelectMessageForDeleting,
    selectedMessagesForDeleting,
    onResetSelectedMessagesForDeleting: () =>
      setSelectedMessagesForDeleting([]),
    onOpenDeleteMessageModal: () => setIsOpenDeleteMessageModal(true),
    onCloseDeleteMessageModal: () => setIsOpenDeleteMessageModal(false),
    isOpenDeleteMessageModal,
    onDeleteSelectedMessages,
  };
}
