export type TherapistsRoutes = 'list' | 'cancellations';

export const authRoutes = {
  auth: {
    PATH: '/auth',
  },
  callback: {
    PATH: '/auth/callback',
  },
};

export const dashboardRoutes = {
  dashboard: {
    PATH: '/dashboard',
  },
  requests: {
    PATH: '/dashboard/requests',
  },
  request: {
    PATH: '/dashboard/requests/:id',
    pathBuild: (therapistId: string) => `/dashboard/requests/${therapistId}`,
  },
  therapist: {
    PATH: '/dashboard/therapists/profile/:id',
    pathBuild: (therapistId: string) =>
      `/dashboard/therapists/profile/${therapistId}`,
  },
  therapists: {
    PATH: '/dashboard/therapists/',
    pathBuild: (routeType: TherapistsRoutes) =>
      `/dashboard/therapists/${routeType}`,
  },
  patients: {
    PATH: '/dashboard/patients',
  },
  messaging: {
    PATH: '/dashboard/messaging/:chatId?',
    pathBuild: (chatId?: string) => `/dashboard/messaging/${chatId || ''}`,
  },
  pricing: {
    PATH: '/dashboard/pricing',
  },
  insurance: {
    PATH: '/dashboard/insurance',
  },
  insuranceSessions: {
    PATH: '/dashboard/insurance-sessions',
  },
  // feedback: {
  //   PATH: '/dashboard/feedback',
  // },
  logs: {
    PATH: '/logs',
    validateBillHistory: {
      PATH: '/logs/validate-bill-history',
    },
    submitBillHistory: {
      PATH: '/logs/submit-bill-history',
    },
    billStatusHistory: {
      PATH: '/logs/bill-status-history',
    },
  },
  feedback: {
    PATH: '/dashboard/feedback',
    pathBuild: (therapistId: string) =>
      `/dashboard/therapists/profile/${therapistId}/feedback`,
  },
  resources: {
    PATH: '/dashboard/resources',
  },
  business: {
    PATH: '/dashboard/business',
    pathBuild: (therapistId: string) => `/dashboard/business/${therapistId}`,
  },
  vouchers: {
    PATH: '/dashboard/vouchers',
    pathBuild: () => `/dashboard/vouchers`,
  },
};
