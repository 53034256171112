import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type TherapistRequestsCountVariables = Types.Exact<{ [key: string]: never; }>;


export type TherapistRequestsCount = (
  { __typename: 'Subscription' }
  & Pick<Types.Subscription, 'therapistRequestAdded'>
);


export const TherapistRequestsCountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TherapistRequestsCount"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"therapistRequestAdded"}}]}}]} as unknown as DocumentNode;

/**
 * __useTherapistRequestsCount__
 *
 * To run a query within a React component, call `useTherapistRequestsCount` and pass it any options that fit your needs.
 * When your component renders, `useTherapistRequestsCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTherapistRequestsCount({
 *   variables: {
 *   },
 * });
 */
export function useTherapistRequestsCount(baseOptions?: Apollo.SubscriptionHookOptions<TherapistRequestsCount, TherapistRequestsCountVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<TherapistRequestsCount, TherapistRequestsCountVariables>(TherapistRequestsCountDocument, options);
      }
export type TherapistRequestsCountHookResult = ReturnType<typeof useTherapistRequestsCount>;
export type TherapistRequestsCountSubscriptionResult = Apollo.SubscriptionResult<TherapistRequestsCount>;