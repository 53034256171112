import React from 'react';
import styled from 'styled-components';

import { getSize } from 'lib/utils';
import { DEFAULT_AVATAR } from 'lib/constants/therapist';
import { Role } from '__generated__/types';

import { MtaLogoIcon } from 'ui';

export interface UserAvatarProps {
  className?: string;
  src?: string | null;
  alt?: string;
  type: string;
}

function UserAvatar({ className, src, type, alt }: UserAvatarProps) {
  return (
    <Wrapper className={className}>
      <Image src={src || DEFAULT_AVATAR} alt={alt} />

      {type === Role.Therapist && (
        <TherapistCircle>
          <LogoIcon />
        </TherapistCircle>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
`;

const Image = styled.img`
  width: ${getSize(42)};
  height: ${getSize(42)};
  object-fit: cover;
  border-radius: 50%;
`;

const TherapistCircle = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${getSize(16)};
  height: ${getSize(16)};
  border: ${getSize(0.5)} solid var(--gray4);
  border-radius: 50%;
  background: white;
`;

const LogoIcon = styled(MtaLogoIcon)`
  width: ${getSize(12)};
  height: ${getSize(12)};
`;

export default UserAvatar;
