import * as React from 'react';
import { getSize } from 'lib/utils';

export interface TherapistIconProps {
  pathColor?: string;
  width?: number;
  height?: number;
}

function TherapistIcon({
  width = 24,
  height = 24,
  pathColor = '#E5E4E8',
}: TherapistIconProps) {
  return (
    <svg
      width={getSize(width)}
      height={getSize(height)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M12 9.686a4.078 4.078 0 100-8.156 4.078 4.078 0 000 8.156zM20.25 9.686a2.578 2.578 0 100-5.156 2.578 2.578 0 000 5.156zM3.75 9.686a2.578 2.578 0 100-5.156 2.578 2.578 0 000 5.156zM6.29 12c-1.015-.83-1.934-.72-3.107-.72C1.428 11.28 0 12.699 0 14.443v5.12c0 .757.618 1.373 1.379 1.373 3.282 0 2.887.06 2.887-.142 0-3.627-.43-6.287 2.024-8.793z"
          fill={pathColor}
        />
        <path
          d="M13.116 11.298c-2.05-.17-3.831.002-5.368 1.27-2.571 2.06-2.076 4.833-2.076 8.226 0 .898.73 1.642 1.641 1.642 9.895 0 10.289.319 10.875-.98.193-.44.14-.3.14-4.504 0-3.338-2.89-5.654-5.212-5.654zM20.817 11.28c-1.18 0-2.094-.11-3.107.721 2.436 2.487 2.024 4.966 2.024 8.793 0 .203-.328.142 2.838.142.788 0 1.428-.638 1.428-1.422v-5.07c0-1.745-1.428-3.164-3.183-3.164z"
          fill={pathColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 14h-2v2H9v2h2v2h2v-2h2v-2h-2v-2z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default TherapistIcon;
