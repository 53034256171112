import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RemovePostVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type RemovePost = (
  { __typename: 'Mutation' }
  & Pick<Types.Mutation, 'removePost'>
);


export const RemovePostDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemovePost"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removePost"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode;
export type RemovePostMutationFn = Apollo.MutationFunction<RemovePost, RemovePostVariables>;

/**
 * __useRemovePost__
 *
 * To run a mutation, you first call `useRemovePost` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePost` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePost, { data, loading, error }] = useRemovePost({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemovePost(baseOptions?: Apollo.MutationHookOptions<RemovePost, RemovePostVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePost, RemovePostVariables>(RemovePostDocument, options);
      }
export type RemovePostHookResult = ReturnType<typeof useRemovePost>;
export type RemovePostMutationResult = Apollo.MutationResult<RemovePost>;
export type RemovePostMutationOptions = Apollo.BaseMutationOptions<RemovePost, RemovePostVariables>;