import * as Types from '../../../__generated__/types';

import { AdminClinicCredentialsFragment } from '../../query/__generated__/get-clinic-credentials';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type EditClinicCredentialsVariables = Types.Exact<{
  input: Types.ClinicCredentialsInput;
}>;


export type EditClinicCredentials = (
  { __typename: 'Mutation' }
  & { adminClinicCredentialsEdit: (
    { __typename: 'ClinicCredentialsPayload' }
    & { creds?: Types.Maybe<(
      { __typename: 'ClinicCredentialsModel' }
      & AdminClinicCredentialsFragment
    )>, userErrors: Array<(
      { __typename: 'ValidationError' }
      & Pick<Types.ValidationError, 'field' | 'messages'>
    )> }
  ) }
);


export const EditClinicCredentialsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"EditClinicCredentials"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ClinicCredentialsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminClinicCredentialsEdit"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"creds"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AdminClinicCredentialsFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"userErrors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"field"}},{"kind":"Field","name":{"kind":"Name","value":"messages"}}]}}]}}]}},...AdminClinicCredentialsFragment.definitions]} as unknown as DocumentNode;
export type EditClinicCredentialsMutationFn = Apollo.MutationFunction<EditClinicCredentials, EditClinicCredentialsVariables>;

/**
 * __useEditClinicCredentials__
 *
 * To run a mutation, you first call `useEditClinicCredentials` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditClinicCredentials` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editClinicCredentials, { data, loading, error }] = useEditClinicCredentials({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditClinicCredentials(baseOptions?: Apollo.MutationHookOptions<EditClinicCredentials, EditClinicCredentialsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditClinicCredentials, EditClinicCredentialsVariables>(EditClinicCredentialsDocument, options);
      }
export type EditClinicCredentialsHookResult = ReturnType<typeof useEditClinicCredentials>;
export type EditClinicCredentialsMutationResult = Apollo.MutationResult<EditClinicCredentials>;
export type EditClinicCredentialsMutationOptions = Apollo.BaseMutationOptions<EditClinicCredentials, EditClinicCredentialsVariables>;