import React, { useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled, { CSSProp } from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import { getSize } from 'lib/utils';

const INPUT_CONTAINER_STYLES = {
  width: '100%',
  padding: `${getSize(8)} 0 ${getSize(9)}`,
  fontWeight: 400,
  fontSize: getSize(12),
  lineHeight: getSize(20),
  border: 0,
};

const INPUT_CSS = {
  color: ' var(--black3)',
};

export interface PhoneInputProps extends FieldRenderProps<string> {
  label?: string;
  rootCSS?: CSSProp;
  labelCSS?: CSSProp;
  inputContainerCSS?: React.CSSProperties;
  disabled?: boolean;
  inputCSS?: React.CSSProperties;
  hasLine?: boolean;
}

function FormPhoneInput({
  input,
  meta,
  label,
  rootCSS,
  disabled,
  labelCSS,
  inputCSS,
  inputContainerCSS,
  hasLine = true,
}: PhoneInputProps) {
  const [isInputFocused, setIsInputFocused] = useState(false);

  const { error, submitError, touched, dirtySinceLastSubmit, data } = meta;
  const hasError =
    ((error || submitError) && touched && !dirtySinceLastSubmit) || data?.error;

  return (
    <Wrapper as={label ? 'label' : 'div'} $CSS={rootCSS}>
      {label && <Text $CSS={labelCSS}>{label}</Text>}

      <PhoneInput
        placeholder="Enter phone"
        disabled={disabled}
        value={input.value}
        country="gb"
        containerStyle={inputContainerCSS || INPUT_CONTAINER_STYLES}
        inputStyle={inputCSS || INPUT_CSS}
        disableDropdown
        specialLabel=""
        onChange={input.onChange}
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
      />
      {hasLine && (
        <Line $hasError={hasError} $isInputFocused={isInputFocused} />
      )}

      {hasError && <ErrorText>{error || submitError || data?.error}</ErrorText>}
    </Wrapper>
  );
}

const Wrapper = styled.label<{ $CSS?: CSSProp }>`
  display: block;

  ${({ $CSS }) => $CSS}
`;

const Text = styled.span<{ $CSS?: CSSProp }>`
  display: block;
  font-weight: 400;
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--gray7);

  ${({ $CSS }) => $CSS}
`;

const Line = styled.div<{ $isInputFocused: boolean; $hasError: boolean }>`
  width: 100%;
  height: ${getSize(1)};
  border-radius: ${getSize(8)};
  background: var(
    ${({ $isInputFocused, $hasError }) => {
      if ($hasError) {
        return '--red';
      } else if ($isInputFocused) {
        return '--purple';
      } else {
        return '--purple3';
      }
    }}
  );
  transition: 0.3s ease-out;
`;

const ErrorText = styled.span`
  margin: ${getSize(2)} 0 0;
  font-weight: 400;
  font-size: ${getSize(10)};
  line-height: ${getSize(16)};
  color: var(--red);
`;

export default FormPhoneInput;
