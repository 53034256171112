import React, { FC, useEffect } from 'react';
import styled, { CSSProp } from 'styled-components';
import { Option } from '../../drop-down-filter';
import { CheckedSelectIcon } from 'ui/icons';
import { getSize } from 'lib/utils';

interface DropDownMenuProps {
  options: Option[];
  values: string[];
  menuCSS?: CSSProp;
  onClick: (value: string) => void;
  onWindowClick: () => void;
}

const DropDownMenu: FC<DropDownMenuProps> = ({
  options,
  values,
  onClick,
  onWindowClick,
  menuCSS,
}) => {
  useEffect(() => {
    window.addEventListener('click', onWindowClick);
    return () => window.removeEventListener('click', onWindowClick);
  }, [onWindowClick]);
  return (
    <Wrapper CSS={menuCSS} onClick={(e) => e.stopPropagation()}>
      {options.map(({ value, label }) => (
        <DropDownMenuItem key={value} onClick={() => onClick(value)}>
          <LabelText>{label}</LabelText>
          {values.includes(value) && (
            <CheckedSelectIcon stroke={'var(--purple5)'} />
          )}
        </DropDownMenuItem>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.ul<{ CSS?: CSSProp }>`
  position: absolute;
  left: 0;
  top: 100%;
  width: ${getSize(256)};
  border-radius: ${getSize(8)};
  box-shadow: 0px ${getSize(20)} ${getSize(90)} rgba(40, 31, 61, 0.16);
  background-color: #fff;
  padding: ${getSize(12)} 0;
  z-index: 1000;

  ${({ CSS }) => CSS}
`;

const DropDownMenuItem = styled.li`
  width: 100%;
  padding: ${getSize(12)} ${getSize(24)};
  transition: 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: var(-black3);

  &:hover {
    background-color: var(--purple11);
  }

  & > svg {
    width: ${getSize(16)};
    height: ${getSize(11)};
  }
`;

const LabelText = styled.p`
  font-size: ${getSize(12)};
  line-height: ${getSize(12)};
`;

export default DropDownMenu;
