import React from 'react';
import styled from 'styled-components';
import { getSize } from 'lib/utils';
import { EditIcon } from 'ui';

export interface WriteMessageButtonProps {
  onClick?: () => void;
  isExpanded: boolean;
}

function WriteMessageButton({ onClick, isExpanded }: WriteMessageButtonProps) {
  return (
    <Wrapper $isExpanded={isExpanded} onClick={onClick}>
      <Icon $isExpanded={isExpanded} />
    </Wrapper>
  );
}

const Wrapper = styled.button<{ $isExpanded: boolean }>`
  position: absolute;
  top: 50%;
  right: ${getSize(24)};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ $isExpanded }) =>
    $isExpanded
      ? `${getSize(11)} ${getSize(10)} ${getSize(11)} ${getSize(12)}`
      : getSize(11)};
  background: var(--purple3);
  border-radius: ${getSize(8)};
  transition: 0.3s ease-out;
  white-space: nowrap;
  transform: translateY(-50%);

  ${({ $isExpanded }) =>
    !$isExpanded &&
    `
  &:hover {
    color: var(--purple);
  }
  `}
`;

const Icon = styled(EditIcon)`
  margin: 0 0 0 ${getSize(2)};
  overflow: visible;
`;

export default WriteMessageButton;
