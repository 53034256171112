import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Button } from 'ui/button';
import { Modal } from 'ui/modal';

interface ManageUserConfirmModalProps {
  isLoading?: boolean;
  isVisible: boolean;
  onClose: () => void;
  onAction: () => void;
  title: string;
  text: string;
}

const ManageUserConfirmModal: FC<ManageUserConfirmModalProps> = ({
  isLoading,
  isVisible,
  onClose,
  onAction,
  title,
  text,
}) => {
  return (
    <Modal title={title} isVisible={isVisible} onClose={onClose}>
      <Text>{text}</Text>
      <ActionButton theme="secondary" onClick={onClose}>
        No
      </ActionButton>
      <Button isDisabled={isLoading} theme="primary" onClick={onAction}>
        Yes
      </Button>
    </Modal>
  );
};

const Text = styled.p`
  font-size: ${getSize(12)};
  line-height: ${getSize(20)};
  margin-bottom: ${getSize(26)};
  max-width: ${getSize(451)};
`;

const ActionButton = styled(Button)`
  margin-bottom: ${getSize(14)};
  color: var(--black3);
`;

export default ManageUserConfirmModal;
