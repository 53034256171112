import StorageService from '../utils/storage-service';

function getResult(json: any, response: Response) {
  return {
    ...response,
    ok: true,
    json: () => new Promise((resolve) => resolve(json)),
    text: () =>
      new Promise<string>((resolve) => resolve(JSON.stringify(json) as string)),
  };
}

export const customFetch = async (uri: string, options?: any) => {
  const accessToken = StorageService.getAccessToken();

  options.headers.authorization = `Bearer ${accessToken}`;
  const initialRequest = fetch(uri, options);

  return initialRequest.then(async (response) => {
    const json = await response.json();
    const errorCode = json?.errors?.[0]?.extensions?.status;

    if (errorCode === 401) {
      const accessToken = StorageService.getAccessToken();

      if (!accessToken) {
        return getResult(json, response);
      }
    }

    return getResult(json, response);
  });
};
