import React from 'react';
import styled from 'styled-components';
import { getSize } from 'lib/utils';
import PDFIcon from 'ui/icons/pdf-icon';
import { FileExtension } from '__generated__/types';
import {
  DocIcon,
  DocxIcon,
  JpegIcon,
  JpgIcon,
  Mp3Icon,
  Mp4Icon,
  PngIcon,
} from 'ui';

const { Pdf, Jpeg, Png, Jpg, Mp4, Doc, Docx, Mp3, M4A } = FileExtension;
const FILE_EXTENSION_ICONS = {
  [Pdf]: PDFIcon,
  [Jpeg]: JpegIcon,
  [Png]: PngIcon,
  [Jpg]: JpgIcon,
  [Mp4]: Mp4Icon,
  [Doc]: DocIcon,
  [Docx]: DocxIcon,
  [Mp3]: Mp3Icon,
  [M4A]: Mp3Icon,
};

interface DocumentItemProps {
  size: number;
  fileName: string;
  extension: FileExtension;
  onFilePreview: () => void;
}

function DocumentItem({
  fileName,
  size,
  extension,
  onFilePreview,
}: DocumentItemProps) {
  const ExtensionIcon = FILE_EXTENSION_ICONS[extension] || null;

  return (
    <Wrapper onClick={onFilePreview}>
      <IconWrapper>
        {ExtensionIcon && <ExtensionIcon width={40} height={40} />}
      </IconWrapper>

      <NameWrapper>
        <NameAndExtension>
          <MaterialName className="text-line-clamp-1">{fileName}</MaterialName>
        </NameAndExtension>
        <Size>{`${size} kb`}</Size>
      </NameWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  transition: all 0.4s ease-in-out 0s;
  min-width: ${getSize(200)};
  max-width: ${getSize(200)};
  margin-right: ${getSize(35)};
  margin-bottom: ${getSize(30)};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    div > span {
      color: var(--purple4);
    }
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 ${getSize(12)} 0 0;
`;

const NameWrapper = styled.div`
  min-width: 0;
  display: flex;
  flex-shrink: 1;
  flex-direction: column;
`;

const NameAndExtension = styled.span`
  display: flex;
  flex-shrink: 1;
  min-width: 0;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--black3);
`;

const MaterialName = styled.span`
  max-width: ${getSize(150)};
  white-space: nowrap;
  text-overflow: ellipsis;
  white-space: break-spaces;
  -webkit-line-clamp: inherit !important;
`;

const Size = styled.p`
  font-weight: 400;
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--gray2);
`;

export default DocumentItem;
