import { SendMessage } from './../mutation/__generated__/send-message';
import { ChatChanged } from './../subscription/__generated__/chat-changed';
import { ChatFragment } from 'pages/messaging-page/query/__generated__/chats-list';
import {
  ApolloCache,
  OnSubscriptionDataOptions,
  FetchResult,
} from '@apollo/client';
import { ChatAdded } from '../subscription/__generated__/chat-added';
import { AdminStartNewChat } from '../mutation/__generated__/admin-start-new-chat';
import { getArrayWithItemInTop } from 'lib/utils';
import { ADMIN_ID } from '../messaging-page';

interface UpdateChatListCacheAfterChanged
  extends OnSubscriptionDataOptions<ChatChanged> {
  selectedChatId?: string;
}

export function updateChatListCacheAfterAdded({
  client: { cache },
  subscriptionData: { data },
}: OnSubscriptionDataOptions<ChatAdded>) {
  const incomingChat = data?.chatAdded;
  if (!incomingChat) return;

  cache.modify({
    fields: {
      listChats(existingChats, { readField }) {
        const isIncomingChatExist = existingChats?.nodes.some(
          (ref: any) => readField('id', ref) === incomingChat.id,
        );
        const newChatFragment = cache.writeFragment({
          data: incomingChat,
          fragment: ChatFragment,
          fragmentName: 'ChatFragment',
        });

        return isIncomingChatExist
          ? existingChats
          : {
              ...existingChats,
              nodes: [newChatFragment, ...existingChats.nodes],
            };
      },
    },
  });
}

export function updateChatListCacheAfterChanged({
  selectedChatId,
  client: { cache },
  subscriptionData: { data },
}: UpdateChatListCacheAfterChanged) {
  const incomingChat = data?.chatChanged;
  if (!incomingChat) return;

  cache.modify({
    fields: {
      listChats(existingChats, { readField }) {
        function getChatsWithIncomingInTop() {
          const existChatIndex = existingChats?.nodes.findIndex(
            (ref: any) => readField('id', ref) === incomingChat?.id,
          );
          const newChats = getArrayWithItemInTop(
            existingChats.nodes,
            existChatIndex,
          );

          return { ...existingChats, nodes: newChats };
        }

        if (incomingChat?.id === selectedChatId) {
          incomingChat.unreadCount = 0;
        }

        cache.writeFragment({
          data: incomingChat,
          fragment: ChatFragment,
          fragmentName: 'ChatFragment',
        });

        return incomingChat.lastMessage?.sender.id === ADMIN_ID
          ? getChatsWithIncomingInTop()
          : existingChats;
      },
    },
  });
}

export function updateChatListCacheAfterSendMessage(
  cache: ApolloCache<SendMessage>,
  result: FetchResult<SendMessage>,
) {
  const incomingChatId = result.data?.sendMessage.message?.chatId;

  if (incomingChatId) {
    cache.modify({
      fields: {
        listChats(existingChats, { readField }) {
          const activeChatIndex = existingChats.nodes.findIndex(
            (node: ChatFragment) => readField('id', node) === incomingChatId,
          );
          const newChats = getArrayWithItemInTop(
            existingChats.nodes,
            activeChatIndex,
          );

          return { ...existingChats, nodes: newChats };
        },
      },
    });
  }
}

export function updateChatListCacheAfterStartNewChat(
  cache: ApolloCache<AdminStartNewChat>,
  { data }: FetchResult<AdminStartNewChat>,
) {
  const incomingChat = data?.adminStartChat;
  if (!incomingChat) return;

  cache.modify({
    fields: {
      listChats(existingChats, { readField }) {
        const existChatIndex = existingChats?.nodes.findIndex(
          (ref: any) => readField('id', ref) === incomingChat.id,
        );

        if (existChatIndex >= 0) {
          const newChats = getArrayWithItemInTop(
            existingChats.nodes,
            existChatIndex,
          );

          return { ...existingChats, nodes: newChats };
        }

        const newChatFragment = cache.writeFragment({
          data: incomingChat,
          fragment: ChatFragment,
          fragmentName: 'ChatFragment',
        });

        return {
          ...existingChats,
          nodes: [newChatFragment, ...existingChats.nodes],
        };
      },
    },
  });
}
