export function getCorrectUri(url = '', urn = '') {
  if (!url) return '';
  return `${url.startsWith('http') ? url : `https://${url}`}${
    urn && `/${urn}`
  }`;
}

const REDIRECT_URL_BRANCES = ['develop', 'master'];

export function getRedirectOrLogoutUrl(isRedirect?: boolean) {
  const vercelUrl = process.env.REACT_APP_VERCEL_URL;

  if (
    REDIRECT_URL_BRANCES.includes(
      String(process.env.REACT_APP_VERCEL_GIT_COMMIT_REF),
    ) ||
    !vercelUrl
  ) {
    return isRedirect
      ? process.env.REACT_APP_AUTH0_REDIRECT_URL
      : process.env.REACT_APP_AUTH0_LOGOUT_URL;
  }

  return vercelUrl;
}
