import React from 'react';

function CalendarWideIcon({ ...rest }) {
  return (
    <svg
      width="19"
      height="15"
      viewBox="0 0 19 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.50879 4.5C2.50879 3.39543 3.40422 2.5 4.50879 2.5H15.5595C16.664 2.5 17.5595 3.39543 17.5595 4.5V11.75C17.5595 12.8546 16.664 13.75 15.5595 13.75H4.50879C3.40422 13.75 2.50879 12.8546 2.50879 11.75V4.5Z"
        stroke="#444752"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3779 1.25V3.75"
        stroke="#444752"
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M6.68945 1.25V3.75"
        stroke="#444752"
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M2.50879 6.25H17.5595"
        stroke="#444752"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CalendarWideIcon;
