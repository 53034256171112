import React, { useMemo, useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { Config, FORM_ERROR } from 'final-form';
import styled from 'styled-components';
import {
  Button,
  FilesPreviewModal,
  PopUpMessage,
  ShadowWrapper,
  TextBlockList,
} from 'ui';
import {
  DATE_OF_BIRTHDAY,
  getSize,
  TimeUtil,
  validationRules,
} from 'lib/utils';
import { TherapistInfoAside } from '../therapist-info-aside';
import { CheckIcon } from 'ui/icons/check-icon';
import { CommissionInput } from 'ui/form-elements/commission-input';
import { DocumentItem } from 'components/document-item';
import { TherapistInfoFragment } from 'common/query/__generated__/get-therapist-by-id';
import { useChangeCommissionPercent } from 'common/mutation/__generated__/change-commission-percent';
import { notifyError, notifySuccess } from 'lib/utils/notification';
import { sortApproachesOrTreatmentAreas } from '../../utils/sort-therapy-and-treatment-areas';
import { updateTherapistCommissionCache } from './utils';
import { QualificationFileModel } from '__generated__/types';
import FeedbackBlock from '../feedback-block';
import { useHistory } from 'react-router-dom';
import { dashboardRoutes } from 'routes/root-routes';
import { useGetTherapistFeedback } from 'common/query/__generated__/get-therapist-feedback';

interface TherapistInfoMainProps {
  isRequest?: boolean;
  onModalAction: (id: string, isBan?: boolean) => void;
  onUnbanTherapist: () => void;
  therapist: TherapistInfoFragment;
}

const TITLE_CSS = {
  fontSize: getSize(22),
  lineHeight: getSize(33),
  marginBottom: getSize(4),
};

const EMPTY_TITLE = 'No title';

interface CommissionInputFormValues {
  initialSessionCommissionPercent: number;
  ongoingSessionCommissionPercent: number;
}

const SUCCESS_NOTIFICATION = {
  title: 'Edit success',
  text: 'Price information was changed!',
};

function TherapistInfoMain({
  isRequest,
  onModalAction,
  therapist,
  onUnbanTherapist,
}: TherapistInfoMainProps) {
  const [filePreview, setFilePreview] = useState<
    QualificationFileModel | undefined
  >();
  const history = useHistory();

  const [changeCommissionPercent, { loading: commissionLoading }] =
    useChangeCommissionPercent({
      onError: ({ name, message }) =>
        notifyError({ title: name, text: message }),
      update: updateTherapistCommissionCache,
    });

  const {
    id: therapistId,
    bio,
    therapyApproaches,
    coreTrainingFile,
    dbsCertificateFile,
    title,
    deletedAt,
    user: { firstName, lastName },
    indemnityCertificateFile,
    initialSessionCommissionPercent,
    ongoingSessionCommissionPercent,
    qualification: { coreTraining, additionalTrainings, registrations },
  } = therapist;

  const { data: feedbacks } = useGetTherapistFeedback({
    variables: {
      first: 10,
      therapistId,
    },
  });

  const handleFormSubmit: Config<CommissionInputFormValues>['onSubmit'] =
    async (
      { initialSessionCommissionPercent, ongoingSessionCommissionPercent },
      form,
    ) => {
      try {
        const response = await changeCommissionPercent({
          variables: {
            input: {
              therapistId,
              initialCommissionPercent: Number(initialSessionCommissionPercent),
              ongoingCommissionPercent: Number(ongoingSessionCommissionPercent),
            },
          },
        });

        const validateErrors =
          response.data?.adminChangeCommissionPercent.userErrors;

        if (validateErrors && Number(validateErrors?.length) > 0) {
          const errorText = '';
          validateErrors.map((errors) =>
            errors?.messages.map((error) => {
              notifyError({ text: error });
              errorText.concat(error).concat(' ');
              return { [FORM_ERROR]: errorText };
            }),
          );
        } else {
          notifySuccess(SUCCESS_NOTIFICATION);
          setTimeout(form.restart);
        }
      } catch (error: any) {
        notifyError({ text: error.message });
      }
    };

  const sortedTherapyApproaches = useMemo(
    () => sortApproachesOrTreatmentAreas(therapyApproaches),
    [therapyApproaches],
  );

  return (
    <Wrapper $isRequest={isRequest}>
      <Content>
        <TherapistInfoAside therapist={therapist} />
        <MainInfo>
          <Title as="h1">{`About ${
            title?.label !== EMPTY_TITLE ? title?.label : ''
          } ${firstName} ${lastName}`}</Title>
          <About>{bio}</About>
          <TextBlockList
            title="Therapies Offered"
            itemsList={sortedTherapyApproaches}
            titleCSS={TITLE_CSS}
          />
          <Title>Qualifications</Title>
          <QualificationWrapper>
            <FlexColumn>
              <FlexColumn>
                <Subtile>Core Training:</Subtile>
                <ul>
                  <QualificationItem>
                    {coreTraining.explanation}
                  </QualificationItem>
                </ul>
              </FlexColumn>
              {additionalTrainings.length !== 0 && (
                <FlexColumn>
                  <Subtile>Accreditations / Additional trainings:</Subtile>
                  <ul>
                    {additionalTrainings.map((text) => (
                      <QualificationItem key={text.id}>
                        {text.description}
                      </QualificationItem>
                    ))}
                  </ul>
                </FlexColumn>
              )}
            </FlexColumn>
            <FlexColumn>
              <FlexColumn>
                <Subtile>Memberships / Registrations:</Subtile>
                {registrations?.map(
                  ({
                    id,
                    professionalRegistration,
                    expiryDate,
                    registrationNumber,
                  }) => {
                    const isLongNumber = registrationNumber.length > 20;
                    const formattedNumber = isLongNumber
                      ? `${registrationNumber.slice(0, 15)}...`
                      : registrationNumber;

                    return (
                      <QualificationItem key={id}>
                        {professionalRegistration.label}
                        <RegistrationItem>
                          <FlexColumn>
                            <RegistrationLabel>
                              Registration number
                            </RegistrationLabel>
                            <RegistrationText>
                              {formattedNumber}
                              {isLongNumber && (
                                <PopUpMessage
                                  rootCSS={{ whiteSpace: 'nowrap' }}
                                  className="full-number">
                                  {registrationNumber}
                                </PopUpMessage>
                              )}
                            </RegistrationText>
                          </FlexColumn>
                          <FlexColumn>
                            <RegistrationLabel>Expiry date</RegistrationLabel>
                            <RegistrationText>
                              {TimeUtil.getFormatted(
                                expiryDate,
                                DATE_OF_BIRTHDAY,
                              )}
                            </RegistrationText>
                          </FlexColumn>
                        </RegistrationItem>
                      </QualificationItem>
                    );
                  },
                )}
              </FlexColumn>
            </FlexColumn>
          </QualificationWrapper>
          <Title>Therapists uploads</Title>
          <DocumentList>
            <DocumentItem
              size={coreTrainingFile.sizeInKb}
              fileName={coreTrainingFile.name}
              extension={coreTrainingFile.extension}
              onFilePreview={() => setFilePreview(coreTrainingFile)}
            />
            <DocumentItem
              size={dbsCertificateFile.sizeInKb}
              fileName={dbsCertificateFile.name}
              extension={dbsCertificateFile.extension}
              onFilePreview={() => setFilePreview(dbsCertificateFile)}
            />
            <DocumentItem
              size={indemnityCertificateFile.sizeInKb}
              fileName={indemnityCertificateFile.name}
              extension={indemnityCertificateFile.extension}
              onFilePreview={() => setFilePreview(indemnityCertificateFile)}
            />
            {additionalTrainings.map((training) => (
              <DocumentItem
                key={training.id}
                size={training.qualificationFile.sizeInKb}
                fileName={training.qualificationFile.name}
                extension={training.qualificationFile.extension}
                onFilePreview={() => setFilePreview(training.qualificationFile)}
              />
            ))}
          </DocumentList>
          {(therapist.observer || therapist.writer) && (
            <>
              <Title>Community</Title>
              <CommunityWrapper>
                {therapist.observer && (
                  <CheckField>
                    <IconWrapper>
                      <CheckIcon />
                    </IconWrapper>
                    <CommunityText>
                      Interested in writing an article for the blog
                    </CommunityText>
                  </CheckField>
                )}
                {therapist.writer && (
                  <CheckField>
                    <IconWrapper>
                      <CheckIcon />
                    </IconWrapper>
                    <CommunityText>
                      Interested in peer supervision with other members
                    </CommunityText>
                  </CheckField>
                )}
              </CommunityWrapper>
            </>
          )}
          {!isRequest && (
            <>
              <Title>Commission</Title>
              <FinalForm<CommissionInputFormValues>
                onSubmit={handleFormSubmit}
                initialValues={{
                  initialSessionCommissionPercent,
                  ongoingSessionCommissionPercent,
                }}
                render={({ handleSubmit, hasValidationErrors, dirty }) => (
                  <CommissionInputForm onSubmit={handleSubmit}>
                    <FormWrapper>
                      <CommissionTitle>
                        Initial session commission
                      </CommissionTitle>
                      <CommissionWrapper>
                        <Field
                          errorCSS={{
                            position: 'absolute',
                            top: getSize(50),
                            width: getSize(180),
                          }}
                          validate={validationRules.commissionValue}
                          name="initialSessionCommissionPercent"
                          component={CommissionInput}
                        />
                      </CommissionWrapper>
                    </FormWrapper>

                    <FormWrapper>
                      <CommissionTitle>
                        Ongoing session commission
                      </CommissionTitle>
                      <CommissionWrapper>
                        <Field
                          errorCSS={{
                            position: 'absolute',
                            top: getSize(50),
                            width: getSize(180),
                          }}
                          validate={validationRules.commissionValue}
                          name="ongoingSessionCommissionPercent"
                          component={CommissionInput}
                        />
                      </CommissionWrapper>
                    </FormWrapper>
                    <SaveButton
                      isDisabled={!dirty || hasValidationErrors}
                      isLoading={commissionLoading}
                      theme="primary">
                      Save
                    </SaveButton>
                  </CommissionInputForm>
                )}
              />
            </>
          )}
          {!isRequest && !!feedbacks?.adminTherapistFeedback.nodes.length && (
            <>
              <TitleWrapper>
                <Title>Patient feedback</Title>
                <LinkButton
                  onClick={() =>
                    history.push(
                      dashboardRoutes.feedback.pathBuild(therapist.id),
                    )
                  }>
                  see more
                </LinkButton>
              </TitleWrapper>
              <FeedbackWrapper>
                {feedbacks?.adminTherapistFeedback.nodes.map(
                  (feedback, index) => {
                    if (index < 2) {
                      return (
                        <FeedbackBlock
                          key={feedback.id}
                          feedback={feedback}
                          isFirst={index === 0}
                        />
                      );
                    }
                  },
                )}
              </FeedbackWrapper>
            </>
          )}
        </MainInfo>
      </Content>
      <FilesPreviewModal
        file={filePreview}
        onClose={() => setFilePreview(undefined)}
        isVisible={Boolean(filePreview)}
      />
    </Wrapper>
  );
}

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h3`
  font-weight: 600;
  font-size: ${getSize(22)};
  line-height: ${getSize(32)};
  color: var(--black3);
  margin-bottom: ${getSize(16)};
`;

const About = styled.pre`
  white-space: break-spaces;
  overflow-wrap: anywhere;
  margin-bottom: ${getSize(40)};
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--black3);
`;

const Wrapper = styled(ShadowWrapper)<{ $isRequest?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${getSize(40)} 0
    ${({ $isRequest }) => ($isRequest ? getSize(20) : getSize(0))};
`;

const Content = styled.div`
  display: flex;
  padding: 0 ${getSize(36)} ${getSize(40)};
`;

const MainInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${getSize(29)};
  max-width: ${getSize(660)};
`;

const QualificationWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${getSize(8)};
  margin-bottom: ${getSize(24)};
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

const QualificationItem = styled.li`
  position: relative;
  padding-left: ${getSize(48)};
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--black3);
  list-style: none;

  &::before {
    content: '';
    position: absolute;
    width: ${getSize(8)};
    height: ${getSize(8)};
    top: ${getSize(8)};
    left: ${getSize(24)};
    border-radius: 50%;
    background-color: var(--purple);
  }
`;

const Text = styled.p`
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--gray7);
`;

const CommunityText = styled(Text)`
  color: var(--black3);
`;

const Subtile = styled.p`
  font-weight: 600;
  margin-bottom: ${getSize(12)};
  color: var(--black3);
`;

const DocumentList = styled.div`
  display: flex;
  flex-flow: wrap;
  max-width: ${getSize(480)};
`;

const CommunityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${getSize(30)};
  & div:not(:last-child) {
    margin-bottom: ${getSize(18)};
  }
`;

const CheckField = styled.div`
  display: flex;
`;

const IconWrapper = styled.span`
  margin-right: ${getSize(8)};
`;

const CommissionWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const CommissionInputForm = styled.form`
  display: flex;
  align-items: flex-end;
  flex-flow: wrap;
  & > div:not(:first-child) {
    margin-left: ${getSize(24)};
  }
  margin-bottom: 28px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SaveButton = styled(Button)`
  padding: ${getSize(5)} ${getSize(20)};
  min-height: auto;
  margin: 0 0 ${getSize(8)} ${getSize(26)};
  font-weight: 400;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${getSize(24)} ${getSize(24)} ${getSize(30)};
  box-shadow: ${getSize(0)} ${getSize(20)} ${getSize(90)} rgba(40, 31, 61, 0.06);
`;

const RemoveButton = styled(Button)`
  width: 100%;
  padding: ${getSize(8)} 0;
  font-weight: 600;
`;

const BanOrUnbanButton = styled(Button)<{ $isUnban?: boolean }>`
  margin-bottom: ${getSize(16)};

  ${({ $isUnban }) => $isUnban && 'color: var(--black3)'};
`;

const CommissionTitle = styled.p`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--gray7);
  margin: 0 0 ${getSize(16)} 0;
`;

const RegistrationItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RegistrationLabel = styled.label`
  font-size: ${getSize(11)};
  line-height: ${getSize(24)};
  color: var(--gray2);
`;

const RegistrationText = styled.span`
  position: relative;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--black3);

  &:hover {
    .full-number {
      display: flex;
      animation: showTooltip 0.3s ease-out forwards;
    }
  }
`;

const FeedbackWrapper = styled.div`
  display: flex;
`;

const LinkButton = styled.div`
  color: var(--purple4);
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  cursor: pointer;
`;
export default TherapistInfoMain;
