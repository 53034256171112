import { FieldPolicy, Reference } from '@apollo/client';

type TPageInfo = {
  hasNextPage: boolean;
  endCursor: string | null;
};

type TExistingRelay<TNode> = Readonly<{
  nodes: TNode[];
  pageInfo: TPageInfo;
}>;

type TIncomingRelay<TNode> = {
  nodes: TNode[];
  pageInfo: TPageInfo;
};

type RelayFieldPolicy<TNode> = FieldPolicy<
  TExistingRelay<TNode>,
  TIncomingRelay<TNode>,
  TIncomingRelay<TNode>
>;
type KeyArgs = FieldPolicy<any>['keyArgs'];

export function getUniqueList(arr: any[], key: string) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

export function relayStylePagination<TNode = Reference>(
  keyArgs: KeyArgs = ['filter', 'orderBy'],
  uniqueKey: keyof Reference | string = '__ref',
): RelayFieldPolicy<TNode> {
  return {
    keyArgs,
    merge(existing = makeEmptyData(), incoming, { variables }) {
      if (variables?.filter?.first) {
        // refetch query for upcoming sessions
        if (!variables?.filter?.after) {
          return incoming;
        }
      }

      const data = existing ? [...existing.nodes] : [];

      const newData = getUniqueList(
        [...data, ...(incoming.nodes || [])],
        uniqueKey,
      );

      const merged: {
        nodes: TNode[];
        pageInfo: TPageInfo;
      } = {
        nodes: newData,
        pageInfo: { ...existing.pageInfo, ...incoming.pageInfo },
      };

      return merged;
    },
    // Return all items stored so far, to avoid ambiguities
    // about the order of the items.
    read(existing, { args }) {
      return existing;
    },
  };
}

export function relayStyleOffsetPagination<TNode = Reference>(
  keyArgs: KeyArgs = [],
  uniqueKey = '__ref',
): FieldPolicy<TNode[]> {
  return {
    keyArgs,
    merge(existing: any, incoming: any) {
      const data = existing?.vouchers ? [...existing.vouchers] : [];

      const list = getUniqueList(
        [...data, ...(incoming.vouchers || [])],
        uniqueKey,
      );

      return {
        ...existing,
        ...incoming,
        vouchers: list,
      };
    },
  };
}

function makeEmptyData(): TExistingRelay<any> {
  return {
    nodes: [],
    pageInfo: {
      hasNextPage: false,
      endCursor: null,
    },
  };
}
