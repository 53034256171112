import React, { useState, useCallback } from 'react';
import styled, { CSSProp } from 'styled-components';
import { ArrowDownIcon } from 'ui/icons';
import { DropDownMenu } from './components';
import { getSize } from 'lib/utils';

export interface Option {
  value: string;
  label: string;
}

export interface DropDownFilterProps<T> {
  title?: string;
  value: T | T[] | null | Record<string, unknown>;
  setValue: (value: string) => void;
  options: Option[];
  menuCSS?: CSSProp;
  wrapperCSS?: CSSProp;
  buttonCSS?: CSSProp;
}

function DropDownFilter<T extends string>({
  title,
  options,
  menuCSS,
  wrapperCSS,
  buttonCSS,
  value,
  setValue,
}: DropDownFilterProps<T>) {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectOption = useCallback(
    (newValue) => {
      if (!value) return;
      return setValue(newValue);
    },
    [value, setValue],
  );

  return (
    <Wrapper $CSS={wrapperCSS}>
      <DropDownButton
        disabled={options.length <= 0}
        onClick={() => setIsOpen(!isOpen)}
        $CSS={buttonCSS}>
        {title && <Title>{title}</Title>}

        <ArrowIndicator isOpen={isOpen}>
          <ArrowIcon />
        </ArrowIndicator>
      </DropDownButton>

      {isOpen && (
        <DropDownMenu
          options={options}
          menuCSS={menuCSS}
          values={[value as string]}
          onClick={handleSelectOption}
          onWindowClick={() => setIsOpen(false)}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ $CSS?: CSSProp }>`
  position: relative;

  ${({ $CSS }) => $CSS}
`;

const DropDownButton = styled.button<{ $CSS?: CSSProp }>`
  position: relative;
  display: flex;
  align-items: center;
  color: var(--black3);

  &[disabled] {
    cursor: not-allowed;
  }

  ${({ $CSS }) => $CSS}
`;

const Title = styled.p`
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  margin-right: ${getSize(47)};
  white-space: nowrap;
`;

const ArrowIndicator = styled.span<{ isOpen: boolean }>`
  transform: scale(${({ isOpen }) => (isOpen ? -1 : 1)});
  transition: 0.2s ease-out;

  & > svg {
    width: ${getSize(22)};
    height: ${getSize(22)};
  }
`;

const ArrowIcon = styled(ArrowDownIcon)`
  stroke: var(--black3);
`;

export default DropDownFilter;
