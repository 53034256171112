import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetAdminCompanyFeedbackDescriptionsVariables = Types.Exact<{
  first?: Types.Maybe<Types.Scalars['Int']>;
  after?: Types.Maybe<Types.Scalars['String']>;
  companyId: Types.Scalars['String'];
}>;


export type GetAdminCompanyFeedbackDescriptions = (
  { __typename: 'Query' }
  & { adminCompanyFeedbackDescriptions: (
    { __typename: 'FeedbackDescriptions' }
    & { nodes: Array<(
      { __typename: 'FeedbackTextModel' }
      & Pick<Types.FeedbackTextModel, 'description' | 'therapistName' | 'createdAt' | 'sessionMetaId'>
    )>, pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<Types.PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);


export const GetAdminCompanyFeedbackDescriptionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetAdminCompanyFeedbackDescriptions"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"first"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"after"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminCompanyFeedbackDescriptions"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"Variable","name":{"kind":"Name","value":"first"}}},{"kind":"Argument","name":{"kind":"Name","value":"after"},"value":{"kind":"Variable","name":{"kind":"Name","value":"after"}}},{"kind":"Argument","name":{"kind":"Name","value":"companyId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"nodes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"therapistName"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"sessionMetaId"}}]}},{"kind":"Field","name":{"kind":"Name","value":"pageInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hasNextPage"}},{"kind":"Field","name":{"kind":"Name","value":"endCursor"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetAdminCompanyFeedbackDescriptions__
 *
 * To run a query within a React component, call `useGetAdminCompanyFeedbackDescriptions` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminCompanyFeedbackDescriptions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminCompanyFeedbackDescriptions({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetAdminCompanyFeedbackDescriptions(baseOptions: Apollo.QueryHookOptions<GetAdminCompanyFeedbackDescriptions, GetAdminCompanyFeedbackDescriptionsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminCompanyFeedbackDescriptions, GetAdminCompanyFeedbackDescriptionsVariables>(GetAdminCompanyFeedbackDescriptionsDocument, options);
      }
export function useGetAdminCompanyFeedbackDescriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminCompanyFeedbackDescriptions, GetAdminCompanyFeedbackDescriptionsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminCompanyFeedbackDescriptions, GetAdminCompanyFeedbackDescriptionsVariables>(GetAdminCompanyFeedbackDescriptionsDocument, options);
        }
export type GetAdminCompanyFeedbackDescriptionsHookResult = ReturnType<typeof useGetAdminCompanyFeedbackDescriptions>;
export type GetAdminCompanyFeedbackDescriptionsLazyQueryHookResult = ReturnType<typeof useGetAdminCompanyFeedbackDescriptionsLazyQuery>;
export type GetAdminCompanyFeedbackDescriptionsQueryResult = Apollo.QueryResult<GetAdminCompanyFeedbackDescriptions, GetAdminCompanyFeedbackDescriptionsVariables>;