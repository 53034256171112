import { useReadChat } from '../mutation/__generated__/read-chat';

export interface ReadChatMessagesArgs {
  chatId?: string;
  onSuccess?: () => void;
}

export function useReadChatMessages() {
  const [readChat, { loading: readChatLoading }] = useReadChat();

  function readChatMessages({ chatId, onSuccess }: ReadChatMessagesArgs) {
    if (chatId && !readChatLoading) {
      // fix Apollo bug 'Cannot read property 'cache' of null' and slow cache update
      setTimeout(() => {
        readChat({ variables: { id: chatId } }).then(() => onSuccess?.());
      }, 1000);
    }
  }

  return { readChatMessages };
}
