import { Option, OptionFromBackend } from 'types';

export function transformToOptions(data?: OptionFromBackend[]): Option[] {
  if (!data) return [];
  return data.map((item) => ({
    value: item.id,
    label: item.label,
    explanation: item.explanation,
  }));
}
