import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import styled from 'styled-components';
import { FeedbackTextModel } from '__generated__/types';
import { format } from 'date-fns';

interface FeedbackSectionProps {
  feedback?: Pick<
    FeedbackTextModel,
    'description' | 'therapistName' | 'createdAt' | 'sessionMetaId'
  >;
}

export const FeedbackSection: FC<FeedbackSectionProps> = ({ feedback }) => {
  return (
    <Wrapper>
      <Header>
        <HeaderText>
          {format(new Date(feedback?.createdAt || 0), 'DD MMM YYYY')}
        </HeaderText>
        <HeaderText>{feedback?.therapistName}</HeaderText>
      </Header>
      <FeedbackText>{feedback?.description}</FeedbackText>
    </Wrapper>
  );
};

const Wrapper = styled.section``;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderText = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: #444752;
`;
const FeedbackText = styled.div`
  margin-top: 7px;
  margin-bottom: 60px;
  font-style: normal;
  font-weight: normal;
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: #444752;
`;
