import { CSSProperties } from 'react';
import { Styles } from 'react-select';

export const customStyles: Partial<Styles<any, any>> = {
  container: (base, { selectProps: { isOpen } }) => ({
    ...base,
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: '-1px',
      width: '100%',
      height: '1px',
      borderRadius: '8px',
      background: `var(${isOpen ? '--purple4' : '--purple3'})`,
      transition: '0.3s ease-out',
      zIndex: 30000,
    },
  }),
  control: (base) => {
    return {
      ...base,
      minHeight: 'inherit',
      height: '44px',
      boxShadow: 'none',
      border: 0,
      background: 'transparent',

      '&:hover': {
        cursor: 'pointer',
      },
    };
  },
  input: () => ({
    color: 'transparent',
  }),
  placeholder: (base) => ({
    ...base,
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'var(--gray2)',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    overflow: 'visible',
    zIndex: 11000,
  }),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore-next-line
  singleValue: (base, { isFocused, hasValue, isDisabled }) => ({
    ...base,
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    color: `var(${(() => {
      if (isDisabled) {
        return '--gray7';
      } else if (isFocused || hasValue) {
        return '--black3';
      } else {
        return '--gray2';
      }
    })()})`,
  }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: (base, { isDisabled, selectProps: { isOpen } }) => ({
    ...base,
    display: isDisabled ? 'none' : 'flex',
    padding: 0,
    transform: `scale(${isOpen ? -1 : 1})`,
    transition: '0.2s ease-out',
  }),
  menu: (base) => ({
    ...base,
    top: 'calc(100% + 8px)',
    margin: 0,
    padding: 0,
    borderRadius: '8px',
    boxShadow: '0px 20px 90px var(--gray-opacity)',
    overflow: 'hidden',
    zIndex: 11000,
  }),
  menuList: (base) => ({
    ...base,
    padding: '12px 0',
    maxHeight: '168px',
  }),
  option: (base, { isSelected }) => ({
    ...base,
    padding: '12px 16px',
    minHeight: '48px',
    fontWeight: 'normal',
    lineHeight: '24px',
    fontSize: '14px',
    color: 'var(--black3)',
    background: `var(${isSelected ? '--purple11' : '--white'})`,
    transition: 'all cubic-bezier(0.4, 0, 0.2, 1) 0.15s',
    cursor: 'pointer',

    '&:hover': {
      background: `var(--purple11)`,
    },
  }),
};
