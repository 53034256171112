import { getSize } from 'lib/utils';
import React from 'react';
import styled, { CSSProp } from 'styled-components';
import { CloseButton } from 'ui/close-button';

interface Props {
  children?: React.ReactNode;
  isVisible: boolean;
  title?: string;
  description?: string;
  onClose?: () => void;
  titleCSS?: CSSProp;
  headerCSS?: CSSProp;
  paperCSS?: CSSProp;
  className?: string;
  renderTitle?: JSX.Element;
  closeButtonCSS?: CSSProp;
  descriptionCSS?: CSSProp;
}

const Modal = (props: Props) => {
  const {
    title,
    description,
    isVisible,
    children,
    onClose,
    titleCSS,
    className,
    headerCSS,
    paperCSS,
    renderTitle,
    closeButtonCSS,
    descriptionCSS,
  } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <Root onClick={onClose}>
      <Paper
        onClick={(e) => e.stopPropagation()}
        className={className}
        $CSS={paperCSS}>
        <Header $CSS={headerCSS}>
          {renderTitle
            ? renderTitle
            : title && <Title $CSS={titleCSS}>{title}</Title>}

          {onClose && <Close $CSS={closeButtonCSS} onClick={onClose} />}
        </Header>
        {description && (
          <Description $CSS={descriptionCSS}>{description}</Description>
        )}
        {children}
      </Paper>
    </Root>
  );
};

const Root = styled.div`
  position: fixed;
  overflow: auto;
  z-index: 99;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--black-opacity2);
  display: flex;
  padding: ${getSize(10)};
`;

const Paper = styled.div<{ $CSS?: CSSProp }>`
  width: 100%;
  max-width: ${getSize(530)};
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: ${getSize(34)} ${getSize(36)} ${getSize(38)};
  border-radius: ${getSize(8)};
  background-color: var(--white);

  ${({ $CSS }) => $CSS}
`;

const Title = styled.h2<{ $CSS?: CSSProp }>`
  font-weight: 600;
  font-size: ${getSize(22)};
  line-height: ${getSize(33)};
  color: var(--black3);

  ${({ $CSS }) => $CSS}
`;

const Header = styled.header<{ $CSS?: CSSProp }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: ${getSize(20)};

  ${({ $CSS }) => $CSS}
`;

const Close = styled(CloseButton)<{ $CSS?: CSSProp }>`
  margin: ${getSize(5)} 0 0 ${getSize(10)};

  ${({ $CSS }) => $CSS}
`;

const Description = styled.div<{ $CSS?: CSSProp }>`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  margin-bottom: ${getSize(37)};
  color: var(--black3);

  ${({ $CSS }) => $CSS}
`;

export default Modal;
