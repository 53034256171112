import { getSize } from 'lib/utils';
import FeedbackBlock from 'pages/therapist-page/components/feedback-block';
import React, { FC, useState, useMemo, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ShadowWrapper } from 'ui';
import { SwitchComponent } from 'ui/switch-component';
import { OrderByDirection } from '__generated__/types';
import { AllCompanies } from './components/all-companies';
import { useGetFutureUpcomingSessionCount } from 'common/query/__generated__/get-future-session-count';
import {
  FeedbackRowFragment,
  useGetUsersFeedback,
} from 'common/query/__generated__/get-users-feedback';
import { AddCompanyModal } from './components/company-modal/add-company-modal';
import {
  useAdminGetAllCompanies,
  useAdminGetAllCompaniesLazyQuery,
} from 'common/query/__generated__/admin-get-all-companies';
import { EditCompanyModal } from './components/company-modal/edit-company-modal';
import { GenerateReportModal } from './components/company-modal/components/generate-report-modal';

export enum ConfirmType {
  Refresh = 'REFRESH',
  Archive = 'ARCHIVE',
}

export enum SwitchVariants {
  Active = 'Active',
  Archived = 'Archived',
}

export enum BusinessAdminSort {
  Created = 'CREATED',
  Companies = 'COMPANIES',
  Cap = 'CAP',
  Employees = 'EMPLOYEES',
  PlanType = 'PLAN_TYPE',
  Registered = 'REGISTERED',
  Utilization = 'UTILIZATION',
  Avg = 'AVG',
  Total = 'TOTAL',
}

const BusinessPage: FC = () => {
  const [companyId, setCompanyId] = useState('1');

  const [filter, setFilter] = useState('');
  const [sortOption, setSortOption] = useState(BusinessAdminSort.Created);

  const [isModalShow, setIsModalShow] = useState(false);

  const openFeedbackModal = () => {
    setIsModalShow(true);
  };

  const closeFeedbackModal = () => {
    setIsModalShow(false);
  };

  const [isCompanyPopupShow, setIsCompanyPopupShow] = useState(false);
  const [isCompanyEditPopupShow, setIsCompanyEditPopupShow] = useState(false);

  const openAddCompanyModal = () => {
    setIsCompanyPopupShow(true);
  };

  const openEditCompanyModal = () => {
    setIsCompanyEditPopupShow(true);
  };

  const closeAddCompanyModal = () => {
    setIsCompanyPopupShow(false);
  };

  const closeEditCompanyModal = () => {
    setIsCompanyEditPopupShow(false);
  };

  const [isReportOpen, setIsReportOpen] = useState(false);

  const openReportModal = () => {
    setIsReportOpen(true);
  };

  const closeReportModal = () => {
    setIsReportOpen(false);
  };

  const [sortDirection, setSortDirection] = useState(OrderByDirection.Desc);

  const { data: futureSessionCount } = useGetFutureUpcomingSessionCount();

  const MAX_VISIBLE_FEEDBACK = 10;

  const usersFeedbackData = useGetUsersFeedback({
    variables: {
      first: MAX_VISIBLE_FEEDBACK,
    },
  });

  const [
    getAllCompanies,
    { loading: companiesLoading, error, data: companies },
  ] = useAdminGetAllCompaniesLazyQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getAllCompanies();
  }, []);

  const usersFeedback = usersFeedbackData.data?.adminUsersFeedback?.nodes;

  const userId = usersFeedback ? usersFeedback[0]?.patientId || '0' : '0';
  const [patientId, setPatientId] = useState<string>(userId);

  const feedbackList: FeedbackRowFragment[] = useMemo(
    () => usersFeedbackData.data?.adminUsersFeedback.nodes || [],
    [usersFeedbackData.data?.adminUsersFeedback.nodes],
  );

  const onRefetchCompanies = async () => {
    await getAllCompanies();
  };

  const [tab, setTab] = useState(1);

  const switchVariants = ['Active', 'Archived'];

  const switchSelect = ['Active', 'Archived'][tab - 1];

  return (
    <Wrapper>
      <ShadowWrapperStyled>
        <HeaderButtonsContainer>
          <AddCompanyButton onClick={openAddCompanyModal}>
            + Add New
          </AddCompanyButton>
          <SwitchComponentStyled
            variants={switchVariants}
            tabNumber={tab}
            setTabNumber={setTab}
          />
        </HeaderButtonsContainer>

        <AllCompaniesStyled
          dataLength={feedbackList.length}
          onNext={() => null}
          hasMore={false}
          companies={companies}
          isLoading={companiesLoading}
          futureSessionCount={
            futureSessionCount?.futureUpcomingSessionCount[0] || 0
          }
          setSortOption={setSortOption}
          setSortDirection={setSortDirection}
          sortDirection={sortDirection}
          sortOption={sortOption}
          onOpenModal={openEditCompanyModal}
          setCompanyId={setCompanyId}
          openReportModal={openReportModal}
          switchSelect={switchSelect}
        />
      </ShadowWrapperStyled>
      {isCompanyPopupShow && (
        <AddCompanyModal
          onCloseModal={closeAddCompanyModal}
          onRefetchCompanies={onRefetchCompanies}
          patientId={patientId}
        />
      )}
      {isCompanyEditPopupShow && (
        <EditCompanyModal
          onCloseModal={closeEditCompanyModal}
          companyId={companyId}
          openReportModal={openReportModal}
          onRefetchCompanies={onRefetchCompanies}
          switchSelect={switchSelect}
        />
      )}
      {isReportOpen && (
        <GenerateReportModal
          onCloseModal={closeReportModal}
          companyId={companyId}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: ${getSize(56)};
`;

const ShadowWrapperStyled = styled(ShadowWrapper)`
  padding: ${getSize(40)} 0;
`;

const AllCompaniesStyled = styled(AllCompanies)`
  margin-top: 31px;
`;

const SwitchComponentStyled = styled(SwitchComponent)`
  padding: 0 ${getSize(15)};
`;

const HeaderButtonsContainer = styled.div`
  display: flex;
`;

const AddCompanyButton = styled.button`
  background: #7b5cff;
  border-radius: 52px;
  padding: 9px 20px;

  margin-left: ${getSize(40)};
  font-weight: 600;
  font-size: 12px;

  color: #ffffff;
`;

export default BusinessPage;
