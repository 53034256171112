import * as React from 'react';

function FeedbackIcon({ ...rest }) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <path
        d="M16.5346 4.34477L17.8526 3.02681C18.0929 2.78654 18.0929 2.39702 17.8526 2.15675C17.6124 1.91648 17.2228 1.91652 16.9826 2.15675L15.6646 3.4747C15.4243 3.71493 15.4243 4.1045 15.6646 4.34477C15.9048 4.585 16.2943 4.58508 16.5346 4.34477Z"
        fill="#E5E4E8"
      />
      <path
        d="M19.3942 7.24902H18.0762C17.7364 7.24902 17.4609 7.52448 17.4609 7.86426C17.4609 8.20403 17.7364 8.47949 18.0762 8.47949H19.3942C19.7339 8.47949 20.0094 8.20403 20.0094 7.86426C20.0094 7.52448 19.7339 7.24902 19.3942 7.24902Z"
        fill="#E5E4E8"
      />
      <path
        d="M12.1465 2.54842C12.4863 2.54842 12.7617 2.27296 12.7617 1.93319V0.615234C12.7617 0.275461 12.4863 0 12.1465 0C11.8067 0 11.5312 0.275461 11.5312 0.615234V1.93319C11.5312 2.27296 11.8067 2.54842 12.1465 2.54842Z"
        fill="#E5E4E8"
      />
      <path
        d="M1.16841 15.3366C0.928342 15.5766 0.928137 15.9664 1.16841 16.2066L3.80436 18.8426C4.04455 19.0828 4.43411 19.0829 4.67442 18.8426L5.70539 17.8116L2.19942 14.3057L1.16841 15.3366Z"
        fill="#E5E4E8"
      />
      <path
        d="M11.8201 15.834L7.5 17.867L10.4532 20.8202C10.6934 21.0604 11.083 21.0605 11.3233 20.8202L13.6297 18.5138C13.87 18.2735 13.87 17.884 13.6297 17.6437L11.8201 15.834Z"
        fill="#E5E4E8"
      />
      <path
        d="M9.2882 2.15676C9.14653 2.01509 8.94474 1.95131 8.74737 1.98572C8.55 2.02017 8.3818 2.14855 8.29648 2.32984L3.07031 13.4354L6.57629 16.9414L17.6819 11.7152C17.8632 11.6299 17.9916 11.4617 18.026 11.2643C18.0605 11.0669 17.9966 10.8652 17.855 10.7235L9.2882 2.15676Z"
        fill="#E5E4E8"
      />
    </svg>
  );
}

export default FeedbackIcon;
