export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const EXPIRY_DATE_FORMAT = 'MM/YY';
export const MONTH_AND_FULL_YEAR = 'MM/YYYY';
export const HOURS_AND_MINUTES = 'HH.mm';
export const DATE_TIME = 'hh:mm a';
export const TIME_WITH_TWENTY_FOUR_HOURS = 'HH:mm';
export const FULL_YEAR_AND_MONTH = 'YYYY-MM';
export const ISO_DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';
export const DATE_AND_HOUR = 'YYYY-MM-DD[T]HH:mm';
export const DATE_MONTH = 'D MMM';
export const FULL_MONTH = 'MMMM';
export const DAY_MONTH_YEAR_FORMAT = 'DD.MM.YYYY';
export const DATE_WITH_WEEK = 'ddd, DD MMM YYYY';
export const FULL_DATE = 'DD MMMM YYYY';
export const FULL_DATE_WITH_SHORT_MONTH = 'DD MMM YYYY';
export const MONTH_DAY_FULLYEAR = 'MMM DD, YYYY';
export const DAY_AND_MONTH = 'D MMMM';

export const NUMBER_DAYS_IN_WEEK = 7;

export enum WeekFieldValue {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
  sunday = 'sunday',
}
export interface WeekField {
  value: WeekFieldValue;
  label: string;
}

export const WEEK_FIELDS: WeekField[] = [
  { value: WeekFieldValue.monday, label: 'Monday' },
  { value: WeekFieldValue.tuesday, label: 'Tuesday' },
  { value: WeekFieldValue.wednesday, label: 'Wednesday' },
  { value: WeekFieldValue.thursday, label: 'Thursday' },
  { value: WeekFieldValue.friday, label: 'Friday' },
  { value: WeekFieldValue.saturday, label: 'Saturday' },
  { value: WeekFieldValue.sunday, label: 'Sunday' },
];

export const WEEK_NUMBERS = [
  { value: 'Monday', label: 1 },
  { value: 'Tuesday', label: 2 },
  { value: 'Wednesday', label: 3 },
  { value: 'Thursday', label: 4 },
  { value: 'Friday', label: 5 },
  { value: 'Saturday', label: 6 },
  { value: 'Sunday', label: 7 },
];
