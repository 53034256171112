import React, { FC } from 'react';
import styled from 'styled-components';

interface SwitchComponentProps {
  variants: string[];
  tabNumber: number;
  setTabNumber: React.Dispatch<React.SetStateAction<number>>;
  className?: string;
}

const SwitchComponent: FC<SwitchComponentProps> = ({
  variants,
  tabNumber,
  setTabNumber,
  className,
}) => {
  return (
    <Wrapper className={className}>
      <Switch $numOfVariantActive={tabNumber}>
        {variants.map((variant, index) => (
          <Variant key={index} onClick={() => setTabNumber(index + 1)}>
            {variant}
          </Variant>
        ))}
      </Switch>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

interface SwitchProps {
  $numOfVariantActive: number;
}

const Switch = styled.div<SwitchProps>`
  background: #faf8fd;
  border-radius: 8px;
  padding: 2px 3px;
  display: flex;

  & button:nth-child(${({ $numOfVariantActive }) => $numOfVariantActive}) {
    background-color: white;
    cursor: default;
  }
`;

const Variant = styled.button`
  width: 100px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 6px;
  cursor: pointer;
`;

export default SwitchComponent;
