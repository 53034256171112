import React from 'react';

function CheckedSelectIcon({ ...rest }) {
  return (
    <svg
      width={18}
      height={13}
      viewBox="0 0 18 13"
      stroke="#D88DDE"
      fill="none"
      {...rest}>
      <path
        d="M17 1L6 12 1 7"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CheckedSelectIcon;
