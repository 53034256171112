import { useBanTherapistById } from 'common/mutation/__generated__/ban-therapist-by-id';
import { useUnbanTherapistById } from 'common/mutation/__generated__/unban-therapist-by-id';

export const useManageTherapistBan = ({
  onSuccess,
}: {
  onSuccess: () => void;
}) => {
  const [banTherapist, { loading: isLoadingBan }] = useBanTherapistById({
    update(cache) {
      cache.evict({
        fieldName: 'adminTherapistsSearch',
        broadcast: true,
      });
      cache.evict({
        fieldName: 'adminGetTherapistById',
        broadcast: true,
      });
      cache.gc();
    },
  });

  const [unbanTherapist, { loading: isLoadingUnban }] = useUnbanTherapistById({
    update(cache) {
      cache.evict({
        fieldName: 'adminTherapistsSearch',
        broadcast: true,
      });
      cache.evict({
        fieldName: 'adminGetTherapistById',
        broadcast: true,
      });
      cache.gc();
    },
  });

  const handleBanTherapist = (therapistId: string) => {
    banTherapist({
      variables: {
        input: {
          therapistId,
        },
      },
    }).finally(onSuccess);
  };

  const handleUnbanTherapist = (therapistId: string) => {
    unbanTherapist({
      variables: {
        input: {
          therapistId,
        },
      },
    });
  };

  return {
    onBanTherapist: handleBanTherapist,
    onUnbanTherapist: handleUnbanTherapist,
    isLoadingBan,
    isLoadingUnban,
  };
};
