import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ResendSubscriptionEmailVariables = Types.Exact<{
  companyPlanId: Types.Scalars['String'];
}>;


export type ResendSubscriptionEmail = (
  { __typename: 'Mutation' }
  & Pick<Types.Mutation, 'resendSubscriptionEmail'>
);


export const ResendSubscriptionEmailDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ResendSubscriptionEmail"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyPlanId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resendSubscriptionEmail"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"companyPlanId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyPlanId"}}}]}]}}]} as unknown as DocumentNode;
export type ResendSubscriptionEmailMutationFn = Apollo.MutationFunction<ResendSubscriptionEmail, ResendSubscriptionEmailVariables>;

/**
 * __useResendSubscriptionEmail__
 *
 * To run a mutation, you first call `useResendSubscriptionEmail` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendSubscriptionEmail` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendSubscriptionEmail, { data, loading, error }] = useResendSubscriptionEmail({
 *   variables: {
 *      companyPlanId: // value for 'companyPlanId'
 *   },
 * });
 */
export function useResendSubscriptionEmail(baseOptions?: Apollo.MutationHookOptions<ResendSubscriptionEmail, ResendSubscriptionEmailVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendSubscriptionEmail, ResendSubscriptionEmailVariables>(ResendSubscriptionEmailDocument, options);
      }
export type ResendSubscriptionEmailHookResult = ReturnType<typeof useResendSubscriptionEmail>;
export type ResendSubscriptionEmailMutationResult = Apollo.MutationResult<ResendSubscriptionEmail>;
export type ResendSubscriptionEmailMutationOptions = Apollo.BaseMutationOptions<ResendSubscriptionEmail, ResendSubscriptionEmailVariables>;