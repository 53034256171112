import { useGetAdminFeedbackDescriptions } from 'common/query/__generated__/get-admin-feedback-description';
import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import { CloseIcon, Loader } from 'ui';
import { FeedbackSection } from './components/feedback-section';

interface FeedbackModalProps {
  onCloseModal: () => void;
  patientId: string;
}

export const FeedbackModal: FC<FeedbackModalProps> = ({
  onCloseModal,
  patientId,
}) => {
  const { data: description, fetchMore: descriptionFetchMore } =
    useGetAdminFeedbackDescriptions({
      variables: {
        first: 10,
        patientId: patientId,
      },
    });

  const feedbackData = description?.adminFeedbackDescriptions.nodes;

  return (
    <ShadowWrapper>
      <Content>
        <Header>
          <HeaderText>Feedback</HeaderText>
          <CloseIconStyled onClick={onCloseModal} />
        </Header>
        <ScrollFeedbackContainer>
          <FeedbackContent
            dataLength={feedbackData?.length || 0}
            next={() => {
              if (!descriptionFetchMore) return;
              return descriptionFetchMore({
                variables: {
                  after:
                    description?.adminFeedbackDescriptions.pageInfo.endCursor,
                },
              });
            }}
            hasMore={Boolean(
              description?.adminFeedbackDescriptions.pageInfo.hasNextPage,
            )}
            scrollableTarget="scrollableChatsList"
            loader={<Loader />}>
            {feedbackData?.map((feedback) => (
              <FeedbackSection
                key={feedback.sessionMetaId}
                feedback={feedback}
              />
            ))}
          </FeedbackContent>
        </ScrollFeedbackContainer>
      </Content>
    </ShadowWrapper>
  );
};

const ShadowWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.51);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const Content = styled.div`
  width: ${getSize(582)};
  height: ${getSize(647)};
  background: #ffffff;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${getSize(22)} ${getSize(23)} ${getSize(19)} ${getSize(27)};
  border-bottom: 1px solid #f1f1f1;
`;
const HeaderText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: ${getSize(18)};

  color: #000000;
`;

const CloseIconStyled = styled(CloseIcon)`
  cursor: pointer;
`;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore-next-line
const FeedbackContent = styled(InfiniteScroll)`
  padding: ${getSize(27)} ${getSize(30)} ${getSize(30)} ${getSize(30)};
`;

const ScrollFeedbackContainer = styled.div`
  overflow: auto;
`;
