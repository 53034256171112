import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import styled from 'styled-components';

export enum CancellationsTabs {
  REQUESTS = 'REQUESTS',
  SESSIONS = 'SESSIONS',
}

const { REQUESTS, SESSIONS } = CancellationsTabs;

interface TabsProps {
  activeTab: CancellationsTabs;
  setActiveTab: (value: CancellationsTabs) => void;
}

const Tabs: FC<TabsProps> = ({ activeTab, setActiveTab }) => {
  return (
    <Wrapper>
      <Button
        $isActive={activeTab === REQUESTS}
        onClick={() => setActiveTab(REQUESTS)}>
        Requests
      </Button>
      <Button
        $isActive={activeTab === SESSIONS}
        onClick={() => setActiveTab(SESSIONS)}>
        Sessions
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  top: ${getSize(28)};
  left: ${getSize(40)};
  display: flex;
  padding: ${getSize(2)};
  border-radius: ${getSize(8)};
  background-color: var(--purple11);
`;

const Button = styled.button<{ $isActive?: boolean }>`
  width: ${getSize(100)};
  height: ${getSize(32)};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  border-radius: ${getSize(6)};

  ${({ $isActive }) =>
    $isActive
      ? `
      background-color:var(--white);
      `
      : ` background-color:transparent;
      color: var(--gray7)`}
`;

export default Tabs;
