import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetFutureUpcomingSessionCountVariables = Types.Exact<{ [key: string]: never; }>;


export type GetFutureUpcomingSessionCount = (
  { __typename: 'Query' }
  & Pick<Types.Query, 'futureUpcomingSessionCount'>
);


export const GetFutureUpcomingSessionCountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetFutureUpcomingSessionCount"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"futureUpcomingSessionCount"}}]}}]} as unknown as DocumentNode;

/**
 * __useGetFutureUpcomingSessionCount__
 *
 * To run a query within a React component, call `useGetFutureUpcomingSessionCount` and pass it any options that fit your needs.
 * When your component renders, `useGetFutureUpcomingSessionCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFutureUpcomingSessionCount({
 *   variables: {
 *   },
 * });
 */
export function useGetFutureUpcomingSessionCount(baseOptions?: Apollo.QueryHookOptions<GetFutureUpcomingSessionCount, GetFutureUpcomingSessionCountVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFutureUpcomingSessionCount, GetFutureUpcomingSessionCountVariables>(GetFutureUpcomingSessionCountDocument, options);
      }
export function useGetFutureUpcomingSessionCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFutureUpcomingSessionCount, GetFutureUpcomingSessionCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFutureUpcomingSessionCount, GetFutureUpcomingSessionCountVariables>(GetFutureUpcomingSessionCountDocument, options);
        }
export type GetFutureUpcomingSessionCountHookResult = ReturnType<typeof useGetFutureUpcomingSessionCount>;
export type GetFutureUpcomingSessionCountLazyQueryHookResult = ReturnType<typeof useGetFutureUpcomingSessionCountLazyQuery>;
export type GetFutureUpcomingSessionCountQueryResult = Apollo.QueryResult<GetFutureUpcomingSessionCount, GetFutureUpcomingSessionCountVariables>;