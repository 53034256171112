import { TherapistFragment } from 'common/query/__generated__/get-therapists-by-request';
import { getSize } from 'lib/utils';
import { FC, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import { Loader } from 'ui';
import { OrderByDirection, TherapistAdminSort } from '__generated__/types';
import {
  Head,
  TherapistCard,
  RowTemplate,
  EditTherapistModal,
} from './components';
import { ArrowDownIcon } from 'ui/icons';

export const revertedDirection = {
  [OrderByDirection.Asc]: OrderByDirection.Desc,
  [OrderByDirection.Desc]: OrderByDirection.Asc,
};
interface TherapistsListProps {
  dataLength: number;
  onNext: () => void;
  hasMore: boolean;
  therapists?: TherapistFragment[];
  isLoading?: boolean;
  futureSessionCountExtended: number[];
  setSortOption: (v: TherapistAdminSort) => void;
  setSortDirection: (v: OrderByDirection) => void;
  sortDirection: OrderByDirection;
  sortOption: TherapistAdminSort;
}

const TherapistsList: FC<TherapistsListProps> = ({
  onNext,
  dataLength,
  hasMore,
  therapists,
  isLoading,
  futureSessionCountExtended,
  setSortOption,
  setSortDirection,
  sortDirection,
  sortOption,
}) => {
  const [editingTherapist, setEditingTherapist] =
    useState<TherapistFragment | null>(null);

  const onCloseEditModal = () => {
    setEditingTherapist(null);
  };

  const handleSort = (option: TherapistAdminSort) => {
    if (sortOption !== option) {
      setSortOption(option);
      setSortDirection(OrderByDirection.Desc);
    } else {
      setSortDirection(revertedDirection[sortDirection]);
    }
  };

  const renderArrow = (option: TherapistAdminSort) => {
    return (
      <Icon direction={sortDirection} active={sortOption === option}>
        <ArrowDownIcon
          height={getSize(12)}
          width={getSize(12)}
          stroke={sortOption === option ? '#000' : '#97909E'}
        />
      </Icon>
    );
  };
  return (
    <>
      <Wrapper>
        <HeaderWrapper>
          <Head
            futureSessionCountExtended={futureSessionCountExtended}
            setSortOption={setSortOption}
            setSortDirection={setSortDirection}
            sortDirection={sortDirection}
            sortOption={sortOption}
          />
          <TableHeader>
            <TableHeaderText>Therapists</TableHeaderText>
            <TableHeaderText
              disabled={isLoading}
              active={sortOption === TherapistAdminSort.Rating}
              onClick={() => handleSort(TherapistAdminSort.Rating)}>
              Rating
              {renderArrow(TherapistAdminSort.Rating)}
            </TableHeaderText>
            <TableHeaderText
              disabled={isLoading}
              active={sortOption === TherapistAdminSort.RejectionsCount}
              onClick={() => handleSort(TherapistAdminSort.RejectionsCount)}>
              Booking rejections
              {renderArrow(TherapistAdminSort.RejectionsCount)}
            </TableHeaderText>
            <TableHeaderText
              disabled={isLoading}
              active={sortOption === TherapistAdminSort.InitialSessionsCount}
              onClick={() =>
                handleSort(TherapistAdminSort.InitialSessionsCount)
              }>
              Initial sessions
              {renderArrow(TherapistAdminSort.InitialSessionsCount)}
            </TableHeaderText>
            <TableHeaderText
              disabled={isLoading}
              active={sortOption === TherapistAdminSort.PastSessionsCount}
              onClick={() => handleSort(TherapistAdminSort.PastSessionsCount)}>
              Past sessions
              {renderArrow(TherapistAdminSort.PastSessionsCount)}
            </TableHeaderText>
            <TableHeaderText
              disabled={isLoading}
              active={sortOption === TherapistAdminSort.UpcomingSessions}
              onClick={() => handleSort(TherapistAdminSort.UpcomingSessions)}>
              Upcoming sessions
              {renderArrow(TherapistAdminSort.UpcomingSessions)}
            </TableHeaderText>
            <TableHeaderText
              active={sortOption === TherapistAdminSort.OverdueSessions}
              disabled={isLoading}
              onClick={() => handleSort(TherapistAdminSort.OverdueSessions)}>
              Overdue sessions
              {renderArrow(TherapistAdminSort.OverdueSessions)}
            </TableHeaderText>
            <TableHeaderText
              disabled={isLoading}
              active={
                sortOption === TherapistAdminSort.SumOfMonthAvailabilityHours
              }
              onClick={() =>
                handleSort(TherapistAdminSort.SumOfMonthAvailabilityHours)
              }>
              Availability
              {renderArrow(TherapistAdminSort.SumOfMonthAvailabilityHours)}
            </TableHeaderText>
          </TableHeader>
        </HeaderWrapper>
        <ScrollWrapper id="scrollableChatsList">
          {isLoading ? (
            <Loader hasFillWholeBlock size={50} />
          ) : (
            <TherapistList
              dataLength={dataLength}
              next={onNext}
              hasMore={hasMore}
              scrollableTarget="scrollableChatsList"
              loader={<Loader />}>
              {therapists?.map((therapist) => (
                <TherapistCard
                  key={therapist.id}
                  therapist={therapist}
                  onEdit={setEditingTherapist}
                />
              ))}
            </TherapistList>
          )}
        </ScrollWrapper>
      </Wrapper>

      {editingTherapist && (
        <EditTherapistModal
          therapist={editingTherapist}
          onCloseModal={onCloseEditModal}
        />
      )}
    </>
  );
};

const Wrapper = styled.div`
  padding: ${getSize(20)} 0;
`;
const HeaderWrapper = styled.div`
  padding: 0 ${getSize(40)};
`;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore-next-line
const TherapistList = styled(InfiniteScroll)`
  padding: ${getSize(20)}${getSize(40)};
`;

const ScrollWrapper = styled.div`
  overflow: auto;
  margin-top: ${getSize(12)};
  height: ${getSize(500)};

  &::-webkit-scrollbar {
    display: none;
  }
`;

const TableHeader = styled(RowTemplate)`
  height: ${getSize(52)};
  background-color: var(--purple11);
  border-radius: ${getSize(8)};
`;

const TableHeaderText = styled.button<{ active?: boolean }>`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  text-align: center;
  padding: 0 ${getSize(18)};
  color: var(--gray7);
  display: flex;
  align-items: center;
  color: ${({ active }) => (active ? 'var(--black)' : 'var(--gray7)')};

  &:first-child {
    padding-left: ${getSize(24)};
    text-align: left;
  }
`;

const Icon = styled.div<{ direction: OrderByDirection; active: boolean }>`
  margin-left: 0px;
  ${({ direction, active }) =>
    active &&
    direction === OrderByDirection.Asc &&
    'transform: rotate(180deg)'};
`;

export default TherapistsList;
