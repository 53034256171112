import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetUsersFeedbackVariables = Types.Exact<{
  first?: Types.Maybe<Types.Scalars['Int']>;
  after?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetUsersFeedback = (
  { __typename: 'Query' }
  & { adminUsersFeedback: (
    { __typename: 'FeedbackRowsModel' }
    & { nodes: Array<(
      { __typename: 'FeedbackUserModel' }
      & FeedbackRowFragment
    )>, pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<Types.PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);

export type FeedbackRowFragment = (
  { __typename: 'FeedbackUserModel' }
  & Pick<Types.FeedbackUserModel, 'id' | 'patientId' | 'therapistId' | 'sessionMetaId' | 'therapistName' | 'sessionsAmount' | 'rating' | 'progressRate' | 'satisfactionRate' | 'readByAdmin' | 'createdAt' | 'company'>
);

export const FeedbackRowFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FeedbackRowFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FeedbackUserModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"patientId"}},{"kind":"Field","name":{"kind":"Name","value":"therapistId"}},{"kind":"Field","name":{"kind":"Name","value":"sessionMetaId"}},{"kind":"Field","name":{"kind":"Name","value":"therapistName"}},{"kind":"Field","name":{"kind":"Name","value":"sessionsAmount"}},{"kind":"Field","name":{"kind":"Name","value":"rating"}},{"kind":"Field","name":{"kind":"Name","value":"progressRate"}},{"kind":"Field","name":{"kind":"Name","value":"satisfactionRate"}},{"kind":"Field","name":{"kind":"Name","value":"readByAdmin"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"company"}}]}}]} as unknown as DocumentNode;
export const GetUsersFeedbackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetUsersFeedback"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"first"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"after"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminUsersFeedback"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"Variable","name":{"kind":"Name","value":"first"}}},{"kind":"Argument","name":{"kind":"Name","value":"after"},"value":{"kind":"Variable","name":{"kind":"Name","value":"after"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"nodes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FeedbackRowFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"pageInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hasNextPage"}},{"kind":"Field","name":{"kind":"Name","value":"endCursor"}}]}}]}}]}},...FeedbackRowFragment.definitions]} as unknown as DocumentNode;

/**
 * __useGetUsersFeedback__
 *
 * To run a query within a React component, call `useGetUsersFeedback` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersFeedback` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersFeedback({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetUsersFeedback(baseOptions?: Apollo.QueryHookOptions<GetUsersFeedback, GetUsersFeedbackVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersFeedback, GetUsersFeedbackVariables>(GetUsersFeedbackDocument, options);
      }
export function useGetUsersFeedbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersFeedback, GetUsersFeedbackVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersFeedback, GetUsersFeedbackVariables>(GetUsersFeedbackDocument, options);
        }
export type GetUsersFeedbackHookResult = ReturnType<typeof useGetUsersFeedback>;
export type GetUsersFeedbackLazyQueryHookResult = ReturnType<typeof useGetUsersFeedbackLazyQuery>;
export type GetUsersFeedbackQueryResult = Apollo.QueryResult<GetUsersFeedback, GetUsersFeedbackVariables>;