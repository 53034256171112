import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { getSize } from 'lib/utils';
import { Button } from 'ui/button';
import { ShadowWrapper } from 'ui/shadow-wrapper';
import { dashboardRoutes } from 'routes/root-routes';
import { TherapistFragment } from 'common/query/__generated__/get-therapists-by-request';
import { DEFAULT_AVATAR } from 'lib/constants/therapist';
import { UserShortInfo } from 'components/user-short-info';
import RowTemplate from '../../../row-template';
import { PopUpMessage, RedFlagMarker } from 'ui';
import { getRoundedValue } from 'components/therapists-list/utils/rounding-helper';
import { FeedbackRowFragment } from 'common/query/__generated__/get-users-feedback';
import { CopyIcon } from 'ui';
import { AnyAaaaRecord } from 'dns';
import useCopyToClipboard from 'ui/hooks/use-copy-to-clipboard';
import { toast } from 'react-toastify';
import { FeedbackCompaniesAdminFragment } from 'common/query/__generated__/admin-comanies-feedback';
import { useGetAdminCompanyFeedbackDescriptionsLazyQuery } from 'common/query/__generated__/get-admin-company-feedback-description';

export interface TherapistCardProps {
  feedback: FeedbackCompaniesAdminFragment;
  onOpenModal: () => void;
  setCompanyId: React.Dispatch<React.SetStateAction<string>>;
}

function FeedbackB2bCard({
  feedback,
  onOpenModal,
  setCompanyId,
}: TherapistCardProps) {
  const history = useHistory();
  const [value, copy] = useCopyToClipboard();
  const [showIdModal, setShowIdModal] = useState(false);

  const openTherapistProfile = () => {
    history.push(dashboardRoutes.feedback.pathBuild(feedback.companyId));
  };

  const refIdModal = useRef(null);

  useEffect(() => {
    const onClick = (e: any) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      refIdModal?.current?.contains(e.target) || setShowIdModal(false);
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);

  const onCopyClick = (text?: string) => {
    copy(companyId);
    toast('id copied');
    setShowIdModal(false);
  };

  const onOpenFeedbackModal = () => {
    setCompanyId(companyId);
    onOpenModal();
  };

  const {
    name,
    companyId,
    sessionsAmount,
    rating,
    progressRate,
    satisfactionRate,
    readByAdmin,
    employeesAmount,
  } = feedback;

  const [getDescription, { loading, error, data: description }] =
    useGetAdminCompanyFeedbackDescriptionsLazyQuery({});

  useEffect(() => {
    getDescription({
      variables: {
        first: 10,
        companyId,
      },
    });
  }, [companyId]);

  const feedbackDescriptionLength =
    description?.adminCompanyFeedbackDescriptions.nodes?.length || 0;

  return (
    <Wrapper>
      {/* <InfoBlock ref={refIdModal}>
        <TextWithModal onClick={() => setShowIdModal(true)}>
          {name?.length && name.length > 20 ? `${name?.slice(0, 20)}...` : name}
        </TextWithModal>
        {showIdModal && (
          <TextModal>
            {name} <StyledCopyIcon onClick={() => onCopyClick(name)} />
          </TextModal>
        )}
      </InfoBlock> */}
      <InfoBlock>
        <Text title={name}>{name}</Text>
      </InfoBlock>
      <InfoBlock>
        <Text>{employeesAmount}</Text>
      </InfoBlock>
      <InfoBlock>
        <Text>{sessionsAmount}</Text>
      </InfoBlock>
      <InfoBlock>
        <Text>{rating && +rating.toFixed(2)}</Text>
      </InfoBlock>
      <InfoBlock>
        <Text>{progressRate && progressRate.toFixed(2)}</Text>
      </InfoBlock>
      <InfoBlock>
        <Text>{satisfactionRate && satisfactionRate.toFixed(2)}</Text>
      </InfoBlock>
      <InfoBlock>
        <ButtonContainer>
          <Bubble $isActive={!readByAdmin} />
          <FeedbackButton
            onClick={onOpenFeedbackModal}
            $isActive={!!feedbackDescriptionLength}>
            Feedback
          </FeedbackButton>
        </ButtonContainer>
      </InfoBlock>
    </Wrapper>
  );
}

const gridTemplate = css`
  grid-template-columns: 16% 14% 14% 14% 14% 14% 16%;
  padding: 0 15px;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled(RowTemplate)`
  height: ${getSize(64)};
  box-shadow: 0 0 ${getSize(30)} var(--blue-opacity2);
  border-radius: ${getSize(8)};
  margin-bottom: ${getSize(12)};

  ${gridTemplate}
`;

const StyledCopyIcon = styled(CopyIcon)`
  cursor: pointer;
  margin-left: 15px;
`;

const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:first-child {
    padding-left: ${getSize(24)};
    justify-content: flex-start;
  }
`;

const Text = styled.p`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--black3);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TextWithModal = styled(Text)`
  cursor: pointer;
`;

const TextModal = styled.div`
  position: absolute;
  fons-size: 14px;
  white-space: nowrap;
  background: #ffffff;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 15px;
  top: -15px;
  z-index: 2;
  display: flex;
`;

const AcceptingOfRequest = styled.div`
  position: relative;
  cursor: pointer;

  &:hover {
    .tooltip-message {
      display: block;
      white-space: nowrap;
      animation: showTooltip 0.3s ease-out forwards;
    }
  }
`;

const MoreButton = styled(Button)`
  min-height: ${getSize(20)};
  padding: ${getSize(5)} ${getSize(20)};
  font-weight: 400;
  max-width: ${getSize(70)};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface BubbleProps {
  $isActive?: boolean;
}

const Bubble = styled.div<BubbleProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
  background: #02d26e;
  margin-right: 8px;
`;

interface FeedbackButton {
  $isActive: boolean;
}

const FeedbackButton = styled.button<FeedbackButton>`
  background: ${({ $isActive }) => ($isActive ? '#7a60e9' : '#E1E6EF')};
  border-radius: 8px;
  padding: 5px 12px;
  color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
`;

export default FeedbackB2bCard;
