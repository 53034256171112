import { getSize, validationRules } from 'lib/utils';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Button, Input, ShadowWrapper } from 'ui';
import { Field, Form as FinalForm } from 'react-final-form';
import { Maybe } from '__generated__/types';

const INPUT_CSS = css`
  border: 1px solid var(--gray36);
  border-radius: ${getSize(8)};
  padding: ${getSize(6)} ${getSize(12)};
  min-height: ${getSize(47)};
  font-weight: 500;
  font-size: ${getSize(13)};
  line-height: ${getSize(24)};

  transition: 0.3s ease-out;

  &:focus {
    border-color: var(--purple);
  }

  &:hover:not(:focus) {
    border-color: var(--black10);
  }

  &:-webkit-autofill:focus {
    transition: 0.3s ease-out;
    border: 1px solid var(--purple) !important;
  }
  &:-webkit-autofill:hover:not(:-webkit-autofill:focus) {
    transition: 0.3s ease-out;
    border: 1px solid var(--black10) !important;
  }
  &:-webkit-autofill {
    transition: 0.3s ease-out;
    border: 1px solid var(--gray36) !important;
  }
`;

export interface InsuranceFormValues {
  siteId: string;
  username: string;
  password: string;
  providerId?: string;
  insuranceLogin?: Maybe<string> | undefined;
}
interface InsuranceFormProps {
  isTherapistResume?: boolean;
  initialValues?: InsuranceFormValues;
  onSubmit: (values: InsuranceFormValues) => void;
  isLoading?: boolean;
}

const InsuranceForm: FC<InsuranceFormProps> = ({
  isTherapistResume,
  initialValues,
  isLoading,
  onSubmit,
}) => {
  return (
    <Wrapper>
      <Title>
        {isTherapistResume ? 'Healthcode information' : 'Insurance providers'}
      </Title>
      <FinalForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, hasValidationErrors, dirty }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FieldsWrapper>
                <Field
                  name="siteId"
                  label="Site ID"
                  placeholder="Enter site ID"
                  validate={validationRules.required}
                  rootCSS={INPUT_CSS}
                  component={Input}
                />
                <Field
                  name="username"
                  label=" Webservices User ID"
                  placeholder="Enter user ID"
                  validate={validationRules.required}
                  rootCSS={INPUT_CSS}
                  component={Input}
                />
                <Field
                  name="password"
                  label="Password"
                  placeholder="Enter password"
                  validate={validationRules.required}
                  rootCSS={INPUT_CSS}
                  component={Input}
                />
                <Field
                  name="insuranceLogin"
                  label="FTP User ID"
                  placeholder="Enter FTP User ID"
                  rootCSS={INPUT_CSS}
                  component={Input}
                />
                {!isTherapistResume && (
                  <Field
                    name="providerId"
                    label="Provider ID"
                    placeholder="Enter provider ID"
                    validate={validationRules.required}
                    rootCSS={INPUT_CSS}
                    component={Input}
                  />
                )}
              </FieldsWrapper>
              <FormButton
                isDisabled={hasValidationErrors || !dirty}
                isLoading={isLoading}
                theme="primary">
                Save
              </FormButton>
            </form>
          );
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled(ShadowWrapper)`
  padding: ${getSize(34)} ${getSize(32)};
  margin-bottom: ${getSize(40)};
`;

const Title = styled.p`
  ont-weight: 600;
  font-size: ${getSize(24)};
  line-height: ${getSize(36)};
  margin-bottom: ${getSize(25)};
`;

const FieldsWrapper = styled.div`
  display: flex;
  margin-bottom: ${getSize(45)};
`;

const FormButton = styled(Button)`
  min-width: ${getSize(282)};
`;

export default InsuranceForm;
