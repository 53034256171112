import * as Types from '../../../../__generated__/types';

import { ChatFragment } from '../../query/__generated__/chats-list';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ChatAddedVariables = Types.Exact<{ [key: string]: never; }>;


export type ChatAdded = (
  { __typename: 'Subscription' }
  & { chatAdded: (
    { __typename: 'ChatModel' }
    & ChatFragment
  ) }
);


export const ChatAddedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"ChatAdded"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"chatAdded"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChatFragment"}}]}}]}},...ChatFragment.definitions]} as unknown as DocumentNode;

/**
 * __useChatAdded__
 *
 * To run a query within a React component, call `useChatAdded` and pass it any options that fit your needs.
 * When your component renders, `useChatAdded` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatAdded({
 *   variables: {
 *   },
 * });
 */
export function useChatAdded(baseOptions?: Apollo.SubscriptionHookOptions<ChatAdded, ChatAddedVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ChatAdded, ChatAddedVariables>(ChatAddedDocument, options);
      }
export type ChatAddedHookResult = ReturnType<typeof useChatAdded>;
export type ChatAddedSubscriptionResult = Apollo.SubscriptionResult<ChatAdded>;