import React from 'react';
import { getSize } from 'lib/utils';

function ArrowDownIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={getSize(24)}
      height={getSize(24)}
      fill="none"
      stroke="#13101F"
      viewBox="0 0 24 24"
      {...rest}>
      <path strokeLinecap="square" strokeWidth="2" d="M6 9l6 6 6-6" />
    </svg>
  );
}

export default ArrowDownIcon;
