import React from 'react';

function VideoCameraIcon({ ...rest }) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <g clipPath="url(#clip0_20_10)">
        <path
          d="M13.9458 5.01428H2.95461C1.50494 5.01428 0.318848 6.20038 0.318848 7.65005V16.5326C0.318848 17.9823 1.50494 19.1684 2.95461 19.1684H13.9458C15.3954 19.1684 16.5815 17.9823 16.5815 16.5326V7.65005C16.5815 6.17402 15.3954 5.01428 13.9458 5.01428Z"
          fill="white"
        />
        <path
          d="M22.4595 6.46311C22.3014 6.48947 22.1432 6.56854 22.0115 6.64761L17.8997 9.0198V15.1348L22.0378 17.507C22.8022 17.955 23.7511 17.6915 24.1991 16.9271C24.3309 16.6899 24.41 16.4263 24.41 16.1364V7.99185C24.41 7.01662 23.4875 6.22589 22.4595 6.46311Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_20_10">
          <rect
            width="24.0909"
            height="24.0909"
            fill="white"
            transform="translate(0.318848 0.0454102)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default VideoCameraIcon;
