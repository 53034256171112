import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { auth0WebAuth, refreshAccessToken } from 'lib/auth';
import { StorageService } from 'lib/utils';
import { notifyError } from 'lib/utils/notification';
import { DecodeTokenService } from 'lib/services';
import { Loader } from 'ui';
import { authRoutes, dashboardRoutes } from 'routes/root-routes';
import { marketingOnlyEmails } from 'types';

export interface ChooseRoleForm {
  role: string;
}

const isRememberUser = Boolean(StorageService.getIsRememberUser());

function Callback() {
  const history = useHistory();

  /** TODO: this is hack for merketing only admins */
  const navigateToHome = useCallback(() => {
    const looggedUserEmail = StorageService.getAdminEmail() || '';
    const routeToRedirect = marketingOnlyEmails.includes(looggedUserEmail)
      ? dashboardRoutes.resources.PATH
      : dashboardRoutes.requests.PATH;

    history.replace(routeToRedirect);
  }, [history]);

  const handleSuccessSendRole = useCallback(() => {
    refreshAccessToken(isRememberUser).then(navigateToHome);
  }, [navigateToHome]);

  useEffect(() => {
    auth0WebAuth.parseHash((errors, result) => {
      const accessToken = result?.accessToken;
      const error = errors?.errorDescription;

      if (error) {
        notifyError({ text: error });
        setTimeout(() => history.replace(authRoutes.auth.PATH), 5000);
      } else if (accessToken) {
        const roleFromToken =
          DecodeTokenService.getUserRoleFromToken(accessToken);
        if (roleFromToken === 'admin') {
          StorageService.setAccessToken(accessToken, isRememberUser);
          navigateToHome();
        }
      }
    });
  }, [navigateToHome, handleSuccessSendRole, history]);

  return <Loader hasFillWholeBlock size={50} />;
}

export default Callback;
