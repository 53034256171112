import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SendMessageVariables = Types.Exact<{
  input: Types.MessageInput;
}>;


export type SendMessage = (
  { __typename: 'Mutation' }
  & { sendMessage: (
    { __typename: 'MessagePayload' }
    & { message?: Types.Maybe<(
      { __typename: 'MessageModel' }
      & Pick<Types.MessageModel, 'chatId'>
    )>, userErrors: Array<(
      { __typename: 'ValidationError' }
      & Pick<Types.ValidationError, 'field' | 'messages'>
    )> }
  ) }
);


export const SendMessageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SendMessage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"MessageInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sendMessage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"chatId"}}]}},{"kind":"Field","name":{"kind":"Name","value":"userErrors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"field"}},{"kind":"Field","name":{"kind":"Name","value":"messages"}}]}}]}}]}}]} as unknown as DocumentNode;
export type SendMessageMutationFn = Apollo.MutationFunction<SendMessage, SendMessageVariables>;

/**
 * __useSendMessage__
 *
 * To run a mutation, you first call `useSendMessage` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessage` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessage, { data, loading, error }] = useSendMessage({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMessage(baseOptions?: Apollo.MutationHookOptions<SendMessage, SendMessageVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessage, SendMessageVariables>(SendMessageDocument, options);
      }
export type SendMessageHookResult = ReturnType<typeof useSendMessage>;
export type SendMessageMutationResult = Apollo.MutationResult<SendMessage>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<SendMessage, SendMessageVariables>;