import React, { useCallback } from 'react';
import { NavbarElementProps } from 'react-day-picker';
import styled from 'styled-components';

import { ArrowDownIcon } from 'ui/icons';
import { getSize, TimeUtil, ISO_DATE_FORMAT } from 'lib/utils';

interface Navbar extends NavbarElementProps {
  onMonthChange?: (value: Date) => void;
  selectedDay?: Date | Date[];
}

function Navbar({
  onPreviousClick,
  onNextClick,
  localeUtils,
  month,
  selectedDay,
  onMonthChange,
}: Navbar) {
  const date = localeUtils.formatMonthTitle(month);

  const onNextMoth = useCallback(() => {
    if (selectedDay && !Array.isArray(selectedDay) && onMonthChange) {
      const nextMonth = TimeUtil.parse(
        selectedDay.toISOString(),
        ISO_DATE_FORMAT,
      )
        .add(1, 'month')
        .toDate();

      onMonthChange(nextMonth);
    } else {
      onNextClick();
    }
  }, [onMonthChange, onNextClick, selectedDay]);

  const onPrevMonth = useCallback(() => {
    if (selectedDay && !Array.isArray(selectedDay) && onMonthChange) {
      const prevMonth = TimeUtil.parse(
        selectedDay.toISOString(),
        ISO_DATE_FORMAT,
      )
        .subtract(1, 'month')
        .toDate();

      onMonthChange(prevMonth);
    } else {
      onPreviousClick();
    }
  }, [onMonthChange, onPreviousClick, selectedDay]);

  return (
    <Root onClick={(e) => e.stopPropagation()}>
      <Button type="button" onClick={onPrevMonth}>
        <LeftIcon />
      </Button>
      <DateDisplay>{date}</DateDisplay>
      <Button type="button" onClick={onNextMoth}>
        <RightIcon />
      </Button>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.3em;
`;

const Button = styled.button`
  &:hover {
    svg {
      stroke: var(--purple);
    }
  }
`;

const Icon = styled(ArrowDownIcon)`
  stroke: var(--gray2);
  transition: 0.3s ease-out;
`;

const LeftIcon = styled(Icon)`
  transform: rotate(90deg);
`;

const RightIcon = styled(Icon)`
  transform: rotate(-90deg);
`;

const DateDisplay = styled.p`
  flex-grow: 1;
  text-align: center;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(14)};
  color: var(--black3);
`;

export default Navbar;
