import React from 'react';
import ContainerWithoutCssReset from './container-without-css-reset';
import styled from 'styled-components';
import { TimeUtil } from 'lib/utils';

const PostPreview = (props: any) => {
  const { title, postText } = props;

  console.log('post text', postText);

  const parsedPostText = postText.replace(/\n/g, '<br />');
  console.log('parsedPostText', parsedPostText);

  return (
    <Container>
      <Title>{title}</Title>

      <RowContainer>
        <CreatedAt>
          {TimeUtil.getFormatted(new Date(), 'Do MMMM YYYY')}
        </CreatedAt>
        <Dot />
        <Dot />
        <Dot />
        <Dot />
      </RowContainer>

      <ContainerWithoutCssReset
        dangerouslySetInnerHTML={{
          __html: postText,
        }}></ContainerWithoutCssReset>
    </Container>
  );
};

const Container = styled.div`
  padding: 15px;
`;

const Title = styled.p`
  font-weight: 400;
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 20px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
`;

const CreatedAt = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-right: 15px;
`;

const Dot = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 30px;
  background: var(--gray4);
  margin-right: 3px;
  margin-left: 3px;
`;

export default PostPreview;
