import React from 'react';
import styled from 'styled-components';

import { getSize, transformLongStringToShortWithDots } from 'lib/utils';
import { MessageAttachment } from '../../message-send-form';

import { CrossIcon, ErrorMessage } from 'ui';

export interface AttachmentItemProps {
  uploadPercent?: number;
  attachment: MessageAttachment;
  onCloseClick?: () => void;
  isExpanded: boolean;
}

function AttachmentItem({
  uploadPercent,
  onCloseClick,
  isExpanded,
  attachment: { name, format },
}: AttachmentItemProps) {
  const hasError = Number(uploadPercent) < 0;

  return (
    <li>
      <Inner $hasError={hasError}>
        <Name>
          {`${transformLongStringToShortWithDots(
            name,
            isExpanded ? 30 : 12,
          )}.${format.toLowerCase()}`}
        </Name>
        {uploadPercent && <UploadProgress $percent={uploadPercent} />}

        <CloseButton
          type="button"
          onClick={onCloseClick}
          disabled={Boolean(uploadPercent)}>
          <CrossIconStylized className="close-attachment-icon" />
        </CloseButton>
      </Inner>
      {hasError && <ErrorMessage>Upload file error</ErrorMessage>}
    </li>
  );
}

const Inner = styled.div<{ $hasError: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 ${getSize(8)} 0 ${getSize(10)};
  background: var(--purple3);
  border-radius: ${getSize(4)};
  border: ${getSize(1)} solid
    var(${({ $hasError }) => ($hasError ? '--red' : '--purple3')});
`;

const Name = styled.p`
  margin: 0 auto 0 0;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  white-space: nowrap;
`;

const UploadProgress = styled.div<{ $percent: number }>`
  position: relative;
  margin: 0 ${getSize(8)} 0 0;
  width: ${getSize(46)};
  height: ${getSize(12)};
  border: ${getSize(1)} solid var(--gray4);
  background: var(--pink2);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ $percent }) => $percent}%;
    height: 100%;
    background: var(--purple4);
  }
`;

const CloseButton = styled.button`
  &:not(:disabled) {
    &:hover {
      .close-attachment-icon {
        stroke: var(--purple);
      }
    }
  }

  &:disabled {
    &:hover {
      cursor: not-allowed;
    }
  }
`;

const CrossIconStylized = styled(CrossIcon)`
  width: ${getSize(10)};
  height: ${getSize(10)};
  stroke: var(--black3);
  transition: 0.3s ease-out;
`;

export default AttachmentItem;
