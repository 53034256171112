import * as Types from '../../../__generated__/types';

import { TherapistInsuranceCredentialsFragment } from '../../query/__generated__/get-therapist-by-id';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type EditTherapistInsuranceCredentialsVariables = Types.Exact<{
  input: Types.TherapistCredentialsInput;
}>;


export type EditTherapistInsuranceCredentials = (
  { __typename: 'Mutation' }
  & { adminTherapistCredentialsEdit: (
    { __typename: 'TherapistCredentialsPayload' }
    & { creds?: Types.Maybe<(
      { __typename: 'TherapistCredentialsModel' }
      & TherapistInsuranceCredentialsFragment
    )>, userErrors: Array<(
      { __typename: 'ValidationError' }
      & Pick<Types.ValidationError, 'field' | 'messages'>
    )> }
  ) }
);


export const EditTherapistInsuranceCredentialsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"EditTherapistInsuranceCredentials"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TherapistCredentialsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminTherapistCredentialsEdit"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"creds"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"TherapistInsuranceCredentialsFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"userErrors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"field"}},{"kind":"Field","name":{"kind":"Name","value":"messages"}}]}}]}}]}},...TherapistInsuranceCredentialsFragment.definitions]} as unknown as DocumentNode;
export type EditTherapistInsuranceCredentialsMutationFn = Apollo.MutationFunction<EditTherapistInsuranceCredentials, EditTherapistInsuranceCredentialsVariables>;

/**
 * __useEditTherapistInsuranceCredentials__
 *
 * To run a mutation, you first call `useEditTherapistInsuranceCredentials` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTherapistInsuranceCredentials` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTherapistInsuranceCredentials, { data, loading, error }] = useEditTherapistInsuranceCredentials({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTherapistInsuranceCredentials(baseOptions?: Apollo.MutationHookOptions<EditTherapistInsuranceCredentials, EditTherapistInsuranceCredentialsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTherapistInsuranceCredentials, EditTherapistInsuranceCredentialsVariables>(EditTherapistInsuranceCredentialsDocument, options);
      }
export type EditTherapistInsuranceCredentialsHookResult = ReturnType<typeof useEditTherapistInsuranceCredentials>;
export type EditTherapistInsuranceCredentialsMutationResult = Apollo.MutationResult<EditTherapistInsuranceCredentials>;
export type EditTherapistInsuranceCredentialsMutationOptions = Apollo.BaseMutationOptions<EditTherapistInsuranceCredentials, EditTherapistInsuranceCredentialsVariables>;