import { dashboardRoutes } from 'routes/root-routes';
import { ReadChatMessagesArgs } from './use-read-chat-messages';
import { ChatFragment } from '../query/__generated__/chats-list';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { useHistory, generatePath, useParams } from 'react-router-dom';
import { getChatById } from '../utils';
import { useChatsList } from '../query/__generated__/chats-list';
import { ChatUserType } from '__generated__/types';
import { ADMIN_ID, THERAPISTS_GROUP_ID } from '../messaging-page';

interface UseChatListLogicArgs {
  readChatMessages: (args: ReadChatMessagesArgs) => void;
}

export const MAX_VISIBLE_CHATS = 20;

export function useChatListLogic({ readChatMessages }: UseChatListLogicArgs) {
  const history = useHistory();
  const [selectedChatId, setSelectedChatId] = useState('');
  const [currentUnreadCount, setCurrentUnreadCount] = useState(0);
  const { chatId: chatIdFromUrl } = useParams<{ chatId?: string }>();

  const chatsListQuery = useChatsList({
    variables: { limit: MAX_VISIBLE_CHATS },
    notifyOnNetworkStatusChange: true,
  });

  const chatsList = useMemo(() => {
    const chats = chatsListQuery.data?.listChats.nodes;
    if (!chats) return [];

    const currentChats = chats.reduce<{
      data: ChatFragment[];
      therapistsChat?: ChatFragment;
    }>(
      (acc, chat) => {
        const isTherapistsGroupChatType = chat.participants.length > 2;
        const isAdminChatType =
          chat.participants.length === 2 && chat.type === ChatUserType.Admin;

        if (isTherapistsGroupChatType) {
          acc.therapistsChat = chat;
        } else if (!isAdminChatType) {
          acc.data.push(chat);
        }

        return acc;
      },
      { data: [] },
    );

    if (currentChats.therapistsChat) {
      currentChats.data.unshift(currentChats.therapistsChat);
    }

    return currentChats.data;
  }, [chatsListQuery.data?.listChats.nodes]);

  const selectedChat = useMemo(
    () => getChatById(chatsList, selectedChatId),
    [chatsList, selectedChatId],
  );

  const participantsCountOfSelectedChat = useMemo(() => {
    if (!selectedChat) return 0;

    return selectedChat.participants.length > 2
      ? selectedChat.participants.filter(
          (participant) =>
            participant.id !== ADMIN_ID &&
            participant.id !== THERAPISTS_GROUP_ID,
        ).length || 0
      : 0;
  }, [selectedChat]);

  const setSelectedChat = useCallback(
    (chatId: string) => {
      setSelectedChatId(chatId);
      history.replace(generatePath(dashboardRoutes.messaging.PATH, { chatId }));
    },
    [history],
  );

  useEffect(() => {
    if (!selectedChatId && chatsList.length > 0) {
      let newSelectedChat = chatsList[0];

      if (chatIdFromUrl) {
        newSelectedChat =
          chatsList.find((chat) => chat.id === chatIdFromUrl) ||
          newSelectedChat;
      }

      const { unreadCount, id: newChatId } = newSelectedChat;
      setSelectedChat(newChatId);

      if (unreadCount) {
        readChatMessages({ chatId: newChatId });
        setCurrentUnreadCount(unreadCount);
      }
    }
  }, [
    chatsList,
    selectedChatId,
    setSelectedChat,
    chatIdFromUrl,
    readChatMessages,
  ]);

  return {
    selectedChatId,
    chatsList,
    selectedChat,
    chatsListQuery,
    currentUnreadCount,
    setCurrentUnreadCount,
    setSelectedChat,
    participantsCountOfSelectedChat,
  };
}
