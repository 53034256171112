import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UnbanTherapistByIdVariables = Types.Exact<{
  input: Types.TherapistRemoveInput;
}>;


export type UnbanTherapistById = (
  { __typename: 'Mutation' }
  & Pick<Types.Mutation, 'adminUnBanTherapistById'>
);


export const UnbanTherapistByIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UnbanTherapistById"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TherapistRemoveInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminUnBanTherapistById"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type UnbanTherapistByIdMutationFn = Apollo.MutationFunction<UnbanTherapistById, UnbanTherapistByIdVariables>;

/**
 * __useUnbanTherapistById__
 *
 * To run a mutation, you first call `useUnbanTherapistById` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnbanTherapistById` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unbanTherapistById, { data, loading, error }] = useUnbanTherapistById({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnbanTherapistById(baseOptions?: Apollo.MutationHookOptions<UnbanTherapistById, UnbanTherapistByIdVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnbanTherapistById, UnbanTherapistByIdVariables>(UnbanTherapistByIdDocument, options);
      }
export type UnbanTherapistByIdHookResult = ReturnType<typeof useUnbanTherapistById>;
export type UnbanTherapistByIdMutationResult = Apollo.MutationResult<UnbanTherapistById>;
export type UnbanTherapistByIdMutationOptions = Apollo.BaseMutationOptions<UnbanTherapistById, UnbanTherapistByIdVariables>;