import * as Types from '../../../../__generated__/types';

import { ChatFragment } from '../../query/__generated__/chats-list';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ChatChangedVariables = Types.Exact<{ [key: string]: never; }>;


export type ChatChanged = (
  { __typename: 'Subscription' }
  & { chatChanged: (
    { __typename: 'ChatModel' }
    & ChatFragment
  ) }
);


export const ChatChangedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"ChatChanged"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"chatChanged"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChatFragment"}}]}}]}},...ChatFragment.definitions]} as unknown as DocumentNode;

/**
 * __useChatChanged__
 *
 * To run a query within a React component, call `useChatChanged` and pass it any options that fit your needs.
 * When your component renders, `useChatChanged` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatChanged({
 *   variables: {
 *   },
 * });
 */
export function useChatChanged(baseOptions?: Apollo.SubscriptionHookOptions<ChatChanged, ChatChangedVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ChatChanged, ChatChangedVariables>(ChatChangedDocument, options);
      }
export type ChatChangedHookResult = ReturnType<typeof useChatChanged>;
export type ChatChangedSubscriptionResult = Apollo.SubscriptionResult<ChatChanged>;