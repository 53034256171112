import { SessionNeedTransferModelFragment } from 'common/query/__generated__/get-insurance-sessions';
import { DEFAULT_AVATAR } from 'lib/constants/therapist';
import { FULL_DATE_WITH_HOUR, getSize, TimeUtil } from 'lib/utils';
import React, { FC } from 'react';
import styled, { CSSProp } from 'styled-components';
import {
  AlertIcon,
  Button,
  CircleCheckIcon,
  ClockIcon,
  InPersonIcon,
  LiveChatIcon,
  PopUpMessage,
  ShadowWrapper,
  VideoChatIcon,
} from 'ui';

import { BillStatus, RemittanceStatus, SessionType } from '__generated__/types';

const POPUP_CSS: CSSProp = {
  height: getSize(43),
  alignItems: 'center',
  zIndex: 100,
  whiteSpace: 'nowrap',
};

const SESSION_TYPE_ICONS = {
  [SessionType.InPerson]: <InPersonIcon />,
  [SessionType.VideoCall]: <VideoChatIcon />,
  [SessionType.LiveChat]: <LiveChatIcon />,
};

const ErrorIcon = () => AlertIcon({ stroke: 'var(--red)' });

const {
  Validated,
  InProcess,
  ProcessingNotRequired,
  AwaitingErrorCorrection,
  AwaitingInsurer,
  Cancelled,
  Unprocessed,
  Collected,
} = BillStatus;

export const INSURANCE_BILL_STATUS = {
  [Validated]: ClockIcon,
  [InProcess]: ClockIcon,
  [ProcessingNotRequired]: ClockIcon,
  [AwaitingErrorCorrection]: ClockIcon,
  [AwaitingInsurer]: ClockIcon,
  [Cancelled]: ErrorIcon,
  [Unprocessed]: ClockIcon,
  [Collected]: CircleCheckIcon,
};
export const INSURANCE_BILL_TITLES = {
  [Validated]: 'Pending',
  [InProcess]: 'Pending',
  [ProcessingNotRequired]: 'Pending',
  [AwaitingErrorCorrection]: 'Pending',
  [AwaitingInsurer]: 'Pending',
  [Cancelled]: 'Failed',
  [Unprocessed]: 'Pending',
  [Collected]: 'Collected by insurer',
};

interface SessionItemProps {
  session: SessionNeedTransferModelFragment;
  onPaySession: () => void;
  isPayButtonHide?: boolean;
}

const SessionItem: FC<SessionItemProps> = ({
  session,
  onPaySession,
  isPayButtonHide,
}) => {
  const {
    billStatus,
    sessionType,
    startDateUtc,
    patientConnected,
    invoiceNo,
    therapistPublic,
    amountToPay,
    remittanceStatus,
  } = session;
  const { firstName, lastName, avatar, insuranceCompany } = patientConnected;
  const { fullName, professionalTitle, avatarUrl } = therapistPublic;

  const BillStatusIcon = billStatus && INSURANCE_BILL_STATUS[billStatus];

  const startDate = TimeUtil.getFormatted(startDateUtc, FULL_DATE_WITH_HOUR);

  return (
    <Wrapper>
      <Date>{startDate}</Date>
      <UserInfo>
        <Avatar src={avatarUrl || DEFAULT_AVATAR} />
        <div>
          <BlackText>{fullName}</BlackText>
          <GrayText>{professionalTitle}</GrayText>
        </div>
      </UserInfo>
      <UserInfo>
        <Avatar src={avatar?.source || DEFAULT_AVATAR} />
        <BlackText>
          {firstName} {lastName}
        </BlackText>
      </UserInfo>
      <Container>
        <BlackText>{amountToPay ? `${amountToPay} £` : '-'}</BlackText>
      </Container>
      <Container>{SESSION_TYPE_ICONS[sessionType]}</Container>
      <Container>
        <BlackText className="text-line-clamp-1">{invoiceNo}</BlackText>
        <PopUpMessage rootCSS={POPUP_CSS} className="paid-status">
          {invoiceNo}
        </PopUpMessage>
      </Container>
      <Container>
        {BillStatusIcon ? <BillStatusIcon /> : '-'}
        {billStatus && (
          <PopUpMessage rootCSS={POPUP_CSS} className="paid-status">
            {INSURANCE_BILL_TITLES[billStatus]}
          </PopUpMessage>
        )}
      </Container>
      <Container>
        <PayStatus
          color={remittanceStatus === RemittanceStatus.Paid ? 'green' : 'red'}>
          {remittanceStatus}
        </PayStatus>
      </Container>

      <Container>
        {insuranceCompany?.logoRoundUrl ? (
          <InsuranceIcon src={insuranceCompany?.logoRoundUrl} />
        ) : (
          '-'
        )}
      </Container>
      {!isPayButtonHide && remittanceStatus === RemittanceStatus.Paid && (
        <PayButton theme="primary" onClick={onPaySession}>
          Pay
        </PayButton>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(ShadowWrapper)`
  padding: ${getSize(22)} 0;
  display: grid;
  grid-template-columns: 10.71% 19.24% 15.06% 6.78% 8.83% 15.15% 4.83% 3.06% 8% 8.64%;
  align-items: center;
  margin-bottom: ${getSize(16)};
`;

const BlackText = styled.p`
  text-align: left;
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
`;

const GrayText = styled(BlackText)`
  color: var(--gray7);
`;

const Date = styled(BlackText)`
  margin-right: ${getSize(16)};
  padding-left: ${getSize(20)};
`;

const Avatar = styled.img`
  width: ${getSize(40)};
  height: ${getSize(40)};
  border-radius: 100%;
  margin-right: ${getSize(8)};
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    .paid-status {
      display: flex;
      animation: showTooltip 0.3s ease-out forwards;
    }
  }
`;

const InsuranceIcon = styled.img`
  width: ${getSize(32)};
  height: ${getSize(32)};
  border-radius: 100%;
  border: 0.5px solid var(--purple3);
`;

const PayButton = styled(Button)`
  width: ${getSize(73)};
  min-height: ${getSize(31)};
  height: ${getSize(31)};
  padding: 0;
`;

interface PayStatusProps {
  color: 'green' | 'red';
}

const PayStatus = styled.p<PayStatusProps>`
  text-align: left;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  font-weight: 500;
  color: ${({ color }) => color}};
  border: ${({ color }) => `1.5px solid ${color}`};
  padding: 2px 3px;
  border-radius: 2px;
`;

export default SessionItem;
