import React from 'react';
import { LogType } from '__generated__/types';
import BillHistoryList from '../components/bill-history-list/bill-history-list';
import BillStatusItem from '../components/bill-status-item/bill-status-item';
import useReadSpecificLog from '../hooks/useReadSpecificLog';
import { useAdminBillStatusHistory } from '../queries/__generated__/admin-bill-status-history';

const BillStatusHistory = () => {
  const { data, loading, fetchMore } = useAdminBillStatusHistory({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      first: 50,
    },
  });

  const { handleReadSpecificLog } = useReadSpecificLog({
    logType: LogType.BillStatus,
  });

  const items = data?.adminBillStatusHistory.nodes || [];

  return (
    <BillHistoryList
      id="BillStatusHistory"
      loading={loading && !items?.length}
      items={items}
      onOpen={handleReadSpecificLog}
      renderItem={BillStatusItem}
      hasNextPage={!!data?.adminBillStatusHistory.pageInfo.hasNextPage}
      loadMore={() =>
        fetchMore({
          variables: {
            after: data?.adminBillStatusHistory.pageInfo.endCursor,
          },
        })
      }
    />
  );
};

export default BillStatusHistory;
