import { DateISO } from 'scalars';
import { DateTime } from 'scalars';
import { DateTimeISO } from 'scalars';
import { UUID } from 'scalars';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A string represents date without time in UTC ex. YYYY-MM-DD */
  DateISO: DateISO;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: DateTime;
  /** A string represents date with time in UTC with 0 seconds 0 milliseconds ex. YYYY-MM-DDThh:mm:00.000Z */
  DateTimeISO: DateTimeISO;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** A string represents string UUID v4 */
  UUID: UUID;
};

export type AcademicInitialModel = {
  __typename?: 'AcademicInitialModel';
  id: Scalars['String'];
  label: Scalars['String'];
  explanation?: Maybe<Scalars['String']>;
};

export type AddAdditionalTrainingInput = {
  description: Scalars['String'];
  qualificationFile: AddQualificationFileInput;
};

export type AddAvatarFileInput = {
  fileType: FileType;
  source: Scalars['String'];
  name: Scalars['String'];
  sizeInKb: Scalars['Float'];
  extension: FileExtension;
};

export type AddCompanyInput = {
  name: Scalars['String'];
  emailDomains: Array<Scalars['String']>;
  totalEmployees: Scalars['Int'];
  invoiceEmail: Scalars['String'];
  integrationType: IntegrationType;
  manager: AddManagerInput;
  slackManager?: Maybe<AddSlackManagerInput>;
  plan: AddCompanyPlanInput;
  slackDomain?: Maybe<Scalars['String']>;
  teamsWebhook?: Maybe<Scalars['String']>;
  sessionRestrictions?: Maybe<B2BSessionRestrictionsInput>;
};

export type AddCompanyPlanInput = {
  planType: CompanyPlanType;
  employeeLimit?: Maybe<Scalars['Int']>;
  activatedAt: Scalars['String'];
  durationType: PlanDurationType;
  sessionPrice?: Maybe<Scalars['Int']>;
  insuranceCompanyId?: Maybe<Scalars['String']>;
};

export type AddEventInput = {
  eventType: EventType;
  metadata: AddMetadataInput;
};

export type AddGoalInput = {
  title: Scalars['String'];
  steps: Array<StepInput>;
};

export type AddManagerInput = {
  name: Scalars['String'];
  email: Scalars['String'];
};

export type AddMaterialInput = {
  fileType: FileType;
  tag: MaterialTag;
  source: Scalars['String'];
  name: Scalars['String'];
  sizeInKb: Scalars['Float'];
  extension: FileExtension;
  patientId: Scalars['String'];
  sessionMetaId?: Maybe<Scalars['String']>;
};

export type AddMetadataInput = {
  deviceType: DeviceType;
  resourceId: Scalars['String'];
};

export type AddQualificationFileInput = {
  fileType: FileType;
  source: Scalars['String'];
  name: Scalars['String'];
  sizeInKb: Scalars['Float'];
  extension: FileExtension;
};

export type AddRegistrationInput = {
  /** uuid from professional_registrations table */
  professionalRegistration: Scalars['String'];
  type: Scalars['String'];
  /** format YYYY-MM-DD */
  expiryDate: Scalars['String'];
  registrationNumber: Scalars['String'];
};

export type AddSlackManagerInput = {
  name: Scalars['String'];
  email: Scalars['String'];
};

export type AddVideoFileInput = {
  fileType: FileType;
  source: Scalars['String'];
  name: Scalars['String'];
  sizeInKb: Scalars['Float'];
  extension: FileExtension;
};

export type AdditionalTrainingModel = {
  __typename?: 'AdditionalTrainingModel';
  id: Scalars['String'];
  description: Scalars['String'];
  qualification: QualificationModel;
  qualificationId: Scalars['String'];
  qualificationFile: QualificationFileModel;
  qualificationFileId: Scalars['String'];
};

export type AdminCompanyPayload = {
  __typename?: 'AdminCompanyPayload';
  company?: Maybe<CompanyModel>;
  userErrors: Array<UserError>;
};

export type AdminCustomerModel = {
  __typename?: 'AdminCustomerModel';
  id: Scalars['String'];
  paymentMethod?: Maybe<Scalars['String']>;
};

export type AdminEditTherapistApproachAndExperienceInput = {
  /** uuid array */
  treatmentAreas: Array<Scalars['String']>;
  /** uuid array */
  therapyApproaches: Array<Scalars['String']>;
  /** uuid array */
  addInsuranceCompanies: Array<Scalars['String']>;
  /** uuid array */
  removeInsuranceCompanies: Array<Scalars['String']>;
  /** uuid array */
  addTherapistClientTypes: Array<Scalars['String']>;
  /** uuid array */
  removeTherapistClientTypes: Array<Scalars['String']>;
  therapistId: Scalars['String'];
};

export type AdminEditTherapistPersonalInfoInput = {
  /** uuid from therapist_titles table */
  title: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  gender: Gender;
  ethnicity?: Maybe<Ethnicity>;
  phoneNumber: Scalars['String'];
  country: Scalars['String'];
  city: Scalars['String'];
  postcode: Scalars['String'];
  correspondenceAddress: Scalars['String'];
  timezone?: Maybe<Scalars['String']>;
  therapistId: Scalars['String'];
};

export type AdminEditTherapistProfileInput = {
  bio: Scalars['String'];
  avatar: AddAvatarFileInput;
  video?: Maybe<AddVideoFileInput>;
  addLanguages: Array<Scalars['String']>;
  removeLanguages: Array<Scalars['String']>;
  sessionTypes: Array<SessionType>;
  officeLocations: Array<TherapistEditOfficeLocationInput>;
  addOfficeLocations: Array<TherapistAddOfficeLocationInput>;
  /** uuid array */
  removeOfficeLocations: Array<Scalars['String']>;
  hardWorker?: Maybe<Scalars['Boolean']>;
  therapistId: Scalars['String'];
};

export type AdminEditTherapistQualificationInfoInput = {
  /** uuid from professional_titles table */
  professionalTitle: Scalars['String'];
  /** uuid from academic_initials table */
  addAcademicInitials: Array<Scalars['String']>;
  /** uuid from academic_initials table */
  removeAcademicInitials: Array<Scalars['String']>;
  addDbsCertificate?: Maybe<AddQualificationFileInput>;
  addIndemnityCertificate?: Maybe<AddQualificationFileInput>;
  /** uuid from core_trainings table */
  coreTraining: Scalars['String'];
  /** format YYYY-MM-DD */
  coreQualificationDate: Scalars['String'];
  addCoreTrainingFile?: Maybe<AddQualificationFileInput>;
  additionalTrainings: Array<EditAdditionalTrainingInput>;
  addAdditionalTrainings: Array<AddAdditionalTrainingInput>;
  /** uuid array */
  removeAdditionalTrainings: Array<Scalars['String']>;
  registrations: Array<EditRegistrationInput>;
  addRegistrations: Array<AddRegistrationInput>;
  /** uuid array */
  removeRegistrations: Array<Scalars['String']>;
  hasThreeYearsExperience: Scalars['Boolean'];
  therapistId: Scalars['String'];
};

export type AdminInsuranceSessionModel = {
  __typename?: 'AdminInsuranceSessionModel';
  id: Scalars['String'];
  isRescheduled: Scalars['Boolean'];
  isCanceled: Scalars['Boolean'];
  sessionType: SessionType;
  /** ISO 8601 date time string */
  startDateUtc: Scalars['DateTimeISO'];
  /** ISO 8601 date time string */
  endDateUtc: Scalars['DateTimeISO'];
  timezone: Scalars['String'];
  clientType: TherapistClientTypeModel;
  clientTypeId: Scalars['String'];
  therapistPublic: TherapistPublicModel;
  therapistId: Scalars['String'];
  patientConnected: PatientConnectedModel;
  patientId: Scalars['String'];
  session: SessionModel;
  sessionId: Scalars['String'];
  officeLocation?: Maybe<OfficeLocationModel>;
  paid: Scalars['Boolean'];
  needManualPayment: Scalars['Boolean'];
  sessionOutcome?: Maybe<SessionOutcomeModel>;
  reviewSkipped?: Maybe<Scalars['Boolean']>;
  paymentType: PaymentType;
  therapistName?: Maybe<Scalars['String']>;
  fullAmount: Scalars['String'];
  amountToPay: Scalars['String'];
  providerReference?: Maybe<Scalars['String']>;
  invoiceNo?: Maybe<Scalars['String']>;
  billStatus?: Maybe<BillStatus>;
  remittanceStatus?: Maybe<RemittanceStatus>;
};

export type AdminPaymentPayload = {
  __typename?: 'AdminPaymentPayload';
  success: Scalars['Boolean'];
  userErrors: Array<UserError>;
};

export type AdminTherapistPayload = {
  __typename?: 'AdminTherapistPayload';
  therapistAdmin?: Maybe<TherapistAdminModel>;
  userErrors: Array<UserError>;
};

export type AdminVoucherModel = {
  __typename?: 'AdminVoucherModel';
  id: Scalars['String'];
  patientId: Scalars['String'];
  patientName: Scalars['String'];
  therapistId: Scalars['String'];
  therapistName: Scalars['String'];
  status: VoucherStatus;
  usedAt: Scalars['String'];
  sessionType: SessionType;
  sessionDuration: Scalars['Float'];
};

export type AdminVoucherPagesModel = {
  __typename?: 'AdminVoucherPagesModel';
  vouchers: Array<AdminVoucherModel>;
  page: Scalars['Float'];
  pages: Scalars['Float'];
  pageSize: Scalars['Float'];
  total: Scalars['Float'];
};

export type AssessmentOutcomeInput = {
  about: Scalars['String'];
  /** sessionRequestId to which the AO will be attached */
  sessionRequestId?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  howLong: Scalars['String'];
  howSeriously: Scalars['String'];
  considerEndLife: Scalars['String'];
  want: Array<Scalars['String']>;
  therapyBefore?: Maybe<Scalars['String']>;
  else?: Maybe<Scalars['String']>;
};

export type AssessmentOutcomePayload = {
  __typename?: 'AssessmentOutcomePayload';
  ok: Scalars['Boolean'];
  userErrors: Array<UserError>;
};

export type AvailabilityDatesInput = {
  dates: Array<Scalars['DateISO']>;
  timezone: Scalars['String'];
};

export type AvailabilityFilter = {
  /** ISO 8601 date time string */
  startOfFromDateUTC: Scalars['DateTimeISO'];
  /** ISO 8601 date time string */
  endOfToDateUTC: Scalars['DateTimeISO'];
};

export type AvailabilityIntervalInput = {
  /** ISO 8601 date time string */
  startTimeUtc: Scalars['DateTimeISO'];
  /** ISO 8601 date time string */
  endTimeUtc: Scalars['DateTimeISO'];
  sessionType: Array<AvailabilitySessionType>;
  /** timezone ex. Asia/Omsk */
  timezone: Scalars['String'];
  officeLocationId?: Maybe<Scalars['String']>;
};

export type AvailabilityIntervalModel = {
  __typename?: 'AvailabilityIntervalModel';
  startOfIntervalUtc: Scalars['DateTimeISO'];
  endOfIntervalUtc: Scalars['DateTimeISO'];
  sessionType: Array<AvailabilitySessionType>;
  officeLocation?: Maybe<OfficeLocationModel>;
};

export type AvailabilityPayload = {
  __typename?: 'AvailabilityPayload';
  ok: Scalars['Boolean'];
  userErrors: Array<UserError>;
};

/** Set recurring availability for one day of week */
export type AvailabilityRecurringSetInput = {
  intervals: Array<AvailabilityIntervalInput>;
  /** 1 for monday, 7 for sunday */
  isoWeekDay: Scalars['Float'];
  /** When setting up recurring availability from single day, set this for removing single day availability */
  singleDayRemove?: Maybe<AvailabilitySingleRemoveInput>;
};

export enum AvailabilitySessionType {
  InPerson = 'IN_PERSON',
  VideoCall = 'VIDEO_CALL',
  LiveChat = 'LIVE_CHAT',
  All = 'ALL'
}

/** Set single availability for one day */
export type AvailabilitySingleDayInput = {
  intervals: Array<AvailabilityIntervalInput>;
  /** timezone ex. Asia/Omsk */
  timezone: Scalars['String'];
  date: Scalars['DateISO'];
};

export type AvailabilitySingleRemoveInput = {
  /** ISO 8601 date string in client timezone */
  isoDate: Scalars['DateISO'];
  /** timezone ex. Asia/Omsk */
  timezone: Scalars['String'];
};

export enum B2BRole {
  Owner = 'Owner',
  Manager = 'Manager',
  Member = 'Member'
}

export type B2BSessionRestrictions = {
  __typename?: 'B2BSessionRestrictions';
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  companyId: Scalars['String'];
  sessionDurations: Array<Scalars['Float']>;
  sessionTypes: Array<SessionType>;
  sessionProfessionalTitles: Array<Scalars['String']>;
};

export type B2BSessionRestrictionsInput = {
  sessionTypes: Array<SessionType>;
  sessionDurations: Array<Scalars['Float']>;
  sessionProfessionalTitles: Array<Scalars['String']>;
};

export type B2BUserRoleModel = {
  __typename?: 'B2BUserRoleModel';
  id: Scalars['String'];
  role: B2BRole;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum BestTime {
  Morning = 'MORNING',
  Afternoon = 'AFTERNOON',
  Evening = 'EVENING'
}

export enum BillStatus {
  Unprocessed = 'UNPROCESSED',
  InProcess = 'IN_PROCESS',
  ProcessingNotRequired = 'PROCESSING_NOT_REQUIRED',
  AwaitingErrorCorrection = 'AWAITING_ERROR_CORRECTION',
  Validated = 'VALIDATED',
  Cancelled = 'CANCELLED',
  AwaitingInsurer = 'AWAITING_INSURER',
  Collected = 'COLLECTED'
}

export type BillStatusHistoryConnection = {
  __typename?: 'BillStatusHistoryConnection';
  nodes: Array<BillStatusHistoryModel>;
  pageInfo: PageInfo;
};

export type BillStatusHistoryFilter = {
  providerReference?: Maybe<Scalars['String']>;
  status?: Maybe<BillStatus>;
};

export type BillStatusHistoryModel = {
  __typename?: 'BillStatusHistoryModel';
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  viewedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  patientId: Scalars['String'];
  therapistId: Scalars['String'];
  status: Scalars['String'];
  res?: Maybe<Scalars['JSON']>;
  providerReference: Scalars['String'];
};

export type BillSubmitHistoryConnection = {
  __typename?: 'BillSubmitHistoryConnection';
  nodes: Array<BillSubmitHistoryModel>;
  pageInfo: PageInfo;
};

export type BillSubmitHistoryModel = {
  __typename?: 'BillSubmitHistoryModel';
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  viewedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  patientId: Scalars['String'];
  therapistId: Scalars['String'];
  req?: Maybe<Scalars['JSON']>;
  res?: Maybe<Scalars['JSON']>;
  providerReference: Scalars['String'];
  startDateUtc: Scalars['DateTimeISO'];
  endDateUtc: Scalars['DateTimeISO'];
  isSuccess: Scalars['Boolean'];
};

export type BillValidateHistoryConnection = {
  __typename?: 'BillValidateHistoryConnection';
  nodes: Array<BillValidateHistoryModel>;
  pageInfo: PageInfo;
};

export type BillValidateHistoryFilter = {
  from?: Maybe<Scalars['DateISO']>;
  to?: Maybe<Scalars['DateISO']>;
};

export type BillValidateHistoryModel = {
  __typename?: 'BillValidateHistoryModel';
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  viewedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  patientId: Scalars['String'];
  therapistId: Scalars['String'];
  req?: Maybe<Scalars['JSON']>;
  res?: Maybe<Scalars['JSON']>;
  startDateUtc: Scalars['DateTimeISO'];
  endDateUtc: Scalars['DateTimeISO'];
  isSuccess: Scalars['Boolean'];
};

export type CancellationsFilter = {
  name?: Maybe<Scalars['String']>;
};

export type CancelledRequestModel = {
  __typename?: 'CancelledRequestModel';
  id: Scalars['String'];
  therapist: TherapistModel;
  patient: PatientModel;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  requestDate: Scalars['DateTime'];
  reasonForCancellation: Scalars['String'];
};

export type CancelledRequestsConnection = {
  __typename?: 'CancelledRequestsConnection';
  nodes: Array<CancelledRequestModel>;
  pageInfo: PageInfo;
};

export type CancelledSessionModel = {
  __typename?: 'CancelledSessionModel';
  id: Scalars['String'];
  therapist: TherapistModel;
  patient: PatientModel;
  sessionType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sessionDate: Scalars['DateTime'];
  reasonForCancellation: Scalars['String'];
};

export type CancelledSessionsConnection = {
  __typename?: 'CancelledSessionsConnection';
  nodes: Array<CancelledSessionModel>;
  pageInfo: PageInfo;
};

export type CancelletionsCountModel = {
  __typename?: 'CancelletionsCountModel';
  count: Scalars['Int'];
};

export type ChangeSessionPriceInput = {
  id: Scalars['String'];
  priceType: SessionPriceType;
  thirtyMin: Scalars['Float'];
  fiftyMin: Scalars['Float'];
  eightyMin: Scalars['Float'];
};

export type ChatConnectionModel = {
  __typename?: 'ChatConnectionModel';
  nodes: Array<ChatModel>;
  pageInfo: PageInfo;
};

export type ChatModel = {
  __typename?: 'ChatModel';
  id: Scalars['String'];
  title: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  lastMessage?: Maybe<MessageModel>;
  lastMessageId?: Maybe<Scalars['String']>;
  unreadCount: Scalars['Int'];
  participants: Array<ChatUserModel>;
  type: ChatUserType;
};

export type ChatUserModel = {
  __typename?: 'ChatUserModel';
  id: Scalars['String'];
  name: Scalars['String'];
  avatarUrl?: Maybe<Scalars['String']>;
  type: ChatUserType;
};

export enum ChatUserType {
  Therapist = 'THERAPIST',
  Patient = 'PATIENT',
  Admin = 'ADMIN'
}

export type ClinicCredentialsInput = {
  siteId: Scalars['String'];
  username: Scalars['String'];
  password: Scalars['String'];
  providerId: Scalars['String'];
  insuranceLogin?: Maybe<Scalars['String']>;
};

export type ClinicCredentialsModel = {
  __typename?: 'ClinicCredentialsModel';
  id: Scalars['String'];
  siteId: Scalars['String'];
  username: Scalars['String'];
  password: Scalars['String'];
  providerId: Scalars['String'];
  insuranceLogin?: Maybe<Scalars['String']>;
};

export type ClinicCredentialsPayload = {
  __typename?: 'ClinicCredentialsPayload';
  creds?: Maybe<ClinicCredentialsModel>;
  userErrors: Array<UserError>;
};

export type CompanyModel = {
  __typename?: 'CompanyModel';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  fullAddress: Scalars['String'];
  country: Scalars['String'];
  city: Scalars['String'];
  postalCode: Scalars['String'];
  companyKey: Scalars['String'];
  invoiceEmail: Scalars['String'];
  totalEmployees: Scalars['Int'];
  emailDomains: Array<Scalars['String']>;
  managerId: Scalars['String'];
  currentPlanId?: Maybe<Scalars['String']>;
  integrationType: IntegrationType;
  sessionsBooked: Scalars['Int'];
  employeesRegistered: Scalars['Int'];
  slackConfiguration: SlackConfigurationModel;
  employeesActive: Scalars['Int'];
  employeesAddedOnSlack: Scalars['Int'];
  avgSessionsPerPatient: Scalars['Float'];
  utilization: Scalars['Float'];
  manager: ManagerModel;
  slackManager?: Maybe<SlackManagerModel>;
  slackDomain?: Maybe<Scalars['String']>;
  currentPlan?: Maybe<CompanyPlanModel>;
  nextPlan?: Maybe<CompanyPlanModel>;
  sessionRestrictions: B2BSessionRestrictions;
  archived: Scalars['Boolean'];
};

export type CompanyPlanModel = {
  __typename?: 'CompanyPlanModel';
  id: Scalars['String'];
  companyId: Scalars['String'];
  planType: CompanyPlanType;
  insuranceCompanyId?: Maybe<Scalars['String']>;
  employeeLimit?: Maybe<Scalars['Int']>;
  sessionsLeft?: Maybe<Scalars['Int']>;
  activatedAt: Scalars['DateTime'];
  isActive: Scalars['Boolean'];
  activeTo: Scalars['DateTime'];
  durationType: PlanDurationType;
  sessionPrice?: Maybe<Scalars['Int']>;
  sessionRestrictions: B2BSessionRestrictions;
};

export enum CompanyPlanType {
  Unlimited = 'UNLIMITED',
  LimitedPerEmployee = 'LIMITED_PER_EMPLOYEE',
  LimitedPerCompany = 'LIMITED_PER_COMPANY',
  SubscriptionPerEmployee = 'SUBSCRIPTION_PER_EMPLOYEE',
  SubscriptionPerCompany = 'SUBSCRIPTION_PER_COMPANY',
  Insurance = 'INSURANCE',
  InsuranceLimited = 'INSURANCE_LIMITED',
  Resources = 'RESOURCES',
  Mixed = 'MIXED'
}

export type CoreTrainingModel = {
  __typename?: 'CoreTrainingModel';
  id: Scalars['String'];
  label: Scalars['String'];
  explanation?: Maybe<Scalars['String']>;
};




export enum DeviceType {
  Ios = 'IOS',
  Android = 'ANDROID',
  Web = 'WEB'
}

export type EarningsFilter = {
  year: Scalars['Int'];
};

export type EditAdditionalTrainingInput = {
  id: Scalars['String'];
  description: Scalars['String'];
  addQualificationFile?: Maybe<AddQualificationFileInput>;
};

export type EditCompanyInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  invoiceEmail?: Maybe<Scalars['String']>;
  totalEmployees?: Maybe<Scalars['Int']>;
  emailDomains?: Maybe<Array<Scalars['String']>>;
  manager?: Maybe<EditManagerInput>;
  slackManager?: Maybe<EditSlackManagerInput>;
  plan?: Maybe<EditCompanyPlanInput>;
  slackDomain?: Maybe<Scalars['String']>;
  sessionRestrictions?: Maybe<B2BSessionRestrictionsInput>;
};

export type EditCompanyPlanInput = {
  planType: CompanyPlanType;
  employeeLimit?: Maybe<Scalars['Int']>;
  activatedAt?: Maybe<Scalars['String']>;
  durationType?: Maybe<PlanDurationType>;
  sessionPrice?: Maybe<Scalars['Int']>;
  insuranceCompanyId?: Maybe<Scalars['String']>;
};

export type EditGoalInput = {
  title: Scalars['String'];
  steps: Array<StepInput>;
  id: Scalars['String'];
};

export type EditManagerInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type EditPostInput = {
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
  postText?: Maybe<Scalars['String']>;
  subCategoryId?: Maybe<Scalars['String']>;
  type?: Maybe<ResourcesPostTypeEnum>;
};

export type EditRegistrationInput = {
  /** uuid */
  id: Scalars['String'];
  /** uuid from professional_registrations table */
  professionalRegistration: Scalars['String'];
  type: Scalars['String'];
  /** format YYYY-MM-DD */
  expiryDate: Scalars['String'];
  registrationNumber: Scalars['String'];
};

export type EditSlackManagerInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type EmailChangeInput = {
  email: Scalars['String'];
};

export type EmergencyContactModel = {
  __typename?: 'EmergencyContactModel';
  id: Scalars['String'];
  therapistName?: Maybe<Scalars['String']>;
  therapistPhone?: Maybe<Scalars['String']>;
  therapistAddress?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  additionalContactName?: Maybe<Scalars['String']>;
  additionalContactPhone?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
};

export type EmergencyContactViewModel = {
  __typename?: 'EmergencyContactViewModel';
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type EnterprisePaymentModel = PaymentModelV2 & {
  __typename?: 'EnterprisePaymentModel';
  id: Scalars['String'];
  sessionType: SessionType;
  sessionDate: Scalars['DateTimeISO'];
  duration: Scalars['Int'];
  therapistId: Scalars['String'];
  therapist: TherapistPreviewModel;
  patientId: Scalars['String'];
  patient: PatientConnectedModel;
  clientType: TherapistClientTypeModel;
  paymentType: PaymentType;
  status: PaymentStatus;
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  paymentDate?: Maybe<Scalars['DateTimeISO']>;
  needManualPayment?: Maybe<Scalars['Boolean']>;
};

export enum Ethnicity {
  White = 'WHITE',
  BlackOrBlackBritish = 'BLACK_OR_BLACK_BRITISH',
  AsianOrAsianBritish = 'ASIAN_OR_ASIAN_BRITISH',
  MixedOrMultiple = 'MIXED_OR_MULTIPLE'
}

export enum EventType {
  ResourceOpened = 'RESOURCE_OPENED'
}

export type FeedbackAdminConnection = {
  __typename?: 'FeedbackAdminConnection';
  nodes: Array<FeedbackAdminModel>;
  pageInfo: PageInfo;
};

export type FeedbackAdminModel = {
  __typename?: 'FeedbackAdminModel';
  id: Scalars['String'];
  therapistId: Scalars['String'];
  patient: PatientModel;
  patientId: Scalars['String'];
  rating: Scalars['Int'];
  description: Scalars['String'];
  sessionMetaId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type FeedbackCompanyModel = {
  __typename?: 'FeedbackCompanyModel';
  name: Scalars['String'];
  companyId: Scalars['String'];
  employeesAmount: Scalars['Int'];
  sessionsAmount: Scalars['Int'];
  rating: Scalars['Float'];
  progressRate: Scalars['Float'];
  satisfactionRate: Scalars['Float'];
  readByAdmin: Scalars['Boolean'];
};

export type FeedbackCompanyRowsModel = {
  __typename?: 'FeedbackCompanyRowsModel';
  nodes: Array<FeedbackCompanyModel>;
  pageInfo: PageInfo;
};

export type FeedbackDescriptions = {
  __typename?: 'FeedbackDescriptions';
  nodes: Array<FeedbackTextModel>;
  pageInfo: PageInfo;
};

export type FeedbackReviewModel = {
  __typename?: 'FeedbackReviewModel';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  rating: Scalars['Float'];
  description: Scalars['String'];
};

export type FeedbackRowsModel = {
  __typename?: 'FeedbackRowsModel';
  nodes: Array<FeedbackUserModel>;
  pageInfo: PageInfo;
};

export type FeedbackTextModel = {
  __typename?: 'FeedbackTextModel';
  description?: Maybe<Scalars['String']>;
  therapistName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  sessionMetaId: Scalars['String'];
};

export type FeedbackTotalInfo = {
  __typename?: 'FeedbackTotalInfo';
  reviewsAmount: Scalars['Int'];
  totalRating: Scalars['Float'];
  progress: Scalars['Float'];
  experience: Scalars['Float'];
};

export type FeedbackUserModel = {
  __typename?: 'FeedbackUserModel';
  id: Scalars['String'];
  patientId: Scalars['String'];
  therapistName: Scalars['String'];
  therapistId: Scalars['String'];
  sessionsAmount: Scalars['Int'];
  sessionMetaId: Scalars['String'];
  rating: Scalars['Float'];
  progressRate: Scalars['Float'];
  satisfactionRate: Scalars['Float'];
  readByAdmin: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  company: Scalars['String'];
};

export enum FileExtension {
  Pdf = 'PDF',
  Mp4 = 'MP4',
  Mp3 = 'MP3',
  Jpg = 'JPG',
  Jpeg = 'JPEG',
  Png = 'PNG',
  Doc = 'DOC',
  Docx = 'DOCX',
  M4A = 'M4A'
}

export enum FileType {
  Document = 'DOCUMENT',
  Video = 'VIDEO',
  Audio = 'AUDIO',
  Image = 'IMAGE'
}

export enum FirstLoginType {
  Unverified = 'UNVERIFIED',
  FirstLogin = 'FIRST_LOGIN',
  NotFirstLogin = 'NOT_FIRST_LOGIN'
}

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
  Transgender = 'TRANSGENDER',
  NonBinary = 'NON_BINARY',
  GenderFluid = 'GENDER_FLUID'
}

export type GeneralPractitionerViewModel = {
  __typename?: 'GeneralPractitionerViewModel';
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type GetVouchersFilter = {
  search?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Float']>;
  pageSize?: Maybe<Scalars['Float']>;
};

export type GoalFilter = {
  type?: Maybe<GoalType>;
};

export type GoalModel = {
  __typename?: 'GoalModel';
  id: Scalars['String'];
  patientId: Scalars['String'];
  title: Scalars['String'];
  steps: Array<StepModel>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type GoalPayload = {
  __typename?: 'GoalPayload';
  goal?: Maybe<GoalModel>;
  userErrors: Array<UserError>;
};

export enum GoalType {
  All = 'ALL',
  Completed = 'COMPLETED',
  Active = 'ACTIVE'
}

export type InsuranceAddInput = {
  insuranceCompanyId: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  /** Allowed values F | M */
  gender: Scalars['String'];
  dateOfBirth: Scalars['DateISO'];
  postcode: Scalars['String'];
  policyNumber: Scalars['String'];
  addressLines?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InsuranceAuthAddInput = {
  authorizedSessionsCount: Scalars['Int'];
  authCode?: Maybe<Scalars['String']>;
};

export type InsuranceCompanyModel = {
  __typename?: 'InsuranceCompanyModel';
  id: Scalars['String'];
  label: Scalars['String'];
  logoUrl: Scalars['String'];
  logoRoundUrl: Scalars['String'];
  explanation?: Maybe<Scalars['String']>;
};

export type InsurancePaymentModel = PaymentModelV2 & {
  __typename?: 'InsurancePaymentModel';
  id: Scalars['String'];
  sessionType: SessionType;
  sessionDate: Scalars['DateTimeISO'];
  duration: Scalars['Int'];
  therapistId: Scalars['String'];
  therapist: TherapistPreviewModel;
  patientId: Scalars['String'];
  patient: PatientConnectedModel;
  clientType: TherapistClientTypeModel;
  paymentType: PaymentType;
  status: PaymentStatus;
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  paymentDate?: Maybe<Scalars['DateTimeISO']>;
  needManualPayment?: Maybe<Scalars['Boolean']>;
  insuranceCompany: InsuranceCompanyModel;
};

export type InsuranceSessionFilter = {
  status?: Maybe<InsuranceSessionStatusFilter>;
  fromStartDateUtc?: Maybe<Scalars['DateTimeISO']>;
  toStartDateUtc?: Maybe<Scalars['DateTimeISO']>;
};

export enum InsuranceSessionStatusFilter {
  All = 'ALL',
  Unpaid = 'UNPAID'
}

export enum IntegrationType {
  Regular = 'REGULAR',
  Slack = 'SLACK',
  Teams = 'TEAMS',
  TeamsBot = 'TEAMS_BOT'
}

export enum IsoWeekDay {
  Mon = 'MON',
  Tue = 'TUE',
  Wed = 'WED',
  Thu = 'THU',
  Fri = 'FRI',
  Sat = 'SAT',
  Sun = 'SUN'
}


export type LanguageModel = {
  __typename?: 'LanguageModel';
  id: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  native: Scalars['String'];
};

export type LocationInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export enum LogType {
  ValidateBill = 'VALIDATE_BILL',
  SubmitBill = 'SUBMIT_BILL',
  BillStatus = 'BILL_STATUS'
}

export type LogsIndicatorsModel = {
  __typename?: 'LogsIndicatorsModel';
  validateCount: Scalars['Int'];
  submitCount: Scalars['Int'];
  statusCount: Scalars['Int'];
  all: Scalars['Int'];
};

export enum MeStatus {
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Fail = 'FAIL'
}

export type ManagerModel = {
  __typename?: 'ManagerModel';
  name: Scalars['String'];
  email: Scalars['String'];
};

export type MaterialChangeModel = {
  __typename?: 'MaterialChangeModel';
  material: MaterialModel;
  type: MaterialChangedType;
};

export enum MaterialChangedType {
  Added = 'ADDED',
  Deleted = 'DELETED'
}

export type MaterialConnectionModel = {
  __typename?: 'MaterialConnectionModel';
  nodes: Array<MaterialModel>;
  pageInfo: PageInfo;
};

export type MaterialIndicatorsFilter = {
  patientId: Scalars['String'];
};

export type MaterialIndicatorsModel = {
  __typename?: 'MaterialIndicatorsModel';
  worksheetsCount: Scalars['Int'];
  diaryCount: Scalars['Int'];
  recordingCount: Scalars['Int'];
  all: Scalars['Int'];
};

export type MaterialModel = {
  __typename?: 'MaterialModel';
  id: Scalars['String'];
  fileType: FileType;
  source: Scalars['String'];
  name: Scalars['String'];
  sizeInKb: Scalars['Float'];
  extension: FileExtension;
  tag: MaterialTag;
  isRead: Scalars['Boolean'];
  patientId: Scalars['String'];
  sessionMetaId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  therapistName?: Maybe<Scalars['String']>;
};

export type MaterialPayload = {
  __typename?: 'MaterialPayload';
  material?: Maybe<MaterialModel>;
  userErrors: Array<UserError>;
};

export enum MaterialTag {
  Worksheet = 'WORKSHEET',
  Diary = 'DIARY',
  Recording = 'RECORDING'
}

export type MaterialsFilter = {
  patientId: Scalars['String'];
  sessionMetaId?: Maybe<Scalars['String']>;
  tag?: Maybe<MaterialTag>;
};

export type MessageAttachmentInput = {
  type: Scalars['String'];
  source: Scalars['String'];
  name: Scalars['String'];
  sizeInKB: Scalars['Int'];
  format: Scalars['String'];
};

export type MessageAttachmentModel = {
  __typename?: 'MessageAttachmentModel';
  type: Scalars['String'];
  source: Scalars['String'];
  name: Scalars['String'];
  sizeInKB: Scalars['Int'];
  format: Scalars['String'];
};

export type MessageConnectionModel = {
  __typename?: 'MessageConnectionModel';
  edges: Array<MessageEdge>;
  pageInfo: RelayPageInfo;
};

export type MessageEdge = {
  __typename?: 'MessageEdge';
  cursor: Scalars['String'];
  node: MessageModel;
};

export type MessageInput = {
  chatId: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<MessageAttachmentInput>>;
};

export type MessageModel = {
  __typename?: 'MessageModel';
  id: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<MessageAttachmentModel>>;
  senderUserId: Scalars['String'];
  chatId: Scalars['String'];
  createdAt: Scalars['DateTimeISO'];
  sender: ChatUserModel;
};

export type MessagePayload = {
  __typename?: 'MessagePayload';
  message?: Maybe<MessageModel>;
  userErrors: Array<UserError>;
};

export type MessageRemoveInput = {
  messagesIds: Array<Scalars['String']>;
};

export type MessageRemovePayload = {
  __typename?: 'MessageRemovePayload';
  node?: Maybe<MessagesRemoveModel>;
  success: Scalars['Boolean'];
  userErrors: Array<UserError>;
};

export type MessagesRemoveModel = {
  __typename?: 'MessagesRemoveModel';
  messagesIds: Array<Scalars['String']>;
  chatId: Scalars['String'];
  lastMessage?: Maybe<MessageModel>;
};

export type MonthEarningsPayload = {
  __typename?: 'MonthEarningsPayload';
  monthEarnings: Scalars['String'];
  prevMonthEarnings: Scalars['String'];
  percentSigned: Scalars['String'];
};

export type MonthPaymentModel = {
  __typename?: 'MonthPaymentModel';
  month: Scalars['Int'];
  total: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  checkUserRole?: Maybe<UserRoleModel>;
  resendVerificationEmail: Scalars['Boolean'];
  setUserRole?: Maybe<UserRoleModel>;
  setCompanyKey: Scalars['Boolean'];
  therapistConnectToStripe: Scalars['String'];
  patientSetupIntent: Scalars['String'];
  patientPaymentMethodSetDefault: Scalars['Boolean'];
  patientPaymentMethodRemove: Scalars['Boolean'];
  therapistAddPersonalInfo: TherapistPersonalInfoPayload;
  therapistEditPersonalInfo: TherapistPersonalInfoPayload;
  therapistAddApproachAndExperience: TherapistApproachPayload;
  therapistEditApproachAndExperience: TherapistApproachPayload;
  therapistAddProfileInfo: TherapistProfileInfoPayload;
  therapistEditProfileInfo: TherapistProfileInfoPayload;
  therapistAddCommunityInfo: TherapistCommunityInfoPayload;
  deleteSelfTherapistAccount: Scalars['Boolean'];
  therapistEditSettings: TherapistSettingPayload;
  patientEditPersonalInfo: PatientPersonalInfoPayload;
  patientEditEmergencyContact: PatientEmergencyContactPayload;
  deleteSelfPatientAccount: Scalars['Boolean'];
  archivePatient: PatientConnectedModel;
  unArchivePatient: PatientConnectedModel;
  registerPmiStatus: PatientModel;
  sendMessage: MessagePayload;
  removeMessage: MessageRemovePayload;
  therapistStartChat: ChatModel;
  adminStartChat: ChatModel;
  readChat: ChatModel;
  therapistSessionAdd: SessionCommonPayload;
  therapistSessionAddMultiple: SessionCommonPayload;
  sessionCancel: SessionCommonPayload;
  therapistSessionCancelAllFuture: SessionCommonPayload;
  patientSessionCancelAll: SessionCommonPayload;
  therapistSessionReschedule: SessionCommonPayload;
  therapistSessionRescheduleAllFuture: SessionCommonPayload;
  patientAddOrEditInsurance: PatientInsurancePayload;
  patientAddInsuranceAuthCode: PatientInsuranceCodePayload;
  patientSessionRequestBook: SessionRequestCommonPayload;
  patientSessionRequestRemove: Scalars['Boolean'];
  patientSessionRequestReschedule: SessionRequestCommonPayload;
  therapistSessionRequestBookAccept: SessionRequestCommonPayload;
  therapistSessionRequestDecline: SessionRequestCommonPayload;
  therapistSessionRequestRescheduleAccept: SessionRequestCommonPayload;
  availabilityRecurringSet: AvailabilityPayload;
  availabilitySetForDay: AvailabilityPayload;
  updateWorkingScheduleDay: UpdateWorkingScheduleDayPayload;
  updateWorkingScheduleDate: UpdateWorkingScheduleDatePayload;
  patientReviewAdd: ReviewCommonPayload;
  patientReviewSkip: Scalars['Boolean'];
  materialAdd: MaterialPayload;
  materialRemove: Scalars['Boolean'];
  readMaterial: Scalars['DateTime'];
  therapistAddQualification: TherapistQualificationPayload;
  therapistEditQualification: TherapistQualificationPayload;
  therapistNoteAdd: NotePayload;
  patientNoteAdd: NotePayload;
  patientNoteEdit: NotePayload;
  patientNoteRemove: Scalars['Boolean'];
  therapistNoteEdit: NotePayload;
  therapistNoteRemove: Scalars['Boolean'];
  patientNoteRemoveAll: Scalars['Boolean'];
  therapistNoteRemoveAll: Scalars['Boolean'];
  therapistOutcomeMeasureSendQuestions: OutcomeMeasurePayload;
  patientOutcomeMeasureSendResult: OutcomeMeasurePayload;
  assessmentOutcomeAdd: AssessmentOutcomePayload;
  addGoal: GoalPayload;
  editGoal: GoalPayload;
  removeGoal: Scalars['Boolean'];
  createNewPost: Scalars['Boolean'];
  editPost: Scalars['Boolean'];
  removePost: Scalars['Boolean'];
  saveUserSubCategories: Scalars['Boolean'];
  reportAbuse: Scalars['Boolean'];
  sessionOutcomeAddOrEdit: SessionOutcomePayload;
  adminAcceptTherapistRequest: AdminTherapistPayload;
  adminRejectTherapistRequest: AdminTherapistPayload;
  adminChangeCommissionPercent: AdminTherapistPayload;
  adminRemoveTherapistById: Scalars['Boolean'];
  adminBanTherapistById: Scalars['Boolean'];
  adminUnBanTherapistById: Scalars['Boolean'];
  adminRemoveTherapistFromSearch: Scalars['Boolean'];
  adminAddTherapistToSearch: Scalars['Boolean'];
  adminTherapistEditPersonalInfo: Scalars['Boolean'];
  adminEditTherapistQualificationInfo: Scalars['Boolean'];
  adminTherapistEditApproachAndExperience: Scalars['Boolean'];
  adminEditTherapistProfile: Scalars['Boolean'];
  adminChangeSessionPrice: SessionPriceAdminPayload;
  adminRemovePatientById: Scalars['Boolean'];
  adminBanPatientById: Scalars['Boolean'];
  adminUnBanPatientById: Scalars['Boolean'];
  adminClinicCredentialsEdit: ClinicCredentialsPayload;
  adminTherapistCredentialsEdit: TherapistCredentialsPayload;
  /** @deprecated Not used since insurance payments rework */
  adminPayForInsuranceSession: AdminPaymentPayload;
  readLogs: LogsIndicatorsModel;
  readSpecificLog?: Maybe<Scalars['UUID']>;
  adminLastVisitEdit: Scalars['Boolean'];
  adminAddCompany: AdminCompanyPayload;
  editCompany: AdminCompanyPayload;
  archiveCompany: Scalars['Boolean'];
  unarchiveCompany: Scalars['Boolean'];
  deleteCompany: Scalars['Boolean'];
  updateCompanyPlanToActive: Scalars['Boolean'];
  resendSubscriptionEmail: Scalars['Boolean'];
  refreshCompanyKey: Scalars['String'];
  changeEmail: Array<UserError>;
  generateReport: ReportResult;
  attachFCMTokenV2: Scalars['Boolean'];
  fcmSignOut: Scalars['Boolean'];
  addEvent: Scalars['Boolean'];
};


export type MutationSetUserRoleArgs = {
  role: Role;
};


export type MutationSetCompanyKeyArgs = {
  companyKey: Scalars['String'];
};


export type MutationTherapistConnectToStripeArgs = {
  countryCode: Scalars['String'];
};


export type MutationPatientPaymentMethodSetDefaultArgs = {
  paymentMethodId: Scalars['String'];
};


export type MutationPatientPaymentMethodRemoveArgs = {
  paymentMethodId: Scalars['String'];
};


export type MutationTherapistAddPersonalInfoArgs = {
  input: TherapistAddPersonalInfoInput;
};


export type MutationTherapistEditPersonalInfoArgs = {
  input: TherapistEditPersonalInfoInput;
};


export type MutationTherapistAddApproachAndExperienceArgs = {
  input: TherapistAddApproachInput;
};


export type MutationTherapistEditApproachAndExperienceArgs = {
  input: TherapistEditApproachInput;
};


export type MutationTherapistAddProfileInfoArgs = {
  input: TherapistAddProfileInput;
};


export type MutationTherapistEditProfileInfoArgs = {
  input: TherapistEditProfileInput;
};


export type MutationTherapistAddCommunityInfoArgs = {
  input: TherapistAddCommunityInfoInput;
};


export type MutationTherapistEditSettingsArgs = {
  input: TherapistEditSettingInput;
};


export type MutationPatientEditPersonalInfoArgs = {
  input: PatientEditPersonalInfoInput;
};


export type MutationPatientEditEmergencyContactArgs = {
  input: PatientEditEmergencyContactInput;
};


export type MutationArchivePatientArgs = {
  patientId: Scalars['String'];
};


export type MutationUnArchivePatientArgs = {
  patientId: Scalars['String'];
};


export type MutationRegisterPmiStatusArgs = {
  input: RegisterPatientPmiStatusInput;
};


export type MutationSendMessageArgs = {
  input: MessageInput;
};


export type MutationRemoveMessageArgs = {
  input: MessageRemoveInput;
};


export type MutationTherapistStartChatArgs = {
  chatUserId: Scalars['String'];
};


export type MutationAdminStartChatArgs = {
  chatUserId: Scalars['String'];
};


export type MutationReadChatArgs = {
  id: Scalars['String'];
};


export type MutationTherapistSessionAddArgs = {
  input: SessionAddInput;
};


export type MutationTherapistSessionAddMultipleArgs = {
  input: SessionAddMultipleInput;
};


export type MutationSessionCancelArgs = {
  input: SessionCancelInput;
};


export type MutationTherapistSessionCancelAllFutureArgs = {
  input: SessionCancelAllFuture;
};


export type MutationPatientSessionCancelAllArgs = {
  input: PatientSessionCancelAllInput;
};


export type MutationTherapistSessionRescheduleArgs = {
  input: SessionRescheduleInput;
};


export type MutationTherapistSessionRescheduleAllFutureArgs = {
  input: SessionRescheduleAllFutureInput;
};


export type MutationPatientAddOrEditInsuranceArgs = {
  input: InsuranceAddInput;
};


export type MutationPatientAddInsuranceAuthCodeArgs = {
  input: InsuranceAuthAddInput;
};


export type MutationPatientSessionRequestBookArgs = {
  input: SessionRequestAddInput;
};


export type MutationPatientSessionRequestRemoveArgs = {
  id: Scalars['String'];
};


export type MutationPatientSessionRequestRescheduleArgs = {
  input: SessionRequestRescheduleInput;
};


export type MutationTherapistSessionRequestBookAcceptArgs = {
  input: SessionRequestAcceptInput;
};


export type MutationTherapistSessionRequestDeclineArgs = {
  input: SessionRequestDeclineInput;
};


export type MutationTherapistSessionRequestRescheduleAcceptArgs = {
  input: SessionRequestAcceptInput;
};


export type MutationAvailabilityRecurringSetArgs = {
  input: AvailabilityRecurringSetInput;
};


export type MutationAvailabilitySetForDayArgs = {
  input: AvailabilitySingleDayInput;
};


export type MutationUpdateWorkingScheduleDayArgs = {
  input: UpdateWorkingScheduleDayInput;
};


export type MutationUpdateWorkingScheduleDateArgs = {
  input: UpdateWorkingScheduleDateInput;
};


export type MutationPatientReviewAddArgs = {
  input: ReviewAddInput;
};


export type MutationPatientReviewSkipArgs = {
  sessionMetaId: Scalars['String'];
};


export type MutationMaterialAddArgs = {
  input: AddMaterialInput;
};


export type MutationMaterialRemoveArgs = {
  id: Scalars['String'];
};


export type MutationReadMaterialArgs = {
  input: ViewMaterialInput;
};


export type MutationTherapistAddQualificationArgs = {
  input: TherapistAddQualificationInput;
};


export type MutationTherapistEditQualificationArgs = {
  input: TherapistEditQualificationInput;
};


export type MutationTherapistNoteAddArgs = {
  input: TherapistAddNoteInput;
};


export type MutationPatientNoteAddArgs = {
  input: PatientAddNoteInput;
};


export type MutationPatientNoteEditArgs = {
  input: PatientEditNoteInput;
};


export type MutationPatientNoteRemoveArgs = {
  id: Scalars['String'];
};


export type MutationTherapistNoteEditArgs = {
  input: TherapistEditNoteInput;
};


export type MutationTherapistNoteRemoveArgs = {
  id: Scalars['String'];
};


export type MutationPatientNoteRemoveAllArgs = {
  input: PatientRemoveAllNotesInput;
};


export type MutationTherapistNoteRemoveAllArgs = {
  input: TherapistRemoveAllNotesInput;
};


export type MutationTherapistOutcomeMeasureSendQuestionsArgs = {
  input: OutcomeMeasureSendQuestionsInput;
};


export type MutationPatientOutcomeMeasureSendResultArgs = {
  input: OutcomeMeasureSendResultInput;
};


export type MutationAssessmentOutcomeAddArgs = {
  input: AssessmentOutcomeInput;
};


export type MutationAddGoalArgs = {
  input: AddGoalInput;
};


export type MutationEditGoalArgs = {
  input: EditGoalInput;
};


export type MutationRemoveGoalArgs = {
  id: Scalars['String'];
};


export type MutationCreateNewPostArgs = {
  input: NewPostInput;
};


export type MutationEditPostArgs = {
  id: Scalars['String'];
  input: EditPostInput;
};


export type MutationRemovePostArgs = {
  id: Scalars['String'];
};


export type MutationSaveUserSubCategoriesArgs = {
  subCategoryIds: Array<Scalars['String']>;
};


export type MutationReportAbuseArgs = {
  input: ReportAbuseInput;
};


export type MutationSessionOutcomeAddOrEditArgs = {
  input: SessionOutcomeInput;
};


export type MutationAdminAcceptTherapistRequestArgs = {
  input: TherapistChangeRequestStatusInput;
};


export type MutationAdminRejectTherapistRequestArgs = {
  input: TherapistChangeRequestStatusInput;
};


export type MutationAdminChangeCommissionPercentArgs = {
  input: TherapistChangeCommissionInput;
};


export type MutationAdminRemoveTherapistByIdArgs = {
  input: TherapistRemoveInput;
};


export type MutationAdminBanTherapistByIdArgs = {
  input: TherapistRemoveInput;
};


export type MutationAdminUnBanTherapistByIdArgs = {
  input: TherapistRemoveInput;
};


export type MutationAdminRemoveTherapistFromSearchArgs = {
  input: TherapistRemoveInput;
};


export type MutationAdminAddTherapistToSearchArgs = {
  input: TherapistRemoveInput;
};


export type MutationAdminTherapistEditPersonalInfoArgs = {
  input: AdminEditTherapistPersonalInfoInput;
};


export type MutationAdminEditTherapistQualificationInfoArgs = {
  input: AdminEditTherapistQualificationInfoInput;
};


export type MutationAdminTherapistEditApproachAndExperienceArgs = {
  input: AdminEditTherapistApproachAndExperienceInput;
};


export type MutationAdminEditTherapistProfileArgs = {
  input: AdminEditTherapistProfileInput;
};


export type MutationAdminChangeSessionPriceArgs = {
  input: ChangeSessionPriceInput;
};


export type MutationAdminRemovePatientByIdArgs = {
  input: PatientRemoveInput;
};


export type MutationAdminBanPatientByIdArgs = {
  input: PatientRemoveInput;
};


export type MutationAdminUnBanPatientByIdArgs = {
  input: PatientRemoveInput;
};


export type MutationAdminClinicCredentialsEditArgs = {
  input: ClinicCredentialsInput;
};


export type MutationAdminTherapistCredentialsEditArgs = {
  input: TherapistCredentialsInput;
};


export type MutationAdminPayForInsuranceSessionArgs = {
  sessionMetaId: Scalars['String'];
};


export type MutationReadLogsArgs = {
  type: LogType;
};


export type MutationReadSpecificLogArgs = {
  logId: Scalars['UUID'];
  type: LogType;
};


export type MutationAdminAddCompanyArgs = {
  input: AddCompanyInput;
};


export type MutationEditCompanyArgs = {
  input: EditCompanyInput;
};


export type MutationArchiveCompanyArgs = {
  companyId: Scalars['String'];
};


export type MutationUnarchiveCompanyArgs = {
  companyId: Scalars['String'];
};


export type MutationDeleteCompanyArgs = {
  companyId: Scalars['String'];
};


export type MutationUpdateCompanyPlanToActiveArgs = {
  sessionId: Scalars['String'];
};


export type MutationResendSubscriptionEmailArgs = {
  companyPlanId: Scalars['String'];
};


export type MutationRefreshCompanyKeyArgs = {
  companyId: Scalars['String'];
};


export type MutationChangeEmailArgs = {
  input: EmailChangeInput;
};


export type MutationGenerateReportArgs = {
  dateTo: Scalars['String'];
  dateFrom: Scalars['String'];
  companyId: Scalars['String'];
};


export type MutationAttachFcmTokenV2Args = {
  deviceId: Scalars['String'];
  token: Scalars['String'];
};


export type MutationFcmSignOutArgs = {
  deviceId: Scalars['String'];
};


export type MutationAddEventArgs = {
  input: AddEventInput;
};

export type NewPostInput = {
  title: Scalars['String'];
  subtitle: Scalars['String'];
  imageUrl: Scalars['String'];
  author: Scalars['String'];
  videoUrl?: Maybe<Scalars['String']>;
  postText?: Maybe<Scalars['String']>;
  subCategoryId: Scalars['String'];
  type: ResourcesPostTypeEnum;
};

export type NoteConnectionModel = {
  __typename?: 'NoteConnectionModel';
  nodes: Array<NoteModel>;
  pageInfo: PageInfo;
};

export type NoteModel = {
  __typename?: 'NoteModel';
  id: Scalars['String'];
  therapistId?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
  createdForPatientId?: Maybe<Scalars['String']>;
  sessionMetaId?: Maybe<Scalars['String']>;
  shareWithTherapistId?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  date: Scalars['String'];
  createdByTherapist: Scalars['Boolean'];
  createdByPatient: Scalars['Boolean'];
  shared: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
};

export type NotePayload = {
  __typename?: 'NotePayload';
  note?: Maybe<NoteModel>;
  userErrors: Array<UserError>;
};

export enum NoteType {
  All = 'ALL',
  Shared = 'SHARED',
  OnlyMyNotes = 'ONLY_MY_NOTES'
}

export type NotesFilter = {
  patientId: Scalars['String'];
  type: NoteType;
  sessionMetaId?: Maybe<Scalars['String']>;
};

export type OfficeLocationModel = {
  __typename?: 'OfficeLocationModel';
  id: Scalars['String'];
  fullAddress: Scalars['String'];
  country: Scalars['String'];
  city: Scalars['String'];
  town?: Maybe<Scalars['String']>;
  postcode: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  hideFullAddress: Scalars['Boolean'];
  therapistId: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export enum OrderByDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OutcomeMeasureCodeModel = {
  __typename?: 'OutcomeMeasureCodeModel';
  id: Scalars['String'];
  type: OutcomeMeasureType;
};

export type OutcomeMeasureModel = {
  __typename?: 'OutcomeMeasureModel';
  id: Scalars['String'];
  initial: Scalars['Boolean'];
  finished: Scalars['Boolean'];
  codeId: Scalars['String'];
  codeType: OutcomeMeasureType;
  score?: Maybe<Scalars['Int']>;
  therapistId: Scalars['String'];
  patientId: Scalars['String'];
  therapistName: Scalars['String'];
  scoreRepresent?: Maybe<Scalars['String']>;
  title: TherapistTitleModel;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type OutcomeMeasurePayload = {
  __typename?: 'OutcomeMeasurePayload';
  outcomeMeasure?: Maybe<OutcomeMeasureModel>;
  userErrors: Array<UserError>;
};

export type OutcomeMeasureQuestionModel = {
  __typename?: 'OutcomeMeasureQuestionModel';
  id: Scalars['String'];
  question: Scalars['String'];
  codeId: Scalars['String'];
};

export type OutcomeMeasureSendQuestionsInput = {
  codeId: Scalars['String'];
  patientId: Scalars['String'];
};

export type OutcomeMeasureSendResultInput = {
  /** outcome measure id */
  id: Scalars['String'];
  /** sum of all points */
  score: Scalars['Int'];
};

export enum OutcomeMeasureType {
  Anxiety = 'ANXIETY',
  Depression = 'DEPRESSION'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  endCursor?: Maybe<Scalars['String']>;
};

export type PatientAddNoteInput = {
  text: Scalars['String'];
  date: Scalars['DateISO'];
  sessionMetaId?: Maybe<Scalars['String']>;
  shareWithTherapistId?: Maybe<Scalars['String']>;
};

export type PatientAdminConnection = {
  __typename?: 'PatientAdminConnection';
  nodes: Array<PatientAdminModel>;
  pageInfo: PageInfo;
};

export type PatientAdminModel = {
  __typename?: 'PatientAdminModel';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  user: UserModel;
  userId: Scalars['String'];
  patientId: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  previousSession?: Maybe<PatientAdminSessionModel>;
  upcomingSession?: Maybe<PatientAdminSessionModel>;
  upcomingSessionDate?: Maybe<Scalars['DateTime']>;
  lastTherapistName?: Maybe<Scalars['String']>;
  upcomingTherapistName?: Maybe<Scalars['String']>;
  lastSessionRequestStatus?: Maybe<Scalars['String']>;
};

export enum PatientAdminOrder {
  FromOldToNew = 'FROM_OLD_TO_NEW',
  FromNewToOld = 'FROM_NEW_TO_OLD',
  FromAToZ = 'FROM_A_TO_Z',
  FromZToA = 'FROM_Z_TO_A',
  ByUpcomingSession = 'BY_UPCOMING_SESSION',
  ByTherapistName = 'BY_THERAPIST_NAME',
  BySessionRequestStatus = 'BY_SESSION_REQUEST_STATUS'
}

export type PatientAdminSessionModel = {
  __typename?: 'PatientAdminSessionModel';
  /** ISO 8601 date time string */
  startDateUtc?: Maybe<Scalars['DateTimeISO']>;
  /** Therapist full name */
  therapistName?: Maybe<Scalars['String']>;
};

export enum PatientAdminSort {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type PatientConnectedModel = {
  __typename?: 'PatientConnectedModel';
  id: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  therapistId: Scalars['String'];
  dateOfBirthday?: Maybe<Scalars['String']>;
  avatar?: Maybe<UserPublicFileModel>;
  visitType: VisitType;
  clientTypes: Array<Scalars['String']>;
  attendedSessionsCount: Scalars['Int'];
  countSessionsNextTwoMonths: Scalars['Int'];
  archived: Scalars['Boolean'];
  chatId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  authCode?: Maybe<Scalars['String']>;
  authorizedSessionsCount?: Maybe<Scalars['Int']>;
  leftSessionsCount?: Maybe<Scalars['Int']>;
  policyNumber?: Maybe<Scalars['String']>;
  insuranceCompany?: Maybe<InsuranceCompanyModel>;
  unpaidSessionsCount: Scalars['Int'];
  invoiceRequiredSessionsCount: Scalars['Int'];
  companyId?: Maybe<Scalars['String']>;
  companyPlan?: Maybe<CompanyPlanModel>;
  pmiStatus?: Maybe<PatientPmiStatus>;
};

export type PatientEditEmergencyContactInput = {
  therapistName?: Maybe<Scalars['String']>;
  therapistPhone?: Maybe<Scalars['String']>;
  therapistAddress?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  additionalContactName?: Maybe<Scalars['String']>;
  additionalContactPhone?: Maybe<Scalars['String']>;
};

export type PatientEditNoteInput = {
  id: Scalars['String'];
  text: Scalars['String'];
  date: Scalars['DateISO'];
  shareWithTherapistId?: Maybe<Scalars['String']>;
};

export type PatientEditPersonalInfoInput = {
  avatar?: Maybe<AddAvatarFileInput>;
  /** format YYYY-MM-DD */
  dateOfBirthday?: Maybe<Scalars['DateISO']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

export type PatientEmergencyContactPayload = {
  __typename?: 'PatientEmergencyContactPayload';
  patient?: Maybe<PatientModel>;
  userErrors: Array<UserError>;
};

export type PatientInsuranceAuthModel = {
  __typename?: 'PatientInsuranceAuthModel';
  id: Scalars['String'];
  authorizedSessionsCount: Scalars['Int'];
  leftSessionsCount: Scalars['Int'];
  authCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
};

export type PatientInsuranceCodePayload = {
  __typename?: 'PatientInsuranceCodePayload';
  userErrors: Array<UserError>;
  code?: Maybe<PatientInsuranceAuthModel>;
};

export type PatientInsuranceModel = {
  __typename?: 'PatientInsuranceModel';
  id: Scalars['String'];
  insuranceCompanyId: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  gender: Scalars['String'];
  dateOfBirth: Scalars['DateISO'];
  postcode: Scalars['String'];
  status: MeStatus;
  policyNumber: Scalars['String'];
  addressLines?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PatientInsurancePayload = {
  __typename?: 'PatientInsurancePayload';
  userErrors: Array<UserError>;
  insurance?: Maybe<PatientInsuranceModel>;
};

export type PatientLocationModel = {
  __typename?: 'PatientLocationModel';
  id: Scalars['String'];
  country: Scalars['String'];
  city: Scalars['String'];
  town?: Maybe<Scalars['String']>;
  postcode: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  fullAddress: Scalars['String'];
  patientId: Scalars['String'];
};

export type PatientMeSubscriptionModel = {
  __typename?: 'PatientMeSubscriptionModel';
  status: MeStatus;
  patientMeId: Scalars['String'];
};

export type PatientModel = {
  __typename?: 'PatientModel';
  id: Scalars['String'];
  dateOfBirthday?: Maybe<Scalars['String']>;
  location?: Maybe<PatientLocationModel>;
  emergencyContact?: Maybe<EmergencyContactModel>;
  user: UserModel;
  userId: Scalars['String'];
  sessionRequests: Array<SessionRequestModel>;
  connectedTherapists: Array<TherapistPublicModel>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  defaultPaymentMethod?: Maybe<Scalars['String']>;
  /** ISO 8601 date time string */
  firstSessionDateUts?: Maybe<Scalars['DateTimeISO']>;
  attendedSessionsCount: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  pmiStatus?: Maybe<PatientPmiStatus>;
};

export type PatientPaymentMethodModel = {
  __typename?: 'PatientPaymentMethodModel';
  id: Scalars['String'];
  type: Scalars['String'];
  brand?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['String']>;
  expYear?: Maybe<Scalars['String']>;
};

export type PatientPaymentMethodsPayload = {
  __typename?: 'PatientPaymentMethodsPayload';
  paymentMethods: Array<PatientPaymentMethodModel>;
  defaultPaymentMethod?: Maybe<Scalars['String']>;
};

export type PatientPersonalInfoPayload = {
  __typename?: 'PatientPersonalInfoPayload';
  patient?: Maybe<PatientModel>;
  userErrors: Array<UserError>;
};

export enum PatientPmiStatus {
  Covered = 'COVERED',
  NotCovered = 'NOT_COVERED'
}

export type PatientRemoveAllNotesInput = {
  sessionMetaId?: Maybe<Scalars['String']>;
};

export type PatientRemoveInput = {
  patientId: Scalars['String'];
};

export type PatientSessionCancelAllInput = {
  reason: Scalars['String'];
  therapistId: Scalars['String'];
  /** ISO 8601 date time string. Start date from which to delete future sessions */
  startDateUtc?: Maybe<Scalars['DateTimeISO']>;
};

export type PaymentConnectionModel = {
  __typename?: 'PaymentConnectionModel';
  nodes: Array<PaymentModel>;
  pageInfo: PageInfo;
};

export type PaymentConnectionModelV2 = {
  __typename?: 'PaymentConnectionModelV2';
  nodes: Array<PaymentModelV2>;
  pageInfo: PageInfo;
};

export type PaymentFilter = {
  /** ISO 8601 date time string */
  startDateUTC: Scalars['DateTimeISO'];
  /** ISO 8601 date time string */
  endDateUTC: Scalars['DateTimeISO'];
  paymentStatusFilter?: Maybe<PaymentStatusFilter>;
};

export type PaymentModel = {
  __typename?: 'PaymentModel';
  id: Scalars['String'];
  therapistId: Scalars['String'];
  patientId: Scalars['String'];
  insuranceCompany?: Maybe<InsuranceCompanyModel>;
  paymentDate?: Maybe<Scalars['DateTimeISO']>;
  sessionDate: Scalars['DateTimeISO'];
  status: PaymentStatus;
  amount?: Maybe<Scalars['Int']>;
  patient: PatientConnectedModel;
  therapist: TherapistPreviewModel;
  clientType: TherapistClientTypeModel;
  sessionType: SessionType;
  /**  in minutes */
  duration: Scalars['Int'];
  currency: Scalars['String'];
  paymentCardBrand?: Maybe<Scalars['String']>;
  paymentCardLast4?: Maybe<Scalars['String']>;
  needManualPayment: Scalars['Boolean'];
  paymentType: PaymentType;
};

export type PaymentModelV2 = {
  id: Scalars['String'];
  sessionType: SessionType;
  sessionDate: Scalars['DateTimeISO'];
  duration: Scalars['Int'];
  therapistId: Scalars['String'];
  therapist: TherapistPreviewModel;
  patientId: Scalars['String'];
  patient: PatientConnectedModel;
  clientType: TherapistClientTypeModel;
  paymentType: PaymentType;
  status: PaymentStatus;
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  paymentDate?: Maybe<Scalars['DateTimeISO']>;
  needManualPayment?: Maybe<Scalars['Boolean']>;
};

export enum PaymentStatus {
  Canceled = 'CANCELED',
  Processing = 'PROCESSING',
  RequiresAction = 'REQUIRES_ACTION',
  RequiresCapture = 'REQUIRES_CAPTURE',
  RequiresConfirmation = 'REQUIRES_CONFIRMATION',
  RequiresPaymentMethod = 'REQUIRES_PAYMENT_METHOD',
  Succeeded = 'SUCCEEDED',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
  ContactAdmin = 'CONTACT_ADMIN'
}

export enum PaymentStatusFilter {
  All = 'ALL',
  Paid = 'PAID',
  Unpaid = 'UNPAID'
}

export enum PaymentType {
  Stripe = 'STRIPE',
  Insurance = 'INSURANCE',
  Enterprise = 'ENTERPRISE',
  Voucher = 'VOUCHER'
}

export type PlanAndSessionsInfoModel = {
  __typename?: 'PlanAndSessionsInfoModel';
  plan?: Maybe<CompanyPlanModel>;
  sessionsLeft?: Maybe<Scalars['Int']>;
};

export enum PlanDurationType {
  Quarterly = 'QUARTERLY',
  Annual = 'ANNUAL',
  Monthly = 'MONTHLY'
}

export type PostDetailsModel = {
  __typename?: 'PostDetailsModel';
  id: Scalars['String'];
  createdAt: Scalars['String'];
  title: Scalars['String'];
  subtitle: Scalars['String'];
  author?: Maybe<Scalars['String']>;
  imageUrl: Scalars['String'];
  videoUrl?: Maybe<Scalars['String']>;
  postText?: Maybe<Scalars['String']>;
  subCategoryId: Scalars['String'];
  subCategory?: Maybe<ResourcesSubcategoryDetailsModel>;
  type: ResourcesPostTypeEnum;
};

export type ProfessionalRegistrationModel = {
  __typename?: 'ProfessionalRegistrationModel';
  id: Scalars['String'];
  label: Scalars['String'];
  explanation?: Maybe<Scalars['String']>;
};

export type ProfessionalTitleModel = {
  __typename?: 'ProfessionalTitleModel';
  id: Scalars['String'];
  label: Scalars['String'];
  explanation?: Maybe<Scalars['String']>;
};

export type QualificationFileModel = {
  __typename?: 'QualificationFileModel';
  id: Scalars['String'];
  fileType: FileType;
  source: Scalars['String'];
  name: Scalars['String'];
  sizeInKb: Scalars['Float'];
  extension: FileExtension;
};

export type QualificationModel = {
  __typename?: 'QualificationModel';
  id: Scalars['String'];
  professionalTitle: ProfessionalTitleModel;
  academicInitials: Array<AcademicInitialModel>;
  dbsCertificate: QualificationFileModel;
  dbsCertificateId: Scalars['String'];
  indemnityCertificate: QualificationFileModel;
  indemnityCertificateId: Scalars['String'];
  coreTraining: CoreTrainingModel;
  hasThreeYearsExperience: Scalars['Boolean'];
  coreQualificationDate: Scalars['String'];
  coreTrainingFile: QualificationFileModel;
  coreTrainingFileId: Scalars['String'];
  additionalTrainings: Array<AdditionalTrainingModel>;
  registrations: Array<RegistrationModel>;
  therapistId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  me?: Maybe<UserModel>;
  checkEmailIsAvailable: StatusResponseModel;
  patientPaymentMethods: PatientPaymentMethodsPayload;
  validateB2BRegistration?: Maybe<Scalars['Boolean']>;
  planAndSessionsInfo: PlanAndSessionsInfoModel;
  company: CompanyModel;
  therapistTitles: Array<TherapistTitleModel>;
  therapistSearch: TherapistPublicConnection;
  therapistSearchMatched?: Maybe<Array<TherapistPublicModel>>;
  therapistPublicById: TherapistPublicModel;
  therapistPatientDashboardPreview: TherapistPatientDashboardPreviewModel;
  therapistPatients: Array<PatientConnectedModel>;
  chatMessages: MessageConnectionModel;
  listChats: ChatConnectionModel;
  therapistListUsersCanStartChat: Array<ChatUserModel>;
  adminListUsersCanStartChat: Array<ChatUserModel>;
  overallUnreadCount: Scalars['Int'];
  chatById: ChatModel;
  therapistSessions: Array<SessionModel>;
  patientSessions: Array<SessionModel>;
  patientHadSessionsOrRequests: Scalars['Boolean'];
  therapistUpcomingSessionsByPatientId: SessionPaginatePayload;
  therapistPastSessionsByPatientId: SessionPaginatePayload;
  patientUpcomingSessions: SessionWithRequestsPaginatePayload;
  patientPastSessions: SessionPaginatePayload;
  patientCanceledSessions: SessionPaginatePayload;
  therapistCanceledSessions: SessionPaginatePayload;
  therapistUpcomingSessions: SessionPaginatePayload;
  nearestSessions?: Maybe<Array<SessionModel>>;
  patientInsuranceCodesHistory: Array<PatientInsuranceAuthModel>;
  patientME?: Maybe<PatientInsuranceModel>;
  insuranceCompanies: Array<InsuranceCompanyModel>;
  getSessionPrices: Array<SessionPriceModel>;
  getSessionPrice: SessionPriceModel;
  therapistAvailability: TherapistAvailability;
  therapistRecurringAvailability: Array<AvailabilityIntervalModel>;
  workingScheduleDays: WorkingScheduleDaysConnection;
  workingScheduleDates: WorkingScheduleDatesConnection;
  therapistClientTypes: Array<TherapistClientTypeModel>;
  /** find or create session meta by exist sessions */
  sessionMeta: SessionMetaPayload;
  /** find session meta by id */
  sessionMetaById: SessionMetaModel;
  therapistSessionsNeededAction: Array<SessionMetaModel>;
  patientSessionsNeededFeedback: Array<SessionMetaModel>;
  patientAppNeededFeedback: Array<SessionMetaModel>;
  monthlyB2BSessionBreakdown: Array<SessionMetaBreakdownModel>;
  topB2BSessionThemes: Array<SessionMetaThemesModel>;
  recentSessionFeedback: Array<FeedbackReviewModel>;
  satisfactionAndProgress: SatisfactionReviewModel;
  patientGetOwnMaterials: MaterialConnectionModel;
  therapistGetPatientMaterials: MaterialConnectionModel;
  getMaterialIndicators: MaterialIndicatorsModel;
  treatmentAreas: Array<TreatmentAreaModel>;
  languages: Array<LanguageModel>;
  academicInitials: Array<AcademicInitialModel>;
  coreTrainings: Array<CoreTrainingModel>;
  professionalRegistrations: Array<ProfessionalRegistrationModel>;
  professionalTitles: Array<ProfessionalTitleModel>;
  patientGetNotes: NoteConnectionModel;
  therapistGetPatientNotes: NoteConnectionModel;
  therapistGetOutcomeMeasureCodes: Array<OutcomeMeasureCodeModel>;
  therapistGetPatientOutcomeMeasures: Array<OutcomeMeasureModel>;
  patientGetOutcomeMeasures: Array<OutcomeMeasureModel>;
  patientGetOutcomeMeasureQuestions: Array<OutcomeMeasureQuestionModel>;
  patientGetGoals: Array<GoalModel>;
  wellbeingTrackerQuestions: Array<WellbeingQuestionModel>;
  wellbeingTrackerResponses: Array<Array<WellbeingResponseModel>>;
  wellbeingTrackerUserResponses: Array<Array<WellbeingUserResponseModel>>;
  wellbeingTrackerUserRecommendedResources: Array<PostDetailsModel>;
  wellbeingTrackerEngagement: WellbeingEngagementModel;
  getCategoriesAndSubcategories: Array<ResourcesCategoryDetailsModel>;
  getPostsBySubCategory: Array<PostDetailsModel>;
  getPostsAndSubcategoriesByCategory: Array<ResourcesSubcategoryDetailsModel>;
  getPostsByType: Array<PostDetailsModel>;
  getAllPosts: Array<PostDetailsModel>;
  getLatestPosts: Array<PostDetailsModel>;
  getPostDetails: PostDetailsModel;
  therapistPayments: Array<PaymentModel>;
  therapistPaymentsV2: Array<PaymentModelV2>;
  patientPayments: PaymentConnectionModel;
  patientPaymentsV2: PaymentConnectionModelV2;
  therapistEarnings: YearEarningsPayload;
  therapistMonthEarnings: MonthEarningsPayload;
  getFailedPayment: Scalars['String'];
  therapyApproaches: Array<TherapyApproachModel>;
  adminTherapistsSearch: TherapistAdminConnection;
  therapistRequestsCount: Scalars['Int'];
  adminGetTherapistById: TherapistAdminModel;
  /** First value - upcoming sessions, Second value - Kate's upcoming sessions */
  futureUpcomingSessionCount: Array<Scalars['Int']>;
  /** First value - upcoming sessions, Second value - Kate's upcoming sessions */
  futureUpcomingSessionCountExtended: Array<Scalars['Int']>;
  adminPatientsSearch: PatientAdminConnection;
  adminClinicCredentials?: Maybe<ClinicCredentialsModel>;
  adminSetUpBankAccount: Scalars['String'];
  adminGetBankAccount: AdminCustomerModel;
  adminBillStatusHistory: BillStatusHistoryConnection;
  adminBillSubmitHistory: BillSubmitHistoryConnection;
  adminBillValidateHistory: BillValidateHistoryConnection;
  getSessionsNeedTransferForInsurance: Array<AdminInsuranceSessionModel>;
  getInsuranceSessions: Array<AdminInsuranceSessionModel>;
  getUnreadFailedLogsCount: LogsIndicatorsModel;
  adminCancelledRequests: CancelledRequestsConnection;
  adminCancelledSessions: CancelledSessionsConnection;
  adminCancellationsCount: CancelletionsCountModel;
  adminUsersFeedback: FeedbackRowsModel;
  adminFeedbackTotalInfo: FeedbackTotalInfo;
  adminFeedbackDescriptions: FeedbackDescriptions;
  adminCompaniesFeedback: FeedbackCompanyRowsModel;
  adminCompanyFeedbackDescriptions: FeedbackDescriptions;
  adminTherapistFeedback: FeedbackAdminConnection;
  adminGetCompany: CompanyModel;
  adminGetAllCompanies: Array<CompanyModel>;
  adminTotalBusinessInfo: TotalBusinessInfoModel;
  adminGetVouchers?: Maybe<AdminVoucherPagesModel>;
  therapistResources: TherapistResourceConnection;
};


export type QueryCheckEmailIsAvailableArgs = {
  email: Scalars['String'];
};


export type QueryValidateB2BRegistrationArgs = {
  email: Scalars['String'];
  companyKey: Scalars['String'];
};


export type QueryTherapistSearchArgs = {
  filter?: Maybe<TherapistSearchFilter>;
  after?: Maybe<Scalars['String']>;
  orderBy?: Maybe<TherapistSearchOrder>;
  first?: Maybe<Scalars['Int']>;
  timezone: Scalars['String'];
};


export type QueryTherapistSearchMatchedArgs = {
  timezone: Scalars['String'];
  filter?: Maybe<TherapistMatchFilter>;
};


export type QueryTherapistPublicByIdArgs = {
  byTherapist?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type QueryTherapistPatientDashboardPreviewArgs = {
  patientId: Scalars['String'];
};


export type QueryChatMessagesArgs = {
  searchPhrase?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  chatId: Scalars['String'];
};


export type QueryListChatsArgs = {
  limit?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryChatByIdArgs = {
  id: Scalars['String'];
};


export type QueryTherapistSessionsArgs = {
  sessionFilter: SessionFilter;
};


export type QueryPatientSessionsArgs = {
  sessionFilter: SessionFilter;
};


export type QueryTherapistUpcomingSessionsByPatientIdArgs = {
  filter?: Maybe<SessionPaginateFilterInput>;
  patientId: Scalars['String'];
};


export type QueryTherapistPastSessionsByPatientIdArgs = {
  filter?: Maybe<SessionPaginateFilterInput>;
  patientId: Scalars['String'];
};


export type QueryPatientUpcomingSessionsArgs = {
  filter?: Maybe<SessionPaginateFilterInput>;
};


export type QueryPatientPastSessionsArgs = {
  filter?: Maybe<SessionPaginateFilterInput>;
};


export type QueryPatientCanceledSessionsArgs = {
  filter?: Maybe<SessionPaginateFilterInput>;
};


export type QueryTherapistCanceledSessionsArgs = {
  filter?: Maybe<SessionPaginateFilterInput>;
  patientId: Scalars['String'];
};


export type QueryTherapistUpcomingSessionsArgs = {
  options: SessionOptionsInput;
  filter?: Maybe<SessionPaginateFilterInput>;
};


export type QueryGetSessionPriceArgs = {
  priceType: SessionPriceType;
};


export type QueryTherapistAvailabilityArgs = {
  availabilityFilter: AvailabilityFilter;
  therapistId: Scalars['String'];
};


export type QueryWorkingScheduleDaysArgs = {
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
};


export type QueryWorkingScheduleDatesArgs = {
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
};


export type QuerySessionMetaArgs = {
  input: SessionMetaInput;
};


export type QuerySessionMetaByIdArgs = {
  id: Scalars['String'];
};


export type QueryMonthlyB2BSessionBreakdownArgs = {
  until: Scalars['DateISO'];
  from: Scalars['DateISO'];
};


export type QueryPatientGetOwnMaterialsArgs = {
  after?: Maybe<Scalars['String']>;
  filter: MaterialsFilter;
};


export type QueryTherapistGetPatientMaterialsArgs = {
  after?: Maybe<Scalars['String']>;
  filter: MaterialsFilter;
};


export type QueryGetMaterialIndicatorsArgs = {
  filter: MaterialIndicatorsFilter;
};


export type QueryPatientGetNotesArgs = {
  after?: Maybe<Scalars['String']>;
  filter: NotesFilter;
};


export type QueryTherapistGetPatientNotesArgs = {
  after?: Maybe<Scalars['String']>;
  filter: NotesFilter;
};


export type QueryTherapistGetPatientOutcomeMeasuresArgs = {
  input: TherapistGetPatientOutcomeMeasureInput;
};


export type QueryPatientGetOutcomeMeasureQuestionsArgs = {
  codeId: Scalars['String'];
};


export type QueryPatientGetGoalsArgs = {
  filter?: Maybe<GoalFilter>;
};


export type QueryWellbeingTrackerResponsesArgs = {
  until: Scalars['DateISO'];
  from: Scalars['DateISO'];
  questionIds: Array<Scalars['String']>;
};


export type QueryWellbeingTrackerUserResponsesArgs = {
  until: Scalars['DateISO'];
  from: Scalars['DateISO'];
  questionIds: Array<Scalars['String']>;
};


export type QueryWellbeingTrackerUserRecommendedResourcesArgs = {
  limit: Scalars['Int'];
};


export type QueryGetPostsBySubCategoryArgs = {
  subCategoryIds: Array<Scalars['String']>;
};


export type QueryGetPostsAndSubcategoriesByCategoryArgs = {
  categoryId: Scalars['String'];
};


export type QueryGetPostsByTypeArgs = {
  type: ResourcesPostTypeEnum;
};


export type QueryGetPostDetailsArgs = {
  postId: Scalars['String'];
};


export type QueryTherapistPaymentsArgs = {
  paymentFilter: PaymentFilter;
};


export type QueryTherapistPaymentsV2Args = {
  paymentFilter: PaymentFilter;
};


export type QueryPatientPaymentsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type QueryPatientPaymentsV2Args = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type QueryTherapistEarningsArgs = {
  earningsFilter: EarningsFilter;
};


export type QueryGetFailedPaymentArgs = {
  sessionMetaId: Scalars['String'];
};


export type QueryAdminTherapistsSearchArgs = {
  sort?: Maybe<TherapistAdminSortFilter>;
  first?: Maybe<Scalars['Float']>;
  after?: Maybe<Scalars['String']>;
  filter?: Maybe<SearchTherapistFilter>;
};


export type QueryAdminGetTherapistByIdArgs = {
  id: Scalars['String'];
};


export type QueryAdminPatientsSearchArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filter?: Maybe<SearchPatientFilter>;
  sort?: Maybe<PatientAdminSort>;
  orderBy?: Maybe<PatientAdminOrder>;
};


export type QueryAdminBillStatusHistoryArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filter?: Maybe<BillStatusHistoryFilter>;
};


export type QueryAdminBillSubmitHistoryArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filter?: Maybe<BillValidateHistoryFilter>;
};


export type QueryAdminBillValidateHistoryArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filter?: Maybe<BillValidateHistoryFilter>;
};


export type QueryGetInsuranceSessionsArgs = {
  filter: InsuranceSessionFilter;
};


export type QueryAdminCancelledRequestsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  therapist?: Maybe<CancellationsFilter>;
};


export type QueryAdminCancelledSessionsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  therapist?: Maybe<CancellationsFilter>;
};


export type QueryAdminUsersFeedbackArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryAdminFeedbackTotalInfoArgs = {
  tab: ReviewsTab;
};


export type QueryAdminFeedbackDescriptionsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
};


export type QueryAdminCompaniesFeedbackArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryAdminCompanyFeedbackDescriptionsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  companyId: Scalars['String'];
};


export type QueryAdminTherapistFeedbackArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  therapistId: Scalars['String'];
};


export type QueryAdminGetCompanyArgs = {
  companyId: Scalars['String'];
};


export type QueryAdminGetVouchersArgs = {
  input: GetVouchersFilter;
};


export type QueryTherapistResourcesArgs = {
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
};

export type RegisterPatientPmiStatusInput = {
  pmiStatus: PatientPmiStatus;
};

export type RegistrationModel = {
  __typename?: 'RegistrationModel';
  id: Scalars['String'];
  professionalRegistration: ProfessionalRegistrationModel;
  type: Scalars['String'];
  expiryDate: Scalars['String'];
  registrationNumber: Scalars['String'];
  qualification: QualificationModel;
  qualificationId: Scalars['String'];
};

export type RelayPageInfo = {
  __typename?: 'RelayPageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
};

export enum RemittanceStatus {
  Paid = 'PAID',
  Unpaid = 'UNPAID'
}

export type ReportAbuseInput = {
  reason: Scalars['String'];
  postId: Scalars['String'];
};

export type ReportResult = {
  __typename?: 'ReportResult';
  filename: Scalars['String'];
  dataUrl: Scalars['String'];
};

export enum RequestVisitType {
  Ongoing = 'ONGOING',
  FirstSession = 'FIRST_SESSION',
  Reschedule = 'RESCHEDULE'
}

export type ResourcesCategoryDetailsModel = {
  __typename?: 'ResourcesCategoryDetailsModel';
  id: Scalars['String'];
  name: Scalars['String'];
  imageUrl: Scalars['String'];
  iconUrl: Scalars['String'];
  subCategories: Array<ResourcesSubcategoryDetailsModel>;
};

export enum ResourcesPostTypeEnum {
  Video = 'VIDEO',
  Text = 'TEXT'
}

export type ResourcesSubcategoryDetailsModel = {
  __typename?: 'ResourcesSubcategoryDetailsModel';
  id: Scalars['String'];
  name: Scalars['String'];
  imageUrl: Scalars['String'];
  posts?: Maybe<Array<PostDetailsModel>>;
};

export type ReviewAddInput = {
  sessionMetaId: Scalars['String'];
  description: Scalars['String'];
  rating: Scalars['Int'];
  progressRate: Scalars['Int'];
  satisfactionRate: Scalars['Int'];
};

export type ReviewCommonPayload = {
  __typename?: 'ReviewCommonPayload';
  success: Scalars['Boolean'];
  userErrors: Array<UserError>;
};

export enum ReviewsTab {
  All = 'ALL',
  B2B = 'B2B'
}

export enum Role {
  Therapist = 'THERAPIST',
  Patient = 'PATIENT',
  Admin = 'ADMIN',
  Manager = 'MANAGER'
}

export type SatisfactionReviewModel = {
  __typename?: 'SatisfactionReviewModel';
  satisfactionRate?: Maybe<Scalars['Float']>;
  progressRate?: Maybe<Scalars['Float']>;
};

export type ScheduleEntryInput = {
  /** ISO-8601 hh:mm:ss */
  from: Scalars['String'];
  /** ISO-8601 hh:mm:ss */
  until: Scalars['String'];
  types: Array<SessionType>;
  officeLocationId?: Maybe<Scalars['String']>;
};

export type SearchPatientFilter = {
  searchName?: Maybe<Scalars['String']>;
};

export type SearchTherapistFilter = {
  status?: Maybe<TherapistRequestStatus>;
  searchName?: Maybe<Scalars['String']>;
};

export type SessionAddInput = {
  /** ISO 8601 date time string */
  startDateUtc: Scalars['DateTimeISO'];
  /** ISO 8601 date time string */
  endDateUtc: Scalars['DateTimeISO'];
  timezone: Scalars['String'];
  sessionType: SessionType;
  /** true for recurring type session */
  isRecurring: Scalars['Boolean'];
  /** save value from startDateUtc (only recurring) */
  startRecurringDateUtc?: Maybe<Scalars['DateTimeISO']>;
  /** last day of the end of the session (only recurring) */
  endRecurringDateUtc?: Maybe<Scalars['DateTimeISO']>;
  /** we have only weekly type (only recurring) */
  recurringType?: Maybe<SessionRecurringType>;
  /** ids of therapist client types */
  clientType: Scalars['String'];
  isoWeekDays?: Maybe<Array<IsoWeekDay>>;
  patientId: Scalars['String'];
  officeLocationId?: Maybe<Scalars['String']>;
  paymentType: PaymentType;
  sessionPriceId: Scalars['UUID'];
};

export type SessionAddMultipleInput = {
  sessionDates: Array<SessionDate>;
  timezone: Scalars['String'];
  sessionType: SessionType;
  patientId: Scalars['String'];
  /** id of therapist client types */
  clientType: Scalars['String'];
  officeLocationId?: Maybe<Scalars['String']>;
  paymentType: PaymentType;
  sessionPriceId: Scalars['UUID'];
};

export type SessionCancelAllFuture = {
  /** session id */
  id: Scalars['String'];
  /** ISO 8601 date time string. That date will be update endFromDateIso of potential the canceling session */
  startFromDateIso: Scalars['DateTimeISO'];
  reason?: Maybe<Scalars['String']>;
};

export type SessionCancelInput = {
  /** session id */
  id: Scalars['String'];
  /** ISO 8601 date where started exception */
  startDateUtc?: Maybe<Scalars['DateTimeISO']>;
  reason: Scalars['String'];
};

export type SessionCommonPayload = {
  __typename?: 'SessionCommonPayload';
  success: Scalars['Boolean'];
  userErrors: Array<UserError>;
};

export type SessionDate = {
  /** ISO 8601 date time string */
  startDateUtc: Scalars['DateTimeISO'];
  /** ISO 8601 date time string */
  endDateUtc: Scalars['DateTimeISO'];
};

export type SessionFilter = {
  /** ISO 8601 date time string */
  startOfFromDateUTC: Scalars['DateTimeISO'];
  /** ISO 8601 date time string */
  endOfToDateUTC: Scalars['DateTimeISO'];
};

export type SessionMetaBreakdownModel = {
  __typename?: 'SessionMetaBreakdownModel';
  year: Scalars['Float'];
  month: Scalars['Float'];
  courses: Scalars['Float'];
  sessions: Scalars['Float'];
};

export type SessionMetaInput = {
  /** session id */
  id: Scalars['String'];
  /** Base64 encoded start date in UTC In ISO format */
  hash: Scalars['String'];
};

export type SessionMetaModel = {
  __typename?: 'SessionMetaModel';
  id: Scalars['String'];
  isRescheduled: Scalars['Boolean'];
  isCanceled: Scalars['Boolean'];
  sessionType: SessionType;
  /** ISO 8601 date time string */
  startDateUtc: Scalars['DateTimeISO'];
  /** ISO 8601 date time string */
  endDateUtc: Scalars['DateTimeISO'];
  timezone: Scalars['String'];
  clientType: TherapistClientTypeModel;
  clientTypeId: Scalars['String'];
  therapistPublic: TherapistPublicModel;
  therapistId: Scalars['String'];
  patientConnected: PatientConnectedModel;
  patientId: Scalars['String'];
  session: SessionModel;
  sessionId: Scalars['String'];
  officeLocation?: Maybe<OfficeLocationModel>;
  paid: Scalars['Boolean'];
  needManualPayment: Scalars['Boolean'];
  sessionOutcome?: Maybe<SessionOutcomeModel>;
  reviewSkipped?: Maybe<Scalars['Boolean']>;
  paymentType: PaymentType;
  therapistName?: Maybe<Scalars['String']>;
};

export type SessionMetaPayload = {
  __typename?: 'SessionMetaPayload';
  sessionMeta?: Maybe<SessionMetaModel>;
  userErrors: Array<UserError>;
};

export type SessionMetaThemesModel = {
  __typename?: 'SessionMetaThemesModel';
  label: Scalars['String'];
  percentage: Scalars['Float'];
};

export type SessionModel = {
  __typename?: 'SessionModel';
  id: Scalars['String'];
  isRescheduled: Scalars['Boolean'];
  isCanceled: Scalars['Boolean'];
  /** ISO 8601 date time string */
  startDateUtc: Scalars['DateTimeISO'];
  /** ISO 8601 date time string */
  endDateUtc: Scalars['DateTimeISO'];
  timezone: Scalars['String'];
  isRecurring: Scalars['Boolean'];
  startRecurringDateUtc?: Maybe<Scalars['DateTimeISO']>;
  endRecurringDateUtc?: Maybe<Scalars['DateTimeISO']>;
  recurringType?: Maybe<SessionRecurringType>;
  clientType: TherapistClientTypeModel;
  clientTypeId: Scalars['String'];
  isoWeekDays?: Maybe<Array<IsoWeekDay>>;
  sessionType: SessionType;
  therapistPublic: TherapistPublicModel;
  therapistId: Scalars['String'];
  patientConnected: PatientConnectedModel;
  patientId: Scalars['String'];
  paid: Scalars['Boolean'];
  officeLocation?: Maybe<OfficeLocationModel>;
  sessionOutcome?: Maybe<SessionOutcomeModel>;
  paymentType: PaymentType;
  sessionPrice?: Maybe<SessionPrice>;
  sessionPriceId: Scalars['String'];
};

export type SessionOptionsInput = {
  /** timezone ex. Asia/Omsk */
  timezone: Scalars['String'];
};

export type SessionOutcomeInput = {
  patientAttended: Scalars['Boolean'];
  patientWantContinue: Scalars['Boolean'];
  reason?: Maybe<Scalars['String']>;
  /** Session meta id */
  sessionMetaId: Scalars['String'];
};

export type SessionOutcomeModel = {
  __typename?: 'SessionOutcomeModel';
  id: Scalars['String'];
  patientAttended: Scalars['Boolean'];
  patientWantContinue: Scalars['Boolean'];
  reason?: Maybe<Scalars['String']>;
  /** Session meta id */
  sessionMetaId: Scalars['String'];
};

export type SessionOutcomePayload = {
  __typename?: 'SessionOutcomePayload';
  sessionOutcome?: Maybe<SessionOutcomeModel>;
  userErrors: Array<UserError>;
};

export type SessionPaginateFilterInput = {
  /** less than or equal 20 */
  first?: Maybe<Scalars['Int']>;
  /** cursor */
  after?: Maybe<Scalars['String']>;
};

export type SessionPaginatePayload = {
  __typename?: 'SessionPaginatePayload';
  nodes: Array<SessionModel>;
  pageInfo: PageInfo;
};

export type SessionPrice = {
  __typename?: 'SessionPrice';
  id: Scalars['String'];
  priceType: SessionPriceType;
  thirtyMin: Scalars['Float'];
  fiftyMin: Scalars['Float'];
  eightyMin: Scalars['Float'];
};

export type SessionPriceAdminPayload = {
  __typename?: 'SessionPriceAdminPayload';
  sessionPrices?: Maybe<SessionPriceModel>;
  userErrors: Array<UserError>;
};

export type SessionPriceInput = {
  id: Scalars['String'];
  priceType: SessionPriceType;
  thirtyMin: Scalars['Float'];
  fiftyMin: Scalars['Float'];
  eightyMin: Scalars['Float'];
};

export type SessionPriceModel = {
  __typename?: 'SessionPriceModel';
  id: Scalars['String'];
  priceType: SessionPriceType;
  thirtyMin: Scalars['Float'];
  fiftyMin: Scalars['Float'];
  eightyMin: Scalars['Float'];
};

export enum SessionPriceType {
  B2B = 'B2B',
  B2BSelfPay = 'B2B_SELF_PAY',
  B2CSelfPay = 'B2C_SELF_PAY',
  B2CInsurance = 'B2C_INSURANCE'
}

export enum SessionRecurringType {
  Weekly = 'WEEKLY'
}

export type SessionRequestAcceptInput = {
  /** Session request id */
  id: Scalars['String'];
};

export type SessionRequestAddInput = {
  therapistId: Scalars['String'];
  sessionType: SessionType;
  sessionPriceId: Scalars['UUID'];
  timezone: Scalars['String'];
  /** ISO 8601 date time string */
  startDateUtc: Scalars['DateTimeISO'];
  /** ISO 8601 date time string */
  endDateUtc: Scalars['DateTimeISO'];
  /** id of therapist client types */
  clientType: Scalars['String'];
  officeLocationId?: Maybe<Scalars['String']>;
  paymentType: PaymentType;
  voucher?: Maybe<Scalars['String']>;
};

export type SessionRequestCommonPayload = {
  __typename?: 'SessionRequestCommonPayload';
  sessionRequest?: Maybe<SessionRequestModel>;
  userErrors: Array<UserError>;
  /** True if patient completed guided match */
  aoSubmitted?: Maybe<Scalars['Boolean']>;
};

export type SessionRequestDeclineInput = {
  /** Session request id */
  id: Scalars['String'];
  reasonForPatient: Scalars['String'];
  reasonForAdmin: Scalars['String'];
};

export type SessionRequestModel = {
  __typename?: 'SessionRequestModel';
  id: Scalars['String'];
  status: SessionRequestStatus;
  requestType: SessionRequestType;
  sessionType: SessionType;
  /** ISO 8601 date time string */
  startDateUtc: Scalars['DateTimeISO'];
  /** ISO 8601 date time string */
  endDateUtc: Scalars['DateTimeISO'];
  startOfPrevIntervalUtc?: Maybe<Scalars['DateTimeISO']>;
  clientType: TherapistClientTypeModel;
  clientTypeId: Scalars['String'];
  session?: Maybe<SessionModel>;
  sessionId?: Maybe<Scalars['String']>;
  therapistPublic: TherapistPublicModel;
  therapistId: Scalars['String'];
  patientPotential: PatientConnectedModel;
  patientId: Scalars['String'];
  assessmentOutcomeId?: Maybe<Scalars['String']>;
  requestVisitType: RequestVisitType;
  officeLocation?: Maybe<OfficeLocationModel>;
  paymentType: PaymentType;
  sessionPrice?: Maybe<SessionPrice>;
  sessionPriceId: Scalars['String'];
};

export type SessionRequestRescheduleInput = {
  /** session id */
  id: Scalars['String'];
  /** ISO 8601 date where started exception. Only for recurring session */
  startOfPrevIntervalUtc?: Maybe<Scalars['DateTimeISO']>;
  /** ISO 8601 date time string */
  startDateUtc: Scalars['DateTimeISO'];
};

export enum SessionRequestStatus {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Created = 'CREATED'
}

export enum SessionRequestType {
  Book = 'BOOK',
  Reschedule = 'RESCHEDULE'
}

export type SessionRescheduleAllFutureInput = {
  /** session id */
  id: Scalars['String'];
  /** ISO 8601 date string. That date will be endReschedule date for old session */
  endOfPrevIntervalDate: Scalars['DateISO'];
  /** ISO 8601 date time string. That date will be endReschedule date for old session */
  startDateUtc: Scalars['DateTimeISO'];
  /** ISO 8601 date time string */
  endDateUtc: Scalars['DateTimeISO'];
  timezone: Scalars['String'];
  sessionType: SessionType;
  startRecurringDateUtc: Scalars['DateTimeISO'];
  endRecurringDateUtc?: Maybe<Scalars['DateTimeISO']>;
  recurringType?: Maybe<SessionRecurringType>;
  /** id of therapist client types */
  clientType?: Maybe<Scalars['String']>;
  isoWeekDays?: Maybe<Array<IsoWeekDay>>;
  officeLocationId?: Maybe<Scalars['String']>;
};

export type SessionRescheduleInput = {
  /** session id */
  id: Scalars['String'];
  /** ISO 8601 date time string */
  startDateUtc: Scalars['DateTimeISO'];
  /** ISO 8601 date time string */
  endDateUtc: Scalars['DateTimeISO'];
  timezone: Scalars['String'];
  sessionType: SessionType;
  /** ISO 8601 date where started exception. For reschedule one day in recurring session type */
  startOfPrevIntervalUtc?: Maybe<Scalars['DateTimeISO']>;
  /** id of therapist client types */
  clientType?: Maybe<Scalars['String']>;
  officeLocationId?: Maybe<Scalars['String']>;
};

export enum SessionType {
  InPerson = 'IN_PERSON',
  VideoCall = 'VIDEO_CALL',
  LiveChat = 'LIVE_CHAT'
}

export type SessionWithRequests = SessionModel | SessionRequestModel;

export type SessionWithRequestsPaginatePayload = {
  __typename?: 'SessionWithRequestsPaginatePayload';
  nodes: Array<SessionWithRequests>;
  pageInfo: PageInfo;
};

export type SlackConfigurationModel = {
  __typename?: 'SlackConfigurationModel';
  isRequired: Scalars['Boolean'];
  isRegistered: Scalars['Boolean'];
  isInstalled: Scalars['Boolean'];
  isConfigured: Scalars['Boolean'];
  installUrl: Scalars['String'];
};

export type SlackManagerModel = {
  __typename?: 'SlackManagerModel';
  name: Scalars['String'];
  email: Scalars['String'];
  slackUserId?: Maybe<Scalars['String']>;
};

export type StatusResponseModel = {
  __typename?: 'StatusResponseModel';
  status: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type StepInput = {
  title: Scalars['String'];
  completed: Scalars['Boolean'];
};

export type StepModel = {
  __typename?: 'StepModel';
  title: Scalars['String'];
  completed: Scalars['Boolean'];
};

export type StripePaymentModel = PaymentModelV2 & {
  __typename?: 'StripePaymentModel';
  id: Scalars['String'];
  sessionType: SessionType;
  sessionDate: Scalars['DateTimeISO'];
  duration: Scalars['Int'];
  therapistId: Scalars['String'];
  therapist: TherapistPreviewModel;
  patientId: Scalars['String'];
  patient: PatientConnectedModel;
  clientType: TherapistClientTypeModel;
  paymentType: PaymentType;
  status: PaymentStatus;
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  paymentDate?: Maybe<Scalars['DateTimeISO']>;
  needManualPayment?: Maybe<Scalars['Boolean']>;
  paymentCardBrand?: Maybe<Scalars['String']>;
  paymentCardLast4?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  chatAdded: ChatModel;
  messageAdded: MessageModel;
  overallUnreadCountChanged: Scalars['Int'];
  chatChanged: ChatModel;
  messagesRemoved: MessagesRemoveModel;
  meResponseReceived: PatientMeSubscriptionModel;
  materialIndicatorsChanged: MaterialIndicatorsModel;
  materialAddedOrRemoved: MaterialChangeModel;
  outcomeMeasureSent: OutcomeMeasureModel;
  therapistRequestAdded: Scalars['Int'];
};

export type TherapistAddApproachInput = {
  treatmentAreas: Array<Scalars['String']>;
  therapyApproaches: Array<Scalars['String']>;
  insuranceCompanies: Array<Scalars['String']>;
  therapistClientTypes: Array<Scalars['String']>;
};

export type TherapistAddCommunityInfoInput = {
  writer: Scalars['Boolean'];
  observer: Scalars['Boolean'];
};

export type TherapistAddNoteInput = {
  text: Scalars['String'];
  date: Scalars['DateISO'];
  sessionMetaId?: Maybe<Scalars['String']>;
  createdForPatientId: Scalars['String'];
};

export type TherapistAddOfficeLocationInput = {
  fullAddress: Scalars['String'];
  country: Scalars['String'];
  city: Scalars['String'];
  town?: Maybe<Scalars['String']>;
  postcode: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  hideFullAddress: Scalars['Boolean'];
};

export type TherapistAddPersonalInfoInput = {
  /** uuid from therapist_titles table */
  title: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  gender: Gender;
  ethnicity?: Maybe<Ethnicity>;
  phoneNumber: Scalars['String'];
  country: Scalars['String'];
  city: Scalars['String'];
  postcode: Scalars['String'];
  correspondenceAddress: Scalars['String'];
  timezone?: Maybe<Scalars['String']>;
};

export type TherapistAddProfileInput = {
  bio: Scalars['String'];
  avatar: AddAvatarFileInput;
  languages: Array<Scalars['String']>;
  sessionTypes: Array<SessionType>;
  hardWorker: Scalars['Boolean'];
};

export type TherapistAddQualificationInput = {
  /** uuid from professional_titles table */
  professionalTitle: Scalars['String'];
  /** uuid from academic_initials table */
  academicInitials: Array<Scalars['String']>;
  dbsCertificate: AddQualificationFileInput;
  indemnityCertificate: AddQualificationFileInput;
  /** uuid from core_trainings table */
  coreTraining: Scalars['String'];
  /** format YYYY-MM-DD */
  coreQualificationDate: Scalars['String'];
  coreTrainingFile: AddQualificationFileInput;
  additionalTrainings: Array<AddAdditionalTrainingInput>;
  registrations: Array<AddRegistrationInput>;
  hasThreeYearsExperience: Scalars['Boolean'];
};

export type TherapistAdminConnection = {
  __typename?: 'TherapistAdminConnection';
  nodes: Array<TherapistAdminModel>;
  pageInfo: PageInfo;
};

export type TherapistAdminModel = {
  __typename?: 'TherapistAdminModel';
  id: Scalars['String'];
  title?: Maybe<TherapistTitleModel>;
  bio?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sessionTypes: Array<SessionType>;
  languages: Array<LanguageModel>;
  hardWorker: Scalars['Boolean'];
  writer: Scalars['Boolean'];
  observer: Scalars['Boolean'];
  officeLocations: Array<OfficeLocationModel>;
  location?: Maybe<TherapistLocationModel>;
  qualification: QualificationModel;
  user: UserModel;
  userId: Scalars['String'];
  requestStatus: TherapistRequestStatus;
  qualificationFiles?: Maybe<Array<QualificationFileModel>>;
  treatmentAreas: Array<TherapistTreatmentAreaModel>;
  therapyApproaches: Array<TherapistTherapyApproachModel>;
  therapistClientTypes: Array<TherapistClientTypeModel>;
  insuranceCompanies: Array<InsuranceCompanyModel>;
  coreTrainingFile: QualificationFileModel;
  dbsCertificateFile: QualificationFileModel;
  indemnityCertificateFile: QualificationFileModel;
  professionalRegistrations: Array<Scalars['String']>;
  initialSessionCommissionPercent: Scalars['Float'];
  ongoingSessionCommissionPercent: Scalars['Float'];
  deletedFromSearch: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  insuranceCredentials?: Maybe<TherapistCredentialsModel>;
  upcomingCount: Scalars['Int'];
  avgRating: Scalars['Float'];
  rejectionsCount: Scalars['Int'];
  initialSessionsCount: Scalars['Int'];
  overallSessionsCount: Scalars['Int'];
  took24hToReactLastWeek: Scalars['Boolean'];
  sumOfMonthAvailabilityHours: Scalars['Float'];
  upcomingSessions: Scalars['Int'];
  overdueSessions: Scalars['Int'];
};

export enum TherapistAdminSort {
  Rating = 'RATING',
  InitialSessionsCount = 'INITIAL_SESSIONS_COUNT',
  RejectionsCount = 'REJECTIONS_COUNT',
  PastSessionsCount = 'PAST_SESSIONS_COUNT',
  SumOfMonthAvailabilityHours = 'SUM_OF_MONTH_AVAILABILITY_HOURS',
  UpdatedAt = 'UPDATED_AT',
  RequestAcceptance = 'REQUEST_ACCEPTANCE',
  UpcomingSessions = 'UPCOMING_SESSIONS',
  OverdueSessions = 'OVERDUE_SESSIONS'
}

export type TherapistAdminSortFilter = {
  sortOption?: Maybe<TherapistAdminSort>;
  direction?: Maybe<OrderByDirection>;
};

export type TherapistApproachPayload = {
  __typename?: 'TherapistApproachPayload';
  therapist?: Maybe<TherapistModel>;
  userErrors: Array<UserError>;
};

export type TherapistAvailability = {
  __typename?: 'TherapistAvailability';
  availabilityIntervals: Array<AvailabilityIntervalModel>;
};

export type TherapistChangeCommissionInput = {
  therapistId: Scalars['String'];
  initialCommissionPercent: Scalars['Float'];
  ongoingCommissionPercent: Scalars['Float'];
};

export type TherapistChangeRequestStatusInput = {
  therapistId: Scalars['String'];
};

export type TherapistClientTypeModel = {
  __typename?: 'TherapistClientTypeModel';
  id: Scalars['String'];
  label: Scalars['String'];
  explanation?: Maybe<Scalars['String']>;
};

export type TherapistCommunityInfoPayload = {
  __typename?: 'TherapistCommunityInfoPayload';
  therapist?: Maybe<TherapistModel>;
  userErrors: Array<UserError>;
};

export type TherapistCredentialsInput = {
  siteId: Scalars['String'];
  username: Scalars['String'];
  password: Scalars['String'];
  therapistId: Scalars['String'];
  insuranceLogin?: Maybe<Scalars['String']>;
};

export type TherapistCredentialsModel = {
  __typename?: 'TherapistCredentialsModel';
  id: Scalars['String'];
  siteId: Scalars['String'];
  username: Scalars['String'];
  password: Scalars['String'];
  therapistId: Scalars['String'];
  insuranceLogin?: Maybe<Scalars['String']>;
};

export type TherapistCredentialsPayload = {
  __typename?: 'TherapistCredentialsPayload';
  creds?: Maybe<TherapistCredentialsModel>;
  userErrors: Array<UserError>;
};

export type TherapistEditApproachInput = {
  /** uuid array */
  treatmentAreas: Array<Scalars['String']>;
  /** uuid array */
  therapyApproaches: Array<Scalars['String']>;
  /** uuid array */
  addInsuranceCompanies: Array<Scalars['String']>;
  /** uuid array */
  removeInsuranceCompanies: Array<Scalars['String']>;
  /** uuid array */
  addTherapistClientTypes: Array<Scalars['String']>;
  /** uuid array */
  removeTherapistClientTypes: Array<Scalars['String']>;
};

export type TherapistEditNoteInput = {
  id: Scalars['String'];
  text: Scalars['String'];
  date: Scalars['DateISO'];
};

export type TherapistEditOfficeLocationInput = {
  id: Scalars['String'];
  fullAddress: Scalars['String'];
  country: Scalars['String'];
  city: Scalars['String'];
  town?: Maybe<Scalars['String']>;
  postcode: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  hideFullAddress: Scalars['Boolean'];
};

export type TherapistEditPersonalInfoInput = {
  /** uuid from therapist_titles table */
  title: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  gender: Gender;
  ethnicity?: Maybe<Ethnicity>;
  phoneNumber: Scalars['String'];
  country: Scalars['String'];
  city: Scalars['String'];
  postcode: Scalars['String'];
  correspondenceAddress: Scalars['String'];
  timezone?: Maybe<Scalars['String']>;
};

export type TherapistEditProfileInput = {
  bio: Scalars['String'];
  avatar: AddAvatarFileInput;
  video?: Maybe<AddVideoFileInput>;
  addLanguages: Array<Scalars['String']>;
  removeLanguages: Array<Scalars['String']>;
  sessionTypes: Array<SessionType>;
  officeLocations: Array<TherapistEditOfficeLocationInput>;
  addOfficeLocations: Array<TherapistAddOfficeLocationInput>;
  /** uuid array */
  removeOfficeLocations: Array<Scalars['String']>;
  hardWorker?: Maybe<Scalars['Boolean']>;
};

export type TherapistEditQualificationInput = {
  /** uuid from professional_titles table */
  professionalTitle: Scalars['String'];
  /** uuid from academic_initials table */
  addAcademicInitials: Array<Scalars['String']>;
  /** uuid from academic_initials table */
  removeAcademicInitials: Array<Scalars['String']>;
  addDbsCertificate?: Maybe<AddQualificationFileInput>;
  addIndemnityCertificate?: Maybe<AddQualificationFileInput>;
  /** uuid from core_trainings table */
  coreTraining: Scalars['String'];
  /** format YYYY-MM-DD */
  coreQualificationDate: Scalars['String'];
  addCoreTrainingFile?: Maybe<AddQualificationFileInput>;
  additionalTrainings: Array<EditAdditionalTrainingInput>;
  addAdditionalTrainings: Array<AddAdditionalTrainingInput>;
  /** uuid array */
  removeAdditionalTrainings: Array<Scalars['String']>;
  registrations: Array<EditRegistrationInput>;
  addRegistrations: Array<AddRegistrationInput>;
  /** uuid array */
  removeRegistrations: Array<Scalars['String']>;
  hasThreeYearsExperience: Scalars['Boolean'];
};

export type TherapistEditSettingInput = {
  minNoticePeriod?: Maybe<Scalars['Int']>;
  minNoticePeriodType?: Maybe<TimePeriodType>;
  maxNoticePeriod?: Maybe<Scalars['Int']>;
  maxNoticePeriodType?: Maybe<TimePeriodType>;
  directBooking: Scalars['Boolean'];
};

export type TherapistGetPatientOutcomeMeasureInput = {
  patientId: Scalars['String'];
};

export type TherapistLocationModel = {
  __typename?: 'TherapistLocationModel';
  id: Scalars['String'];
  country: Scalars['String'];
  city: Scalars['String'];
  town?: Maybe<Scalars['String']>;
  postcode: Scalars['String'];
  correspondenceAddress: Scalars['String'];
  therapistId: Scalars['String'];
};

export type TherapistMatchFilter = {
  /** therapists gender */
  gender?: Maybe<Array<Maybe<Gender>>>;
  /** therapists ethnicity */
  ethnicity?: Maybe<Array<Maybe<Ethnicity>>>;
  /** therapists language */
  language?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** ids of treatment areas */
  treatmentAreas?: Maybe<Array<Maybe<Scalars['String']>>>;
  sessionTypes?: Maybe<Array<Maybe<SessionType>>>;
  location?: Maybe<LocationInput>;
  bestTimes?: Maybe<Array<Maybe<BestTime>>>;
  daysOfWeek?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** ids of professional titles */
  professionalTitles?: Maybe<Array<Maybe<Scalars['String']>>>;
  howSeriously?: Maybe<Scalars['String']>;
  therapyGoal: TherapyGoal;
  /** ids of therapist client types */
  clientTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** ids of insurance companies */
  insuranceCompanies?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TherapistModel = {
  __typename?: 'TherapistModel';
  id: Scalars['String'];
  title?: Maybe<TherapistTitleModel>;
  bio?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  ethnicity?: Maybe<Ethnicity>;
  sessionTypes: Array<SessionType>;
  languages: Array<LanguageModel>;
  hardWorker: Scalars['Boolean'];
  writer: Scalars['Boolean'];
  observer: Scalars['Boolean'];
  officeLocations: Array<OfficeLocationModel>;
  location?: Maybe<TherapistLocationModel>;
  qualification?: Maybe<QualificationModel>;
  user: UserModel;
  userId: Scalars['String'];
  sessions: Array<SessionModel>;
  treatmentAreas: Array<TherapistTreatmentAreaModel>;
  therapyApproaches: Array<TherapistTherapyApproachModel>;
  insuranceCompanies: Array<InsuranceCompanyModel>;
  therapistClientTypes: Array<TherapistClientTypeModel>;
  connectedPatients: Array<PatientConnectedModel>;
  sessionRequests: Array<SessionRequestModel>;
  setting: TherapistSettingModel;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountSubmitted: Scalars['Boolean'];
  stripePayoutsEnabled: Scalars['Boolean'];
  onboarded: Scalars['Boolean'];
  onboardedStep: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  requestStatus: TherapistRequestStatus;
};

export type TherapistPatientDashboardPreviewModel = {
  __typename?: 'TherapistPatientDashboardPreviewModel';
  /** patient id */
  id: Scalars['String'];
  clientTypes: Array<Scalars['String']>;
  attendedSessionsCount: Scalars['Int'];
  visitType: VisitType;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  age?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  generalPractitioner: GeneralPractitionerViewModel;
  emergencyContacts: Array<EmergencyContactViewModel>;
  assessmentOutcomeId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  authCode?: Maybe<Scalars['String']>;
  authorizedSessionsCount?: Maybe<Scalars['Int']>;
  policyNumber?: Maybe<Scalars['String']>;
  insuranceCompany?: Maybe<InsuranceCompanyModel>;
  unpaidSessionsCount: Scalars['Int'];
  invoiceRequiredSessionsCount: Scalars['Int'];
  /** Company UUID */
  companyId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  company?: Maybe<CompanyModel>;
  leftSessionsCount?: Maybe<Scalars['Int']>;
};

export type TherapistPersonalInfoPayload = {
  __typename?: 'TherapistPersonalInfoPayload';
  therapist?: Maybe<TherapistModel>;
  userErrors: Array<UserError>;
};

export type TherapistPreviewModel = {
  __typename?: 'TherapistPreviewModel';
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  avatar?: Maybe<UserPublicFileModel>;
  userId: Scalars['String'];
};

export type TherapistProfileInfoPayload = {
  __typename?: 'TherapistProfileInfoPayload';
  therapist?: Maybe<TherapistModel>;
  userErrors: Array<UserError>;
};

export type TherapistPublicConnection = {
  __typename?: 'TherapistPublicConnection';
  nodes: Array<TherapistPublicModel>;
  pageInfo: PageInfo;
};

export type TherapistPublicModel = {
  __typename?: 'TherapistPublicModel';
  id: Scalars['String'];
  title: Scalars['String'];
  fullName: Scalars['String'];
  professionalTitle: Scalars['String'];
  coreTraining: CoreTrainingModel;
  bio: Scalars['String'];
  gender?: Maybe<Gender>;
  ethnicity?: Maybe<Ethnicity>;
  therapiesOffered: Array<TherapistTherapyApproachModel>;
  topSpecialities: Array<TherapistTreatmentAreaModel>;
  distance?: Maybe<Scalars['String']>;
  sessionTypes: Array<SessionType>;
  avatarUrl: Scalars['String'];
  therapistClientTypes: Array<Scalars['String']>;
  languages: Array<LanguageModel>;
  insuranceCompanies: Array<InsuranceCompanyModel>;
  officeLocations: Array<OfficeLocationModel>;
  additionalTrainings: Array<Scalars['String']>;
  professionalRegistrations: Array<Scalars['String']>;
  nearestAvailabilities: Array<AvailabilityIntervalModel>;
  setting: TherapistSettingModel;
  video?: Maybe<UserPublicFileModel>;
};

export type TherapistQualificationPayload = {
  __typename?: 'TherapistQualificationPayload';
  qualification?: Maybe<QualificationModel>;
  userErrors: Array<UserError>;
};

export type TherapistRemoveAllNotesInput = {
  patientId: Scalars['String'];
  sessionMetaId?: Maybe<Scalars['String']>;
};

export type TherapistRemoveInput = {
  therapistId: Scalars['String'];
};

export enum TherapistRequestStatus {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Created = 'CREATED'
}

export type TherapistResourceConnection = {
  __typename?: 'TherapistResourceConnection';
  edges: Array<TherapistResourceEdge>;
  pageInfo: RelayPageInfo;
};

export type TherapistResourceEdge = {
  __typename?: 'TherapistResourceEdge';
  cursor: Scalars['String'];
  node: TherapistResourceNode;
};

export type TherapistResourceModel = {
  __typename?: 'TherapistResourceModel';
  id: Scalars['ID'];
  url: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  contentType: Scalars['String'];
  createdAt: Scalars['DateTimeISO'];
  updatedAt: Scalars['DateTimeISO'];
};

export type TherapistResourceNode = {
  __typename?: 'TherapistResourceNode';
  resource: TherapistResourceModel;
};

export type TherapistSearchFilter = {
  /** therapists gender */
  gender?: Maybe<Array<Maybe<Gender>>>;
  /** therapists ethnicity */
  ethnicity?: Maybe<Array<Maybe<Ethnicity>>>;
  /** therapists language */
  language?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** ids of treatment areas */
  treatmentAreas?: Maybe<Array<Maybe<Scalars['String']>>>;
  sessionTypes?: Maybe<Array<Maybe<SessionType>>>;
  location?: Maybe<LocationInput>;
  availabilityDates?: Maybe<AvailabilityDatesInput>;
  bestTimes?: Maybe<Array<Maybe<BestTime>>>;
  /** ids of professional titles */
  professionalTitles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** ids of therapy approaches */
  therapyApproaches?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** ids of therapist client types */
  clientTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** ids of insurance companies */
  insuranceCompanies?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TherapistSearchOrder = {
  sort?: Maybe<TherapistSort>;
  direction?: Maybe<OrderByDirection>;
};

export type TherapistSettingModel = {
  __typename?: 'TherapistSettingModel';
  id: Scalars['String'];
  minNoticePeriod?: Maybe<Scalars['Int']>;
  minNoticePeriodType?: Maybe<TimePeriodType>;
  maxNoticePeriod?: Maybe<Scalars['Int']>;
  maxNoticePeriodType?: Maybe<TimePeriodType>;
  directBooking: Scalars['Boolean'];
  therapistId?: Maybe<Scalars['String']>;
};

export type TherapistSettingPayload = {
  __typename?: 'TherapistSettingPayload';
  therapistSetting?: Maybe<TherapistSettingModel>;
  userErrors: Array<UserError>;
};

export enum TherapistSort {
  Recommended = 'RECOMMENDED',
  Alphabet = 'ALPHABET',
  Distance = 'DISTANCE'
}

export type TherapistTherapyApproachModel = {
  __typename?: 'TherapistTherapyApproachModel';
  id: Scalars['String'];
  label: Scalars['String'];
  explanation?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
};

export type TherapistTitleModel = {
  __typename?: 'TherapistTitleModel';
  id: Scalars['String'];
  label: Scalars['String'];
  explanation?: Maybe<Scalars['String']>;
};

export type TherapistTreatmentAreaModel = {
  __typename?: 'TherapistTreatmentAreaModel';
  id: Scalars['String'];
  label: Scalars['String'];
  relatedId: Scalars['String'];
  explanation?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
};

export type TherapyApproachModel = {
  __typename?: 'TherapyApproachModel';
  id: Scalars['String'];
  label: Scalars['String'];
  explanation?: Maybe<Scalars['String']>;
};

export enum TherapyGoal {
  Difficulties = 'DIFFICULTIES',
  Emotions = 'EMOTIONS',
  BreakPatterns = 'BREAK_PATTERNS',
  FeelBetter = 'FEEL_BETTER',
  KnowMyself = 'KNOW_MYSELF',
  QualityLife = 'QUALITY_LIFE',
  Relationships = 'RELATIONSHIPS',
  Past = 'PAST',
  Traumatic = 'TRAUMATIC',
  NotSure = 'NOT_SURE'
}

export enum TimePeriodType {
  Hours = 'HOURS',
  Days = 'DAYS',
  Weeks = 'WEEKS'
}

export type TotalBusinessInfoModel = {
  __typename?: 'TotalBusinessInfoModel';
  registeredPatients: Scalars['Int'];
  activePatients: Scalars['Int'];
  totalSessionsAmount: Scalars['Int'];
  avgSessionsPerPatient: Scalars['Float'];
  upcomingSessions: Scalars['Int'];
};

export type TreatmentAreaModel = {
  __typename?: 'TreatmentAreaModel';
  id: Scalars['String'];
  label: Scalars['String'];
  relatedId: Scalars['String'];
  explanation?: Maybe<Scalars['String']>;
};


export type UpdateWorkingScheduleDateInput = {
  /** ISO-8601 yyyy-mm-dd */
  date: Scalars['String'];
  schedule: Array<ScheduleEntryInput>;
};

export type UpdateWorkingScheduleDatePayload = {
  __typename?: 'UpdateWorkingScheduleDatePayload';
  success: Scalars['Boolean'];
  userErrors: Array<UserError>;
};

export type UpdateWorkingScheduleDayInput = {
  /** ISO-8601 Monday - Sunday as 1 - 7. */
  day: Scalars['Int'];
  schedule: Array<ScheduleEntryInput>;
};

export type UpdateWorkingScheduleDayPayload = {
  __typename?: 'UpdateWorkingScheduleDayPayload';
  success: Scalars['Boolean'];
  userErrors: Array<UserError>;
};

export type UserError = {
  field: Scalars['String'];
  messages: Array<Scalars['String']>;
};

export type UserModel = {
  __typename?: 'UserModel';
  id: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  provider: Scalars['String'];
  avatar?: Maybe<UserPublicFileModel>;
  avatarId?: Maybe<Scalars['String']>;
  video?: Maybe<UserPublicFileModel>;
  videoId?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  role: Role;
  phoneNumber?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientModel>;
  therapist?: Maybe<TherapistModel>;
  onboarded: Scalars['Boolean'];
  verified: Scalars['Boolean'];
  timezone?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  company?: Maybe<CompanyModel>;
  firstLogin: FirstLoginType;
  subcategories?: Maybe<Array<ResourcesSubcategoryDetailsModel>>;
  b2bRoles: Array<B2BUserRoleModel>;
  slackUserId?: Maybe<Scalars['String']>;
};

export type UserPublicFileModel = {
  __typename?: 'UserPublicFileModel';
  id: Scalars['String'];
  fileType: FileType;
  source: Scalars['String'];
  name: Scalars['String'];
  sizeInKb: Scalars['Float'];
  extension: FileExtension;
};

export type UserRoleModel = {
  __typename?: 'UserRoleModel';
  role?: Maybe<Role>;
};

export type ValidationError = UserError & {
  __typename?: 'ValidationError';
  field: Scalars['String'];
  messages: Array<Scalars['String']>;
};

export type ViewMaterialInput = {
  id: Scalars['String'];
};

export enum VisitType {
  Ongoing = 'ONGOING',
  FirstSession = 'FIRST_SESSION'
}

export type VoucherPaymentModel = PaymentModelV2 & {
  __typename?: 'VoucherPaymentModel';
  id: Scalars['String'];
  sessionType: SessionType;
  sessionDate: Scalars['DateTimeISO'];
  duration: Scalars['Int'];
  therapistId: Scalars['String'];
  therapist: TherapistPreviewModel;
  patientId: Scalars['String'];
  patient: PatientConnectedModel;
  clientType: TherapistClientTypeModel;
  paymentType: PaymentType;
  status: PaymentStatus;
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  paymentDate?: Maybe<Scalars['DateTimeISO']>;
  needManualPayment?: Maybe<Scalars['Boolean']>;
  voucherId?: Maybe<Scalars['String']>;
};

export enum VoucherStatus {
  Default = 'DEFAULT',
  Claimed = 'CLAIMED',
  Used = 'USED',
  Expired = 'EXPIRED',
  Transferred = 'TRANSFERRED',
  Reversed = 'REVERSED'
}

export type WellbeingEngagementModel = {
  __typename?: 'WellbeingEngagementModel';
  last30?: Maybe<Scalars['Float']>;
  prior30?: Maybe<Scalars['Float']>;
};

export type WellbeingQuestionModel = {
  __typename?: 'WellbeingQuestionModel';
  id: Scalars['String'];
  label: Scalars['String'];
  survey: Scalars['String'];
  color: Scalars['String'];
};

export type WellbeingResponseBreakdownModel = {
  __typename?: 'WellbeingResponseBreakdownModel';
  score?: Maybe<Scalars['Float']>;
  number: Scalars['Float'];
};

export type WellbeingResponseModel = {
  __typename?: 'WellbeingResponseModel';
  id: Scalars['String'];
  issued: Scalars['DateTime'];
  average?: Maybe<Scalars['Float']>;
  breakdown: Array<WellbeingResponseBreakdownModel>;
};

export type WellbeingUserResponseModel = {
  __typename?: 'WellbeingUserResponseModel';
  id: Scalars['String'];
  issued: Scalars['DateTime'];
  score?: Maybe<Scalars['Float']>;
};

export type WorkingScheduleAvailabilityModel = {
  __typename?: 'WorkingScheduleAvailabilityModel';
  from: Scalars['DateTimeISO'];
  until: Scalars['DateTimeISO'];
  types: Array<SessionType>;
  officeLocationId?: Maybe<Scalars['String']>;
  entryId: Scalars['String'];
  duration: Scalars['Int'];
  officeLocation: OfficeLocationModel;
};

export type WorkingScheduleDateEdge = {
  __typename?: 'WorkingScheduleDateEdge';
  cursor: Scalars['String'];
  date: Scalars['DateISO'];
  modified: Scalars['Boolean'];
  node: WorkingScheduleNode;
};

export type WorkingScheduleDatesConnection = {
  __typename?: 'WorkingScheduleDatesConnection';
  edges: Array<WorkingScheduleDateEdge>;
  pageInfo: RelayPageInfo;
};

export type WorkingScheduleDayEdge = {
  __typename?: 'WorkingScheduleDayEdge';
  cursor: Scalars['String'];
  day: Scalars['Int'];
  node: WorkingScheduleNode;
};

export type WorkingScheduleDaysConnection = {
  __typename?: 'WorkingScheduleDaysConnection';
  edges: Array<WorkingScheduleDayEdge>;
  pageInfo: RelayPageInfo;
};

export type WorkingScheduleEntryModel = {
  __typename?: 'WorkingScheduleEntryModel';
  id: Scalars['String'];
  /** ISO-8601 hh:mm:ss */
  from: Scalars['String'];
  /** ISO-8601 hh:mm:ss */
  until: Scalars['String'];
  types: Array<SessionType>;
  officeLocationId?: Maybe<Scalars['String']>;
  officeLocation: OfficeLocationModel;
  availabilities: Array<WorkingScheduleAvailabilityModel>;
};

export type WorkingScheduleNode = {
  __typename?: 'WorkingScheduleNode';
  schedule: Array<WorkingScheduleEntryModel>;
};

export type YearEarningsPayload = {
  __typename?: 'YearEarningsPayload';
  totalToDate: Scalars['String'];
  totalForYear: Scalars['String'];
  year: Scalars['Int'];
  byMonth: Array<MonthPaymentModel>;
};
