import { OnSubscriptionDataOptions } from '@apollo/client';
import { parseStoreNameArgs } from 'lib/apollo-client/utils/parse-store-name-args';
import { MessageAdded } from './../subscription/__generated__/message-added';
import { ChatMessageFragment } from '../query/__generated__/chat-messages';
import { MessagesRemoved } from '../subscription/__generated__/messages-removed';
import { ChatFragment } from '../query/__generated__/chats-list';

export function updateChatMessagesCache({
  client: { cache },
  subscriptionData: { data },
}: OnSubscriptionDataOptions<MessageAdded>) {
  const incomingMessage = data?.messageAdded;
  if (!incomingMessage) return;

  cache.modify({
    fields: {
      chatMessages(existingChatEdges, { readField, storeFieldName }) {
        const chatId = parseStoreNameArgs(
          storeFieldName,
          'chatMessages',
        ).chatId;

        const messageCursor = JSON.stringify({
          id: incomingMessage.id,
          createdAt: incomingMessage.createdAt,
        });

        const newMessageEdgeFragment = {
          __typename: 'MessageEdge',
          cursor: btoa(messageCursor),
          node: cache.writeFragment({
            data: incomingMessage,
            fragment: ChatMessageFragment,
            fragmentName: 'ChatMessageFragment',
          }),
        };

        if (
          chatId === incomingMessage.chatId &&
          !existingChatEdges.edges.some(
            (ref: any) => readField('id', ref.node) === incomingMessage.id,
          )
        ) {
          return {
            ...existingChatEdges,
            edges: [...existingChatEdges.edges, newMessageEdgeFragment],
          };
        } else {
          return existingChatEdges;
        }
      },
    },
  });
}

export function updateChatMessagesCacheAfterDeleting({
  client: { cache },
  subscriptionData: { data },
}: OnSubscriptionDataOptions<MessagesRemoved>) {
  const messagesRemoved = data?.messagesRemoved;
  if (!messagesRemoved) return;
  cache.modify({
    fields: {
      chatMessages(existingChatEdges, { readField, storeFieldName }) {
        const chatId = parseStoreNameArgs(
          storeFieldName,
          'chatMessages',
        ).chatId;

        if (chatId !== messagesRemoved.chatId) return existingChatEdges;

        return {
          ...existingChatEdges,
          edges: [
            ...existingChatEdges.edges.filter((ref: any) => {
              const existentChatEdgesNodeId = String(readField('id', ref.node));
              return !(
                existentChatEdgesNodeId &&
                messagesRemoved.messagesIds.includes(existentChatEdgesNodeId)
              );
            }),
          ],
        };
      },
      listChats(existingChats, { readField }) {
        existingChats.nodes?.forEach((node: ChatFragment) => {
          const chatId = readField('id', node);

          if (chatId !== messagesRemoved.chatId) {
            return node;
          }

          cache.modify({
            id: cache.identify(node),
            fields: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              lastMessage() {
                return messagesRemoved.lastMessage;
              },
            },
            broadcast: false,
          });
        });

        return existingChats;
      },
    },
  });
}
