import { SessionType } from '__generated__/types';
import { InPersonIcon, VideoChatIcon, LiveChatIcon } from 'ui/icons';

export const SESSION_TYPES_SVG_ICONS = {
  [SessionType.InPerson]: InPersonIcon,
  [SessionType.VideoCall]: VideoChatIcon,
  [SessionType.LiveChat]: LiveChatIcon,
};

export const SESSION_TYPE_TITLES = {
  [SessionType.InPerson]: 'In Person',
  [SessionType.VideoCall]: 'Video Call',
  [SessionType.LiveChat]: 'Live Chat',
};

export const DEFAULT_AVATAR = '/images/default-avatar.png';
