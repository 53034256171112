import { getSize, validationRules } from 'lib/utils';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Button, CloseIcon, Loader } from 'ui';
import { Field, Form as FinalForm } from 'react-final-form';
import { transformEnumToOptions } from 'lib/utils/form';
import { bookingSelectStyles } from 'ui/form-elements/single-select/utils/styles';
import { SingleSelect } from 'ui/form-elements/single-select';
import { DatePickerInput } from 'ui/form-elements';

import { TimeUtil } from 'lib/utils';

// GraphQL Hooks
import { useGenerateReport } from 'pages/business-page/mutation/__generated__/admin-generate-report';

enum ReportOptions {
  LastMonth = 'LAST_MONTH',
  LastQuarter = 'LAST_QUARTER',
  LastYear = 'LAST_YEAR',
  Custom = 'CUSTOM',
}

interface FeedbackModalProps {
  onCloseModal: () => void;
  companyId: string;
}

interface ReportFormValues {
  reportPeriod: ReportOptions;
  reportAt: string;
  reportTo: string;
}

const ROOT_CSS = css`
  margin-bottom: ${getSize(10)};
`;

const INPUT_CSS = css`
  border: 1px solid var(--gray19);
  border-radius: ${getSize(8)};
  padding: ${getSize(6)} ${getSize(12)};
  min-height: ${getSize(47)};
  font-weight: 500;
  font-size: ${getSize(13)};
  line-height: ${getSize(24)};
  overflow: visible;

  transition: 0.3s ease-out;

  &:focus {
    border-color: var(--purple);
  }

  &:hover:not(:focus) {
    border-color: var(--black10);
  }

  &:-webkit-autofill:focus {
    transition: 0.3s ease-out;
    border: 1px solid var(--purple) !important;
  }
  &:-webkit-autofill:hover:not(:-webkit-autofill:focus) {
    transition: 0.3s ease-out;
    border: 1px solid var(--black10) !important;
  }
  &:-webkit-autofill {
    transition: 0.3s ease-out;
    border: 1px solid var(--gray36) !important;
  }
`;

export const GenerateReportModal: FC<FeedbackModalProps> = ({
  onCloseModal,
  companyId,
}) => {
  //const loading = false;

  /** Use hook for graphql mutation */
  const [generateReport, { data, loading, error }] = useGenerateReport();

  /**
   * @description Method will take type of report period and make dateFrom - dateTo dates.
   * @param {object} values - object of type ReportFormValues
   * @returns {object} - object with dateFrom and dateTo properties
   */
  const generateFilter = (values: ReportFormValues) => {
    const { reportPeriod, reportAt, reportTo } = values;

    switch (reportPeriod) {
      default:
      case ReportOptions.LastMonth: {
        const lastMonth = TimeUtil.getLastMonthStartAndEnd('YYYY-MM-DD');
        return {
          dateFrom: lastMonth.start,
          dateTo: lastMonth.end,
        };
      }
      case ReportOptions.LastQuarter: {
        const lastQuarter = TimeUtil.getLastQuarterStartAndEnd('YYYY-MM-DD');
        return {
          dateFrom: lastQuarter.start,
          dateTo: lastQuarter.end,
        };
      }
      case ReportOptions.LastYear: {
        const lastYear = TimeUtil.getLastYearStartAndEnd('YYYY-MM-DD');
        return {
          dateFrom: lastYear.start,
          dateTo: lastYear.end,
        };
      }
      case ReportOptions.Custom:
        return {
          dateFrom: reportAt,
          dateTo: reportTo,
        };
    }
  };

  const handleFormSubmit = async (values: ReportFormValues) => {
    const reportFilter = generateFilter(values);

    const tmpData = {
      companyId,
      ...reportFilter,
    };

    try {
      const data = await generateReport({ variables: tmpData });
      const dataUrl = data.data?.generateReport.dataUrl || '';
      const fileName = data.data?.generateReport.filename || '';
      downloadPDF(dataUrl, fileName);
    } catch (error) {
      console.log('Error happend');
      console.log(error);
    }
  };

  /**
   * @description Method will convert base64 to .pdf doc and downlaod it.
   * @param {string} dataUrl - our base64 doc
   * @param {string} fileName - name for file
   */
  const downloadPDF = (dataUrl: string, fileName: string) => {
    const linkSource = `data:application/pdf;base64,${dataUrl}`;
    const downloadLink = document.createElement('a');

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <ShadowWrapper>
      <Content>
        <Header>
          <HeaderText>Report Setup</HeaderText>
          <CloseIconStyled onClick={onCloseModal} />
        </Header>
        {loading ? (
          <Loader />
        ) : (
          <FinalForm
            onSubmit={handleFormSubmit}
            render={({ values, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Field
                  name="reportPeriod"
                  label="Select report period"
                  options={transformEnumToOptions(ReportOptions, true)}
                  customSelectStyles={bookingSelectStyles}
                  isSearchable={false}
                  inputCSS={INPUT_CSS}
                  rootCSS={ROOT_CSS}
                  hasLine={false}
                  validate={validationRules.required}
                  component={SingleSelect}
                />
                {values.reportPeriod === ReportOptions.Custom ? (
                  <FieldWrapper>
                    <Field
                      name="reportAt"
                      label="From"
                      customSelectStyles={bookingSelectStyles}
                      isSearchable={false}
                      rootCSS={ROOT_CSS}
                      inputCSS={INPUT_CSS}
                      validate={validationRules.required}
                      component={DatePickerInput}
                    />
                    <Field
                      name="reportTo"
                      label="To"
                      customSelectStyles={bookingSelectStyles}
                      isSearchable={false}
                      rootCSS={ROOT_CSS}
                      inputCSS={INPUT_CSS}
                      validate={validationRules.required}
                      component={DatePickerInput}
                    />
                  </FieldWrapper>
                ) : (
                  <EmptyField />
                )}
                <LineSeparator />
                <GenerateReportButton>Generate Report</GenerateReportButton>
              </Form>
            )}
          />
        )}
      </Content>
    </ShadowWrapper>
  );
};

const ShadowWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.51);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const Content = styled.div`
  width: ${getSize(582)};
  background: #ffffff;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${getSize(22)} ${getSize(23)} ${getSize(19)} ${getSize(27)};
  border-bottom: 1px solid #f1f1f1;
`;
const HeaderText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: ${getSize(18)};

  color: #000000;
`;

const CloseIconStyled = styled(CloseIcon)`
  cursor: pointer;
`;

interface GenerateReportButtonProps {
  isWhite?: boolean;
}

const GenerateReportButton = styled(Button)<GenerateReportButtonProps>`
  height: 42px;
  min-width: ${getSize(130)};
  width: 100%;
  background: ${({ isWhite }) => (isWhite ? 'white' : '#6b4ee6')};
  border: 1px solid #6b4ee6;
  box-sizing: border-box;
  border-radius: 34px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isWhite }) => (isWhite ? '#6b4ee6' : 'white')};
  font-weight: 600;
  font-size: ${getSize(14)};
`;

const Form = styled.form`
  padding: ${getSize(27)};
`;

const LineSeparator = styled.div`
  height: 1px;
  background: #f1f1f1;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const FieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 14px;
`;

const EmptyField = styled.div`
  height: ${getSize(74)};
`;
