import React, { useState, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from 'ui/loader';
import styled, { CSSProp } from 'styled-components';
import { getSize } from 'lib/utils';
import { TableTitles } from 'components/table-titles';
import { Paper, ShadowWrapper } from 'ui';
import { RowItem } from './components/row-item';
import { EditPricingModal } from './components';

import { RowTemplate } from './components/row-template';
import { PricingRowItem } from './components/pricing-row-item';

/** Fragment */
import { SessionPriceAdminFragment } from 'common/fragments/__generated__/session-price-admin.fragment';

/** Queries */
import { useGetSessionPrices } from 'common/query/__generated__/get-session-prices';

const MAX_VISIBLE_THERAPISTS = 10;

/** Parts of page */
import { Therapists } from './therapists';
import { Pricings } from './pricings';

const PricingPage = () => {
  const debounceHandleSearchFormSubmit = () => {
    console.log('DA');
  };
  return (
    <Wrapper>
      <Inner asElement="section">
        <Pricings />
        <Therapists />
      </Inner>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: ${getSize(50)} ${getSize(56)} ${getSize(111)};
`;

const Inner = styled(ShadowWrapper)`
  position: relative;
  padding: ${getSize(28)} ${getSize(0)};
  max-width: ${getSize(1076)};
  width: 100%;
`;

export default PricingPage;
