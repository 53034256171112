import { getSize } from 'lib/utils';
import { CSSProperties } from 'react';
import { Styles } from 'react-select';

export const customStyles: Partial<Styles<any, any>> = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore-next-line
  container: (base, { isFocused, isDisabled, selectProps: { isError } }) => ({
    ...base,
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: `${getSize(3)}`,
      width: '100%',
      height: `${getSize(1)}`,
      borderRadius: `${getSize(8)}`,
      background: `var(${(() => {
        if (isError) {
          return '--red';
        } else if (isDisabled) {
          return '--gray4';
        } else if (isFocused) {
          return '--purple4';
        } else {
          return '--purple3';
        }
      })()}
      )`,
      transition: '0.3s ease-out',
    },
  }),
  control: (base) => {
    return {
      ...base,
      minHeight: 'inherit',
      height: `${getSize(44)}`,
      boxShadow: 'none',
      border: 0,
      background: 'transparent',

      '&:hover': {
        cursor: 'pointer',
      },
    };
  },
  placeholder: (base) => ({
    ...base,
    fontWeight: 'normal',
    fontSize: `${getSize(12)}`,
    lineHeight: `${getSize(20)}`,
    color: 'var(--gray7)',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    overflow: 'visible',
  }),
  singleValue: (
    base,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore-next-line
    { isFocused, hasValue, isDisabled, selectProps: { hasLightTheme } },
  ) => ({
    ...base,
    fontWeight: 'normal',
    fontSize: `${getSize(12)}`,
    lineHeight: `${getSize(20)}`,
    color: `var(${(() => {
      if (isDisabled) {
        return '--gray7';
      } else if (isFocused || hasValue) {
        if (hasLightTheme) {
          return '--white';
        } else {
          return '--black3';
        }
      } else {
        return '--gray2';
      }
    })()})`,
  }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: (base, { selectProps: { isOpen } }) => ({
    ...base,
    padding: `0 ${getSize(2)}`,
    transform: `scale(${isOpen ? -1 : 1})`,
    transition: '0.2s ease-out',
  }),
  menu: (base) => ({
    ...base,
    top: `calc(100% + ${getSize(8)})`,
    margin: 0,
    zIndex: 10,
    padding: 0,
    borderRadius: `${getSize(8)}`,
    boxShadow: `0 ${getSize(10)} ${getSize(30)} var(--blue-opacity2)`,
    overflow: 'hidden',
  }),
  menuList: (base) => ({
    ...base,
    padding: `${getSize(12)} 0`,
    maxHeight: `${getSize(168)}`,
  }),
  noOptionsMessage: (base) => ({
    ...base,
    fontWeight: 'normal',
    fontSize: getSize(14),
    lineHeight: getSize(20),
    color: `var(--gray7)`,
  }),
  option: (base, { isSelected }) => ({
    ...base,
    padding: `${getSize(12)} ${getSize(24)}`,
    minHeight: `${getSize(48)}`,
    fontWeight: 'normal',
    lineHeight: `${getSize(24)}`,
    fontSize: `${getSize(14)}`,
    color: 'var(--black3)',
    background: `var(${isSelected ? '--purple11' : '--white'})`,
    transition: 'all cubic-bezier(0.4, 0, 0.2, 1) 0.15s',
    cursor: 'pointer',

    '&:hover': {
      background: `var(--purple11)`,
    },
  }),
};

export const onboardingSelectStyles: Partial<Styles<any, any>> = {
  ...customStyles,
  singleValue: (
    base,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore-next-line
    { isFocused, hasValue, isDisabled, selectProps: { hasLightTheme } },
  ) => ({
    ...base,
    fontWeight: 500,
    fontSize: `${getSize(13)}`,
    lineHeight: `${getSize(24)}`,
    color: `var(${(() => {
      if (isDisabled) {
        return '--gray7';
      } else if (isFocused || hasValue) {
        if (hasLightTheme) {
          return '--white';
        } else {
          return '--black4';
        }
      } else {
        return '--gray2';
      }
    })()})`,
  }),
  placeholder: (base) => ({
    ...base,
    fontWeight: 'normal',
    fontSize: `${getSize(13)}`,
    lineHeight: `${getSize(24)}`,
    color: 'var(--gray7)',
  }),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore-next-line
  container: (base, { isFocused, isDisabled, selectProps: { isError } }) => ({
    ...base,
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: `${getSize(-1)}`,
      width: '100%',
      height: `${getSize(1)}`,
      borderRadius: `${getSize(8)}`,
      background: `var(${(() => {
        if (isError) {
          return '--red';
        } else if (isDisabled) {
          return '--gray4';
        } else if (isFocused) {
          return '--purple4';
        } else {
          return '--purple3';
        }
      })()}
      )`,
      transition: '0.3s ease-out',
    },
  }),
};

export const customTimeAvailabilityStyles: Partial<Styles<any, any>> = {
  container: (base) => ({
    ...base,
    height: '100%',
  }),
  control: (base) => {
    return {
      ...base,
      height: `100%`,
      boxShadow: 'none',
      border: 0,
      background: 'transparent',

      '&:hover': {
        cursor: 'pointer',
      },
    };
  },
  placeholder: (base) => ({
    ...base,
    fontWeight: 'normal',
    fontSize: `${getSize(12)}`,
    lineHeight: `${getSize(20)}`,
    color: 'var(--gray7)',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    overflow: 'visible',
  }),
  singleValue: (
    base,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore-next-line
    { isFocused, hasValue, isDisabled, selectProps: { hasLightTheme } },
  ) => ({
    ...base,
    fontWeight: 500,
    fontSize: `${getSize(13)}`,
    lineHeight: `${getSize(24)}`,
    color: `var(${(() => {
      if (isDisabled) {
        return '--gray7';
      } else if (isFocused || hasValue) {
        if (hasLightTheme) {
          return '--white';
        } else {
          return '--black3';
        }
      } else {
        return '--gray2';
      }
    })()})`,
  }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: (base, { selectProps: { isOpen } }) => ({
    ...base,
    padding: `0 ${getSize(12)}`,
    transform: `scale(${isOpen ? -1 : 1})`,
    transition: '0.2s ease-out',
    '& > svg': {
      stroke: `var(--${isOpen ? 'purple' : 'black3'})`,
      width: getSize(19),
      height: getSize(19),
    },
  }),
  menu: (base) => ({
    ...base,
    margin: 0,
    zIndex: 10,
    padding: 0,
    border: 'none',
    borderRadius: `${getSize(10)}`,
    boxShadow: 'none',
    filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1))',
    overflow: 'hidden',
    width: 'auto',
    position: 'absolute',
    left: -135,
    bottom: 6,
  }),
  menuList: (base) => ({
    ...base,
    padding: `${getSize(12)} 0`,
    minHeight: `${getSize(303)}`,
    minWidth: `${getSize(296)}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
  }),
  noOptionsMessage: (base) => ({
    ...base,
    fontWeight: 'normal',
    fontSize: `${getSize(14)}`,
    lineHeight: `${getSize(20)}`,
    color: `var(--gray7)`,
    alignSelf: 'center',
  }),
  option: (base, { isSelected }) => ({
    ...base,
    padding: `${getSize(7)} 0`,
    minHeight: `${getSize(37)}`,
    fontWeight: 500,
    lineHeight: `${getSize(24)}`,
    fontSize: `${getSize(15)}`,
    color: 'var(--black3)',
    background: `var(--white)`,
    transition: 'all cubic-bezier(0.4, 0, 0.2, 1) 0.15s',
    cursor: 'pointer',
    border: `1px solid var(--${isSelected ? 'purple' : 'transparent'})`,
    borderRadius: `${getSize(8)}`,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',

    '&:hover': {
      borderColor: `var(--${isSelected ? 'purple' : 'gray34'})`,
      background: 'var(--white)',
    },
  }),
};

export const bookingSelectStyles: Partial<Styles<any, any>> = {
  ...customStyles,
  singleValue: (
    base,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore-next-line
    { isFocused, hasValue, isDisabled, selectProps: { hasLightTheme } },
  ) => ({
    ...base,
    fontWeight: 500,
    fontSize: `${getSize(13)}`,
    lineHeight: `${getSize(24)}`,
    color: `var(${(() => {
      if (isDisabled) {
        return '--gray7';
      } else if (isFocused || hasValue) {
        if (hasLightTheme) {
          return '--white';
        } else {
          return '--black3';
        }
      } else {
        return '--gray2';
      }
    })()})`,
  }),
  placeholder: (base) => ({
    ...base,
    fontWeight: 500,
    fontSize: `${getSize(13)}`,
    lineHeight: `${getSize(24)}`,
    color: 'var(--gray7)',
  }),
  container: (base) => ({
    ...base,
  }),
  control: (base, state) => {
    return {
      ...base,
      minHeight: getSize(43),
      boxShadow: 'none',
      border: state.isFocused
        ? `${getSize(1)} solid var(--purple)`
        : `${getSize(1)} solid var(--gray19)`,
      background: 'transparent',
      transition: '0.2s ease-out',
      borderRadius: getSize(8),

      '&:hover': {
        cursor: 'pointer',
        border: state.isFocused
          ? `${getSize(1)} solid var(--purple)`
          : `${getSize(1)} solid var(--gray22)`,
      },
    };
  },
  valueContainer: (base) => ({
    ...base,
    padding: `${getSize(6)} 0 ${getSize(6)} ${getSize(9)}`,
    overflow: 'visible',
    alignItems: 'center',
  }),
  indicatorsContainer: (base, { isDisabled, selectProps: { isOpen } }) => ({
    ...base,
    display: isDisabled ? 'none' : 'flex',
    margin: `0 ${getSize(14)} 0 0`,
    height: getSize(44),
    transform: `scale(${isOpen ? -1 : 1})`,
    transition: '0.2s ease-out',
  }),
};

export const guidedMatchSingleSelectStyles: Partial<Styles<any, any>> = {
  ...bookingSelectStyles,
  container: (base) => ({
    ...base,
    backgroundColor: 'var(--white)',
    borderRadius: `${getSize(10)}`,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: `${getSize(6)} 0 ${getSize(6)} ${getSize(14)}`,
  }),
};
