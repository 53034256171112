import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetCancelledRequestsVariables = Types.Exact<{
  first?: Types.Maybe<Types.Scalars['Int']>;
  after?: Types.Maybe<Types.Scalars['String']>;
  therapist?: Types.Maybe<Types.CancellationsFilter>;
}>;


export type GetCancelledRequests = (
  { __typename: 'Query' }
  & { adminCancelledRequests: (
    { __typename: 'CancelledRequestsConnection' }
    & { nodes: Array<(
      { __typename: 'CancelledRequestModel' }
      & CanceledRequestFragment
    )>, pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<Types.PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);

export type CanceledRequestFragment = (
  { __typename: 'CancelledRequestModel' }
  & Pick<Types.CancelledRequestModel, 'id' | 'requestDate' | 'reasonForCancellation' | 'updatedAt' | 'createdAt'>
  & { patient: (
    { __typename: 'PatientModel' }
    & CancelledPatientFragment
  ), therapist: (
    { __typename: 'TherapistModel' }
    & CancelledTherapistFragment
  ) }
);

export type CancelledTherapistFragment = (
  { __typename: 'TherapistModel' }
  & Pick<Types.TherapistModel, 'id' | 'bio' | 'userId'>
  & { title?: Types.Maybe<(
    { __typename: 'TherapistTitleModel' }
    & Pick<Types.TherapistTitleModel, 'id' | 'label' | 'explanation'>
  )>, user: (
    { __typename: 'UserModel' }
    & Pick<Types.UserModel, 'id' | 'email' | 'firstName' | 'lastName'>
    & { avatar?: Types.Maybe<(
      { __typename: 'UserPublicFileModel' }
      & Pick<Types.UserPublicFileModel, 'id' | 'fileType' | 'source' | 'name' | 'sizeInKb' | 'extension'>
    )> }
  ), qualification?: Types.Maybe<(
    { __typename: 'QualificationModel' }
    & { professionalTitle: (
      { __typename: 'ProfessionalTitleModel' }
      & Pick<Types.ProfessionalTitleModel, 'label' | 'id'>
    ) }
  )> }
);

export type CancelledPatientFragment = (
  { __typename: 'PatientModel' }
  & Pick<Types.PatientModel, 'id' | 'dateOfBirthday' | 'userId'>
  & { user: (
    { __typename: 'UserModel' }
    & Pick<Types.UserModel, 'id' | 'email' | 'firstName' | 'lastName'>
    & { avatar?: Types.Maybe<(
      { __typename: 'UserPublicFileModel' }
      & Pick<Types.UserPublicFileModel, 'id' | 'fileType' | 'source' | 'name' | 'sizeInKb' | 'extension'>
    )> }
  ) }
);

export const CancelledPatientFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CancelledPatientFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PatientModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"dateOfBirthday"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"avatar"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"fileType"}},{"kind":"Field","name":{"kind":"Name","value":"source"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"sizeInKb"}},{"kind":"Field","name":{"kind":"Name","value":"extension"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"userId"}}]}}]} as unknown as DocumentNode;
export const CancelledTherapistFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CancelledTherapistFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"TherapistModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"explanation"}}]}},{"kind":"Field","name":{"kind":"Name","value":"bio"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"avatar"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"fileType"}},{"kind":"Field","name":{"kind":"Name","value":"source"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"sizeInKb"}},{"kind":"Field","name":{"kind":"Name","value":"extension"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"userId"}},{"kind":"Field","name":{"kind":"Name","value":"qualification"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"professionalTitle"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode;
export const CanceledRequestFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CanceledRequestFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CancelledRequestModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"patient"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CancelledPatientFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"therapist"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CancelledTherapistFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"requestDate"}},{"kind":"Field","name":{"kind":"Name","value":"reasonForCancellation"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}},...CancelledPatientFragment.definitions,...CancelledTherapistFragment.definitions]} as unknown as DocumentNode;
export const GetCancelledRequestsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCancelledRequests"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"first"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"after"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"therapist"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"CancellationsFilter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminCancelledRequests"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"Variable","name":{"kind":"Name","value":"first"}}},{"kind":"Argument","name":{"kind":"Name","value":"after"},"value":{"kind":"Variable","name":{"kind":"Name","value":"after"}}},{"kind":"Argument","name":{"kind":"Name","value":"therapist"},"value":{"kind":"Variable","name":{"kind":"Name","value":"therapist"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"nodes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CanceledRequestFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"pageInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hasNextPage"}},{"kind":"Field","name":{"kind":"Name","value":"endCursor"}}]}}]}}]}},...CanceledRequestFragment.definitions]} as unknown as DocumentNode;

/**
 * __useGetCancelledRequests__
 *
 * To run a query within a React component, call `useGetCancelledRequests` and pass it any options that fit your needs.
 * When your component renders, `useGetCancelledRequests` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCancelledRequests({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      therapist: // value for 'therapist'
 *   },
 * });
 */
export function useGetCancelledRequests(baseOptions?: Apollo.QueryHookOptions<GetCancelledRequests, GetCancelledRequestsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCancelledRequests, GetCancelledRequestsVariables>(GetCancelledRequestsDocument, options);
      }
export function useGetCancelledRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCancelledRequests, GetCancelledRequestsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCancelledRequests, GetCancelledRequestsVariables>(GetCancelledRequestsDocument, options);
        }
export type GetCancelledRequestsHookResult = ReturnType<typeof useGetCancelledRequests>;
export type GetCancelledRequestsLazyQueryHookResult = ReturnType<typeof useGetCancelledRequestsLazyQuery>;
export type GetCancelledRequestsQueryResult = Apollo.QueryResult<GetCancelledRequests, GetCancelledRequestsVariables>;