import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  split,
} from '@apollo/client';
import { cacheOptions } from './cache-options';
import { customFetch } from './custom-fetch';
import { config } from 'lib/utils/config';
import { getMainDefinition } from '@apollo/client/utilities';
import { refreshAccessToken } from 'lib/auth';
import { WebSocketLink } from '@apollo/client/link/ws';
import { StorageService } from 'lib/utils';

const httpLink = createHttpLink({
  uri: `${config.REACT_APP_GQL_URL}`,
  fetch: customFetch,
});

export const wsLink = new WebSocketLink({
  uri: `${config.REACT_APP_GQL_URL}`
    .replace('https:', 'wss:')
    .replace('http:', 'ws:'),
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: async () => {
      const isRememberUser = Boolean(StorageService.getIsRememberUser());
      try {
        await refreshAccessToken(isRememberUser);
      } catch (e) {
        console.log(e);
      }
      const accessToken = StorageService.getAccessToken();
      return {
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      };
    },
    connectionCallback: (error: any) => {
      if (error?.message === 'Authentication Failure!') {
        (wsLink as any).subscriptionClient.close(false, false);
      }
    },
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

export const client = new ApolloClient({
  ssrMode: false,
  link: splitLink,
  cache: new InMemoryCache(cacheOptions),
});
