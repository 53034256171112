import React from 'react';

function DocxIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      {...rest}>
      <path
        d="M19.122 1.003a.438.438 0 00-.31-.128H6.563A3.066 3.066 0 003.5 3.938v20.125a3.066 3.066 0 003.063 3.062h14.875a3.066 3.066 0 003.062-3.063v-17.5a.437.437 0 00-.128-.309l-5.25-5.25zm.128 1.366l3.756 3.756h-1.569a2.19 2.19 0 01-2.187-2.188V2.37zM6.562 1.75h11.813v2.188A3.066 3.066 0 0021.438 7h2.187v12.25H4.375V3.937A2.19 2.19 0 016.563 1.75zm14.875 24.5H6.563a2.19 2.19 0 01-2.187-2.188v-3.937h19.25v3.938a2.19 2.19 0 01-2.188 2.187z"
        fill="#7A60E9"
      />
      <path
        d="M7.438 21a.438.438 0 00-.438.438v3.5a.438.438 0 00.438.437 2.188 2.188 0 000-4.375zm.437 3.425V21.95a1.312 1.312 0 010 2.475zM12.25 21c-.965 0-1.75.981-1.75 2.188 0 1.206.785 2.187 1.75 2.187S14 24.394 14 23.187C14 21.982 13.215 21 12.25 21zm0 3.5c-.474 0-.875-.601-.875-1.313 0-.711.4-1.312.875-1.312.474 0 .875.601.875 1.313 0 .711-.4 1.312-.875 1.312zM16.625 21.875c.087 0 .173.02.251.058a.436.436 0 00.623-.375.435.435 0 00-.25-.417 1.453 1.453 0 00-.624-.141c-.965 0-1.75.981-1.75 2.188 0 1.206.785 2.187 1.75 2.187.216 0 .43-.049.624-.141a.437.437 0 10-.373-.792.583.583 0 01-.251.058c-.474 0-.875-.601-.875-1.313 0-.711.4-1.312.875-1.312zM20.759 21.047a.437.437 0 00-.587.195l-.484.968-.484-.968a.438.438 0 00-.782.392l.777 1.554-.777 1.554a.436.436 0 00.36.636.439.439 0 00.422-.244l.484-.968.484.968a.437.437 0 10.782-.392l-.777-1.554.777-1.554a.437.437 0 00-.195-.587zM10.5 9.188a.438.438 0 00.438.437h9.624a.438.438 0 000-.875h-9.625a.438.438 0 00-.437.438zM7.438 11.375h7.875a.438.438 0 000-.875H7.436a.438.438 0 000 .875zM20.563 12.25h-7a.438.438 0 000 .875h7a.438.438 0 000-.875zM7.438 14.875H17.5a.438.438 0 000-.875H7.437a.438.438 0 000 .875zM7.438 9.625h1.75a.437.437 0 100-.875h-1.75a.437.437 0 100 .875zM20.563 10.5h-3.5a.438.438 0 000 .875h3.5a.438.438 0 000-.875zM7.438 13.125h4.375a.438.438 0 000-.875H7.436a.438.438 0 000 .875zM20.563 14H19.25a.438.438 0 000 .875h1.313a.438.438 0 000-.875zM7.438 16.625h2.625a.438.438 0 000-.875H7.437a.438.438 0 000 .875zM7.438 7.875h4.375a.438.438 0 000-.875H7.436a.437.437 0 100 .875zM16.188 15.75h-4.375a.438.438 0 000 .875h4.374a.438.438 0 000-.875zM20.563 15.75h-2.625a.438.438 0 000 .875h2.625a.438.438 0 000-.875z"
        fill="#7A60E9"
      />
    </svg>
  );
}

export default DocxIcon;
