import { TherapistFragment } from 'common/query/__generated__/get-therapists-by-request';
import { TableTitles } from 'components/table-titles';
import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import { Loader } from 'ui';
import { RequestItem } from './components';

interface TherapistRequestsProps {
  isLoading: boolean;
  onNext: () => void;
  dataLength: number;
  hasMore: boolean;
  therapists?: TherapistFragment[];
}

const TherapistRequests: FC<TherapistRequestsProps> = ({
  isLoading,
  dataLength,
  onNext,
  therapists,
  hasMore,
}) => {
  return (
    <DashboardWrapper className="dash-wrap">
      <TableTitles
        secondColumnCSS={{ marginLeft: getSize(360) }}
        thirdColumnCSS={{ marginLeft: getSize(170) }}
        firstColumnName="Therapists"
        secondColumnName="Request Date"
        thirdColumnName="Email"
      />
      <ScrollWrapper id="scrollableRequestsList">
        {isLoading ? (
          <Loader hasFillWholeBlock size={50} />
        ) : (
          <TherapistList
            dataLength={dataLength}
            next={onNext}
            hasMore={hasMore}
            scrollableTarget="scrollableRequestsList"
            style={{ overflow: 'visible' }}
            loader={<Loader />}>
            {therapists?.map((therapist) => (
              <RequestItem key={therapist.id} therapist={therapist} />
            ))}
          </TherapistList>
        )}
      </ScrollWrapper>
    </DashboardWrapper>
  );
};

const DashboardWrapper = styled.div`
  padding-top: ${getSize(20)};
`;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore-next-line
const TherapistList = styled(InfiniteScroll)`
  padding: 0 ${getSize(40)} ${getSize(20)};

  & > *:not(:first-child) {
    margin-top: ${getSize(12)};
  }
`;

const ScrollWrapper = styled.div`
  overflow: auto;
  padding-top: ${getSize(12)};
  height: ${getSize(500)};

  &:: -webkit-scrollbar {
    display: none;
  }
`;

export default TherapistRequests;
