import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AdminGetBankAccountVariables = Types.Exact<{ [key: string]: never; }>;


export type AdminGetBankAccount = (
  { __typename: 'Query' }
  & { adminGetBankAccount: (
    { __typename: 'AdminCustomerModel' }
    & Pick<Types.AdminCustomerModel, 'id' | 'paymentMethod'>
  ) }
);


export const AdminGetBankAccountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AdminGetBankAccount"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminGetBankAccount"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"paymentMethod"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useAdminGetBankAccount__
 *
 * To run a query within a React component, call `useAdminGetBankAccount` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetBankAccount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetBankAccount({
 *   variables: {
 *   },
 * });
 */
export function useAdminGetBankAccount(baseOptions?: Apollo.QueryHookOptions<AdminGetBankAccount, AdminGetBankAccountVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetBankAccount, AdminGetBankAccountVariables>(AdminGetBankAccountDocument, options);
      }
export function useAdminGetBankAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetBankAccount, AdminGetBankAccountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetBankAccount, AdminGetBankAccountVariables>(AdminGetBankAccountDocument, options);
        }
export type AdminGetBankAccountHookResult = ReturnType<typeof useAdminGetBankAccount>;
export type AdminGetBankAccountLazyQueryHookResult = ReturnType<typeof useAdminGetBankAccountLazyQuery>;
export type AdminGetBankAccountQueryResult = Apollo.QueryResult<AdminGetBankAccount, AdminGetBankAccountVariables>;