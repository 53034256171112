import { getSize } from 'lib/utils';

const THEMES: any = {
  primary: (isLoading: boolean) => `
    font-weight: 600;
    color: white;
    background: var(--purple);

    &:hover {
      background: var(--purple4);
    }

    ${
      !isLoading &&
      `
      &:disabled {
        color: var(--purple5);
        background: var(--purple6);
      }
    `
    }
  `,
  secondary: (isLoading: boolean) => `
    border: ${getSize(1)} solid var(--purple3);
    background: white;
    color: var(--red);

    &:hover {
      border-color: var(--gray3);
    }

    &:active {
      border-color: var(--purple5);
      background: var(--purple5);
    }

    &:focus {
      border-color: var(--purple5);
    }

    &:disabled {
      border-color: var(--gray4);
      color: var(--gray4);
    }

    ${isLoading ? 'border-color: var(--gray4);' : ''}
  `,
  tertiary: (isLoading: boolean) => `
    font-weight: 600;
    color: var(--purple);
    background: white;

    &:hover {
      background: var(--purple7);
    }

    &:focus {
      background: var(--purple8);
    }

    ${
      !isLoading &&
      `
      &:disabled {
        background: var(--purple9);
      }
    `
    }
  `,
  'buble-primary': () => `
    padding: ${getSize(7)} ${getSize(28)};
    min-height: auto;
    font-weight: 600;
    font-size: ${getSize(12)};
    line-height: ${getSize(18)};
    color: var(--purple);
    border: ${getSize(1)} solid var(--purple);
    border-radius: ${getSize(28)};
    transition: 0.2s ease-out;
    text-transform: uppercase;

    &:hover {
      box-shadow: 0 0 ${getSize(15)} var(--purple-opacity2);
    }
  `,
  'buble-secondary': () => `
    padding: ${getSize(7)} ${getSize(28)};
    min-height: auto;
    font-weight: 400;
    font-size: ${getSize(12)};
    line-height: ${getSize(18)};
    color: var(--black3);
    border: ${getSize(1)} solid var(--purple3);
    border-radius: ${getSize(28)};
    transition: 0.2s ease-out;

    &:hover {
      border-color: var(--purple);
      color: var(--purple);
      box-shadow: 0 0 ${getSize(15)} var(--purple-opacity2);
    }
  `,
};

export default THEMES;
