import React, { useMemo } from 'react';
import styled from 'styled-components';

import { getSize } from 'lib/utils';
import { ChatUserFragment } from 'pages/messaging-page/query/__generated__/users-for-start-new-chat';

import { Button } from 'ui';
import { UserAvatar } from 'pages/messaging-page/components/user-avatar';

export interface InterlocutorItemProps {
  onWriteClick: () => void;
  searchString?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  interlocutor: ChatUserFragment;
  hasShowButton: boolean;
}

function InterlocutorItem({
  onWriteClick,
  searchString,
  isLoading,
  isDisabled,
  hasShowButton,
  interlocutor: { avatarUrl, name, type },
}: InterlocutorItemProps) {
  const currentName = useMemo(() => {
    return {
      __html: searchString
        ? name.replace(
            new RegExp(`${searchString.trim()}`, 'gi'),
            (oldValue) => `<span class="search-element">${oldValue}</span>`,
          )
        : name,
    };
  }, [name, searchString]);

  return (
    <Wrapper>
      <Image src={avatarUrl} type={type} alt={`${name} avatar`} />
      <Name dangerouslySetInnerHTML={currentName} />

      {hasShowButton && (
        <WriteButton
          type="button"
          theme="secondary"
          onClick={onWriteClick}
          isLoading={isLoading}
          isDisabled={isDisabled}>
          Write
        </WriteButton>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.li`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: ${getSize(16)};
  }
`;

const Image = styled(UserAvatar)`
  margin: 0 ${getSize(16)} 0 0;
`;

const Name = styled.p`
  margin: 0 ${getSize(16)} 0 0;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--black3);

  .search-element {
    background: var(--purple5);
  }
`;

const WriteButton = styled(Button)`
  margin: 0 0 0 auto;
  min-width: ${getSize(78)};
  color: var(--black3);
`;

export default InterlocutorItem;
