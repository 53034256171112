import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import styled from 'styled-components';
import { SearchIcon } from 'ui/icons';

interface SearchInputProps {
  placeholder?: string;
  onChange: (value: string) => void;
  className?: string;
}

const SearchInput: FC<SearchInputProps> = ({
  placeholder,
  onChange,
  className,
}) => {
  return (
    <Wrapper>
      <InputIcon />
      <Input
        className={className}
        placeholder={placeholder || 'Search'}
        onChange={(e) => onChange(e.target.value)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const InputIcon = styled(SearchIcon)`
  position: absolute;
  top: 50%;
  left: ${getSize(12)};
  transform: translateY(-50%);
  width: ${getSize(20)};
  height: ${getSize(20)};
`;

const Input = styled.input`
  min-height: ${getSize(36)};
  min-width: ${getSize(250)};
  padding: ${getSize(9)} ${getSize(16)} ${getSize(8)} ${getSize(40)};
  font-size: ${getSize(11)};
  line-height: ${getSize(16)};
  color: var(--black3);
  transition: all 0.3s ease-out;
  box-shadow: 0px ${getSize(20)} ${getSize(90)} rgba(40, 31, 61, 0.04);
  border-bottom: 1px solid var(--purple3);

  &::placeholder {
    color: var(--gray7);
  }

  &:focus {
    background-color: var(--purple11);
    border-bottom: 1px solid var(--purple12);
  }
`;

export default SearchInput;
