import { CanceledRequestFragment } from 'common/query/__generated__/get-cancelled-requests';
import { CanceledSessionFragment } from 'common/query/__generated__/get-cancelled-sessions';
import { DEFAULT_AVATAR } from 'lib/constants/therapist';
import {
  DATE_OF_BIRTHDAY,
  TIME_WITH_TWENTY_FOUR_HOURS,
  getSize,
  TimeUtil,
} from 'lib/utils';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { dashboardRoutes } from 'routes/root-routes';
import styled from 'styled-components';
import { Button } from 'ui';
import { RowTemplate } from '../row-template';

const visibleReasonLength = 42;
type CancellationItemProps = {
  item: CanceledSessionFragment | CanceledRequestFragment;
};

const CancellationItem: React.FC<CancellationItemProps> = ({ item }) => {
  const [isShowFullReason, setIsShowFullReason] = useState(false);
  const history = useHistory();

  const isReasonLong = item.reasonForCancellation.length > visibleReasonLength;
  const reasonText = isReasonLong
    ? `${item.reasonForCancellation.slice(0, visibleReasonLength)}...`
    : item.reasonForCancellation;

  const date =
    item?.__typename === 'CancelledRequestModel'
      ? item.requestDate
      : item.sessionDate;

  const openTherapistProfile = () => {
    history.push(dashboardRoutes.therapist.pathBuild(item.therapist.id));
  };

  return (
    <MainWrapper>
      <InfoWrapper>
        <UserBlock>
          <Avatar src={item.therapist.user.avatar?.source || DEFAULT_AVATAR} />
          <div>
            <BlackText>
              {item.therapist.user.firstName} {item.therapist.user.lastName}
            </BlackText>
            <GrayText>
              {item.therapist.qualification?.professionalTitle.label}
            </GrayText>
          </div>
        </UserBlock>
        <UserBlock>
          <Avatar src={item.patient.user.avatar?.source || DEFAULT_AVATAR} />
          <BlackText>
            {item.patient.user.firstName} {item.patient.user.lastName}
          </BlackText>
        </UserBlock>
        <InfoBlock>
          <BlackText>{TimeUtil.getFormatted(date, DATE_OF_BIRTHDAY)}</BlackText>
          <GrayText>
            {TimeUtil.getFormatted(date, TIME_WITH_TWENTY_FOUR_HOURS)}
          </GrayText>
        </InfoBlock>
        <InfoBlock>
          <ReasonPreview>
            {reasonText}{' '}
            {isReasonLong && (
              <ReasonButton
                onClick={() => setIsShowFullReason(!isShowFullReason)}>
                Show more
              </ReasonButton>
            )}
          </ReasonPreview>
        </InfoBlock>
        <InfoBlock>
          <MoreButton onClick={openTherapistProfile} theme="primary">
            More
          </MoreButton>
        </InfoBlock>
      </InfoWrapper>
      {isShowFullReason && (
        <FullReason>
          <FullReasonTitle>Reason for cancellation</FullReasonTitle>
          <GrayText>
            {item.reasonForCancellation}{' '}
            <ReasonButton onClick={() => setIsShowFullReason(false)}>
              Hide
            </ReasonButton>
          </GrayText>
        </FullReason>
      )}
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  margin-bottom: ${getSize(12)};
`;

const InfoWrapper = styled(RowTemplate)`
  height: ${getSize(64)};
  box-shadow: 0 0 ${getSize(30)} var(--blue-opacity2);
  border-radius: ${getSize(8)};
`;

const BlackText = styled.p`
  text-align: left;
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
`;

const GrayText = styled(BlackText)`
  color: var(--gray7);
`;

const Avatar = styled.img`
  width: ${getSize(40)};
  height: ${getSize(40)};
  border-radius: 100%;
  margin-right: ${getSize(8)};
`;

const UserBlock = styled.div`
  display: flex;
  align-items: center;

  &:first-child {
    padding-left: ${getSize(24)};
  }
`;

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:last-child {
    padding-right: ${getSize(24)};
  }
`;

const ReasonPreview = styled.div`
  padding: 0 ${getSize(20)};
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  text-align: center;
`;

const ReasonButton = styled.button`
  color: var(--purple4);
`;

const MoreButton = styled(Button)`
  min-height: ${getSize(20)};
  padding: ${getSize(5)} ${getSize(20)};
  font-weight: 400;
  max-width: ${getSize(70)};
`;

const FullReason = styled.div`
  padding: ${getSize(20)} ${getSize(20)};
  margin-top: ${getSize(2)};
  box-shadow: 0 0 ${getSize(30)} var(--blue-opacity2);
  border-radius: ${getSize(8)};
`;

const FullReasonTitle = styled.p`
  font-weight: 600;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  margin-bottom: ${getSize(8)};
`;

export default CancellationItem;
