import { TherapistFragment } from 'common/query/__generated__/get-therapists-by-request';
import { getSize } from 'lib/utils';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Button, CloseIcon } from 'ui';
import { EditTherapistForm, EditTherapistProfileForm } from './components';

interface EditTherapistModalProps {
  onCloseModal: () => void;
  therapist: TherapistFragment;
}

enum EditTherapistTabs {
  ACCOUNT = 'ACCOUNT',
  PERSONAL_INFO = 'PERSONAL_INFO',
}

const EditTherapistModal: FC<EditTherapistModalProps> = ({
  onCloseModal,
  therapist,
}) => {
  const [currentTab, setCurrentTab] = useState<EditTherapistTabs>(
    EditTherapistTabs.ACCOUNT,
  );

  const renderContent = {
    [EditTherapistTabs.ACCOUNT]: (
      <EditTherapistForm onCloseModal={onCloseModal} therapist={therapist} />
    ),
    [EditTherapistTabs.PERSONAL_INFO]: (
      <EditTherapistProfileForm
        onCloseModal={onCloseModal}
        therapist={therapist}
      />
    ),
  };

  return (
    <ShadowWrapper>
      <Content>
        <Header>
          <HeaderText>Therapist Details</HeaderText>
          <CloseIconStyled onClick={onCloseModal} />
        </Header>
        <TabsWrapper>
          <Tab
            onClick={() => setCurrentTab(EditTherapistTabs.ACCOUNT)}
            theme={
              currentTab === EditTherapistTabs.ACCOUNT
                ? 'buble-primary'
                : 'buble-secondary'
            }>
            Account
          </Tab>

          <Tab
            onClick={() => setCurrentTab(EditTherapistTabs.PERSONAL_INFO)}
            theme={
              currentTab === EditTherapistTabs.PERSONAL_INFO
                ? 'buble-primary'
                : 'buble-secondary'
            }>
            Personal Information
          </Tab>
        </TabsWrapper>

        {renderContent[currentTab]}
      </Content>
    </ShadowWrapper>
  );
};

const ShadowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.51);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const Content = styled.div`
  position: relative;
  width: ${getSize(700)};
  height: ${getSize(647)};
  background: #ffffff;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${getSize(22)} ${getSize(23)} ${getSize(19)} ${getSize(27)};
  border-bottom: 1px solid #f1f1f1;
`;
const HeaderText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: ${getSize(18)};

  color: #000000;
`;

const CloseIconStyled = styled(CloseIcon)`
  cursor: pointer;
`;

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 16px;
  margin-bottom: 10px;
`;

const Tab = styled(Button)`
  min-width: 200px;

  &:last-child {
    margin-left: 10px;
  }
`;

export default EditTherapistModal;
