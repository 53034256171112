import React, {
  CSSProperties,
  useState,
  useRef,
  KeyboardEvent,
  useCallback,
  useEffect,
} from 'react';
import styled, { CSSProp } from 'styled-components/macro';
import { EmojiData, Picker as EmojiPicker } from 'emoji-mart';
import { FieldRenderProps, useForm } from 'react-final-form';
import { isSafari } from 'react-device-detect';
import { getSize } from 'lib/utils';
import { useWindowClick } from 'hooks';
import { EmojiMartItem, getImage, pasteHtmlAtCaret } from './utils';
import { EmojiIcon } from 'ui';

export interface EmojiInputProps extends FieldRenderProps<string> {
  rootCSS?: CSSProp;
}

const PICKER_STYLE: CSSProperties = {
  position: 'absolute',
  bottom: `calc(100% + ${getSize(10)})`,
  width: getSize(338),
  right: 0,
};

function EmojiInput({
  rootCSS,
  input: { onChange, value: fieldValue },
}: EmojiInputProps) {
  const form = useForm();
  const inputRef = useRef<HTMLDivElement>(null);
  const cleanedTextRef = useRef('');

  const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true);
  const [isVisible, setIsVisible] = useState(false);

  useWindowClick(() => setIsVisible(false));

  function togglePlaceholderDisplay() {
    setIsPlaceholderVisible(!cleanedTextRef.current);
  }

  useEffect(() => {
    const input = inputRef.current;

    if (input && !fieldValue && cleanedTextRef.current) {
      cleanedTextRef.current = '';
      input.innerHTML = '';
      togglePlaceholderDisplay();
    }
  }, [fieldValue]);

  const emitChange = useCallback(() => {
    onChange(cleanedTextRef.current);
    togglePlaceholderDisplay();
  }, [onChange]);

  const replaceAllTextEmojiToString = useCallback(() => {
    function getTextContent() {
      let text = inputRef.current?.innerHTML || '';

      if (isSafari) {
        text = text.replace(new RegExp('</div>', 'g'), '');
      }

      return text
        .split(/<br>|<div>/)
        .join('\n')
        .trim();
    }

    const container = document.createElement('div');
    container.innerHTML = getTextContent();

    const images = Array.prototype.slice.call(
      container.querySelectorAll('img'),
    );

    images.forEach((image) => {
      container.innerHTML = container.innerHTML.replace(
        image.outerHTML,
        image.dataset.emoji,
      );
    });

    const text = container.innerText;

    cleanedTextRef.current = text;
    emitChange();
  }, [emitChange]);

  const handleEmojiSelect = useCallback(
    (emoji: EmojiData) => {
      const input = inputRef.current;

      if (input) {
        setIsVisible(false);

        input.focus();
        pasteHtmlAtCaret(getImage(emoji as EmojiMartItem));
        input.focus();
        replaceAllTextEmojiToString();
      }
    },
    [replaceAllTextEmojiToString],
  );

  const handleKeyPress = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();

        form.submit();
        e.currentTarget.innerHTML = '';
        replaceAllTextEmojiToString();
      }
    },
    [form, replaceAllTextEmojiToString],
  );

  function handleInput() {
    replaceAllTextEmojiToString();
    togglePlaceholderDisplay();
  }

  return (
    <Wrapper onClick={(e) => e.stopPropagation()} $CSS={rootCSS}>
      <EditableDiv
        ref={inputRef}
        contentEditable
        data-placeholder={isPlaceholderVisible ? 'Start typing' : undefined}
        onKeyDown={handleKeyPress}
        onKeyPress={handleKeyPress}
        onInput={handleInput}
      />

      <Button
        onClick={() => setIsVisible((prevValue) => !prevValue)}
        type="button">
        <EmojiIconStylized />
      </Button>

      {isVisible && (
        <EmojiPicker
          useButton
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore-next-line
          style={PICKER_STYLE}
          title="Emoji"
          onSelect={handleEmojiSelect}
          showPreview={false}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ $CSS?: CSSProp }>`
  flex-grow: 1;
  display: flex;
  position: relative;
  margin: 0 ${getSize(16)} 0 0;

  .emoji-mart-anchors {
    padding: 0 ${getSize(6)};
  }

  .emoji-mart-anchor {
    padding: ${getSize(12)} ${getSize(4)};
  }

  .emoji-mart-anchors svg,
  .emoji-mart-anchors img {
    height: ${getSize(18)};
    width: ${getSize(18)};
  }

  .emoji-mart-anchor-icon {
    display: flex;
    justify-content: center;
  }

  .emoji-mart-search {
    margin-top: ${getSize(6)};
    padding: 0 ${getSize(6)};

    input {
      font-size: ${getSize(16)};
      padding: ${getSize(5)} ${getSize(25)} ${getSize(6)} ${getSize(10)};
      border-radius: ${getSize(5)};
    }
  }

  .emoji-mart-search-icon {
    top: ${getSize(7)};
    right: ${getSize(11)};
    z-index: 2;
    padding: ${getSize(2)} ${getSize(5)} ${getSize(1)};
  }

  .emoji-mart-bar {
    border-top-left-radius: ${getSize(5)};
    border-top-right-radius: ${getSize(5)};
  }

  .emoji-mart-preview {
    display: none;
  }

  .emoji-mart-preview-emoji {
    left: ${getSize(12)};
  }

  .emoji-mart .emoji-mart-emoji {
    padding: ${getSize(6)};

    &.emoji-mart-emoji-native {
      span {
        width: ${getSize(24)} !important;
        height: ${getSize(24)} !important;
        font-size: ${getSize(24)} !important;
      }
    }

    span {
      font-size: ${getSize(38)} !important;
    }
  }

  .emoji-mart-scroll {
    height: ${getSize(270)};
    padding: 0 ${getSize(6)} ${getSize(6)} ${getSize(6)};
  }

  .emoji-mart-category-label span {
    padding: ${getSize(5)} ${getSize(6)};
    font-size: ${getSize(16)};
  }

  .emoji-mart-no-results-label {
    font-size: ${getSize(14)};
  }

  .emoji-mart-preview-data {
    left: ${getSize(68)};
    right: ${getSize(12)};
  }

  .emoji-mart-title-label {
    font-size: ${getSize(26)};
  }

  .emoji-mart-preview-skins {
    right: ${getSize(30)};
  }

  .emoji-mart-preview-name {
    font-size: ${getSize(14)};
  }

  .emoji-mart-preview-shortname {
    font-size: ${getSize(12)};
  }

  .emoji-mart-skin-swatches {
    padding: ${getSize(2)} 0;
    border-radius: ${getSize(12)};
  }

  .emoji-mart-skin-swatch.selected {
    width: ${getSize(16)};
    padding: 0 ${getSize(2)};

    ::after {
      width: ${getSize(4)};
      height: ${getSize(4)};
      margin: ${getSize(-2)} 0 0 ${getSize(-2)};
    }
  }

  .emoji-mart-skin {
    max-width: ${getSize(12)};
  }

  .emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch {
    width: ${getSize(16)};
    padding: 0 ${getSize(2)};
  }

  ${({ $CSS }) => $CSS}
`;

const EditableDiv = styled.div`
  flex-grow: 1;
  margin: 0 ${getSize(16)} 0 0;
  padding: 0;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--black3);
  resize: none;
  max-height: ${getSize(120)};
  word-break: break-word;
  overflow: auto;

  &::placeholder {
    color: var(--gray7);
  }

  &::before {
    content: attr(data-placeholder);
    font-weight: 400;
    font-size: ${getSize(12)};
    line-height: ${getSize(24)};
    color: var(--gray7);
  }

  .emoji {
    width: ${getSize(24)} !important;
    height: ${getSize(24)} !important;
    vertical-align: bottom;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${getSize(28)};
  height: ${getSize(28)};
  background: var(--purple5);
  border-radius: 50%;
  transition: 0.3s ease-out;
  cursor: pointer;

  &:hover {
    background: var(--purple9);
  }
`;

const EmojiIconStylized = styled(EmojiIcon)`
  width: ${getSize(14)};
  height: ${getSize(14)};
`;

export default EmojiInput;
