import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AdminAddCompanyVariables = Types.Exact<{
  input: Types.AddCompanyInput;
}>;


export type AdminAddCompany = (
  { __typename: 'Mutation' }
  & { adminAddCompany: (
    { __typename: 'AdminCompanyPayload' }
    & { company?: Types.Maybe<(
      { __typename: 'CompanyModel' }
      & Pick<Types.CompanyModel, 'id' | 'companyKey'>
    )>, userErrors: Array<(
      { __typename: 'ValidationError' }
      & Pick<Types.ValidationError, 'field' | 'messages'>
    )> }
  ) }
);


export const AdminAddCompanyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AdminAddCompany"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddCompanyInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminAddCompany"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"company"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"companyKey"}}]}},{"kind":"Field","name":{"kind":"Name","value":"userErrors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"field"}},{"kind":"Field","name":{"kind":"Name","value":"messages"}}]}}]}}]}}]} as unknown as DocumentNode;
export type AdminAddCompanyMutationFn = Apollo.MutationFunction<AdminAddCompany, AdminAddCompanyVariables>;

/**
 * __useAdminAddCompany__
 *
 * To run a mutation, you first call `useAdminAddCompany` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAddCompany` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAddCompany, { data, loading, error }] = useAdminAddCompany({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminAddCompany(baseOptions?: Apollo.MutationHookOptions<AdminAddCompany, AdminAddCompanyVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminAddCompany, AdminAddCompanyVariables>(AdminAddCompanyDocument, options);
      }
export type AdminAddCompanyHookResult = ReturnType<typeof useAdminAddCompany>;
export type AdminAddCompanyMutationResult = Apollo.MutationResult<AdminAddCompany>;
export type AdminAddCompanyMutationOptions = Apollo.BaseMutationOptions<AdminAddCompany, AdminAddCompanyVariables>;