import * as React from 'react';

function CircleCheckIcon({ ...rest }) {
  return (
    <svg
      width={22}
      height={22}
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      fill="#79D0BB"
      {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.33 3.526a8.182 8.182 0 104.852 7.479v-.836a.91.91 0 011.818 0v.836a10 10 0 11-5.93-9.14.91.91 0 01-.74 1.661z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.726 3.268a.904.904 0 01.001 1.295l-9.333 9.168a.942.942 0 01-1.32 0l-2.8-2.747a.904.904 0 010-1.295.946.946 0 011.32 0l2.139 2.1 8.673-8.52a.946.946 0 011.32-.001z"
      />
    </svg>
  );
}

export default CircleCheckIcon;
