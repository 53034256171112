import { ISO_DATE_FORMAT, TimeUtil } from 'lib/utils';
import { useMemo, useState } from 'react';
import { DatesRange } from 'ui/drop-down-calendar/utils/add-day-to-range';

export const useDropDownCalendar = () => {
  const [selectedDaysRange, setSelectedDaysRange] = useState<DatesRange>({
    from: null,
    to: null,
  });

  const selectedDays = useMemo(() => {
    const from = selectedDaysRange.from;
    const to = selectedDaysRange.to;

    if (from && to) {
      return [from, to];
    } else if (from) {
      return from;
    } else if (to) {
      return to;
    }
  }, [selectedDaysRange]);

  const calendarFilterTitle = useMemo(() => {
    const { from, to } = selectedDaysRange;

    const filterStart =
      from &&
      TimeUtil.parse(from.toISOString(), ISO_DATE_FORMAT).format('MMM D');
    const filterEnd =
      to && TimeUtil.parse(to.toISOString(), ISO_DATE_FORMAT).format('MMM D');

    return filterStart || filterEnd
      ? `${filterStart}${filterEnd ? ` - ${filterEnd}` : ''}`
      : 'Filter by dates';
  }, [selectedDaysRange]);

  return {
    calendarFilterTitle,
    setSelectedDaysRange,
    selectedDays,
    selectedDaysRange,
  };
};
