import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetAllResourcesVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllResources = (
  { __typename: 'Query' }
  & { getAllPosts: Array<(
    { __typename: 'PostDetailsModel' }
    & Pick<Types.PostDetailsModel, 'id' | 'title' | 'subtitle' | 'postText' | 'videoUrl' | 'imageUrl' | 'author' | 'type'>
    & { subCategory?: Types.Maybe<(
      { __typename: 'ResourcesSubcategoryDetailsModel' }
      & Pick<Types.ResourcesSubcategoryDetailsModel, 'id' | 'name'>
    )> }
  )> }
);


export const GetAllResourcesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetAllResources"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getAllPosts"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"subtitle"}},{"kind":"Field","name":{"kind":"Name","value":"postText"}},{"kind":"Field","name":{"kind":"Name","value":"videoUrl"}},{"kind":"Field","name":{"kind":"Name","value":"imageUrl"}},{"kind":"Field","name":{"kind":"Name","value":"author"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"subCategory"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetAllResources__
 *
 * To run a query within a React component, call `useGetAllResources` and pass it any options that fit your needs.
 * When your component renders, `useGetAllResources` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllResources({
 *   variables: {
 *   },
 * });
 */
export function useGetAllResources(baseOptions?: Apollo.QueryHookOptions<GetAllResources, GetAllResourcesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllResources, GetAllResourcesVariables>(GetAllResourcesDocument, options);
      }
export function useGetAllResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllResources, GetAllResourcesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllResources, GetAllResourcesVariables>(GetAllResourcesDocument, options);
        }
export type GetAllResourcesHookResult = ReturnType<typeof useGetAllResources>;
export type GetAllResourcesLazyQueryHookResult = ReturnType<typeof useGetAllResourcesLazyQuery>;
export type GetAllResourcesQueryResult = Apollo.QueryResult<GetAllResources, GetAllResourcesVariables>;