import { useDebounceCallback } from '@react-hook/debounce';
import { getSize } from 'lib/utils';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import { Loader, Paper } from 'ui';
import { DropDownCalendar } from 'ui/drop-down-calendar';
import { DatesRange } from 'ui/drop-down-calendar/utils/add-day-to-range';
import { RowTemplate, VoucherCard } from './components';
import { useVouchersList } from './hooks';
import { useDropDownCalendar } from '../../hooks/use-drop-down-calendar';
import { VoucherStatus } from '__generated__/types';

const VouchersPage = () => {
  const { hasMore, loading, onLoadMore, vouchers, onSearch } =
    useVouchersList();

  const handleSearchMessageFormSubmit = (search?: string) => {
    onSearch(search);
  };

  const {
    calendarFilterTitle,
    setSelectedDaysRange,
    selectedDays,
    selectedDaysRange,
  } = useDropDownCalendar();

  const debounceHandleSearchFormSubmit = useDebounceCallback(
    handleSearchMessageFormSubmit,
    250,
  );

  const onDayClickRange = (values: DatesRange) => {
    setSelectedDaysRange(values);
  };

  return (
    <Paper searchRequest={debounceHandleSearchFormSubmit} title="Voucher">
      <Root>
        <Label>Vouchers used</Label>
        <HeaderWrapper>
          <UsedCount>6</UsedCount>

          <DropDownCalendar
            title={calendarFilterTitle}
            selectedRange={selectedDaysRange}
            selectedDays={selectedDays}
            onDayClickRange={onDayClickRange}
            isRange
          />
        </HeaderWrapper>

        <TableHeader>
          <TableHeaderText>Voucher Id</TableHeaderText>

          <TableHeaderText>Clients</TableHeaderText>

          <TableHeaderText>Therapist</TableHeaderText>

          <TableHeaderText>Paid</TableHeaderText>

          <TableHeaderText>Type</TableHeaderText>

          <TableHeaderText>Duration</TableHeaderText>

          <TableHeaderText>Redeemed</TableHeaderText>
        </TableHeader>

        {loading ? (
          <Loader hasFillWholeBlock size={50} />
        ) : (
          <List
            dataLength={vouchers?.length || 0}
            next={onLoadMore}
            hasMore={hasMore}
            scrollableTarget="scrollableChatsList"
            loader={<Loader />}>
            {vouchers?.map((voucher) => (
              <VoucherCard
                key={voucher.id}
                voucherId={voucher.id}
                clientName={voucher.patientName}
                therapistName={voucher.therapistName}
                type={voucher.sessionType}
                duration={voucher.sessionDuration.toString()}
                redeemed={voucher.usedAt}
                paid={voucher.status === VoucherStatus.Used}
              />
            ))}
          </List>
        )}
      </Root>
    </Paper>
  );
};

const Label = styled.p`
  font-size: ${getSize(13)};
  font-weight: 500;
  line-height: ${getSize(24)};

  color: var(--gray8);
`;

const Root = styled.div`
  margin: ${getSize(12)} ${getSize(40)};
`;

const UsedCount = styled.p`
  font-size: ${getSize(22)};
  font-weight: 600;
  line-height: ${getSize(32)};

  margin-top: 4px;
`;

const TableHeader = styled(RowTemplate)`
  margin-top: 39px;
  background-color: var(--purple11);
`;

const TableHeaderText = styled.button`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  text-align: center;
  padding: 0 ${getSize(18)};
  color: var(--gray7);
  display: flex;
  align-items: center;

  &:first-child {
    padding-left: ${getSize(24)};
    text-align: left;
  }
`;

const List = styled(InfiniteScroll)`
  margin: ${getSize(-20)} ${getSize(-40)};
  padding: ${getSize(20)} ${getSize(40)};
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default VouchersPage;
