import { OnSubscriptionDataOptions } from '@apollo/client';
import { OverallUnreadCountChanged } from './../subscription/__generated__/overall-unread-count-changed';

export function updateOverallUnreadCountCache({
  client: { cache },
  subscriptionData: { data },
}: OnSubscriptionDataOptions<OverallUnreadCountChanged>) {
  const newCount = data?.overallUnreadCountChanged;
  if (!Number.isInteger(newCount)) return;

  cache.modify({
    fields: {
      overallUnreadCount: () => (newCount ? newCount : 0),
    },
  });
}
