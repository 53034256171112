import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type BanPatientByIdVariables = Types.Exact<{
  input: Types.PatientRemoveInput;
}>;


export type BanPatientById = (
  { __typename: 'Mutation' }
  & Pick<Types.Mutation, 'adminBanPatientById'>
);


export const BanPatientByIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"BanPatientById"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PatientRemoveInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminBanPatientById"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type BanPatientByIdMutationFn = Apollo.MutationFunction<BanPatientById, BanPatientByIdVariables>;

/**
 * __useBanPatientById__
 *
 * To run a mutation, you first call `useBanPatientById` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBanPatientById` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [banPatientById, { data, loading, error }] = useBanPatientById({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBanPatientById(baseOptions?: Apollo.MutationHookOptions<BanPatientById, BanPatientByIdVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BanPatientById, BanPatientByIdVariables>(BanPatientByIdDocument, options);
      }
export type BanPatientByIdHookResult = ReturnType<typeof useBanPatientById>;
export type BanPatientByIdMutationResult = Apollo.MutationResult<BanPatientById>;
export type BanPatientByIdMutationOptions = Apollo.BaseMutationOptions<BanPatientById, BanPatientByIdVariables>;