import lodashSet from 'lodash.set';
import { UserError } from '__generated__/types';
import { Option } from 'ui/drop-down-filter/drop-down-filter';

export function getFormSubmitError(errors: UserError[]) {
  return errors.reduce(
    (acc: Record<string, string[]>, currentError) =>
      lodashSet(acc, currentError.field, currentError.messages),
    {},
  );
}

export function getStringFromEnum(enumString?: string | null) {
  return enumString
    ? enumString[0].toUpperCase() +
        enumString.slice(1).toLowerCase().split('_').join(' ')
    : '';
}

function getStringFromEnumCapitalizeEveryWord(enumString?: string | null) {
  return enumString
    ? enumString
        .toLowerCase()
        .split('_')
        .map((item) => item[0].toUpperCase() + item.slice(1))
        .join(' ')
    : '';
}

export function transformEnumToOptions(
  enums: {
    [key: string]: string | number;
  },
  capitalizeEveryWord?: boolean,
): Option[] {
  return (
    Object.values(enums).map((value) => {
      return {
        value: String(value),
        label:
          typeof value === 'string'
            ? capitalizeEveryWord
              ? getStringFromEnumCapitalizeEveryWord(value)
              : getStringFromEnum(value)
            : String(value),
      };
    }) || []
  );
}
