import React, { useMemo } from 'react';
import styled, { CSSProp } from 'styled-components';
import { getSize, ISO_DATE_FORMAT, TimeUtil } from 'lib/utils';
import { ShadowWrapper } from 'ui/shadow-wrapper';
import { Button } from 'ui/button';
import { CrossIcon, PopUpMessage, TrashIcon } from 'ui';
import { UserShortInfo } from '../user-short-info';
import { PatientFragment } from 'common/query/__generated__/get-patients-by-request';
import { DEFAULT_AVATAR } from 'lib/constants/therapist';
import useCopyToClipboard from 'ui/hooks/use-copy-to-clipboard';
import { toast } from 'react-toastify';
import { PauseIcon } from '../../ui/icons/pause-icon';
import { PlayIcon } from '../../ui/icons/play-icon';
import { CheckIcon } from '../../ui/icons/check-icon';

export interface PatientCardProps {
  patient: PatientFragment;
  modalAction: (id: string, isBan: boolean) => void;
  onUnbanPatient: () => void;
}

function PatientCard({
  patient,
  modalAction,
  onUnbanPatient,
}: PatientCardProps) {
  const [value, copy] = useCopyToClipboard();
  const {
    id,
    user: { lastName, firstName, avatar, email },
    createdAt,
    deletedAt,
  } = patient;

  const firstSessionDateUtc = patient.user.patient?.firstSessionDateUts;

  const registrationDate = useMemo(
    () => TimeUtil.parse(createdAt, ISO_DATE_FORMAT).format('DD.MM.YYYY'),
    [createdAt],
  );

  const firstSessionDate = useMemo(
    () =>
      firstSessionDateUtc
        ? TimeUtil.parse(firstSessionDateUtc, ISO_DATE_FORMAT).format(
            'DD.MM.YYYY',
          )
        : '-',
    [firstSessionDateUtc],
  );

  const therapistUpcomingSessionName = patient.upcomingTherapistName;
  const therapistPastSessionName = patient.lastTherapistName;
  console.log(patient.lastTherapistName);
  const upcomingSessionDateUtc = patient.upcomingSessionDate;
  const previousSessionDateUtc = patient.previousSession?.startDateUtc;
  const lastSessionRequestStatus = patient.lastSessionRequestStatus;

  const upcomingSessionDate = useMemo(
    () =>
      upcomingSessionDateUtc
        ? TimeUtil.parse(upcomingSessionDateUtc, ISO_DATE_FORMAT).format(
            'DD.MM.YYYY hh:mm a',
          )
        : '-',
    [upcomingSessionDateUtc],
  );

  const previousSessionDate = useMemo(
    () =>
      previousSessionDateUtc
        ? TimeUtil.parse(previousSessionDateUtc, ISO_DATE_FORMAT).format(
            'DD.MM.YYYY hh:mm a',
          )
        : '-',
    [previousSessionDateUtc],
  );

  const isBaned = Boolean(deletedAt);
  const isDeletedPatient = !firstName && !lastName;

  const onCopyClick = (text?: string) => {
    text && copy(text);
    toast('email copied');
  };

  const displayLastRequestStatusIcon = () => {
    console.log(lastSessionRequestStatus);
    if (!lastSessionRequestStatus || lastSessionRequestStatus === 'created') {
      return null;
    }

    if (lastSessionRequestStatus === 'accepted') {
      return <CheckIcon width={16} height={16} />;
    }
  };

  return (
    <Wrapper>
      <InfoBlock $CSS={{ width: getSize(80) }}>
        <Text>{`${firstName} ${lastName}`}</Text>
      </InfoBlock>
      <InfoBlock $CSS={{ width: getSize(150) }}>
        <Email onClick={() => onCopyClick(email)}>{email}</Email>
      </InfoBlock>
      <InfoBlock $CSS={{ width: getSize(75) }}>
        <Text>{registrationDate}</Text>
      </InfoBlock>
      <InfoBlock $CSS={{ width: getSize(75) }}>
        <Text>{firstSessionDate}</Text>
      </InfoBlock>
      <InfoBlock $CSS={{ width: getSize(100) }}>
        <Text>
          {upcomingSessionDate === '-'
            ? therapistPastSessionName
            : therapistUpcomingSessionName}
        </Text>
      </InfoBlock>
      <InfoBlock $CSS={{ width: getSize(85), paddingLeft: getSize(10) }}>
        <Text>{previousSessionDate}</Text>
      </InfoBlock>
      <InfoBlock $CSS={{ width: getSize(80), paddingLeft: getSize(10) }}>
        <Text>{upcomingSessionDate}</Text>
      </InfoBlock>
      <InfoBlock $CSS={{ width: getSize(35) }}>
        <Text>{displayLastRequestStatusIcon()}</Text>
      </InfoBlock>
      {!isDeletedPatient && (
        <ButtonsWrapper>
          {isBaned ? (
            <UnbanButton theme="secondary" onClick={onUnbanPatient}>
              <PlayIcon />
            </UnbanButton>
          ) : (
            <BanUserButton
              theme="secondary"
              onClick={() => modalAction(id, true)}>
              <PauseIcon />
            </BanUserButton>
          )}
          <RemoveBaseButton
            theme="secondary"
            onClick={() => modalAction(id, false)}>
            <TrashIcon />
          </RemoveBaseButton>
        </ButtonsWrapper>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(ShadowWrapper)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: ${getSize(12)} ${getSize(24)};
`;
const MailBlock = styled.div`
  position: relative;
`;

const MailText = styled.button<{ $color?: string; $width?: number }>`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--gray7);
  width: ${({ $width }) => ($width ? getSize($width) : '100%')};
  min-width: ${({ $width }) => ($width ? getSize($width) : 'auto')};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;

  &:hover ~ {
    .tooltip-message {
      display: block;
      animation: showTooltip 0.3s ease-out forwards;
      white-space: nowrap;
    }
  }
`;

const DateText = styled(MailText)`
  text-align: center;
  white-space: pre-wrap;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: ${getSize(10)};
`;

const RemoveBaseButton = styled(Button)`
  flex-shrink: 0;
  min-height: auto;
  font-weight: 500;
  font-size: 10px;
`;

const BanUserButton = styled(Button)`
  margin-right: ${getSize(10)};
  flex-shrink: 0;
  font-size: 10px;
`;

const UnbanButton = styled(BanUserButton)`
  color: var(--black3);
`;

const InfoBlock = styled.div<{ $CSS?: CSSProp }>`
  display: flex;
  flex-direction: column;
  margin: 0 ${getSize(16)} 0 0;
  ${({ $CSS }) => $CSS}
`;

const Text = styled.p<{ $width?: number }>`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--black3);
  width: ${({ $width }) => ($width ? getSize($width) : '100%')};
  overflow-wrap: break-word;
`;
const Email = styled.p<{ $width?: number }>`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--black3);
  width: ${({ $width }) => ($width ? getSize($width) : '100%')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default PatientCard;
