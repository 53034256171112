import React from 'react';
import styled, { CSSProp } from 'styled-components';
import { getSize } from 'lib/utils';

interface TableTitlesProps {
  firstColumnName: string;
  secondColumnName: string;
  thirdColumnName: string;
  fourthColumnName?: string;
  fifthColumnName?: string;
  sixthColumnName?: string;
  seventhColumnName?: string;
  firstColumnCSS?: CSSProp;
  secondColumnCSS?: CSSProp;
  thirdColumnCSS?: CSSProp;
  fourthColumnCSS?: CSSProp;
  fifthColumnCSS?: CSSProp;
  sixthColumnCSS?: CSSProp;
  seventhColumnCSS?: CSSProp;
  wrapperCSS?: CSSProp;
}

function TableTitles({
  firstColumnName,
  secondColumnName,
  thirdColumnName,
  fourthColumnName,
  fifthColumnName,
  sixthColumnName,
  seventhColumnName,
  firstColumnCSS,
  secondColumnCSS,
  thirdColumnCSS,
  fourthColumnCSS,
  fifthColumnCSS,
  sixthColumnCSS,
  seventhColumnCSS,
  wrapperCSS,
}: TableTitlesProps) {
  return (
    <Wrapper $wrapperCSS={wrapperCSS}>
      <FirstColumn $firstColumnCSS={firstColumnCSS}>
        {firstColumnName}
      </FirstColumn>
      <SecondColumn $secondColumnCSS={secondColumnCSS}>
        {secondColumnName}
      </SecondColumn>
      <ThirdColumn $thirdColumnCSS={thirdColumnCSS}>
        {thirdColumnName}
      </ThirdColumn>
      {fourthColumnName && (
        <FourthColumn $fourthColumnCSS={fourthColumnCSS}>
          {fourthColumnName}
        </FourthColumn>
      )}
      {fifthColumnName && (
        <FifthColumn $fifthColumnCss={fifthColumnCSS}>
          {fifthColumnName}
        </FifthColumn>
      )}
      {sixthColumnName && (
        <SixthColumn $sixthColumnCss={sixthColumnCSS}>
          {sixthColumnName}
        </SixthColumn>
      )}
      {seventhColumnCSS && (
        <SeventhColumn $seventhColumnCss={seventhColumnCSS}>
          {seventhColumnName}
        </SeventhColumn>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ $wrapperCSS?: CSSProp }>`
  display: flex;
  background: var(--purple11);
  border-radius: ${getSize(8)};
  padding: ${getSize(17)} ${getSize(24)};
  margin: ${getSize(0)} ${getSize(40)};
  ${({ $wrapperCSS }) => $wrapperCSS}
`;

const FirstColumn = styled.p<{ $firstColumnCSS?: CSSProp }>`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--gray7);
  ${({ $firstColumnCSS }) => $firstColumnCSS}
`;

const SecondColumn = styled.p<{ $secondColumnCSS?: CSSProp }>`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--gray7);
  text-align: center;
  ${({ $secondColumnCSS }) => $secondColumnCSS}
`;

const ThirdColumn = styled.p<{ $thirdColumnCSS?: CSSProp }>`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--gray7);
  text-align: center;
  ${({ $thirdColumnCSS }) => $thirdColumnCSS}
`;

const FourthColumn = styled.p<{ $fourthColumnCSS?: CSSProp }>`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--gray7);
  text-align: center;
  ${({ $fourthColumnCSS }) => $fourthColumnCSS}
`;

const FifthColumn = styled.p<{ $fifthColumnCss?: CSSProp }>`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--gray7);
  text-align: center;
  ${({ $fifthColumnCss }) => $fifthColumnCss}
`;

const SixthColumn = styled.p<{ $sixthColumnCss?: CSSProp }>`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--gray7);
  text-align: center;
  ${({ $sixthColumnCss }) => $sixthColumnCss}
`;
const SeventhColumn = styled.p<{ $seventhColumnCss?: CSSProp }>`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--gray7);
  text-align: center;
  ${({ $seventhColumnCss }) => $seventhColumnCss}
`;

export default TableTitles;
