import { useDebounceCallback } from '@react-hook/debounce';
import { useState, useEffect } from 'react';
import { useOverallUnreadCount } from '../query/__generated__/overall-unread-count';
import { useOverallUnreadCountChanged } from '../subscription/__generated__/overall-unread-count-changed';
import { updateOverallUnreadCountCache } from './';

export function useUnreadCount() {
  const { data: overallUnreadCountData } = useOverallUnreadCount();
  const [unreadCount, setUnreadCount] = useState(0);

  const debounceSetUnreadCount = useDebounceCallback(setUnreadCount, 1300);

  useEffect(() => {
    const count = Number(overallUnreadCountData?.overallUnreadCount);

    if (count >= 0) {
      debounceSetUnreadCount(count);
    }
  }, [debounceSetUnreadCount, overallUnreadCountData?.overallUnreadCount]);

  useOverallUnreadCountChanged({
    onSubscriptionData: updateOverallUnreadCountCache,
  });

  return { unreadCount };
}
