import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import { Range } from 'react-range';
import styled from 'styled-components';

interface RangeInputProps {
  step?: number;
  min?: number;
  max?: number;
  values: number[];
  onChange: (value: number[]) => void;
}

const RangeInput: FC<RangeInputProps> = (props) => {
  return (
    <Wrapper>
      <Range
        {...props}
        renderTrack={({ props, children }) => {
          return <Track {...props}>{children}</Track>;
        }}
        renderThumb={({ props }) => {
          return <Thumb {...props} />;
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: ${getSize(16)};
`;

const Track = styled.div`
  height: ${getSize(6)};
  width: 100%;
  border-radius: ${getSize(8)};
  background-color: var(--purple2);
`;

const Thumb = styled.div`
  width: ${getSize(20)};
  height: ${getSize(20)};
  background-color: var(--purple);
  border-radius: 100%;
`;

export default RangeInput;
