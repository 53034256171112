import React from 'react';

function DownloadIconMini({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="#444752"
      {...rest}>
      <g clipPath="url(#prefix__clip0)" fillRule="evenodd" clipRule="evenodd">
        <path d="M13.185 8.648A.648.648 0 0112.537 8V2.815a.648.648 0 00-.648-.648H4.11a.648.648 0 00-.648.648V8a.648.648 0 01-1.296 0V2.815A1.944 1.944 0 014.11.871h7.778a1.944 1.944 0 011.944 1.944V8c0 .358-.29.648-.648.648zM8.458 14.94a.648.648 0 01-.917 0L4.95 12.346a.648.648 0 11.916-.917L8 13.564l2.134-2.134a.648.648 0 11.917.917l-2.593 2.592z" />
        <path d="M8 15.129a.648.648 0 01-.648-.648V6.055a.648.648 0 011.296 0v8.426c0 .358-.29.648-.648.648z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path
            fill="#fff"
            transform="rotate(-180 7.889 7.889)"
            d="M0 0h15.556v15.556H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DownloadIconMini;
