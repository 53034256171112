import { useEffect, useState } from 'react';
import { useAdminGetVouchersLazyQuery } from '../queries/__generated__/admin-get-vouchers';

const PAGE_SIZE = 10;

export const useVouchersList = () => {
  const [searchValue, setSearchValue] = useState<string>();

  const [getVouchers, { data, fetchMore, loading }] =
    useAdminGetVouchersLazyQuery();

  useEffect(() => {
    getVouchers({
      variables: {
        input: {
          page: 1,
          pageSize: PAGE_SIZE,
          search: searchValue,
        },
      },
    });
  }, [searchValue]);

  const response = data?.adminGetVouchers;

  const hasMore = (response?.page || 0) <= (response?.pages || 0);

  const onLoadMore = () => {
    if (hasMore) {
      fetchMore?.({
        variables: {
          page: (response?.page || 0) + 1,
        },
      });
    }
  };

  return {
    vouchers: response?.vouchers,
    hasMore: hasMore,
    onLoadMore,
    loading,
    onSearch: setSearchValue,
  };
};
