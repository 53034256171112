import * as Types from '../../../__generated__/types';

import { CancelledPatientFragment, CancelledTherapistFragment } from './get-cancelled-requests';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetCancelledSessionsVariables = Types.Exact<{
  first?: Types.Maybe<Types.Scalars['Int']>;
  after?: Types.Maybe<Types.Scalars['String']>;
  therapist?: Types.Maybe<Types.CancellationsFilter>;
}>;


export type GetCancelledSessions = (
  { __typename: 'Query' }
  & { adminCancelledSessions: (
    { __typename: 'CancelledSessionsConnection' }
    & { nodes: Array<(
      { __typename: 'CancelledSessionModel' }
      & CanceledSessionFragment
    )>, pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<Types.PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);

export type CanceledSessionFragment = (
  { __typename: 'CancelledSessionModel' }
  & Pick<Types.CancelledSessionModel, 'id' | 'sessionDate' | 'reasonForCancellation' | 'updatedAt' | 'createdAt'>
  & { patient: (
    { __typename: 'PatientModel' }
    & CancelledPatientFragment
  ), therapist: (
    { __typename: 'TherapistModel' }
    & CancelledTherapistFragment
  ) }
);

export const CanceledSessionFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CanceledSessionFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CancelledSessionModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"patient"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CancelledPatientFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"therapist"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CancelledTherapistFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"sessionDate"}},{"kind":"Field","name":{"kind":"Name","value":"reasonForCancellation"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}},...CancelledPatientFragment.definitions,...CancelledTherapistFragment.definitions]} as unknown as DocumentNode;
export const GetCancelledSessionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCancelledSessions"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"first"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"after"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"therapist"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"CancellationsFilter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminCancelledSessions"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"Variable","name":{"kind":"Name","value":"first"}}},{"kind":"Argument","name":{"kind":"Name","value":"after"},"value":{"kind":"Variable","name":{"kind":"Name","value":"after"}}},{"kind":"Argument","name":{"kind":"Name","value":"therapist"},"value":{"kind":"Variable","name":{"kind":"Name","value":"therapist"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"nodes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CanceledSessionFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"pageInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hasNextPage"}},{"kind":"Field","name":{"kind":"Name","value":"endCursor"}}]}}]}}]}},...CanceledSessionFragment.definitions]} as unknown as DocumentNode;

/**
 * __useGetCancelledSessions__
 *
 * To run a query within a React component, call `useGetCancelledSessions` and pass it any options that fit your needs.
 * When your component renders, `useGetCancelledSessions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCancelledSessions({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      therapist: // value for 'therapist'
 *   },
 * });
 */
export function useGetCancelledSessions(baseOptions?: Apollo.QueryHookOptions<GetCancelledSessions, GetCancelledSessionsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCancelledSessions, GetCancelledSessionsVariables>(GetCancelledSessionsDocument, options);
      }
export function useGetCancelledSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCancelledSessions, GetCancelledSessionsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCancelledSessions, GetCancelledSessionsVariables>(GetCancelledSessionsDocument, options);
        }
export type GetCancelledSessionsHookResult = ReturnType<typeof useGetCancelledSessions>;
export type GetCancelledSessionsLazyQueryHookResult = ReturnType<typeof useGetCancelledSessionsLazyQuery>;
export type GetCancelledSessionsQueryResult = Apollo.QueryResult<GetCancelledSessions, GetCancelledSessionsVariables>;