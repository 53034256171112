import React, { useMemo } from 'react';
import { NavbarElementProps } from 'react-day-picker';
import styled from 'styled-components';
import { Dayjs } from 'dayjs';
import getYears from 'lib/utils/date';

import { transformStringsToOptions } from 'lib/utils';
import { DateSelect } from './components/date-select';

interface DateOfBirthNavbarProps extends NavbarElementProps {
  isDateOfBirth?: boolean;
  getMonth: (value: any) => void;
  getYear: (value: any) => void;
  date: Dayjs;
}

const years = getYears(1920);

function DateOfBirthNavbar({
  date,
  localeUtils,
  getMonth,
  getYear,
}: DateOfBirthNavbarProps) {
  const monthsOptions = useMemo(
    () =>
      localeUtils
        .getMonths()
        .map((month, index) => ({ value: String(index), label: month })),
    [localeUtils],
  );

  return (
    <Root onClick={(e) => e.preventDefault()}>
      <div>
        <DateSelect
          onChange={getMonth}
          value={String(date.month())}
          options={monthsOptions}
        />
      </div>

      <DateSelect
        onChange={getYear}
        value={String(date.year())}
        options={transformStringsToOptions(years)}
      />
    </Root>
  );
}

const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  align-items: center;
  padding: 1em 1.3em 0;
`;

export default DateOfBirthNavbar;
