import React, { useEffect } from 'react';
import styled, { CSSProp } from 'styled-components';
import { FieldRenderProps } from 'react-final-form';
import { getSize } from 'lib/utils';

export interface BubbleRadioButtonProps extends FieldRenderProps<string> {
  label: string;
  radioRootCSS: CSSProp;
  errorMessage: string;
  setErrorMessage: (hasError: string) => void;
}

function BubbleRadioButton({
  label,
  meta,
  input,
  radioRootCSS,
  errorMessage,
  setErrorMessage,
}: BubbleRadioButtonProps): JSX.Element {
  useEffect(() => {
    const { error, submitError, touched, dirtySinceLastSubmit, data } = meta;
    const hasError =
      ((error || submitError) && touched && !dirtySinceLastSubmit) ||
      data?.error;

    if (!errorMessage && hasError) {
      const newErrorMessage = error || submitError || data?.error;
      setErrorMessage(newErrorMessage);
    } else if (errorMessage && !hasError) {
      setErrorMessage('');
    }
  }, [errorMessage, meta, setErrorMessage]);

  const isActive = input.checked;

  return (
    <Wrapper $CSS={radioRootCSS} $isActive={isActive}>
      <Input
        name={input.name}
        type="radio"
        onChange={input.onChange}
        checked={isActive}
        value={input.value}
      />
      {label}
    </Wrapper>
  );
}

const Wrapper = styled.label<{ $isActive?: boolean; $CSS: CSSProp }>`
  padding: ${getSize(6)} ${getSize(20)};
  text-align: center;
  font-weight: 400;
  font-size: ${getSize(14)};
  line-height: ${getSize(24)};
  cursor: pointer;
  border: ${getSize(1)} solid var(--purple3);
  border-radius: ${getSize(10)};
  transition: 0.2s ease-in-out;
  background: var(${({ $isActive }) => ($isActive ? '--purple3' : '--white')});

  &:hover {
    background: var(--purple3);
    border-color: var(--purple3);
  }

  ${({ $CSS }) => $CSS}
`;

const Input = styled.input`
  display: none;
`;

export default BubbleRadioButton;
