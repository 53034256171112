import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { getSize } from 'lib/utils';
import { Button } from 'ui/button';
import { ShadowWrapper } from 'ui/shadow-wrapper';
import { dashboardRoutes } from 'routes/root-routes';
import { TherapistFragment } from 'common/query/__generated__/get-therapists-by-request';
import { DEFAULT_AVATAR } from 'lib/constants/therapist';
import { UserShortInfo } from 'components/user-short-info';
import RowTemplate from '../row-template';
import { PopUpMessage, RedFlagMarker } from 'ui';
import { getRoundedValue } from 'components/therapists-list/utils/rounding-helper';
import { FeedbackRowFragment } from 'common/query/__generated__/get-users-feedback';
import { CopyIcon } from 'ui';
import { AnyAaaaRecord } from 'dns';
import useCopyToClipboard from 'ui/hooks/use-copy-to-clipboard';
import { toast } from 'react-toastify';
import { adminGetAllCompaniesFragment } from 'common/query/__generated__/admin-get-all-companies';
import {
  insuranceCompaniesFragment,
  useGetInsuranceCompanies,
} from 'common/query/__generated__/get-all-insurance-companies';
import { format } from 'date-fns';

export interface CompanyCardProps {
  company: adminGetAllCompaniesFragment;
  onOpenModal: () => void;
  setCompanyId: React.Dispatch<React.SetStateAction<string>>;
  insuranceCompanies?: insuranceCompaniesFragment[];
  openReportModal: () => void;
}

function CompanyCard({
  company,
  onOpenModal,
  setCompanyId,
  insuranceCompanies,
  openReportModal,
}: CompanyCardProps) {
  const history = useHistory();
  const [value, copy] = useCopyToClipboard();
  const [showIdModal, setShowIdModal] = useState(false);

  const refIdModal = useRef(null);

  const returnSeparateAndLowerCaseString = (str: string) => {
    if (!str[0]) return;
    return (
      str[0].toUpperCase() + str.slice(1).split('_').join(' ').toLowerCase()
    );
  };

  useEffect(() => {
    const onClick = (e: any) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      refIdModal?.current?.contains(e.target) || setShowIdModal(false);
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);

  // const onCopyClick = (text?: string) => {
  //   copy(patientId);
  //   toast('id copied');
  //   setShowIdModal(false);
  // };

  const {
    id,
    name,
    companyKey,
    createdAt,
    totalEmployees,
    employeesRegistered,
    utilization,
    avgSessionsPerPatient,
    sessionsBooked,
    currentPlan,
    nextPlan,
  } = company;

  const companyPlan = currentPlan?.planType || '';
  const insuranceCompanyId = currentPlan?.insuranceCompanyId;

  const onDetailClick = () => {
    setCompanyId(id);
    onOpenModal();
  };

  const onReportClick = () => {
    setCompanyId(id);
    openReportModal();
  };

  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    document.addEventListener('mouseout', function (event) {
      setCursorPosition({ x: event.clientX, y: event.clientY });
    });

    return document.removeEventListener('mouseout', function (event) {
      setCursorPosition({ x: event.clientX, y: event.clientY });
    });
  }, []);

  const findCompanyById = (id: string) => {
    let result;
    if (!insuranceCompanies) return;
    for (const company of insuranceCompanies) {
      if (company.id === id) {
        result = company;
      }
    }
    return result;
  };

  return (
    <Wrapper>
      <InfoBlock>
        <Text>{name}</Text>
      </InfoBlock>
      <InfoBlock>
        <Text>{format(new Date(createdAt), 'DD.MM.YYYY')}</Text>
      </InfoBlock>
      <InfoBlock>
        <Text>
          {insuranceCompanyId && findCompanyById(insuranceCompanyId) && (
            <ImgContainer>
              <ImgStyled
                src={findCompanyById(insuranceCompanyId)?.logoUrl}
                alt={findCompanyById(insuranceCompanyId)?.label}
              />
              <InsurancePopup $x={cursorPosition.x} $y={cursorPosition.y}>
                <InsuranceHeader>
                  {findCompanyById(insuranceCompanyId)?.label}
                </InsuranceHeader>
                <InsuranceImg
                  src={findCompanyById(insuranceCompanyId)?.logoUrl}
                  alt={findCompanyById(insuranceCompanyId)?.label}
                />
              </InsurancePopup>
            </ImgContainer>
          )}
          {returnSeparateAndLowerCaseString(companyPlan)}
        </Text>
      </InfoBlock>
      <InfoBlock>
        <Text>Current: {currentPlan?.isActive ? 'Yes' : 'No'}</Text>
        <Text>Next: {nextPlan?.isActive ? 'Yes' : 'No'}</Text>
      </InfoBlock>
      <InfoBlock>
        <Text>Current: {currentPlan?.employeeLimit}</Text>
        <Text>Next: {nextPlan?.employeeLimit}</Text>
      </InfoBlock>
      <InfoBlock>
        <Text>{totalEmployees}</Text>
      </InfoBlock>
      <InfoBlock>
        <Text>{employeesRegistered}</Text>
      </InfoBlock>
      <InfoBlock>
        <Text>{utilization}</Text>
      </InfoBlock>
      <InfoBlock>
        <Text>{avgSessionsPerPatient?.toFixed(1)}</Text>
      </InfoBlock>
      <InfoBlock>
        <Text>{sessionsBooked}</Text>
      </InfoBlock>
      <InfoBlock>
        <ReportButton onClick={onReportClick}>Report</ReportButton>
        <DetailsButton onClick={onDetailClick}>Details</DetailsButton>
      </InfoBlock>
    </Wrapper>
  );
}

const gridTemplate = css`
  grid-template-columns: 12% 12% 12% 9% 9% 9% 7% 6% 6% 9% 9%;
`;

const Wrapper = styled(RowTemplate)`
  height: ${getSize(64)};
  box-shadow: 0 0 ${getSize(30)} var(--blue-opacity2);
  border-radius: ${getSize(8)};
  margin-bottom: ${getSize(12)};

  ${gridTemplate}
`;

const StyledCopyIcon = styled(CopyIcon)`
  cursor: pointer;
  margin-left: 15px;
`;

const InfoBlock = styled.div`
  display: inline;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-left: ${getSize(25)};
  justify-content: flex-start;
`;

const Text = styled.p`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--black3);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
`;

const TextWithModal = styled(Text)`
  cursor: pointer;
`;

const TextModal = styled.div`
  position: absolute;
  fons-size: 14px;
  white-space: nowrap;
  background: #ffffff;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 15px;
  top: -15px;
  z-index: 2;
  display: flex;
`;

const AcceptingOfRequest = styled.div`
  position: relative;
  cursor: pointer;

  &:hover {
    .tooltip-message {
      display: block;
      white-space: nowrap;
      animation: showTooltip 0.3s ease-out forwards;
    }
  }
`;

const MoreButton = styled(Button)`
  min-height: ${getSize(20)};
  padding: ${getSize(5)} ${getSize(20)};
  font-weight: 400;
  max-width: ${getSize(70)};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface BubbleProps {
  $isActive?: boolean;
}

const Bubble = styled.div<BubbleProps>`
  width: 8px;
  height: 8px;
  margin-right: 18px;
  border-radius: 50%;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
  background: #02d26e;
`;

const DetailsButton = styled.button`
  background: #7a60e9;
  border-radius: 8px;
  padding: 5px 12px;
  color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
`;

const ReportButton = styled(DetailsButton)`
  background: #ffffff;
  border: 1px solid #f0edfd;
  color: #444752;
  margin-bottom: 2px;
`;

const ImgStyled = styled.img`
  max-width: 60px;
  max-height: 30px;
  margin: 0 auto;
`;

interface InsurancePopupProps {
  $x: number;
  $y: number;
}
const InsurancePopup = styled.div<InsurancePopupProps>`
  position: fixed;
  display: none;
  top: ${({ $y }) => `calc(${$y}px + 10px)`};
  left: ${({ $x }) => `calc(${$x}px + 10px)`};
  padding: 15px;

  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  transition: all 0.1s;
  z-index: 9;
`;

const ImgContainer = styled.div`
  position: relative;

  &:hover ${InsurancePopup} {
    display: flex;
  }
`;
const InsuranceHeader = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;

  color: #444752;
`;
const InsuranceImg = styled.img``;

export default CompanyCard;
