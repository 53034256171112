import { ISO_DATE_FORMAT, TimeUtil } from 'lib/utils';

export interface DatesRange {
  from: Date | null;
  to: Date | null;
}

interface AddDayToRangeProps {
  day: Date;
  range?: DatesRange;
}

export function addDayToRange({ day, range }: AddDayToRangeProps) {
  const fromDate =
    range &&
    range.from &&
    TimeUtil.parse(range.from.toISOString(), ISO_DATE_FORMAT);
  const toDate =
    range &&
    range.to &&
    TimeUtil.parse(range.to.toISOString(), ISO_DATE_FORMAT);

  let from: Date | null = null;
  let to: Date | null = null;

  if (!fromDate) {
    from = day;
  } else if (fromDate && toDate) {
    from = null;
    to = null;
  } else if (fromDate && fromDate.isBefore(day)) {
    from = fromDate.toDate();
    to = day;
  } else if (fromDate && fromDate.isAfter(day)) {
    from = day;
    to = fromDate.toDate();
  }

  return { from, to };
}
