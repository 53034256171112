import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { getSize } from 'lib/utils';
import { Button } from 'ui/button';
import { dashboardRoutes } from 'routes/root-routes';
import { TherapistFragment } from 'common/query/__generated__/get-therapists-by-request';
import { DEFAULT_AVATAR } from 'lib/constants/therapist';
import { UserShortInfo } from 'components/user-short-info';
import { RowTemplate } from '../row-template';
import { PopUpMessage, RedFlagMarker } from 'ui';
import { getRoundedValue } from 'components/therapists-list/utils/rounding-helper';

export interface TherapistCardProps {
  therapist: TherapistFragment;
  onEdit?: (therapist: TherapistFragment) => void;
}

function TherapistCard({ therapist, onEdit }: TherapistCardProps) {
  const history = useHistory();

  const openTherapistProfile = () => {
    history.push(dashboardRoutes.therapist.pathBuild(therapist.id));
  };

  const {
    user: { avatar, lastName, firstName },
    qualification: { professionalTitle },
    avgRating,
    rejectionsCount,
    initialSessionsCount,
    overallSessionsCount,
    sumOfMonthAvailabilityHours,
    upcomingCount,
    overdueSessions,
  } = therapist;

  return (
    <Wrapper>
      <InfoBlock>
        <UserShortInfo
          avatar={avatar?.source || DEFAULT_AVATAR}
          titleCSS={{ width: getSize(150) }}
          name={`${firstName} ${lastName}`}
          profession={professionalTitle.label}
        />
      </InfoBlock>
      <InfoBlock>
        <Text>{avgRating}</Text>
      </InfoBlock>
      <InfoBlock>
        <Text>{rejectionsCount}</Text>
      </InfoBlock>
      <InfoBlock>
        <Text>{initialSessionsCount}</Text>
      </InfoBlock>
      <InfoBlock>
        <Text>{overallSessionsCount}</Text>
      </InfoBlock>
      <InfoBlock>
        <Text>{upcomingCount}</Text>
      </InfoBlock>
      <InfoBlock>
        {overdueSessions > 0 ? <RedFlagMarker /> : null}
        <Text>{overdueSessions}</Text>
      </InfoBlock>
      <InfoBlock>
        {sumOfMonthAvailabilityHours < 5 && (
          <DurationOfSession $attention>
            {getRoundedValue(sumOfMonthAvailabilityHours)} hours
          </DurationOfSession>
        )}
      </InfoBlock>
      {onEdit && (
        <InfoBlock>
          <MoreButton onClick={() => onEdit(therapist)} theme="primary">
            Edit
          </MoreButton>
        </InfoBlock>
      )}
      <InfoBlock>
        <MoreButton onClick={openTherapistProfile} theme="primary">
          More
        </MoreButton>
      </InfoBlock>
    </Wrapper>
  );
}

const Wrapper = styled(RowTemplate)`
  height: ${getSize(64)};
  box-shadow: 0 0 ${getSize(30)} var(--blue-opacity2);
  border-radius: ${getSize(8)};
  margin-bottom: ${getSize(12)};
`;

const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    padding-left: ${getSize(24)};
    justify-content: flex-start;
  }
`;

const Text = styled.p`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--black3);
`;

const AcceptingOfRequest = styled.div`
  position: relative;
  cursor: pointer;

  &:hover {
    .tooltip-message {
      display: block;
      white-space: nowrap;
      animation: showTooltip 0.3s ease-out forwards;
    }
  }
`;

const DurationOfSession = styled.p<{
  $isLessDuration?: boolean;
  $attention?: boolean;
}>`
  width: ${getSize(77)};
  height: ${getSize(33)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${getSize(8)};
  font-size: ${getSize(12)};
  line-height: ${getSize(20)};

  background-color: ${({ $attention }) =>
    $attention ? ' var(--red-opacity)' : 'none'};
  color: ${({ $attention }) => ($attention ? ' var(--red)' : 'black')};
`;

const MoreButton = styled(Button)`
  min-height: ${getSize(20)};
  padding: ${getSize(5)} ${getSize(20)};
  font-weight: 400;
  max-width: ${getSize(70)};
`;

export default TherapistCard;
