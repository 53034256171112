import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AdminSetUpBankAccountVariables = Types.Exact<{ [key: string]: never; }>;


export type AdminSetUpBankAccount = (
  { __typename: 'Query' }
  & Pick<Types.Query, 'adminSetUpBankAccount'>
);


export const AdminSetUpBankAccountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AdminSetUpBankAccount"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminSetUpBankAccount"}}]}}]} as unknown as DocumentNode;

/**
 * __useAdminSetUpBankAccount__
 *
 * To run a query within a React component, call `useAdminSetUpBankAccount` and pass it any options that fit your needs.
 * When your component renders, `useAdminSetUpBankAccount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSetUpBankAccount({
 *   variables: {
 *   },
 * });
 */
export function useAdminSetUpBankAccount(baseOptions?: Apollo.QueryHookOptions<AdminSetUpBankAccount, AdminSetUpBankAccountVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminSetUpBankAccount, AdminSetUpBankAccountVariables>(AdminSetUpBankAccountDocument, options);
      }
export function useAdminSetUpBankAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminSetUpBankAccount, AdminSetUpBankAccountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminSetUpBankAccount, AdminSetUpBankAccountVariables>(AdminSetUpBankAccountDocument, options);
        }
export type AdminSetUpBankAccountHookResult = ReturnType<typeof useAdminSetUpBankAccount>;
export type AdminSetUpBankAccountLazyQueryHookResult = ReturnType<typeof useAdminSetUpBankAccountLazyQuery>;
export type AdminSetUpBankAccountQueryResult = Apollo.QueryResult<AdminSetUpBankAccount, AdminSetUpBankAccountVariables>;