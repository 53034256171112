import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetTherapistsRequestsCountVariables = Types.Exact<{ [key: string]: never; }>;


export type GetTherapistsRequestsCount = (
  { __typename: 'Query' }
  & Pick<Types.Query, 'therapistRequestsCount'>
);


export const GetTherapistsRequestsCountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetTherapistsRequestsCount"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"therapistRequestsCount"}}]}}]} as unknown as DocumentNode;

/**
 * __useGetTherapistsRequestsCount__
 *
 * To run a query within a React component, call `useGetTherapistsRequestsCount` and pass it any options that fit your needs.
 * When your component renders, `useGetTherapistsRequestsCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTherapistsRequestsCount({
 *   variables: {
 *   },
 * });
 */
export function useGetTherapistsRequestsCount(baseOptions?: Apollo.QueryHookOptions<GetTherapistsRequestsCount, GetTherapistsRequestsCountVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTherapistsRequestsCount, GetTherapistsRequestsCountVariables>(GetTherapistsRequestsCountDocument, options);
      }
export function useGetTherapistsRequestsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTherapistsRequestsCount, GetTherapistsRequestsCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTherapistsRequestsCount, GetTherapistsRequestsCountVariables>(GetTherapistsRequestsCountDocument, options);
        }
export type GetTherapistsRequestsCountHookResult = ReturnType<typeof useGetTherapistsRequestsCount>;
export type GetTherapistsRequestsCountLazyQueryHookResult = ReturnType<typeof useGetTherapistsRequestsCountLazyQuery>;
export type GetTherapistsRequestsCountQueryResult = Apollo.QueryResult<GetTherapistsRequestsCount, GetTherapistsRequestsCountVariables>;