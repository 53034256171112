import { getSize } from 'lib/utils';
import { FC } from 'react';
import styled from 'styled-components';
import { CloseIcon } from 'ui';
import { AddResourcesForm } from './components/add-new-resource-form';

interface FeedbackModalProps {
  resourceToEdit: any;
  onCloseModal: () => void;
  refetchResources: () => void;
}

export const AddResourcesModal: FC<FeedbackModalProps> = ({
  onCloseModal,
  refetchResources,
  resourceToEdit,
}) => {
  return (
    <ShadowWrapper>
      <Content>
        <Header>
          <HeaderText>Add Resource</HeaderText>
          <CloseIconStyled onClick={onCloseModal} />
        </Header>
        <AddResourcesForm
          resourceToEdit={resourceToEdit}
          onCloseModal={onCloseModal}
          refetchResources={refetchResources}
        />
      </Content>
    </ShadowWrapper>
  );
};

const ShadowWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.51);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const Content = styled.div`
  position: relative;
  width: 90%;
  max-width: ${getSize(1400)};
  height: 90%;
  background: #ffffff;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${getSize(22)} ${getSize(23)} ${getSize(19)} ${getSize(27)};
  border-bottom: 1px solid #f1f1f1;
`;
const HeaderText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: ${getSize(18)};

  color: #000000;
`;

const CloseIconStyled = styled(CloseIcon)`
  cursor: pointer;
`;
