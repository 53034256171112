import React from 'react';
import { AuthLayout } from 'layouts/auth';
import { SignInForm } from './components/signin-form';

function AuthPageForm() {
  return (
    <AuthLayout>
      <SignInForm />
    </AuthLayout>
  );
}

export default AuthPageForm;
