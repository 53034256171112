import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GenerateReportVariables = Types.Exact<{
  dateFrom: Types.Scalars['String'];
  dateTo: Types.Scalars['String'];
  companyId: Types.Scalars['String'];
}>;


export type GenerateReport = (
  { __typename: 'Mutation' }
  & { generateReport: (
    { __typename: 'ReportResult' }
    & Pick<Types.ReportResult, 'filename' | 'dataUrl'>
  ) }
);


export const GenerateReportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GenerateReport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dateFrom"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dateTo"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"dateFrom"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dateFrom"}}},{"kind":"Argument","name":{"kind":"Name","value":"dateTo"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dateTo"}}},{"kind":"Argument","name":{"kind":"Name","value":"companyId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"filename"}},{"kind":"Field","name":{"kind":"Name","value":"dataUrl"}}]}}]}}]} as unknown as DocumentNode;
export type GenerateReportMutationFn = Apollo.MutationFunction<GenerateReport, GenerateReportVariables>;

/**
 * __useGenerateReport__
 *
 * To run a mutation, you first call `useGenerateReport` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateReport` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateReport, { data, loading, error }] = useGenerateReport({
 *   variables: {
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGenerateReport(baseOptions?: Apollo.MutationHookOptions<GenerateReport, GenerateReportVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateReport, GenerateReportVariables>(GenerateReportDocument, options);
      }
export type GenerateReportHookResult = ReturnType<typeof useGenerateReport>;
export type GenerateReportMutationResult = Apollo.MutationResult<GenerateReport>;
export type GenerateReportMutationOptions = Apollo.BaseMutationOptions<GenerateReport, GenerateReportVariables>;