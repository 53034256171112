import React, { useMemo } from 'react';
import styled from 'styled-components';

import { getSize, TimeUtil } from 'lib/utils';
import { ChatFragment } from 'pages/messaging-page/query/__generated__/chats-list';
import { DATE_TIME, ISO_DATE_FORMAT } from 'lib/utils/date';
import { ADMIN_ID } from 'pages/messaging-page/messaging-page';
import { getInnerHtmlWithWrapedEmoji } from 'lib/utils';

import { UserAvatar } from '../../../user-avatar';
import { TextOfCount } from 'ui';

export interface ChatItemProps {
  isActive: boolean;
  chat: ChatFragment;
  isExpanded: boolean;
  onClick: () => void;
  userId?: string;
}

const DEFAULT_TEXT = 'Your chat history will be displayed here';
const ATTACHMENT_TEXT = 'Attached files';

function ChatItem({
  isActive,
  isExpanded,
  onClick,
  chat: { imageUrl, title, type, unreadCount, lastMessage, participants },
}: ChatItemProps) {
  const time = useMemo(() => {
    if (!lastMessage?.createdAt) return;

    const messageDate = TimeUtil.parse(lastMessage.createdAt, ISO_DATE_FORMAT);

    if (messageDate.isToday()) {
      return messageDate.format(DATE_TIME).toUpperCase();
    } else if (messageDate.isBefore(TimeUtil.now(), 'year')) {
      return messageDate.format('DD MMMM');
    }

    return messageDate.format('DD MMMM YYYY');
  }, [lastMessage?.createdAt]);

  function renderText() {
    const isGroupChat = participants.length > 2;
    const messageSenderName = lastMessage?.sender.name;

    function getText() {
      const currentText = lastMessage?.text;
      const hasAttachment = Number(lastMessage?.attachments?.length) > 0;

      if (currentText) {
        return currentText;
      } else if (hasAttachment) {
        return ATTACHMENT_TEXT;
      }

      return DEFAULT_TEXT;
    }

    const textSpan = (
      <span
        className="text-line-clamp-1"
        dangerouslySetInnerHTML={getInnerHtmlWithWrapedEmoji(getText())}
      />
    );

    if (isGroupChat && messageSenderName) {
      const isCurrentUserMessage = lastMessage?.sender.id === ADMIN_ID;
      return (
        <>
          <ParticipantName>{`${
            isCurrentUserMessage ? 'You' : messageSenderName
          }: `}</ParticipantName>
          {textSpan}
        </>
      );
    }

    return textSpan;
  }

  return (
    <li>
      <ButtonInner $isActive={isActive} onClick={onClick} disabled={isActive}>
        <ImageWrapper $isActive={isActive} src={imageUrl} type={type} />

        {!isExpanded && (
          <ContentWrapper>
            <Header>
              <Name>{title}</Name>
              <Time>{time}</Time>
            </Header>

            <TextWrapper>
              <Text>{renderText()}</Text>
              {unreadCount > 0 && <TextOfCount>{unreadCount}</TextOfCount>}
            </TextWrapper>
          </ContentWrapper>
        )}
      </ButtonInner>
    </li>
  );
}

const ButtonInner = styled.button<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  padding: ${getSize(19)} ${getSize(24)};
  width: 100%;
  ${({ $isActive }) => $isActive && 'background: var(--purple3);'}
  transition: 0.3s ease-out;

  &:not(:disabled):hover {
    background: var(--purple6);
  }
`;

const ImageWrapper = styled(UserAvatar)<{ $isActive: boolean }>`
  margin: 0 ${getSize(16)} 0 0;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  min-height: ${getSize(42)};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Name = styled.p`
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--black3);
  white-space: nowrap;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Text = styled.p`
  text-align: left;
  font-weight: 400;
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--gray2);

  .emoji {
    vertical-align: bottom;
    font-size: ${getSize(18)};
  }
`;

const ParticipantName = styled.span`
  color: var(--gray7);
`;

const Time = styled(({ ...rest }) => <Text {...rest} as="time" />)`
  white-space: nowrap;
`;

export default ChatItem;
