import * as React from 'react';

function InsuranceIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={23}
      height={23}
      viewBox="0 0 23 23"
      fill="none"
      {...rest}>
      <g clipPath="url(#prefix__clip0)" fill="#E5E4E8">
        <path d="M11.375 8.291a3.226 3.226 0 00-3.223 3.223 3.226 3.226 0 003.223 3.222 3.226 3.226 0 003.223-3.222 3.226 3.226 0 00-3.223-3.223zm1.289 3.867h-.644v.645a.644.644 0 11-1.29 0v-.645h-.644a.644.644 0 110-1.289h.644v-.644a.644.644 0 111.29 0v.644h.644a.644.644 0 110 1.29z" />
        <path d="M19.11 3.135c-2.403 0-4.729-.747-6.728-2.08l-.65-.433a.643.643 0 00-.715 0l-.65.433c-1.998 1.333-4.324 2.08-6.726 2.08a.644.644 0 00-.645.644v7.316c0 5.154 3.286 9.756 8.175 11.385a.644.644 0 00.408 0c4.89-1.63 8.175-6.23 8.175-11.385V3.78a.644.644 0 00-.645-.644zm-7.735 12.89a4.517 4.517 0 01-4.512-4.511 4.517 4.517 0 014.512-4.512 4.517 4.517 0 014.511 4.512 4.517 4.517 0 01-4.511 4.511z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path
            fill="#fff"
            transform="translate(.375 .514)"
            d="M0 0h22v22H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default InsuranceIcon;
