import { getSize } from 'lib/utils';
import React from 'react';

function StarIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={getSize(20)}
      height={getSize(20)}
      viewBox="0 0 36 33"
      fill="#F7C852"
      {...rest}>
      <path d="M18 0l4.041 12.438H35.12l-10.58 7.687 4.041 12.437L18 24.875 7.42 32.562l4.041-12.437-10.58-7.687h13.078L18 0z" />
    </svg>
  );
}

export default StarIcon;
