import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import { TextBlockList } from 'ui/text-block-list';
import { CheckedWorkTypeIcon, MailIcon, PhoneIcon } from 'ui/icons';
import { getSize } from 'lib/utils';
import { LocationPinIcon } from 'ui/icons/location-pin-icon';
import {
  LanguageModalFragment,
  TherapistInfoFragment,
} from 'common/query/__generated__/get-therapist-by-id';
import { sortApproachesOrTreatmentAreas } from '../../utils/sort-therapy-and-treatment-areas';

const TITLE_CSS = {
  fontSize: getSize(24),
  lineHeight: getSize(36),
  marginBottom: getSize(4),
};

const INPUT_CONTAINER_STYLES = {
  width: '100%',
  padding: `${getSize(8)} 0 ${getSize(9)}`,
  fontWeight: 400,
  fontSize: getSize(12),
  lineHeight: getSize(20),
  border: 0,
};

const INPUT_CSS = {
  color: ' var(--black3)',
};

const WRAPPER_CSS = { marginBottom: getSize(40) };

interface TherapistInfoAsideProps {
  therapist: TherapistInfoFragment;
}

const TherapistInfoAside: FC<TherapistInfoAsideProps> = ({
  therapist,
}: TherapistInfoAsideProps) => {
  const {
    user: { phoneNumber, email },
    location,
    therapistClientTypes,
    languages,
    insuranceCompanies,
    treatmentAreas,
  } = therapist;

  const sortedLanguages = useMemo(
    () =>
      languages?.reduce((acc: LanguageModalFragment[], curr) => {
        if (curr.code === 'en') {
          acc.unshift(curr);
        } else {
          acc.push(curr);
        }

        return acc;
      }, []),
    [languages],
  );

  const sortedTreatmentAreas = useMemo(
    () => sortApproachesOrTreatmentAreas(treatmentAreas),
    [treatmentAreas],
  );

  return (
    <Wrapper>
      <Title>Contacts</Title>
      <ItemsColumnWrapper>
        <ElementWrapper>
          <IconWrapper>
            <PhoneIcon />
          </IconWrapper>
          {phoneNumber && (
            <PhoneInput
              disabled
              country="gb"
              containerStyle={INPUT_CONTAINER_STYLES}
              inputStyle={INPUT_CSS}
              disableDropdown
              specialLabel=""
              value={phoneNumber}
            />
          )}
        </ElementWrapper>
        <ElementWrapper>
          <IconWrapper>
            <MailIcon pathColor="var(--purple4)" />
          </IconWrapper>
          <Text>{email}</Text>
        </ElementWrapper>
        <ElementWrapper>
          <IconWrapper>
            <LocationPinIcon />
          </IconWrapper>
          <Text>{location?.correspondenceAddress}</Text>
        </ElementWrapper>
      </ItemsColumnWrapper>
      <TextBlockList
        title="Specialties"
        titleCSS={TITLE_CSS}
        wrapperCSS={WRAPPER_CSS}
        itemsList={sortedTreatmentAreas}
      />
      <Title>Works with</Title>
      <ItemsColumnWrapper>
        {therapistClientTypes?.map((item) => {
          return (
            <Item key={item.id}>
              <CheckIcon width={getSize(24)} height={getSize(24)} />
              {item.label}
            </Item>
          );
        })}
      </ItemsColumnWrapper>
      <Title>Languages</Title>
      <LanguagesWrapper>
        {sortedLanguages?.map((language) =>
          language ? (
            <Item key={language.id}>
              <LanguageCode>{language.code}</LanguageCode> {language.name}
            </Item>
          ) : null,
        )}
      </LanguagesWrapper>
      {insuranceCompanies.length !== 0 && (
        <>
          <Title>Insurance providers</Title>
          <InsurancesWrapper>
            {insuranceCompanies.map((insurance) =>
              insurance ? (
                <InsuranceImg key={insurance.label} src={insurance.logoUrl} />
              ) : null,
            )}
          </InsurancesWrapper>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${getSize(250)};
  width: 100%;
`;

const Title = styled.p`
  font-weight: 600;
  font-size: ${getSize(24)};
  line-height: ${getSize(36)};
  color: var(--black3);
  margin-bottom: ${getSize(16)};
`;

const ItemsColumnWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  & li {
    margin-bottom: ${getSize(16)};
  }
  &:not(:last-child) {
    margin-bottom: ${getSize(20)};
  }
`;

const LanguagesWrapper = styled(ItemsColumnWrapper)`
  grid-template-columns: 1fr;
`;

const Item = styled.li`
  display: flex;
  align-items: center;
`;

const CheckIcon = styled(CheckedWorkTypeIcon)`
  margin-right: ${getSize(9)};
`;

const LanguageCode = styled.p`
  width: ${getSize(32)};
  height: ${getSize(22)};
  border-radius: ${getSize(2)};
  margin-right: ${getSize(10)};
  background-color: var(--purple5);
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  color: var(--black3);
`;

const InsurancesWrapper = styled.div`
  max-width: ${getSize(260)};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${getSize(10)} ${getSize(20)};
`;

const InsuranceImg = styled.img`
  width: ${getSize(120)};
  height: ${getSize(48)};
  object-fit: contain;
`;

const Text = styled.p`
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--black3);
  max-width: ${getSize(220)};
`;

const IconWrapper = styled.span`
  margin-right: ${getSize(12)};
`;

const ElementWrapper = styled.li`
  display: flex;
  align-items: center;
  & svg {
    width: ${getSize(24)};
    height: ${getSize(24)};
  }
`;

export default TherapistInfoAside;
