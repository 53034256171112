import {
  DAY_MONTH_YEAR_FORMAT,
  DATE_WITH_WEEK,
  EXPIRY_DATE_FORMAT,
  ISO_DATE_FORMAT,
} from 'lib/constants/date';
import { TimeUtil } from 'lib/utils';

export function formatDate(date: Date, format: string) {
  if (format === DAY_MONTH_YEAR_FORMAT) {
    return `${TimeUtil.parse(date.toISOString(), ISO_DATE_FORMAT).format(
      format,
    )} (${TimeUtil.now().diff(date, 'year')})`;
  }

  return TimeUtil.parse(date.toISOString(), ISO_DATE_FORMAT).format(format);
}

export function parseDate(str: string, format: string) {
  if (format === EXPIRY_DATE_FORMAT) {
    const [month, shortYear] = str.split('/');

    return TimeUtil.parse(`${month}/01/20${shortYear}`, 'DD/MM/YYYY').toDate();
  } else if (format === DAY_MONTH_YEAR_FORMAT) {
    const date = str.split('(')[0].replace(' ', '');
    const [day, month, year] = date.split('.');

    return TimeUtil.parse(`${month}/${day}/${year}`, 'MM/DD/YYYY').toDate();
  } else if (format === DATE_WITH_WEEK) {
    const date = str.split(',');
    const [, day, month, year] = date[1].split(' ');

    return TimeUtil.parse(`${month} ${day} ${year}`, 'MMM DD YYYY').toDate();
  }
  return TimeUtil.parse(str, format).toDate();
}
