import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getSize } from 'lib/utils';
import { Paper } from 'ui/paper';
import {
  PatientFragment,
  useGetPatients,
} from 'common/query/__generated__/get-patients-by-request';
import { notifyError } from 'lib/utils/notification';
import { useRemovePatientById } from 'common/mutation/__generated__/remove-patient-by-id';
import { useDebounceCallback } from '@react-hook/debounce';
import { useBanPatientById } from 'common/mutation/__generated__/ban-patient-by-id';
import { ManageUserConfirmModal } from 'ui';
import { useUnbanPatientById } from 'common/mutation/__generated__/unban-patient-by-id';
import { PatientAdminOrder, PatientAdminSort } from '../../__generated__/types';
import { PatientsList } from '../../components/patients-list';

const PATIENTS_LIMIT = 10;

function PatientsPage() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isBanPatient, setIsBanPatient] = useState(false);
  const [patientId, setPatientId] = useState('');
  const [filter, setFilter] = useState('');
  const [refetchLoading, setRefetchLoading] = useState(false);
  const [sortOption, setSortOption] = useState<PatientAdminOrder>(
    PatientAdminOrder.FromNewToOld,
  );
  const [sortDirection, setSortDirection] = useState<PatientAdminSort>(
    PatientAdminSort.Desc,
  );

  const patientsQuery = useGetPatients({
    //adding this option because of following issue in apollo https://github.com/apollographql/react-apollo/issues/2177
    fetchPolicy: 'network-only',
    variables: {
      first: PATIENTS_LIMIT,
      filter: { searchName: filter },
      orderBy: sortOption,
      sort: sortDirection,
    },
  });

  const patientsList: PatientFragment[] = useMemo(() => {
    setRefetchLoading(false);
    return patientsQuery.data?.adminPatientsSearch.nodes || [];
  }, [patientsQuery.data?.adminPatientsSearch.nodes]);

  const [removePatientById, { loading: removeLoading }] = useRemovePatientById({
    onError: ({ name, message }) => notifyError({ title: name, text: message }),
    update(cache) {
      cache.evict({
        fieldName: 'adminPatientsSearch',
        broadcast: true,
      });
    },
  });

  const [banPatientById, { loading: banLoading }] = useBanPatientById({
    onError: ({ name, message }) => notifyError({ title: name, text: message }),
    update(cache) {
      cache.evict({
        fieldName: 'adminPatientsSearch',
        broadcast: true,
      });
    },
  });

  const [unbanPatientById] = useUnbanPatientById({
    onError: ({ name, message }) => notifyError({ title: name, text: message }),
    update(cache) {
      cache.evict({
        fieldName: 'adminPatientsSearch',
        broadcast: true,
      });
    },
  });

  const modalAction = (patientId: string, isBan: boolean) => {
    setIsModalVisible(true);
    setPatientId(patientId);
    setIsBanPatient(isBan);
  };

  const handleRemovePatientFromBase = () => {
    removePatientById({ variables: { input: { patientId } } }).finally(() =>
      setIsModalVisible(false),
    );
  };

  const handleBanPatient = () => {
    banPatientById({ variables: { input: { patientId } } }).finally(() =>
      setIsModalVisible(false),
    );
  };

  const handleUnbanPatient = (patientId: string) => {
    unbanPatientById({ variables: { input: { patientId } } });
  };

  const handleSearchMessageFormSubmit = useCallback((searchText?: string) => {
    const searchPhrase = searchText?.trim() || '';
    setFilter(searchPhrase);
    setSortOption(PatientAdminOrder.FromNewToOld);
  }, []);

  const debounceHandleSearchFormSubmit = useDebounceCallback(
    handleSearchMessageFormSubmit,
    250,
  );

  useEffect(() => {
    patientsQuery.refetch();
    setRefetchLoading(true);
  }, [sortDirection]);

  return (
    <Paper searchRequest={debounceHandleSearchFormSubmit} title="Patients">
      <DashboardWrapper>
        <PatientsList
          dataLength={patientsList.length}
          isLoading={patientsQuery.loading || refetchLoading}
          patients={patientsList}
          onNext={() =>
            patientsQuery.fetchMore({
              variables: {
                first: PATIENTS_LIMIT,
                filter: { searchName: filter },
                orderBy: sortOption,
                sort: sortDirection,
                after:
                  patientsQuery.data?.adminPatientsSearch.pageInfo.endCursor,
              },
            })
          }
          hasMore={Boolean(
            patientsQuery.data?.adminPatientsSearch.pageInfo.hasNextPage,
          )}
          modalAction={modalAction}
          onUnbanPatient={handleUnbanPatient}
          setSortOption={setSortOption}
          setSortDirection={setSortDirection}
          sortDirection={sortDirection}
          sortOption={sortOption}
        />
      </DashboardWrapper>
      <ManageUserConfirmModal
        title={isBanPatient ? 'Pause access' : 'Removing patient'}
        text={
          isBanPatient
            ? 'Do you want to pause access for this patient?'
            : `Do you want to delete patient from base?`
        }
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onAction={isBanPatient ? handleBanPatient : handleRemovePatientFromBase}
        isLoading={removeLoading || banLoading}
      />
    </Paper>
  );
}

const DashboardWrapper = styled.div`
  padding-top: ${getSize(20)};
`;

const PatientList = styled(InfiniteScroll)`
  padding: 0 ${getSize(40)} ${getSize(20)};

  & > *:not(:first-child) {
    margin-top: ${getSize(12)};
  }
`;

const ScrollWrapper = styled.div`
  overflow: auto;
  padding-top: ${getSize(12)};
  height: ${getSize(450)};

  &:: -webkit-scrollbar {
    display: none;
  }
`;

export default PatientsPage;
