import styled from 'styled-components';
import { getSize } from 'lib/utils';
import { Button } from 'ui/button';
import { RowTemplate } from '../row-template';
import { TherapistFragment } from 'common/query/__generated__/get-therapists-by-request';
import { UserShortInfo } from 'components/user-short-info';
import { DEFAULT_AVATAR } from 'lib/constants/therapist';

interface TherapistRowItemProps {
  therapist: TherapistFragment;
  tmp?: string;
  onClick?: () => void;
}

function TherapistRowItem({ tmp, therapist, onClick }: TherapistRowItemProps) {
  //const { eightyMin, fiftyMin, priceType, thirtyMin } = pricingData;

  const {
    ongoingSessionCommissionPercent,
    initialSessionCommissionPercent,
    user: { avatar, lastName, firstName },
    qualification: { professionalTitle },
  } = therapist;

  return (
    <Wrapper>
      <InfoBlock>
        <UserShortInfo
          avatar={avatar?.source || DEFAULT_AVATAR}
          titleCSS={{ width: getSize(150) }}
          name={`${firstName} ${lastName}`}
          profession={professionalTitle.label}
        />
      </InfoBlock>
      <InfoBlock>
        <Text>{Math.round(initialSessionCommissionPercent * 100) / 100}%</Text>
      </InfoBlock>
      <InfoBlock>
        <Text>{Math.round(ongoingSessionCommissionPercent * 100) / 100}%</Text>
      </InfoBlock>
      <InfoBlock>
        <EditButton onClick={onClick} theme="primary">
          Change
        </EditButton>
      </InfoBlock>
    </Wrapper>
  );
}

const Wrapper = styled(RowTemplate)`
  height: ${getSize(64)};
  box-shadow: 0 0 ${getSize(30)} var(--blue-opacity2);
  border-radius: ${getSize(8)};
  margin-bottom: ${getSize(12)};
`;

const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    padding-left: ${getSize(24)};
    justify-content: flex-start;
  }
`;

const Text = styled.p`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--black3);
`;

const EditButton = styled(Button)`
  min-height: ${getSize(20)};
  padding: ${getSize(5)} ${getSize(20)};
  font-weight: 400;
  max-width: ${getSize(100)};
`;

export default TherapistRowItem;
