import React from 'react';
import styled from 'styled-components';
import { getSize } from 'lib/utils';

export interface CheckboxIndicatorProps {
  isChecked?: boolean;
  onClick?: () => void;
  className?: string;
}

function CheckboxIndicator({
  isChecked,
  onClick,
  className,
}: CheckboxIndicatorProps) {
  return (
    <Wrapper className={className} $isChecked={isChecked} onClick={onClick} />
  );
}

const Wrapper = styled.span<{ $isChecked?: boolean }>`
  position: relative;
  flex-shrink: 0;
  width: ${getSize(22)};
  height: ${getSize(22)};
  border: 1px solid
    var(${({ $isChecked }) => ($isChecked ? '--purple' : '--purple3')});
  border-radius: ${getSize(4)};
  background: var(${({ $isChecked }) => ($isChecked ? '--purple' : '--white')});
  transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  cursor: pointer;

  &:hover {
    ${({ $isChecked }) => !$isChecked && 'border-color: var(--purple2);'}

    &::before {
      transform: rotate(-45deg) scale(1.2);
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: ${getSize(4)};
    left: ${getSize(4)};
    width: ${getSize(12)};
    height: ${getSize(7)};
    border: 2px solid white;
    border-top: 0;
    border-right: 0;
    transform: rotate(-45deg);
    transition: 0.3s ease-out;
  }
`;

export default CheckboxIndicator;
