import React, { FC, useMemo } from 'react';
import styled, { CSSProp } from 'styled-components';
import { PopUpMessage } from 'ui/pop-up-message';
import { getSize } from 'lib/utils';

const POPUP_CSS: CSSProp = { whiteSpace: 'nowrap' };

interface Item {
  label?: string;
  explanation?: string | null;
}

interface TextBlockListProps {
  title: string;
  itemsList?: (Item | null)[];
  wrapperCSS?: CSSProp;
  listWrapperCSS?: CSSProp;
  itemCSS?: CSSProp;
  titleCSS?: CSSProp;
  isShortInfo?: boolean;
}

const TextBlockList: FC<TextBlockListProps> = ({
  title,
  itemsList = [],
  isShortInfo,
  wrapperCSS,
  listWrapperCSS,
  itemCSS,
  titleCSS,
}) => {
  const items = useMemo(
    () => (isShortInfo ? itemsList.slice(0, 3) : itemsList),
    [isShortInfo, itemsList],
  );

  return (
    <Wrapper CSS={wrapperCSS}>
      <Title CSS={titleCSS}>{title}</Title>
      <TextListWrapper CSS={listWrapperCSS}>
        {items?.map((item, index) => (
          <TextItem key={index} CSS={itemCSS}>
            {item?.label}
            {item?.explanation && (
              <PopUpMessage rootCSS={POPUP_CSS}>
                {item.explanation}
              </PopUpMessage>
            )}
          </TextItem>
        ))}
        {isShortInfo && itemsList.length > 3 && <TextItem>More</TextItem>}
      </TextListWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ CSS?: CSSProp }>`
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: ${getSize(20)};
  }

  ${({ CSS }) => CSS}
`;

const Title = styled.h3<{ CSS?: CSSProp }>`
  font-weight: 600;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--black3);
  margin-bottom: ${getSize(2)};

  ${({ CSS }) => CSS}
`;

const TextListWrapper = styled.div<{ CSS?: CSSProp }>`
  display: flex;
  flex-wrap: wrap;

  ${({ CSS }) => CSS}
`;

const TextItem = styled.div<{ CSS?: CSSProp }>`
  position: relative;
  padding: ${getSize(9)} ${getSize(16)};
  margin: ${getSize(12)} ${getSize(12)} 0 0;
  border-radius: ${getSize(10)};
  border: none;
  cursor: default;
  text-decoration: none;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--black3);
  background-color: var(--purple11);
  &:hover > p {
    display: block;
    animation: showTooltip 0.3s ease-out forwards;
  }
  ${({ CSS }) => CSS}
`;

export default TextBlockList;
