import { getSize } from 'lib/utils';
import React, { FC, useState, useMemo, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ShadowWrapper } from 'ui';
import { SwitchComponent } from 'ui/switch-component';
import { OrderByDirection, TherapistAdminSort } from '__generated__/types';
import { AllUsers } from './components/feedback-user-table/components/all-users';
import { useGetFutureUpcomingSessionCount } from 'common/query/__generated__/get-future-session-count';
import {
  FeedbackRowFragment,
  useGetUsersFeedback,
} from 'common/query/__generated__/get-users-feedback';
import { FeedbackModal } from './components/feedback-modal/feedback-modal';
import { FeedbackUserTable } from './components/feedback-user-table/feedback-user-table';
import { FeedbackB2bTable } from './components/feedback-b2b-table/feedback-b2b-table';
import { FeedbackB2bModal } from './components/feedback-b2b-modal/feedback-b2b-modal';

const FeedbackPage: FC = () => {
  const [tab, setTab] = useState(1);
  const [isModalShow, setIsModalShow] = useState(false);
  const [isB2bModalShow, setIsB2bModalShow] = useState(false);

  const openFeedbackModal = () => {
    setIsModalShow(true);
  };

  const closeFeedbackModal = () => {
    setIsModalShow(false);
  };

  const openB2bFeedbackModal = () => {
    setIsB2bModalShow(true);
  };

  const closeB2bFeedbackModal = () => {
    setIsB2bModalShow(false);
  };
  const { data: futureSessionCount } = useGetFutureUpcomingSessionCount();

  const therapistSessionCount = futureSessionCount?.futureUpcomingSessionCount
    ? futureSessionCount.futureUpcomingSessionCount[0]
    : 0;

  const MAX_VISIBLE_FEEDBACK = 10;

  const usersFeedbackData = useGetUsersFeedback({
    variables: {
      first: MAX_VISIBLE_FEEDBACK,
    },
  });

  const usersFeedback = usersFeedbackData.data?.adminUsersFeedback?.nodes;

  const userId = usersFeedback ? usersFeedback[0]?.patientId || '0' : '0';
  const [patientId, setPatientId] = useState<string>(userId);
  const [companyId, setCompanyId] = useState<string>('');

  enum SwitchVariants {
    All = 'ALL',
    B2b = 'B2B',
  }
  const switchVariants = ['ALL', 'B2B'];
  const switchSelect = switchVariants[tab - 1];

  return (
    <Wrapper>
      <ShadowWrapperStyled>
        <SwitchComponentStyled
          variants={switchVariants}
          tabNumber={tab}
          setTabNumber={setTab}
        />
        {switchSelect === SwitchVariants.All && (
          <FeedbackUserTable
            openFeedbackModal={openFeedbackModal}
            setPatientId={setPatientId}
          />
        )}
        {switchSelect === SwitchVariants.B2b && (
          <FeedbackB2bTable
            openFeedbackModal={openB2bFeedbackModal}
            setCompanyId={setCompanyId}
          />
        )}
      </ShadowWrapperStyled>
      {isModalShow && (
        <FeedbackModal
          onCloseModal={closeFeedbackModal}
          patientId={patientId}
        />
      )}
      {isB2bModalShow && (
        <FeedbackB2bModal
          onCloseModal={closeB2bFeedbackModal}
          companyId={companyId}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: ${getSize(56)};
`;

const ShadowWrapperStyled = styled(ShadowWrapper)`
  padding: ${getSize(40)} 0;
`;

const AllUsersStyled = styled(AllUsers)`
  margin-top: 31px;
`;

const SwitchComponentStyled = styled(SwitchComponent)`
  padding: 0 ${getSize(40)};
`;

export default FeedbackPage;
