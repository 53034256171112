import React, { FC } from 'react';
import TitleWithInfo from '../title-with-info';
import PricesForm from '../prices-form';
import { useAdminChangeSessionPrice } from 'common/mutation/__generated__/change-session-price';
import { notifyError, notifySuccess } from 'lib/utils/notification';
import { Config } from 'final-form';
import { CommissionFormValues } from '../prices-form/prices-form';
import { getFormSubmitError } from 'lib/utils/form';
import { SUCCESS_NOTIFICATION } from 'components/session-price-form/constants';
import { UserError } from '__generated__/types';
import { SessionPriceAdminFragment } from 'common/fragments/__generated__/session-price-admin.fragment';

const CommonPricesForm: FC<CommonPricesForm> = ({ sessionPrices }) => {
  const [changeSessionPrice, { loading: sessionPriceLoading }] =
    useAdminChangeSessionPrice({
      onError: ({ name, message }) =>
        notifyError({ title: name, text: message }),
    });

  const handleFormSubmit: Config<CommissionFormValues>['onSubmit'] = (
    { minPrice, mediumPrice, maxPrice },
    form,
  ) => {
    console.log('THIS');
    // TODO: Fix this
    // return changeSessionPrice({
    //   variables: {
    //     input: {
    //       thirtyMin: Number(minPrice),
    //       fiftyMin: Number(mediumPrice),
    //       eightyMin: Number(maxPrice),
    //     },
    //   },
    // }).then(({ data }) => {
    //   const validateErrors = data?.adminChangeDefaultSessionPrice?.userErrors;
    //   if (validateErrors && validateErrors?.length > 0) {
    //     notifyError({ text: 'Validate error' });
    //     return getFormSubmitError(validateErrors as UserError[]);
    //   } else {
    //     notifySuccess(SUCCESS_NOTIFICATION);
    //     setTimeout(form.restart);
    //   }
    // });
  };

  return (
    <>
      {sessionPrices && (
        <>
          <TitleWithInfo
            title="Session price"
            info="Session price is common for every therapist on platform."
          />
          <PricesForm
            handleFormSubmit={handleFormSubmit}
            initialValues={{
              minPrice: sessionPrices.thirtyMin.toString(),
              mediumPrice: sessionPrices.fiftyMin.toString(),
              maxPrice: sessionPrices.eightyMin.toString(),
            }}
            submitLoading={sessionPriceLoading}
          />
        </>
      )}
    </>
  );
};

interface CommonPricesForm {
  sessionPrices?: SessionPriceAdminFragment;
}

export default CommonPricesForm;
