import { useGetFutureUpcomingSessionCount } from 'common/query/__generated__/get-future-session-count';
import {
  FeedbackRowFragment,
  useGetUsersFeedback,
} from 'common/query/__generated__/get-users-feedback';
import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  OrderByDirection,
  TherapistAdminSort,
  TherapistRequestStatus,
} from '__generated__/types';
import { AllUsers } from './components/all-users';

interface FeedbackUserTableProps {
  openFeedbackModal: () => void;
  setPatientId: React.Dispatch<React.SetStateAction<string>>;
}

export const FeedbackUserTable: FC<FeedbackUserTableProps> = ({
  openFeedbackModal,
  setPatientId,
}) => {
  const [filter, setFilter] = useState('');
  const [sortOption, setSortOption] = useState(TherapistAdminSort.UpdatedAt);
  const [sortDirection, setSortDirection] = useState(OrderByDirection.Desc);

  const MAX_VISIBLE_FEEDBACK = 10;

  const usersFeedbackData = useGetUsersFeedback({
    variables: {
      first: MAX_VISIBLE_FEEDBACK,
    },
  });

  const { data: futureSessionCount } = useGetFutureUpcomingSessionCount();

  const therapistSessionCount = futureSessionCount?.futureUpcomingSessionCount
    ? futureSessionCount.futureUpcomingSessionCount[0]
    : 0;

  const feedbackList: FeedbackRowFragment[] = useMemo(
    () => usersFeedbackData.data?.adminUsersFeedback.nodes || [],
    [usersFeedbackData.data?.adminUsersFeedback.nodes],
  );
  return (
    <AllUsersStyled
      dataLength={feedbackList.length}
      onNext={() => {
        return usersFeedbackData.fetchMore({
          variables: {
            filter: {
              status: TherapistRequestStatus.Accepted,
              searchName: filter,
            },
            after:
              usersFeedbackData.data?.adminUsersFeedback.pageInfo.endCursor,
          },
        });
      }}
      hasMore={Boolean(
        usersFeedbackData.data?.adminUsersFeedback.pageInfo.hasNextPage,
      )}
      therapists={feedbackList}
      isLoading={usersFeedbackData.loading}
      futureSessionCount={therapistSessionCount}
      setSortOption={setSortOption}
      setSortDirection={setSortDirection}
      sortDirection={sortDirection}
      sortOption={sortOption}
      onOpenModal={openFeedbackModal}
      setPatientId={setPatientId}
    />
  );
};

const AllUsersStyled = styled(AllUsers)`
  margin-top: 31px;
`;
