import React, { FC } from 'react';
import styled from 'styled-components';

const RowTemplate: FC<{ className?: string }> = ({ children, className }) => {
  return <Wrapper className={className}>{children}</Wrapper>;
};

const Wrapper = styled.div`
  min-width: 100%;
  display: grid;
  grid-template-columns: 20% 8% 8% 8% 8% 9% 8% 8% 10% 10%;
  align-items: center;
`;

export default RowTemplate;
