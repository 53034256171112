import React, { useState } from 'react';

import { AddResourcesModal } from './add-new-post-modal';
import { getSize } from '../../lib/utils';
import { TableTitles } from '../../components/table-titles';
import styled from 'styled-components';
import { useGetAllResources } from '../../common/query/__generated__/admin-get-all-resources';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader, ManageUserConfirmModal, ShadowWrapper } from '../../ui';
import ResourceCard from '../../components/resource-card/resource-card';
import { useRemovePost } from '../../common/mutation/__generated__/remove-resource';
import { notifyError } from '../../lib/utils/notification';

const ResourcesPage = () => {
  /** State Data */
  const [resourceToEdit, setResourceToEdit] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [resourceToDeleteId, setResourceToDeleteId] = useState('');
  const [isResourcesPopupShow, setIsResourcesPopupShow] = useState(false);
  /** Fetch data from API */
  const { data, loading, error, refetch: refetchPosts } = useGetAllResources();
  const posts = data?.getAllPosts || [];
  const onCloseModal = () => {
    setIsResourcesPopupShow(false);
    setResourceToEdit(null);
  };

  const refetchResources = () => {
    refetchPosts();
  };

  const openAddNewPostModal = () => {
    setIsResourcesPopupShow(true);
  };
  const deleteResource = (id: string) => {
    setResourceToDeleteId(id);
    setIsModalVisible(true);
  };

  const [removeResourceId] = useRemovePost({
    onError: ({ name, message }) => notifyError({ title: name, text: message }),
  });

  const handleRemoveResourceFromBase = () => {
    removeResourceId({
      variables: {
        id: resourceToDeleteId,
      },
    }).finally(() => setIsModalVisible(false));
  };

  const handleEditClick = (resource: any) => {
    setResourceToEdit(resource);
    setIsResourcesPopupShow(true);
  };

  return (
    <Wrapper>
      <ShadowWrapperStyled>
        <HeaderButtonsContainer>
          <AddNewPostButton onClick={openAddNewPostModal}>
            + Add New
          </AddNewPostButton>
        </HeaderButtonsContainer>
        <DashboardWrapper>
          <TableTitles
            firstColumnCSS={{ width: getSize(140) }}
            secondColumnCSS={{ width: getSize(140) }}
            thirdColumnCSS={{ width: getSize(140) }}
            fourthColumnCSS={{ width: getSize(140) }}
            fifthColumnCSS={{ width: getSize(140) }}
            sixthColumnCSS={{ width: getSize(140) }}
            seventhColumnCSS={{ width: getSize(140) }}
            firstColumnName="SubCategory"
            secondColumnName="Author"
            thirdColumnName="Title"
            fourthColumnName="Subtitle"
            fifthColumnName="Type"
            seventhColumnName="Actions"
          />
          <ScrollWrapper id="scrollableChatsList">
            <ResourcesList
              dataLength={posts.length}
              next={() => {}}
              hasMore={false}
              scrollableTarget="scrollableChatsList"
              style={{ overflow: 'visible' }}
              loader={<Loader />}>
              {posts?.map((post) => (
                <ResourceCard
                  key={post.id}
                  resource={post}
                  deleteResource={deleteResource}
                  handleEditClick={handleEditClick}
                />
              ))}
            </ResourcesList>
          </ScrollWrapper>
        </DashboardWrapper>
        {isResourcesPopupShow && (
          <AddResourcesModal
            onCloseModal={onCloseModal}
            resourceToEdit={resourceToEdit}
            refetchResources={refetchResources}
          />
        )}
        <ManageUserConfirmModal
          title="Remove resource"
          text="Are you sure you want to remove this resource?"
          isVisible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          onAction={handleRemoveResourceFromBase}
          isLoading={false}
        />
      </ShadowWrapperStyled>
    </Wrapper>
  );
};

const HeaderButtonsContainer = styled.div`
  display: flex;
`;

const AddNewPostButton = styled.button`
  background: #7b5cff;
  border-radius: 52px;
  padding: 9px 20px;

  margin-left: ${getSize(40)};
  font-weight: 600;
  font-size: 12px;

  color: #ffffff;
`;

const DashboardWrapper = styled.div`
  padding-top: ${getSize(20)};
`;

const ResourcesList = styled(InfiniteScroll)`
  padding: 0 ${getSize(40)} ${getSize(20)};

  & > *:not(:first-child) {
    margin-top: ${getSize(12)};
  }
`;

const ScrollWrapper = styled.div`
  overflow: auto;
  padding-top: ${getSize(12)};
  height: ${getSize(450)};

  &:: -webkit-scrollbar {
    display: none;
  }
`;

const Wrapper = styled.div`
  padding: ${getSize(56)};
`;

const ShadowWrapperStyled = styled(ShadowWrapper)`
  padding: ${getSize(40)} 0;
`;

export default ResourcesPage;
