import React from 'react';
import styled from 'styled-components';
import { getSize } from 'lib/utils';
import { RoundCrossIcon } from 'ui/icons';

export interface CrossButtonProps {
  onClick: () => void;
  className?: string;
}

function CrossButton({ onClick, className }: CrossButtonProps) {
  return (
    <Button className={className} type="button" onClick={onClick}>
      <CrossIcon />
    </Button>
  );
}

const Button = styled.button`
  &:hover {
    svg {
      stroke: var(--purple);
    }
  }
`;

const CrossIcon = styled(RoundCrossIcon)`
  transition: 0.3s ease-out;
  width: ${getSize(24)};
  height: ${getSize(24)};
`;

export default CrossButton;
