import React from 'react';
import styled from 'styled-components';
import { Button, LogoLink } from 'ui';
import { getSize } from 'lib/utils';
import DashboardNavLinks, {
  LinkItem,
} from 'components/dashboard-nav-links/ dashboard-nav-links';
import { logout } from '../../lib/auth';

interface DashboardSidebarLayoutProps {
  links: LinkItem[];
}

function DashboardSidebarLayout({ links }: DashboardSidebarLayoutProps) {
  return (
    <Aside>
      <LogoWrapper>
        <LogoLink />
        <SidebarStylized links={links} numberItemPerRow={1} />
        <LogoutButton onClick={logout} theme="buble-secondary">
          Log Out
        </LogoutButton>
      </LogoWrapper>
    </Aside>
  );
}

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Aside = styled.aside`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  background: white;
  box-shadow: ${getSize(0)} ${getSize(20)} ${getSize(90)} var(--blue-opacity2);
  overflow: auto;
  height: 100%;
`;

const SidebarStylized = styled(DashboardNavLinks)`
  display: flex;
  width: ${getSize(314)};
  padding: ${getSize(0)} ${getSize(125)} ${getSize(0)} ${getSize(36)};
  z-index: 10;
`;
const LogoutButton = styled(Button)`
  margin-top: 30px;
  margin-left: 45px;
  border: ${getSize(1)} solid --var(purple3);
  border-radius: ${getSize(8)};
  color: red;
  padding: ${getSize(10)} ${getSize(30)};
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  width: ${getSize(112)};
  height: ${getSize(44)};
  font-weight: 600;
`;
export default DashboardSidebarLayout;
