import { TherapistFragment } from 'common/query/__generated__/get-therapists-by-request';
import { FeedbackRowFragment } from 'common/query/__generated__/get-users-feedback';
import {
  Head,
  RowTemplate,
  TherapistCard,
} from 'components/therapists-list/components';
import { revertedDirection } from 'components/therapists-list/therapists-list';
import { getSize } from 'lib/utils';
import { map } from 'lodash';
import React, { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled, { css } from 'styled-components';
import { ArrowDownIcon, Loader } from 'ui';
import {
  OrderByDirection,
  ReviewsTab,
  TherapistAdminSort,
} from '__generated__/types';
import { FeedbackCard } from './feedback-card';
import { HeaderTable } from '../../header-table';

interface AllUsersProps {
  className?: string;
  dataLength: number;
  onNext: () => void;
  hasMore: boolean;
  therapists?: FeedbackRowFragment[];
  isLoading?: boolean;
  futureSessionCount: number;
  setSortOption: (v: TherapistAdminSort) => void;
  setSortDirection: (v: OrderByDirection) => void;
  sortDirection: OrderByDirection;
  sortOption: TherapistAdminSort;
  onOpenModal: () => void;
  setPatientId: React.Dispatch<React.SetStateAction<string>>;
}

export const AllUsers: FC<AllUsersProps> = ({
  className,
  onNext,
  dataLength,
  hasMore,
  therapists,
  isLoading,
  futureSessionCount,
  setSortOption,
  setSortDirection,
  sortDirection,
  sortOption,
  onOpenModal,
  setPatientId,
}) => {
  const handleSort = (option: TherapistAdminSort) => {
    if (sortOption !== option) {
      setSortOption(option);
      setSortDirection(OrderByDirection.Desc);
    } else {
      setSortDirection(revertedDirection[sortDirection]);
    }
  };

  const renderArrow = (option: TherapistAdminSort) => {
    return (
      <Icon direction={sortDirection} active={sortOption === option}>
        <ArrowDownIcon
          height={getSize(12)}
          width={getSize(12)}
          stroke={sortOption === option ? '#000' : '#97909E'}
        />
      </Icon>
    );
  };

  return (
    <Wrapper className={className}>
      <HeaderWrapper>
        <HeaderTable ReviewsTab={ReviewsTab.All} />
        <TableHeader>
          <TableHeaderText
            disabled={isLoading}
            active={sortOption === TherapistAdminSort.Rating}
            onClick={() => handleSort(TherapistAdminSort.Rating)}>
            Patient
            {renderArrow(TherapistAdminSort.Rating)}
          </TableHeaderText>
          <TableHeaderText
            disabled={isLoading}
            active={sortOption === TherapistAdminSort.RejectionsCount}
            onClick={() => handleSort(TherapistAdminSort.RejectionsCount)}>
            Therapist
            {renderArrow(TherapistAdminSort.RejectionsCount)}
          </TableHeaderText>
          <TableHeaderText
            disabled={isLoading}
            active={sortOption === TherapistAdminSort.InitialSessionsCount}
            onClick={() => handleSort(TherapistAdminSort.InitialSessionsCount)}>
            Total Sessions
            {renderArrow(TherapistAdminSort.InitialSessionsCount)}
          </TableHeaderText>
          <TableHeaderText
            disabled={isLoading}
            active={sortOption === TherapistAdminSort.PastSessionsCount}
            onClick={() => handleSort(TherapistAdminSort.PastSessionsCount)}>
            Rating Avg
            {renderArrow(TherapistAdminSort.PastSessionsCount)}
          </TableHeaderText>
          <TableHeaderText
            active={sortOption === TherapistAdminSort.RequestAcceptance}
            disabled={isLoading}
            onClick={() => handleSort(TherapistAdminSort.RequestAcceptance)}>
            Progress
            {renderArrow(TherapistAdminSort.RequestAcceptance)}
          </TableHeaderText>
          <TableHeaderText
            disabled={isLoading}
            active={
              sortOption === TherapistAdminSort.SumOfMonthAvailabilityHours
            }
            onClick={() =>
              handleSort(TherapistAdminSort.SumOfMonthAvailabilityHours)
            }>
            Experience
            {renderArrow(TherapistAdminSort.SumOfMonthAvailabilityHours)}
          </TableHeaderText>
          <TableHeaderText
            disabled={isLoading}
            active={
              sortOption === TherapistAdminSort.SumOfMonthAvailabilityHours
            }
            onClick={() =>
              handleSort(TherapistAdminSort.SumOfMonthAvailabilityHours)
            }>
            Company
            {renderArrow(TherapistAdminSort.SumOfMonthAvailabilityHours)}
          </TableHeaderText>
          <TableHeaderText
            disabled={isLoading}
            active={
              sortOption === TherapistAdminSort.SumOfMonthAvailabilityHours
            }
            onClick={() =>
              handleSort(TherapistAdminSort.SumOfMonthAvailabilityHours)
            }>
            Feedback
            {renderArrow(TherapistAdminSort.SumOfMonthAvailabilityHours)}
          </TableHeaderText>
        </TableHeader>
      </HeaderWrapper>
      <ScrollWrapper id="scrollableChatsList">
        {isLoading ? (
          <Loader hasFillWholeBlock size={50} />
        ) : (
          <UsersFeedbackList
            dataLength={dataLength}
            next={onNext}
            hasMore={hasMore}
            scrollableTarget="scrollableChatsList"
            loader={<Loader />}>
            {therapists?.map((feedback) => (
              <FeedbackCard
                key={feedback.id}
                feedback={feedback}
                onOpenModal={onOpenModal}
                setPatientId={setPatientId}
              />
            ))}
          </UsersFeedbackList>
        )}
      </ScrollWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const gridTemplate = css`
  grid-template-columns: 10% 10% 14% 14% 12% 12% 12% 16%;
  padding: 0 15px;
`;

const HeaderWrapper = styled.div`
  padding: 0 ${getSize(40)};
`;

const TableHeader = styled(RowTemplate)`
  margin-top: 31px;
  height: ${getSize(52)};
  background-color: var(--purple11);
  border-radius: ${getSize(8)};
  ${gridTemplate}
`;

const TableHeaderText = styled.button<{ active?: boolean }>`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  text-align: center;
  padding: 0 ${getSize(18)};
  color: var(--gray7);
  display: flex;
  align-items: center;
  color: ${({ active }) => (active ? 'var(--black)' : 'var(--gray7)')};

  &:first-child {
    padding-left: ${getSize(24)};
    text-align: left;
  }

  &:last-child {
    display: flex;
    justify-content: flex-end;
  }
`;

const Icon = styled.div<{ direction: OrderByDirection; active: boolean }>`
  margin-left: 0px;
  ${({ direction, active }) =>
    active &&
    direction === OrderByDirection.Asc &&
    'transform: rotate(180deg)'};
`;

const ScrollWrapper = styled.div`
  overflow: auto;
  margin-top: ${getSize(12)};
  height: ${getSize(500)};

  &::-webkit-scrollbar {
    display: none;
  }
`;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore-next-line
const UsersFeedbackList = styled(InfiniteScroll)`
  padding: ${getSize(20)}${getSize(40)};
`;
