import React, { ReactNode } from 'react';
import styled, { CSSProp } from 'styled-components';

import { getSize } from 'lib/utils';

import { LogoLink } from 'ui';

export interface AuthLayoutProps {
  children: ReactNode;
  contentCSS?: CSSProp;
}

function AuthLayout({ children, contentCSS }: AuthLayoutProps) {
  return (
    <Wrapper>
      <Header>
        <HeaderInner>
          <LogoLink isDisabled />
        </HeaderInner>
      </Header>

      <Content>
        <ContentInner $CSS={contentCSS}>{children}</ContentInner>
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: linear-gradient(
    180deg,
    var(--gray) ${getSize(-80)},
    var(--purple)
  );
`;

const Header = styled.header`
  position: relative;
  background: transparent;
`;

const HeaderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: ${getSize(14)} ${getSize(110)};
  max-width: ${getSize(1440)};
`;

const Content = styled.main`
  flex-grow: 1;
  overflow: auto;
`;

const ContentInner = styled.div<{ $CSS?: CSSProp }>`
  margin: 0 auto;
  height: 100%;
  max-width: ${getSize(1440)};

  ${({ $CSS }) => $CSS}
`;

export default AuthLayout;
