const SCROLL_RATIO = 0.9;

export function getScrollPosition(
  wrapper: HTMLDivElement | HTMLUListElement,
  ratio = SCROLL_RATIO,
) {
  const { scrollTop, scrollHeight, clientHeight } = wrapper;
  const positiveScrollTop = Math.abs(scrollTop);

  const isNearTop = scrollHeight - scrollHeight * ratio > positiveScrollTop;
  const isNearBottom =
    (scrollHeight - clientHeight - 1) * ratio < positiveScrollTop;
  const isOnTop = positiveScrollTop === 0;
  const isOnBottom = positiveScrollTop === scrollHeight - clientHeight;

  return { isNearTop, isNearBottom, isOnTop, isOnBottom };
}
