import React, { Dispatch, FC, SetStateAction } from 'react';
import { PatientAdminOrder, PatientAdminSort } from '../../__generated__/types';
import { ArrowDownIcon, DropDownFilter, Loader } from '../../ui';
import styled from 'styled-components';
import { getSize } from '../../lib/utils';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PatientFragment } from '../../common/query/__generated__/get-patients-by-request';
import { PatientCard } from '../patient-card';
import { RowTemplate } from './components/row-template';

export const revertedDirection = {
  [PatientAdminSort.Asc]: PatientAdminSort.Desc,
  [PatientAdminSort.Desc]: PatientAdminSort.Asc,
};
export interface PatientsListProps {
  dataLength: number;
  onNext: () => void;
  hasMore: boolean;
  patients?: PatientFragment[];
  isLoading?: boolean;
  modalAction: (patientId: string, isBan: boolean) => void;
  onUnbanPatient: (patientId: string) => void;
  setSortOption: Dispatch<SetStateAction<PatientAdminOrder>>;
  setSortDirection: Dispatch<SetStateAction<PatientAdminSort>>;
  sortDirection: PatientAdminSort;
  sortOption: PatientAdminOrder;
}
const PatientsList: FC<PatientsListProps> = ({
  onNext,
  dataLength,
  hasMore,
  patients,
  isLoading,
  setSortOption,
  setSortDirection,
  sortDirection,
  sortOption,
  modalAction,
  onUnbanPatient,
}) => {
  const handleSort = (option: PatientAdminOrder) => {
    if (sortOption !== option) {
      setSortOption(option);
      setSortDirection(PatientAdminSort.Asc);
    } else {
      setSortDirection(revertedDirection[sortDirection]);
    }
  };
  const renderArrow = (option: PatientAdminOrder) => {
    return (
      <Icon direction={sortDirection} active={sortOption === option}>
        <ArrowDownIcon
          height={getSize(12)}
          width={getSize(12)}
          stroke={sortOption === option ? '#000' : '#97909E'}
        />
      </Icon>
    );
  };

  const sortOptions = [
    { value: PatientAdminSort.Desc, label: 'Sort from new to old' },
    { value: PatientAdminSort.Asc, label: 'Sort from old to new' },
  ];

  return (
    <>
      <Wrapper>
        <HeaderWrapper>
          <DropDownFilterWrapper>
            <DropDownFilter
              title={
                sortOption === PatientAdminOrder.FromOldToNew
                  ? 'Sort from old to new'
                  : 'Sort from new to old'
              }
              buttonCSS={
                sortOption !== PatientAdminOrder.FromOldToNew &&
                sortOption !== PatientAdminOrder.FromNewToOld
                  ? INACTIVE_SORT_CSS
                  : {}
              }
              options={sortOptions}
              value={
                sortOption === PatientAdminOrder.FromOldToNew ||
                sortOption === PatientAdminOrder.FromNewToOld
                  ? sortDirection
                  : {}
              }
              setValue={(value) => {
                console.log('value', value);
                setSortDirection(value as PatientAdminSort);
                setSortOption(
                  value === PatientAdminSort.Asc
                    ? PatientAdminOrder.FromOldToNew
                    : PatientAdminOrder.FromNewToOld,
                );
              }}
            />
          </DropDownFilterWrapper>
          <TableHeader>
            <TableHeaderText>Patients</TableHeaderText>
            <TableHeaderText disabled={isLoading}>Email</TableHeaderText>
            <TableHeaderText disabled={isLoading}>Signed up</TableHeaderText>
            <TableHeaderText disabled={isLoading}>1st session</TableHeaderText>
            <TableHeaderText
              disabled={isLoading}
              active={sortOption === PatientAdminOrder.ByTherapistName}
              onClick={() => handleSort(PatientAdminOrder.ByTherapistName)}>
              Therapist
              {renderArrow(PatientAdminOrder.ByTherapistName)}
            </TableHeaderText>
            <TableHeaderText disabled={isLoading}>Previous</TableHeaderText>
            <TableHeaderText
              disabled={isLoading}
              active={sortOption === PatientAdminOrder.ByUpcomingSession}
              onClick={() => handleSort(PatientAdminOrder.ByUpcomingSession)}>
              Upcoming
              {renderArrow(PatientAdminOrder.ByUpcomingSession)}
            </TableHeaderText>
            <TableHeaderText
              disabled={isLoading}
              active={sortOption === PatientAdminOrder.BySessionRequestStatus}
              onClick={() =>
                handleSort(PatientAdminOrder.BySessionRequestStatus)
              }>
              Status
              {renderArrow(PatientAdminOrder.BySessionRequestStatus)}
            </TableHeaderText>
            <TableHeaderText disabled={isLoading}> </TableHeaderText>
          </TableHeader>
        </HeaderWrapper>
        <ScrollWrapper id="scrollableChatsList">
          {isLoading ? (
            <Loader hasFillWholeBlock size={50} />
          ) : (
            <PatientList
              dataLength={dataLength}
              next={onNext}
              hasMore={hasMore}
              scrollableTarget="scrollableChatsList"
              loader={<Loader />}>
              {patients?.map((patient) => (
                <PatientCard
                  key={patient.id}
                  patient={patient}
                  modalAction={modalAction}
                  onUnbanPatient={() => onUnbanPatient(patient.id)}
                />
              ))}
            </PatientList>
          )}
        </ScrollWrapper>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  padding: ${getSize(20)} 0;
`;
const HeaderWrapper = styled.div`
  padding: 0 ${getSize(40)};
`;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore-next-line
const PatientList = styled(InfiniteScroll)`
  padding: ${getSize(20)}${getSize(40)};
`;

const ScrollWrapper = styled.div`
  overflow: auto;
  margin-top: ${getSize(12)};
  height: ${getSize(500)};

  &::-webkit-scrollbar {
    display: none;
  }
`;

const DropDownFilterWrapper = styled.div`
  margin-bottom: 20px;
`;

const TableHeader = styled(RowTemplate)`
  height: ${getSize(52)};
  background-color: var(--purple11);
  border-radius: ${getSize(8)};
`;

const TableHeaderText = styled.button<{ active?: boolean }>`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  text-align: center;
  color: var(--gray7);
  display: flex;
  align-items: center;
  color: ${({ active }) => (active ? 'var(--black)' : 'var(--gray7)')};

  &:first-child {
    padding-left: ${getSize(24)};
    text-align: left;
  }
`;

const Icon = styled.div<{ direction: PatientAdminSort; active: boolean }>`
  margin-left: 0px;
  ${({ direction, active }) =>
    active &&
    direction === PatientAdminSort.Asc &&
    'transform: rotate(180deg)'};
`;
const INACTIVE_SORT_CSS = {
  color: '#97909E',
};
export default PatientsList;
