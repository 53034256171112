import React from 'react';

function InPersonIcon({ ...rest }) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...rest}>
      <path
        d="M7.086 8.571c0-1.64 1.355-2.971 3.027-2.971h11.774c1.672 0 3.027 1.33 3.027 2.971v14.857c0 1.642-1.355 2.972-3.027 2.972H10.113c-1.672 0-3.027-1.33-3.027-2.971V8.57z"
        fill="#6B4EE6"
      />
      <path
        d="M16 16.743a2.971 2.971 0 100-5.943 2.971 2.971 0 000 5.943zM12.286 19.714c0-.82.665-1.485 1.485-1.485h4.457a1.486 1.486 0 010 2.971h-4.457c-.82 0-1.485-.665-1.485-1.486z"
        fill="#fff"
      />
    </svg>
  );
}

export default InPersonIcon;
