import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ReadLogsVariables = Types.Exact<{
  type: Types.LogType;
}>;


export type ReadLogs = (
  { __typename: 'Mutation' }
  & { readLogs: (
    { __typename: 'LogsIndicatorsModel' }
    & Pick<Types.LogsIndicatorsModel, 'validateCount' | 'submitCount' | 'statusCount' | 'all'>
  ) }
);


export const ReadLogsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ReadLogs"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"type"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LogType"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"readLogs"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"type"},"value":{"kind":"Variable","name":{"kind":"Name","value":"type"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"validateCount"}},{"kind":"Field","name":{"kind":"Name","value":"submitCount"}},{"kind":"Field","name":{"kind":"Name","value":"statusCount"}},{"kind":"Field","name":{"kind":"Name","value":"all"}}]}}]}}]} as unknown as DocumentNode;
export type ReadLogsMutationFn = Apollo.MutationFunction<ReadLogs, ReadLogsVariables>;

/**
 * __useReadLogs__
 *
 * To run a mutation, you first call `useReadLogs` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadLogs` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readLogs, { data, loading, error }] = useReadLogs({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useReadLogs(baseOptions?: Apollo.MutationHookOptions<ReadLogs, ReadLogsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadLogs, ReadLogsVariables>(ReadLogsDocument, options);
      }
export type ReadLogsHookResult = ReturnType<typeof useReadLogs>;
export type ReadLogsMutationResult = Apollo.MutationResult<ReadLogs>;
export type ReadLogsMutationOptions = Apollo.BaseMutationOptions<ReadLogs, ReadLogsVariables>;