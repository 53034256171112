import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RemovePatientByIdVariables = Types.Exact<{
  input: Types.PatientRemoveInput;
}>;


export type RemovePatientById = (
  { __typename: 'Mutation' }
  & Pick<Types.Mutation, 'adminRemovePatientById'>
);


export const RemovePatientByIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemovePatientById"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PatientRemoveInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminRemovePatientById"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type RemovePatientByIdMutationFn = Apollo.MutationFunction<RemovePatientById, RemovePatientByIdVariables>;

/**
 * __useRemovePatientById__
 *
 * To run a mutation, you first call `useRemovePatientById` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePatientById` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePatientById, { data, loading, error }] = useRemovePatientById({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemovePatientById(baseOptions?: Apollo.MutationHookOptions<RemovePatientById, RemovePatientByIdVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePatientById, RemovePatientByIdVariables>(RemovePatientByIdDocument, options);
      }
export type RemovePatientByIdHookResult = ReturnType<typeof useRemovePatientById>;
export type RemovePatientByIdMutationResult = Apollo.MutationResult<RemovePatientById>;
export type RemovePatientByIdMutationOptions = Apollo.BaseMutationOptions<RemovePatientById, RemovePatientByIdVariables>;