import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AdminUnarchiveCompanyVariables = Types.Exact<{
  companyId: Types.Scalars['String'];
}>;


export type AdminUnarchiveCompany = (
  { __typename: 'Mutation' }
  & Pick<Types.Mutation, 'unarchiveCompany'>
);


export const AdminUnarchiveCompanyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AdminUnarchiveCompany"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"unarchiveCompany"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"companyId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyId"}}}]}]}}]} as unknown as DocumentNode;
export type AdminUnarchiveCompanyMutationFn = Apollo.MutationFunction<AdminUnarchiveCompany, AdminUnarchiveCompanyVariables>;

/**
 * __useAdminUnarchiveCompany__
 *
 * To run a mutation, you first call `useAdminUnarchiveCompany` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUnarchiveCompany` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUnarchiveCompany, { data, loading, error }] = useAdminUnarchiveCompany({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAdminUnarchiveCompany(baseOptions?: Apollo.MutationHookOptions<AdminUnarchiveCompany, AdminUnarchiveCompanyVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUnarchiveCompany, AdminUnarchiveCompanyVariables>(AdminUnarchiveCompanyDocument, options);
      }
export type AdminUnarchiveCompanyHookResult = ReturnType<typeof useAdminUnarchiveCompany>;
export type AdminUnarchiveCompanyMutationResult = Apollo.MutationResult<AdminUnarchiveCompany>;
export type AdminUnarchiveCompanyMutationOptions = Apollo.BaseMutationOptions<AdminUnarchiveCompany, AdminUnarchiveCompanyVariables>;