import React, { ReactNode } from 'react';
import styled, { CSSProp } from 'styled-components';
import { getSize } from 'lib/utils';
import { ShadowWrapper } from 'ui/shadow-wrapper';
import { SearchForm } from 'components/search-form';

export interface PaperProps {
  children: ReactNode;
  title?: string;
  className?: string;
  titleWrapperCSS?: CSSProp;
  titleCSS?: CSSProp;
  rootCSS?: CSSProp;
  isRequest?: boolean;
  searchRequest: (search?: string) => void;
}

function Paper({
  children,
  title,
  className,
  titleWrapperCSS,
  titleCSS,
  rootCSS,
  isRequest,
  searchRequest,
}: PaperProps) {
  return (
    <Wrapper $CSS={rootCSS}>
      <Inner asElement="section" className={className} $isRequest={isRequest}>
        <TitleWrapper $CSS={titleWrapperCSS}>
          {title && <Title $CSS={titleCSS}>{title}</Title>}
          <SearchForm onFormSubmit={searchRequest} />
        </TitleWrapper>
        {children}
      </Inner>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ $CSS?: CSSProp }>`
  display: flex;
  padding: ${getSize(50)} ${getSize(56)} ${getSize(111)};
  ${({ $CSS }) => $CSS}
`;

const Inner = styled(ShadowWrapper)<{ $isRequest?: boolean }>`
  position: relative;
  padding: ${getSize(28)} ${getSize(0)}
    ${({ $isRequest }) => ($isRequest ? getSize(0) : getSize(40))};
  max-width: ${getSize(1076)};
  width: 100%;
`;

const TitleWrapper = styled.div<{ $CSS?: CSSProp }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${getSize(40)};

  ${({ $CSS }) => $CSS}
`;

const Title = styled.h1<{ $CSS?: CSSProp }>`
  font-weight: 600;
  font-size: ${getSize(18)};
  line-height: ${getSize(32)};
  color: var(--black3);

  ${({ $CSS }) => $CSS}
`;

export default Paper;
