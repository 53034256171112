import React from 'react';
import styled, { CSSProp } from 'styled-components';
import { getSize } from 'lib/utils';
import { DEFAULT_AVATAR } from 'lib/constants/therapist';

interface UserShortInfoProps {
  avatar: string;
  titleCSS?: CSSProp;
  profession?: string;
  name: string;
}

function UserShortInfo({
  avatar,
  titleCSS,
  name,
  profession,
}: UserShortInfoProps) {
  return (
    <>
      <Avatar src={avatar || DEFAULT_AVATAR} alt="Therapist avatar" />
      <InfoBlock $CSS={titleCSS}>
        <Text>{name}</Text>
        {profession && <ProfessionText>{profession}</ProfessionText>}
      </InfoBlock>
    </>
  );
}

const Avatar = styled.img`
  flex: 0 0 ${getSize(40)};
  width: ${getSize(40)};
  height: ${getSize(40)};
  border-radius: 50%;
  object-fit: cover;
  margin: 0 ${getSize(16)} 0 0;
`;

const InfoBlock = styled.div<{ $CSS?: CSSProp }>`
  display: flex;
  flex-direction: column;
  margin: 0 ${getSize(16)} 0 0;
  ${({ $CSS }) => $CSS}
`;

const Text = styled.p<{ $width?: number }>`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--black3);
  width: ${({ $width }) => ($width ? getSize($width) : '100%')};
  overflow-wrap: break-word;
`;

const ProfessionText = styled(Text)`
  color: var(--gray7);
`;

export default UserShortInfo;
