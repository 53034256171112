import { CancellationsPage } from 'pages/cancellations-page';
import TherapistsPage from 'pages/therapists-page';
import FeedbackPage from 'pages/feedback-page-old';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { dashboardRoutes } from 'routes/root-routes';

const { therapists, feedback } = dashboardRoutes;

const TherapistsRoutes = () => {
  return (
    <Route>
      <Switch>
        <Route exact path={therapists.PATH}>
          <Redirect to={therapists.pathBuild('list')} />
        </Route>
        <Route
          exact
          path={therapists.pathBuild('list')}
          component={TherapistsPage}
        />
        <Route
          exact
          path={therapists.pathBuild('cancellations')}
          component={CancellationsPage}
        />
        {/* <Route path={feedback.pathBuild(':id')} component={FeedbackPage} /> */}
      </Switch>
    </Route>
  );
};

export default TherapistsRoutes;
