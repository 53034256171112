import React from 'react';
import { getSize } from 'lib/utils';

interface MailIconProps {
  width?: number;
  height?: number;
  pathColor?: string;
}

function MailIcon({
  width = 24,
  height = 24,
  pathColor = '#E5E4E8',
}: MailIconProps) {
  return (
    <svg
      width={getSize(width)}
      height={getSize(height)}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 3.75C2.58921 3.75 2.25 4.08921 2.25 4.5V13.5C2.25 13.9108 2.58921 14.25 3 14.25H15C15.4108 14.25 15.75 13.9108 15.75 13.5V4.5C15.75 4.08921 15.4108 3.75 15 3.75H3ZM0.75 4.5C0.75 3.26079 1.76079 2.25 3 2.25H15C16.2392 2.25 17.25 3.26079 17.25 4.5V13.5C17.25 14.7392 16.2392 15.75 15 15.75H3C1.76079 15.75 0.75 14.7392 0.75 13.5V4.5Z"
        fill={pathColor}
      />
      <path
        d="M0.885518 4.06997C1.12305 3.73063 1.5907 3.6481 1.93004 3.88564L8.99994 8.83457L16.0698 3.88564C16.4092 3.6481 16.8768 3.73063 17.1144 4.06997C17.3519 4.4093 17.2694 4.87695 16.93 5.11449L9.43004 10.3645C9.1718 10.5453 8.82808 10.5453 8.56984 10.3645L1.06985 5.11449C0.730509 4.87695 0.647983 4.4093 0.885518 4.06997Z"
        fill={pathColor}
      />
    </svg>
  );
}

export default MailIcon;
