import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AdminReadSpecificLogVariables = Types.Exact<{
  logId: Types.Scalars['UUID'];
  type: Types.LogType;
}>;


export type AdminReadSpecificLog = (
  { __typename: 'Mutation' }
  & Pick<Types.Mutation, 'readSpecificLog'>
);


export const AdminReadSpecificLogDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AdminReadSpecificLog"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"logId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"type"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LogType"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"readSpecificLog"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"logId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"logId"}}},{"kind":"Argument","name":{"kind":"Name","value":"type"},"value":{"kind":"Variable","name":{"kind":"Name","value":"type"}}}]}]}}]} as unknown as DocumentNode;
export type AdminReadSpecificLogMutationFn = Apollo.MutationFunction<AdminReadSpecificLog, AdminReadSpecificLogVariables>;

/**
 * __useAdminReadSpecificLog__
 *
 * To run a mutation, you first call `useAdminReadSpecificLog` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminReadSpecificLog` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminReadSpecificLog, { data, loading, error }] = useAdminReadSpecificLog({
 *   variables: {
 *      logId: // value for 'logId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAdminReadSpecificLog(baseOptions?: Apollo.MutationHookOptions<AdminReadSpecificLog, AdminReadSpecificLogVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminReadSpecificLog, AdminReadSpecificLogVariables>(AdminReadSpecificLogDocument, options);
      }
export type AdminReadSpecificLogHookResult = ReturnType<typeof useAdminReadSpecificLog>;
export type AdminReadSpecificLogMutationResult = Apollo.MutationResult<AdminReadSpecificLog>;
export type AdminReadSpecificLogMutationOptions = Apollo.BaseMutationOptions<AdminReadSpecificLog, AdminReadSpecificLogVariables>;