import { composeValidators, getSize, validationRules } from 'lib/utils';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import {
  AddCompanyInput,
  CompanyPlanType,
  IntegrationType,
  PlanDurationType,
  SessionType,
} from '__generated__/types';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import { SingleSelect } from 'ui/form-elements/single-select';
import { bookingSelectStyles } from 'ui/form-elements/single-select/utils/styles';
import { DatePickerInput, Input } from 'ui';
import button from 'ui/button/button';
import { transformEnumToOptions } from 'lib/utils/form';
import { useGetInsuranceCompanies } from 'common/query/__generated__/get-all-insurance-companies';
import { useAdminAddCompany } from 'pages/business-page/mutation/__generated__/admin-add-company';
import { notifyError } from 'lib/utils/notification';
import { Option } from '../../../../../ui/drop-down-filter/drop-down-filter';
import { MultiSelect, Option as MultiSelectOption } from './MultiSelect';
import { useProfessionalTitles } from 'common/query/__generated__/professional-titles';

export interface CompanyFormValues {
  activatedAt: string;
  duration: PlanDurationType;
  emailDomain1: string;
  emailDomain2: string;
  emailDomain3: string;
  emailDomain4: string;
  emailDomain5: string;
  emailDomain6: string;
  emailDomain7: string;
  emailDomain8: string;
  emailDomain9: string;
  emailDomain10: string;
  employeeLimit?: number;
  planStartDate?: string;
  insurer: string;
  pricePerSession: string;
  integrationType: IntegrationType;
  invoiceEmail: string;
  managerEmail: string;
  managerName: string;
  name: string;
  planType: CompanyPlanType;
  totalEmployees: number;
  isInsuranceVisible: boolean;
  isUnlimited: boolean;
  isSlackVisible: boolean;
  isTeamsVisible: boolean;
  isTeamsBotVisible: boolean;
  month: number;
  year: number;
  slackName: string;
  slackDomain: string;
  slackEmail: string;
  teamsWebhook: string;
  isSessionsPerCompany: boolean;
  isPricePerSessionVisible: boolean;
  durationOptions: Option[];
  sessionProfessionalTitles: MultiSelectOption[];
  sessionTypes: MultiSelectOption[];
  sessionDurations: MultiSelectOption[];
}

const timezoneOptions = [
  {
    value: 'option 1',
    label: 'option 1',
  },
  {
    value: 'option 2',
    label: 'option 2',
  },
  {
    value: 'option 3',
    label: 'option 3',
  },
];

const INPUT_CSS = css`
  border: 1px solid var(--gray19);
  border-radius: ${getSize(8)};
  padding: ${getSize(6)} ${getSize(12)};
  min-height: ${getSize(47)};
  font-weight: 500;
  font-size: ${getSize(13)};
  line-height: ${getSize(24)};
  overflow: visible;

  transition: 0.3s ease-out;

  &:focus {
    border-color: var(--purple);
  }

  &:hover:not(:focus) {
    border-color: var(--black10);
  }

  &:-webkit-autofill:focus {
    transition: 0.3s ease-out;
    border: 1px solid var(--purple) !important;
  }
  &:-webkit-autofill:hover:not(:-webkit-autofill:focus) {
    transition: 0.3s ease-out;
    border: 1px solid var(--black10) !important;
  }
  &:-webkit-autofill {
    transition: 0.3s ease-out;
    border: 1px solid var(--gray36) !important;
  }
`;

const ROOT_CSS = css`
  margin-bottom: ${getSize(10)};
`;

const ROOT_CHECKBOX_CSS = css`
  margin-bottom: ${getSize(10)};
  padding: 8px 17px;
  border: 1px solid black;
  color: #6b4ee6;
  background: #fbfaff;
  border: 1px solid #b7aaec;
  border-radius: 25px;

  & span {
    color: #6b4ee6;
  }
`;

const ROOT_CHECKBOX_CSS_DISABLED = css`
  margin-bottom: ${getSize(10)};
  padding: 8px 17px;
  color: #bfbfbf;
  background: #fbfaff;
  border: none;
  border-radius: 25px;

  & span {
    color: #bfbfbf;
  }

  & div:hover span {
    color: #bfbfbf;
  }

  &:hover span {
    color: #bfbfbf;
    cursor: not-allowed;
  }
`;

interface AddCompanyFormProps {
  onCloseModal: () => void;
  onRefetchCompanies: () => void;
}

export const AddCompanyForm: FC<AddCompanyFormProps> = ({
  onCloseModal,
  onRefetchCompanies,
}) => {
  const [addCompany, { loading: addCompanyLoading }] = useAdminAddCompany();
  const { data } = useProfessionalTitles({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
  });
  const sessionProfessionalTitlesOptions = (data?.professionalTitles || []).map(
    (pt) => ({
      value: pt.id,
      label: pt.label,
    }),
  );
  const sessionDurationsOptions = [
    { value: 30, label: '30 Minutes' },
    { value: 50, label: '50 Minutes' },
    { value: 80, label: '80 Minutes' },
  ];
  const sessionTypesOptions = [
    { value: SessionType.InPerson, label: 'In Person' },
    { value: SessionType.VideoCall, label: 'Video Call' },
    { value: SessionType.LiveChat, label: 'Live Chat' },
  ];
  const handleFormSubmit = async (values: CompanyFormValues) => {
    let fromDate;

    if (values.planType === CompanyPlanType.SubscriptionPerCompany) {
      if (!values.planStartDate) {
        throw new Error('Plan start date missing');
      }
      const splitDate = values.planStartDate.split('-');
      fromDate =
        new Date(
          parseInt(splitDate[0]),
          parseInt(splitDate[1]) - 1,
          parseInt(splitDate[2]),
          0,
          0,
          0,
          0,
        ).getTime() -
        new Date().getTimezoneOffset() * 60000;
    } else {
      fromDate =
        new Date(values.year, values.month, 1, 0, 0, 0, 0).getTime() -
        new Date().getTimezoneOffset() * 60000;
    }

    const isSlackIntegration = values.integrationType === IntegrationType.Slack;
    const isTeamsBotIntegration =
      values.integrationType === IntegrationType.TeamsBot;

    const sessionDurations = values.sessionDurations.map((sd) =>
      parseInt(sd.value, 10),
    );
    const sessionProfessionalTitles = values.sessionProfessionalTitles.map(
      (pt) => pt.value,
    );
    const sessionTypes = values.sessionTypes.map(
      (st) => st.value as unknown as SessionType,
    );

    const sendData: AddCompanyInput = {
      name: values.name,
      emailDomains: [
        values.emailDomain1,
        values.emailDomain2,
        values.emailDomain3,
        values.emailDomain4,
        values.emailDomain5,
        values.emailDomain6,
        values.emailDomain7,
        values.emailDomain8,
        values.emailDomain9,
        values.emailDomain10,
      ].filter((domain) => domain !== undefined),
      totalEmployees: +values.totalEmployees,
      invoiceEmail: values.invoiceEmail,
      integrationType: values.integrationType,
      manager: { name: values.managerName, email: values.managerEmail },
      plan: {
        planType: values.planType,
        employeeLimit: values.employeeLimit ? +values.employeeLimit : undefined,
        activatedAt: new Date(fromDate).toISOString(),
        durationType: values.duration,
        insuranceCompanyId: values.insurer,
        sessionPrice: +values.pricePerSession,
      },
      slackManager:
        isSlackIntegration || isTeamsBotIntegration
          ? {
              name: values.slackName,
              email: values.slackEmail,
            }
          : undefined,
      slackDomain:
        isSlackIntegration || isTeamsBotIntegration
          ? values.slackDomain
          : undefined,
      teamsWebhook: values.teamsWebhook,
      sessionRestrictions: {
        sessionTypes,
        sessionDurations,
        sessionProfessionalTitles,
      },
    };

    try {
      await addCompany({
        variables: {
          input: sendData,
        },
      }).then((response) => {
        const validateErrors = response.data?.adminAddCompany.userErrors;
        if (validateErrors?.length) {
          notifyError({ text: validateErrors[0].messages[0] });
        }
      });
    } catch (error: any) {
      notifyError({ text: error?.message });
    } finally {
      onRefetchCompanies();
      onCloseModal();
    }
  };

  const { data: insuranceCompaniesData } = useGetInsuranceCompanies();

  const insuranceOptions = insuranceCompaniesData?.insuranceCompanies.map(
    (insuranceCompany) => {
      return {
        value: insuranceCompany.id,
        label: insuranceCompany.label,
      };
    },
  );

  const months = Array.from(Array(12).keys()).map((key) => {
    return {
      value: String(key),
      label: new Date(0, key).toLocaleString('en', { month: 'long' }),
    };
  });
  const year = Array.from(Array(7).keys()).map((key) => {
    return {
      value: new Date().getFullYear() + key,
      label: new Date().getFullYear() + key,
    };
  });

  const initialValues = {
    sessionTypes: [],
    sessionDurations: [],
    sessionProfessionalTitles: [],
  };

  return (
    <FinalForm
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, hasValidationErrors, dirty, values, form }) => (
        <Wrapper>
          <FormSpy
            onChange={({ values }) => {
              const isTypePlanInsurance =
                values.planType === CompanyPlanType.Mixed ||
                values.planType === CompanyPlanType.Insurance ||
                values.planType === CompanyPlanType.InsuranceLimited;

              const isTypePlanSession =
                values.planType === CompanyPlanType.LimitedPerEmployee ||
                values.planType === CompanyPlanType.Unlimited;

              const isUnlimited =
                values.planType === CompanyPlanType.Unlimited ||
                values.planType === CompanyPlanType.Insurance;

              const isTypeIntegrationSlack =
                values.integrationType === IntegrationType.Slack;

              const isTypeIntegrationTeams =
                values.integrationType === IntegrationType.Teams;

              const isTypeIntegrationTeamsBot =
                values.integrationType === IntegrationType.TeamsBot;

              const isSessionsPerCompany =
                values.planType === CompanyPlanType.SubscriptionPerCompany ||
                values.planType === CompanyPlanType.LimitedPerCompany;

              const isPricePerSessionVisible =
                values.planType === CompanyPlanType.SubscriptionPerCompany ||
                values.planType === CompanyPlanType.SubscriptionPerEmployee;

              switch (values.planType) {
                case CompanyPlanType.SubscriptionPerEmployee:
                  values.durationOptions = transformEnumToOptions(
                    PlanDurationType,
                  ).filter(
                    (option) =>
                      option.value != 'QUARTERLY' && option.value != 'ANNUAL',
                  );
                  break;
                case CompanyPlanType.SubscriptionPerCompany:
                case CompanyPlanType.LimitedPerCompany:
                  values.durationOptions =
                    transformEnumToOptions(PlanDurationType);
                  break;
                default:
                  values.durationOptions = transformEnumToOptions(
                    PlanDurationType,
                  ).filter((option) => option.value != 'MONTHLY');
                  break;
              }

              values.isSessionsPerCompany = isSessionsPerCompany;
              values.isPricePerSessionVisible = isPricePerSessionVisible;
              values.isUnlimited = isUnlimited;
              values.isInsuranceVisible = isTypePlanInsurance;
              values.isSlackVisible = isTypeIntegrationSlack;
              values.isTeamsVisible = isTypeIntegrationTeams;
              values.isTeamsBotVisible = isTypeIntegrationTeamsBot;
            }}
          />
          <Field
            name="name"
            label="Company"
            placeholder="Enter company name"
            validate={validationRules.required}
            inputCSS={INPUT_CSS}
            rootCSS={ROOT_CSS}
            hasLine={false}
            component={Input}
          />
          <Field
            name="managerName"
            label="Contact person"
            placeholder="Enter contact person name"
            validate={validationRules.required}
            inputCSS={INPUT_CSS}
            rootCSS={ROOT_CSS}
            hasLine={false}
            component={Input}
          />
          <Field
            name="managerEmail"
            label="Contact email"
            placeholder="Enter contact email"
            validate={validationRules.required}
            inputCSS={INPUT_CSS}
            rootCSS={ROOT_CSS}
            hasLine={false}
            component={Input}
          />

          <Field
            name="emailDomain1"
            label="Email Domain 1"
            placeholder="@your_domain"
            validate={validationRules.required}
            inputCSS={INPUT_CSS}
            rootCSS={ROOT_CSS}
            hasLine={false}
            component={Input}
          />
          <Field
            name="emailDomain2"
            label="Email Domain 2"
            placeholder="@your_domain"
            inputCSS={INPUT_CSS}
            rootCSS={ROOT_CSS}
            hasLine={false}
            component={Input}
          />
          <Field
            name="emailDomain3"
            label="Email Domain 3"
            placeholder="@your_domain"
            inputCSS={INPUT_CSS}
            rootCSS={ROOT_CSS}
            hasLine={false}
            component={Input}
          />
          <Field
            name="emailDomain4"
            label="Email Domain 4"
            placeholder="@your_domain"
            inputCSS={INPUT_CSS}
            rootCSS={ROOT_CSS}
            hasLine={false}
            component={Input}
          />
          <Field
            name="emailDomain5"
            label="Email Domain 5"
            placeholder="@your_domain"
            inputCSS={INPUT_CSS}
            rootCSS={ROOT_CSS}
            hasLine={false}
            component={Input}
          />
          <Field
            name="emailDomain6"
            label="Email Domain 6"
            placeholder="@your_domain"
            inputCSS={INPUT_CSS}
            rootCSS={ROOT_CSS}
            hasLine={false}
            component={Input}
          />
          <Field
            name="emailDomain7"
            label="Email Domain 7"
            placeholder="@your_domain"
            inputCSS={INPUT_CSS}
            rootCSS={ROOT_CSS}
            hasLine={false}
            component={Input}
          />
          <Field
            name="emailDomain8"
            label="Email Domain 8"
            placeholder="@your_domain"
            inputCSS={INPUT_CSS}
            rootCSS={ROOT_CSS}
            hasLine={false}
            component={Input}
          />
          <Field
            name="emailDomain9"
            label="Email Domain 9"
            placeholder="@your_domain"
            inputCSS={INPUT_CSS}
            rootCSS={ROOT_CSS}
            hasLine={false}
            component={Input}
          />
          <Field
            name="emailDomain10"
            label="Email Domain 10"
            placeholder="@your_domain"
            inputCSS={INPUT_CSS}
            rootCSS={ROOT_CSS}
            hasLine={false}
            component={Input}
          />
          <Field
            name="invoiceEmail"
            label="Invoice email"
            placeholder="Enter invoice email"
            inputCSS={INPUT_CSS}
            rootCSS={ROOT_CSS}
            hasLine={false}
            component={Input}
            validate={validationRules.required}
          />
          <Field
            name="totalEmployees"
            label="Total employees"
            placeholder="Enter total employees"
            inputCSS={INPUT_CSS}
            rootCSS={ROOT_CSS}
            hasLine={false}
            type="number"
            component={Input}
            validate={composeValidators(
              validationRules.required,
              validationRules.positiveValue,
            )}
          />
          <LineSeparator />
          <Field
            name="integrationType"
            label="Integration Type"
            options={transformEnumToOptions(IntegrationType)}
            customSelectStyles={bookingSelectStyles}
            isSearchable={false}
            inputCSS={INPUT_CSS}
            rootCSS={ROOT_CSS}
            hasLine={false}
            validate={validationRules.required}
            component={SingleSelect}
          />
          {values.isSlackVisible && (
            <>
              <Field
                name="slackName" // Slack manager name
                label="Slack name"
                placeholder="Enter Slack name"
                inputCSS={INPUT_CSS}
                rootCSS={ROOT_CSS}
                hasLine={false}
                component={Input}
              />
              <Field
                name="slackDomain"
                label="Slack URL"
                placeholder="Enter Slack URL"
                inputCSS={INPUT_CSS}
                rootCSS={ROOT_CSS}
                hasLine={false}
                component={Input}
              />
              <Field
                name="slackEmail" // Slack manager email
                label="Slack email"
                placeholder="Enter Slack email"
                inputCSS={INPUT_CSS}
                rootCSS={ROOT_CSS}
                hasLine={false}
                component={Input}
              />
            </>
          )}
          {values.isTeamsBotVisible && (
            <>
              <Field
                name="slackName"
                label="Teams manager name"
                placeholder="Enter Teams name"
                inputCSS={INPUT_CSS}
                rootCSS={ROOT_CSS}
                hasLine={false}
                component={Input}
              />
              <Field
                name="slackDomain"
                label="Teams email domain"
                placeholder="Enter Teams email domain"
                inputCSS={INPUT_CSS}
                rootCSS={ROOT_CSS}
                hasLine={false}
                component={Input}
              />
              <Field
                name="slackEmail"
                label="Teams manager email"
                placeholder="Enter teams manager email"
                inputCSS={INPUT_CSS}
                rootCSS={ROOT_CSS}
                hasLine={false}
                component={Input}
              />
            </>
          )}
          {values.isTeamsVisible && (
            <Field
              name="teamsWebhook"
              label="Teams Webhook"
              placeholder="Enter Teams Webhook URL"
              inputCSS={INPUT_CSS}
              rootCSS={ROOT_CSS}
              hasLine={false}
              component={Input}
            />
          )}
          <Field
            name="planType"
            label="Plan"
            customSelectStyles={bookingSelectStyles}
            isSearchable={false}
            rootCSS={ROOT_CSS}
            inputCSS={INPUT_CSS}
            hasLine={false}
            validate={validationRules.required}
            render={({ input, meta, ref, ...outerProps }) => {
              const onChange = (e: CompanyPlanType) => {
                form.change('planType', e);
                form.change('employeeLimit', undefined);
              };
              return (
                <SingleSelect
                  selectRef={ref}
                  options={transformEnumToOptions(CompanyPlanType)}
                  input={{ ...input, onChange }}
                  meta={meta}
                  {...outerProps}
                />
              );
            }}
          />
          {values.isPricePerSessionVisible && (
            <Field
              name="pricePerSession"
              label="Price per Session"
              placeholder="Price per Session"
              inputCSS={INPUT_CSS}
              rootCSS={ROOT_CSS}
              hasLine={false}
              type="number"
              component={Input}
              validate={validationRules.required}
            />
          )}
          {values.isInsuranceVisible && (
            <Field
              name="insurer"
              label="Insurer"
              options={insuranceOptions}
              customSelectStyles={bookingSelectStyles}
              isSearchable={false}
              rootCSS={ROOT_CSS}
              inputCSS={INPUT_CSS}
              hasLine={false}
              component={SingleSelect}
              validate={validationRules.required}
            />
          )}
          <ThreeFieldWrapper>
            {values.planType === CompanyPlanType.SubscriptionPerCompany ? (
              <Field
                name="planStartDate"
                label="Plan start date"
                options={months}
                customSelectStyles={bookingSelectStyles}
                isSearchable={false}
                rootCSS={ROOT_CSS}
                inputCSS={INPUT_CSS}
                hasLine={false}
                component={DatePickerInput}
                validate={validationRules.required}
              />
            ) : (
              <MonthAndYearContainer>
                <Field
                  name="month"
                  label="Month"
                  options={months}
                  customSelectStyles={bookingSelectStyles}
                  isSearchable={false}
                  rootCSS={ROOT_CSS}
                  inputCSS={INPUT_CSS}
                  hasLine={false}
                  component={SingleSelect}
                  validate={validationRules.required}
                />
                <Field
                  name="year"
                  label="Year"
                  options={year}
                  customSelectStyles={bookingSelectStyles}
                  isSearchable={false}
                  rootCSS={ROOT_CSS}
                  inputCSS={INPUT_CSS}
                  hasLine={false}
                  component={SingleSelect}
                  validate={validationRules.required}
                />
              </MonthAndYearContainer>
            )}
            {values.planType != CompanyPlanType.Resources && (
              <Field
                name="employeeLimit"
                label={
                  values.isSessionsPerCompany
                    ? 'Sessions per company'
                    : 'Sessions per user'
                }
                options={timezoneOptions}
                customSelectStyles={bookingSelectStyles}
                isSearchable={false}
                rootCSS={ROOT_CSS}
                inputCSS={INPUT_CSS}
                hasLine={false}
                type="number"
                component={Input}
                disabled={
                  values.planType !== CompanyPlanType.Mixed &&
                  values.planType !== CompanyPlanType.LimitedPerEmployee &&
                  values.planType !== CompanyPlanType.LimitedPerCompany &&
                  values.planType !== CompanyPlanType.InsuranceLimited &&
                  values.planType !== CompanyPlanType.SubscriptionPerCompany &&
                  values.planType !== CompanyPlanType.SubscriptionPerEmployee
                }
                validate={validationRules.positiveValue}
              />
            )}
            <Field
              name="duration"
              label="Duration"
              options={values.durationOptions}
              customSelectStyles={bookingSelectStyles}
              isSearchable={false}
              rootCSS={ROOT_CSS}
              inputCSS={INPUT_CSS}
              hasLine={false}
              component={SingleSelect}
              validate={validationRules.required}
            />
          </ThreeFieldWrapper>
          <div>
            <Field
              name="sessionDurations"
              label="Session Durations"
              options={sessionDurationsOptions}
              component={MultiSelect}
              rootCSS={ROOT_CSS}
            />
          </div>
          <div>
            <Field
              name="sessionTypes"
              label="Session Types"
              options={sessionTypesOptions}
              component={MultiSelect}
              rootCSS={ROOT_CSS}
            />
          </div>
          <div>
            <Field
              name="sessionProfessionalTitles"
              label="Professional Titles"
              options={sessionProfessionalTitlesOptions}
              component={MultiSelect}
              rootCSS={ROOT_CSS}
            />
          </div>
          <ButtonFieldWrapper>
            <CreateCompanyButton
              onClick={handleSubmit}
              isLoading={addCompanyLoading}>
              Create Company
            </CreateCompanyButton>
          </ButtonFieldWrapper>
        </Wrapper>
      )}
    />
  );
};

const Wrapper = styled.div`
  padding: ${getSize(11)} ${getSize(27)} 80px ${getSize(27)};
  overflow: scroll;
`;

interface LineSeparatorProps {
  marginTop?: number;
}
const LineSeparator = styled.div<LineSeparatorProps>`
  height: 1px;
  background: #f1f1f1;
  margin-top: ${({ marginTop }) =>
    marginTop || marginTop === 0 ? marginTop : 24}px;
  margin-bottom: 20px;
`;

const ThreeFieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-column-gap: 9px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  margin-left: -15px;

  & label {
    margin-left: 15px;
  }
`;

const Title = styled.p`
  font-weight: 500;
  font-size: ${getSize(12)};
  margin-bottom: 10px;

  color: #838383;
`;

const CreateCompanyButton = styled(button)`
  bottom: 0;
  height: 42px;
  background: #6b4ee6;
  border: 1px solid #6b4ee6;
  box-sizing: border-box;
  border-radius: 34px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
  font-weight: 600;
  font-size: 14px;
`;

const ButtonFieldWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: ${getSize(11)} ${getSize(27)};
  background-color: white;
  border-radius: 10px;
  border-top: 1px solid #f1f1f1;
`;

const MonthAndYearContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 3fr 2fr;
  grid-column-gap: 9px;
`;
