import { getSize } from 'lib/utils';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ThreeDotsIcon } from 'ui/icons';

export interface ControlDropdownProps {
  children: React.ReactNode;
}

function ControlDropdown({ children }: ControlDropdownProps) {
  const [visible, setVisible] = useState(false);
  const onOutsideClick = () => setVisible(false);

  useEffect(() => {
    window.addEventListener('click', onOutsideClick);
    return () => window.removeEventListener('click', onOutsideClick);
  }, []);

  return (
    <Wrapper onClick={(e) => e.stopPropagation()}>
      <DotsButton onClick={() => setVisible(!visible)}>
        <ThreeDotsIcon />
      </DotsButton>
      {visible && <List>{children}</List>}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  margin: 0 0 0 auto;
`;

const List = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  padding: ${getSize(20)};
  background: white;
  box-shadow: 0 ${getSize(20)} ${getSize(90)} rgba(40, 31, 61, 0.06);
  border-radius: ${getSize(8)};
  z-index: 98;
  min-width: ${getSize(185)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const DotsButton = styled.button`
  padding: ${getSize(6)};
  border-radius: ${getSize(8)};
  background-color: var(--purple11);
  width: 32px;
  height: 32px;
`;

export default ControlDropdown;
