import { getSize } from 'lib/utils';
import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';

interface StrengthLineProps {
  value?: string;
}
interface StyledProps {
  $color: string;
  $coloredItems: number;
}

const COLORS = {
  RED: 'red',
  ORANGE: 'orange',
  GREEN: 'green',
};

const StrengthLine: FC<StrengthLineProps> = ({ value = '' }) => {
  const { length } = value;

  const [color, setColor] = useState(COLORS.RED);
  const [coloredItems, setColoredItems] = useState(1);

  useEffect(() => {
    let currentColor = COLORS.RED;
    let currentColoredItems = 1;
    if (length >= 8) {
      const hasUppercaseLetters = /[A-Z]/.test(value);
      const hasLowercaseLetters = /[a-z]/.test(value);
      const hasNumbers = /[0-9]/.test(value);
      const hasSpecialCharacters = /[!@#$%^&*()\-__+.]/.test(value);
      currentColoredItems = 1;

      if (hasUppercaseLetters && hasNumbers && hasLowercaseLetters) {
        currentColoredItems = 2;
        currentColor = COLORS.ORANGE;

        if (
          hasUppercaseLetters &&
          hasNumbers &&
          hasSpecialCharacters &&
          hasLowercaseLetters
        ) {
          currentColor = COLORS.GREEN;
          currentColoredItems = 3;
        }
      }
    }
    setColor(currentColor);
    setColoredItems(currentColoredItems);
  }, [length, value]);
  return (
    <Line>
      {[...Array(3)].map((_, index) => (
        <LineItem key={index} $color={color} $coloredItems={coloredItems} />
      ))}
    </Line>
  );
};

const Line = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: ${getSize(8)};
`;

const LineItem = styled.span<StyledProps>`
  width: 32%;
  height: ${getSize(2)};
  border-radius: ${getSize(4)};
  background-color: var(--purple3);

  &: nth-child(-n + ${({ $coloredItems }) => $coloredItems}) {
    background-color: ${({ $color }) => $color};
  }
`;

export default StrengthLine;
