import { FileExtension, FileType } from '__generated__/types';

export enum BUCKET_TYPES {
  REPRESENTATION = 'representation',
  QUALIFICATIONS = 'qualifications',
  MATERIALS = 'materials',
}

export interface UploadFile {
  id?: string;
  fileType: FileType;
  source: string;
  name: string;
  sizeInKb: number;
  extension: FileExtension;
}

const { Pdf, Mp4, Jpg, Jpeg, Png, Doc, Docx, Mp3 } = FileExtension;
export const ALL_FILE_TYPES = {
  [FileType.Document]: [Pdf, Doc, Docx],
  [FileType.Video]: [Mp4],
  [FileType.Image]: [Jpg, Jpeg, Png],
  [FileType.Audio]: [Mp3],
};
