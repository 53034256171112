import React from 'react';

function PhotoCameraIcon({ ...rest }) {
  return (
    <svg width={24} height={25} viewBox="0 0 24 25" fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.168 3.035c.185-.275.498-.44.832-.44h6c.334 0 .647.165.832.44l1.703 2.53H21c.796 0 1.559.314 2.121.871.563.558.879 1.313.879 2.102v10.896c0 .788-.316 1.544-.879 2.101a3.014 3.014 0 01-2.121.87H3a3.015 3.015 0 01-2.121-.87A2.958 2.958 0 010 19.434V8.538c0-.789.316-1.544.879-2.102A3.015 3.015 0 013 5.566h3.465l1.703-2.53zm1.367 1.54L7.832 7.106A1.003 1.003 0 017 7.547H3c-.265 0-.52.104-.707.29a.986.986 0 00-.293.7v10.897c0 .263.105.514.293.7.187.186.442.29.707.29h18c.265 0 .52-.104.707-.29a.986.986 0 00.293-.7V8.538a.986.986 0 00-.293-.7 1.005 1.005 0 00-.707-.29h-4c-.334 0-.647-.166-.832-.442l-1.703-2.53h-4.93z"
        fill="#B4B0BB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 10.519c-1.657 0-3 1.33-3 2.971 0 1.642 1.343 2.972 3 2.972s3-1.33 3-2.972c0-1.64-1.343-2.971-3-2.971zM7 13.49c0-2.735 2.239-4.952 5-4.952s5 2.217 5 4.952c0 2.736-2.239 4.953-5 4.953s-5-2.217-5-4.953z"
        fill="#B4B0BB"
      />
    </svg>
  );
}

export default PhotoCameraIcon;
