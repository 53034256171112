import React from 'react';

function VideoChatIcon({ ...rest }) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...rest}>
      <path
        d="M17.091 17.938c-1.455-.862-1.455-3.014 0-3.875l7.368-4.36c1.455-.861 3.274.215 3.274 1.938v8.718c0 1.723-1.819 2.799-3.274 1.938l-7.367-4.36z"
        fill="#E5E4E8"
      />
      <path
        d="M4.267 12.333c0-2.835 2.398-5.133 5.356-5.133h6.887c2.958 0 5.357 2.298 5.357 5.133v7.334c0 2.835-2.399 5.133-5.357 5.133H9.623c-2.958 0-5.356-2.298-5.356-5.133v-7.334z"
        fill="#6B4EE6"
      />
    </svg>
  );
}

export default VideoChatIcon;
