import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AdminEditTherapistProfileVariables = Types.Exact<{
  input: Types.AdminEditTherapistProfileInput;
}>;


export type AdminEditTherapistProfile = (
  { __typename: 'Mutation' }
  & Pick<Types.Mutation, 'adminEditTherapistProfile'>
);


export const AdminEditTherapistProfileDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AdminEditTherapistProfile"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AdminEditTherapistProfileInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminEditTherapistProfile"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type AdminEditTherapistProfileMutationFn = Apollo.MutationFunction<AdminEditTherapistProfile, AdminEditTherapistProfileVariables>;

/**
 * __useAdminEditTherapistProfile__
 *
 * To run a mutation, you first call `useAdminEditTherapistProfile` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditTherapistProfile` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditTherapistProfile, { data, loading, error }] = useAdminEditTherapistProfile({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminEditTherapistProfile(baseOptions?: Apollo.MutationHookOptions<AdminEditTherapistProfile, AdminEditTherapistProfileVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminEditTherapistProfile, AdminEditTherapistProfileVariables>(AdminEditTherapistProfileDocument, options);
      }
export type AdminEditTherapistProfileHookResult = ReturnType<typeof useAdminEditTherapistProfile>;
export type AdminEditTherapistProfileMutationResult = Apollo.MutationResult<AdminEditTherapistProfile>;
export type AdminEditTherapistProfileMutationOptions = Apollo.BaseMutationOptions<AdminEditTherapistProfile, AdminEditTherapistProfileVariables>;