import {
  PricesForm,
  TitleWithInfo,
} from 'components/session-price-form/components';
import React, { FC } from 'react';
import styled from 'styled-components';
import { SessionPriceAdminFragment } from 'common/fragments/__generated__/session-price-admin.fragment';
import { TherapistFragment } from 'common/query/__generated__/get-therapists-by-request';
import { notifyError, notifySuccess } from 'lib/utils/notification';
import { getFormSubmitError } from 'lib/utils/form';
import { UserError } from '__generated__/types';
import { SUCCESS_NOTIFICATION } from 'components/session-price-form/constants';
import { useAdminChangeTherapistSessionPrice } from 'common/mutation/__generated__/change-therapist-session-price';
import { Config } from 'final-form';
import { CommissionFormValues } from 'components/session-price-form/components/prices-form/prices-form';
import { CloseButton } from 'ui';

/** Mutation */
import { useAdminChangeSessionPrice } from 'common/mutation/__generated__/change-session-price';

interface TherapistEditPricesModalProps {
  pricingToEdit: SessionPriceAdminFragment;
  onClose: () => void;
}

const EditPricingModal: FC<TherapistEditPricesModalProps> = ({
  pricingToEdit,
  onClose,
}) => {
  // const [changeSessionPrice, { loading: sessionPriceLoading }] =
  //   useAdminChangeTherapistSessionPrice({
  //     onCompleted: (data) => {
  //       const validateErrors = data?.adminChangeSessionPrice?.userErrors;

  //       if (validateErrors && validateErrors?.length > 0) {
  //         notifyError({ text: 'Validate error' });
  //         return getFormSubmitError(validateErrors as UserError[]);
  //       } else {
  //         notifySuccess(SUCCESS_NOTIFICATION);
  //       }

  //       onClose();
  //     },
  //     onError: ({ name, message }) =>
  //       notifyError({ title: name, text: message }),
  //   });

  //const [changeSessionPrice] = useAdminChangeSessionPrice();
  const [changeSessionPrice, { loading: sessionPriceLoading }] =
    useAdminChangeSessionPrice({
      onCompleted: (data) => {
        const validateErrors = data?.adminChangeSessionPrice?.userErrors;

        if (validateErrors && validateErrors?.length > 0) {
          notifyError({ text: 'Validate error' });
          return getFormSubmitError(validateErrors as UserError[]);
        } else {
          notifySuccess(SUCCESS_NOTIFICATION);
        }

        onClose();
      },
      onError: ({ name, message }) =>
        notifyError({ title: name, text: message }),
    });

  /**
   * @method
   */
  const handleFormSubmit: Config<CommissionFormValues>['onSubmit'] = async (
    { minPrice, mediumPrice, maxPrice },
    form,
  ) => {
    console.log('Lets submit this form');
    console.log(minPrice, mediumPrice, maxPrice);
    console.log('ANd id ', pricingToEdit.id);
    // TODO: fix this
    await changeSessionPrice({
      variables: {
        input: {
          priceType: pricingToEdit.priceType,
          thirtyMin: Number(minPrice),
          fiftyMin: Number(mediumPrice),
          eightyMin: Number(maxPrice),
          id: pricingToEdit.id,
        },
      },
    });

    form.restart();
    return;
  };

  return (
    <ShadowWrapper>
      <Content>
        <HeaderWrapper>
          <TitleWithInfo
            title={pricingToEdit.priceType?.replace(/_/g, ' ')}
            info={`Change pricing.`}
          />

          <CloseButton onClick={onClose} />
        </HeaderWrapper>

        <PricesForm
          handleFormSubmit={handleFormSubmit}
          initialValues={{
            minPrice: pricingToEdit.thirtyMin.toString(),
            mediumPrice: pricingToEdit.fiftyMin.toString(),
            maxPrice: pricingToEdit.eightyMin.toString(),
          }}
          submitLoading={false}
        />
      </Content>
    </ShadowWrapper>
  );
};

const ShadowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.51);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const Content = styled.div`
  position: relative;
  background: #ffffff;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 15px 16px 0 16px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;

export default EditPricingModal;
