import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { getSize } from 'lib/utils';
import { MtaLogoIcon } from 'ui/icons';
import { dashboardRoutes } from '../../routes/root-routes';

export interface LogoLinkProps {
  className?: string;
  isDisabled?: boolean;
}

function LogoLink({ className, isDisabled }: LogoLinkProps) {
  function Wrapper({ ...rest }) {
    if (isDisabled) {
      return <IconWrapper {...rest} />;
    } else {
      return <LinkStylized {...rest} to={dashboardRoutes.dashboard.PATH} />;
    }
  }

  return (
    <Wrapper className={className}>
      <Icon />
      My Therapy Assistant
    </Wrapper>
  );
}

const LinkStylized = styled(Link)`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-weight: 500;
  font-size: ${getSize(13)};
  line-height: ${getSize(19)};
  color: var(--purple);
  transition: 0.3s ease-out;
  padding: ${getSize(20)} ${getSize(30)} ${getSize(58)};

  &:hover {
    color: var(--black3);

    svg {
      transform: scale(1.1);
    }
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-weight: 500;
  font-size: ${getSize(13)};
  line-height: ${getSize(19)};
  color: var(--purple);
`;

const Icon = styled(MtaLogoIcon)`
  margin: 0 ${getSize(6)} 0 0;
  width: ${getSize(44)};
  height: ${getSize(40)};
  transition: 0.3s ease-out;
`;

export default LogoLink;
