import styled from 'styled-components';

const ContainerWithoutCssReset = styled.div`
  /* Default Styles */
  a:link,
  a:visited {
    color: inherit;
    text-decoration: underline;
    cursor: auto;
  }

  a:link:active,
  a:visited:active {
    color: inherit;
  }

  abbr {
    /* None */
  }

  address {
    display: block;
    font-style: italic;
  }

  area {
    display: none;
  }

  article,
  aside,
  audio,
  base,
  bdi,
  bdo,
  blockquote,
  body,
  br,
  button,
  canvas,
  caption,
  cite,
  code,
  col,
  colgroup,
  datalist,
  dd,
  del,
  details,
  dfn,
  dialog,
  div,
  dl,
  dt,
  em,
  embed,
  fieldset,
  figcaption,
  figure,
  footer,
  form,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  head,
  header,
  hr,
  html,
  i,
  iframe,
  img,
  input,
  ins,
  kbd,
  label,
  legend,
  li,
  link,
  main,
  map,
  mark,
  menu,
  menuitem,
  meta,
  meter,
  nav,
  noscript,
  object,
  ol,
  optgroup,
  option,
  output,
  p,
  param,
  picture,
  pre,
  progress,
  q,
  rp,
  rt,
  ruby,
  s,
  samp,
  script,
  section,
  select,
  small,
  source,
  span,
  strike,
  strong,
  style,
  sub,
  summary,
  sup,
  table,
  tbody,
  td,
  template,
  textarea,
  tfoot,
  th,
  thead,
  time,
  title,
  tr,
  track,
  u,
  ul,
  var,
  video,
  wbr {
    /* Default styles */
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    outline: none;
  }

  a:focus,
  body:focus,
  html:focus,
  iframe:focus {
    outline: none;
  }

  a:link:active,
  a:visited:active {
    /* Default styles */
  }

  blockquote {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 40px;
    margin-right: 40px;
  }

  body {
    display: block;
    margin: 8px;
  }

  body:focus,
  html:focus {
    outline: none;
  }

  button {
    /* None */
  }

  canvas {
    /* None */
  }

  caption {
    display: table-caption;
    text-align: center;
  }

  cite {
    font-style: italic;
  }

  code {
    font-family: monospace;
  }

  col {
    display: table-column;
  }

  colgroup {
    display: table-column-group;
  }

  datalist {
    display: none;
  }

  dd {
    display: block;
    margin-left: 40px;
  }

  del {
    text-decoration: line-through;
  }

  details {
    display: block;
  }

  dfn {
    font-style: italic;
  }

  dialog {
    /* None */
  }

  div {
    display: block;
  }

  dl {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
  }

  dt {
    display: block;
  }

  em {
    font-style: italic;
  }

  embed:focus,
  iframe:focus {
    outline: none;
  }

  fieldset {
    display: block;
    margin-left: 2px;
    margin-right: 2px;
    padding-top: 0.35em;
    padding-bottom: 0.625em;
    padding-left: 0.75em;
    padding-right: 0.75em;
    border: 2px groove;
  }

  figcaption {
    display: block;
  }

  figure {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 40px;
    margin-right: 40px;
  }

  footer {
    display: block;
  }

  form {
    display: block;
    margin-top: 0em;
  }

  h1 {
    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h3 {
    display: block;
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h4 {
    display: block;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h5 {
    display: block;
    font-size: 0.83em;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h6 {
    display: block;
    font-size: 0.67em;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  header {
    display: block;
  }

  hr {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
  }

  html {
    display: block;
  }

  html:focus {
    outline: none;
  }

  i {
    font-style: italic;
  }

  iframe:focus {
    outline: none;
  }

  iframe[seamless] {
    display: block;
  }

  img {
    display: inline-block;
  }

  input {
    /* None */
  }

  ins {
    text-decoration: underline;
  }

  kbd {
    font-family: monospace;
  }

  label {
    cursor: default;
  }

  legend {
    display: block;
    padding-left: 2px;
    padding-right: 2px;
    border: none;
  }

  li {
    display: list-item;
  }

  link {
    display: none;
  }

  main {
    /* None */
  }

  map {
    display: inline;
  }

  mark {
    background-color: yellow;
    color: black;
  }

  menu {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }

  menuitem {
    /* None */
  }

  meta {
    /* None */
  }

  meter {
    /* None */
  }

  nav {
    display: block;
  }

  noscript {
    /* None */
  }

  object:focus {
    outline: none;
  }

  p:after {
    content: '';
    display: inline-block;
    width: 0px;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }
  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }
  ul ul,
  ol ul {
    list-style-type: circle;
    list-style-position: inside;
    margin-left: 15px;
  }
  ol ol,
  ul ol {
    list-style-type: lower-latin;
    list-style-position: inside;
    margin-left: 15px;
  }

  ol li {
    padding-left: 40px;
  }
`;

export default ContainerWithoutCssReset;
