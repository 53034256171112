import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RemoveTherapistFromBaseVariables = Types.Exact<{
  input: Types.TherapistRemoveInput;
}>;


export type RemoveTherapistFromBase = (
  { __typename: 'Mutation' }
  & Pick<Types.Mutation, 'adminRemoveTherapistById'>
);


export const RemoveTherapistFromBaseDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemoveTherapistFromBase"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TherapistRemoveInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminRemoveTherapistById"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type RemoveTherapistFromBaseMutationFn = Apollo.MutationFunction<RemoveTherapistFromBase, RemoveTherapistFromBaseVariables>;

/**
 * __useRemoveTherapistFromBase__
 *
 * To run a mutation, you first call `useRemoveTherapistFromBase` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTherapistFromBase` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTherapistFromBase, { data, loading, error }] = useRemoveTherapistFromBase({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTherapistFromBase(baseOptions?: Apollo.MutationHookOptions<RemoveTherapistFromBase, RemoveTherapistFromBaseVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTherapistFromBase, RemoveTherapistFromBaseVariables>(RemoveTherapistFromBaseDocument, options);
      }
export type RemoveTherapistFromBaseHookResult = ReturnType<typeof useRemoveTherapistFromBase>;
export type RemoveTherapistFromBaseMutationResult = Apollo.MutationResult<RemoveTherapistFromBase>;
export type RemoveTherapistFromBaseMutationOptions = Apollo.BaseMutationOptions<RemoveTherapistFromBase, RemoveTherapistFromBaseVariables>;