import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Button, Modal } from 'ui';

interface PayConfirmationModalProps {
  isVisible: boolean;
  onClose: () => void;
  onPay: () => void;
  isLoading?: boolean;
}

const PayConfirmationModal: FC<PayConfirmationModalProps> = ({
  isVisible,
  isLoading,
  onClose,
  onPay,
}) => {
  return (
    <Modal
      title="Payment confirmation "
      isVisible={isVisible}
      onClose={onClose}>
      <Description>
        Are you sure you want to transfer funds for this session?
      </Description>
      <CancelButton isDisabled={isLoading} theme="secondary" onClick={onClose}>
        Cancel
      </CancelButton>
      <Button isLoading={isLoading} theme="primary" onClick={onPay}>
        Confirm
      </Button>
    </Modal>
  );
};

const Description = styled.p`
  font-size: ${getSize(12)};
  line-height: ${getSize(20)};
  margin-bottom: ${getSize(26)};
`;

const CancelButton = styled(Button)`
  margin-bottom: ${getSize(14)};
`;

export default PayConfirmationModal;
