import { Config } from 'final-form';
import { getSize, validationRules } from 'lib/utils';
import React, { FC } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import styled from 'styled-components';
import { Button, PriceInput, CommissionInput } from 'ui';

const TherapistComissionForm: FC<TherapistComissionFormProps> = ({
  initialValues,
  handleFormSubmit,
  submitLoading,
}) => {
  return (
    <PriceWrapper>
      <FinalForm<CommissionFormValues>
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        render={({ handleSubmit, hasValidationErrors, dirty }) => (
          <form>
            <InputWrapper>
              <Field
                validate={validationRules.commissionValue}
                name="initialCommissionPercent"
                label="Initial commission percent"
                component={CommissionInput}
              />
              <Field
                validate={validationRules.commissionValue}
                name="ongoingCommissionPercent"
                label="Ongoing commission percent"
                component={CommissionInput}
              />
            </InputWrapper>

            <SaveButton
              isDisabled={!dirty || hasValidationErrors}
              onClick={handleSubmit}
              isLoading={submitLoading}
              theme="primary">
              Save
            </SaveButton>
          </form>
        )}
      />
    </PriceWrapper>
  );
};

interface TherapistComissionFormProps {
  initialValues: CommissionFormValues;
  handleFormSubmit: Config<CommissionFormValues>['onSubmit'];
  submitLoading: boolean;
}

export interface CommissionFormValues {
  initialCommissionPercent: number;
  ongoingCommissionPercent: number;
}

const PriceWrapper = styled.div`
  display: flex;
  padding: ${getSize(20)} 0 ${getSize(60)};
`;

const InputWrapper = styled.div`
  display: flex;
  margin-bottom: ${getSize(40)};
  & label:not(:first-child) {
    margin-left: ${getSize(20)};
  }
`;

const SaveButton = styled(Button)`
  width: ${getSize(313)};
  padding: ${getSize(10)};
  font-weight: 400;
`;

export default TherapistComissionForm;
