import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import styled, { CSSProp } from 'styled-components';
import { AlertIcon, PopUpMessage } from 'ui';

const TitleWithInfo: FC<TitleWithInfoProps> = ({ title, info, titleCSS }) => {
  return (
    <TitleWrapper>
      <Title $CSS={titleCSS}>{title}</Title>
      <AlertIconStylized />

      <PopUpMessage
        className="tooltip-message"
        rootCSS={{ width: getSize(340) }}>
        {info}
      </PopUpMessage>
    </TitleWrapper>
  );
};

interface TitleWithInfoProps {
  title: string;
  info: string;
  titleCSS?: CSSProp;
}

const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: ${getSize(360)};

  .tooltip-message {
    left: ${getSize(332)};
    &::before {
      left: ${getSize(20)};
    }
  }
`;

const Title = styled.h1<{ $CSS?: CSSProp }>`
  font-weight: 600;
  font-size: ${getSize(18)};
  line-height: ${getSize(32)};
  color: var(--black3);
  ${({ $CSS }) => $CSS}
`;

const AlertIconStylized = styled(AlertIcon)`
  width: ${getSize(15)};
  height: ${getSize(15)};
  stroke: var(--gray2);
  cursor: pointer;
  margin-left: ${getSize(12)};

  &:hover ~ {
    .tooltip-message {
      display: block;
      animation: showTooltip 0.3s ease-out forwards;
    }
  }
`;

export default TitleWithInfo;
