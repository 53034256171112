import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetTherapistFeedbackVariables = Types.Exact<{
  first?: Types.Maybe<Types.Scalars['Int']>;
  after?: Types.Maybe<Types.Scalars['String']>;
  therapistId: Types.Scalars['String'];
}>;


export type GetTherapistFeedback = (
  { __typename: 'Query' }
  & { adminTherapistFeedback: (
    { __typename: 'FeedbackAdminConnection' }
    & { nodes: Array<(
      { __typename: 'FeedbackAdminModel' }
      & FeedbackAdminFragment
    )>, pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<Types.PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);

export type FeedbackAdminFragment = (
  { __typename: 'FeedbackAdminModel' }
  & Pick<Types.FeedbackAdminModel, 'id' | 'therapistId' | 'rating' | 'description' | 'sessionMetaId' | 'createdAt' | 'updatedAt'>
  & { patient: (
    { __typename: 'PatientModel' }
    & PatientFeedbackFragment
  ) }
);

export type PatientFeedbackFragment = (
  { __typename: 'PatientModel' }
  & Pick<Types.PatientModel, 'id' | 'dateOfBirthday' | 'userId'>
  & { user: (
    { __typename: 'UserModel' }
    & Pick<Types.UserModel, 'id' | 'email' | 'firstName' | 'lastName'>
    & { avatar?: Types.Maybe<(
      { __typename: 'UserPublicFileModel' }
      & Pick<Types.UserPublicFileModel, 'id' | 'fileType' | 'source' | 'name' | 'sizeInKb' | 'extension'>
    )> }
  ) }
);

export const PatientFeedbackFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PatientFeedbackFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PatientModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"dateOfBirthday"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"avatar"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"fileType"}},{"kind":"Field","name":{"kind":"Name","value":"source"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"sizeInKb"}},{"kind":"Field","name":{"kind":"Name","value":"extension"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"userId"}}]}}]} as unknown as DocumentNode;
export const FeedbackAdminFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FeedbackAdminFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FeedbackAdminModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"patient"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PatientFeedbackFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"therapistId"}},{"kind":"Field","name":{"kind":"Name","value":"rating"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"sessionMetaId"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}},...PatientFeedbackFragment.definitions]} as unknown as DocumentNode;
export const GetTherapistFeedbackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetTherapistFeedback"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"first"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"after"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"therapistId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminTherapistFeedback"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"Variable","name":{"kind":"Name","value":"first"}}},{"kind":"Argument","name":{"kind":"Name","value":"after"},"value":{"kind":"Variable","name":{"kind":"Name","value":"after"}}},{"kind":"Argument","name":{"kind":"Name","value":"therapistId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"therapistId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"nodes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FeedbackAdminFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"pageInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hasNextPage"}},{"kind":"Field","name":{"kind":"Name","value":"endCursor"}}]}}]}}]}},...FeedbackAdminFragment.definitions]} as unknown as DocumentNode;

/**
 * __useGetTherapistFeedback__
 *
 * To run a query within a React component, call `useGetTherapistFeedback` and pass it any options that fit your needs.
 * When your component renders, `useGetTherapistFeedback` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTherapistFeedback({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      therapistId: // value for 'therapistId'
 *   },
 * });
 */
export function useGetTherapistFeedback(baseOptions: Apollo.QueryHookOptions<GetTherapistFeedback, GetTherapistFeedbackVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTherapistFeedback, GetTherapistFeedbackVariables>(GetTherapistFeedbackDocument, options);
      }
export function useGetTherapistFeedbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTherapistFeedback, GetTherapistFeedbackVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTherapistFeedback, GetTherapistFeedbackVariables>(GetTherapistFeedbackDocument, options);
        }
export type GetTherapistFeedbackHookResult = ReturnType<typeof useGetTherapistFeedback>;
export type GetTherapistFeedbackLazyQueryHookResult = ReturnType<typeof useGetTherapistFeedbackLazyQuery>;
export type GetTherapistFeedbackQueryResult = Apollo.QueryResult<GetTherapistFeedback, GetTherapistFeedbackVariables>;