import jwtDecode from 'jwt-decode';
import { config } from 'lib/utils';

interface DecodeToken {
  [key: string]: any;
}
type AccessToken = string | null | undefined;

class DecodeTokenService {
  getDecodedToken(accessToken: AccessToken) {
    if (accessToken) {
      return jwtDecode<DecodeToken>(accessToken);
    }
  }

  getDataFromTokenByPropName(accessToken: AccessToken, prop: string) {
    const audience = config.AUTH0_AUDIENCE.startsWith('http')
      ? config.AUTH0_AUDIENCE
      : `http://${config.AUTH0_AUDIENCE}`;
    return this.getDecodedToken(accessToken)?.[`${audience}/${prop}`];
  }

  getUserRoleFromToken(accessToken: AccessToken) {
    return this.getDataFromTokenByPropName(accessToken, 'roles')?.[0];
  }

  getLoginCountFromToken(accessToken: AccessToken) {
    return this.getDataFromTokenByPropName(accessToken, 'loginCount');
  }

  getUserEmail(accessToken: AccessToken) {
    return this.getDataFromTokenByPropName(accessToken, 'email');
  }
}

export default new DecodeTokenService();
