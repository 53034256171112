import { useTherapistRequestsCount } from '../subscription/__generated__/therapists-requests-count';
import { useGetTherapistsRequestsCount } from '../query/__generated__/get-therapist-requests-count';

export const useRequestsCounter = () => {
  const { data } = useGetTherapistsRequestsCount();
  const requestsCount = data?.therapistRequestsCount;

  useTherapistRequestsCount({
    onSubscriptionData: ({ subscriptionData, client: { cache } }) => {
      const requestsCount = subscriptionData.data?.therapistRequestAdded;
      if (requestsCount) {
        cache.modify({
          id: 'ROOT_QUERY',
          fields: {
            therapistRequestsCount() {
              return requestsCount;
            },
          },
        });
      }
    },
  });

  return { requestsCount: requestsCount || 0 };
};
