import React from 'react';

function SvgComponent({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      {...rest}>
      <path
        d="M19.122 1.003a.438.438 0 00-.31-.128H6.563A3.066 3.066 0 003.5 3.938v20.125a3.066 3.066 0 003.063 3.062h14.875a3.066 3.066 0 003.062-3.063v-17.5a.437.437 0 00-.128-.309l-5.25-5.25zm.128 1.366l3.756 3.756h-1.569a2.19 2.19 0 01-2.187-2.188V2.37zM6.562 1.75h11.813v2.188A3.066 3.066 0 0021.438 7h2.187v12.25H4.375V3.937A2.19 2.19 0 016.563 1.75zm14.875 24.5H6.563a2.19 2.19 0 01-2.187-2.188v-3.937h19.25v3.938a2.19 2.19 0 01-2.188 2.187z"
        fill="#7A60E9"
      />
      <path
        d="M13.938 21h-.876a.438.438 0 00-.437.438v3.5a.438.438 0 00.875 0v-1.313h.438a1.313 1.313 0 000-2.625zm0 1.75H13.5v-.875h.438a.438.438 0 010 .875zM11.188 21a.438.438 0 00-.438.438v2.625a.438.438 0 01-.875 0 .438.438 0 00-.875 0 1.313 1.313 0 002.625 0v-2.625a.438.438 0 00-.438-.438zM19.313 22.75h-.875a.438.438 0 000 .875h.385c-.123.504-.45.875-.823.875-.474 0-.875-.601-.875-1.313 0-.711.4-1.312.875-1.312.087 0 .173.02.25.058a.437.437 0 00.374-.792A1.454 1.454 0 0018 21c-.965 0-1.75.981-1.75 2.188 0 1.206.785 2.187 1.75 2.187s1.75-.981 1.75-2.188a.438.438 0 00-.438-.437zM11.813 9.625a1.313 1.313 0 100-2.625 1.313 1.313 0 000 2.625zm0-1.75a.438.438 0 110 .876.438.438 0 010-.876zM8.312 16.625h11.375a.438.438 0 00.382-.652l-3.938-7a.437.437 0 00-.762 0l-2.427 4.315-1.209-1.726a.437.437 0 00-.716 0l-3.063 4.375a.437.437 0 00.358.688zm7.438-6.545l3.19 5.67h-4.275l-1.167-1.667 2.252-4.003zm-4.375 2.496l2.222 3.175H9.153l2.222-3.175z"
        fill="#7A60E9"
      />
    </svg>
  );
}

export default SvgComponent;
