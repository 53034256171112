import { dashboardRoutes } from 'routes/root-routes';
import { PricingIcon } from 'ui/icons/pricing-icon';
import { RequestIcon } from 'ui/icons/request-icon';
import { TherapistIcon } from 'ui/icons/therapist-icon';
import { PatientIcon } from 'ui/icons/patient-icon';
import { MessageIcon } from 'ui/icons/message-icon';
import { InsuranceIcon } from 'ui/icons/insurance-icon';
import { FeedbackIcon } from 'ui/icons/feedback-icon';
import { BusinessIcon, DiscountIcon, ListIcon } from 'ui';
import { LogType } from '__generated__/types';

export enum DashboardNavLinkType {
  MESSAGING = 'messaging',
  REQUESTS = 'requests',
  THERAPISTS = 'therapists',
  LOGS = 'logs',
}

export const DASHBOARD_LINKS = [
  {
    href: dashboardRoutes.requests.PATH,
    text: 'Requests',
    Icon: RequestIcon,
    type: DashboardNavLinkType.REQUESTS,
  },
  {
    href: dashboardRoutes.therapists.PATH,
    text: 'Therapists',
    Icon: TherapistIcon,
    type: DashboardNavLinkType.THERAPISTS,
  },
  {
    href: dashboardRoutes.patients.PATH,
    text: 'Patients',
    Icon: PatientIcon,
  },
  {
    href: dashboardRoutes.messaging.pathBuild(),
    text: 'Messaging',
    Icon: MessageIcon,
    type: DashboardNavLinkType.MESSAGING,
  },
  {
    href: dashboardRoutes.pricing.PATH,
    text: 'Pricing',
    Icon: PricingIcon,
  },
  {
    href: dashboardRoutes.insurance.PATH,
    text: 'Insurance',
    Icon: InsuranceIcon,
  },
  {
    href: dashboardRoutes.insuranceSessions.PATH,
    text: 'Insurance Sessions',
    Icon: ListIcon,
  },
  {
    href: dashboardRoutes.vouchers.PATH,
    text: 'Voucher',
    Icon: DiscountIcon,
  },
  {
    href: dashboardRoutes.business.PATH,
    text: 'Businesses',
    Icon: BusinessIcon,
  },
  {
    href: dashboardRoutes.feedback.PATH,
    text: 'Feedback',
    Icon: FeedbackIcon,
  },
  {
    href: dashboardRoutes.logs.PATH,
    text: 'Logs',
    Icon: InsuranceIcon,
    type: DashboardNavLinkType.LOGS,
  },
  {
    href: dashboardRoutes.resources.PATH,
    text: 'Resources',
    Icon: ListIcon,
  },
];

/** These are links only allowed for marketing admins, this is a quick hack
 * TODO: This needs to be fixed asap.
 */
export const DASHBOARD_MARKETING_LINKS = [
  {
    href: dashboardRoutes.resources.PATH,
    text: 'Resources',
    Icon: ListIcon,
  },
];

export const LOGS_LINKS = [
  {
    href: dashboardRoutes.logs.validateBillHistory.PATH,
    text: 'Validate Bill Logs',
    type: LogType.ValidateBill,
  },
  {
    href: dashboardRoutes.logs.submitBillHistory.PATH,
    text: 'Submit Bill Logs',
    type: LogType.SubmitBill,
  },
  {
    href: dashboardRoutes.logs.billStatusHistory.PATH,
    text: 'Bill Status Logs',
    type: LogType.BillStatus,
  },
];
