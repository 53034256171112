import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import styled from 'styled-components';

const RowTemplate: FC<RowTemplateProps> = ({ children, className }) => {
  return <Root className={className}>{children}</Root>;
};

interface RowTemplateProps {
  className?: string;
}

const Root = styled.div`
  min-width: 100%;
  height: ${getSize(52)};

  display: grid;
  grid-template-columns: 14% 18% 18% 11% 11% 11% 17%;
  align-items: center;

  border-radius: ${getSize(8)};
`;

export default RowTemplate;
