import { useState } from 'react';
import { Loader } from 'ui/loader';
import { getSize } from 'lib/utils';
import styled from 'styled-components';
import { EditPricingModal } from '../components';

import { RowTemplate } from '../components/row-template';
import { PricingRowItem } from '../components/pricing-row-item';

/** Fragment */
import { SessionPriceAdminFragment } from 'common/fragments/__generated__/session-price-admin.fragment';

/** Queries */
import { useGetSessionPrices } from 'common/query/__generated__/get-session-prices';

/**
 *
 * @returns
 */
const Pricings = () => {
  /** Get pricings */
  const { data: pricesData, loading: loadingPrices } = useGetSessionPrices();

  /** State data */
  const [pricingToEdit, setPricingToEdit] =
    useState<SessionPriceAdminFragment | null>(null);

  /**
   * @method
   */
  const renderTableRows = () => {
    return pricesData?.getSessionPrices.map((pricingData) => {
      return (
        <PricingRowItem
          pricingData={pricingData}
          key={pricingData.id}
          onClick={() => setPricingToEdit(pricingData)}
        />
      );
    });
  };

  if (loadingPrices) {
    return <Loader />;
  }

  return (
    <>
      {pricingToEdit && (
        <EditPricingModal
          pricingToEdit={pricingToEdit}
          onClose={() => setPricingToEdit(null)}
        />
      )}
      <HeadWrapper>
        <Title>Pricings</Title>
      </HeadWrapper>

      <TableHeadWrapper>
        <TableHeader>
          <TableHeaderText>Pricing type</TableHeaderText>
          <TableHeaderText>30 min</TableHeaderText>
          <TableHeaderText>50 min</TableHeaderText>
          <TableHeaderText>80 min</TableHeaderText>
          <TableHeaderText>Options</TableHeaderText>
        </TableHeader>
      </TableHeadWrapper>
      <PricingTableContainer> {renderTableRows()}</PricingTableContainer>
    </>
  );
};

const PricingTableContainer = styled.div`
  padding: ${getSize(20)}${getSize(40)};
`;

const TableHeader = styled(RowTemplate)`
  height: ${getSize(52)};
  background-color: var(--purple11);
  border-radius: ${getSize(8)};
`;

const TableHeadWrapper = styled.div`
  padding: 0 ${getSize(40)};
`;

const HeadWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 ${getSize(40)};
  margin-bottom: ${getSize(40)};
`;

const Title = styled.h1`
  font-weight: 600;
  font-size: ${getSize(18)};
  line-height: ${getSize(32)};
  color: var(--black3);
`;

const TableHeaderText = styled.button<{ active?: boolean }>`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  text-align: center;
  color: var(--gray7);
  display: flex;
  align-items: center;
  color: ${({ active }) => (active ? 'var(--black)' : 'var(--gray7)')};

  &:first-child {
    padding-left: ${getSize(24)};
    text-align: left;
  }
`;

export default Pricings;
