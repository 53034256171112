import { CanceledRequestFragment } from 'common/query/__generated__/get-cancelled-requests';
import { CanceledSessionFragment } from 'common/query/__generated__/get-cancelled-sessions';
import { getSize } from 'lib/utils';
import { CancellationsTabs } from 'pages/cancellations-page/components/tabs/tabs';
import React, { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import { Loader } from 'ui';
import { CancellationItem, RowTemplate } from './components';

interface CancellationsProps {
  dataLength: number;
  onNext: () => void;
  hasMore: boolean;
  isLoading?: boolean;
  activeTab: CancellationsTabs;
  data: (CanceledSessionFragment | CanceledRequestFragment)[];
}

const Cancellations: FC<CancellationsProps> = ({
  dataLength,
  onNext,
  hasMore,
  isLoading,
  activeTab,
  data,
}) => {
  const isRequests = activeTab === CancellationsTabs.REQUESTS;

  return (
    <div>
      <HeaderWrapper>
        <TableHeader>
          <TableHeaderText>Therapist</TableHeaderText>
          <TableHeaderText>Patient</TableHeaderText>
          <TableHeaderText>
            {isRequests ? 'Request' : 'Session'} date
          </TableHeaderText>
          <TableHeaderText>Reason for cancellation</TableHeaderText>
        </TableHeader>
      </HeaderWrapper>
      <ScrollWrapper id="scrollCancellationsList">
        {isLoading ? (
          <Loader hasFillWholeBlock size={50} />
        ) : (
          <CancellationsList
            key={isRequests ? 'Request' : 'Session'}
            dataLength={dataLength}
            next={onNext}
            hasMore={hasMore}
            scrollableTarget="scrollCancellationsList"
            loader={<Loader />}>
            {data.map((item) => {
              return <CancellationItem item={item} key={item.id} />;
            })}
          </CancellationsList>
        )}
      </ScrollWrapper>
    </div>
  );
};

const HeaderWrapper = styled.div`
  padding: 0 ${getSize(40)};
`;

const TableHeader = styled(RowTemplate)`
  height: ${getSize(52)};
  background-color: var(--purple11);
  border-radius: ${getSize(8)};
`;

const TableHeaderText = styled.p`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  text-align: center;
  padding: 0 ${getSize(18)};
  color: var(--gray7);

  &:first-child {
    padding-left: ${getSize(24)};
    text-align: left;
  }
`;

const ScrollWrapper = styled.div`
  overflow: auto;
  margin-top: ${getSize(12)};
  height: ${getSize(500)};

  &::-webkit-scrollbar {
    display: none;
  }
`;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore-next-line
const CancellationsList = styled(InfiniteScroll)`
  padding: ${getSize(20)} ${getSize(40)};
`;

export default Cancellations;
