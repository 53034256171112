import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { StorageService } from 'lib/utils';
import { authRoutes, dashboardRoutes } from '../routes/root-routes';

function useRedirect() {
  const history = useHistory();

  useEffect(() => {
    const accessToken = StorageService.getAccessToken();
    const isAuthorized = !!accessToken;

    if (isAuthorized) {
      if (history.location.pathname.includes('/auth')) {
        history.push(dashboardRoutes.requests.PATH);
      }
    } else {
      history.push(authRoutes.auth.PATH);
    }
  }, []);
}

export default useRedirect;
