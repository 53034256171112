import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled, { CSSProp } from 'styled-components';
import { PopUpMessage } from 'ui/pop-up-message';
import { getSize } from 'lib/utils';
import { CheckboxIndicator } from '../checkbox-indicator';
import { AlertIcon } from 'ui/icons';

export interface CheckboxProps extends FieldRenderProps<string> {
  label?: string;
  rootCSS?: CSSProp;
  disabled?: boolean;
  tooltip?: string;
}

const TOOLTIP_CSS = { width: getSize(238) };

function Checkbox({
  input,
  meta,
  label,
  rootCSS,
  disabled,
  tooltip,
}: CheckboxProps) {
  const isChecked = input.checked;
  const { error, submitError, touched, dirtySinceLastSubmit, data } = meta;
  const hasError =
    ((error || submitError) && touched && !dirtySinceLastSubmit) || data?.error;

  return (
    <Wrapper $CSS={rootCSS} $isDisabled={disabled}>
      <Inner $isChecked={isChecked} $hasError={hasError}>
        <Input {...input} disabled={disabled} />
        <IndicatorStylized isChecked={isChecked} />

        {label && (
          <>
            <Text>{label}</Text>
            {tooltip && (
              <Tooltip>
                <AlertIconStylized />
                <PopUpMessage className="tooltip-message" rootCSS={TOOLTIP_CSS}>
                  {tooltip}
                </PopUpMessage>
              </Tooltip>
            )}
          </>
        )}
      </Inner>
      {hasError && <ErrorText>{error || submitError || data?.error}</ErrorText>}
    </Wrapper>
  );
}

const Wrapper = styled.label<{ $CSS?: CSSProp; $isDisabled?: boolean }>`
  display: block;
  ${({ $isDisabled }) => ($isDisabled ? '' : 'cursor: pointer;')}

  ${({ $CSS }) => $CSS}
`;

const Inner = styled.div<{ $isChecked?: boolean; $hasError?: boolean }>`
  display: flex;
  align-items: center;
  & span {
    border-color: var(
      ${({ $isChecked, $hasError }) => {
        if ($hasError) {
          return '--red';
        } else if ($isChecked) {
          return '--purple5';
        } else {
          return '--purple5';
        }
      }}
    );
  }
  ${({ $isChecked }) =>
    !$isChecked &&
    `
    &:hover {
      span {
        color: var(--purple);
        border-color: var(--purple5);
      }
    }
  `}
`;

const Input = styled.input`
  display: none;
`;

const IndicatorStylized = styled(CheckboxIndicator)`
  margin: 0 ${getSize(8)} 0 0;
`;

const Text = styled.span`
  position: relative;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  color: var(--black3);
`;

const AlertIconStylized = styled(AlertIcon)`
  width: ${getSize(15)};
  height: ${getSize(15)};
  stroke: var(--gray2);
  cursor: pointer;

  &:hover ~ {
    .tooltip-message {
      display: block;
      animation: showTooltip 0.3s ease-out forwards;
    }
  }
`;

const Tooltip = styled.div`
  position: relative;
  margin-left: ${getSize(9)};
`;

const ErrorText = styled.span`
  margin: ${getSize(2)} 0 0;
  font-weight: 400;
  font-size: ${getSize(10)};
  line-height: ${getSize(16)};
  color: var(--red);
`;

export default Checkbox;
