import { TherapistInfoFragment } from 'common/query/__generated__/get-therapist-by-id';
import { ChangeCommissionPercent } from 'common/mutation/__generated__/change-commission-percent';
import { ApolloCache, FetchResult } from '@apollo/client';
import { TherapistFragment } from 'common/query/__generated__/get-therapists-by-request';

export function updateTherapistCommissionCache(
  cache: ApolloCache<ChangeCommissionPercent>,
  { data }: FetchResult<ChangeCommissionPercent>,
) {
  const incomingTherapist = data?.adminChangeCommissionPercent.therapistAdmin;

  if (incomingTherapist) {
    const newSessionCommisionPercent = {
      initialSessionCommissionPercent:
        incomingTherapist.initialSessionCommissionPercent,
      ongoingSessionCommissionPercent:
        incomingTherapist.ongoingSessionCommissionPercent,
    };

    cache.writeFragment({
      id: `TherapistAdminModel:${incomingTherapist.id}`,
      fragment: TherapistFragment,
      fragmentName: 'TherapistFragment',
      data: newSessionCommisionPercent,
    });
    cache.writeFragment({
      id: `TherapistAdminModel:${incomingTherapist.id}`,
      fragment: TherapistInfoFragment,
      fragmentName: 'TherapistInfoFragment',
      data: newSessionCommisionPercent,
    });
  }
}
