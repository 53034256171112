import { FormApi } from 'final-form';
import { getSize } from 'lib/utils';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { Button, CloseIcon, Loader } from 'ui';
import { CompanyFormValues } from './add-company-form';

interface FeedbackModalProps {
  onCloseModal: () => void;
  onYesButtonClick: () => void;
  questionText: string;
}

export const ConfirmModal: FC<FeedbackModalProps> = ({
  onCloseModal,
  onYesButtonClick,
  questionText,
}) => {
  const loading = false;
  return (
    <ShadowWrapper>
      <Content>
        <Header>
          <HeaderText>{questionText}</HeaderText>
          <CloseIconStyled onClick={onCloseModal} />
        </Header>
        {loading ? (
          <Loader />
        ) : (
          <QuestionWrapper>
            <Question>Are you sure?</Question>
            <ButtonContainer>
              <AnswerButton onClick={onCloseModal} isWhite>
                No
              </AnswerButton>
              <AnswerButton onClick={onYesButtonClick}>Yes</AnswerButton>
            </ButtonContainer>
          </QuestionWrapper>
        )}
      </Content>
    </ShadowWrapper>
  );
};

const ShadowWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.51);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const Content = styled.div`
  width: ${getSize(582)};
  min-height: ${getSize(647)};
  background: #ffffff;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-content: center;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${getSize(22)} ${getSize(23)} ${getSize(19)} ${getSize(27)};
  border-bottom: 1px solid #f1f1f1;
`;
const HeaderText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: ${getSize(18)};

  color: #000000;
`;

const CloseIconStyled = styled(CloseIcon)`
  cursor: pointer;
`;

const Question = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 44px;
  color: #000000;
`;

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5px;
  margin-top: 32px;
`;

interface AnswerButtonProps {
  isWhite?: boolean;
}

const AnswerButton = styled(Button)<AnswerButtonProps>`
  height: 42px;
  min-width: ${getSize(130)};
  width: 100%;
  background: ${({ isWhite }) => (isWhite ? 'white' : '#6b4ee6')};
  border: 1px solid #6b4ee6;
  box-sizing: border-box;
  border-radius: 34px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isWhite }) => (isWhite ? '#6b4ee6' : 'white')};
  font-weight: 600;
  font-size: ${getSize(14)};
`;
