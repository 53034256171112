import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type BanTherapistByIdVariables = Types.Exact<{
  input: Types.TherapistRemoveInput;
}>;


export type BanTherapistById = (
  { __typename: 'Mutation' }
  & Pick<Types.Mutation, 'adminBanTherapistById'>
);


export const BanTherapistByIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"BanTherapistById"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TherapistRemoveInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminBanTherapistById"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type BanTherapistByIdMutationFn = Apollo.MutationFunction<BanTherapistById, BanTherapistByIdVariables>;

/**
 * __useBanTherapistById__
 *
 * To run a mutation, you first call `useBanTherapistById` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBanTherapistById` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [banTherapistById, { data, loading, error }] = useBanTherapistById({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBanTherapistById(baseOptions?: Apollo.MutationHookOptions<BanTherapistById, BanTherapistByIdVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BanTherapistById, BanTherapistByIdVariables>(BanTherapistByIdDocument, options);
      }
export type BanTherapistByIdHookResult = ReturnType<typeof useBanTherapistById>;
export type BanTherapistByIdMutationResult = Apollo.MutationResult<BanTherapistById>;
export type BanTherapistByIdMutationOptions = Apollo.BaseMutationOptions<BanTherapistById, BanTherapistByIdVariables>;