import { useMemo } from 'react';
import { TimeUtil } from 'lib/utils';
import { ChatMessageEdgeFragment } from 'pages/messaging-page/query/__generated__/chat-messages';
import { ISO_DATE_FORMAT, DATE_TIME } from 'lib/utils/date';

export function useDatetime(
  messageDateIso: string,
  previousMessage: ChatMessageEdgeFragment | null,
) {
  const messageDate = useMemo(
    () => TimeUtil.parse(messageDateIso, ISO_DATE_FORMAT),
    [messageDateIso],
  );
  const messageTime = useMemo(
    () => messageDate.format(DATE_TIME),
    [messageDate],
  );

  const borderDate = useMemo(() => {
    const previousMessageDate =
      previousMessage &&
      TimeUtil.parse(previousMessage.node.createdAt, ISO_DATE_FORMAT);

    function getValidFormattedDate() {
      if (messageDate.isToday()) {
        return 'Today';
      } else if (messageDate.isBefore(TimeUtil.now(), 'year')) {
        return messageDate.format('DD MMMM YYYY');
      }

      return messageDate.format('DD MMMM');
    }

    if (
      !previousMessageDate ||
      previousMessageDate?.isBefore(messageDate, 'date')
    ) {
      return getValidFormattedDate();
    }
  }, [messageDate, previousMessage]);

  return { messageTime, borderDate };
}
