import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetFilteredInsuranceSessionsVariables = Types.Exact<{
  filter: Types.InsuranceSessionFilter;
}>;


export type GetFilteredInsuranceSessions = (
  { __typename: 'Query' }
  & { getInsuranceSessions: Array<(
    { __typename: 'AdminInsuranceSessionModel' }
    & SessionNeedTransferModelFragment
  )> }
);

export type SessionNeedTransferModelFragment = (
  { __typename: 'AdminInsuranceSessionModel' }
  & Pick<Types.AdminInsuranceSessionModel, 'id' | 'startDateUtc' | 'sessionType' | 'providerReference' | 'invoiceNo' | 'billStatus' | 'amountToPay' | 'remittanceStatus'>
  & { therapistPublic: (
    { __typename: 'TherapistPublicModel' }
    & Pick<Types.TherapistPublicModel, 'id' | 'fullName' | 'professionalTitle' | 'avatarUrl'>
  ), patientConnected: (
    { __typename: 'PatientConnectedModel' }
    & Pick<Types.PatientConnectedModel, 'id' | 'firstName' | 'lastName'>
    & { avatar?: Types.Maybe<(
      { __typename: 'UserPublicFileModel' }
      & Pick<Types.UserPublicFileModel, 'source'>
    )>, insuranceCompany?: Types.Maybe<(
      { __typename: 'InsuranceCompanyModel' }
      & Pick<Types.InsuranceCompanyModel, 'id' | 'logoRoundUrl'>
    )> }
  ) }
);

export const SessionNeedTransferModelFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SessionNeedTransferModelFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AdminInsuranceSessionModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"startDateUtc"}},{"kind":"Field","name":{"kind":"Name","value":"sessionType"}},{"kind":"Field","name":{"kind":"Name","value":"therapistPublic"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"fullName"}},{"kind":"Field","name":{"kind":"Name","value":"professionalTitle"}},{"kind":"Field","name":{"kind":"Name","value":"avatarUrl"}}]}},{"kind":"Field","name":{"kind":"Name","value":"patientConnected"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"avatar"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"source"}}]}},{"kind":"Field","name":{"kind":"Name","value":"insuranceCompany"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"logoRoundUrl"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"providerReference"}},{"kind":"Field","name":{"kind":"Name","value":"invoiceNo"}},{"kind":"Field","name":{"kind":"Name","value":"billStatus"}},{"kind":"Field","name":{"kind":"Name","value":"amountToPay"}},{"kind":"Field","name":{"kind":"Name","value":"remittanceStatus"}}]}}]} as unknown as DocumentNode;
export const GetFilteredInsuranceSessionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetFilteredInsuranceSessions"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"InsuranceSessionFilter"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getInsuranceSessions"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SessionNeedTransferModelFragment"}}]}}]}},...SessionNeedTransferModelFragment.definitions]} as unknown as DocumentNode;

/**
 * __useGetFilteredInsuranceSessions__
 *
 * To run a query within a React component, call `useGetFilteredInsuranceSessions` and pass it any options that fit your needs.
 * When your component renders, `useGetFilteredInsuranceSessions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilteredInsuranceSessions({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetFilteredInsuranceSessions(baseOptions: Apollo.QueryHookOptions<GetFilteredInsuranceSessions, GetFilteredInsuranceSessionsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilteredInsuranceSessions, GetFilteredInsuranceSessionsVariables>(GetFilteredInsuranceSessionsDocument, options);
      }
export function useGetFilteredInsuranceSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilteredInsuranceSessions, GetFilteredInsuranceSessionsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilteredInsuranceSessions, GetFilteredInsuranceSessionsVariables>(GetFilteredInsuranceSessionsDocument, options);
        }
export type GetFilteredInsuranceSessionsHookResult = ReturnType<typeof useGetFilteredInsuranceSessions>;
export type GetFilteredInsuranceSessionsLazyQueryHookResult = ReturnType<typeof useGetFilteredInsuranceSessionsLazyQuery>;
export type GetFilteredInsuranceSessionsQueryResult = Apollo.QueryResult<GetFilteredInsuranceSessions, GetFilteredInsuranceSessionsVariables>;