import { getSize } from 'lib/utils';
import React from 'react';
import styled from 'styled-components';
import { DropDownFilter } from 'ui';
import { OrderByDirection, TherapistAdminSort } from '__generated__/types';

type HeadProps = {
  futureSessionCountExtended: number[];
  setSortOption: (v: TherapistAdminSort) => void;
  setSortDirection: (v: OrderByDirection) => void;
  sortDirection: OrderByDirection;
  sortOption: TherapistAdminSort;
};

const Head = ({
  futureSessionCountExtended,
  setSortOption,
  setSortDirection,
  sortDirection,
  sortOption,
}: HeadProps) => {
  const sortOptions = [
    { value: OrderByDirection.Asc, label: 'Sort from new to old' },
    { value: OrderByDirection.Desc, label: 'Sort from old to new' },
  ];
  return (
    <Wrapper>
      <DropDownFilter
        title={
          sortOption === TherapistAdminSort.UpdatedAt
            ? sortOptions.find((i) => i.value === sortDirection)?.label
            : 'Sort from new to old'
        }
        buttonCSS={
          sortOption !== TherapistAdminSort.UpdatedAt ? INACTIVE_SORT_CSS : {}
        }
        options={sortOptions}
        value={
          sortOption === TherapistAdminSort.UpdatedAt
            ? sortDirection
            : OrderByDirection.Asc
        }
        setValue={(value) => {
          setSortOption(TherapistAdminSort.UpdatedAt);
          setSortDirection(value as OrderByDirection);
        }}
      />
      <UpcomingSessionsContainer>
        <UpcomingSessions>
          <UpcomingSessionsCount>
            <TextRed>{`${futureSessionCountExtended[3]}`}</TextRed>
          </UpcomingSessionsCount>
          <OverdueTextContainer>
            <Text>Overdue Pending</Text>
          </OverdueTextContainer>
        </UpcomingSessions>

        <UpcomingSessions>
          <UpcomingSessionsCount>{`${futureSessionCountExtended[0]}/${futureSessionCountExtended[1]}/${futureSessionCountExtended[2]}`}</UpcomingSessionsCount>
          <Text>Upcoming sessions</Text>
        </UpcomingSessions>
      </UpcomingSessionsContainer>
    </Wrapper>
  );
};

const INACTIVE_SORT_CSS = {
  color: '#97909E',
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${getSize(24)};
`;

const UpcomingSessions = styled.div`
  padding: ${getSize(10)} ${getSize(24)};
  display: flex;
  align-items: center;
  border: 1px solid var(--gray4);
  border-radius: ${getSize(8)};
`;

const UpcomingSessionsCount = styled.p`
  font-weight: 600;
  font-size: ${getSize(24)};
  line-height: ${getSize(36)};
  margin-right: ${getSize(12)};
  color: var(--purple4);
`;

const Text = styled.p`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--black3);
`;

const TextRed = styled.span`
  color: var(--red);
`;

const OverdueTextContainer = styled.div`
  width: 50px;
`;

const UpcomingSessionsContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export default Head;
