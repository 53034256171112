import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components';

import { getSize } from 'lib/utils';
import { useIsMessagingRoute, useUnreadCount } from './utils';
import { DashboardNavLinkType } from 'lib/utils/links';

import { TextOfCount } from 'ui';
import { useChatChanged } from 'pages/messaging-page/subscription/__generated__/chat-changed';
import { updateChatListCacheAfterChanged } from 'pages/messaging-page/utils';
import { useRequestsCounter } from './utils/use-requests-counter';
import { dashboardRoutes } from 'routes/root-routes';
import { useCancellationsCounter } from './utils/use-cancellations-counter';
import { useLogsCounter } from './utils/use-logs-count';

export interface LinkItem {
  href: string;
  text: string;
  type?: DashboardNavLinkType;
  Icon: (props: any) => JSX.Element;
}
export interface DashboardNavLinksProps {
  links: LinkItem[];
  className?: string;
  listCSS?: CSSProp;
  numberItemPerRow?: number;
}
const { MESSAGING, REQUESTS, THERAPISTS, LOGS } = DashboardNavLinkType;

function DashboardNavLinks({
  links,
  className,
  listCSS,
  numberItemPerRow = links.length,
}: DashboardNavLinksProps) {
  const { unreadCount } = useUnreadCount();
  const { isMessagingRoute } = useIsMessagingRoute();
  const { requestsCount } = useRequestsCounter();
  const { cancellationsCount } = useCancellationsCounter();
  const { logsCount } = useLogsCounter();

  useChatChanged({
    onSubscriptionData: updateChatListCacheAfterChanged,
    skip: isMessagingRoute,
  });
  return (
    <nav className={className}>
      <List CSS={listCSS} numberItemPerRow={numberItemPerRow}>
        {links.map(({ href, text, Icon, type }) => (
          <li key={href}>
            <Link to={href}>
              <Icon />
              {text}

              {type === MESSAGING && unreadCount > 0 && (
                <TextOfCountStylized>{unreadCount}</TextOfCountStylized>
              )}
              {type === REQUESTS && requestsCount > 0 && (
                <TextOfCountStylized>{requestsCount}</TextOfCountStylized>
              )}
              {type === THERAPISTS && !!cancellationsCount && (
                <TextOfCountStylized className="cancellations-count">
                  {cancellationsCount}
                </TextOfCountStylized>
              )}
              {type === LOGS && !!logsCount?.all && (
                <TextOfCountStylized className="logs-count">
                  {logsCount?.all}
                </TextOfCountStylized>
              )}
            </Link>
            {type === THERAPISTS && (
              <NestedLinks className="nested-links">
                <li>
                  <Link exact to={dashboardRoutes.therapists.pathBuild('list')}>
                    List
                  </Link>
                </li>
                <li>
                  <Link
                    exact
                    to={dashboardRoutes.therapists.pathBuild('cancellations')}>
                    Cancellations
                    {!!cancellationsCount && (
                      <TextOfCountStylized>
                        {cancellationsCount}
                      </TextOfCountStylized>
                    )}
                  </Link>
                </li>
              </NestedLinks>
            )}
          </li>
        ))}
      </List>
    </nav>
  );
}

const List = styled.ul<{
  CSS?: CSSProp;
  numberItemPerRow?: number;
}>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(
    ${({ numberItemPerRow }) => numberItemPerRow},
    auto
  );
  grid-gap: ${getSize(15)} ${getSize(36)};
  align-items: center;
  ${({ CSS }) => CSS}
`;

const Link = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--gray7);
  transition: 0.3s ease-out;

  padding: ${getSize(5)} ${getSize(10)};
  border-radius: ${getSize(8)};

  & > svg {
    flex-shrink: 0;
    margin: 0 ${getSize(12)} 0 0;
    width: ${getSize(22)};
    height: ${getSize(22)};
  }

  &:hover {
    color: var(--purple);
  }

  &.active {
    color: var(--black3);
    width: 100%;

    svg {
      path {
        fill: var(--purple4);
      }
    }

    & > .cancellations-count {
      display: none;
    }

    & ~ .nested-links {
      display: block;
    }
  }
`;

const TextOfCountStylized = styled(TextOfCount)`
  margin: 0 0 0 ${getSize(7)};
`;

const NestedLinks = styled.ul`
  display: none;
  padding-left: ${getSize(34)};
`;

export default DashboardNavLinks;
