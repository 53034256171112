import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AdminGetVouchersVariables = Types.Exact<{
  input: Types.GetVouchersFilter;
}>;


export type AdminGetVouchers = (
  { __typename: 'Query' }
  & { adminGetVouchers?: Types.Maybe<(
    { __typename: 'AdminVoucherPagesModel' }
    & Pick<Types.AdminVoucherPagesModel, 'page' | 'pages' | 'pageSize' | 'total'>
    & { vouchers: Array<(
      { __typename: 'AdminVoucherModel' }
      & Pick<Types.AdminVoucherModel, 'id' | 'patientId' | 'patientName' | 'therapistName' | 'usedAt' | 'sessionType' | 'sessionDuration' | 'status'>
    )> }
  )> }
);


export const AdminGetVouchersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AdminGetVouchers"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetVouchersFilter"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminGetVouchers"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"vouchers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"patientId"}},{"kind":"Field","name":{"kind":"Name","value":"patientName"}},{"kind":"Field","name":{"kind":"Name","value":"therapistName"}},{"kind":"Field","name":{"kind":"Name","value":"usedAt"}},{"kind":"Field","name":{"kind":"Name","value":"sessionType"}},{"kind":"Field","name":{"kind":"Name","value":"sessionDuration"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}},{"kind":"Field","name":{"kind":"Name","value":"page"}},{"kind":"Field","name":{"kind":"Name","value":"pages"}},{"kind":"Field","name":{"kind":"Name","value":"pageSize"}},{"kind":"Field","name":{"kind":"Name","value":"total"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useAdminGetVouchers__
 *
 * To run a query within a React component, call `useAdminGetVouchers` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetVouchers` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetVouchers({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminGetVouchers(baseOptions: Apollo.QueryHookOptions<AdminGetVouchers, AdminGetVouchersVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetVouchers, AdminGetVouchersVariables>(AdminGetVouchersDocument, options);
      }
export function useAdminGetVouchersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetVouchers, AdminGetVouchersVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetVouchers, AdminGetVouchersVariables>(AdminGetVouchersDocument, options);
        }
export type AdminGetVouchersHookResult = ReturnType<typeof useAdminGetVouchers>;
export type AdminGetVouchersLazyQueryHookResult = ReturnType<typeof useAdminGetVouchersLazyQuery>;
export type AdminGetVouchersQueryResult = Apollo.QueryResult<AdminGetVouchers, AdminGetVouchersVariables>;