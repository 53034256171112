import React, { FC, ReactNode } from 'react';
import styled, { CSSProp } from 'styled-components';
import { getSize } from 'lib/utils';

interface PopUpMessageProps {
  children: ReactNode;
  rootCSS?: CSSProp;
  className?: string;
}

const PopUpMessage: FC<PopUpMessageProps> = ({
  children,
  rootCSS,
  className,
}) => {
  return (
    <Text className={className} $CSS={rootCSS}>
      {children}
    </Text>
  );
};

const Text = styled.p<{ $CSS?: CSSProp }>`
  position: absolute;
  z-index: 1000;
  left: 50%;
  bottom: calc(100% + ${getSize(3)});
  display: none;
  padding: ${getSize(5)} ${getSize(15)};
  text-align: center;
  font-weight: 400;
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: white;
  border-radius: ${getSize(8)};
  background: var(--black3);
  transform: translateX(-50%);
  box-shadow: 0 ${getSize(20)} ${getSize(90)} rgba(40, 31, 61, 0.11);
  opacity: 0;

  &::before {
    content: '';
    z-index: -1;
    position: absolute;
    left: 50%;
    bottom: ${getSize(-5)};
    width: ${getSize(25)};
    height: ${getSize(25)};
    background: var(--black3);
    border-radius: ${getSize(4)};
    transform: translateX(-50%) rotate(-45deg);
    transform-origin: center;
  }

  @keyframes showTooltip {
    to {
      opacity: 1;
      transform: translate(-50%, ${getSize(-10)});
    }
  }

  ${({ $CSS }) => $CSS}
`;

export default PopUpMessage;
