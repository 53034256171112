import {
  AdminGetAllCompanies,
  adminGetAllCompaniesFragment,
} from 'common/query/__generated__/admin-get-all-companies';
import { useGetInsuranceCompanies } from 'common/query/__generated__/get-all-insurance-companies';
import { RowTemplate } from 'components/therapists-list/components';
import { revertedDirection } from 'components/therapists-list/therapists-list';
import { getSize } from 'lib/utils';

import React, { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled, { css } from 'styled-components';
import { ArrowDownIcon, Loader } from 'ui';
import { OrderByDirection } from '__generated__/types';
import { BusinessAdminSort, SwitchVariants } from '../business-page';
import { CompanyCard } from './company-card';

import { HeaderTable } from './header-table';

interface AllUsersProps {
  className?: string;
  dataLength: number;
  onNext: () => void;
  hasMore: boolean;
  companies?: AdminGetAllCompanies;
  isLoading?: boolean;
  futureSessionCount: number;
  setSortOption: (v: BusinessAdminSort) => void;
  setSortDirection: (v: OrderByDirection) => void;
  sortDirection: OrderByDirection;
  sortOption: BusinessAdminSort;
  onOpenModal: () => void;
  setCompanyId: React.Dispatch<React.SetStateAction<string>>;
  openReportModal: () => void;
  switchSelect: string;
}

export const AllCompanies: FC<AllUsersProps> = ({
  className,
  onNext,
  dataLength,
  hasMore,
  companies,
  isLoading,
  setSortOption,
  setSortDirection,
  sortDirection,
  sortOption,
  onOpenModal,
  setCompanyId,
  openReportModal,
  switchSelect,
}) => {
  const companiesArray = companies?.adminGetAllCompanies || [];

  const DescAscToNum = (sortDirection: OrderByDirection) => {
    return sortDirection === OrderByDirection.Asc ? 1 : -1;
  };

  const companiesSort = (
    a: adminGetAllCompaniesFragment,
    b: adminGetAllCompaniesFragment,
  ) => {
    if (!a || !b) return 0;
    if (sortOption === BusinessAdminSort.Companies) {
      if (a.name > b.name) {
        return DescAscToNum(sortDirection);
      }
      if (a.name < b.name) {
        return DescAscToNum(sortDirection) * -1;
      }
    }
    if (sortOption === BusinessAdminSort.Created) {
      if (a.createdAt > b.createdAt) {
        return DescAscToNum(sortDirection);
      }
      if (a.createdAt < b.createdAt) {
        return DescAscToNum(sortDirection) * -1;
      }
    }
    if (sortOption === BusinessAdminSort.Cap) {
      if (!a.currentPlan?.employeeLimit || !b.currentPlan?.employeeLimit)
        return 0;
      if (a.currentPlan.employeeLimit > b.currentPlan.employeeLimit) {
        return DescAscToNum(sortDirection);
      }
      if (a.currentPlan.employeeLimit < b.currentPlan.employeeLimit) {
        return DescAscToNum(sortDirection) * -1;
      }
    }
    if (sortOption === BusinessAdminSort.Employees) {
      if (a.totalEmployees > b.totalEmployees) {
        return DescAscToNum(sortDirection);
      }
      if (a.totalEmployees < b.totalEmployees) {
        return DescAscToNum(sortDirection) * -1;
      }
    }
    if (sortOption === BusinessAdminSort.PlanType) {
      if (!a.currentPlan?.planType || !b.currentPlan?.planType) return 0;
      if (a.currentPlan.planType > b.currentPlan.planType) {
        return DescAscToNum(sortDirection);
      }
      if (a.totalEmployees < b.totalEmployees) {
        return DescAscToNum(sortDirection) * -1;
      }
    }
    if (sortOption === BusinessAdminSort.Registered) {
      if (a.employeesRegistered > b.employeesRegistered) {
        return DescAscToNum(sortDirection);
      }
      if (a.employeesRegistered < b.employeesRegistered) {
        return DescAscToNum(sortDirection) * -1;
      }
    }
    if (sortOption === BusinessAdminSort.Utilization) {
      if (a.utilization > b.utilization) {
        return DescAscToNum(sortDirection);
      }
      if (a.utilization < b.utilization) {
        return DescAscToNum(sortDirection) * -1;
      }
    }
    if (sortOption === BusinessAdminSort.Avg) {
      if (a.avgSessionsPerPatient > b.avgSessionsPerPatient) {
        return DescAscToNum(sortDirection);
      }
      if (a.avgSessionsPerPatient < b.avgSessionsPerPatient) {
        return DescAscToNum(sortDirection) * -1;
      }
    }
    if (sortOption === BusinessAdminSort.Total) {
      if (a.totalEmployees > b.totalEmployees) {
        return DescAscToNum(sortDirection);
      }
      if (a.totalEmployees < b.totalEmployees) {
        return DescAscToNum(sortDirection) * -1;
      }
    }
    return 0;
  };

  const handleSort = (option: BusinessAdminSort) => {
    if (sortOption !== option) {
      setSortOption(option);
      setSortDirection(OrderByDirection.Desc);
    } else {
      setSortDirection(revertedDirection[sortDirection]);
    }
  };

  const renderArrow = (option: BusinessAdminSort) => {
    return (
      <Icon direction={sortDirection} active={sortOption === option}>
        <ArrowDownIcon
          height={getSize(12)}
          width={getSize(12)}
          stroke={sortOption === option ? '#000' : '#97909E'}
        />
      </Icon>
    );
  };

  const { data: insuranceCompaniesData } = useGetInsuranceCompanies();
  const insuranceCompanies = insuranceCompaniesData?.insuranceCompanies
    ? insuranceCompaniesData?.insuranceCompanies
    : [];

  return (
    <Wrapper className={className}>
      <HeaderWrapper>
        <HeaderTable />
        <TableHeader>
          <TableHeaderText
            active={sortOption === BusinessAdminSort.Companies}
            onClick={() => handleSort(BusinessAdminSort.Companies)}>
            Companies
            {renderArrow(BusinessAdminSort.Companies)}
          </TableHeaderText>
          <TableHeaderText
            disabled={isLoading}
            active={sortOption === BusinessAdminSort.Created}
            onClick={() => handleSort(BusinessAdminSort.Created)}>
            Created
            {renderArrow(BusinessAdminSort.Created)}
          </TableHeaderText>
          <TableHeaderText
            disabled={isLoading}
            active={sortOption === BusinessAdminSort.PlanType}
            onClick={() => handleSort(BusinessAdminSort.PlanType)}>
            Plan
            {renderArrow(BusinessAdminSort.PlanType)}
          </TableHeaderText>
          <TableHeaderText>Active</TableHeaderText>
          <TableHeaderText
            disabled={isLoading}
            active={sortOption === BusinessAdminSort.Cap}
            onClick={() => handleSort(BusinessAdminSort.Cap)}>
            Cap
            {renderArrow(BusinessAdminSort.Cap)}
          </TableHeaderText>
          <TableHeaderText
            disabled={isLoading}
            active={sortOption === BusinessAdminSort.Employees}
            onClick={() => handleSort(BusinessAdminSort.Employees)}>
            Empl
            {renderArrow(BusinessAdminSort.Employees)}
          </TableHeaderText>
          <TableHeaderText
            active={sortOption === BusinessAdminSort.Registered}
            disabled={isLoading}
            onClick={() => handleSort(BusinessAdminSort.Registered)}>
            Registered
            {renderArrow(BusinessAdminSort.Registered)}
          </TableHeaderText>
          <TableHeaderText
            disabled={isLoading}
            active={sortOption === BusinessAdminSort.Utilization}
            onClick={() => handleSort(BusinessAdminSort.Utilization)}>
            Utili
            {renderArrow(BusinessAdminSort.Utilization)}
          </TableHeaderText>
          <TableHeaderText
            disabled={isLoading}
            active={sortOption === BusinessAdminSort.Avg}
            onClick={() => handleSort(BusinessAdminSort.Avg)}>
            Avg
            {renderArrow(BusinessAdminSort.Avg)}
          </TableHeaderText>
          <TableHeaderText
            disabled={isLoading}
            active={sortOption === BusinessAdminSort.Total}
            onClick={() => handleSort(BusinessAdminSort.Total)}>
            Total
            {renderArrow(BusinessAdminSort.Total)}
          </TableHeaderText>
        </TableHeader>
      </HeaderWrapper>
      <ScrollWrapper id="scrollableChatsList">
        {isLoading ? (
          <Loader hasFillWholeBlock size={50} />
        ) : (
          <UsersCompaniesList
            dataLength={dataLength}
            next={onNext}
            hasMore={hasMore}
            scrollableTarget="scrollableChatsList"
            loader={<Loader />}>
            {companiesArray
              ?.filter(
                (companies) =>
                  companies.archived ===
                  (switchSelect.toLowerCase() ===
                    SwitchVariants.Archived.toLowerCase()),
              )
              .sort((a, b) => companiesSort(a, b))
              .map((company) => (
                <CompanyCard
                  key={company.id}
                  company={company}
                  insuranceCompanies={insuranceCompanies}
                  onOpenModal={onOpenModal}
                  setCompanyId={setCompanyId}
                  openReportModal={openReportModal}
                />
              ))}
          </UsersCompaniesList>
        )}
      </ScrollWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const gridTemplate = css`
  grid-template-columns: 12% 12% 12% 9% 9% 9% 7% 6% 6% 9% 9%;
`;

const HeaderWrapper = styled.div`
  padding: 0 ${getSize(40)};
`;

const TableHeader = styled(RowTemplate)`
  margin-top: 31px;
  height: ${getSize(52)};
  background-color: var(--purple11);
  border-radius: ${getSize(8)};
  ${gridTemplate}
`;

const TableHeaderText = styled.button<{ active?: boolean }>`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  text-align: center;
  padding: 0 ${getSize(18)};
  color: var(--gray7);
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ active }) => (active ? 'var(--black)' : 'var(--gray7)')};

  &:first-child {
    padding-left: ${getSize(24)};
    text-align: left;
  }
`;

const Icon = styled.div<{ direction: OrderByDirection; active: boolean }>`
  margin-left: 0px;
  ${({ direction, active }) =>
    active &&
    direction === OrderByDirection.Asc &&
    'transform: rotate(180deg)'};
`;

const ScrollWrapper = styled.div`
  overflow: auto;
  margin-top: ${getSize(12)};
  height: ${getSize(500)};

  &::-webkit-scrollbar {
    display: none;
  }
`;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore-next-line
const UsersCompaniesList = styled(InfiniteScroll)`
  padding: ${getSize(20)}${getSize(40)};
`;
