import { getSize } from 'lib/utils';
import 'react';
import { useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';
import Select, { CommonProps, ValueType } from 'react-select';
import styled, { CSSProp } from 'styled-components';

export interface Option {
  label: string;
  value: string;
}

const Label = styled.label<{ CSS?: CSSProp }>`
  display: block;
  ${({ CSS }) => CSS}
`;

const Text = styled.span<{ CSS?: CSSProp }>`
  display: block;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  color: var(--gray7);

  ${({ CSS }) => CSS}
`;

export interface MultiSelectProps extends FieldRenderProps<string> {
  label?: string;
  options: Option[];
  disabled?: boolean;
  rootCSS?: CSSProp;
}

export const MultiSelect = (props: MultiSelectProps) => {
  const { disabled, options, input, label, rootCSS } = props;
  const handleChange = useCallback(
    (values: ValueType<Option, true>) => {
      input.onChange(values);
    },
    [input],
  );
  return (
    <Label CSS={rootCSS}>
      <Text>{label}</Text>
      <Select
        label={label}
        options={options}
        value={input.value as any as Option[]}
        isMulti={true}
        isDisabled={disabled}
        onChange={handleChange}
      />
    </Label>
  );
};
