import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AdminGetAllCompaniesVariables = Types.Exact<{ [key: string]: never; }>;


export type AdminGetAllCompanies = (
  { __typename: 'Query' }
  & { adminGetAllCompanies: Array<(
    { __typename: 'CompanyModel' }
    & adminGetAllCompaniesFragment
  )> }
);

export type adminGetAllCompaniesFragment = (
  { __typename: 'CompanyModel' }
  & Pick<Types.CompanyModel, 'id' | 'name' | 'companyKey' | 'createdAt' | 'totalEmployees' | 'employeesRegistered' | 'utilization' | 'avgSessionsPerPatient' | 'sessionsBooked' | 'archived'>
  & { currentPlan?: Types.Maybe<(
    { __typename: 'CompanyPlanModel' }
    & Pick<Types.CompanyPlanModel, 'insuranceCompanyId' | 'planType' | 'employeeLimit' | 'isActive'>
  )>, nextPlan?: Types.Maybe<(
    { __typename: 'CompanyPlanModel' }
    & Pick<Types.CompanyPlanModel, 'insuranceCompanyId' | 'planType' | 'employeeLimit' | 'isActive'>
  )> }
);

export const adminGetAllCompaniesFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"adminGetAllCompaniesFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CompanyModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"companyKey"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"totalEmployees"}},{"kind":"Field","name":{"kind":"Name","value":"employeesRegistered"}},{"kind":"Field","name":{"kind":"Name","value":"utilization"}},{"kind":"Field","name":{"kind":"Name","value":"avgSessionsPerPatient"}},{"kind":"Field","name":{"kind":"Name","value":"sessionsBooked"}},{"kind":"Field","name":{"kind":"Name","value":"archived"}},{"kind":"Field","name":{"kind":"Name","value":"currentPlan"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"insuranceCompanyId"}},{"kind":"Field","name":{"kind":"Name","value":"planType"}},{"kind":"Field","name":{"kind":"Name","value":"employeeLimit"}},{"kind":"Field","name":{"kind":"Name","value":"isActive"}}]}},{"kind":"Field","name":{"kind":"Name","value":"nextPlan"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"insuranceCompanyId"}},{"kind":"Field","name":{"kind":"Name","value":"planType"}},{"kind":"Field","name":{"kind":"Name","value":"employeeLimit"}},{"kind":"Field","name":{"kind":"Name","value":"isActive"}}]}}]}}]} as unknown as DocumentNode;
export const AdminGetAllCompaniesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AdminGetAllCompanies"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminGetAllCompanies"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"adminGetAllCompaniesFragment"}}]}}]}},...adminGetAllCompaniesFragment.definitions]} as unknown as DocumentNode;

/**
 * __useAdminGetAllCompanies__
 *
 * To run a query within a React component, call `useAdminGetAllCompanies` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetAllCompanies` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetAllCompanies({
 *   variables: {
 *   },
 * });
 */
export function useAdminGetAllCompanies(baseOptions?: Apollo.QueryHookOptions<AdminGetAllCompanies, AdminGetAllCompaniesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetAllCompanies, AdminGetAllCompaniesVariables>(AdminGetAllCompaniesDocument, options);
      }
export function useAdminGetAllCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetAllCompanies, AdminGetAllCompaniesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetAllCompanies, AdminGetAllCompaniesVariables>(AdminGetAllCompaniesDocument, options);
        }
export type AdminGetAllCompaniesHookResult = ReturnType<typeof useAdminGetAllCompanies>;
export type AdminGetAllCompaniesLazyQueryHookResult = ReturnType<typeof useAdminGetAllCompaniesLazyQuery>;
export type AdminGetAllCompaniesQueryResult = Apollo.QueryResult<AdminGetAllCompanies, AdminGetAllCompaniesVariables>;