import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type OverallUnreadCountVariables = Types.Exact<{ [key: string]: never; }>;


export type OverallUnreadCount = (
  { __typename: 'Query' }
  & Pick<Types.Query, 'overallUnreadCount'>
);


export const OverallUnreadCountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"OverallUnreadCount"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"overallUnreadCount"}}]}}]} as unknown as DocumentNode;

/**
 * __useOverallUnreadCount__
 *
 * To run a query within a React component, call `useOverallUnreadCount` and pass it any options that fit your needs.
 * When your component renders, `useOverallUnreadCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverallUnreadCount({
 *   variables: {
 *   },
 * });
 */
export function useOverallUnreadCount(baseOptions?: Apollo.QueryHookOptions<OverallUnreadCount, OverallUnreadCountVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OverallUnreadCount, OverallUnreadCountVariables>(OverallUnreadCountDocument, options);
      }
export function useOverallUnreadCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OverallUnreadCount, OverallUnreadCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OverallUnreadCount, OverallUnreadCountVariables>(OverallUnreadCountDocument, options);
        }
export type OverallUnreadCountHookResult = ReturnType<typeof useOverallUnreadCount>;
export type OverallUnreadCountLazyQueryHookResult = ReturnType<typeof useOverallUnreadCountLazyQuery>;
export type OverallUnreadCountQueryResult = Apollo.QueryResult<OverallUnreadCount, OverallUnreadCountVariables>;