import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled, { CSSProp } from 'styled-components';
import Select, {
  FormatOptionLabelMeta,
  components,
  Styles,
} from 'react-select';
import { MenuPlacement, MenuPosition, ValueType } from 'react-select/src/types';
import { SelectComponents } from 'react-select/src/components';

import { customStyles } from './utils/styles';
import { ArrowDownIcon } from 'ui';
import { getSize } from 'lib/utils';
import StateManager from 'react-select';

export interface Option {
  label: string | JSX.Element;
  value: string;
}
export interface SingleSelectProps extends FieldRenderProps<string> {
  label?: string;
  rootCSS?: CSSProp;
  labelCSS?: CSSProp;
  noOptionsMessage?: () => string;
  disabled?: boolean;
  options: Option[];
  isSearchable?: boolean;
  placeholder?: string;
  CustomOptionLabel?: (
    option: Option,
    labelMeta: FormatOptionLabelMeta<Option, any>,
  ) => JSX.Element;
  customComponents?: Partial<SelectComponents<Option, any>>;
  hasLightTheme?: boolean;
  arrowColorVariable?: string;
  labelTextWhenDisabled?: string;
  timeInputId?: string;
  customSelectStyles?: Partial<Styles<any, any>>;
  captureMenuScroll?: boolean;
  menuPlacement?: MenuPlacement;
  selectRef: React.RefObject<StateManager<Option>>;
  handleOuterWrapperFocus?: (isFocused: boolean) => void;
  menuPosition?: MenuPosition;
}

function SingleSelect({
  input,
  meta,
  label,
  rootCSS,
  noOptionsMessage,
  disabled,
  options = [],
  placeholder,
  isSearchable = true,
  customComponents,
  CustomOptionLabel,
  hasLightTheme,
  arrowColorVariable,
  labelTextWhenDisabled,
  timeInputId,
  labelCSS,
  customSelectStyles,
  captureMenuScroll,
  menuPlacement,
  handleOuterWrapperFocus,
  selectRef,
  menuPosition,
}: SingleSelectProps) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!timeInputId) return;
    const inputMenu = document.getElementById(timeInputId);

    if (inputMenu) {
      const menuScrollableContainer = inputMenu.children[0];
      const optionHeight = menuScrollableContainer.children[0].clientHeight;
      const eightTimeAmIndex = options.findIndex(
        ({ label }) => label === '08:00',
      );
      if (eightTimeAmIndex > 0) {
        menuScrollableContainer.scrollTo({
          top: optionHeight * eightTimeAmIndex + 1,
        });
      }
    }
  });

  const { error, submitError, touched, dirtySinceLastSubmit, data } = meta;
  const hasError =
    ((error || submitError) && touched && !dirtySinceLastSubmit) || data?.error;

  const selectedOption = useMemo(
    () => options.find((option) => option.value === input.value) ?? null,
    [options, input],
  );
  const handleChange = useCallback(
    (valueType: ValueType<Option, any>) => {
      const newValue = (valueType as Option).value;
      input.onChange(newValue);
    },
    [input],
  );

  const getDropdownColor = useCallback(() => {
    if (disabled) {
      return '--gray7';
    } else if (hasLightTheme) {
      return '--white';
    } else {
      return arrowColorVariable || '--black3';
    }
  }, [disabled, hasLightTheme, arrowColorVariable]);

  return (
    <Wrapper
      as={label ? 'label' : 'div'}
      $CSS={rootCSS}
      onClick={() => {
        isOpen
          ? handleOuterWrapperFocus && handleOuterWrapperFocus(true)
          : handleOuterWrapperFocus && handleOuterWrapperFocus(false);
      }}>
      {label && (
        <Text $disabled={disabled} $CSS={labelCSS}>
          {label}
        </Text>
      )}
      <Select
        components={{
          DropdownIndicator: () => (
            <ArrowDownIcon stroke={`var(${getDropdownColor()})`} />
          ),
          ...(typeof labelTextWhenDisabled === 'string' && disabled
            ? {
                SingleValue: () => <p>{labelTextWhenDisabled}</p>,
                Placeholder: () => <p>{labelTextWhenDisabled}</p>,
              }
            : {}),
          ...customComponents,
          Menu: (props) => {
            return (
              <components.Menu {...props}>
                <div id={timeInputId} ref={props.innerRef}>
                  {props.children}
                </div>
              </components.Menu>
            );
          },
        }}
        value={selectedOption}
        placeholder={placeholder}
        onChange={handleChange}
        options={options}
        noOptionsMessage={noOptionsMessage}
        styles={customSelectStyles || customStyles}
        isSearchable={isSearchable}
        isDisabled={disabled}
        isError={hasError}
        openMenuOnFocus={selectRef ? true : undefined}
        isOpen={isOpen}
        hasLightTheme={hasLightTheme}
        menuShouldScrollIntoView
        menuPosition={menuPosition}
        menuPlacement={menuPlacement}
        captureMenuScroll={captureMenuScroll}
        formatOptionLabel={CustomOptionLabel}
        ref={selectRef}
        onMenuClose={() => {
          setIsOpen(false);
        }}
        onMenuOpen={() => {
          setIsOpen(true);
        }}
      />

      {hasError && <ErrorText>{error || submitError || data?.error}</ErrorText>}
    </Wrapper>
  );
}

const Wrapper = styled.label<{ $CSS?: CSSProp }>`
  display: block;

  ${({ $CSS }) => $CSS}
`;

const Text = styled.span<{ $disabled?: boolean; $CSS?: CSSProp }>`
  display: block;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  color: var(--gray7);

  ${({ $CSS }) => $CSS}
`;

const ErrorText = styled.span`
  margin: ${getSize(2)} 0 0;
  font-weight: 400;
  font-size: ${getSize(10)};
  line-height: ${getSize(16)};
  color: var(--red);
`;

export default SingleSelect;
