import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetAdminFeedbackDescriptionsVariables = Types.Exact<{
  first?: Types.Maybe<Types.Scalars['Int']>;
  after?: Types.Maybe<Types.Scalars['String']>;
  patientId: Types.Scalars['String'];
}>;


export type GetAdminFeedbackDescriptions = (
  { __typename: 'Query' }
  & { adminFeedbackDescriptions: (
    { __typename: 'FeedbackDescriptions' }
    & { nodes: Array<(
      { __typename: 'FeedbackTextModel' }
      & Pick<Types.FeedbackTextModel, 'description' | 'therapistName' | 'createdAt' | 'sessionMetaId'>
    )>, pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<Types.PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);


export const GetAdminFeedbackDescriptionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetAdminFeedbackDescriptions"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"first"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"after"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"patientId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminFeedbackDescriptions"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"Variable","name":{"kind":"Name","value":"first"}}},{"kind":"Argument","name":{"kind":"Name","value":"after"},"value":{"kind":"Variable","name":{"kind":"Name","value":"after"}}},{"kind":"Argument","name":{"kind":"Name","value":"patientId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"patientId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"nodes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"therapistName"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"sessionMetaId"}}]}},{"kind":"Field","name":{"kind":"Name","value":"pageInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hasNextPage"}},{"kind":"Field","name":{"kind":"Name","value":"endCursor"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetAdminFeedbackDescriptions__
 *
 * To run a query within a React component, call `useGetAdminFeedbackDescriptions` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminFeedbackDescriptions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminFeedbackDescriptions({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetAdminFeedbackDescriptions(baseOptions: Apollo.QueryHookOptions<GetAdminFeedbackDescriptions, GetAdminFeedbackDescriptionsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminFeedbackDescriptions, GetAdminFeedbackDescriptionsVariables>(GetAdminFeedbackDescriptionsDocument, options);
      }
export function useGetAdminFeedbackDescriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminFeedbackDescriptions, GetAdminFeedbackDescriptionsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminFeedbackDescriptions, GetAdminFeedbackDescriptionsVariables>(GetAdminFeedbackDescriptionsDocument, options);
        }
export type GetAdminFeedbackDescriptionsHookResult = ReturnType<typeof useGetAdminFeedbackDescriptions>;
export type GetAdminFeedbackDescriptionsLazyQueryHookResult = ReturnType<typeof useGetAdminFeedbackDescriptionsLazyQuery>;
export type GetAdminFeedbackDescriptionsQueryResult = Apollo.QueryResult<GetAdminFeedbackDescriptions, GetAdminFeedbackDescriptionsVariables>;