import { getSize } from 'lib/utils';

const THEMES: any = {
  primary: () => `
  display: block;

  .DayPickerInput {
    width: 100%;

    .DayPickerInput-Overlay {
      z-index: 10;
      top: ${getSize(2)};
      border-radius: ${getSize(8)};
      box-shadow: 0 ${getSize(10)} ${getSize(30)} var(--blue-opacity2);

      .DayPicker-wrapper {
        padding: ${getSize(15)} ${getSize(5)};
      }

      .DayPicker-Month {
        font-weight: 400;
        font-size: ${getSize(14)};
        line-height: ${getSize(14)};
        color: var(--black3);
        z-index: 100;
      }

      .DayPicker-Day {
        padding: 0.8em 0.7em;
        transition: background 0.2s ease-out;
        color: var(--black3);

        &:hover {
          &:not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected) {
            background: var(--purple3) !important;
          }
        }

        &--today {
          &:not(.DayPicker-Day--outside) {
            border-radius: ${getSize(8)};
            background: var(--purple5);
            color: var(--purple);
          }
        }

        &--selected {
          &:not(.DayPicker-Day--outside) {
            border-radius: ${getSize(8)};
            background: var(--purple);
            color: white;

            &:hover {
              background: var(--purple) !important;
            }
          }
        }

        &--disabled {
          color: var(--black3);
        }
      }
    }
  }
  `,
  secondary: () => `
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  position: relative;

  .DayPickerInput {
    width: 100%;
    height: 100%;
    flex-grow: 1;

    .DayPickerInput-OverlayWrapper{
      position: static;
    }

    .DayPickerInput-Overlay {
      z-index: 10;
      bottom: calc(100% + ${getSize(1)});
      border-radius: ${getSize(8)};
      filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
      left: 0;
      right: auto;

      .DayPicker-wrapper {
        padding: 0;
      }

      .DayPicker-Month {
        font-weight: 400;
        font-size: ${getSize(14)};
        line-height: ${getSize(14)};
        color: var(--black3);
        z-index: 100;
        border-collapse: separate;
        border-spacing: ${getSize(3)};
        margin: ${getSize(10)} ${getSize(18)} ${getSize(18)} ${getSize(18)};
      }

      .DayPicker-Day {
        padding: 0.7em 0.6em;
        transition: background 0.2s ease-out, outline 0.2s ease-out;
        color: var(--black3);

        &:hover {
          &:not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected) {
            background: var(--white) !important;
            outline: 1px solid var(--black9);
            border-radius: ${getSize(8)};
          }
          & > p::before {
            top: ${getSize(-7)} !important;
          }
        }

        &--today {
          &:not(.DayPicker-Day--outside) {
            color: var(--black3);
            font-weight: 600;
          }

          & p {
            color: var(--black3) !important;
          }
        }

        &--selected {
          &:not(.DayPicker-Day--outside) {
            border-radius: ${getSize(8)};
            outline: 1px solid var(--black9);
            background-color: var(--gray-opacity5);
            color: var(--black3);
            & > p::before {
              top: ${getSize(-7)} !important;
            }
            &:hover {
              background: var(--white) !important;
            }
          }

          &:not(.DayPicker-Day--today) {
            font-weight: 500;
          }
        }

        &--disabled {
          padding: 0.7em 0.6em;
          font-weight: 400;
          color: var(--gray35);
        }
      }
    }
  `,
};

export default THEMES;
