import styled from 'styled-components';
import { getSize } from 'lib/utils';
import { Button } from 'ui/button';
import { RowTemplate } from '../row-template';

export interface PricingRowItem {
  pricingData: any;
  onClick: () => void;
}

function PricingRowItem({ pricingData, onClick }: PricingRowItem) {
  const { eightyMin, fiftyMin, priceType, thirtyMin } = pricingData;

  return (
    <Wrapper>
      <InfoBlock>
        <Text>{priceType}</Text>
      </InfoBlock>
      <InfoBlock>
        <Text>{thirtyMin}</Text>
      </InfoBlock>
      <InfoBlock>
        <Text>{fiftyMin}</Text>
      </InfoBlock>
      <InfoBlock>
        <Text>{eightyMin}</Text>
      </InfoBlock>
      <InfoBlock>
        <EditButton onClick={onClick} theme="primary">
          Edit
        </EditButton>
      </InfoBlock>
    </Wrapper>
  );
}

const Wrapper = styled(RowTemplate)`
  height: ${getSize(64)};
  box-shadow: 0 0 ${getSize(30)} var(--blue-opacity2);
  border-radius: ${getSize(8)};
  margin-bottom: ${getSize(12)};
`;

const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    padding-left: ${getSize(24)};
    justify-content: flex-start;
  }
`;

const Text = styled.p`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--black3);
`;

const EditButton = styled(Button)`
  min-height: ${getSize(20)};
  padding: ${getSize(5)} ${getSize(20)};
  font-weight: 400;
  max-width: ${getSize(70)};
`;

export default PricingRowItem;
