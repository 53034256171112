import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { DashboardLayout } from 'layouts/dashboard';
import PatientsPage from 'pages/patients-page';
import MessagingPage from 'pages/messaging-page';
import PricingPage from 'pages/pricing-page';
import RequestsPage from 'pages/requests-page';
import TherapistPage from 'pages/therapist-page';
import { dashboardRoutes } from 'routes/root-routes';
import InsurancePage from 'pages/insurance-page/insurance-page';
import InsuranceSessionsPage from 'pages/insurance-sessions-page';
import LogsPage from 'pages/logs-page';
import TherapistsRoutes from './therapists-routes';
import FeedbackPage from 'pages/feedback-page';
import businessPage from 'pages/business-page';
import { VouchersPage } from 'pages/vouchers-page';
import ResourcesPage from '../../pages/resources';

/** Hack for marketing admins */
import { marketingOnlyEmails } from 'types';
import { StorageService } from 'lib/utils';

const DashboardRoute = () => {
  /** Check if logged user is on list marketingOnlyEmails and serve only resources to it */
  const looggedUserEmail = StorageService.getAdminEmail() || '';

  if (marketingOnlyEmails.includes(looggedUserEmail)) {
    return (
      <Router>
        <DashboardLayout>
          <Switch>
            <Route
              path={dashboardRoutes.resources.PATH}
              component={ResourcesPage}
            />
          </Switch>
        </DashboardLayout>
      </Router>
    );
  }

  return (
    <Router>
      <DashboardLayout>
        <Switch>
          <Route
            exact
            path={dashboardRoutes.requests.PATH}
            component={RequestsPage}
          />
          <Route
            exact
            path={dashboardRoutes.request.PATH}
            component={TherapistPage}
          />
          <Route
            exact
            path={dashboardRoutes.therapist.PATH}
            component={TherapistPage}
          />
          <Route
            exact
            path={dashboardRoutes.patients.PATH}
            component={PatientsPage}
          />
          <Route
            path={dashboardRoutes.therapists.PATH}
            component={TherapistsRoutes}
          />
          <Route
            exact
            path={dashboardRoutes.messaging.PATH}
            component={MessagingPage}
          />
          <Route path={dashboardRoutes.pricing.PATH} component={PricingPage} />
          <Route
            path={dashboardRoutes.insurance.PATH}
            component={InsurancePage}
          />
          <Route path={dashboardRoutes.logs.PATH} component={LogsPage} />
          <Route
            path={dashboardRoutes.insuranceSessions.PATH}
            component={InsuranceSessionsPage}
          />
          <Route
            path={dashboardRoutes.feedback.PATH}
            component={FeedbackPage}
          />
          <Route
            path={dashboardRoutes.resources.PATH}
            component={ResourcesPage}
          />
          <Route
            path={dashboardRoutes.vouchers.PATH}
            component={VouchersPage}
          />
          <Route
            path={dashboardRoutes.business.PATH}
            component={businessPage}
          />
          <Redirect
            from={dashboardRoutes.dashboard.PATH}
            to={dashboardRoutes.requests.PATH}
          />
        </Switch>
      </DashboardLayout>
    </Router>
  );
};

export default DashboardRoute;
