import { SessionNeedTransferModelFragment } from 'common/query/__generated__/get-insurance-sessions';
import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import styled from 'styled-components';
import { SessionItem } from './components';

interface SessionsListProps {
  sessions?: SessionNeedTransferModelFragment[];
  onPaySession: (sessionMetaId: string) => void;
  isPayButtonHide?: boolean;
}

const SessionsList: FC<SessionsListProps> = ({
  sessions,
  onPaySession,
  isPayButtonHide,
}) => {
  return (
    <Wrapper>
      <Head>
        <ColumnTitle>Session date</ColumnTitle>
        <ColumnTitle>Therapist</ColumnTitle>
        <ColumnTitle>Patient</ColumnTitle>
        <ColumnTitle>Fee</ColumnTitle>
        <ColumnTitle>Session type</ColumnTitle>
        <ColumnTitle>Invoice No</ColumnTitle>
        <ColumnTitle>Status</ColumnTitle>
        <ColumnTitle></ColumnTitle>
        <ColumnTitle>Insurer</ColumnTitle>
      </Head>
      {sessions?.map((session) => (
        <SessionItem
          key={session.id}
          session={session}
          isPayButtonHide={isPayButtonHide}
          onPaySession={() => onPaySession(session.id)}
        />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Head = styled.div`
  display: grid;
  grid-template-columns: 10.71% 19.24% 16.06% 6.78% 7.83% 15.15% 4.83% 5.06% 8% 8.64%;
  align-items: center;
  margin-bottom: ${getSize(16)};
`;

const ColumnTitle = styled.p`
  text-align: left;
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--gray7);

  &:first-child {
    padding-left: ${getSize(16)};
  }
`;

export default SessionsList;
