import React from 'react';

function LiveChatIcon({ ...rest }) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="#6B4EE6" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.4 16a9.6 9.6 0 119.613 9.6H6.4V16z"
      />
      <path
        d="M11.57 14.154c0-.612.545-1.108 1.218-1.108h6.424c.673 0 1.219.496 1.219 1.108 0 .612-.546 1.108-1.219 1.108h-6.424c-.673 0-1.219-.496-1.219-1.108zM11.57 18.585c0-.612.495-1.108 1.107-1.108h3.692a1.108 1.108 0 010 2.215h-3.692a1.108 1.108 0 01-1.108-1.107z"
        fill="#fff"
      />
    </svg>
  );
}

export default LiveChatIcon;
