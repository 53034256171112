import React, { useCallback, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled, { CSSProp } from 'styled-components';
import { DropzoneRootProps, useDropzone } from 'react-dropzone';

import { BUCKET_TYPES, UploadFile } from 'interfaces/files';
import { notifyError } from 'lib/utils/notification';
import { getFileInfo, getSize, uploadFileToS3 } from 'lib/utils';
import { Button } from 'ui';
import { FileExtension } from '__generated__/types';
import { normalizePrivateUrl } from '../../../../lib/utils/normalize-private-url';

export interface UploadMaterialButtonProps
  extends FieldRenderProps<UploadFile[] | undefined> {
  rootCSS?: CSSProp;
  disabled?: boolean;
}
export interface WrapperProps extends DropzoneRootProps {
  CSS?: CSSProp;
  isDragActive: boolean;
}

function UploadMaterialButton({
  input,
  meta,
  rootCSS,
  disabled,
}: UploadMaterialButtonProps) {
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      let currentErrorMessage = '';
      const valueFiles =
        input.value && Array.isArray(input.value) ? input.value : [];
      const incomingFile = acceptedFiles[0];

      const { fileName, fileExtension, fileType } = getFileInfo(incomingFile);

      async function setFileToForm(source: string, currentFileType: string) {
        const newFile = {
          source: normalizePrivateUrl(source),
          name: fileName,
          sizeInKb: incomingFile.size,
          fileType: currentFileType.toUpperCase(),
          extension: fileExtension.toUpperCase(),
        };

        input.onChange([...valueFiles, newFile]);
      }

      if (
        Object.values(FileExtension).includes(
          fileExtension.toUpperCase() as FileExtension,
        ) &&
        fileType
      ) {
        // http://localhost:3001/aws/admin-signed-url?therapistUserId=undefined&fileType=image&fileName=domain-connect-verify-urls%20(1)&extension=png&bucketType=representation
        const apiUrl = `aws/admin-signed-url?therapistUserId=resources&fileType=${fileType.toLocaleLowerCase()}&fileName=${fileName}&extension=${fileExtension}&bucketType=${
          BUCKET_TYPES.REPRESENTATION
        }`;

        setIsLoadingFile(true);
        uploadFileToS3({ apiUrl, file: incomingFile })
          .then((fileSource) => setFileToForm(fileSource, fileType))
          .catch((error) => notifyError({ text: error?.message }))
          .finally(() => setIsLoadingFile(false));
      } else {
        currentErrorMessage = `The file must be one of these formats: pdf, mp4, doc, docx, jpeg, png`;
      }

      setErrorMessage(currentErrorMessage);
    },
    [input],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    multiple: false,
    onDrop,
  });

  const { error, submitError, touched, dirtySinceLastSubmit, data } = meta;
  const hasError =
    ((error || submitError) && touched && !dirtySinceLastSubmit) ||
    data?.error ||
    errorMessage;

  return (
    <Wrapper
      {...(!disabled ? getRootProps() : {})}
      CSS={rootCSS}
      aria-label="Upload-files"
      isDragActive={isDragActive}>
      <SelectButton
        isLoading={isLoadingFile}
        type="button"
        theme="primary"
        isDisabled={disabled}>
        <Label>
          <input {...(!disabled ? getInputProps() : {})} />
          Select File
        </Label>
      </SelectButton>
      {hasError && (
        <ErrorText>
          {errorMessage || error || submitError || data?.error}
        </ErrorText>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div<WrapperProps>`
  display: block;

  ${({ CSS }) => CSS}
`;
const Label = styled.label`
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;

  & > input {
    display: none;
  }
`;

const SelectButton = styled(Button)`
  padding: 0;
  width: 100%;
`;

const ErrorText = styled.span`
  margin: ${getSize(2)} 0 0;
  font-weight: 400;
  font-size: ${getSize(10)};
  line-height: ${getSize(16)};
  color: var(--red);
`;

export default UploadMaterialButton;
