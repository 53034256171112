import React, { FC } from 'react';
import TitleWithInfo from '../title-with-info';
import PricesForm from '../prices-form';
import { notifyError, notifySuccess } from 'lib/utils/notification';
import { Config } from 'final-form';
import { CommissionFormValues } from '../prices-form/prices-form';
import { getFormSubmitError } from 'lib/utils/form';
import { SUCCESS_NOTIFICATION } from 'components/session-price-form/constants';
import { UserError } from '__generated__/types';
import { useChangeProfessionalSessionPrice } from 'common/mutation/__generated__/change-professional-session-price';
import { SessionPriceAdminFragment } from 'common/fragments/__generated__/session-price-admin.fragment';

const ProfessionalPricesForm: FC<ProfessionalPricesFormProps> = ({
  proffessionalId,
  title,
  sessionPrices,
}) => {
  const [changeSessionPrice, { loading: sessionPriceLoading }] =
    useChangeProfessionalSessionPrice({
      onCompleted: (data) => {
        const validateErrors = data?.adminChangeSessionPrice?.userErrors;

        if (validateErrors && validateErrors?.length > 0) {
          notifyError({ text: 'Validate error' });
          return getFormSubmitError(validateErrors as UserError[]);
        } else {
          notifySuccess(SUCCESS_NOTIFICATION);
        }
      },
      onError: ({ name, message }) =>
        notifyError({ title: name, text: message }),
    });

  const handleFormSubmit: Config<CommissionFormValues>['onSubmit'] = async (
    { minPrice, mediumPrice, maxPrice },
    form,
  ) => {
    // TODO : fix this
    // await changeSessionPrice({
    //   variables: {
    //     input: {
    //       thirtyMin: Number(minPrice),
    //       fiftyMin: Number(mediumPrice),
    //       eightyMin: Number(maxPrice),
    //       // TODO: this is removedprofessionalTitleId: proffessionalId,
    //     },
    //   },
    // });

    form.restart();
    return;
  };

  return (
    <>
      <TitleWithInfo
        title={title}
        info={`Session price is common for every ${title.toLocaleLowerCase()}.`}
      />
      <PricesForm
        handleFormSubmit={handleFormSubmit}
        initialValues={{
          minPrice: sessionPrices?.thirtyMin.toString() || '',
          mediumPrice: sessionPrices?.fiftyMin.toString() || '',
          maxPrice: sessionPrices?.eightyMin.toString() || '',
        }}
        submitLoading={sessionPriceLoading}
      />
    </>
  );
};

interface ProfessionalPricesFormProps {
  title: string;
  proffessionalId: string;
  sessionPrices?: SessionPriceAdminFragment;
}

export default ProfessionalPricesForm;
