import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetAdminFeedbackTotalInfoVariables = Types.Exact<{
  tab: Types.ReviewsTab;
}>;


export type GetAdminFeedbackTotalInfo = (
  { __typename: 'Query' }
  & { adminFeedbackTotalInfo: (
    { __typename: 'FeedbackTotalInfo' }
    & Pick<Types.FeedbackTotalInfo, 'reviewsAmount' | 'totalRating' | 'progress' | 'experience'>
  ) }
);


export const GetAdminFeedbackTotalInfoDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetAdminFeedbackTotalInfo"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"tab"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ReviewsTab"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminFeedbackTotalInfo"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"tab"},"value":{"kind":"Variable","name":{"kind":"Name","value":"tab"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"reviewsAmount"}},{"kind":"Field","name":{"kind":"Name","value":"totalRating"}},{"kind":"Field","name":{"kind":"Name","value":"progress"}},{"kind":"Field","name":{"kind":"Name","value":"experience"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetAdminFeedbackTotalInfo__
 *
 * To run a query within a React component, call `useGetAdminFeedbackTotalInfo` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminFeedbackTotalInfo` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminFeedbackTotalInfo({
 *   variables: {
 *      tab: // value for 'tab'
 *   },
 * });
 */
export function useGetAdminFeedbackTotalInfo(baseOptions: Apollo.QueryHookOptions<GetAdminFeedbackTotalInfo, GetAdminFeedbackTotalInfoVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminFeedbackTotalInfo, GetAdminFeedbackTotalInfoVariables>(GetAdminFeedbackTotalInfoDocument, options);
      }
export function useGetAdminFeedbackTotalInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminFeedbackTotalInfo, GetAdminFeedbackTotalInfoVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminFeedbackTotalInfo, GetAdminFeedbackTotalInfoVariables>(GetAdminFeedbackTotalInfoDocument, options);
        }
export type GetAdminFeedbackTotalInfoHookResult = ReturnType<typeof useGetAdminFeedbackTotalInfo>;
export type GetAdminFeedbackTotalInfoLazyQueryHookResult = ReturnType<typeof useGetAdminFeedbackTotalInfoLazyQuery>;
export type GetAdminFeedbackTotalInfoQueryResult = Apollo.QueryResult<GetAdminFeedbackTotalInfo, GetAdminFeedbackTotalInfoVariables>;