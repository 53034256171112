import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UsersForStartNewChatVariables = Types.Exact<{ [key: string]: never; }>;


export type UsersForStartNewChat = (
  { __typename: 'Query' }
  & { adminListUsersCanStartChat: Array<(
    { __typename: 'ChatUserModel' }
    & ChatUserFragment
  )> }
);

export type ChatUserFragment = (
  { __typename: 'ChatUserModel' }
  & Pick<Types.ChatUserModel, 'id' | 'name' | 'avatarUrl' | 'type'>
);

export const ChatUserFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ChatUserFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ChatUserModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"avatarUrl"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]} as unknown as DocumentNode;
export const UsersForStartNewChatDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"UsersForStartNewChat"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminListUsersCanStartChat"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChatUserFragment"}}]}}]}},...ChatUserFragment.definitions]} as unknown as DocumentNode;

/**
 * __useUsersForStartNewChat__
 *
 * To run a query within a React component, call `useUsersForStartNewChat` and pass it any options that fit your needs.
 * When your component renders, `useUsersForStartNewChat` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersForStartNewChat({
 *   variables: {
 *   },
 * });
 */
export function useUsersForStartNewChat(baseOptions?: Apollo.QueryHookOptions<UsersForStartNewChat, UsersForStartNewChatVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersForStartNewChat, UsersForStartNewChatVariables>(UsersForStartNewChatDocument, options);
      }
export function useUsersForStartNewChatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersForStartNewChat, UsersForStartNewChatVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersForStartNewChat, UsersForStartNewChatVariables>(UsersForStartNewChatDocument, options);
        }
export type UsersForStartNewChatHookResult = ReturnType<typeof useUsersForStartNewChat>;
export type UsersForStartNewChatLazyQueryHookResult = ReturnType<typeof useUsersForStartNewChatLazyQuery>;
export type UsersForStartNewChatQueryResult = Apollo.QueryResult<UsersForStartNewChat, UsersForStartNewChatVariables>;