import React from 'react';
import { getSize } from 'lib/utils';

function SearchIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={getSize(24)}
      height={getSize(24)}
      fill="none"
      stroke="#7A60E9"
      viewBox="0 0 24 24"
      {...rest}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35"
      />
    </svg>
  );
}

export default SearchIcon;
