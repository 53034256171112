import { useAdminGetCompanyLazyQuery } from 'common/query/__generated__/admin-get-company';
import { FormApi } from 'final-form';
import { getSize } from 'lib/utils';
import { useAdminArchiveCompany } from 'pages/business-page/mutation/__generated__/admin-archive-company';
import { useAdminRefreshCompanyKey } from 'pages/business-page/mutation/__generated__/admin-refresh-company-key';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { CloseIcon, Loader } from 'ui';
import { ConfirmModal } from './components/confirm-modal';
import {
  CompanyFormValues,
  EditCompanyForm,
} from './components/edit-company-form';

import { ConfirmType, SwitchVariants } from '../../business-page';
import { useAdminUnarchiveCompany } from 'pages/business-page/mutation/__generated__/admin-unarchive-company';

interface FeedbackModalProps {
  onCloseModal: () => void;
  openReportModal: () => void;
  onRefetchCompanies: () => void;
  companyId: string;
  switchSelect: string;
}

export const EditCompanyModal: FC<FeedbackModalProps> = ({
  onCloseModal,
  companyId,
  openReportModal,
  onRefetchCompanies,
  switchSelect,
}) => {
  const [
    getCompany,
    { loading, error, data: companyData, fetchMore: descriptionFetchMore },
  ] = useAdminGetCompanyLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const openConfirmModal = () => {
    setIsConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const formRef = useRef<FormApi<
    CompanyFormValues,
    Partial<CompanyFormValues>
  > | null>(null);

  const [
    refreshCompanyKey,
    { data: companyIdData, loading: addCompanyLoading },
  ] = useAdminRefreshCompanyKey();

  useEffect(() => {
    formRef.current?.mutators.setValue(
      'AccessCode',
      companyIdData?.refreshCompanyKey,
    );
  }, [companyIdData]);

  const [archiveCompany, { loading: archiveCompanyLoading }] =
    useAdminArchiveCompany();

  const [unarchiveCompany, { loading: unarchiveCompanyLoading }] =
    useAdminUnarchiveCompany();

  useEffect(() => {
    getCompany({
      variables: {
        companyId: companyId,
      },
    });
  }, [companyId]);

  const [confirmType, setConfirmType] = useState<ConfirmType>(
    ConfirmType.Archive,
  );

  const onYesButtonClick = async () => {
    if (confirmType === ConfirmType.Refresh) {
      await refreshCompanyKey({
        variables: {
          companyId: companyId,
        },
      });
      closeConfirmModal();
    } else {
      if (switchSelect === SwitchVariants.Active) {
        archiveCompany({
          variables: {
            companyId: companyId,
          },
        })
          .then(onRefetchCompanies)
          .then(closeConfirmModal)
          .then(onCloseModal);
      } else {
        unarchiveCompany({
          variables: {
            companyId: companyId,
          },
        })
          .then(onRefetchCompanies)
          .then(closeConfirmModal)
          .then(onCloseModal);
      }
    }
  };

  const questionText = () => {
    if (confirmType === ConfirmType.Refresh) {
      return 'Refresh Code';
    } else if (confirmType === ConfirmType.Archive) {
      return 'Archive Company';
    }
    return 'Save Change';
  };
  return (
    <ShadowWrapper>
      <Content>
        <Header>
          <HeaderText>Company Details</HeaderText>
          <CloseIconStyled onClick={onCloseModal} />
        </Header>
        {loading ? (
          <StyledLoader />
        ) : (
          <EditCompanyForm
            onCloseModal={onCloseModal}
            openReportModal={openReportModal}
            openConfirmModal={openConfirmModal}
            companyData={companyData}
            setConfirmType={setConfirmType}
            switchSelect={switchSelect}
            formRef={formRef}
            onRefetchCompanies={onRefetchCompanies}
          />
        )}
      </Content>
      {isConfirmModalOpen && (
        <ConfirmModal
          onCloseModal={closeConfirmModal}
          onYesButtonClick={onYesButtonClick}
          questionText={questionText()}
        />
      )}
    </ShadowWrapper>
  );
};

const ShadowWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.51);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const Content = styled.div`
  position: relative;
  width: ${getSize(582)};
  height: ${getSize(647)};
  background: #ffffff;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${getSize(22)} ${getSize(23)} ${getSize(19)} ${getSize(27)};
  border-bottom: 1px solid #f1f1f1;
`;
const HeaderText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: ${getSize(18)};

  color: #000000;
`;

const CloseIconStyled = styled(CloseIcon)`
  cursor: pointer;
`;

const StyledLoader = styled(Loader)`
  height: 100%;
`;
