import {
  TherapyApproachesFragment,
  TreatmentAreaFragment,
} from 'common/query/__generated__/get-therapist-by-id';

export const sortApproachesOrTreatmentAreas = (
  arr?: (TherapyApproachesFragment | TreatmentAreaFragment)[],
) => {
  return (
    arr &&
    [...arr].sort((a, b) => {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      return 0;
    })
  );
};
