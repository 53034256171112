import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetClinicCredentialsVariables = Types.Exact<{ [key: string]: never; }>;


export type GetClinicCredentials = (
  { __typename: 'Query' }
  & { adminClinicCredentials?: Types.Maybe<(
    { __typename: 'ClinicCredentialsModel' }
    & AdminClinicCredentialsFragment
  )> }
);

export type AdminClinicCredentialsFragment = (
  { __typename: 'ClinicCredentialsModel' }
  & Pick<Types.ClinicCredentialsModel, 'id' | 'siteId' | 'username' | 'password' | 'providerId' | 'insuranceLogin'>
);

export const AdminClinicCredentialsFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AdminClinicCredentialsFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ClinicCredentialsModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"siteId"}},{"kind":"Field","name":{"kind":"Name","value":"username"}},{"kind":"Field","name":{"kind":"Name","value":"password"}},{"kind":"Field","name":{"kind":"Name","value":"providerId"}},{"kind":"Field","name":{"kind":"Name","value":"insuranceLogin"}}]}}]} as unknown as DocumentNode;
export const GetClinicCredentialsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetClinicCredentials"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminClinicCredentials"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AdminClinicCredentialsFragment"}}]}}]}},...AdminClinicCredentialsFragment.definitions]} as unknown as DocumentNode;

/**
 * __useGetClinicCredentials__
 *
 * To run a query within a React component, call `useGetClinicCredentials` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicCredentials` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicCredentials({
 *   variables: {
 *   },
 * });
 */
export function useGetClinicCredentials(baseOptions?: Apollo.QueryHookOptions<GetClinicCredentials, GetClinicCredentialsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicCredentials, GetClinicCredentialsVariables>(GetClinicCredentialsDocument, options);
      }
export function useGetClinicCredentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicCredentials, GetClinicCredentialsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicCredentials, GetClinicCredentialsVariables>(GetClinicCredentialsDocument, options);
        }
export type GetClinicCredentialsHookResult = ReturnType<typeof useGetClinicCredentials>;
export type GetClinicCredentialsLazyQueryHookResult = ReturnType<typeof useGetClinicCredentialsLazyQuery>;
export type GetClinicCredentialsQueryResult = Apollo.QueryResult<GetClinicCredentials, GetClinicCredentialsVariables>;