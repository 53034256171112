import React, { ReactNode } from 'react';
import styled, { CSSProp } from 'styled-components';
import { Button } from 'ui/button';
import { logout } from 'lib/auth';
import { DASHBOARD_LINKS, DASHBOARD_MARKETING_LINKS, getSize } from 'lib/utils';
import { DashboardSidebarLayout } from '../dashboard-sidebar';

/** Hack for marketing admins */
import { marketingOnlyEmails } from 'types';
import { StorageService } from 'lib/utils';

export interface TherapistLayoutProps {
  children: ReactNode;
  contentCSS?: CSSProp;
}

function DashboardLayout({ children, contentCSS }: TherapistLayoutProps) {
  /** Check if logged user is on list marketingOnlyEmails and serve only resources to it */
  const looggedUserEmail = StorageService.getAdminEmail() || '';

  const sidebarLinks = marketingOnlyEmails.includes(looggedUserEmail)
    ? DASHBOARD_MARKETING_LINKS
    : DASHBOARD_LINKS;

  return (
    <Wrapper>
      <ContentWrapper>
        <DashboardSidebarLayout links={sidebarLinks} />
        <Content>
          <ContentInner $CSS={contentCSS}>{children}</ContentInner>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: white;
  overflow: hidden;
`;

const ContentWrapper = styled.main`
  display: flex;
  justify-content: center;
  overflow: hidden;
  height: 100%;
  max-width: ${getSize(1440)};
  margin: 0 auto;
`;

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  overflow: auto;
`;

const LinksWrapper = styled.div<{ $hasSignIn?: boolean }>`
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${getSize(12)};
  align-items: center;
  margin: ${getSize(26)} ${getSize(56)} 0 auto;
`;

const ContentInner = styled.div<{ $CSS?: CSSProp }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: ${getSize(1126)};

  ${({ $CSS }) => $CSS}
`;

export default DashboardLayout;
