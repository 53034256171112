import React from 'react';
import styled from 'styled-components';
import { getSize, toCapitalizeString } from 'lib/utils';
import { Button } from 'ui/button';
import { ShadowWrapper } from 'ui/shadow-wrapper';
import { InPersonIcon, LiveChatIcon, MailIcon, VideoChatIcon } from 'ui/icons';
import { TherapistInfoFragment } from 'common/query/__generated__/get-therapist-by-id';
import { SessionType, TherapistRequestStatus } from '__generated__/types';
import { useAcceptTherapistRequest } from 'common/mutation/__generated__/therapist-accept-request';
import { notifyError } from 'lib/utils/notification';
import { useHistory } from 'react-router-dom';
import { DEFAULT_AVATAR, SESSION_TYPE_TITLES } from 'lib/constants/therapist';
import { useManageTherapistInSearchList } from 'hooks/use-manage-therapist-in-search-list';
import { ControlDropdown } from 'ui';

interface TherapistInfoHeaderProps {
  therapist: TherapistInfoFragment;
  isRequest: boolean;
  rejectAction: (id: string) => void;
  modalAction: (id: string, isBan?: boolean) => void;
  onUnbanTherapist: (id: string) => void;
}

const SESSION_TYPE_ICONS = {
  [SessionType.InPerson]: <InPersonIcon />,
  [SessionType.VideoCall]: <VideoChatIcon />,
  [SessionType.LiveChat]: <LiveChatIcon />,
};

const EMPTY_TITLE = 'No title';

function TherapistInfoHeader({
  therapist,
  isRequest,
  rejectAction,
  modalAction,
  onUnbanTherapist,
}: TherapistInfoHeaderProps) {
  const [acceptTherapistRequest, { loading: acceptLoading }] =
    useAcceptTherapistRequest({
      onError: ({ name, message }) =>
        notifyError({ title: name, text: message }),
      update(cache) {
        cache.evict({
          fieldName: 'adminTherapistsSearch',
          broadcast: true,
        });
        cache.gc();
      },
    });

  const {
    removeFormSearchLoading,
    removeTherapistFromSearch,
    addTherapistToSearch,
    addToSearchLoading,
  } = useManageTherapistInSearchList();

  const history = useHistory();

  const {
    id: therapistId,
    deletedFromSearch,
    user: { avatar, firstName, lastName },
    title,
    gender,
    sessionTypes,
    qualification: { academicInitials, professionalTitle },
    deletedAt,
  } = therapist;

  const isBaned = Boolean(deletedAt);

  const confirmRequest = () => {
    acceptTherapistRequest({
      variables: { input: { therapistId } },
    });
    history.goBack();
  };

  return (
    <Wrapper>
      <Avatar alt="Therapist avatar" src={avatar?.source || DEFAULT_AVATAR} />
      <PersonalInfo>
        <Title>{`${
          title?.label !== EMPTY_TITLE ? title?.label : ''
        } ${firstName} ${lastName}`}</Title>
        <Text $marginBottom={20}>
          {`${toCapitalizeString(String(gender))} • ${
            professionalTitle.label
          } • ${academicInitials.map((academic) => academic.label).join(', ')}`}
        </Text>
        <SessionTypes>
          {sessionTypes?.map((type) =>
            type ? (
              <Session key={type}>
                {SESSION_TYPE_ICONS[type]}
                <Text>{SESSION_TYPE_TITLES[type]}</Text>
              </Session>
            ) : null,
          )}
        </SessionTypes>
      </PersonalInfo>
      <ActionButtons $isRequest={isRequest}>
        {isRequest ? (
          <>
            {therapist.requestStatus !== TherapistRequestStatus.Accepted && (
              <ActionButton
                isDisabled={acceptLoading}
                onClick={confirmRequest}
                theme="primary">
                Confirm request
              </ActionButton>
            )}
            {therapist.requestStatus !== TherapistRequestStatus.Rejected && (
              <ActionButton
                onClick={() => rejectAction(therapistId)}
                theme="secondary">
                Reject request
              </ActionButton>
            )}
          </>
        ) : (
          <ControlDropdown>
            <Button
              onClick={
                deletedFromSearch
                  ? () =>
                      addTherapistToSearch({
                        variables: { input: { therapistId } },
                      })
                  : () =>
                      removeTherapistFromSearch({
                        variables: { input: { therapistId } },
                      })
              }>
              <SearchItem>
                {deletedFromSearch ? 'Add to search' : 'Remove from search'}
              </SearchItem>
            </Button>
            <Button
              onClick={() => {
                isBaned
                  ? onUnbanTherapist(therapist.id)
                  : modalAction(therapist.id, true);
              }}>
              <ControlItem>
                {!isBaned ? 'Pause access' : 'Resume access'}
              </ControlItem>
            </Button>
            <Button onClick={() => modalAction(therapist.id)}>
              <ControlItem>Delete user data</ControlItem>
            </Button>
          </ControlDropdown>
        )}
      </ActionButtons>
    </Wrapper>
  );
}

const Wrapper = styled(ShadowWrapper)`
  display: flex;
  padding: ${getSize(36)};
`;

const Avatar = styled.img`
  width: ${getSize(140)};
  height: ${getSize(140)};
  object-fit: cover;
  border-radius: 50%;
`;

const PersonalInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 ${getSize(24)};
  min-width: ${getSize(316)};
`;

const Text = styled.p<{ $marginBottom?: number }>`
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--gray7);
  margin-bottom: ${({ $marginBottom }) => getSize($marginBottom || 0)};
`;

const Title = styled.h6`
  font-weight: 600;
  font-size: ${getSize(34)};
  line-height: ${getSize(51)};
  color: var(--black3);
`;

const SessionTypes = styled.div`
  display: flex;
  & > div:not(:first-child) {
    margin-left: ${getSize(23)};
  }
`;

const Session = styled.div`
  display: flex;
  align-items: center;
`;

const ActionButtons = styled.div<{ $isRequest: boolean }>`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: ${({ $isRequest }) => ($isRequest ? getSize(86) : 'auto')};
`;

const ActionButton = styled(Button)`
  width: ${getSize(282)};
  height: ${getSize(44)};
  margin-bottom: ${getSize(20)};
  font-weight: 600;
`;

const SearchItem = styled.span`
  text-align: left;
`;
const ControlItem = styled.span`
  color: var(--red);
`;

export default TherapistInfoHeader;
