import { useState, useCallback, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from 'ui/loader';
import styled from 'styled-components';
import { getSize } from 'lib/utils';
import { useDebounceCallback } from '@react-hook/debounce';
import { Paper } from 'ui';

import { RowTemplate } from '../components/row-template';
import { TherapistRowItem } from '../components/therapist-row-item';
import { EditTherapistCommisionModal } from '../components';

import {
  OrderByDirection,
  TherapistAdminSort,
  TherapistRequestStatus,
} from '__generated__/types';

import {
  TherapistFragment,
  useAdminTherapistSearch,
} from 'common/query/__generated__/get-therapists-by-request';

const MAX_VISIBLE_THERAPISTS = 10;

const Therapists = () => {
  /** State */
  const [filter, setFilter] = useState('');
  const [therapistToEdit, setTherapistToEdit] = useState<any>(null);
  const [sortDirection, setSortDirection] = useState(OrderByDirection.Desc);
  const [sortOption, setSortOption] = useState(TherapistAdminSort.UpdatedAt);

  /** Get therapists from API */
  const therapistsData = useAdminTherapistSearch({
    variables: {
      first: MAX_VISIBLE_THERAPISTS,
      filter: { status: TherapistRequestStatus.Accepted, searchName: filter },
      sort: {
        sortOption: sortOption,
        direction: sortDirection,
      },
    },
  });
  const therapists: TherapistFragment[] = useMemo(
    () => therapistsData.data?.adminTherapistsSearch.nodes || [],
    [therapistsData.data?.adminTherapistsSearch.nodes],
  );
  const hasMore = Boolean(
    therapistsData.data?.adminTherapistsSearch.pageInfo.hasNextPage,
  );

  /**
   * @method
   */
  const fetchMore = () =>
    therapistsData.fetchMore({
      variables: {
        filter: {
          status: TherapistRequestStatus.Accepted,
          searchName: filter,
        },
        after: therapistsData.data?.adminTherapistsSearch.pageInfo.endCursor,
      },
    });

  const handleSearchSubmit = useCallback((searchText?: string) => {
    const searchPhrase = searchText?.trim() || '';
    setFilter(searchPhrase);
  }, []);

  const debounceHandleSearchFormSubmit = useDebounceCallback(
    handleSearchSubmit,
    250,
  );

  return (
    <>
      {therapistToEdit && (
        <EditTherapistCommisionModal
          therapist={therapistToEdit}
          onClose={() => setTherapistToEdit(null)}
        />
      )}
      <Paper
        searchRequest={debounceHandleSearchFormSubmit}
        title="Therapists"
        rootCSS={{ padding: 0 }}>
        <Wrapper>
          <HeaderWrapper>
            <TableHeader>
              <TableHeaderText>Therapist</TableHeaderText>
              <TableHeaderText>
                Initial session commission percent
              </TableHeaderText>
              <TableHeaderText>
                Ongoing session commission percent
              </TableHeaderText>
              <TableHeaderText>Option</TableHeaderText>
            </TableHeader>
          </HeaderWrapper>
          <ScrollWrapper id="scrollableChatsList">
            <TherapistList
              next={fetchMore}
              hasMore={hasMore}
              dataLength={therapists.length}
              scrollableTarget="scrollableChatsList"
              loader={<Loader />}>
              {therapists?.map((therapist) => (
                <TherapistRowItem
                  key={therapist.id}
                  therapist={therapist}
                  onClick={() => setTherapistToEdit(therapist)}
                />
              ))}
            </TherapistList>
          </ScrollWrapper>
        </Wrapper>
      </Paper>
    </>
  );
};

const Wrapper = styled.div`
  padding: ${getSize(20)} 0;
`;
const HeaderWrapper = styled.div`
  padding: 0 ${getSize(40)};
`;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore-next-line
const TherapistList = styled(InfiniteScroll)`
  padding: ${getSize(20)}${getSize(40)};
`;

const ScrollWrapper = styled.div`
  overflow: auto;
  margin-top: ${getSize(12)};
  height: ${getSize(500)};

  &::-webkit-scrollbar {
    display: none;
  }
`;

const TableHeader = styled(RowTemplate)`
  height: ${getSize(52)};
  background-color: var(--purple11);
  border-radius: ${getSize(8)};
`;

const TableHeaderText = styled.button<{ active?: boolean }>`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  text-align: center;
  padding: 0 ${getSize(18)};
  color: var(--gray7);
  display: flex;
  align-items: center;
  color: ${({ active }) => (active ? 'var(--black)' : 'var(--gray7)')};

  &:first-child {
    padding-left: ${getSize(24)};
    text-align: left;
  }
`;

export default Therapists;
