import { SESSION_TYPE_TITLES } from 'lib/constants/therapist';
import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import styled from 'styled-components';
import { SessionType } from '__generated__/types';
import RowTemplate from '../row-template';
import { TimeUtil } from '../../../../lib/utils/time-util';

const VoucherCard: FC<VoucherCardProps> = ({
  voucherId,
  clientName,
  therapistName,
  paid,
  type,
  duration,
  redeemed,
}) => {
  return (
    <Wrapper>
      <InfoBlock>
        <Text>{voucherId}</Text>
      </InfoBlock>

      <InfoBlock>
        <Text>{clientName}</Text>
      </InfoBlock>

      <InfoBlock>
        <Text>{therapistName}</Text>
      </InfoBlock>

      <InfoBlock>
        <Text>{paid ? 'Yes' : 'No'}</Text>
      </InfoBlock>

      <InfoBlock>
        <Text>{SESSION_TYPE_TITLES[type]}</Text>
      </InfoBlock>

      <InfoBlock>
        <Text>{`${duration}min`}</Text>
      </InfoBlock>

      <InfoBlock>
        <Text>{TimeUtil.getFormatted(redeemed, 'DD.MM.YYYY - HH:mm')}</Text>
      </InfoBlock>
    </Wrapper>
  );
};

interface VoucherCardProps {
  voucherId: string;
  clientName: string;
  therapistName: string;
  paid: boolean;
  type: SessionType;
  duration: string;
  redeemed: string;
}

const Wrapper = styled(RowTemplate)`
  height: ${getSize(64)};
  box-shadow: 0 0 ${getSize(30)} var(--blue-opacity2);
  border-radius: ${getSize(8)};

  margin-top: ${getSize(18)};
`;

const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: 0 ${getSize(18)};
`;

const Text = styled.p`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--black3);
`;

export default VoucherCard;
