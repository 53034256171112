class StorageService {
  private accessTokenName = '@mental-health-admin-access-token';
  private isRememberUserName = '@mental-health-admin-is-remember-user';
  private adminEmail = '@mental-health-admin-email';

  setAccessToken = (token: string, hasLocalStorage?: boolean) => {
    if (hasLocalStorage) {
      localStorage.setItem(this.accessTokenName, token);
    } else {
      sessionStorage.setItem(this.accessTokenName, token);
    }

    return token;
  };

  getAccessToken = () => {
    return (
      sessionStorage.getItem(this.accessTokenName) ||
      localStorage.getItem(this.accessTokenName)
    );
  };

  removeAccessToken = () => {
    sessionStorage.removeItem(this.accessTokenName);
    localStorage.removeItem(this.accessTokenName);
  };

  setIsRememberUser = () => {
    sessionStorage.setItem(this.isRememberUserName, 'true');
  };

  getIsRememberUser = () => {
    return sessionStorage.getItem(this.isRememberUserName);
  };

  removeIsRememberUser = () => {
    sessionStorage.removeItem(this.isRememberUserName);
  };

  getAdminEmail = () => {
    return localStorage.getItem(this.adminEmail);
  };

  setAdminEmail = (email: string) => {
    localStorage.setItem(this.adminEmail, email);
  };
}

export default new StorageService();
