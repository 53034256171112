import { Config } from 'final-form';
import { getSize, validationRules } from 'lib/utils';
import React, { FC } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import styled from 'styled-components';
import { Button, PriceInput } from 'ui';

const PricesForm: FC<PriceFormProps> = ({
  initialValues,
  handleFormSubmit,
  submitLoading,
}) => {
  return (
    <PriceWrapper>
      <FinalForm<CommissionFormValues>
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        render={({ handleSubmit, hasValidationErrors, dirty }) => (
          <form>
            <InputWrapper>
              <Field
                validate={validationRules.priceValue}
                name="minPrice"
                label="30 min session"
                component={PriceInput}
              />
              <Field
                validate={validationRules.priceValue}
                name="mediumPrice"
                label="50 min session"
                component={PriceInput}
              />
              <Field
                validate={validationRules.priceValue}
                name="maxPrice"
                label="80 min session"
                component={PriceInput}
              />
            </InputWrapper>

            <SaveButton
              isDisabled={!dirty || hasValidationErrors}
              onClick={handleSubmit}
              isLoading={submitLoading}
              theme="primary">
              Save
            </SaveButton>
          </form>
        )}
      />
    </PriceWrapper>
  );
};

interface PriceFormProps {
  initialValues: CommissionFormValues;
  handleFormSubmit: Config<CommissionFormValues>['onSubmit'];
  submitLoading: boolean;
}

export interface CommissionFormValues {
  minPrice: string;
  mediumPrice: string;
  maxPrice: string;
}

const PriceWrapper = styled.div`
  display: flex;
  padding: ${getSize(20)} 0 ${getSize(60)};
`;

const InputWrapper = styled.div`
  display: flex;
  margin-bottom: ${getSize(40)};
  & label:not(:first-child) {
    margin-left: ${getSize(20)};
  }
`;

const SaveButton = styled(Button)`
  width: ${getSize(313)};
  padding: ${getSize(10)};
  font-weight: 400;
`;

export default PricesForm;
