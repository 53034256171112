import { signIn } from 'lib/auth';
import {
  composeValidators,
  getSize,
  StorageService,
  validationRules,
} from 'lib/utils';
import { notifyError } from 'lib/utils/notification';
import React, { useCallback, useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import styled, { CSSProp } from 'styled-components';

import { Button } from 'ui/button';
import { Input } from 'ui/form-elements/input';
import { PasswordInput } from 'ui/form-elements/password-input';
import { Checkbox } from 'ui/form-elements/checkbox';
import { AuthWrapper } from 'ui/auth-wrapper';

export const TITLE_CSS = { margin: `0 0 ${getSize(32)}` };
export const ROOT_CSS: CSSProp = { position: 'relative' };
export const EMAIL_ROOT_CSS = { margin: `0 0 ${getSize(16)}` };
export const PASSWORD_ROOT_CSS = { margin: `0 0 ${getSize(16)}` };
const ACCESS_DENIED_ERROR = 'access_denied';

export interface SignInFormValues {
  email: string;
  password: string;
  isRemember: boolean;
}

function SignInForm() {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [hasAccessDeniedError, setHasAccessDeniedError] = useState(false);

  const handleFormSubmit = useCallback(
    ({ email, password, isRemember }: SignInFormValues) => {
      if (isRemember) {
        StorageService.setIsRememberUser();
      }

      /** TODO: this is not right way to do it but we need to store email */
      StorageService.setAdminEmail(email);

      setIsDataLoading(true);

      return signIn({ email, password })
        .then((value) => {
          console.log('Uspjesno prosao login ovo je email');
          console.log(email);
        })
        .catch((error) => {
          console.log('Error', error);

          const isAccessDeniedError = error?.error === ACCESS_DENIED_ERROR;
          setHasAccessDeniedError(isAccessDeniedError);
          setIsDataLoading(false);
          if (isAccessDeniedError) {
            return {
              email: 'Check email',
              password: 'Check password',
            };
          } else {
            notifyError({ text: error?.description });
          }
        });
    },
    [],
  );

  return (
    <AuthWrapper title="Sign In" titleCSS={TITLE_CSS} rootCSS={ROOT_CSS}>
      <FinalForm
        onSubmit={handleFormSubmit}
        render={({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Field
              name="email"
              label="Email"
              rootCSS={EMAIL_ROOT_CSS}
              component={Input}
              hasErrorText={!hasAccessDeniedError}
              validate={composeValidators(
                validationRules.required,
                validationRules.email,
              )}
              placeholder="Enter email"
            />
            <Field
              name="password"
              label="Password"
              rootCSS={PASSWORD_ROOT_CSS}
              component={PasswordInput}
              hasErrorText={!hasAccessDeniedError}
              validate={validationRules.required}
              placeholder="Enter password"
            />
            <RememberWrapper>
              <Field
                name="isRemember"
                type="checkbox"
                label="Remember me"
                component={Checkbox}
              />
            </RememberWrapper>
            <Button isLoading={isDataLoading} theme="primary">
              Sign In
            </Button>
          </Form>
        )}
      />
    </AuthWrapper>
  );
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 0 ${getSize(20)};
`;

const RememberWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 ${getSize(32)};
`;

export default SignInForm;
