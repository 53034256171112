import { getSize } from 'lib/utils';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

export interface ErrorMessageProps {
  children: ReactNode;
  className?: string;
}

function ErrorMessage({ children, className }: ErrorMessageProps) {
  return <Wrapper className={className}>{children}</Wrapper>;
}

const Wrapper = styled.span`
  margin: ${getSize(2)} 0 0;
  font-weight: 400;
  font-size: ${getSize(10)};
  line-height: ${getSize(16)};
  color: var(--red);
`;

export default ErrorMessage;
