import { composeValidators, getSize, validationRules } from 'lib/utils';
import React, { FC, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { AdminEditTherapistProfileInput, FileType } from '__generated__/types';

import { Field, Form as FinalForm } from 'react-final-form';
import { Button, Textarea } from 'ui';
import { TherapistFragment } from 'common/query/__generated__/get-therapists-by-request';
import { DropzoneInput } from 'ui/form-elements/dropzone-input';
import { useAdminEditTherapistProfile } from 'common/query/__generated__/admin-edit-therapist-profile';
import { notifyError } from 'lib/utils/notification';
import { AdminTherapistSearchDocument } from 'common/query/__generated__/get-therapists-by-request';

export const EditTherapistForm: FC<EditTherapistFormProps> = ({
  onCloseModal,
  therapist,
}) => {
  const [editTherapist, { loading: editLoading }] =
    useAdminEditTherapistProfile({
      onCompleted: () => {
        onCloseModal();
      },
      refetchQueries: [
        {
          query: AdminTherapistSearchDocument,
        },
      ],
      onError: ({ name, message }) =>
        notifyError({ title: name, text: message }),
    });

  const handleFormSubmit = ({ ...values }: TherapistFormValues) => {
    const input: AdminEditTherapistProfileInput = {
      ...values,
      avatar: {
        extension: values.avatar.extension,
        fileType: values.avatar.fileType,
        name: values.avatar.name,
        source: values.avatar.source,
        sizeInKb: values.avatar.sizeInKb,
      },
      addLanguages: [],
      removeLanguages: [],
      sessionTypes: therapist.sessionTypes,
      officeLocations: [],
      addOfficeLocations: [],
      removeOfficeLocations: [],
      hardWorker: therapist.hardWorker,
      therapistId: therapist.id,
    };

    editTherapist({
      variables: {
        input,
      },
    });
  };

  const initialValues = useMemo(() => {
    const video = therapist.user.video;

    return {
      avatar: therapist.user.avatar,
      bio: therapist.bio,
      video: video
        ? {
            fileType: video.fileType,
            source: video.source,
            name: video.name,
            sizeInKb: video.sizeInKb,
            extension: video.extension,
          }
        : undefined,
    };
  }, [therapist]);

  return (
    <FinalForm
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      render={({ handleSubmit }) => {
        return (
          <Wrapper>
            <DropzoneInputWrapper>
              <Field
                name="avatar"
                label="Upload your photo"
                therapistId={therapist.id}
                component={DropzoneInput}
              />
              <Field
                name="video"
                label="Upload your video  "
                fileType={FileType.Video}
                therapistId={therapist.id}
                component={DropzoneInput}
              />
            </DropzoneInputWrapper>

            <Field
              name="bio"
              label="About you (min 800 - max 1 300 characters)"
              placeholder="Start typing"
              rootCSS={ROOT_CSS}
              maxLength={1300}
              component={Textarea}
              validate={composeValidators(
                validationRules.required,
                (value: string) => validationRules.minLength(value, 800),
              )}
            />

            <CreateCompanyButton
              onClick={handleSubmit}
              isLoading={editLoading}
              type="submit">
              Save
            </CreateCompanyButton>
          </Wrapper>
        );
      }}
    />
  );
};

export type TherapistFormValues = AdminEditTherapistProfileInput;

interface EditTherapistFormProps {
  onCloseModal: () => void;
  therapist: TherapistFragment;
}

const Wrapper = styled.div`
  padding: ${getSize(11)} ${getSize(27)} 80px ${getSize(27)};
  overflow: scroll;
`;

interface CreateCompanyButton {
  isWhite?: boolean;
}

const CreateCompanyButton = styled(Button)<CreateCompanyButton>`
  height: 42px;
  background: ${({ isWhite }) => (isWhite ? 'white' : '#6b4ee6')};
  border: 1px solid #6b4ee6;
  box-sizing: border-box;
  border-radius: 34px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${({ isWhite }) => (isWhite ? '#6b4ee6' : 'white')};
  font-weight: 600;
  font-size: ${getSize(14)};
`;

const DropzoneInputWrapper = styled.div`
  margin-bottom: ${getSize(24)};
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: stretch;
  grid-gap: 0 ${getSize(16)};
`;

const ROOT_CSS = css`
  margin-bottom: ${getSize(10)};
`;

export default EditTherapistForm;
