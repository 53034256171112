import { API_DATE_FORMAT } from 'lib/utils';
import { getSize, TimeUtil } from 'lib/utils';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { DatesRange } from 'ui/drop-down-calendar/utils/add-day-to-range';

interface DayInsideRangeProps {
  day: Date;
  datesRange?: DatesRange;
}

const DayInsideRange: FC<DayInsideRangeProps> = ({ day, datesRange }) => {
  const dayNumber = day.getDate();

  const isActive = useMemo(() => {
    if (datesRange) {
      const { from, to } = datesRange;
      if (from && to) {
        const fromDateApi = TimeUtil.getFormatted(from, API_DATE_FORMAT);
        const toDateApi = TimeUtil.getFormatted(to, API_DATE_FORMAT);
        const dayApi = TimeUtil.getFormatted(day, API_DATE_FORMAT);

        return dayApi > fromDateApi && dayApi < toDateApi;
      }
    }
  }, [datesRange, day]);
  return (
    <DateDisplayWrapper>
      <DayDisplayItem $isActive={isActive}>{dayNumber}</DayDisplayItem>
    </DateDisplayWrapper>
  );
};

const DateDisplayWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: ${getSize(5)} ${getSize(2)};
`;

const DayDisplayItem = styled.p<{ $isActive?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${getSize(8)};
  ${({ $isActive }) => $isActive && 'background-color: var(--purple5);'}
`;

export default DayInsideRange;
