import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ShadowWrapper } from 'ui/shadow-wrapper';
import styled from 'styled-components';
import { getSize } from 'lib/utils';
import { Loader } from 'ui';
import { BillFragments } from 'pages/logs-page/types';

export interface RenderBillItemProps<T> {
  item: T;
  onOpen?: (item: T) => void;
}

interface BillHistoryListProps<T> {
  loading: boolean;
  hasNextPage: boolean;
  loadMore: () => Promise<any>;
  id: string;
  header?: React.ReactElement;
  items: T[];
  onOpen?: (item: T) => void;
  renderItem: React.FC<RenderBillItemProps<T>>;
}

const BillHistoryList = <T extends BillFragments>({
  loading,
  items,
  loadMore,
  hasNextPage,
  id,
  header,
  onOpen,
  renderItem: Item,
}: BillHistoryListProps<T>) => {
  return (
    <Wrapper key={id}>
      {header}
      {loading ? (
        <LoaderContainer>
          <LoaderStylized size={50} hasFillWholeBlock />
        </LoaderContainer>
      ) : (
        <Scrollable id={id}>
          <InfiniteScroll
            scrollableTarget={id}
            dataLength={items.length} //This is important field to render the next data
            next={loadMore}
            hasMore={hasNextPage}
            loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>You have seen it all</b>
              </p>
            }>
            {items.map((i) => {
              return <Item key={i.id} item={i} onOpen={onOpen} />;
            })}
          </InfiniteScroll>
        </Scrollable>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(ShadowWrapper)`
  margin: 0 ${getSize(20)};
  padding: ${getSize(10)};
`;

const Scrollable = styled.div`
  overflow: auto;
  height: 70vh;
`;

const LoaderContainer = styled.div`
  position: relative;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoaderStylized = styled(Loader)`
  margin: auto;
`;
export default BillHistoryList;
