import { ALL_FILE_TYPES, UploadFile } from 'interfaces/files';
import { FileType } from '__generated__/types';
import { config } from 'lib/utils';

export async function getBase64FromFile(file: File, callback?: () => void) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);

    reader.readAsDataURL(file);
    callback?.();
  });
}

const allTypes = Object.keys(ALL_FILE_TYPES) as FileType[];

export function getFileInfo(file: File, fileTypes = allTypes) {
  const fileNameArray = file ? file.name.split('.') : [];
  const fileExtension = fileNameArray.pop() || '';
  const fileType = fileTypes.find((type) =>
    ALL_FILE_TYPES[type as FileType].some((extension) => {
      return extension.toLowerCase() === fileExtension.toLowerCase();
    }),
  );

  return { fileName: fileNameArray.join('.') || '', fileExtension, fileType };
}

export function getFileUrlWithoutAuthMeta(fileSource: string) {
  return fileSource.split('?')[0];
}

export function getFileSourceWithCorrectUrl(source: string) {
  return `${config.QUALIFICATION_URL}/${source
    .split('//')[1]
    .split('/')
    .splice(1)
    .join('/')}`;
}

export function getNewFileWithCorrectUrl(file: UploadFile) {
  return {
    ...file,
    source: getFileSourceWithCorrectUrl(file.source),
  };
}
