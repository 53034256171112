import React from 'react';
import styled from 'styled-components';
import { FieldRenderProps } from 'react-final-form';

import { getSize } from 'lib/utils';

import { SearchIcon } from 'ui/icons';

export interface SearchProps extends FieldRenderProps<string> {
  className?: string;
  placeholder?: string;
}

function Search({ input, className, placeholder }: SearchProps) {
  return (
    <Wrapper className={className}>
      <InputIcon />
      <Input {...input} placeholder={placeholder} />
    </Wrapper>
  );
}

const InputIcon = styled(SearchIcon)`
  margin: 0 ${getSize(6)} 0 0;
`;

const Wrapper = styled.label`
  display: flex;
  align-items: center;
  padding: ${getSize(8)} ${getSize(16)} ${getSize(8)} 0;
  border-bottom: ${getSize(1)} solid var(--purple3);
  cursor: pointer;
`;

const Input = styled.input`
  flex-grow: 1;
  padding: 0;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--black3);
  caret-color: var(--purple);

  &::placeholder {
    color: var(--gray7);
  }

  &:-internal-autofill-selected,
  &:-webkit-autofill {
    background-color: var(--red) !important;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill {
    border: 0;
    -webkit-text-fill-color: #ссс;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 174, 255, 0.04) 50%,
      rgba(255, 255, 255, 0) 51%,
      rgba(0, 174, 255, 0.03) 100%
    );
  }
`;

export default Search;
