import React, { SVGProps } from 'react';
import { getSize } from 'lib/utils';
import styled from 'styled-components';

interface CustomButtonProps {
  name: string;
  className?: string;
  Icon: (props: SVGProps<SVGElement>) => JSX.Element;
  onClick(): void;
}

function CustomButton({ name, Icon, onClick, className }: CustomButtonProps) {
  return (
    <Wrapper className={className} onClick={onClick}>
      <Icon />
      {name}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: ${getSize(14)};
  line-height: ${getSize(24)};
  color: var(--gray7);
  transition: color 0.2s ease-in;
  margin-bottom: ${getSize(17)};

  &:hover {
    color: var(--purple);

    & > svg {
      stroke: var(--purple);
    }
  }
`;

export default CustomButton;
