import { TitleWithInfo } from 'components/session-price-form/components';
import { FC } from 'react';
import styled from 'styled-components';
import { TherapistFragment } from 'common/query/__generated__/get-therapists-by-request';
import { notifyError } from 'lib/utils/notification';
import { Config } from 'final-form';
import { CloseButton } from 'ui';
import TherapistComissionForm, {
  CommissionFormValues,
} from './therapist-comission-form';

/** Mutation */
import { useChangeCommissionPercent } from 'common/mutation/__generated__/change-commission-percent';
interface TherapistEditPricesModalProps {
  onClose: () => void;
  therapist: TherapistFragment;
}

const EditTherapistCommisionModal: FC<TherapistEditPricesModalProps> = (
  props,
) => {
  const { onClose, therapist } = props;
  const therapistId = therapist.id;

  const [changeCommissionPercent, { loading }] = useChangeCommissionPercent({
    onError: ({ name, message }) => notifyError({ title: name, text: message }),
  });

  /**
   * @method
   */
  const handleFormSubmit: Config<CommissionFormValues>['onSubmit'] = async (
    { initialCommissionPercent, ongoingCommissionPercent },
    form,
  ) => {
    await changeCommissionPercent({
      variables: {
        input: {
          therapistId,
          initialCommissionPercent: Number(initialCommissionPercent),
          ongoingCommissionPercent: Number(ongoingCommissionPercent),
        },
      },
    });

    form.restart();
    onClose();
    return;
  };

  return (
    <ShadowWrapper>
      <Content>
        <HeaderWrapper>
          <TitleWithInfo
            title="Change therapist commision"
            info={`Change commision for selected therapist.`}
          />

          <CloseButton onClick={onClose} />
        </HeaderWrapper>

        <TherapistComissionForm
          handleFormSubmit={handleFormSubmit}
          initialValues={{
            initialCommissionPercent: therapist.initialSessionCommissionPercent,
            ongoingCommissionPercent: therapist.ongoingSessionCommissionPercent,
          }}
          submitLoading={loading}
        />
      </Content>
    </ShadowWrapper>
  );
};

const ShadowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.51);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const Content = styled.div`
  position: relative;
  background: #ffffff;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 15px 16px 0 16px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;

export default EditTherapistCommisionModal;
