import React from 'react';
import styled from 'styled-components';
import { Loader } from 'ui';
import { getSize } from 'lib/utils';
import { Suggestion } from 'use-places-autocomplete';

export interface SuggestionsProps {
  suggestions: Suggestion[];
  loading: boolean;
  isInputFocused?: boolean;
  onSelect: (value: string) => void;
}

function Suggestions({
  suggestions,
  loading,
  onSelect,
  isInputFocused,
}: SuggestionsProps) {
  if (suggestions.length === 0 || !isInputFocused) return null;

  return (
    <Wrapper>
      {loading && <Loader />}
      {suggestions.map((suggestion) => (
        <SuggestionItem
          key={suggestion.place_id}
          onClick={() => onSelect(suggestion.description)}>
          {suggestion.description}
        </SuggestionItem>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.ul`
  position: absolute;
  z-index: 10;
  top: calc(100% + ${getSize(8)});
  left: 0;
  padding: ${getSize(12)} 0;
  min-width: 100%;
  background: white;
  box-shadow: 0 ${getSize(20)} ${getSize(90)} var(--gray-opacity);
  border-radius: ${getSize(8)};
`;

const SuggestionItem = styled.li`
  padding: ${getSize(12)} ${getSize(24)};
  cursor: pointer;
  transition: 0.3s ease-out;

  &:hover {
    background: var(--purple11);
  }
`;

export default Suggestions;
