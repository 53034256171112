import { CustomEmoji, BaseEmoji } from 'emoji-mart';

export interface EmojiMartItem extends CustomEmoji, BaseEmoji {
  emoticons: string[];
  colons: string;
  id: string;
}

export const TRANSPARENT_GIF =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

function replaceAll(str: string, find: string, replace: string) {
  return str.replace(new RegExp(find, 'g'), replace);
}

export function getImage(emoji: EmojiMartItem) {
  let shortNames = `${emoji.short_names}`;

  shortNames = replaceAll(shortNames, ',', ', ');

  const emojiSpanEl =
    document.querySelector(
      `[aria-label="${emoji.native}, ${shortNames}"] > span`,
    ) || document.querySelector(`[aria-label="${emoji.id}"] > span`);

  if (!emojiSpanEl) return '';

  const style = replaceAll((emojiSpanEl as any).style.cssText, '"', "'");

  let dataEmoji = emoji.native;

  if (!dataEmoji && emoji.emoticons && emoji.emoticons.length > 0) {
    dataEmoji = emoji.emoticons[0];
  }

  return `<img class="emoji" style="${style}" data-emoji="${dataEmoji}" src="${TRANSPARENT_GIF}" />`;
}

export function pasteHtmlAtCaret(html: string) {
  let sel: Selection | null, range;

  if (window.getSelection) {
    // IE9 and non-IE
    sel = window.getSelection();
    if (sel?.getRangeAt && sel.rangeCount) {
      range = sel.getRangeAt(0);
      range.deleteContents();

      // Range.createContextualFragment() would be useful here but is
      // non-standard and not supported in all browsers (IE9, for one)
      const el = document.createElement('div');
      el.innerHTML = html;
      const frag = document.createDocumentFragment();
      let node;
      let lastNode;
      while ((node = el.firstChild)) {
        lastNode = frag.appendChild(node);
      }
      range.insertNode(frag);

      // Preserve the selection
      if (lastNode) {
        range = range.cloneRange();
        range.setStartAfter(lastNode);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }
  }
}
