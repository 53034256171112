import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AddTherapistToSearchVariables = Types.Exact<{
  input: Types.TherapistRemoveInput;
}>;


export type AddTherapistToSearch = (
  { __typename: 'Mutation' }
  & Pick<Types.Mutation, 'adminAddTherapistToSearch'>
);


export const AddTherapistToSearchDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddTherapistToSearch"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TherapistRemoveInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminAddTherapistToSearch"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type AddTherapistToSearchMutationFn = Apollo.MutationFunction<AddTherapistToSearch, AddTherapistToSearchVariables>;

/**
 * __useAddTherapistToSearch__
 *
 * To run a mutation, you first call `useAddTherapistToSearch` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTherapistToSearch` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTherapistToSearch, { data, loading, error }] = useAddTherapistToSearch({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTherapistToSearch(baseOptions?: Apollo.MutationHookOptions<AddTherapistToSearch, AddTherapistToSearchVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTherapistToSearch, AddTherapistToSearchVariables>(AddTherapistToSearchDocument, options);
      }
export type AddTherapistToSearchHookResult = ReturnType<typeof useAddTherapistToSearch>;
export type AddTherapistToSearchMutationResult = Apollo.MutationResult<AddTherapistToSearch>;
export type AddTherapistToSearchMutationOptions = Apollo.BaseMutationOptions<AddTherapistToSearch, AddTherapistToSearchVariables>;