import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RejectTherapistRequestVariables = Types.Exact<{
  input: Types.TherapistChangeRequestStatusInput;
}>;


export type RejectTherapistRequest = (
  { __typename: 'Mutation' }
  & { adminRejectTherapistRequest: (
    { __typename: 'AdminTherapistPayload' }
    & { therapistAdmin?: Types.Maybe<(
      { __typename: 'TherapistAdminModel' }
      & Pick<Types.TherapistAdminModel, 'id' | 'requestStatus'>
    )>, userErrors: Array<(
      { __typename: 'ValidationError' }
      & Pick<Types.ValidationError, 'field' | 'messages'>
    )> }
  ) }
);


export const RejectTherapistRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RejectTherapistRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TherapistChangeRequestStatusInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminRejectTherapistRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"therapistAdmin"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"requestStatus"}}]}},{"kind":"Field","name":{"kind":"Name","value":"userErrors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"field"}},{"kind":"Field","name":{"kind":"Name","value":"messages"}}]}}]}}]}}]} as unknown as DocumentNode;
export type RejectTherapistRequestMutationFn = Apollo.MutationFunction<RejectTherapistRequest, RejectTherapistRequestVariables>;

/**
 * __useRejectTherapistRequest__
 *
 * To run a mutation, you first call `useRejectTherapistRequest` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectTherapistRequest` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectTherapistRequest, { data, loading, error }] = useRejectTherapistRequest({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectTherapistRequest(baseOptions?: Apollo.MutationHookOptions<RejectTherapistRequest, RejectTherapistRequestVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectTherapistRequest, RejectTherapistRequestVariables>(RejectTherapistRequestDocument, options);
      }
export type RejectTherapistRequestHookResult = ReturnType<typeof useRejectTherapistRequest>;
export type RejectTherapistRequestMutationResult = Apollo.MutationResult<RejectTherapistRequest>;
export type RejectTherapistRequestMutationOptions = Apollo.BaseMutationOptions<RejectTherapistRequest, RejectTherapistRequestVariables>;