import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetFutureUpcomingSessionCountExtendedVariables = Types.Exact<{ [key: string]: never; }>;


export type GetFutureUpcomingSessionCountExtended = (
  { __typename: 'Query' }
  & Pick<Types.Query, 'futureUpcomingSessionCountExtended'>
);


export const GetFutureUpcomingSessionCountExtendedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetFutureUpcomingSessionCountExtended"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"futureUpcomingSessionCountExtended"}}]}}]} as unknown as DocumentNode;

/**
 * __useGetFutureUpcomingSessionCountExtended__
 *
 * To run a query within a React component, call `useGetFutureUpcomingSessionCountExtended` and pass it any options that fit your needs.
 * When your component renders, `useGetFutureUpcomingSessionCountExtended` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFutureUpcomingSessionCountExtended({
 *   variables: {
 *   },
 * });
 */
export function useGetFutureUpcomingSessionCountExtended(baseOptions?: Apollo.QueryHookOptions<GetFutureUpcomingSessionCountExtended, GetFutureUpcomingSessionCountExtendedVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFutureUpcomingSessionCountExtended, GetFutureUpcomingSessionCountExtendedVariables>(GetFutureUpcomingSessionCountExtendedDocument, options);
      }
export function useGetFutureUpcomingSessionCountExtendedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFutureUpcomingSessionCountExtended, GetFutureUpcomingSessionCountExtendedVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFutureUpcomingSessionCountExtended, GetFutureUpcomingSessionCountExtendedVariables>(GetFutureUpcomingSessionCountExtendedDocument, options);
        }
export type GetFutureUpcomingSessionCountExtendedHookResult = ReturnType<typeof useGetFutureUpcomingSessionCountExtended>;
export type GetFutureUpcomingSessionCountExtendedLazyQueryHookResult = ReturnType<typeof useGetFutureUpcomingSessionCountExtendedLazyQuery>;
export type GetFutureUpcomingSessionCountExtendedQueryResult = Apollo.QueryResult<GetFutureUpcomingSessionCountExtended, GetFutureUpcomingSessionCountExtendedVariables>;