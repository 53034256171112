import React, { ReactNode } from 'react';
import styled, { CSSProp } from 'styled-components';
import { getSize } from 'lib/utils';
import { ShadowWrapper } from 'ui/shadow-wrapper';

export interface AuthWrapperProps {
  children: ReactNode;
  title: string;
  className?: string;
  titleCSS?: CSSProp;
  rootCSS?: CSSProp;
}

function AuthWrapper({
  children,
  title,
  className,
  titleCSS,
  rootCSS,
}: AuthWrapperProps) {
  return (
    <Wrapper $CSS={rootCSS}>
      <Inner asElement="section" className={className}>
        <Title $CSS={titleCSS}>{title}</Title>
        {children}
      </Inner>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ $CSS?: CSSProp }>`
  display: flex;
  padding: ${getSize(32)};
  min-height: 100%;

  ${({ $CSS }) => $CSS}
`;

const Inner = styled(ShadowWrapper)`
  margin: auto;
  padding: ${getSize(34)} ${getSize(32)} ${getSize(32)};
  width: ${getSize(398)};
`;

const Title = styled.h1<{ $CSS?: CSSProp }>`
  font-weight: 600;
  font-size: ${getSize(18)};
  line-height: ${getSize(32)};
  color: var(--black3);

  ${({ $CSS }) => $CSS}
`;

export default AuthWrapper;
