import { useAddTherapistToSearch } from 'common/mutation/__generated__/add-therapist-from-search';
import { useRemoveTherapistFromSearch } from 'common/mutation/__generated__/remove-therapist-from-search';

export const useManageTherapistInSearchList = () => {
  const [removeTherapistFromSearch, { loading: removeFormSearchLoading }] =
    useRemoveTherapistFromSearch({
      update(cache) {
        cache.evict({
          fieldName: 'adminTherapistsSearch',
          broadcast: true,
        });
        cache.evict({
          fieldName: 'adminGetTherapistById',
          broadcast: true,
        });
        cache.gc();
      },
    });

  const [addTherapistToSearch, { loading: addToSearchLoading }] =
    useAddTherapistToSearch({
      update(cache) {
        cache.evict({
          fieldName: 'adminTherapistsSearch',
          broadcast: true,
        });
        cache.evict({
          fieldName: 'adminGetTherapistById',
          broadcast: true,
        });
        cache.gc();
      },
    });

  return {
    removeTherapistFromSearch,
    addTherapistToSearch,
    removeFormSearchLoading,
    addToSearchLoading,
  };
};
