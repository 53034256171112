import React, { useState } from 'react';
import { useAdminBillSubmitHistory } from '../queries/__generated__/admin-bill-submit-history';
import BillHistoryList from '../components/bill-history-list/bill-history-list';
import BillHistoryHeader from '../components/bill-history-header/bill-history-header';
import BillHistoryItem from '../components/bill-history-item/bill-history-item';
import { LogType, BillValidateHistoryFilter } from '__generated__/types';
import useReadSpecificLog from '../hooks/useReadSpecificLog';

const SubmitBillHistory = () => {
  const [filter, setFilter] = useState<BillValidateHistoryFilter>({
    from: null,
    to: null,
  });

  const { data, loading, fetchMore } = useAdminBillSubmitHistory({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      first: 50,
      filter,
    },
  });

  const { handleReadSpecificLog } = useReadSpecificLog({
    logType: LogType.SubmitBill,
  });

  const items = data?.adminBillSubmitHistory.nodes || [];

  return (
    <BillHistoryList
      id="SubmitBillHistory"
      header={
        <BillHistoryHeader onChange={setFilter} title="Submit Bill History" />
      }
      renderItem={BillHistoryItem}
      loading={loading && !items?.length}
      items={items}
      onOpen={handleReadSpecificLog}
      hasNextPage={!!data?.adminBillSubmitHistory.pageInfo.hasNextPage}
      loadMore={() =>
        fetchMore({
          variables: {
            after: data?.adminBillSubmitHistory.pageInfo.endCursor,
          },
        })
      }
    />
  );
};

export default SubmitBillHistory;
