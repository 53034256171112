import {
  FeedbackCompaniesAdminFragment,
  useGetAdminFeedback,
} from 'common/query/__generated__/admin-comanies-feedback';
import { useGetFutureUpcomingSessionCount } from 'common/query/__generated__/get-future-session-count';
import {
  FeedbackRowFragment,
  useGetUsersFeedback,
} from 'common/query/__generated__/get-users-feedback';
import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  OrderByDirection,
  TherapistAdminSort,
  TherapistRequestStatus,
} from '__generated__/types';
import { AllB2bUsers } from './components/all-b2b-users';

interface FeedbackB2bTableProps {
  openFeedbackModal: () => void;
  setCompanyId: React.Dispatch<React.SetStateAction<string>>;
}

export const FeedbackB2bTable: FC<FeedbackB2bTableProps> = ({
  openFeedbackModal,
  setCompanyId,
}) => {
  const [filter, setFilter] = useState('');
  const [sortOption, setSortOption] = useState(TherapistAdminSort.UpdatedAt);
  const [sortDirection, setSortDirection] = useState(OrderByDirection.Desc);

  const MAX_VISIBLE_FEEDBACK = 10;

  const usersFeedbackData = useGetUsersFeedback({
    variables: {
      first: MAX_VISIBLE_FEEDBACK,
    },
  });

  const { data: feedbackCompanies, fetchMore: fetchMoreFeedbackCompanies } =
    useGetAdminFeedback({
      variables: {
        first: MAX_VISIBLE_FEEDBACK,
      },
    });

  const feedbackCompaniesData = feedbackCompanies?.adminCompaniesFeedback.nodes;

  const { data: futureSessionCount } = useGetFutureUpcomingSessionCount();

  const therapistSessionCount = futureSessionCount?.futureUpcomingSessionCount
    ? futureSessionCount.futureUpcomingSessionCount[0]
    : 0;

  return (
    <AllB2bUsersStyled
      dataLength={feedbackCompaniesData?.length || 0}
      onNext={() => {
        return fetchMoreFeedbackCompanies({
          variables: {
            filter: {
              status: TherapistRequestStatus.Accepted,
              searchName: filter,
            },
            after:
              usersFeedbackData.data?.adminUsersFeedback.pageInfo.endCursor,
          },
        });
      }}
      hasMore={Boolean(
        usersFeedbackData.data?.adminUsersFeedback.pageInfo.hasNextPage,
      )}
      feedbackCompanies={feedbackCompaniesData}
      isLoading={usersFeedbackData.loading}
      futureSessionCount={therapistSessionCount}
      setSortOption={setSortOption}
      setSortDirection={setSortDirection}
      sortDirection={sortDirection}
      sortOption={sortOption}
      onOpenModal={openFeedbackModal}
      setCompanyId={setCompanyId}
    />
  );
};

const AllB2bUsersStyled = styled(AllB2bUsers)`
  margin-top: 31px;
`;
