import React from 'react';
import { getSize } from '../../../lib/utils';

export interface CircleLoaderIconProps {
  circleColor?: string;
  pathColor?: string;
  width?: number;
  height?: number;
  className?: string;
}

function CircleLoaderIcon({
  circleColor = '#D4C9EC',
  pathColor = '#6B4EE6',
  width = 24,
  height = 24,
  className,
}: CircleLoaderIconProps) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={getSize(width)}
      height={getSize(height)}
      fill="none"
      viewBox="0 0 24 24">
      <circle
        cx="12"
        cy="12"
        r="9"
        stroke={circleColor}
        strokeOpacity=".4"
        strokeWidth="3"
      />
      <path stroke={pathColor} strokeWidth="3" d="M12 21a9 9 0 01-9-9" />
    </svg>
  );
}

export default CircleLoaderIcon;
