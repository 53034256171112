import React from 'react';

function CopyIcon({ ...rest }) {
  return (
    <svg
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <path
        d="M4 5.42857C4 4.63959 4.60822 4 5.35849 4H10.6415C11.3918 4 12 4.63959 12 5.42857V12.5714C12 13.3604 11.3918 14 10.6415 14H5.35849C4.60822 14 4 13.3604 4 12.5714V5.42857Z"
        stroke="black"
      />
      <path
        d="M4 11V11.5H4.5V11H4ZM9 4V4.5H9.5V4H9ZM1.5 2.42857C1.5 1.89174 1.90776 1.5 2.35849 1.5V0.5C1.30867 0.5 0.5 1.38745 0.5 2.42857H1.5ZM1.5 9.57143V2.42857H0.5V9.57143H1.5ZM2.35849 10.5C1.90776 10.5 1.5 10.1083 1.5 9.57143H0.5C0.5 10.6126 1.30867 11.5 2.35849 11.5V10.5ZM4 10.5H2.35849V11.5H4V10.5ZM4.5 11V5.42857H3.5V11H4.5ZM4.5 5.42857C4.5 4.89174 4.90776 4.5 5.35849 4.5V3.5C4.30867 3.5 3.5 4.38745 3.5 5.42857H4.5ZM5.35849 4.5H9V3.5H5.35849V4.5ZM8.5 2.42857V4H9.5V2.42857H8.5ZM7.64151 1.5C8.09224 1.5 8.5 1.89174 8.5 2.42857H9.5C9.5 1.38745 8.69133 0.5 7.64151 0.5V1.5ZM2.35849 1.5H7.64151V0.5H2.35849V1.5Z"
        fill="black"
      />
    </svg>
  );
}

export default CopyIcon;
