import React, { useCallback, useMemo, useState } from 'react';
import styled, { CSSProp } from 'styled-components';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';
import { customStyles } from './utils/styles';
import { DropdownIndicator } from './components';

export interface Option {
  value: string | number;
  label: string | number;
}
export interface DateSelectProps {
  rootCSS?: CSSProp;
  options: Option[];
  value: string;
  name?: string;
  onChange: (value: any) => void;
}

function DateSelect({
  value,
  rootCSS,
  options = [],
  onChange,
  name,
}: DateSelectProps) {
  const [isOpen, setIsOpen] = useState(false);

  const selectedOption = useMemo(
    () => options.find((option) => option.value === value),
    [options, value],
  );

  const handleChange = useCallback(
    (valueType: ValueType<Option, any>) => {
      const newValue = (valueType as Option).value;
      onChange(newValue);
    },
    [onChange],
  );

  return (
    <Wrapper CSS={rootCSS} onClick={() => setIsOpen(!isOpen)}>
      <Select
        components={{
          DropdownIndicator: (props) => (
            <DropdownIndicator stroke="var(--black3)" {...props} />
          ),
        }}
        name={name}
        value={selectedOption}
        options={options}
        onChange={handleChange}
        styles={customStyles}
        menuIsOpen={isOpen}
        isOpen={isOpen}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div<{ CSS?: CSSProp }>`
  display: block;
  width: 100%;
  z-index: 10000;

  ${({ CSS }) => CSS}
`;

export default DateSelect;
