import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AdminTotalBusinessInfoVariables = Types.Exact<{ [key: string]: never; }>;


export type AdminTotalBusinessInfo = (
  { __typename: 'Query' }
  & { adminTotalBusinessInfo: (
    { __typename: 'TotalBusinessInfoModel' }
    & Pick<Types.TotalBusinessInfoModel, 'activePatients' | 'totalSessionsAmount' | 'avgSessionsPerPatient' | 'upcomingSessions'>
  ) }
);


export const AdminTotalBusinessInfoDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AdminTotalBusinessInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminTotalBusinessInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"activePatients"}},{"kind":"Field","name":{"kind":"Name","value":"totalSessionsAmount"}},{"kind":"Field","name":{"kind":"Name","value":"avgSessionsPerPatient"}},{"kind":"Field","name":{"kind":"Name","value":"upcomingSessions"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useAdminTotalBusinessInfo__
 *
 * To run a query within a React component, call `useAdminTotalBusinessInfo` and pass it any options that fit your needs.
 * When your component renders, `useAdminTotalBusinessInfo` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminTotalBusinessInfo({
 *   variables: {
 *   },
 * });
 */
export function useAdminTotalBusinessInfo(baseOptions?: Apollo.QueryHookOptions<AdminTotalBusinessInfo, AdminTotalBusinessInfoVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminTotalBusinessInfo, AdminTotalBusinessInfoVariables>(AdminTotalBusinessInfoDocument, options);
      }
export function useAdminTotalBusinessInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminTotalBusinessInfo, AdminTotalBusinessInfoVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminTotalBusinessInfo, AdminTotalBusinessInfoVariables>(AdminTotalBusinessInfoDocument, options);
        }
export type AdminTotalBusinessInfoHookResult = ReturnType<typeof useAdminTotalBusinessInfo>;
export type AdminTotalBusinessInfoLazyQueryHookResult = ReturnType<typeof useAdminTotalBusinessInfoLazyQuery>;
export type AdminTotalBusinessInfoQueryResult = Apollo.QueryResult<AdminTotalBusinessInfo, AdminTotalBusinessInfoVariables>;