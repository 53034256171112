import React from 'react';
import { getSize, TimeUtil } from 'lib/utils';
import styled from 'styled-components';
import { DEFAULT_AVATAR } from 'lib/constants/therapist';
import { StarIcon } from 'ui';
import { FeedbackAdminFragment } from 'common/query/__generated__/get-therapist-feedback';

interface FeedbackProps {
  feedback: FeedbackAdminFragment;
  isFirst?: boolean;
  isFeedbackPage?: boolean;
}

const FeedbackBlock: React.FC<FeedbackProps> = ({
  feedback,
  isFirst,
  isFeedbackPage,
}) => {
  return (
    <Wrapper $isFirst={isFirst} $isFeedbackPage={isFeedbackPage}>
      <TopBlock>
        <UserBlock>
          <Avatar
            src={feedback.patient.user.avatar?.source || DEFAULT_AVATAR}
            alt="Patient avatar"
          />
          <div>
            <Name>
              {feedback.patient.user.firstName} {feedback.patient.user.lastName}
            </Name>
            <Date>
              {TimeUtil.getFormatted(feedback.updatedAt, 'DD MMM YYYY HH:mm')}
            </Date>
          </div>
        </UserBlock>
        <RatingBlock>
          <StarIcon />
          <Rating>{feedback.rating.toFixed(1)}</Rating>
        </RatingBlock>
      </TopBlock>
      <FeedbackText $isFeedbackPage={isFeedbackPage}>
        {feedback.description}
      </FeedbackText>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $isFirst?: boolean; $isFeedbackPage?: boolean }>`
  background: var(--purple11);
  width: ${({ $isFeedbackPage }) => ($isFeedbackPage ? 'auto' : getSize(333))};
  height: ${({ $isFeedbackPage }) => ($isFeedbackPage ? 'auto' : getSize(246))};
  padding: ${getSize(24)};
  overflow: hidden;
  margin-right: ${({ $isFirst }) => ($isFirst ? getSize(24) : 0)};
  margin-bottom: ${({ $isFeedbackPage }) =>
    $isFeedbackPage ? getSize(24) : 0};
  border-radius: 8px;
`;

const TopBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${getSize(16)};
`;
const UserBlock = styled.div`
  display: flex;
`;
const Avatar = styled.img`
  flex: 0 0 ${getSize(40)};
  width: ${getSize(40)};
  height: ${getSize(40)};
  border-radius: 50%;
  object-fit: cover;
  margin: 0 ${getSize(16)} 0 0;
`;
const Name = styled.div`
  font-size: ${getSize(12)};
  line-height: ${getSize(20)};
  font-weight: 600;
`;
const Date = styled.p`
  font-size: ${getSize(11)};
  line-height: ${getSize(17)};
  color: var(--gray7);
`;
const FeedbackText = styled.p<{ $isFeedbackPage?: boolean }>`
  font-size: ${getSize(12)};
  line-height: ${getSize(20)};
  display: -webkit-box;
  ${({ $isFeedbackPage }) => !$isFeedbackPage && '-webkit-line-clamp: 7;'}
  -webkit-box-orient: vertical;
  ${({ $isFeedbackPage }) => !$isFeedbackPage && 'overflow: hidden;'}
  text-overflow: ellipsis;
`;

const RatingBlock = styled.div`
  background: var(--white);
  height: ${getSize(40)};
  width: ${getSize(70)};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${getSize(9)} ${getSize(10)};
`;

const Rating = styled.span`
  font-size: ${getSize(14)};
  line-height: ${getSize(20)};
  padding-top: ${getSize(2)};
`;
export default FeedbackBlock;
