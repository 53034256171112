import React, { FC, useEffect, useMemo, useState } from 'react';

import { API_DATE_FORMAT, getSize, ISO_DATE_FORMAT, TimeUtil } from 'lib/utils';
import { BillValidateHistoryFilter } from '__generated__/types';
import { DropDownCalendar } from 'ui/drop-down-calendar';
import { DatesRange } from 'ui/calendar-small/utils/add-day-to-range';
import styled from 'styled-components';

export interface Props {
  filter?: BillValidateHistoryFilter;
  title: string;
  onChange: (values: BillValidateHistoryFilter) => void;
}

const today = TimeUtil.now();

let timeout: NodeJS.Timeout;

const BillHistoryHeader: FC<Props> = ({ filter, onChange, title }) => {
  const initialInterval = {
    from: filter?.from
      ? TimeUtil.parse(filter.from, API_DATE_FORMAT).toDate()
      : today.subtract(1, 'week').toDate(),
    to: filter?.to
      ? TimeUtil.parse(filter.to, API_DATE_FORMAT).toDate()
      : today.add(48, 'hour').toDate(),
  };
  const [selectedDaysRange, setSelectedDaysRange] = useState<DatesRange>({
    from: null,
    to: null,
  });

  const { from, to } = selectedDaysRange;

  useEffect(() => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      onChange({
        from: from
          ? TimeUtil.getStartOfDay(from.toISOString(), ISO_DATE_FORMAT).format(
              API_DATE_FORMAT,
            )
          : null,
        to: to
          ? TimeUtil.getStartOfDay(to.toISOString(), ISO_DATE_FORMAT)
              .add(1, 'day')
              .format(API_DATE_FORMAT)
          : null,
      });
    }, 1000);
  }, [from, to]);

  const selectedDays = useMemo(() => {
    const from = selectedDaysRange.from;
    const to = selectedDaysRange.to;

    if (from && to) {
      return [from, to];
    } else if (from) {
      return from;
    } else if (to) {
      return to;
    }
  }, [selectedDaysRange]);

  const calendarFilterTitle = useMemo(() => {
    const { from, to } = selectedDaysRange;

    const filterStart =
      from &&
      TimeUtil.parse(from.toISOString(), ISO_DATE_FORMAT).format('MMM D');
    const filterEnd =
      to && TimeUtil.parse(to.toISOString(), ISO_DATE_FORMAT).format('MMM D');

    return filterStart || filterEnd
      ? `${filterStart}${filterEnd ? ` - ${filterEnd}` : ''}`
      : 'Filter by dates';
  }, [selectedDaysRange]);

  return (
    <>
      <Wrapper>
        {title}
        <DropDownCalendar
          title={calendarFilterTitle}
          selectedRange={selectedDaysRange}
          selectedDays={selectedDays}
          onDayClickRange={setSelectedDaysRange}
          isRange
          wrapperCSS={FILTER_WRAPPER_CSS}
        />
      </Wrapper>
      <Wrapper>
        <div style={{ width: 300 }}>Event date </div>
        <div style={{ width: 300 }}>Session start date</div>
        <div style={{ width: 150 }}>Duration</div>
        <div style={{ width: 150 }}>Status</div>
      </Wrapper>
    </>
  );
};

const FILTER_WRAPPER_CSS = {
  minWidth: getSize(112),
  display: 'flex',
  justifyContent: 'flex-end',
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${getSize(10)};
  font-weight: 550;
`;

export default BillHistoryHeader;
