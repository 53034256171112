import React, { useCallback, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ArrowDownIcon } from 'ui/icons';
import { CustomButton } from 'ui/custom-button';
import { getSize } from 'lib/utils';
import { TherapistInfoHeader } from './components/therapist-info-header';
import { TherapistInfoMain } from './components/therapist-info-main';
import { useAdminGetTherapistById } from 'common/query/__generated__/get-therapist-by-id';
import { Loader, ManageUserConfirmModal } from 'ui';
import { useRejectTherapistRequest } from 'common/mutation/__generated__/therapist-reject-request';
import { useRemoveTherapistFromBase } from 'common/mutation/__generated__/remove-therapist-from-base';
import { notifyError, notifySuccess } from 'lib/utils/notification';

import { useManageTherapistBan } from 'hooks/use-manage-therapist-ban';
import { InsuranceForm } from 'components/insurance-form';
import { InsuranceFormValues } from 'components/insurance-form/insurance-form';
import { useEditTherapistInsuranceCredentials } from 'common/mutation/__generated__/edit-therapist-insurance-credentials';
import { getFormSubmitError } from 'lib/utils/form';

function TherapistPage() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [therapistId, setTherapistId] = useState('');
  const [isBan, setIsBan] = useState(false);
  const [isLoadingCredentials, setIsLoadingCredentials] = useState(false);

  const { id } = useParams<{ id: string }>();
  const therapistByIdQuery = useAdminGetTherapistById({ variables: { id } });

  const therapist = therapistByIdQuery.data?.adminGetTherapistById;

  const [editInsuranceCredentials] = useEditTherapistInsuranceCredentials({
    update(cache) {
      cache.evict({
        fieldName: 'adminGetTherapistById',
        broadcast: true,
      });
      cache.gc();
    },
  });

  const [rejectTherapistRequest, { loading: rejectLoading }] =
    useRejectTherapistRequest({
      onError: ({ name, message }) =>
        notifyError({ title: name, text: message }),
      update(cache) {
        cache.evict({
          fieldName: 'adminTherapistsSearch',
          broadcast: true,
        });
        cache.gc();
      },
    });

  const [removeTherapistFromBase, { loading: baseLoading }] =
    useRemoveTherapistFromBase({
      update(cache) {
        cache.evict({
          fieldName: 'adminTherapistsSearch',
          broadcast: true,
        });
        cache.gc();
      },
    });

  const history = useHistory();
  const isRequest = history.location.pathname.includes('requests');

  const modalAction = (id: string, isBan?: boolean) => {
    setTherapistId(id);
    setIsModalVisible(true);
    isBan && setIsBan(true);
  };

  const confirmRejectAction = useCallback(() => {
    rejectTherapistRequest({
      variables: { input: { therapistId } },
    });
    history.goBack();
  }, [history, rejectTherapistRequest, therapistId]);

  const confirmRemoveFromBaseAction = useCallback(() => {
    removeTherapistFromBase({ variables: { input: { therapistId } } });
    history.goBack();
  }, [history, removeTherapistFromBase, therapistId]);

  const { onBanTherapist, onUnbanTherapist } = useManageTherapistBan({
    onSuccess: () => setIsModalVisible(false),
  });

  const confirmModalProps = useMemo(() => {
    if (isRequest) {
      return {
        title: 'Reject request',
        text: 'Do you want to reject therapist request?',
        onAction: confirmRejectAction,
      };
    } else if (isBan) {
      return {
        title: 'Pause access',
        text: 'Do you want to pause access for this therapist?',
        onAction: () => {
          onBanTherapist(therapistId);
        },
      };
    } else {
      return {
        title: 'Removing therapist',
        text: 'Do you want to delete therapist from base?',
        onAction: confirmRemoveFromBaseAction,
      };
    }
  }, [
    isRequest,
    isBan,
    confirmRejectAction,
    onBanTherapist,
    therapistId,
    confirmRemoveFromBaseAction,
  ]);

  const insuranceInitialValues = useMemo(() => {
    const credentials = therapist?.insuranceCredentials;
    if (credentials) {
      const { password, username, siteId, insuranceLogin } = credentials;

      return {
        password,
        username,
        siteId,
        insuranceLogin,
      };
    }
  }, [therapist]);

  const handleSubmitInsuranceCredentials = (values: InsuranceFormValues) => {
    delete values.providerId, setIsLoadingCredentials(true);

    if (therapist?.id) {
      return editInsuranceCredentials({
        variables: {
          input: {
            ...values,
            insuranceLogin: values.insuranceLogin || '',
            therapistId: therapist.id,
          },
        },
      })
        .then((response) => {
          const validateErrors =
            response.data?.adminTherapistCredentialsEdit.userErrors;
          if (validateErrors?.length) {
            validateErrors.forEach(
              ({ field, messages }) =>
                field === 'base' && notifyError({ text: messages[0] }),
            );
            return getFormSubmitError(validateErrors);
          } else {
            notifySuccess({
              title: 'Insurance credentials',
              text: 'Has been edited successfully!',
            });
          }
        })
        .finally(() => setIsLoadingCredentials(false));
    }
  };

  if (!therapist) {
    return <Loader />;
  }

  return (
    <Wrapper>
      <BackButtonWrapper
        Icon={BackArrowIcon}
        name="Back"
        onClick={() => history.goBack()}
      />
      <InfoWrapper>
        <TherapistInfoHeader
          rejectAction={modalAction}
          isRequest={isRequest}
          therapist={therapist}
          modalAction={modalAction}
          onUnbanTherapist={() => onUnbanTherapist(therapist.id)}
        />
      </InfoWrapper>
      <InfoWrapper>
        <InsuranceForm
          isTherapistResume
          initialValues={insuranceInitialValues}
          isLoading={isLoadingCredentials}
          onSubmit={handleSubmitInsuranceCredentials}
        />
      </InfoWrapper>
      <InfoWrapper>
        <TherapistInfoMain
          isRequest={isRequest}
          therapist={therapist}
          onModalAction={modalAction}
          onUnbanTherapist={() => onUnbanTherapist(therapist.id)}
        />
      </InfoWrapper>

      <ManageUserConfirmModal
        {...confirmModalProps}
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        isLoading={rejectLoading || baseLoading}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: ${getSize(56)};
`;

const InfoWrapper = styled.div`
  margin-bottom: ${getSize(39)};
`;

const BackButtonWrapper = styled(CustomButton)``;

const BackArrowIcon = styled(ArrowDownIcon)`
  transform: rotate(90deg);
  stroke: var(--black3);
  width: ${getSize(22)};
  height: ${getSize(22)};
  margin-right: ${getSize(5)};
`;

export default TherapistPage;
