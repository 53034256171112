import React from 'react';

function UserSecondaryIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="90"
      fill="none"
      viewBox="0 0 90 90"
      {...rest}>
      <g fill="#E1DCFA" clipPath="url(#user-secondary-icon-svg)">
        <path d="M44.358 43.353c5.956 0 11.113-2.136 15.327-6.35 4.214-4.214 6.35-9.37 6.35-15.327 0-5.955-2.136-11.111-6.35-15.327C55.47 2.136 50.312 0 44.357 0 38.4 0 33.245 2.136 29.03 6.35c-4.214 4.214-6.35 9.37-6.35 15.326 0 5.957 2.136 11.113 6.35 15.327 4.215 4.213 9.372 6.35 15.327 6.35zM82.288 69.206a53.574 53.574 0 00-.729-5.687A44.815 44.815 0 0080.16 57.8a28.24 28.24 0 00-2.35-5.332c-1.015-1.786-2.207-3.34-3.544-4.62-1.4-1.338-3.112-2.414-5.092-3.198-1.973-.781-4.16-1.177-6.5-1.177-.919 0-1.807.377-3.523 1.495a477.281 477.281 0 01-3.67 2.366c-1.179.75-2.776 1.455-4.749 2.092a18.857 18.857 0 01-5.807.938c-1.93 0-3.883-.315-5.81-.938-1.97-.637-3.567-1.34-4.745-2.092a502.783 502.783 0 01-3.673-2.367c-1.714-1.117-2.604-1.494-3.522-1.494-2.34 0-4.527.396-6.5 1.177-1.978.784-3.692 1.86-5.092 3.2-1.337 1.28-2.53 2.833-3.543 4.618a28.294 28.294 0 00-2.35 5.333 44.914 44.914 0 00-1.398 5.717 53.352 53.352 0 00-.73 5.689c-.119 1.722-.18 3.51-.18 5.314 0 4.698 1.494 8.502 4.44 11.306C14.73 88.597 18.578 90 23.258 90h43.335c4.68 0 8.528-1.403 11.437-4.171 2.946-2.803 4.44-6.607 4.44-11.307-.001-1.814-.063-3.603-.183-5.316z" />
      </g>
      <defs>
        <clipPath id="user-secondary-icon-svg">
          <path fill="#fff" d="M0 0h90v90H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default UserSecondaryIcon;
