import { useLocation } from 'react-router-dom';
import { dashboardRoutes } from 'routes/root-routes';

export function useIsMessagingRoute() {
  const { pathname } = useLocation();

  const messagingRoute = dashboardRoutes.messaging.PATH.split('/')
    .slice(0, -1)
    .join('/');

  const isMessagingRoute = pathname.startsWith(messagingRoute);

  return { isMessagingRoute };
}
