import { RefObject, useEffect } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { ChatMessages } from 'pages/messaging-page/query/__generated__/chat-messages';

interface useFetchAvailableMessageInViewArgs {
  messageListRef: RefObject<HTMLDivElement>;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
  isLoading: boolean;
  fetchPreviousMessages: (
    oldScrollPosition?: number,
  ) => Promise<ApolloQueryResult<ChatMessages>> | undefined;
  fetchNextMessages: () => Promise<ApolloQueryResult<ChatMessages>> | undefined;
}

export function useFetchAvailableMessageInView({
  messageListRef,
  hasPreviousPage,
  hasNextPage,
  isLoading,
  fetchPreviousMessages,
  fetchNextMessages,
}: useFetchAvailableMessageInViewArgs) {
  useEffect(() => {
    const wrapper = messageListRef.current;
    if (!wrapper) return;

    const { scrollHeight, clientHeight } = wrapper;

    if (scrollHeight === clientHeight && !isLoading) {
      if (hasPreviousPage) {
        fetchPreviousMessages();
      } else if (hasNextPage) {
        fetchNextMessages();
      }
    }
  }, [
    messageListRef,
    hasPreviousPage,
    hasNextPage,
    isLoading,
    fetchPreviousMessages,
    fetchNextMessages,
  ]);
}
