import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type OverallUnreadCountChangedVariables = Types.Exact<{ [key: string]: never; }>;


export type OverallUnreadCountChanged = (
  { __typename: 'Subscription' }
  & Pick<Types.Subscription, 'overallUnreadCountChanged'>
);


export const OverallUnreadCountChangedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"OverallUnreadCountChanged"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"overallUnreadCountChanged"}}]}}]} as unknown as DocumentNode;

/**
 * __useOverallUnreadCountChanged__
 *
 * To run a query within a React component, call `useOverallUnreadCountChanged` and pass it any options that fit your needs.
 * When your component renders, `useOverallUnreadCountChanged` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverallUnreadCountChanged({
 *   variables: {
 *   },
 * });
 */
export function useOverallUnreadCountChanged(baseOptions?: Apollo.SubscriptionHookOptions<OverallUnreadCountChanged, OverallUnreadCountChangedVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OverallUnreadCountChanged, OverallUnreadCountChangedVariables>(OverallUnreadCountChangedDocument, options);
      }
export type OverallUnreadCountChangedHookResult = ReturnType<typeof useOverallUnreadCountChanged>;
export type OverallUnreadCountChangedSubscriptionResult = Apollo.SubscriptionResult<OverallUnreadCountChanged>;