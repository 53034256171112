import React from 'react';
import styled from 'styled-components';
import { getSize } from 'lib/utils';
import { ShadowWrapper } from 'ui/shadow-wrapper';
import { Button } from 'ui/button';

export interface ResourceCardProps {
  resource: any;
  deleteResource: (id: string) => void;
  handleEditClick: (resource: any) => void;
}

function ResourceCard({
  resource,
  deleteResource,
  handleEditClick,
}: ResourceCardProps) {
  const { author, title, subtitle, postText, videoUrl } = resource;
  const postId = resource.id;
  const subCategoryName: string = resource.subCategory?.name || '';
  const type = resource.type;

  return (
    <Wrapper>
      <DateText $width={140}>{subCategoryName}</DateText>
      <DateText $width={140}>{author}</DateText>
      <DateText $width={140}>{title}</DateText>
      <DateText $width={140}>{subtitle}</DateText>
      <DateText $width={140}>{type}</DateText>
      <ButtonsWrapper>
        <EditButton theme="primary" onClick={() => handleEditClick(resource)}>
          Edit data
        </EditButton>
        <RemoveBaseButton
          theme="secondary"
          onClick={() => deleteResource(postId)}>
          Delete data
        </RemoveBaseButton>
      </ButtonsWrapper>
    </Wrapper>
  );
}

const Wrapper = styled(ShadowWrapper)`
  display: flex;
  align-items: center;
  padding: ${getSize(12)} ${getSize(24)};
`;
const MailBlock = styled.div`
  position: relative;
`;

const MailText = styled.button<{ $color?: string; $width?: number }>`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--gray7);
  width: ${({ $width }) => ($width ? getSize($width) : '100%')};
  min-width: ${({ $width }) => ($width ? getSize($width) : 'auto')};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;

  &:hover ~ {
    .tooltip-message {
      display: block;
      animation: showTooltip 0.3s ease-out forwards;
      white-space: nowrap;
    }
  }
`;

const DateText = styled(MailText)`
  text-align: center;
  white-space: pre-wrap;
`;

const ButtonsWrapper = styled.div`
  display: inline;
  align-items: center;
  justify-content: flex-end;
  padding-left: ${getSize(10)};
`;

const RemoveBaseButton = styled(Button)`
  flex-shrink: 0;
  min-height: auto;
  font-weight: 500;
  font-size: 10px;
`;

const EditButton = styled(Button)`
  flex-shrink: 0;
  min-height: auto;
  font-weight: 500;
  font-size: 10px;
  margin-right: 10px;
`;

const BanUserButton = styled(Button)`
  margin-right: ${getSize(16)};
  flex-shrink: 0;
  font-size: 10px;
`;

const UnbanButton = styled(BanUserButton)`
  color: var(--black3);
`;

export default ResourceCard;
