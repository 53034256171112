import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetUnreadFailedLogsCountVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUnreadFailedLogsCount = (
  { __typename: 'Query' }
  & { getUnreadFailedLogsCount: (
    { __typename: 'LogsIndicatorsModel' }
    & Pick<Types.LogsIndicatorsModel, 'validateCount' | 'submitCount' | 'statusCount' | 'all'>
  ) }
);


export const GetUnreadFailedLogsCountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetUnreadFailedLogsCount"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getUnreadFailedLogsCount"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"validateCount"}},{"kind":"Field","name":{"kind":"Name","value":"submitCount"}},{"kind":"Field","name":{"kind":"Name","value":"statusCount"}},{"kind":"Field","name":{"kind":"Name","value":"all"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetUnreadFailedLogsCount__
 *
 * To run a query within a React component, call `useGetUnreadFailedLogsCount` and pass it any options that fit your needs.
 * When your component renders, `useGetUnreadFailedLogsCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnreadFailedLogsCount({
 *   variables: {
 *   },
 * });
 */
export function useGetUnreadFailedLogsCount(baseOptions?: Apollo.QueryHookOptions<GetUnreadFailedLogsCount, GetUnreadFailedLogsCountVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnreadFailedLogsCount, GetUnreadFailedLogsCountVariables>(GetUnreadFailedLogsCountDocument, options);
      }
export function useGetUnreadFailedLogsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnreadFailedLogsCount, GetUnreadFailedLogsCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnreadFailedLogsCount, GetUnreadFailedLogsCountVariables>(GetUnreadFailedLogsCountDocument, options);
        }
export type GetUnreadFailedLogsCountHookResult = ReturnType<typeof useGetUnreadFailedLogsCount>;
export type GetUnreadFailedLogsCountLazyQueryHookResult = ReturnType<typeof useGetUnreadFailedLogsCountLazyQuery>;
export type GetUnreadFailedLogsCountQueryResult = Apollo.QueryResult<GetUnreadFailedLogsCount, GetUnreadFailedLogsCountVariables>;