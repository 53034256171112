import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PayInsuranceSessionVariables = Types.Exact<{
  sessionMetaId: Types.Scalars['String'];
}>;


export type PayInsuranceSession = (
  { __typename: 'Mutation' }
  & { adminPayForInsuranceSession: (
    { __typename: 'AdminPaymentPayload' }
    & Pick<Types.AdminPaymentPayload, 'success'>
    & { userErrors: Array<(
      { __typename: 'ValidationError' }
      & Pick<Types.ValidationError, 'field' | 'messages'>
    )> }
  ) }
);


export const PayInsuranceSessionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"PayInsuranceSession"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"sessionMetaId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminPayForInsuranceSession"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"sessionMetaId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"sessionMetaId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"userErrors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"field"}},{"kind":"Field","name":{"kind":"Name","value":"messages"}}]}}]}}]}}]} as unknown as DocumentNode;
export type PayInsuranceSessionMutationFn = Apollo.MutationFunction<PayInsuranceSession, PayInsuranceSessionVariables>;

/**
 * __usePayInsuranceSession__
 *
 * To run a mutation, you first call `usePayInsuranceSession` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayInsuranceSession` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payInsuranceSession, { data, loading, error }] = usePayInsuranceSession({
 *   variables: {
 *      sessionMetaId: // value for 'sessionMetaId'
 *   },
 * });
 */
export function usePayInsuranceSession(baseOptions?: Apollo.MutationHookOptions<PayInsuranceSession, PayInsuranceSessionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PayInsuranceSession, PayInsuranceSessionVariables>(PayInsuranceSessionDocument, options);
      }
export type PayInsuranceSessionHookResult = ReturnType<typeof usePayInsuranceSession>;
export type PayInsuranceSessionMutationResult = Apollo.MutationResult<PayInsuranceSession>;
export type PayInsuranceSessionMutationOptions = Apollo.BaseMutationOptions<PayInsuranceSession, PayInsuranceSessionVariables>;