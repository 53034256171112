import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetAdminFeedbackVariables = Types.Exact<{
  first?: Types.Maybe<Types.Scalars['Int']>;
  after?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetAdminFeedback = (
  { __typename: 'Query' }
  & { adminCompaniesFeedback: (
    { __typename: 'FeedbackCompanyRowsModel' }
    & { nodes: Array<(
      { __typename: 'FeedbackCompanyModel' }
      & FeedbackCompaniesAdminFragment
    )>, pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<Types.PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);

export type FeedbackCompaniesAdminFragment = (
  { __typename: 'FeedbackCompanyModel' }
  & Pick<Types.FeedbackCompanyModel, 'name' | 'companyId' | 'sessionsAmount' | 'rating' | 'progressRate' | 'satisfactionRate' | 'readByAdmin' | 'employeesAmount'>
);

export const FeedbackCompaniesAdminFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FeedbackCompaniesAdminFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FeedbackCompanyModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"companyId"}},{"kind":"Field","name":{"kind":"Name","value":"sessionsAmount"}},{"kind":"Field","name":{"kind":"Name","value":"rating"}},{"kind":"Field","name":{"kind":"Name","value":"progressRate"}},{"kind":"Field","name":{"kind":"Name","value":"satisfactionRate"}},{"kind":"Field","name":{"kind":"Name","value":"readByAdmin"}},{"kind":"Field","name":{"kind":"Name","value":"employeesAmount"}}]}}]} as unknown as DocumentNode;
export const GetAdminFeedbackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetAdminFeedback"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"first"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"after"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminCompaniesFeedback"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"Variable","name":{"kind":"Name","value":"first"}}},{"kind":"Argument","name":{"kind":"Name","value":"after"},"value":{"kind":"Variable","name":{"kind":"Name","value":"after"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"nodes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FeedbackCompaniesAdminFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"pageInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hasNextPage"}},{"kind":"Field","name":{"kind":"Name","value":"endCursor"}}]}}]}}]}},...FeedbackCompaniesAdminFragment.definitions]} as unknown as DocumentNode;

/**
 * __useGetAdminFeedback__
 *
 * To run a query within a React component, call `useGetAdminFeedback` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminFeedback` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminFeedback({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetAdminFeedback(baseOptions?: Apollo.QueryHookOptions<GetAdminFeedback, GetAdminFeedbackVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminFeedback, GetAdminFeedbackVariables>(GetAdminFeedbackDocument, options);
      }
export function useGetAdminFeedbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminFeedback, GetAdminFeedbackVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminFeedback, GetAdminFeedbackVariables>(GetAdminFeedbackDocument, options);
        }
export type GetAdminFeedbackHookResult = ReturnType<typeof useGetAdminFeedback>;
export type GetAdminFeedbackLazyQueryHookResult = ReturnType<typeof useGetAdminFeedbackLazyQuery>;
export type GetAdminFeedbackQueryResult = Apollo.QueryResult<GetAdminFeedback, GetAdminFeedbackVariables>;