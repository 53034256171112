import React, { ReactNode, useState } from 'react';
import styled, { CSSProp } from 'styled-components';
import { FieldValidator } from 'final-form';
import { Field } from 'react-final-form';
import { getSize } from 'lib/utils';

export interface Option<T> {
  label: ReactNode;
  value: string;
  data?: T;
  isDisabled?: boolean;
}

export type IOption<T = undefined> = T extends undefined
  ? Omit<Option<any>, 'data'>
  : Option<T>;

export interface RadioFieldsProps {
  className?: string;
  options: IOption[];
  data?: string;
  name: string;
  radioRootCSS?: CSSProp;
  radioButtonCSS?: CSSProp;
  radioLabelCSS?: CSSProp;
  contentCSS?: CSSProp;
  disabled?: boolean;
  label?: string;
  component: (props: any) => JSX.Element;
  validate?: FieldValidator<string>;
  isPayment?: boolean;
  removeItem?: (id: string) => void;
  fieldLabelCSS?: CSSProp;
  isAssessmentOutcome?: boolean;
  theme?: string;
  isLoading?: boolean;
  buttonCSS?: CSSProp;
  iconCSS?: { [x: string]: any };
  hasIndicator?: boolean;
}

function RadioFields({
  className,
  options,
  name,
  label,
  disabled,
  theme = 'primary',
  radioRootCSS,
  radioButtonCSS,
  radioLabelCSS,
  component,
  contentCSS,
  validate,
  isPayment,
  removeItem,
  fieldLabelCSS,
  isAssessmentOutcome,
  isLoading,
  buttonCSS,
  iconCSS,
  hasIndicator,
}: RadioFieldsProps): JSX.Element {
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <div className={className}>
      {label && <Label $CSS={fieldLabelCSS}>{label}</Label>}

      <Inner $CSS={contentCSS}>
        {options.map(({ value, label, isDisabled, ...data }) => (
          <Field
            {...data}
            key={value}
            id={value}
            name={name}
            label={label}
            type="radio"
            value={value}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            validate={validate}
            disabled={isDisabled}
            isPayment={isPayment}
            removeItem={removeItem}
            radioRootCSS={radioRootCSS}
            radioButtonCSS={radioButtonCSS}
            radioLabelCSS={radioLabelCSS}
            theme={theme}
            isAssessmentOutcome={isAssessmentOutcome}
            isLoading={isLoading}
            component={component}
            buttonCSS={buttonCSS}
            iconProps={iconCSS}
            hasIndicator={hasIndicator}
          />
        ))}
      </Inner>

      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
    </div>
  );
}

const Label = styled.span<{ $CSS?: CSSProp }>`
  display: block;
  width: 100%;
  margin: 0 0 ${getSize(10)};
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  color: var(--gray7);

  ${({ $CSS }) => $CSS}
`;

const Inner = styled.div<{ $CSS?: CSSProp }>`
  ${({ $CSS }) => $CSS}
`;

const ErrorText = styled.span`
  margin: ${getSize(2)} 0 0;
  font-weight: 400;
  font-size: ${getSize(10)};
  line-height: ${getSize(16)};
  color: var(--red);
`;

export default RadioFields;
