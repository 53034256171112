import * as React from 'react';
import { getSize } from 'lib/utils';

export interface PricingIconProps {
  pathColor?: string;
  width?: number;
  height?: number;
}

function PricingIcon({
  width = 24,
  height = 24,
  pathColor = '#E5E4E8',
}: PricingIconProps) {
  return (
    <svg
      width={getSize(width)}
      height={getSize(height)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#prefix__clip0)" fill={pathColor}>
        <path d="M18.72 1.277h-7.369a1.76 1.76 0 00-1.244.516l-9.592 9.59a1.76 1.76 0 000 2.49l7.37 7.368a1.76 1.76 0 002.488 0l9.592-9.59c.33-.33.515-.778.515-1.246V3.037a1.76 1.76 0 00-1.76-1.76zm-3.08 6.16a1.32 1.32 0 11.001-2.64 1.32 1.32 0 010 2.64z" />
        <path d="M22.24 3.037v8.192c0 .405-.162.795-.45 1.082l-9.746 9.747.15.15a1.76 1.76 0 002.488 0l8.801-8.8c.331-.33.517-.778.517-1.245V4.797a1.76 1.76 0 00-1.76-1.76z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PricingIcon;
