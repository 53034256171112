import React, { Dispatch, SetStateAction, useCallback } from 'react';
import styled from 'styled-components';
import { DropEvent, useDropzone } from 'react-dropzone';
import { FieldRenderProps } from 'react-final-form';

import { getFileInfo, getSignedFileUrl, getSize } from 'lib/utils';
import { notifyError } from 'lib/utils/notification';
import { FileExtension, FileType } from '__generated__/types';
import { ApiService } from 'lib/services';
import { MessageAttachment } from '../../message-send-form';

import { AttachIcon } from 'ui';

interface AttachmentInputProps extends FieldRenderProps<MessageAttachment[]> {
  selectedChatId?: string;
  setUploadPercents: Dispatch<SetStateAction<Record<string, number>>>;
}

const MAX_FILES_NUMBER = 10;
const allowedFileExtension = Object.values<string>(FileExtension).reduce(
  (acc, extension) => {
    const extensionInLowerCase = extension.toLowerCase();
    return acc ? `${acc},.${extensionInLowerCase}` : `.${extensionInLowerCase}`;
  },
  '',
);

function AttachmentInput({
  setUploadPercents,
  selectedChatId,
  input: { onChange, value },
}: AttachmentInputProps) {
  const onAttachmentDrop = useCallback(
    async (files: File[], _, e: DropEvent) => {
      (e as any).target.value = '';
      if (!selectedChatId) return;

      const uniqueFiles = files.filter((incomingFile) => {
        return !value.some(
          (valueFile) =>
            `${valueFile.name}.${valueFile.format}` === incomingFile.name,
        );
      });

      const incomingFiles = await Promise.all(
        uniqueFiles.map(async (file) => {
          const { fileName, fileExtension, fileType } = getFileInfo(file);
          const apiUrl = `aws/signed-url-chat?fileType=${fileType?.toLowerCase()}&fileName=${fileName}&extension=${fileExtension.toLowerCase()}&chatId=${selectedChatId}`;

          return await getSignedFileUrl(apiUrl).then(({ data: source }) => ({
            source,
            name: fileName,
            sizeInKB: file.size,
            type: fileType?.toUpperCase() as FileType,
            format: fileExtension.toUpperCase() as FileExtension,
          }));
        }),
      ).catch(() => {
        notifyError({ text: 'Signing file Error' });
        return [];
      });

      function uploadFileAndSetProgress(
        { source }: MessageAttachment,
        index: number,
      ) {
        ApiService.putRequest(source, uniqueFiles[index], {
          onUploadProgress: (progressEvent) => {
            const fileUploadPercent = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );

            setUploadPercents((prevValue) => {
              let clonePrevValue = { ...prevValue };

              if (fileUploadPercent === 100) {
                delete clonePrevValue[source];
              } else {
                clonePrevValue = {
                  ...clonePrevValue,
                  [source]: fileUploadPercent,
                };
              }

              return clonePrevValue;
            });
          },
        }).catch((error) => {
          notifyError({ text: error?.message });
          setUploadPercents((prevValue) => {
            return { ...prevValue, [source]: -1 };
          });
        });
      }

      if (incomingFiles?.length > 0) {
        onChange([...value, ...incomingFiles]);
        incomingFiles.forEach(uploadFileAndSetProgress);
      }
    },
    [onChange, value, setUploadPercents, selectedChatId],
  );

  const { getRootProps, getInputProps } = useDropzone({
    noClick: true,
    multiple: true,
    onDrop: onAttachmentDrop,
    accept: allowedFileExtension,
    maxFiles: MAX_FILES_NUMBER - value.length,
  });

  return (
    <div {...getRootProps()}>
      <AttachmentButton>
        <AttachIconStylized />
        <input
          {...getInputProps()}
          disabled={value.length === MAX_FILES_NUMBER}
        />
      </AttachmentButton>
    </div>
  );
}

const AttachmentButton = styled.label`
  margin: 0 ${getSize(16)} 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${getSize(28)};
  height: ${getSize(28)};
  background: var(--purple5);
  border-radius: 50%;
  transition: 0.3s ease-out;
  cursor: pointer;

  &:hover {
    background: var(--purple9);
  }
`;

const AttachIconStylized = styled(AttachIcon)`
  width: ${getSize(14)};
  height: ${getSize(14)};
`;

export default AttachmentInput;
