import * as React from 'react';

function PhoneIcon({ ...rest }) {
  return (
    <svg
      width={23}
      height={22}
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <path
        d="M21.001 15.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.501 19.501 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 013.111 1h3a2 2 0 012 1.72c.127.96.362 1.903.7 2.81a2 2 0 01-.45 2.11l-1.27 1.27a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45c.908.338 1.85.573 2.81.7a2 2 0 011.72 2.03z"
        stroke="#7A60E9"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PhoneIcon;
