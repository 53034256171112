import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { getSize } from 'lib/utils';

export interface TextOfCountProps {
  children: ReactNode;
  className?: string;
}

function TextOfCount({ children, className }: TextOfCountProps) {
  return <Wrapper className={className}>{children}</Wrapper>;
}

const Wrapper = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${getSize(2)};
  height: ${getSize(15)};
  min-width: ${getSize(23)};
  font-weight: 400;
  font-size: ${getSize(10)};
  line-height: ${getSize(15)};
  color: white;
  border-radius: ${getSize(8)};
  background: var(--red);
`;

export default TextOfCount;
