import * as React from 'react';
import { SVGProps } from 'react';

const DiscountIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m21.462 9.094.538-.09V4.125h-6.016v2.342h-1.289V4.125H0v4.879l.538.09c.933.156 1.61.958 1.61 1.906s-.677 1.75-1.61 1.906l-.538.09v4.879h14.695v-2.342h1.29v2.342H22v-4.879l-.538-.09A1.926 1.926 0 0 1 19.852 11c0-.948.677-1.75 1.61-1.906Zm-5.478 5.15h-1.289v-2.578h1.29v2.578Zm0-3.91h-1.289V7.756h1.29v2.578Z"
      fill="#E5E4E8"
    />
  </svg>
);

export default DiscountIcon;
