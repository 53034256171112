import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type EditCompanyVariables = Types.Exact<{
  input: Types.EditCompanyInput;
}>;


export type EditCompany = (
  { __typename: 'Mutation' }
  & { editCompany: (
    { __typename: 'AdminCompanyPayload' }
    & { company?: Types.Maybe<(
      { __typename: 'CompanyModel' }
      & Pick<Types.CompanyModel, 'id' | 'companyKey'>
    )>, userErrors: Array<(
      { __typename: 'ValidationError' }
      & Pick<Types.ValidationError, 'field' | 'messages'>
    )> }
  ) }
);


export const EditCompanyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"EditCompany"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EditCompanyInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"editCompany"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"company"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"companyKey"}}]}},{"kind":"Field","name":{"kind":"Name","value":"userErrors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"field"}},{"kind":"Field","name":{"kind":"Name","value":"messages"}}]}}]}}]}}]} as unknown as DocumentNode;
export type EditCompanyMutationFn = Apollo.MutationFunction<EditCompany, EditCompanyVariables>;

/**
 * __useEditCompany__
 *
 * To run a mutation, you first call `useEditCompany` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCompany` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCompany, { data, loading, error }] = useEditCompany({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCompany(baseOptions?: Apollo.MutationHookOptions<EditCompany, EditCompanyVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCompany, EditCompanyVariables>(EditCompanyDocument, options);
      }
export type EditCompanyHookResult = ReturnType<typeof useEditCompany>;
export type EditCompanyMutationResult = Apollo.MutationResult<EditCompany>;
export type EditCompanyMutationOptions = Apollo.BaseMutationOptions<EditCompany, EditCompanyVariables>;